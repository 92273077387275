// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.UpcomingScreen = (function()
{
    "use strict";

    UpcomingScreen.prototype = new Screen();

    function UpcomingScreen(){}

    UpcomingScreen.prototype.gotFocus = function()
    {
        if( this._list.getMaxIndex() > 0 )
            this.speak( _x2.requestFocus( this._list, true ) );
    };

    /**
     * Initializer
     * @memberof UpcomingScreen
     * @param   {Object} params
     * @param   {Object} params.entity   -
     * @param   {Array } params.listings -
     * @returns {UpcomingScreen}
     */
    UpcomingScreen.prototype.init = function( params )
    {
        var entity, listings, self = this, listParams = {}, entityListParams = {};

        Screen.prototype.init.call( this );
        this._className     = "UpcomingScreen";
        this._telemetryName = "Entity Upcoming";

        entity = params.entity;

        if( params.listings )
            listings = params.listings;
        else
            listings = entity.getEntityOptions().getUpcomingListings();

        //add poster
        var imageW  = _x2.scaleValInt(303);
        var imageH  = _x2.scaleValInt(404);
        var listPad = _x2.scaleValInt( 80); // Style._pad * 6;
        var listW   = _x2._config._screenW - Style._safeLeft - imageW - listPad - Style._safeRight;

        var imageError = function()
        {
            var fallback = entity.getFallbackImageLink( 303, 404 );
            if( self._image.getUrl() === fallback )
                self._image.setA( 0 ); //fallback already tried. hide image.
            else
                self._image.setUrl( fallback );

            // AAECH-59
            // 3. Search, LifeLock Protection, Upcoming
            // self._image.setUrl( self._image._url + "&default=force" );
        };

        this._image = new ImageWidget().init( { url:entity.getImageLink(303,404), w:imageW, h:imageH, onError:imageError } );
        this.addWidget(this._image);

        //add list
        entityListParams.w        = listW;
        entityListParams.h        = _x2._config._screenH;
        entityListParams.maxIndex = 1;
        entityListParams.type     = UpcomingRowWidget;
        this._entityList          = new ListWidget().init( entityListParams );

        listParams.w        = listW;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 0;
        listParams.type     = ExpandingListRowWidget;
        listParams.obj      = { list:this._entityList, baseWidget:this };
        this._list          = new ListWidget().init( listParams );

        this.addWidget( this._list );
        this._list.addWidget( this._entityList );

        this.addReadyCallback( function(){ self.layout( _x2.scaleValInt(52) ); } );

        this.setBreadCrumbs( entity.getTitle() );

        setTimeout( function(){ self.loadData( listings ) }, 1);

        return this;
    };

    UpcomingScreen.prototype.layout = function( listPad )
    {
        var top = this.getHeaderBottom() + _x2.scaleValInt( 42 );

        this._image.setX(Style._safeLeft);
        this._image.setY(top);

        this._list.setX(Style._safeLeft + this._image.getW() + listPad);
        this._list.setY(top);
        this._ready = true;

        if( _x2._focus === this && this._list.getMaxIndex() > 0 )
            this.speak( _x2.requestFocus( this._list, true ) );
    };

    UpcomingScreen.prototype.loadData = function( listings )
    {
        var date, key, title, i, entries = [], index = 0;

        this._entries = [];

        if( listings && listings.length )
        {
            for( i=0; i<listings.length; i++ )
            {
                date = new Date(listings[i].getStartTime());
                date.setMilliseconds( 0 );
                date.setSeconds( 0 );
                date.setMinutes( 0 );
                date.setHours( 0 );

                key = date.getTime();

                if( entries[key] === undefined )
                {
                    title = ScheduledWidget.DAYS[date.getDay()] + ", " + ScheduledWidget.MONTHS[date.getMonth()] + " " + date.getDate();
                    this._entries[index++] = entries[key] = { title:title, entries:[] };
                }

                entries[key].entries.push( listings[i] );
            }

            this._list.setData( this._entries );

            if( _x2._focus === this && this._ready === true )
                this.speak( _x2.requestFocus( this._list, true ) );
        }
    };

    UpcomingScreen.prototype.speak = function( focus )
    {
        this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press up or down to review days with showings. Press OK to select. ", focus._speakRole, this, false );
    };

    return UpcomingScreen;

})();
