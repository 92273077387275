// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.OnOffOverlay = ( function()
{
    "use strict";

    OnOffOverlay.prototype = new Overlay();

    function OnOffOverlay(){}

    OnOffOverlay.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list );
        this._speakTarget.setText( "On/Off Options. " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press up or down to review options. Press OK to select.", focus._speakRole, this, false );
    };

    OnOffOverlay.prototype.init = function( params )
    {
        var listParams = {}, onOn, onOff;

        Overlay.prototype.init.call( this );
        this._className  = "OnOffOverlay";

        onOn = function()
        {
            _x2.popOverlay();

            if( params.onEnter )
                params.onEnter( true );
        };

        onOff = function()
        {
            _x2.popOverlay();

            if( params.onEnter )
                params.onEnter( false );
        };

        listParams.w        = this._bg.getW() - Style._safeLeft - Style._safeRight;
        listParams.h        = new MenuRowWidget().getHeightUnfocused() * 2;
        listParams.maxIndex = 1;
        listParams.rows     = [ { title:"On", onEnter:onOn, selected:params.isOn===true, rowIndex: 0 }, { title:"Off", onEnter:onOff, selected:params.isOn===false, rowIndex: 1 } ];
        listParams.type     = ChoiceRowWidget;

        this._list = new ListWidget().init( listParams );
        this._bg.addWidget( this._list, Style._safeLeft, (this._bg.getH() - this._list.getH()) / 2 );

        this._sep = new RectWidget().init( { w:listParams.w, h:ListRowWidget.DIVIDER_H, color:"#262626" } );
        this._bg.addWidget( this._sep, Style._safeLeft, this._list.getY() - this._sep.getVal( Widget.H ) );

        return this;
    };

    return OnOffOverlay;

})();
