// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 * Reference: https://etwiki.sys.comcast.net/display/AAE/Offer+Resources
 */
window.TransactionOffer = (function()
{
    "use strict";

    var PURCHASE_TYPES = [ "Buy", "Rent", "Subscribe" ];
    var OFFER_TYPES    = [ "Single", "SeasonBundle", "SimpleUpsell" ];
    var BILLING_TYPE   = [ "OneTime", "Monthly"];

    TransactionOffer.PurchaseType = Object.freeze({
        BUY      : PURCHASE_TYPES[0],
        RENT     : PURCHASE_TYPES[1],
        SUBSCRIBE: PURCHASE_TYPES[2]
    });

    TransactionOffer.OfferType = Object.freeze({
        SINGLE       : OFFER_TYPES[0],
        SEASON_BUNDLE: OFFER_TYPES[1],
        SIMPLE_UPSELL: OFFER_TYPES[2]
    });

    TransactionOffer.prototype = new Showing();

    function TransactionOffer(){}

    TransactionOffer.prototype.init = function( data )
    {
        Showing.prototype.init.call( this, data );

        return this;
    };

    TransactionOffer.prototype.executeTransaction = function( pin )
    {
        var self = this;

        var resolver = function( resolve, reject )
        {
            var form = self.getForm("transact");

            if( form )
            {
                form.getFields(); //init the form fields

                if( pin !== undefined )
                    form.setFieldValue( "purchasePin", pin );

                form.submit().then( resolve ).catch( reject );
            }
            else
                reject("Transaction offer not available");
        };

        return new Promise( resolver );
    };

    /**
     * Return common properties used by various transactional Localytics events.
     *  * finalAction - "buy now", "rent now"
     *  * programType - "Movie", "Series", "Other"
     *  * option - <subscribe | <<buy | rent> <SD | HD> <movie | episode | season>>
     * @param {XtvApi.EntityType} entityType - EPISODE, MOVIE, etc.
     * @returns {Object}
     */
    TransactionOffer.prototype.getLocalyticsCommonTransactional = function( entityType )
    {
        var retval   = {},
            purchase = this.getPurchaseType(),
            programType,
            optionsType,
            option;

        if( entityType === XtvApi.EntityType.MOVIE )
        {
            programType = "Movie";
            optionsType = "movie";
        }
        else if( entityType === XtvApi.EntityType.EPISODE || entityType === XtvApi.EntityType.SERIES )
        {
            if( this.getOfferType() === TransactionOffer.OfferType.SEASON_BUNDLE )
            {
                programType = "Series";
                optionsType = "season";
            }
            else
            {
                programType = "Series";
                optionsType = "episode";
            }
        }
        else if( entityType === XtvApi.EntityType.SPORTS || entityType === XtvApi.EntityType.TEAM ) // TODO: Team included?
        {
            programType = "Sporting Event";
            optionsType = "sporting event";
        }
        else
        {
            programType = "Other";
            optionsType = "other";
        }

        if( purchase === TransactionOffer.PurchaseType.SUBSCRIBE )
        {
            option = "subscribe";

            retval.transaction = option;
            retval.finalAction = "subscribe"; // TODO: Not documented
        }
        else
        {
            if( purchase === TransactionOffer.PurchaseType.BUY )
                option = "buy ";
            else if( purchase === TransactionOffer.PurchaseType.RENT )
                option = "rent ";

            retval.transaction = option;
            retval.finalAction = option + "now";

            if( this.isHD() )
                option += "HD - ";
            else
                option += "SD - ";


            option += optionsType;
        }

        retval.programType = programType;
        retval.option      = option;

        return retval;
    };

    TransactionOffer.prototype.isHD = function()
    {
        return this.get("videoQuality") === "HD";
    };

    TransactionOffer.prototype.isSap = function()
    {
        return this.get("isSap");
    };

    /**
     * Use to filter out unsupported offers. XTVAPI requirement.
     * @return {boolean}
     */
    TransactionOffer.prototype.isSupported = function()
    {
        var isValid = true;

        if( PURCHASE_TYPES.indexOf( this.getPurchaseType() ) < 0 )
            isValid = false;

        if( OFFER_TYPES.indexOf( this.getOfferType() ) < 0 )
            isValid = false;

        if( BILLING_TYPE.indexOf( this.getBillingType() ) < 0 )
            isValid = false;

        return isValid
    };

    TransactionOffer.prototype.isSeasonBundle = function()
    {
        return ( this.getOfferType() === "SeasonBundle" );
    };

    TransactionOffer.prototype.getBillingType = function()
    {
        return this.get("billingType");
    };

    TransactionOffer.prototype.getContentProvider = function()
    {
        if( this._contentProvider === undefined )
        {
            var cp = this.getEmbedded("contentProvider");

            if( cp )
                this._contentProvider = new ContentProvider().init( cp );
        }

        return this._contentProvider;
    };

    TransactionOffer.prototype.getDuration = function()
    {
        var durationMin = 0, duration = this.get("duration");

        if( duration && !isNaN( duration ) )
            durationMin = parseInt( duration / 60 );

        return durationMin;
    };

    TransactionOffer.prototype.getNumberOfFeatures = function()
    {
        return this.get( "numberOfFeatures" );
    };

    TransactionOffer.prototype.getOfferType = function()
    {
        return this.get("offerType");
    };

    TransactionOffer.prototype.getPrice = function()
    {
        return this.get("price");
    };

    TransactionOffer.prototype.getPurchaseType = function()
    {
        return this.get("purchaseType");
    };

    TransactionOffer.prototype.getRating = function()
    {
        return this.getPath( [ "contentRating/detailed", "name" ] );
    };

    TransactionOffer.prototype.getRentalWindowInHours = function()
    {
        var hours = 0, seconds = this.getInteger("rentalWindow");

        if( seconds )
            hours = Math.round( seconds / 3600 );

        return hours
    };

    TransactionOffer.prototype.getVideoQuality = function()
    {
        return this.get("videoQuality");
    };

    return TransactionOffer;
})();
