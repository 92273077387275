// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Encapsulates HAL forms for updating objects
 */
window.Form = (function()
{
    "use strict";

    function Form(){}

    /**
     * Initialize this object with data
     * @memberOf Form
     * @param {Object} data - XTV derived JSON object
     * @param {String} data - name of the form / API name used for logging and error handling
     */
    Form.prototype.init = function( data, name )
    {
        this._data = data;
        this._name = name;

        return this;
    };

    /**
     * Get all field for this form
     * @memberOf Form
     * @returns {Array} array of key-value pairs
     */
    Form.prototype.getFields = function()
    {
        if( ! this._fields )
        {
            var field, fieldObj;
            this._fields = [];
            this._fieldArray = [];

            for( field in this._data.fields )
            {
                if( this._data.fields.hasOwnProperty( field ) )
                {
                    fieldObj = new FormField().init( field, this._data.fields[field]);
                    this._fieldArray.push( fieldObj );
                    this._fields[field] = fieldObj
                }
            }
        }

        return this._fieldArray;
    };

    /**
     * Return the form title
     * @return {String}
     */
    Form.prototype.getTitle = function()
    {
        return this._data.title;
    };

    /**
     * Set the value of a form field
     * @memberOf Form
     * @param {String} key
     * @param {String|Number|Boolean} value
     */
    Form.prototype.setFieldValue = function( key, value )
    {
        this._fields[key].setValue(value);
    };

    /**
     * Submit the form
     * @memberOf Form
     * @param {Object} queryStringParams - replacement values as key value pairs
     * @return {Promise}
     */
    Form.prototype.submit = function( queryStringParams )
    {
        var payload = "";
        var method  = this._data.method;
        var url     = _x2._data._host + XtvApi.trimPath( this._data.action );
        var param;

        if( queryStringParams !== undefined )
        {
            for( param in queryStringParams )
            {
                if( queryStringParams.hasOwnProperty( param ) )
                    url = url.replace( '{' + param + '}', queryStringParams[param] );
            }
        }

        if( this._fieldArray )
        {
            for( var i=0; i<this._fieldArray.length; i++ )
            {
                if( payload.length )
                    payload += '&';

                if( this._fieldArray[i].getValue() !== undefined )
                    payload += this._fieldArray[i].getName() + '=' + this._fieldArray[i].getValue();
            }
        }

        return _x2._network.ajax( { type:method, url:url, data:payload, api:this._name, headers:_x2._data.getAuthHeader(), contentType:'application/x-www-form-urlencoded' } )
    };

    return Form;
})();
