// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.TomatoWidget = (function()
{
    "use strict";

    TomatoWidget.prototype = new Widget();

    function TomatoWidget(){}

    TomatoWidget.prototype.getSpeechStr = function()
    {
        return "Rotten Tomatoes. Critics Rating " + this._criticScore + " percent. Audience Rating " + this._userScore + " percent. ";
    };

    TomatoWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this, params );
        this._className = "TomatoWidget";
        this._numLoaded = 0;

        var self       = this;
        var noTitle    = params ? params.noTitle : undefined;
        var style      = params ? params.style   : undefined;
        var titleStyle = new Style( { font: "regular", color: "#8b8b8b", fontSize: _x2.scaleValInt( 30 ), whiteSpace: "nowrap" } );
        var valueStyle = style ? style : new Style( { font: "regular", color: "#e8e8e8", fontSize: _x2.scaleValInt( 30 ), whiteSpace: "nowrap" } );
        var onLoad     = function() { self._numLoaded++; self.layout(); };

        this.setA( 0 );

        this._criticIcon        = new ImageWidget().init ( { url:_x2._config._imageRoot + "rating_fresh.png"  , onLoad:onLoad } );
        this._userIcon          = new ImageWidget().init ( { url:_x2._config._imageRoot + "rating_popcorn.png", onLoad:onLoad } );
        this._criticScoreString = new StringWidget().init( { style: valueStyle } );
        this._userScoreString   = new StringWidget().init( { style: valueStyle } );

        this._userScoreString.addReadyCallback( onLoad );

        if( ! noTitle )
        {
            this._titleSting = new StringWidget().init( { style: titleStyle, text:"Rotten Tomatoes " + String.fromCharCode( 0x2122 ) } );
            this.addWidget( this._titleSting );
        }

        this.addWidget( this._criticIcon );
        this.addWidget( this._criticScoreString );
        this.addWidget( this._userIcon );
        this.addWidget( this._userScoreString );

        return this;
    };

    TomatoWidget.prototype.layout = function()
    {
        if( this._numLoaded === 3 )
        {
            var titleW, lowerW;
            var lowerY = this._titleSting ? this._titleSting.getH() : 0;
            var maxH   = this._criticIcon.getH();

            if( this._criticScoreString.getH() > maxH )
                maxH = this._criticScoreString.getH();

            if( this._userIcon.getH() > maxH )
                maxH = this._userIcon.getH();

            this._criticIcon.setY( lowerY + (maxH - this._criticIcon.getH()) / 2 );
            this._criticScoreString.setY( lowerY + (maxH - this._criticScoreString.getH()) / 2 );
            this._userIcon.setY( lowerY + (maxH - this._userIcon.getH()) / 2 );
            this._userScoreString.setY( this._criticScoreString.getY() );

            this._criticScoreString.setX( this._criticIcon.getX() + this._criticIcon.getW() + Style._pad/2 );

            if( this._criticScore <= 0 )
            {
                this._userIcon.setX( 0 );
                this._userScoreString.setX( this._userIcon.getX() + this._userIcon.getW() + Style._pad/2 );
                this._criticIcon.setA( 0 );
                this._criticScoreString.setA( 0 );
            }
            else
            {
                this._userIcon.setX( this._criticScoreString.getX() + this._criticScoreString.getW() + Style._pad );
                this._userScoreString.setX( this._userIcon.getX() + this._userIcon.getW() + Style._pad/2 );
                this._criticIcon.setA( 1 );
                this._criticScoreString.setA( 1 );
            }

            if( this._userScore <= 0 )
            {
                this._userIcon.setA( 0 );
                this._userScoreString.setA( 0 );
            }
            else
            {
                this._userIcon.setA( 1 );
                this._userScoreString.setA( 1 );
            }

            titleW = this._titleSting ? this._titleSting.getW() : 0;
            lowerW = this._userScoreString.getX() + this._userScoreString.getW();

            this.setW( titleW < lowerW ? lowerW : titleW );
            this.setH( lowerY + maxH );

            this.callbackSignalReady();
        }
    };

    TomatoWidget.prototype.setData = function( review )
    {
        this.setA( 0 );
        this._userScore   = review.getFanSummaryScore();
        this._criticScore = review.getCriticSummaryScore();

        var userIco   = review.getFanSummaryRotten()    ? "rating_spilled.png" : "rating_popcorn.png";
        var criticIco = review.getCriticSummaryRotten() ? "rating_splat.png"   : "rating_fresh.png";

        this._userIcon.setUrl( _x2._config._imageRoot + userIco );
        this._criticIcon.setUrl( _x2._config._imageRoot + criticIco );
        this._userScoreString.setText( this._userScore + "%" );
        this._criticScoreString.setText( this._criticScore + "%" );

        this.layout();
    };

    return TomatoWidget;

})();
