// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.MoreTargetWidget = ( function()
{
    "use strict";

    MoreTargetWidget.prototype = new Widget();

    /**
     * @enum Dir
     * @property {Number} DOWN  - Down Arrow
     * @property {Number} LEFT  - Left Arrow
     * @property {Number} RIGHT - Right Arrow
     * @property {Number} UP    - Up Arrow
     */
    MoreTargetWidget.Dir = Object.freeze
    ({
        DOWN  : 0,
        LEFT  : 1,
        RIGHT : 2,
        UP    : 3
    });

    MoreTargetWidget.Size = Object.freeze
    ({
        GALLERY : 0,
        SMALL   : 1
    });

    function MoreTargetWidget(){}

    /**
     * Initializer
     * @memberof MoreTargetWidget
     * @param   {Object}  params
     * @param   {Number}  params.dir   - arrow direction
     * @param   {Number}  params.h     - height of target area
     * @param   {Number} [params.size] - size of arrow to use, default is GALLERY
     * @param   {Number}  params.w     - width of target area
     * @returns {MoreTargetWidget}
     */
    MoreTargetWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this );
        this._className = "MoreTargetWidget";

        this._notScale = 1;
        this._scale    = 8 / 9;

        var self     = this;
        var imName   = [ "moreDownGallery.png", "moreLeftGallery.png", "moreRightGallery.png", "moreUpGallery.png"];
        var imNameSm = [ "moreDownSm.png"     , "moreLeftSm.png"     , "moreRightSm.png"     , "moreUpSm.png"     ];
        var fileName = _x2._config._imageRoot + ((params.size === MoreTargetWidget.Size.SMALL) ? imNameSm[params.dir] : imName[params.dir]);

        this._image = new ImageWidget().init( { url:fileName, onLoad:function() { self.layoutMoreTargetWidget(); } } );
        this._image.setA( 0.6 );
        this._image.setScale( this._scale );
        this.addWidget( this._image );

        this.setW( params.w );
        this.setH( params.h );

        this.setMouseOutListener ( function() { self._image.animate( { duration:250, xScale:self._scale   , yScale:self._scale   , a:0.6 } ); } );
        this.setMouseOverListener( function() { self._image.animate( { duration:250, xScale:self._notScale, yScale:self._notScale, a:1.0 } ); } );

        return this;
    };

    MoreTargetWidget.prototype.layoutMoreTargetWidget = function()
    {
        this._image.setX( (this.getW() - this._image.getW()) / 2 );
        this._image.setY( (this.getH() - this._image.getH()) / 2 );
    };

    MoreTargetWidget.prototype.setArrowH = function( val )
    {
        this._notScale = val / this._image.getH();
        this._scale    = this._notScale * 8 / 9;
        this._image.setScale( this._scale );

        this.layoutMoreTargetWidget();
    };

    MoreTargetWidget.prototype.setH = function( val )
    {
        Widget.prototype.setH.call( this, val );
        this.layoutMoreTargetWidget();
    };

    return MoreTargetWidget;

})();
