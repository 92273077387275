// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

// AA = Animate Angle
// AR = Aspect Ratio
// GH = Gradient Hue
window.GradientAAARGHTestScreen = ( function()
{
    "use strict";

    GradientAAARGHTestScreen.prototype = new Screen();

    function GradientAAARGHTestScreen(){}

    GradientAAARGHTestScreen.prototype.init = function()
    {
        var angle, delta;
        var alphaC, colorC, linearC, paramsC, gradientC;
        var alphaG, colorG, linearG, paramsG, gradientG;

        var dim = 200;
        var ar = 1.0; // Aspect Ration = width/height
        var h = dim; // h = w / ar
        var w = dim; // w = h * ar

        var i;
        var x0 = Style._safeLeft;
        var x = x0 + dim + (dim*1.25) + (dim*1.5) + (dim*1.75) - (dim*0.5) - (dim*0.75), y = Style._safeTop;
        var col, row;

        Screen.prototype.init.call( this );
        this._className = "GradientAAARGHTestScreen";

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000033" } );
        this.addWidget( this._bg );

        this._gradsC = [];
        this._gradsG = [];

        alphaC = // Color / Chromatic
        [
            {
                opacity : 100,
                location:   0
            },
            {
                opacity :  50,
                location: 100
            }
        ];
        colorC =
        [
            {
                color   : "#FF0000",
                location: 0
            },
            {
                color   : "#00FF00",
                location: 100*1/3
            },
            {
                color   : "#0000FF",
                location: 100*2/3
            },
            {
                color   : "#FFFFFF",
                location: 100
            }
        ];

        alphaG = // Monochrome / Greyscale
        [
            {
                opacity : 100,
                location:   0,
            },
            {
                opacity : 100,
                location: 100
            }
        ];
        colorG =
        [
            {
                color   : "#000000",
                location: 0
            },
            {
                color   : "#555555",
                location: 100*1/3
            },
            {
                color   : "#AAAAAA",
                location: 100*2/3
            },
            {
                color   : "#FFFFFF",
                location: 100
            }
        ];

        linearC =
        {
            w: w,
            h: h,
            type: GradientWidget.Type.Linear,
            angle: 45,
            alphas: alphaC,
            colors: colorC
        };
        linearG =
        {
            w: w,
            h: h,
            type: GradientWidget.Type.Linear,
            angle: 45,
            alphas: alphaG,
            colors: colorG
        };

        var dim2      = 256;
        angle         = 0;
        this._angle   = angle;

        paramsC       = Object.assign( {}, linearC );
        paramsC.angle = angle;
        paramsC.w     = dim2;
        paramsC.h     = dim2;

        paramsG       = Object.assign( {}, linearG );
        paramsG.angle = angle;
        paramsG.w     = dim2;
        paramsG.h     = dim2;

        gradientC     = new GradientWidget().init( paramsC );
        gradientG     = new GradientWidget().init( paramsG );

        gradientC.setA( 1 );
        gradientG.setA( 0 );

        this._gradC  = gradientC;
        this._gradG  = gradientG;

        this.addWidget( gradientC, x0, y );
        this.addWidget( gradientG, x0, y );

        var fontSize = 36;
        var align    = "left";
        var color    = "#e5e500";
        var style    = new Style( { color:color, font:"light", fontSize:fontSize, whiteSpace:"nowrap", textAlign: align } );
        this._text   = new StringWidget().init( { text:"" + this._angle, style: style } );
        this.addWidget( this._text, x0 + dim2, y );

        /*
            i  AR    Row Col
            2  0.5   1   2
            3  0.75  1   3
            4  1.0   2   0
            5  1.25  2   1
            6  1.5   2   2
            7  1.75  2   3
            8  2.0   4   0
        */
        for( i = 2; i <= 8; i++ )
        {
            ar = i / 4; // 2 0
            w  = h * ar;

            paramsC = Object.assign( {}, linearC );
            paramsG = Object.assign( {}, linearG );

            paramsC.w = w;
            paramsG.w = w;

            gradientC = new GradientWidget().init( paramsC );
            gradientG = new GradientWidget().init( paramsG );

            gradientC.setA( 1 );
            gradientG.setA( 0 );

            this._gradsC.push( gradientC );
            this._gradsG.push( gradientG );

            this.addWidget( gradientC, x, y );
            this.addWidget( gradientG, x, y );

//console.log( "(" + i + ") %o", params );

            x += w;

            if( i === 3 )
            {
                x = x0;
               y += dim2;
            }
            if( i === 7 ) // CR at AR =1 .0
            {
                x = x0;
                y += h;
            }
        }

        // Angle Controller in Gradians, 0 = 0.0, 0.25 = 90, 0.50 = 180, 0.75 = 270, 1.0 = 360
        this._anim = new Widget().init();

        this._selectable = true;

        return this;
    };

    GradientAAARGHTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true, i;

        switch( val )
        {
            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                    this.updateAngle( -5.0 );
                break;

            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                    this.updateAngle( +5.0 );
                break;

            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                    this.updateAngle( +45.0 );
                break;

            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED )
                    this.updateAngle( -this._angle ); // force to zero
                break;

            case Host.KEY_ENTER: // Enter = Toggle Color / Monochrome
                if( type === Host.KEY_PRESSED )
                {
                    var alphaC = 1 - this._gradC.getA();
                    var alphaG = 1 - alphaC;

                    this._gradC.setA( alphaC );
                    this._gradG.setA( alphaG );

                    for( i = 0; i < this._gradsC.length; i++ )
                    {
                        this._gradsC[ i ].setA( alphaC );
                        this._gradsG[ i ].setA( alphaG );
                    }
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    GradientAAARGHTestScreen.prototype.updateAngle = function( delta )
    {
        var angle = this._angle + delta;

        if( angle <    0.0)
            angle += 360.0;

        if( angle >= 360.0)
            angle -= 360.0;

        this._angle = angle;
        this._text.setText( "" + this._angle );

        this._gradC.setAngle( angle );
        this._gradG.setAngle( angle );

        for( var i = 0; i < this._gradsC.length; i++ )
        {
            this._gradsC[ i ].setAngle( angle );
            this._gradsG[ i ].setAngle( angle );
        }
    };

    return GradientAAARGHTestScreen;

})();

