// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.RectWidget = ( function()
{
    "use strict";

    RectWidget.prototype             = new Widget();
    RectWidget.prototype.constructor = RectWidget;
    RectWidget.SHADER                = Widget.SHADER_KV;

    function RectWidget(){}

    /** Over-ride to include border thickness for width and height
     * @memberof RectWidget
     * @param {Axis} axis - Widget.H or Widget.W
     */
    RectWidget.prototype.getVal = function( axis )
    {
        var retval = Widget.prototype.getVal.call( this, axis );

        if( this._bThickness )
        {
            switch( axis )
            {
                case Widget.H:
                    switch( typeof( this._bThickness ) )
                    {
                        case "number":
                            retval -= 2 * this._bThickness;
                            break;

                        case "object":
                            retval -= this._bThickness[0] + this._bThickness[2];
                            break;
                    }
                    break;

                case Widget.W:
                    switch( typeof( this._bThickness ) )
                    {
                        case "number":
                            retval -= 2 * this._bThickness;
                            break;

                        case "object":
                            retval -= this._bThickness[1] + this._bThickness[3];
                            break;
                    }
                    break;
            }
        }

        return retval;
    };

    /**
     * Get border as valid array
     * <p>
     * Note: Only used by WebGL but is render agnostic
     * @memberof RectWidget
     * @returns {Number[]} - [ top, left, bottom, right ]
     */
    RectWidget.prototype.getBorderArray = function()
    {
        return Array.isArray( this._bThickness )
            ?                 this._bThickness        // border = [top,left,bottom,right]
            :  Array(4).fill( this._bThickness | 0 ); // border = #
    };

    /**
     * Initializer
     * <p>
     * Three types of rectangles are supported:
     * <p>
     * 1. Inside only via `params.color`
     * 2. Border only via `params.borderColor`
     * 3. Border and Inside via `params.color` and `params.borderColor`
     * @memberof RectWidget
     * @param {Object}  params                    - parameters to define how the rectangle should be displayed
     * @param {Number}  params.w                  - Width in pixels
     * @param {Number}  params.h                  - Height in pixels
     * @param {String} [params.color]             - HTML 6 hex digit color; e.g. '#000000'
     * @param {Number} [params.borderThickness]   - Border thickness in pixels
     * @param {Number} [params.borderThickness[]] - Border thickness [top,left,bottom,right]
     * @param {String} [params.borderColor]       - HTML 6 hex digit color; e.g. '#000000'
     * @param {String} [params.name]              - Class name over-ride
     * @note: params.borderColor must be specified if params.borderThickness is
     */
    RectWidget.prototype.init = function( params )
    {
        if( this._className === undefined )
            this._className = (params && params.name) ? params.name : "RectWidget";

        Widget.prototype.init.call( this );

        // create instance variables here

        this.setW( params.w );
        this.setH( params.h );

        this._color      = params.color;
        this._bThickness = params.borderThickness;
        this._bColor     = params.borderColor;

        return this;
    };

    /**
     * WebGL only: draw rectangle either inside only, border only, or inside + border
     * @memberof RectWidget
     * @param {Number} alphaParent - parent's alpha * current alpha
     */
    RectWidget.prototype.render = function( alphaParent )
    {
        var gl = Widget.gl, shader = Widget.glShaderThis;

        var w  = this._curVal[Widget.W];
        var h  = this._curVal[Widget.H];

        var border;
        var x2, x3;
        var y2, y3;

        gl.bindBuffer( gl.ARRAY_BUFFER, this._buffPosition );

        if( (this._lastW !== w) || (this._lastH !== h) )
        {
            this._lastW = w;
            this._lastH = h;

            border = this.getBorderArray();

            x2 = w + border[3];
            x3 = w + border[3] + border[1];

            y2 = h + border[0];
            y3 = h + border[0] + border[2];

            if( this._bThickness )
            {
                this._dataPosition[26] = x2;
                this._dataPosition[30] = x2;
                this._dataPosition[32] = x2;
                this._dataPosition[34] = x2;

                this._dataPosition[20] = x3;
                this._dataPosition[22] = x3;
                this._dataPosition[24] = x3;
                this._dataPosition[28] = x3;

                this._dataPosition[19] = y2;
                this._dataPosition[23] = y2;
                this._dataPosition[25] = y2;
                this._dataPosition[27] = y2;

                this._dataPosition[13] = y3;
                this._dataPosition[15] = y3;
                this._dataPosition[17] = y3;
                this._dataPosition[21] = y3;
            }

            // Common to with-border and without-border
            this._dataPosition[2] = x2;
            this._dataPosition[6] = x2;

            this._dataPosition[5] = y2;
            this._dataPosition[7] = y2;

            // Update vertices
            gl.bufferSubData( gl.ARRAY_BUFFER, 0, this._dataPosition );
        }

        gl.vertexAttribPointer( shader.avPosition, 2, gl.FLOAT, false, this._dataPosition, 0 ); // index, size, type, bNormalized, stride, offset
        gl.enableVertexAttribArray( shader.avPosition );

        if( this._bThickness )
        {
            gl.uniform4f( shader.uvColor, this._borderRGBA[0] * alphaParent, this._borderRGBA[1] * alphaParent, this._borderRGBA[2] * alphaParent, this._borderRGBA[3] * alphaParent );
            gl.drawArrays( gl.TRIANGLE_STRIP, 4, 16 ); // outside border starts at 4
        }

        if( this._color )
        {
            var r = this._colorsRGBA[0] * alphaParent;
            var g = this._colorsRGBA[1] * alphaParent;
            var b = this._colorsRGBA[2] * alphaParent;
            var a = this._colorsRGBA[3] * alphaParent;

            gl.uniform4f( shader.uvColor, r, g, b, a );
            gl.drawArrays( gl.TRIANGLE_STRIP, 0, 4 ); // common inside border starts at 0
        }
    };

    RectWidget.prototype.resourcesLoad = function()
    {
        var i, str;
        var gl, w, h, verts, border;

        Widget.prototype.resourcesLoad.call( this );

        if( _x2._config._render === Config.RENDER_DOM )
        {
            this._div.style.backgroundColor = this._color;

            if( this._bThickness )
            {
                switch( typeof( this._bThickness ) )
                {
                    case "number":
                        this._div.style.border = this._bThickness + "px solid " + this._bColor;
                        break;

                    case "object":
                        str = "";

                        for( i = 0; i < this._bThickness.length; i++ )
                            str += this._bThickness[i] + "px ";

                        this._div.style.borderWidth = str;
                        this._div.style.borderColor = this._bColor;
                        this._div.style.borderStyle = "solid";
                        break;
                }
            }
        }
        else if( _x2._config._render === Config.RENDER_WEBGL )
        {
            gl = Widget.gl;
            w  = this._curVal[Widget.W];
            h  = this._curVal[Widget.H];

            /*
                Three cases:

                1) Inside only
                2) Border only
                3) Border and Inside

                Regions common to all cases:

                       +-------+-----------------+-----+
                       |.......|\\\\\\\ T \\\\\\\|!!!!!|
                       +.......+-----------------+!!!!!+
                       |.......|/////////////////|! S !|
                       |...Q...|/////// U ///////|!!!!!| U    = inside
                       |.......|/////////////////|!!!!!| QRST = border
                       +.......+-----------------+-----+
                       |.......|============ R ========|
                       +-------+-----------------+-----+

                 x0,y0       x1,y0             x2,y0  x3,y0

                       A-------B\----------------J-----I y0  ^
                       |      /|        \        |    /|     border top
                 x0,y1 |     / L________________\K   / | y1  v
                       |    /  |                /|  /  |     ^
                       |   /   :        /        | /   |     height
                       |  /    |/                |/    |     v
                 x0,y2 | /     E\----------------H-----G y2  ^
                       |/      |             \         |     border bottom
                       C_______D______________________\F y3  v

                 x0,y3       x1,y3             x2,y3  x3,y3

                      |<border>|<------ w ------>|<-b->|
                      x0     x1                x2     x3
                      Left Border Thickness      Right Border Thickness
            */
            if( this._bThickness ) // Cases 2) Border Only & 3) Border and Inside
            {
                this._dataPosition = new Float32Array( 40 );

                border = this.getBorderArray();

                var x0 = 0;
                var x1 =     border[3];
                var x2 = w + border[3];
                var x3 = w + border[3] + border[1];
                var y0 = 0;
                var y1 =     border[0];
                var y2 = h + border[0];
                var y3 = h + border[0] + border[2];

                // Case 2) Border only
                // Case 3) Border and Inside
                // NOTE: Vertex order of first 8 match the non-border
                //       as the inside rect is common for all 3 cases
                // For case 2) we don't draw the inside region
                verts =
                [
                    x1, y1, // [ 0] L \
                    x2, y1, // [ 1] K  \ U inside rect
                    x1, y2, // [ 2] E  /
                    x2, y2, // [ 3] H /

                    x0, y0, // [ 4] A \
                    x1, y0, // [ 5] B  \
                    x0, y3, // [ 6] C  / Q
                    x1, y3, // [ 7] D /
                    x1, y3, // [ 8] D \
                    x1, y2, // [ 9] E  \
                    x3, y3, // [10] F  / R
                    x3, y2, // [11] G /
                    x3, y2, // [12] G \
                    x2, y2, // [13] H  \
                    x3, y0, // [14] I  / S
                    x2, y0, // [15] J /
                    x2, y0, // [16] J \
                    x2, y1, // [17] K  \
                    x1, y0, // [18] B  / T
                    x1, y1  // [19] L /
                ];          // =16 outside rect
            }
            else // Case 1
            {
                // if thickness === undefined or thickness === 0
                verts =
                [
                    0, 0, // A
                    w, 0, // I
                    0, h, // C
                    w, h  // F
                ];
            }

            this._dataPosition = new Float32Array( verts );
            this._buffPosition = gl.createBuffer();

            gl.bindBuffer( gl.ARRAY_BUFFER, this._buffPosition );
            gl.bufferData( gl.ARRAY_BUFFER, this._dataPosition, gl.DYNAMIC_DRAW ); // Dynamic

            if( this._color ) // Case 1 & 3
                this._colorsRGBA = Widget.glColorHexToFloat( this._color );

            if( this._bColor ) // Case 2 & 3
                this._borderRGBA = Widget.glColorHexToFloat( this._bColor );
        }
    };

    RectWidget.prototype.setColor = function( color )
    {
        this._color = color;

        if( _x2._config._render === Config.RENDER_DOM )
            this._div.style.backgroundColor = color;
        else if( _x2._config._render === Config.RENDER_WEBGL )
            this._colorsRGBA = Widget.glColorHexToFloat( color );
    };

    return RectWidget;

})();
