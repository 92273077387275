// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.LruCache = (function()
{
    "use strict";

    function LruCache( maxSize )
    {
console.log( "******************** LruCache CREATED - " + maxSize + " ********************" );
        this._maxSize = maxSize;
        this._size    = 0;
        this._map     = {};
    }

    LruCache.prototype.dump = function()
    {
        console.log( this._map );
        console.log( this._size + " of " + this._maxSize );

        var str = "", curr = this._head;

        str += "head => ";

        while( curr )
        {
            str += curr.obj + " ";

            if( curr._next && curr._next._prev && curr._next._prev === curr )
                str += "<";

            if( curr._next )
                str += "=> ";

            curr = (this._head !== curr._next) ? curr._next : undefined;
        }

        console.log( str );
    };

    LruCache.prototype.get = function( key )
    {
        var retval, entry;

        entry = this.remove( key );

        if( entry )
        {
            retval = entry.obj;

            this.put( key, entry.obj, entry.size );
        }

        return retval;
    };

    LruCache.prototype.put = function( key, obj, size )
    {
        var retval;
        var entry = this._map[key];

        if( entry )
        {
            retval = entry.obj;
            this.remove( key );

            entry.obj  = obj;
            entry.size = size;
        }

console.log( "this._size = " + this._size + ", size = " + size + ", this._maxSize = " + this._maxSize );

        while( (this._size + size > this._maxSize) && this._head )
            this.remove( this._head._prev.key );

        if( entry === undefined )
            entry = { key:key, obj:obj, size:size };

        this._size     += size;
        this._map[key]  = entry;

        if( this._head === undefined )
        {
            this._head = entry;

            entry._prev = entry;
            entry._next = entry;
        }
        else
        {
            entry._next = this._head;
            entry._prev = this._head._prev;

            this._head._prev._next = entry;
            this._head._prev = entry;

            this._head = entry;
        }

        return retval;
    };

    LruCache.prototype.remove = function( key )
    {
        var retval, entry = this._map[key];

        if( entry )
        {
            retval = entry;
            this._size -= entry.size;
            delete this._map[key];

            // single entry remove

            if( entry === entry._next )
            {
                this._head  = undefined;
                entry._prev = undefined;
                entry._next = undefined;
            }
            else
            {
                entry._prev._next = entry._next;
                entry._next._prev = entry._prev;

                if( entry === this._head )
                    this._head = entry._next;
            }
        }
        else
            console.log( "No entry found for - " + key );

        return retval;
    };

    LruCache.prototype.size = function()
    {

    };

    return LruCache;

})();
