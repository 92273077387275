// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.MiniGridRowWidget = ( function()
{
    "use strict";

    MiniGridRowWidget.prototype = new ListRowWidget();

    function MiniGridRowWidget(){}

    MiniGridRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 121 * _x2._config._text );
    };

    MiniGridRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        Widget.prototype.gotFocus.call( this );
        this.setHighlight();
        this._chanStr.gotFocus();
        this._title.gotFocus();
        this._title.setSpeechParams( ". " + _x2.createTimeSpeechStr( this._time.getText() ) + ". Channel " + this._chanStr.getText()+ ". " + this._data.getCallSign() + ". " , undefined, this, newScreen );

        return this;
    };

    /**
     * Initializer
     * @memberof MiniGridRowWidget
     * @param   {Object}    params
     * @param   {Object}    params.obj - The guide screen that led this overlay so the selection cn be returned
     * @param   {Number}    params.w   - Width of the list
     * @returns {MiniGridRowWidget}
     * @see ListRowWidget
     */
    MiniGridRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );
        this._className   = "MiniGridRowWidget";
        this._selectable  = true;
        this._numElements = 0;
        this._maxLogoH    = _x2.scaleValInt( 72 );
        this._maxLogoW    = _x2.scaleValInt( 72 );
        this._controller  = params.obj;

        var self       = this, layout, onFadeLoaded, onLogoLoaded, onLogoFailed;
        var chanStyle  = new Style( { color:"#a3a3a3", font:"medium" , fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#2b9cd8" } );
        var titleStyle = new Style( { color:"#e8e8e8", font:"regular", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#2b9cd8", overflow:"hidden" } );
        var timeStyle  = new Style( { color:"#a3a3a3", font:"medium" , fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", colorHi:"#2b9cd8" } );
        var onReady    = function() { self._numElements++; layout(); };

        layout = function()
        {
            self._chanColW = self._chanStr.getX() + self._chanStr.getW() + Style._pad;

            self._sep.setX( self._chanColW );

            self._chanStr.setY( (self.getHeightUnfocused() - self._chanStr.getH()) / 2 );
            self._name.setY( (self.getHeightUnfocused() - self._name.getH()) / 2 );

            self._title.setX( self._chanColW + _x2.scaleValInt( 36 ) );
            self._title.setY( (self.getHeightUnfocused() - ListRowWidget.DIVIDER_H - self._title.getH() - self._time.getH() - Style._pad) / 2 );

            self._time.setX( self._title.getX() );
            self._time.setY( self._title.getY() + self._title.getH() + Style._pad );
        };

        onFadeLoaded = function()
        {
            self._fade.setX( params.w - self._fade.getW() );
            self._fade.setW( self._fade.getW() );
            self._fade.setH( self.getHeightUnfocused() );
        };

        onLogoLoaded = function()
        {
            var dw, dh, scale;
            var w = self._logo.getW();
            var h = self._logo.getH();

            if( w > 0 && h > 0 )
            {
                dw    = self._maxLogoW / w;
                dh    = self._maxLogoH / h;
                scale = ( dh < dw ) ? dh : dw;

                self._logo.setX( (self._maxLogoW            - self._logo.getW()) / 2 );
                self._logo.setY( (self.getHeightUnfocused() - self._logo.getH()) / 2 );
                self._logo.setScale( scale );
                self._logo.setA( 1 );
            }
        };

        onLogoFailed = function()
        {
            self._name.setA( 1 );
        };

        this._logo = new ImageWidget().init( { onChange:onLogoLoaded, onError:onLogoFailed } );
        this.addWidget( this._logo );

        this._chanStr = new StringWidget().init( { text:"0000", style:chanStyle } );
        this._chanStr.addReadyCallback( onReady );
        this.addWidget( this._chanStr, this._maxLogoW + _x2.scaleValInt( 62 ) );

        this._sep = new RectWidget().init( { w:ListRowWidget.DIVIDER_H, h:this.getHeightUnfocused(), color:"#262626" } );
        this.addWidget( this._sep );

        this._title = new StringWidget().init( { text:"????", style:titleStyle } );
        this.addWidget( this._title );

        this._time = new StringWidget().init( { text:"????", style:timeStyle } );
        this.addWidget( this._time );

        this._fade = new ImageWidget().init( { url:_x2._config._imageRoot + "textFade2.png", onLoad:onFadeLoaded } );
        this.addWidget( this._fade );

        this._name = new StringWidget().init( { text:"???????", style:chanStyle } );
        this.addWidget( this._name );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        return this;
    };

    MiniGridRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );

        this._chanStr.lostFocus();
        this._title.lostFocus();
    };

    MiniGridRowWidget.prototype.processEvent = function( val, type )
    {
        var retval, self = this;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    var doTune = function( chan, sched )
                    {
                        self._controller.setTuneToChannel( self._data, sched );
                    };

                    var i, schedules = this._data.getSchedules(), now = new Date().getTime();

                    if( schedules )
                        for( i = 0; i < schedules.length; i++ )
                            if( schedules[i].getEndTime() > now )
                                break;


                    if( _x2._pc.isEnabled() === true )
                        _x2.checkParentalControls( schedules ? schedules[i] : undefined, undefined, this._data ).then( function() { doTune( self._data, schedules[i] ); } );
                    else
                        doTune( self._data, schedules[i] );

                    retval = true;
                }
                break;
        }

        if( retval === undefined )
            retval = ListRowWidget.prototype.processEvent.call( this, val, type );

        return retval;
    };

    MiniGridRowWidget.prototype.setData = function( data )
    {
        this._data = data;

        if( data !== undefined )
        {
            var now       = new Date().getTime();
            var schedules = data.getSchedules(), sched;

            for( var i = 0; i < schedules.length; i++ )
            {
                if( schedules[i].getEndTime() > now )
                {
                    this._sched = sched = schedules[i];
                    break;
                }
            }

            this._chanStr.setText( data.getNumber() );
            this._name.setText( data.getCallSign() );
            this._name.setA( 0 );

            if( sched )
            {
                this._title.setText( sched.getGridTitle() );
                this._time.setText( _x2.createTimeRange( new Date( sched.getStartTime() ), new Date( sched.getEndTime() ) ) );
            }
            else
                console.log( data );

            this._logo.setA( 0 );
            this._logo.setW();
            this._logo.setH();
            this._logo.setUrl( data.getLogoUrl(50,30) );
            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    MiniGridRowWidget.prototype.setHighlight = function( time, h )
    {
        var obj = this.getGlobalPos();

        obj.w        = this.getW();
        obj.h        = (h === undefined) ? this.getHeightUnfocused() - ListRowWidget.DIVIDER_H : h;
        obj.inner    = false;
        obj.duration = (time === undefined) ? ListWidget.SCROLL_TIME_STEP : time;

        _x2._hi.setPosition( obj );
    };

    return MiniGridRowWidget;

})();
