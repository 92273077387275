// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * Visual to show when loading/waiting for data
 * @class
 */
window.DistractorWidget = ( function()
{
    "use strict";

    DistractorWidget.prototype = new Widget();

    function DistractorWidget(){}

    /**
     * @memberof DistractorWidget
     * @param {Widget} widget - which widget to center on
     */
    DistractorWidget.prototype.centerOnWidget = function( widget )
    {
        if( widget )
        {
            this.setX( (widget.getW() - this.getW()) / 2 );
            this.setY( (widget.getH() - this.getH()) / 2 );
        }
    };

    /**
     * @memberof DistractorWidget
     */
    DistractorWidget.prototype.centerOnScreen = function()
    {
        var pos = this.getGlobalPos();
        this.setX( _x2._config._screenW / 2 - pos.x - this.getW() / 2 );
        this.setY( _x2._config._screenH / 2 - pos.y - this.getH() / 2 );
    };

    /** Hide the distractor
     * @memberof DistractorWidget
     */
    DistractorWidget.prototype.hide = function()
    {
        if( this._showing === true )
        {
            this._showing = false;
            this._repeat  = false;

            if( this.getA() === 0 )
                this.stopAnimation();
            else
                this.animate( { a:0, duration:500 } );
        }
    };

    /**
     * Initializer
     * @memberof DistractorWidget
     * @returns {DistractorWidget}
     * @see RingDistractorWidget
     */
    DistractorWidget.prototype.init = function( params )
    {
        var self = this, imageLoaded;

        Widget.prototype.init.call( this );
        this._className = "DistractorWidget";
        this._numLoaded = 0;
        this._showing   = false;

        imageLoaded = function()
        {
            self._numLoaded++;
            self.layout();
        };

        this._dotScale = 0.6666667;
        this._fadeTime = 500;
        this._offset   = 250;
        this._repeat   = true;

        if( params === undefined || params.hideBg !== true )
        {
            this._bg   = new ImageWidget().init( { url:_x2._config._imageRoot + "loaderBg.png", onLoad:imageLoaded } );
            this.addWidget( this._bg   );
        }


        this._dot0 = new ImageWidget().init( { url: _x2._config._imageRoot + "loaderDot.png", onLoad:imageLoaded } );
        this._dot1 = new ImageWidget().init( { url: _x2._config._imageRoot + "loaderDot.png", onLoad:imageLoaded } );
        this._dot2 = new ImageWidget().init( { url: _x2._config._imageRoot + "loaderDot.png", xScale:0.2, yScale:0.2, onLoad:imageLoaded } );

        this._dot0.setA( 0 );
        this._dot1.setA( 0 );
        this._dot2.setA( 0 );

        this._dot0.setScale( this._dotScale );
        this._dot1.setScale( this._dotScale );
        this._dot2.setScale( this._dotScale );

        this.addWidget( this._dot0 );
        this.addWidget( this._dot1 );
        this.addWidget( this._dot2 );

        this.setA( 0 );

        return this;
    };

    DistractorWidget.prototype.layout = function()
    {
        if( this._numLoaded === (this._bg ? 4 : 3) )
        {
            var y = ( this._bg ) ? (this._bg.getH() - this._dot1.getH()) / 2 : 0;
            var x = ( this._bg ) ? (this._bg.getW() - this._dot1.getW()) / 2 : (3 * this._dot1.getW() + _x2.scaleVal( 6 )) / 2;

            this._dot1.setX( x );
            this._dot0.setX( this._dot1.getX() - this._dot0.getW() - _x2.scaleVal( 6 ) );
            this._dot2.setX( this._dot1.getX() + this._dot0.getW() + _x2.scaleVal( 6 ) );

            this._dot0.setY( y );
            this._dot1.setY( y );
            this._dot2.setY( y );

            this.setW( this._bg ? this._bg.getW() : this._dot2.getX() + this._dot2.getW() );
            this.setH( this._bg ? this._bg.getH() : this._dot2.getY() + this._dot2.getH() );

            this._ready = true;

            if( this._showing === true )
            {
                this._showing = false;
                this.show( this._isStatic );
            }

            this.callbackSignalReady();
        }
    };

    /**
     * Show the distractor
     * @memberof DistractorWidget
     */
    DistractorWidget.prototype.show = function( isStatic )
    {
        this._isStatic = isStatic;

        if( this._showing === false )
        {
            this._showing = true;

            if( this._ready === true )
            {
                if( isStatic !== true )
                {
                    var self    = this, sequence, fadeOutRestart;
                    var fadeOut = { a:0, xScale:this._dotScale, yScale:this._dotScale, duration:this._fadeTime, easing:Widget.Easing.QUAD_IN };

                    this._showing = true;
                    this._repeat = true;

                    sequence = function()
                    {
                        fadeOutRestart.onEnd = (self._repeat === true) ? sequence : undefined;

                        self._dot0.animate( { a:1, xScale:1.0, yScale:1.0, duration:self._fadeTime, easing:Widget.Easing.QUAD_OUT, onEnd:function() { self._dot0.animate( fadeOut ); } } );
                        self._dot1.animate( { a:1, xScale:1.0, yScale:1.0, duration:self._fadeTime, easing:Widget.Easing.QUAD_OUT, onEnd:function() { self._dot1.animate( fadeOut ); }, start:self._offset } );
                        self._dot2.animate( { a:1, xScale:1.0, yScale:1.0, duration:self._fadeTime, easing:Widget.Easing.QUAD_OUT, onEnd:function() { self._dot2.animate( fadeOutRestart ); }, onEndAxis:Widget.ALPHA, start:2*self._offset } );
                    };

                    fadeOutRestart = { a:0, xScale:this._dotScale, yScale:this._dotScale, duration:this._fadeTime, easing:Widget.Easing.QUAD_OUT, onEnd:sequence, onEndAxis:Widget.ALPHA };

                    this.animate( { a:1, duration:500, onEnd:sequence } );
                }
                else
                {
                    this._dot0.setA( 0.33 );
                    this._dot1.setA( 0.66 );
                    this._dot2.setA( 1.00 );
                    this.setA( 1 );
                }
            }
        }
    };

    return DistractorWidget;

})();
