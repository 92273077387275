// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.NotEntitledScreen = ( function()
{
    "use strict";

    NotEntitledScreen.prototype = new Screen();

    function NotEntitledScreen(){}

    NotEntitledScreen.prototype.gotFocus = function()
    {
        _x2.requestFocus( this._help );
    };

    NotEntitledScreen.prototype.init = function()
    {
        Screen.prototype.init.call( this, { noBg:false, noHeader:true, noSep:true } );
        this._className     = "NotEntitledScreen";
        this._telemetryName = "Not Entitled";

        this._help = new HelpOverlay().init( true );
        this.addWidget( this._help );

        _x2._config._host.removeAllTokenLocalStorage();
        _x2._config._host.removeLocalStorage( "features"     );

        return this;
    };

    NotEntitledScreen.prototype.processEvent = function( val, type )
    {
        if( val === Host.KEY_LAST && type === Host.KEY_PRESSED && this._sentAuthorize !== true )
        {
            this._sentAuthorize = true;
            _x2.authorize();
        }

        return true;
    };

    return NotEntitledScreen;

})();
