// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.VodGridRowWidget = (function()
{
    "use strict";

    VodGridRowWidget.prototype = new ListRowWidget();

    function VodGridRowWidget(){}

    VodGridRowWidget.prototype.gotFocus = function( lastFocus, newScreen )
    {
        if( lastFocus && lastFocus.getIndex )
            this._selectedIndex = lastFocus.getIndex();

        if( this._data )
        {
            if( this._selectedIndex >= this._data.length - 1 )
                this._selectedIndex = this._data.length - 1;
        }

        return _x2.requestFocus( this._cells[this._selectedIndex], newScreen );
    };

    VodGridRowWidget.prototype.init = function( params )
    {
        params.sep = false;
        ListRowWidget.prototype.init.call( this, params );
        this._className = "VodGridRowWidget";

        this.initCells( params, XtvApi.EntityRenderStyle.POSTER );
        this.setA( 0 );

        return this;
    };

    VodGridRowWidget.prototype.initCells = function( params, style )
    {
        this._columns       = params.obj.getColumnCount();
        this._cells         = [];
        this._cellW         = Math.floor( (_x2._config._screenW - Style._safeLeft - Style._safeRight) / this._columns );
        this._selectedIndex = 0;

        for( var i = 0; i < this._columns; i++ )
        {
            var cell = new GalleryCellWidget().init( { sep:false, style:style, collectionType:'grid' } );
            this._cells.push( cell );
            this.addWidget( cell, i * this._cellW, 0 );
        }
    };

    VodGridRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 616 );
    };

    VodGridRowWidget.prototype.processEvent = function( val, type )
    {
        var retval;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                    _x2.pushScreen( VodEntityScreen().init( { entity: this._cells[this._selectedIndex] } ) );
                break;

            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._selectedIndex > 0 )
                    {
                        this._selectedIndex--;
                        if( this._cells[this._selectedIndex] )
                            _x2.requestFocus( this._cells[this._selectedIndex] );
                    }
                }
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._selectedIndex < this._columns - 1 && this._selectedIndex < this._data.length - 1 )
                    {
                        this._selectedIndex++;
                        if( this._cells[this._selectedIndex] )
                            _x2.requestFocus( this._cells[this._selectedIndex] );
                    }
                }
                break;

            default:
                retval = ListRowWidget.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    VodGridRowWidget.prototype.setData = function( entities )
    {
        this._data = entities;

        if( entities !== undefined )
        {
            if( entities && entities.length )
            {
                for( var i = 0; i < this._columns; i++ )
                    this._cells[i].setData( entities[i], i );
            }
            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    return VodGridRowWidget;

})();
