// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.RectColorTestScreen = ( function()
{
    "use strict";

    RectColorTestScreen.prototype = new Screen();

    function RectColorTestScreen(){}

    RectColorTestScreen.prototype.init = function()
    {
        var w = _x2._config._screenW / 4;
        var h = _x2._config._screenH / 4;

        var inside = "#444444";
        var skirt  = "#006666";

        this._className = "RectColorTestScreen";

        Screen.prototype.init.call( this );

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        // Inside only
        this._rect = new RectWidget().init( { w:_x2._config._screenW / 4, h:_x2._config._screenH / 4, color:skirt } );
        this._rect.setVal( Widget.ALPHA, 0.5 );
        this.addWidget( this._rect, Style._safeLeft, Style._safeTop );

        // Inside + Uniform border thickness
        this._borderRect = new RectWidget().init( { w:w - 6, h:h - 6, color:inside, borderColor:skirt, borderThickness:3 } );
        this.addWidget( this._borderRect, Style._safeLeft, Style._safeTop + h + 10 );

        // Inside + Varying border thickness
        this._borderRect2 = new RectWidget().init( { w:w - 18, h:h - 12, color:inside, borderColor:skirt, borderThickness:[ 3, 6, 9, 12] } );
        this.addWidget( this._borderRect2, Style._safeLeft, Style._safeTop + 2*h + 20 );

        // Border only
        this._borderOnly = new RectWidget().init( { w:w - 18, h:h - 12, borderColor:"#00FF00", borderThickness:[ 3, 6, 9, 12] } );
        this.addWidget( this._borderOnly, Style._safeLeft + 0.5*w, Style._safeTop + 0.5*h );

        return this;
    };

    RectColorTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    return RectColorTestScreen;

})();
