// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.TextBlockEllipsisTestScreen = ( function()
{
    "use strict";

    TextBlockEllipsisTestScreen.prototype = new Screen();

    function TextBlockEllipsisTestScreen(){}

    TextBlockEllipsisTestScreen.prototype.init = function()
    {
        this._className = "TextBlockEllipsisTestScreen";
        Screen.prototype.init.call( this );

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        var descStyle = new Style( { font: "light"  , color: "#e8e8e8", fontSize: _x2.scaleValInt( 27 ), overflow:"hidden", textOverflow:"ellipsis" } );
        this._width   = 667; // w: divX - titleX - _x2.scaleValInt( 32 )
        this._text    = new TextBlockWidget().init( { style:descStyle, w:this._width, h:100, ellipsis:true } );
        this.addWidget( this._text, Style._safeLeft, Style._safeTop );

        this._selectable = true;

        return this;    
    };

    TextBlockEllipsisTestScreen.prototype.layout = function()
    {
        this._text.setY( 94 );
        this._text.setH( 175 );
        this._text.setText( "<font color='#a3a3a3'>Peter Dinklage, Nikolaj Coster-Waldau, Lena Headey</font><br>(2011-) George R.R. Martin's best-selling book series \"A Song of Ice and Fire\" is brought to the screen as HBO sinks its considerable storytelling teeth into the medieval fantasy epic. It's the depiction of two powerful families -- kings and queens, knights and renegades, liars and honest men -- playing a deadly game for control of the Seven Kingdoms of Westeros, and to sit atop the Iron Throne. Martin is credited as a co-executive producer and one of the writers for the series, whose shooting locations include Northern Ireland, Malta, Croatia and Spain." );
    };
    
    TextBlockEllipsisTestScreen.prototype.resourcesLoaded = function()
    {
        Widget.prototype.resourcesLoaded.call( this );

        this.layout();
    };

    TextBlockEllipsisTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = Screen.prototype.processEvent.call( this, val, type );

        return retval;
    };

    TextBlockEllipsisTestScreen.prototype.updateWidth = function()
    {
    };

    return TextBlockEllipsisTestScreen;

})();

