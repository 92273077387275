// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PinPadWidget = ( function()
{
    "use strict";

    PinPadWidget.prototype = new Widget();

    function PinPadWidget(){}

    PinPadWidget.prototype.clear = function()
    {
        this._index = 0;

        for( var i = 0; i < this._pinChars.length; i++ )
            this._pinChars[i].setA( 0 );
    };

    PinPadWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        var index = this._yIndex * 3 + this._xIndex;

        _x2._hi.fadeOut();
        this._hi.animate( { a:1, duration:250 } );
        this._chars[index].gotFocus();
        this._chars[index].setSpeechParams( "Press arrow keys to review characters. Press OK to select. Press back to exit. ", undefined, this, newScreen );

        return this;
    };

    /**
     * Initializer
     * @memberof PinPadWidget
     * @param   {Object}   params
     * @param   {Function} params.onCapture - Called when the pin has been captured
     * @returns {PinPadWidget}
     */
    PinPadWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this, params );
        this._className  = "PinPadWidget";
        this._selectable = true;

        var self      = this, i;
        var cellW     = _x2.scaleValInt( 183 );
        var cellH     = _x2.scaleValInt(  84 );
        var spacing   = Style._pad / 3;
        var charStyle = new Style( { font:"light", color:"#e8e8e8", colorHi:"#000000", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", textAlign:"center" } );
        var pinStyle  = new Style( { font:"light", color:"#e8e8e8", colorHi:"#000000", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap", textAlign:"center" } );

        this._numReady  = 0;
        this._onCapture = params.onCapture;
        this._index     = 0;
        this._xIndex    = 0;
        this._yIndex    = 0;
        this._pinChars  = new Array(  4 );
        this._digits    = new Array(  4 );
        this._rects     = new Array( 12 );
        this._chars     = new Array( 12 );

        this._pin = new RectWidget().init( { w:3 * cellW + 2 * spacing, h:_x2.scaleValInt( 80 ), color:"#262626" } );
        this.addWidget( this._pin );

        for( i = 0; i < this._pinChars.length; i++ )
        {
            this._pinChars[i] = new StringWidget().init( { text:"*", style:pinStyle } );
            this._pinChars[i].setA( 0 );
            this.addWidget( this._pinChars[i] );
        }

        this._instStr = new StringWidget().init( { text:"Enter PIN", style:new Style( { font:"light", color:"#a3a3a3", fontSize:_x2.scaleValInt(32) } ) } );
        this.addWidget( this._instStr, _x2.scaleValInt(16) );

        for( i = 0; i < this._rects.length; i++ )
        {
            this._rects[i] = new RectWidget().init( { w:cellW, h:cellH, color:"#191919" } );
            this.addWidget( this._rects[i], (i % 3) * (cellW + spacing), Math.floor(i / 3) * (cellH + spacing) + this._pin.getH() + _x2.scaleValInt( 24 ) );
        }

        this._hi = new RectWidget().init( { w:cellW, h:cellH, color:"#e8e8e8" } );
        this._hi.setA( 0 );
        this.addWidget( this._hi, this._rects[0].getX(), this._rects[0].getY() );

        for( i = 0; i < this._chars.length; i++ )
        {
            if( i === 9 )
                this._chars[i] = new DualImageWidget().init( { url:_x2._config._imageRoot + "clearIcon.png" , urlHi:_x2._config._imageRoot + "clearIconHi.png"  } );
            else if( i === 11 )
                this._chars[i] = new DualImageWidget().init( { url:_x2._config._imageRoot + "deleteIcon.png", urlHi:_x2._config._imageRoot + "deleteIconHi.png" } );
            else
            {
                this._chars[i] = new StringWidget().init( { style:charStyle, focusTime:250 } );
                this._chars[i].setW( cellW );
            }

            this.addWidget( this._chars[i], this._rects[i].getX() );
        }

        this._chars[ 0].addReadyCallback( function() { self.layout() } );
        this._chars[ 9].addReadyCallback( function() { self.layout() } );
        this._chars[11].addReadyCallback( function() { self.layout() } );

        this._chars[ 0].setText( "1" );
        this._chars[ 1].setText( "2" );
        this._chars[ 2].setText( "3" );
        this._chars[ 3].setText( "4" );
        this._chars[ 4].setText( "5" );
        this._chars[ 5].setText( "6" );
        this._chars[ 6].setText( "7" );
        this._chars[ 7].setText( "8" );
        this._chars[ 8].setText( "9" );
        this._chars[10].setText( "0" );

        this.setW( 3 * cellW + 2 * spacing );
        this.setH( this._rects[11].getY() + this._rects[11].getH() );

        this._speechStr = [];
        this._speechStr[ 9] = "Clear. ";
        this._speechStr[11] = "Delete. ";

        return this;
    };

    PinPadWidget.prototype.layout = function()
    {
        if( ++this._numReady === 3 )
        {
            var i;
            var start = (this._pin.getW() - 4 * this._pinChars[0].getW() - 12 * Style._pad) / 2;
            var step  = this._pinChars[0].getW() + 4 * Style._pad;
            var self = this;

            for( i = 0; i < this._pinChars.length; i++ )
            {
                this._pinChars[i].setX( start + i * step );
                this._pinChars[i].setY( (this._pin.getH() - this._pinChars[i].getH()) / 1.1 );
            }

            this._instStr.setY( (this._pin.getH() - this._instStr.getH()) / 2 );

            for( i = 0; i < this._chars.length; i++ )
            {
                if( i === 9 || i === 11 )
                    this._chars[i].setX( this._rects[i].getX() + (this._rects[i].getW() - this._chars[i].getW()) / 2 );

                this._rects[i]._div.setAttribute("data-index", i );
                this._chars[i].setY( this._rects[i].getY() + (this._rects[i].getH() - this._chars[i].getH()) / 2 );
                this._rects[i].setMouseDownListener( function( event ) { 
                    event.stopPropagation(); 
                    var currentIndex = parseInt(event.target.getAttribute('data-index'));
                    self._yIndex = Math.floor( currentIndex / 3 );
                    self._xIndex = Math.floor( currentIndex % 3 );
                    self.processEvent( Host.KEY_ENTER, Host.KEY_PRESSED ); 
                } );
                this._rects[i].setMouseEnterListener( function( event, i) { 
                    for( var i = 0; i < self._chars.length; i++ )
                        self._chars[i].lostFocus();
                    
                    var pinPadIndex = parseInt(event.target.getAttribute('data-index'));
                    self._hi.animate( { x: self._rects[pinPadIndex].getX(), y: self._rects[pinPadIndex].getY(), duration:250 } );
                    self._chars[pinPadIndex].gotFocus();
                } );
            }

            this.callbackSignalReady();
        }
    };

    PinPadWidget.prototype.processEvent = function( val, type )
    {
        var retval = true, keyVal, startIndex;
        var index  = this._yIndex * 3 + this._xIndex;
        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    startIndex = this._index;

                    if( index === 9 )
                    {
                        this._chars[index].setSpeechParams( "Cleared. ", undefined, this, false );

                        this._index = 0;

                        for( var i = 0; i < this._pinChars.length; i++ )
                            this._pinChars[i].setA( 0 );
                    }
                    else if( index === 11 )
                    {
                        this._chars[index].setSpeechParams( "Deleted. ", undefined, this, false );

                        if( this._index > 0 )
                        {
                            this._index--;
                            this._pinChars[this._index].setA( 0 );
                        }
                    }
                    else
                    {
                        keyVal = (index < 9) ? index+1 : 0;
                        this._pinChars[this._index].setA( 1 );
                        this._pinChars[this._index].setSpeechParams( "Star", undefined, this, false );
                        this._digits[this._index++] = keyVal;

                        if( this._index >= 4 ) {
                            console.log( this._digits );
                            this._onCapture( this._digits.join( "" ) );
                        }
                    }

                    console.log( "this._index = " + this._index );

                    if( startIndex > 0 && this._index === 0 )
                        this._instStr.animate( { a:1, duration:500 } );
                    else if( startIndex === 0 && this._index > 0 )
                        this._instStr.animate( { a:0, duration:500 } );
                }
                break;

            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._yIndex < 3 )
                    {
                        this._chars[index].lostFocus();
                        this._yIndex++;
                        this._hi.animate( { y:this._rects[this._yIndex * 3].getY(), duration:250 } );
                        index  = this._yIndex * 3 + this._xIndex;
                        this._chars[index].gotFocus();
                        this._chars[index].setSpeechParams( this._speechStr[index], undefined, this, false );
                    }
                }
                break;

            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._xIndex > 0 )
                    {
                        this._chars[index].lostFocus();
                        this._xIndex--;
                        this._hi.animate( { x:this._rects[this._xIndex].getX(), duration:250 } );
                        index  = this._yIndex * 3 + this._xIndex;
                        this._chars[index].gotFocus();
                        this._chars[index].setSpeechParams( this._speechStr[index], undefined, this, false );
                    }
                }
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._xIndex < 2 )
                    {
                        this._chars[index].lostFocus();
                        this._xIndex++;
                        this._hi.animate( { x:this._rects[this._xIndex].getX(), duration:250 } );
                        index  = this._yIndex * 3 + this._xIndex;
                        this._chars[index].gotFocus();
                        this._chars[index].setSpeechParams( this._speechStr[index], undefined, this, false );
                    }
                }
                break;

            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._yIndex > 0 )
                    {
                        this._chars[index].lostFocus();
                        this._yIndex--;
                        this._hi.animate( { y:this._rects[this._yIndex * 3].getY(), duration:250 } );
                        index  = this._yIndex * 3 + this._xIndex;
                        this._chars[this._yIndex * 3 + this._xIndex].gotFocus();
                        this._chars[index].setSpeechParams( this._speechStr[index], undefined, this, false );
                    }
                }
                break;

            default:
                retval = Widget.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval
    };

    return PinPadWidget;

})();
