// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Represents a recorded instance of a {@link LinearShowing}.
 *
 * Maps to XTV "s:VideoObject/Recording"
 */
window.Recording = (function()
{
    "use strict";

    Recording.prototype = new LinearShowing();

    function Recording(){}

    /**
     * Initialize the object
     * @param {Object} data - XTV derived JSON object
     * @return {Recording}
     */
    Recording.prototype.init = function( data )
    {
        LinearShowing.prototype.init.call( this, data );
        return this;
    };

    /**
     * Delete the recording.
     * @memberOf Recording
     * @return {Promise}
     */
    Recording.prototype.delete = function()
    {
        var deleteForm = this.getForm("delete");

        if( deleteForm )
            return deleteForm.submit();
        else
            return Promise.reject( new ApiError().init( { local:"Recording.delete: There is no delete form for this entity"} ) );
    };

    Recording.prototype.getCancelForm = function()
    {
        if( ! this._cancelForm )
            this._cancelForm = this.getForm("cancel");

        return this._cancelForm;
    };

    /**
     * Get the channel associated with the recording
     * @memberOf Recording
     * @return {Channel}
     */
    Recording.prototype.getChannel = function()
    {
        if( !this._channel )
            this._channel = new Channel().init( this.getEmbedded("provider/channel" ) );

        return this._channel;
    };

    Recording.prototype.getCheckedOutDeviceName = function()
    {
        return this.getPath(["checkoutStatus","byDeviceName"]);
    };

    Recording.prototype.getDeleteForm = function()
    {
        if( ! this._deleteForm )
            this._deleteForm = this.getForm("delete");

        return this._deleteForm;
    };

    Recording.prototype.getDurationMs = function()
    {
        var entity, duration = this.getInteger("duration");

        if( !duration )
        {
            entity = this.getEntity();
            if( entity )
                duration = entity.getDuration() * 60000;
        }

        return duration;
    };

    Recording.prototype.getEraseForm = function()
    {
        if( ! this._eraseForm )
            this._eraseForm = this.getForm("erase");

        return this._eraseForm;
    };

    /**
     * Get the fallback image link from the program or series
     * @param width
     * @param height
     * @return {*}
     */
    Recording.prototype.getFallbackImageLink = function(width, height)
    {
        if( this._series )
            return this._series.getFallbackImageLink(width, height);
        else if( this.getProgram() )
            return this.getProgram().getFallbackImageLink(width, height);
    };

    /**
     * Get the recording ID
     * @memberOf Recording
     * @return {String}
     */
    Recording.prototype.getId = function()
    {
        return this.get("id");
    };

    /**
     * Get the image link for this Recording from the program or series
     * @memberOf Recording
     * @param width
     * @param height
     * @return {*}
     */
    Recording.prototype.getImageLink = function(width, height)
    {
        if( this._series && this._series.getImageLink )
            return this._series.getImageLink(width, height);
        else if( this.getProgram() )
            return this.getProgram().getImageLink(width, height);
        else //Note: some recordings come back from XTVAPI without a entity embedded.
            return "https://edge.myriad-gn-xcr.xcr.comcast.net/select/image?priority=true&entityId=" + this.getEntityId() + "&width=" + width + "&height=" + height;
    };

    Recording.prototype.getModifyForm = function()
    {
        if( ! this._modForm )
            this._modForm = this.getForm("modify");

        return this._modForm;
    };

    Recording.prototype.getUndeleteForm = function()
    {
        if( ! this._undeleteForm )
            this._undeleteForm = this.getForm("undeleteForm");

        return this._undeleteForm;
    };

    /**
     * Get the date of the recording
     * @memberOf Recording
     * @return {Date|undefined}
     */
    Recording.prototype.getRecordedOnDate = function()
    {
        var date = this.getByPriority( "dateRecorded", "lastRecorded");
        if( date )
            return new Date(date);

        return undefined;
    };

    /**
     * Returns the recording progress as an integer (0-100)
     * @return {number}
     */
    Recording.prototype.getRecordingProgress = function()
    {
        var duration,
            progress  = -1,
            startedOn = this.getDate("dateRecorded");
            status    = this.getRecordingStatus();

        if( status === 'STARTED' )
        {
            if( startedOn )
            {
                duration = this.getDurationMs();
                progress = Math.round( ( ( Date.now() - startedOn.getTime() ) / duration ) * 100 ) ;
            }
        }

        progress = ( progress < 100 ? progress  : 100      );
        progress = ( progress < 0   ? undefined : progress );

        return progress;
    };

    /**
     * Get the recording status
     * @memberOf Recording
     * @return {String}
     */
    Recording.prototype.getRecordingStatus = function()
    {
        //TODO: constants
        return this.get("recordingStatus");
    };

    /**
     * Get the recording type
     * @memberOf Recording
     * @return {String}
     */
    Recording.prototype.getRecordingType = function()
    {
        return this.get("recordingType");
    };

    /**
     * Get the season number assoc'd with this program.
     * @return {Number|undefined}
     */
    Recording.prototype.getSeasonNumber = function()
    {
        if( this.getProgram() )
            return this.getProgram().getSeasonNumber();

        return undefined;
    };

    Recording.prototype.getScheduleForm = function()
    {
        if( ! this._scheduleForm )
            this._scheduleForm = this.getForm("schedule");

        return this._scheduleForm;
    };

    /**
     * Get the series id for this program
     * @return {String}
     */
    Recording.prototype.getSeriesId = function()
    {
        var seriesId, program, seriesLink, tokens;

        seriesId = this.get("entityId");

        if( ! seriesId )
        {
            program = this.getProgram();
            if( program )
            {
                seriesLink = program.getTvSeriesLink();
                if( seriesLink )
                {
                    tokens = seriesLink.split(/\//g);
                    if( tokens && tokens.length )
                        seriesId = tokens[tokens.length-2];
                }
            }
        }

        if( ! seriesId )
            seriesId = this.get("programId");

        return seriesId;
    };

    /**
     * Get the playable stream URL
     * @return {String}
     */
    Recording.prototype.getStreamUrl = function()
    {
        var url, link = this.getLink("stream");

        if( link )
            url = XtvApi.trimPath( link );

        url = _x2._data._host + url;

        return url;
    };

    Recording.prototype.getSeries = function()
    {
        var program, series;

        if( this._series )
            series = this._series;

        if( ! series )
        {
            program = this.getProgram();
            if( program )
                series = program.getSeries();
        }

        return series;
    };

    Recording.prototype.getSeriesTitle = function()
    {
        var series, title;

        series = this.getSeries();

        if( series )
            title = series.getTitle();

        return title;
    };

    Recording.prototype.getWontRecordReason = function()
    {
        return this.get("wontRecordReason");
    };

    Recording.prototype.isCheckedOut = function()
    {
        return this.getPath(["checkoutStatus", "checkedOut"]);
    };

    Recording.prototype.erase = function()
    {
        var retval, form = this.getEraseForm();

        if( form )
            retval = form.submit();
        else
            retval = Promise.reject( new ApiError().init( { local:"Recording.erase: There is no erase form for this entity"} ) );

        return retval;
    };

    /**
     * Return true if this recording is HD.
     * @return {boolean}
     */
    Recording.prototype.isHD = function()
    {
        return this.get("videoQuality") === 'hd';
    };

    /**
     * Return true if this recording has DVS
     * @memberOf Recording
     * @return {Boolean}
     */
    Recording.prototype.isDvs = function()
    {
        return this.get("dvs");
    };

    /**
     * Return true if this recording has SAP
     * @memberOf Recording
     * @return {Boolean}
     */
    Recording.prototype.isSap = function()
    {
        return this.get("sap");
    };

    /**
     * Return true if this is a future, scheduled recording.
     * @return {boolean}
     */
    Recording.prototype.isScheduled = function()
    {
        var scheduled = false, status = this.get("recordingStatus");

        if( status === 'scheduled' || status === 'STARTED' || status === 'NOTSTARTED' )
            scheduled = true;

        return scheduled;
    };

    /**
     * Return true if this recording is started or complete
     * @return {boolean}
     */
    Recording.prototype.isWatchable = function()
    {
        var watcahble = false;
        var status = this.getRecordingStatus();

        if( status === 'STARTED' || status === 'COMPLETE' )
            watcahble = true;

        return watcahble;
    };

    Recording.prototype.setSeries = function( series )
    {
        this._series = series;
    };

    Recording.prototype.reload = function()
    {
        var self = this;

        var resolver = function( resolve, reject )
        {
            var success = function( result )
            {
                resolve( self.init( JSON.parse(result.data) ) );
            };

            var link = self.getLinkObject( "self" );
            link.resolve().then(success).catch(reject);
        };

        return new Promise( resolver );
    };

    // Recording.prototype.setGroup = function(group)
    // {
    //     this._group = group;
    // };

    Recording.prototype.undelete = function()
    {
        var retval, form = this.getUndeleteForm();

        if( form )
            retval = form.submit();
        else
            retval = Promise.reject( new ApiError().init( { local:"Recording.undelete: There is no undelete form for this entity"} ) );

        return retval;
    };

    return Recording;
})();
