// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ScrollWidget = ( function()
{
    "use strict";

    ScrollWidget.prototype             = new Widget();
    ScrollWidget.prototype.constructor = ScrollWidget;
    ScrollWidget.SHADER                = Widget.SHADER_KVT; // Dummy shader -- not used but checked in Widget.update() if widget has render()

    function ScrollWidget(){}

    // TODO: FIXME: safeLeft, safeRight, safeTop, safeBottom, callback, onProgress
    /**
     * Initializer
     * @param {Object}  params
     * @param {Number} [params.h]      - Height in pixels
     * @param {Number} [params.w]      - Width in pixels
     * @param {Widget} [params.widget] - child of the scroll container, the widget that scrolls
     * @memberof ScrollWidget
     */
    ScrollWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this );
        this._className = "ScrollWidget";

        this._widget     = params.widget;
        this._selectable = true;

        if( this._widget )
            this.addWidget( this._widget );

        this.setVal( Widget.W, params.w );
        this.setVal( Widget.H, params.h );
        this.setVal( Widget.OVERFLOW, "hidden" );

        if( _x2._config._render === Config.RENDER_WEBGL )
        {
            // Note: color doesn't matter -- we don't write to the color framebuffer
            this._stencilRect = new RectWidget().init( { w:params.w, h:params.h, color: "#FFFFFF" } );
            this._stencilRect.resourcesLoad(); // We are NOT added as a child
        }

        return this;
    };

    /** WebGL only: decrement the stencil mask after all children are rendered
     * @memberof ScrollWidget
     */
    ScrollWidget.prototype.postRender = function( /* alphaParent */ )
    {
        var gl = Widget.gl;

                // ... Post-draw children

                Widget.stencilId--;
                Widget.stencilMask >>= 1;

                // 3) Decrement stencil depth -- will be zero if we are back at the original parent
                // That way we don't have to clear the entire stencil buffer in the initial !stencilOn
                gl.stencilOp( gl.KEEP, gl.KEEP, gl.DECR );
                gl.stencilFunc( gl.ALWAYS, Widget.stencilId, Widget.stencilMask );
                gl.colorMask( false, false, false, false );
                gl.depthMask( false );

                this._stencilRect.glRender( 1 ); // render mask

                // 4) Render remaining heirarchy using parent's stencil
                gl.stencilOp( gl.KEEP, gl.KEEP, gl.KEEP );
                gl.stencilFunc( gl.EQUAL, Widget.stencilId, Widget.stencilMask );
                gl.colorMask( true, true, true, true );
                gl.depthMask( true );

        // Only turn stencil off for original parent
        if( this._stencilOn === undefined )
            console.error(
                "Scroll.postRender() turned off the stencil due to Scroll.render() never being called to save the initial stencil state!\n" +
                "Check alpha / alphaParent -- are you trying to render a transparent nested Scroll??"
             );

        if( !this._stencilOn )
            gl.disable( gl.STENCIL_TEST );
    };

    /**
     * WebGL only: set or increment stencil mask before all children are rendered
     * @memberof ScrollWidget
     */
    ScrollWidget.prototype.render = function( /* alphaParent */ )
    {
        var gl = Widget.gl;

        this._stencilOn = gl.isEnabled( gl.STENCIL_TEST );
        if( !this._stencilOn )
        {
            // We track the number of stencil layers and use that as a mask
            // stencil passes IF (ref_val & mask) COMPARE (stencil & mask)
            // NOTE: There is no need to clear the stencil everytime it is turned on via:
            //   gl.clearStencil(0);
            //   gl.clear( gl.STENCIL_BUFFER_BIT );
            // Since X2.nextFrame() does an initial clear per frame
            gl.enable( gl.STENCIL_TEST );
                Widget.stencilId   = 1;
                Widget.stencilMask = 0;
        } else {
                Widget.stencilId++;
        }
                Widget.stencilMask <<= 1;
                Widget.stencilMask |=  1;

                // 1) Increment stencil depth by rendering the mask
                gl.stencilOp( gl.KEEP, gl.KEEP, gl.INCR );  // fail, zfail, zpass
                gl.stencilFunc( gl.ALWAYS, Widget.stencilId, Widget.stencilMask ); // compare, ref_val, mask
                gl.colorMask( false, false, false, false );
                gl.depthMask( false );

                this._stencilRect.glRender( 1 ); // Render mask only into the stencil buffer

                // 2) Render child objects only allowing drawing that pass: parentStencil & childStencil
                gl.stencilOp( gl.KEEP, gl.KEEP, gl.KEEP );
                gl.stencilFunc( gl.EQUAL, Widget.stencilId, Widget.stencilMask );
                gl.colorMask( true, true, true, true );
                gl.depthMask( true );

                // Pre-draw children ...
    };

    // Needed by WebGL to keep stencil mask dimensions in sync
    ScrollWidget.prototype.setH = function( val )
    {
        Widget.prototype.setH.call( this, val );

        if( this._stencilRect )
            this._stencilRect.setH( val );
    };

    /**
     * @memberof ScrollWidget
     * @param {Number}   [params.x]        - Target x offset to scroll to
     * @param {Number}   [params.y]        - Target y offset to scroll to
     * @param {Number}   [params.duration] - In milliseconds
     * @param {Function} [params.onEnd]    - Callback to trigger when animation is complete
     * @see Widget.animate()
     */
    ScrollWidget.prototype.setScroll = function( params )
    {
        if( params.duration === undefined )
            this._widget.setY( params.y );
        else
            this._widget.animate( params );
    };

    // Needed by WebGL to keep stencil mask dimensions in sync
    ScrollWidget.prototype.setW = function( val )
    {
        Widget.prototype.setW.call( this, val );

        if( this._stencilRect )
            this._stencilRect.setW( val );
    };

    return ScrollWidget;

})();
