// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.Oauth = (function()
{
    "use strict";

    var cid  = "xfinity-partner-program";
    var sec  = "fb4f234a3ddd437d6f3800533ecf01679b426061";
//    var root = "https://login.comcast.net";
    var root = "https://login-st.comcast.net";
    var deviceCode;

    function Oauth( onHasSession, onNeedsCredentials )
    {
        _x2._config.debugAddClass( Config.CLASS_OAUTH, 9 );

        var token = Host.getLocalStorage( "OauthToken" ), self = this;

        _x2._config.log( Config.CLASS_OAUTH, 5 ) ?  console.log( token ) : Config.NOP();

        if( !token )
            this.getAuthCode( onNeedsCredentials );
        else
            onHasSession();
    }

    Oauth.prototype.getAuthCode = function( onNeedsCredentials )
    {
        var params = {}, success, error, self = this;

        params.type = "POST";
        params.url  = root + "/oauth/device/code?response_type=device_code&client_id=" + cid;

        error = function( e )
        {
            console.log( e.toString() );
        };

        success = function( response )
        {
            console.log( ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SUCCESS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<" );
            console.log( response.data );

            var parsed = JSON.parse( response.data );
            var obj    = {};

            console.log( parsed );

            self._interval = parsed.interval;

            deviceCode = parsed.device_code;

            console.log( "USER URL  = " + parsed.verification_uri );
            console.log( "USER CODE = " + parsed.user_code        );

            obj.url            = parsed.verification_uri;
            obj.code           = parsed.user_code;
            obj.pollFunc       = function( onSuccess, onError ) { self.pollForToken( onSuccess, onError ); };
            obj.getNewCodeFunc = function( func ) { self.getNewCode( func ); };

            setTimeout( function() { onNeedsCredentials( obj ); }, 1 );
        };

        _x2._network.ajax( params ).then( success ).catch( error );

    };

    Oauth.prototype.logout = function()
    {
        _x2._config._host.removeAllTokenLocalStorage();
        _x2._config._token = undefined;
    };

    Oauth.prototype.pollForToken = function( onSuccess, onError )
    {
        var params = {}, error, success, self = this;

        params.type      = "POST";
        params.url       = root + "/oauth/token?grant_type=device_code&code=" + deviceCode + "&client_id=" + cid + "&client_secret=" + sec;

        error = function( e )
        {
            var errStr;

            self._delay = undefined;

            errStr = e.getError() ? e.getError() : e.getHttpStatusText();
            console.log( e.toString() );

            switch( errStr )
            {
                case "authorization_pending":
                    self._delay = setTimeout( function() { _x2._network.ajax( params ).then( success ).catch( error ); }, self._interval * 1000 );
                    break;

                case "invalid_grant":
                    if( onError )
                        onError();
                    break;

                case "abort":
                    break;

                default:
                    console.log( "ERROR -> unknown response to oauth poll = " + errStr );
                    console.log( e );
                    break;
            }
        };

        success = function( response )
        {
            console.log( response.data );

            self._delay = undefined;

            if( response.data )
                _x2._config._host.setLocalStorage( "OauthToken", response.data );

            if( onSuccess )
                onSuccess();
        };

        _x2._network.ajax( params ).then( success ).catch( error );
    };

    Oauth.prototype.getNewCode = function( onNeedsCredentials )
    {
        if( this._delay )
        {
            clearTimeout( this._delay );
            this._delay = undefined;
        }

        this.getAuthCode( onNeedsCredentials );
    };

    Oauth.prototype.sendCredentials = function( username, password, onSuccess, onError )
    {
    };

    return Oauth;

})();
