// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 * WebGL Font Rendering
 */
window.GlFont = (function()
{
    "use strict";

    GlFont.ALPHA_SCALE    = 16384; // normalized alpha = alpha / 16384
    GlFont.ALPHA_MAX      = 32768; // normalized alpha allowed to go up to 2.0 in fragment shader

    function GlFont( name )
    {
        this._file = 'resources/fonts/webgl/XfinityStandard-' + name;
        if( name === "Medium" || name === "Regular" )
            this._file = 'resources/fonts/webgl/XStandard02_' +  name;
        this._url  = this._file + '.png';
        this._json = this._file + '.json';
    }

    GlFont.prototype.load = function()
    {
        var self = this, paramsMetrics = {}, onSuccessMetrics, onErrorMetrics, onImageLoad, onImageFail;

        onSuccessMetrics = function( response )
        {
            self._metrics = JSON.parse( response.data );

            var a, b, u, v, w, h, i;
            var gl        = Widget.gl;
            var version   = self._metrics.ver;
            var aMetrics  = self._metrics.metrics;
            var srcGlyph  = 1; // skip first dummy array due to trailing comma being first for subsequent array elements
            var numGlyph  = aMetrics.length;
            var dstGlyph  = 1; // first glpyph slot is unicode 0032
            var nGlyph    = numGlyph;
            var SCALE_A   = 1.0/64.0;
            var SCALE_W   = 32768 / self._metrics.w;
            var SCALE_H   = 32768 / self._metrics.h;
            var uvOffset  = 0;

            self._xyBuffer = gl.createBuffer(); // position
            self._uvBuffer = gl.createBuffer(); // texture
            self._ijBuffer = gl.createBuffer(); // index

            self._aWidth   = new Float32Array( nGlyph   );
            self._aHeight  = new Float32Array( nGlyph   );
            self._advanceX = new Float32Array( nGlyph   );
            self._bearingX = new Float32Array( nGlyph   );
            self._aU       = new Float32Array( nGlyph*2 ); // 2 texcoord u per vertex - prescaled by 32768
            self._aV       = new Float32Array( nGlyph*2 ); // 2 texcoord v per vertex - prescaled by 32768
            self._unicode  = [];

            self._w               = self._metrics.w;
            self._h               = self._metrics.h;
            self._fontSize        = self._metrics.fontSize; // native 1:1 font glyph size -- SDF scalable font metrics
            self._maxAscent       = self._metrics.ascent;
            self._maxDescent      = self._metrics.descent;
            self._oneOverFontSize = 1 / self._fontSize;     // render font size = fontsize * ooFontSize

            for( ; srcGlyph < numGlyph; srcGlyph++, dstGlyph++ )
            {
                i = aMetrics[ srcGlyph ][ 0 ];
                a = aMetrics[ srcGlyph ][ 1 ] * SCALE_A;
                b = aMetrics[ srcGlyph ][ 2 ] * SCALE_A;
                u = aMetrics[ srcGlyph ][ 3 ];
                v = aMetrics[ srcGlyph ][ 4 ];
                w = aMetrics[ srcGlyph ][ 5 ];
                h = aMetrics[ srcGlyph ][ 6 ];

                self._advanceX[ dstGlyph ] = a;
                self._bearingX[ dstGlyph ] =(b < 0) ? 0 : b;
                self._aWidth  [ dstGlyph ] = w;
                self._aHeight [ dstGlyph ] = h;

                uvOffset = dstGlyph << 1;
                self._aU[ uvOffset   ] = (u    ) * SCALE_W;
                self._aU[ uvOffset+1 ] = (u + w) * SCALE_W;

                self._aV[ uvOffset   ] = (v    ) * SCALE_H;
                self._aV[ uvOffset+1 ] = (v + h) * SCALE_H;

                self._unicode[ i ] = dstGlyph; // map unicode to glyph slot
            }

            onImageLoad = function()
            {
                self._texture = self._image._texture; // WebGLTexture
            };

            onImageFail = function()
            {
                return Promise.reject( "ERROR: Couldn't load WebGL Font Texture" );
            };

            self._image = new ImageWidget().init( { url:self._url, onLoad:onImageLoad, onError:onImageFail } );
            self._image.resourcesLoad();
        };

        onErrorMetrics = function()
        {
            return Promise.reject( "ERROR: Couldn't load WebGL Font Metrics" );
        };

        paramsMetrics.type         = "GET";
        paramsMetrics.url          = self._json;
        paramsMetrics.responseType = "text";

        return _x2._network.ajax( paramsMetrics ).then( onSuccessMetrics ).catch( onErrorMetrics );
    };

    /** Font metrics: returns the font line height (ascent + descent) in pixels
     * @memberof GlFont
     * @param   {Number} fontSize in pixels
     * @returns {Number} Height in pixels
     * NOTE: Line height is NOT the same as leading even though Photoshop mislabels it.
     * @see https://www.w3.org/Talks/2008/0911-CSS-Amsterdam/line-height.png
     * @see http://jeffyam.com/node/189
     */
    GlFont.prototype.getLineHeight = function( fontSize )
    {
        return (this._maxAscent + this._maxDescent) * (fontSize * this._oneOverFontSize); // SDF scalable font metrics
    };

    /** Font metrics -- return text width in pixels
     * @memberof GlFont
     * @param   {Object}  params
     * @param   {Number}  params.fontSize - Font size in pixels
     * @param   {Number} [params.length]  - Number of characters in string to render
     * @param   {Number} [params.offset]  - Index of first character; defaults to 0
     * @param   {Number}  params.text     - String to render
     * @param   {Number} [params.width]   - Maximum width in pixels before clipping; defaults to full width
     * @returns {Object}  metric          - Width and Offset that would overflow
     * @returns {Number}  metric.width    - Width in pixels
     * @returns {Number}  metric.offset   - Index of first character that would overflow if any; defaults to -1 if none
     */
    GlFont.prototype.getLineWidth = function( params )
    {
        var retval = { offset: -1 };
        var length = params.length ? params.length : params.text.length;
        var scale  = params.fontSize * this._oneOverFontSize;
        var start  = params.offset | 0;
        var text   = params.text;
        var width  = params.width;

        var glyph;
        var end = start + length;

        var w       = 0;
        var advance = this._advanceX; // Note: must be scaled

        if( length < 1 )
            return 0;

        for( ; start < end; ++start )
        {
            glyph = this._unicode[ text.charCodeAt( start ) ]; // Note: Since we support Unicode there is no: & 0xFF

            w += (advance[ glyph ] * scale); // NOTE: Keep in sync: getLineWidth() render()

            // Fits on line?
            if( (width > 0) && (w >= width) ) // common case: no max width specified
            {
                retval.offset = start;
                break;
            }
        }

        retval.width = w+1; // TODO: Math.ceil() ?
        return retval;
    };

    /** Draw string
     * @memberof GlFont
     * @param  {Object}  params
     * @param  {Number}  params.fontSize - Font size in pixels
     * @param  {Number} [params.length]  - Number of characters in string to render
     * @param  {Number} [params.offset]  - Index of first character; defaults to zero
     * @param  {Number}  params.text     - Text
     * @param  {Number}  params.width    - Maximum width in pixels before clipping; defaults to full width
     * @param  {Number}  params.r        - Normalized Red
     * @param  {Number}  params.g        - Normalized Green
     * @param  {Number}  params.b        - Normalized Blue
     * @param  {Number}  params.a        - Normalized Alpha
     */
    GlFont.prototype.render = function( params )
    {
        var gl = Widget.gl, shader = Widget.glShaderThis;

        var length = params.length ? params.length : params.text.length;
        var scale  = params.fontSize * this._oneOverFontSize;
        var start  = params.offset | 0;
        var text   = params.text;
        var width  = params.width  ? params.width          : -1;
        var italic = params.italic ? params.fontSize * 0.4 : 0.0;

        var glyph, coord; // coord = index texture coord U and V for glyph
        var end = start + length;

        var w  = 0;
        var x  = 0;
        var x0 = 0;
        var x1 = 0;
        var y1;

        //array.length, alias
        var nxy   = -1, xya = new Uint16Array( length * 12 ); // Vertices 4*xya = 12
        var nuv   = -1, uvs = new Uint16Array( length *  8 ); // TexCoord 4*uv  = 8
        var nij   = -1, ijk = new Int16Array ( length *  6 ); // VerIndex 2*ijk = 6 // 2 triangles/glyph = top-tri & bot-tri
        var verts = -1;

        var dX;
        var advance = this._advanceX; // glyph advance width
        var bearing = this._bearingX; // where left edge begins from origin
        var renderW = this._aWidth  ; // glyph render width  -- must be scaled
        var renderH = this._aHeight ; // glyph render height -- must be scaled
        var renderU = this._aU      ; // glyph texture coord u[]
        var renderV = this._aV      ; // glyph texture coord v[]
        var unicode = this._unicode ;

        var alphaLeft  = GlFont.ALPHA_SCALE;
        var alphaRight = GlFont.ALPHA_SCALE;

        /*
              p0,q0      p1,q1
                +----------+...|      xya      uvs          ijk
                |/////A////|...|        0, 0   26944, 128   0
                |////A/A///|...|       24, 0   27992, 128   1
                |///A///A//|...|        0,49   26944,2248   2
                |//AAAAAAA/|...|       24,49   27992,2248   3
                |//A/////A/|...|                            2
                |//A/////A/|...|                            1
                |//A/////A/|...|
                +----------+   |
                x0        x1   w
              p2,q2      p3,q3

                |<-render->|
                |<--advancex-->|
        */
        if( length < 1 )
            return;

        for( ; start < end; ++start )
        {
            glyph = unicode[ text.charCodeAt( start ) ]; // Note: Since we support Unicode there is no: & 0xFF
            coord = glyph << 1; // 2 uv per glyph

            // https://www.freetype.org/freetype2/docs/glyphs/glyphs-3.html
            x0 = x  + (bearing[ glyph ] * scale);
            x1 = x0 + (renderW[ glyph ] * scale); // render width
            y1 =      (renderH[ glyph ] * scale);

            dX =      (advance[ glyph ] * scale); // advance width
            w +=       dX;

            if( (width > 0) && (w > width) )
                break;

            // Vertex Position
            xya[ ++nxy ] = x0 + italic; // p0
            xya[ ++nxy ] = 0;           // q0
            xya[ ++nxy ] = alphaLeft;

            xya[ ++nxy ] = x1 + italic; // p1
            xya[ ++nxy ] = 0          ; // q1
            xya[ ++nxy ] = alphaRight;

            xya[ ++nxy ] = x0         ; // p2
            xya[ ++nxy ] = y1         ; // q2
            xya[ ++nxy ] = alphaLeft;

            xya[ ++nxy ] = x1         ; // p3
            xya[ ++nxy ] = y1         ; // q3
            xya[ ++nxy ] = alphaRight;

            // Vertex Texture Coordinates
            uvs[ ++nuv ] = renderU[   coord ]; // u0
            uvs[ ++nuv ] = renderV[   coord ]; // v0

            uvs[ ++nuv ] = renderU[ 1+coord ]; // u1
            uvs[ ++nuv ] = renderV[   coord ]; // v1

            uvs[ ++nuv ] = renderU[   coord ]; // u2
            uvs[ ++nuv ] = renderV[ ++coord ]; // v2

            uvs[ ++nuv ] = renderU[   coord ]; // u3
            uvs[ ++nuv ] = renderV[   coord ]; // v3

            // Vertex Index
            ijk[ ++nij ] = ++verts; // i0
            ijk[ ++nij ] = ++verts; // i1
            ijk[ ++nij ] = ++verts; // i2

            ijk[ ++nij ] = ++verts; // i3
            ijk[ ++nij ] = verts-1; // i2
            ijk[ ++nij ] = verts-2; // i1

            x += dX;
        }

        ++nij;

        gl.bindBuffer( gl.ARRAY_BUFFER, this._uvBuffer );
        gl.vertexAttribPointer( shader.avTexCoord, 2, gl.UNSIGNED_SHORT, false, 0, 0 ); // stride 0
        gl.enableVertexAttribArray( shader.avTexCoord );
        gl.bufferData( gl.ARRAY_BUFFER, uvs, gl.STATIC_DRAW );

        gl.activeTexture( gl.TEXTURE0 );
        gl.bindTexture( gl.TEXTURE_2D, this._texture );
        gl.uniform1i( shader.utDiffuse1, 0 );
        gl.uniform1f( shader.unHeight, this._h );
        gl.uniform4f( shader.uvColor, params.r, params.g, params.b, params.a );
        gl.uniform1f( shader.unWeight, params.bold ? 0.5 : 0.0 ); // see Widget FLAG_W

        gl.bindBuffer( gl.ARRAY_BUFFER, this._xyBuffer );
        gl.vertexAttribPointer( shader.avPosition, 3, gl.UNSIGNED_SHORT, false, 0, 0 ); // 3 = xyz = x y alpha
        gl.enableVertexAttribArray( shader.avPosition );
        gl.bufferData( gl.ARRAY_BUFFER, xya, gl.STATIC_DRAW );

        gl.bindBuffer( gl.ELEMENT_ARRAY_BUFFER, this._ijBuffer );
        gl.bufferData( gl.ELEMENT_ARRAY_BUFFER, ijk, gl.STATIC_DRAW );

        gl.drawElements( gl.TRIANGLES, nij, gl.UNSIGNED_SHORT, 0 ); // 6 vertices per 2 triangles
    };

    return GlFont;

})();
