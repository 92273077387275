// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.RecordingsWidget = ( function()
{
    "use strict";

    RecordingsWidget.prototype = new Widget();

    function RecordingsWidget(){}

    RecordingsWidget.prototype.gotFocus = function()
    {
        if( this._list.getMaxIndex() > 0 )
            this.speak( _x2.requestFocus( this._list, true ) );
        else
            this.speak( this );
    };

    /**
     * Initializer
     * @memberof RecordingsWidget
     * @param   {Object} params
     * @returns {RecordingsWidget}
     */
    RecordingsWidget.prototype.init = function( params )
    {
        var self = this, listParams = {};
        var w    = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        var x    = Style._safeLeft;

        Widget.prototype.init.call( this, params );
        this._className = "RecordingsWidget";
        this._path      = params.path;

        this.setW( _x2._config._screenW );

        this._progress = new ProgressBarAndTextWidget().init( { type:"recordings" } );
        this.addWidget( this._progress );
        var h = this._progress.getH();

        this._sep = new RectWidget().init( { w:w, h:ListRowWidget.DIVIDER_H, color:"#262626" } );
        this.addWidget( this._sep, x, h );

        this._selectable    = true;
        listParams.w        = w;
        listParams.h        = _x2._config._screenH - h;
        listParams.maxIndex = 1;
        listParams.type     = RecordingRowWidget;
        this._list          = new ListWidget().init( listParams );

        this.addWidget( this._list, x, h+ListRowWidget.DIVIDER_H );

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget( self._list ); } );
        this._list.addWidget( this._distractor );

        this._emptyMessage = new EmptyMessageWidget().init( EmptyMessageWidget.Key.RECORDINGS );
        this.addWidget( this._emptyMessage, Style._safeLeft );

        return this;
    };

    RecordingsWidget.prototype.loadData = function( hasFocus )
    {
        var success, fail, self = this;

        this._distractor.show();
        this._progress.loadData();
        this._list.setData();
        this._emptyMessage.hide();

        success = function( recordings )
        {
            self._distractor.hide();

            var groups = recordings.getRecordingsByGroup();
            groups.push( new RecordingGroup().init( { groupType:"deleted", name:"Deleted Recordings" } ) );
            self._list.setData( groups );

            if( groups.length > 1 )
            {
                self._emptyMessage.hide();

                if( _x2._focus === self || hasFocus === true )
                    self.speak( _x2.requestFocus( self._list, true ) );
            }
            else
            {
                self._emptyMessage.show();

                if( _x2._focus === self || hasFocus === true )
                    self.speak( self );
            }

            if( self._listIndex > 0 )
                self._list.setJumpToIndex( self._listIndex );
        };

        fail = function( error )
        {
            var onRetry = function()
            {
                self._distractor.show();
                setTimeout( function (){ self.loadData( hasFocus ) }, 3000 );
            };

            self._distractor.hide();
            console.error( error.toString() );
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.SAVED, onRetry:onRetry } ) );
        };
        _x2._data.cancelAllPendingRequests();
        _x2._data.getRecordings( true ).then( success ).catch( fail );
    };

    RecordingsWidget.prototype.refresh = function( screen )
    {
        var hasFocus = ( this._list.containsWidget( _x2._focus ) === true );
        if( hasFocus )
            _x2._hi.fadeOut(0);

        this._listIndex = this._list.getCurrentAndMaxIndex().current;

        if( this._list.containsWidget( screen._lastFocus ) === true )
            screen._lastFocus = this._list;

        this.loadData( hasFocus );
    };

    RecordingsWidget.prototype.setH = function( val )
    {
        Widget.prototype.setH.call( this, val );
        this._list.setH( val );
    };

    RecordingsWidget.prototype.speak = function( focus )
    {
        var str;

        if( focus === this || focus === undefined )
        {
            if( this._emptyMessage.isShowing() === true )
                str = this._emptyMessage.getSpeechText();
            else
                str = "Loading, please wait. ";

            this._sep.setSpeechParams( str, undefined, this, false );
        }
        else
        {
            str = this._path + ". " +
                  this._progress.getTextStatus() + ". " +
                  focus._speakStr + ". " +
                  "Press up or down to review items. Press OK for options. " +
                  ( this._emptyMessage.isShowing() === true ? this._emptyMessage.getSpeechText() : "" );

            this._sep.setSpeechParams( str, focus._speakRole, this, false );
        }
    };

    return RecordingsWidget;

})();
