// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 * StringWidget text properties
 */
window.Style = (function()
{
    "use strict";

    function Style( object )
    {
        this._object = (object !== undefined) ? object : {};
    }

    /**
     * @memberof Style
     * @param {Number} res - Vertical resolution: 480, 540, 720, or 1080
     */
    Style.initClassVariables = function( res )
    {
        Style._safeBottom = [  17,  19,  45,  68 ][res];
        Style._safeLeft   = [  35,  53,  70, 105 ][res];
        Style._safeRight  = [  35,  53,  70, 105 ][res];
        Style._safeTop    = [  17,  19,  45,  68 ][res];
        Style._pad        = [   4,   4,   6,   9 ][res];

        Style._pillFile   = [ "", "", "pill_28_28_28_28.png"  , "pill_42_42_42_42"       ][res];
        Style._pillHiFile = [ "", "", "pillHi_30_30_28_28.png", "pillHi_45_45_42_42.png" ][res];
    };

    /**
     * @memberof Style
     * @param {Widget} target
     */
    Style.prototype.apply = function( target )
    {
        var key, keys, i, val;

        if( this._object )
        {
            keys = Object.keys(this._object);

            for( i = 0; i < keys.length; i++ )
            {
                key = keys[i];
                val = this._object[key];

                switch( key )
                {
                    case "a":
                    case "alpha":
                        target.setVal( Widget.ALPHA, val );
                        break;

                    case "color":
                        target.setVal( Widget.FONT_COLOR, val );
                        break;

                    case "colorHi":
                        target.setVal( Widget.FONT_COLOR_HI, val );
                        break;

                    case "font":
                        target.setVal( Widget.FONT, val );
                        break;

                    case "fontSize":
                        val = parseInt( val * _x2._config._text );
                        target.setVal( Widget.FONT_SIZE, val );
                        break;

                    case "overflow":
                        target._overflow = val;
                        break;

                    case "textAlign":
                        target.setVal( Widget.TEXT_ALIGN, val );
                        break;

                    case "textOverflow":
                        target._textOverflow = val;
                        break;

                    case "whiteSpace":
                        target.setVal( Widget.TEXT_WHITE_SPACE, val );
                        break;

                    case "width":
//                            target.style.width = val + "px";
                        break;

                    default:
                        console.log( "ERROR -> unknown style type = " + key );
                        break;
                }
            }
        }
    };

    return Style;

})();
