// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * Menu with round buttons. Always visible.
 * @class
 * @see HorizMenuWidget
 * @see TabbedPillButtonsWidget
 */
window.HorizPillButtonsWidget = ( function()
{
    "use strict";

    HorizPillButtonsWidget.prototype = new Widget();

    function HorizPillButtonsWidget(){}

    HorizPillButtonsWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        Widget.prototype.gotFocus.call( this, losingFocus );

        var customSpeakRole = this.getSpeechCustomRole();

        if( this._entries )
        {
            this._entries[this._index].gotFocus();
            this._entries[this._index].setSpeechParams( undefined, customSpeakRole ? customSpeakRole : "button", this, newScreen );
        }

        _x2._hi.fadeOut();

        if( this._layoutDone )
            this.setHighlight( 0 );

        return this;
    };

    /**
     * Initializer.
     * <p>
     * Handles LEFT, RIGHT, ENTER, DOWN navigation keypresses
     * <p>
     * @memberof HorizPillButtonsWidget
     * @param   {Object}    params
     * @param   {Number}   [params.index]   - initial index to receive focus
     * @param   {Widget[]} (params.entries) - must support `onEnter`
     * @param   {Number}    params.spacing
     * @param   {Number}    params.scrollW - width at which the buttons will start to scroll left
     * @returns {HorizPillButtonsWidget}
     * @example
     *
     * var doEnter = function() { .. };
     * var text1   = new StringWidget().init( ..., onEnter:doEnter );
     * var text2   = new StringWidget().init( ..., onEnter:doEnter );
     * var items   = [ text1, text2 ];
     * var menu    = new HorizPillButtonsWidget().init( { entries: items, spacing: _x2.scaleValInt(#) } );
     */
    HorizPillButtonsWidget.prototype.init = function( params )
    {
        var i, onClick, self = this;

        Widget.prototype.init.call( this, params );
        this._className  = "HorizPillButtonsWidget";
        this._selectable = true;

        this._panel = new Widget().init();
        this.addWidget( this._panel );
        this._numReady   = 0;
        this._entries    = params.entries;
        this._spacing    = params.spacing ? params.spacing : 0;
        this._scrollW    = params.scrollW;
        this._index      = params.index ? params.index : 0;
        this._showing    = false;

        if( this._entries )
            this._entries[0].addReadyCallback( function() { self.layout(); } );

        this._hi = new SelectionWidget().init( { url:_x2._config._imageRoot + Style._pillHiFile } );
        this._hi.setA( 0 );
        this._panel.addWidget( this._hi );
        this._xInset = this._hi.getInsetX();

        if( this._entries )
        {
            for( i = 0; i < this._entries.length; i++ ) {
                this._entries[i].setA(0);
                this._panel.addWidget( this._entries[i] );
            }
        }

        onClick = function( evt )
        {
            var i, x = evt.offsetX;

            evt.stopPropagation();

            for( i = 0; i < self._entries.length; i++ )
            {
                if( x >= self._mouseStart[i] && x <= self._mouseStop[i] )
                {
                    console.log( self._entries[i] );

                    if( self._entries[i].hasFocusListener() === true )
                    {
                        if( _x2._focus === self )
                        {
                            if( i === self._index )
                            {
                                if( !self._isDisabled )
                                    self._entries[i].onEnter();
                            }
                            else
                            {
                                self._entries[self._index].lostFocus();
                                self._index = i;
                                self._entries[i].gotFocus();
                                self.setHighlight();
                            }
                        }
                        else
                        {
                            console.error( "DOES NOT HAVE FOCUS" );  // don't think we have this case anywhere
                        }
                    }
                    else
                    {
                        if( !self._isDisabled )
                            self._entries[i].onEnter();
                    }
                    break;
                }
            }
        };

        this.setMouseDownListener( onClick );

        return this;
    };

    HorizPillButtonsWidget.prototype.layout = function()
    {
        var i, x, w, h, delta;

        this._mouseStart = [];
        this._mouseStop  = [];

        x     = this._xInset + Style._pad / 2;
        delta = this._xInset + this._spacing + Style._pad;

        for( i = 0; i < this._entries.length; i++ )
        {
            this._entries[i].setA(1);
            this._entries[i].setX( x );
            this._entries[i].setY( this._hi.getY() + (this._hi.getH() - this._entries[i].getH()) / 2 );
            this._mouseStart[i] = x - delta / 3;
            x += this._entries[i].getW() + delta;
            this._mouseStop[i] = x - delta * 2 / 3;
        }

        w = x - this._spacing - Style._pad / 2;
        h = this._hi.getH();

        this._panel.setW( w );
        this._panel.setH( h );
        this.setW( w );
        this.setH( h );

        //if we've patched new buttons, don't try to select an out of range index
        if( this._index > this._entries.length - 1 )
        {
            this._index = this._entries.length - 1;
            this.setHighlight();
        }
        else if( _x2._focus === this )
            this.setHighlight( 0, true );

        this._hi.setW( this._entries[this._index].getW() + Style._pad );

        this.callbackSignalReady();

        this._layoutDone = true;
    };

    HorizPillButtonsWidget.prototype.lostFocus = function( gettingFocus )
    {
        Widget.prototype.lostFocus.call( this, gettingFocus );

        this._hi.stopAnimation( Widget.ALPHA );
        this._hi.animate( { alpha:0, duration:300 } );

        if( this._entries )
            this._entries[this._index].lostFocus();
    };

    HorizPillButtonsWidget.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    if( ! this._isDisabled && this._entries )
                        this._entries[this._index].onEnter();
                }
                break;

            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._index > 0 )
                    {
                        this._entries[this._index--].lostFocus();
                        this._entries[this._index].gotFocus();
                        this.setHighlight();
                    }
                    else
                        retval = Widget.prototype.processEvent.call( this, val, type );
                }
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._entries && this._index < this._entries.length - 1 )
                    {
                        this._entries[this._index++].lostFocus();
                        this._entries[this._index].gotFocus();
                        this.setHighlight();
                    }
                    else
                        retval = Widget.prototype.processEvent.call( this, val, type );
                }
                break;

            default:
                retval = Widget.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    HorizPillButtonsWidget.prototype.setButtons = function( buttons )
    {
        var i;
        var customSpeakRole = this.getSpeechCustomRole();

        if( this._entries )
        {
            for( i=0; i<this._entries.length; i++ )
                this._panel.removeWidget( this._entries[i] )
        }

        this._entries = buttons;
        this._index   = 0;

        for( i=0; i<this._entries.length; i++ )
            this._panel.addWidget( this._entries[i] );

        this._panel.patchWidget();
        this.layout();

        if( _x2._focus === this )
        {
            this.setHighlight( 0 );
            this._entries[this._index].gotFocus();
            this._entries[this._index].setSpeechParams( undefined, customSpeakRole ? customSpeakRole : "button", this, false );
        }
    };

    HorizPillButtonsWidget.prototype.setDisabled = function( isDisabled )
    {
        this._isDisabled = isDisabled;
    };

    HorizPillButtonsWidget.prototype.setHighlight = function( duration, newScreen )
    {
        var self = this;

        var onEnd = function()
        {
            if( self._scrollW )
                onInc();

            var customSpeakRole = self.getSpeechCustomRole();
            self._entries[self._index].setSpeechParams( undefined, customSpeakRole ? customSpeakRole : "button", self, newScreen );
        };

        var onInc = function()
        {
            var xx = self._scrollW - self._hi.getX() - self._hi.getW();
            self.setX( xx < 0 ? xx : 0 );
        };

        var obj =
        {
            x         : this._entries[this._index].getX() - this._xInset - Style._pad / 2,
            w         : this._entries[this._index].getW() + Style._pad,
            duration  : duration!==undefined?duration:300,
            onEnd     : onEnd,
            onEndAxis : Widget.Axis.X
        };

        if( _x2._focus === this )
        {
            this._hi.stopAnimation( Widget.ALPHA );
            obj.a = 1;
        }

        if( this._scrollW )
            obj.onInc = onInc;

        if( this._entries )
            this._hi.animate( obj );
    };

    return HorizPillButtonsWidget;

})();
