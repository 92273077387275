// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.VodRootScreen = (function()
{
    "use strict";

    VodRootScreen.prototype = new Screen();

    function VodRootScreen(){}

    VodRootScreen.prototype.filtersChanged = function()
    {
        var self = this, onPopped;

        console.log( ">>>>>>>>>>>>>>>>>>>> VOD FILTERS HAVE CHANGED <<<<<<<<<<<<<<<<<<<<<" );

        this.setHeaderFilters( _x2._settings.getValue( Settings.Key.FILTERS ) );

        onPopped = function()
        {
            console.log( "#################### APPLY FILTERS ####################" );

            if( self._activeWidget )
                self._activeWidget.deactivate();

            self._activeWidget = undefined;
            self.swapContent( 0 );
        };

//         window.ll( "tagEvent", "Live TV: Find Channel Jump button", { channel: channel.getCallSign() } ); // Localytics 2.3.8
//         _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Live TV: Find Channel Jump button: " + channel.getCallSign() ) : Config.NOP();

        _x2.popOverlay( this._overlay, onPopped );
    };

    VodRootScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._menu, true );

        this._speakTarget.setText( "Browse. " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press left or right to change filters. Press up or down to review categories. ", focus._speakRole, this, false );
    };

    /**
     * Initializer
     * @memberof VodRootScreen
     * @param {Object} [params]
     * @param {String} [params.intialItem] - Name of menu item to get focus
     * @returns {VodRootScreen}
     */
    VodRootScreen.prototype.init = function( params )
    {
        Screen.prototype.init.call( this );
        this._className     = "VodRootScreen";
        this._telemetryName = "Browse";

        var obj, i, onEnter, onGotFocus, index;
        var self        = this;
        var folders     = _x2._vodRoot.getEmbeddedBrowseItems();
        var entries     = [];
        var buttonStyle = new Style( { font: "regular", color: "#e8e8e8", fontSize: _x2.scaleValInt(32), whiteSpace: "nowrap", colorHi: "#000000" } );

        this._widgets = [];

        var doOnEnter = function( idx )
        {
            var focus     = _x2.requestFocus( self._widgets[idx], true );
            var path      = self.getBreadCrumb();
            var telemetry = path.split( " /" ).join( ":" ); // "Browse / ___" -> "Browse: ___"

            _x2._config.log( Config.CLASS_TELEMETRY, 1 ) ? console.log( "Telemetry Tab: " + telemetry ) : Config.NOP();
            _x2._telemetry.setWatchButton( telemetry );
            self._speakTarget.setText( path + "." );

            if( focus )
                self._speakTarget.setSpeechParams( focus._speakStr, focus._speakRole, self, false );
            else
                self._speakTarget.setSpeechParams( "Loading, please wait. ", undefined, self, false );
        };

        onGotFocus = function( idx ) { return function() { self.setBreadCrumbs( "Browse", entries[idx].getText() ); self.swapContent( idx ); }; };
        onEnter    = function( idx ) { return function() { doOnEnter( idx ); }; };

        for( i = 0; i < folders.length; i++ )
        {
            switch( folders[i].getCollectionRenderStyle() )
            {
                case XtvApi.CollectionRenderStyle.NETWORK_ENTITY:
                case XtvApi.CollectionRenderStyle.GALLERY_ROWS:
                    obj = new VodBrowseWidget().init( folders[i] );
                    break;

                default :
                    console.error( "Error -> Unexpected collection render style = " + folders[i] );
                    break;
            }

            if( obj )
            {
                //NOTE: for speed's sake, we're pulling a depth=1 for the main menu. Therefore we don't know if each entry has
                //children. If we need to defend against bad data to filter menu items at the root, we'll need to pull a depth=2 query.

                entries[i] = new StringWidget().init( { text: folders[i].getTitle(), style: buttonStyle, focusTime: 500 } );
                entries[i].onEnter = onEnter( i );
                entries[i].setFocusListeners( onGotFocus( i ) );

                this._widgets.push( obj );
                this.addWidget( obj, 0, 100 );
            }
        }

        var onOptions = function()
        {
            console.log( "SHOW OPTIONS OVERLAY" );

//             window.ll( "tagEvent", "Live TV Options Button" ); // Localytics 2.3.7
//             _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Live TV: Options Button" ) : Config.NOP();

            self._overlay = new FilterChannelsOverlay().init( { screen:self, which:Settings.Key.FILTERS } );
            _x2.pushOverlay( self._overlay );
        };

        var options = new StringWidget().init( { text:"Options", style:buttonStyle, onEnter:onOptions } );

        // add menu widget for top level vod categories

        if( params && params.intialItem )
            for( i = 0; i < entries.length; i++ )
            {
                if( params.intialItem === entries[i].getText() )
                {
                    index = i;
                    break;
                }
            }

        this._menu = new TabbedPillButtonsWidget().init( { entries: entries, options:options, index:index } );
        this.addWidget( this._menu );

        this.setBreadCrumbs( "Browse" );
        this.addReadyCallback( function(){ self.layout(); } );

        this.setHeaderFilters( _x2._settings.getValue( Settings.Key.FILTERS ) );

        window.ll( "tagScreen", "On Demand" );

        _x2._telemetry.sendScreenViewed( this._telemetryName );

        return this;
    };

    VodRootScreen.prototype.layout = function()
    {
        var i, x, y;

        x = 0; //Style._safeLeft;
        y = this.getHeaderBottom();

        this._menu.setH( y );

        for( i = 0; i < this._widgets.length; i++ )
        {
            this._widgets[i].setX( x );
            this._widgets[i].setY( y );
        }
    };

    VodRootScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_LAST:
            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._menu.containsWidget( _x2._focus ) === false )
                        _x2.requestFocus( this._menu );
                    else
                        retval = Screen.prototype.processEvent.call( this, val, type );
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    VodRootScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        if( this._menu )
            this._menu.setY( this.getHeaderBottom() );
    };

    VodRootScreen.prototype.swapContent = function( index )
    {
        if( this._activeWidget !== this._widgets[index] )
        {
            if( this._activeWidget )
                this._activeWidget.deactivate();

            this._activeWidget = this._widgets[index];
            this._activeWidget.activate();
        }
    };

    return VodRootScreen;
})();
