// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.GuideViewFilterWidget = (function()
{
    "use strict";

    GuideViewFilterWidget.prototype = new Widget();

    GuideViewFilterWidget.Filter = Object.freeze
    ({
        KIDS   : "Kids",
        MOVIE  : "Movie",
        SPORTS : "Sports"
    });

    function GuideViewFilterWidget(){}

    GuideViewFilterWidget.prototype.filterChannels = function()
    {
        var channels = this._cc.getChannels(), i;
        var filters  = _x2._settings.getValue( Settings.Key.FILTERS );

        if( filters.hd )
        {
            var result = [];

            for( i = 0; i < channels.length; i++ )
                if( channels[i].isHD() === true )
                    result.push( channels[i] );

            channels = result;
        }

        return channels;
    };

    GuideViewFilterWidget.prototype.getLocalyticsTabName = function()
    {
        var retval;

        switch( this._filter )
        {
            case GuideViewFilterWidget.Filter.KIDS  : retval = "Live TV: Kids"  ; break; // TODO: Is this correct?
            case GuideViewFilterWidget.Filter.MOVIE : retval = "Live TV: Movies"; break; // TODO: Is this correct?
            case GuideViewFilterWidget.Filter.SPORTS: retval = "Live TV: Sports"; break; // TODO: Is this correct?
        }

        return retval;
    };

    GuideViewFilterWidget.prototype.gotFocus = function()
    {
        if( this._dataLoaded === true && this._list.getA() > 0 )
            _x2.requestFocus( this._list );
    };

    /**
     * Initializer
     * @memberof GuideViewFilterWidget
     * @param   {Object}                        params
     * @param   {GuideViewFilterWidget.Filter} [params.filter]     - Which filter to apply to the metadata
     * @param   {DistractorWidget}             [params.distractor] - Distractor to display during load
     * @returns {GuideViewFilterWidget}
     */
    GuideViewFilterWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this, params );
        this._className         = "GuideViewFilterWidget";
        this._selectable        = true;
        this._filter            = params.filter;
        this._distractor        = params.distractor;
        this._durationPageView  = 0; // Localytics
        this._timestampPageView = 0; // Localytics
        this._timestampPageLoad = 0; // Localytics

        this.setW( _x2._config._screenW );

        this.addWidget( new RectWidget().init( { w:_x2._config._screenW - Style._safeLeft - Style._safeRight, h:ListRowWidget.DIVIDER_H, color:"#303030" } ), Style._safeLeft, -ListRowWidget.DIVIDER_H );

        this._list = this.getDefaultWidget();
        
        this._gvfwEmpty = new EmptyMessageWidget().init( EmptyMessageWidget.Key.GRID_FILTER );
        this.addWidget( this._gvfwEmpty, Style._safeLeft );

        return this;
    };

    GuideViewFilterWidget.prototype.loadData = function()
    {
        var date = new Date(), self = this, onChannelsLoaded, onChannelsFailed, onLingerComplete, time, blocks;

        this._timestampPageLoad = new Date(); // Localytics
        this._list.setData();

        date.setMilliseconds( 0 );
        date.setSeconds( 0 );
        date.setMinutes( (date.getMinutes() < 30) ? 0 : 30 );

        time   = date.getTime();
        blocks = [ [], [], [], [], [], [], [] ];

        var createEmptyString = function()
        {
            var retval;

            if( self._filter === GuideViewFilterWidget.Filter.KIDS )
                retval = "Kids";
            else if( self._filter === GuideViewFilterWidget.Filter.MOVIE )
                retval = "Movies";
            else if( self._filter === GuideViewFilterWidget.Filter.SPORTS )
                retval = "Sports";

            return retval;
        };

        var createTitle = function( index )
        {
            var retval, millis, start, stop;

            if( self._filter === GuideViewFilterWidget.Filter.KIDS )
                retval = "Kids On Now";
            else if( self._filter === GuideViewFilterWidget.Filter.MOVIE )
                retval = "Movies On Now";
            else if( self._filter === GuideViewFilterWidget.Filter.SPORTS )
                retval = "Sports On Now";

            if( index > 0 )
            {
                millis = time + index * 1800000;
                start  = new Date( millis );
                stop   = new Date( millis + 1800000 );
                retval = _x2.createTimeRange( start, stop );
            }

            return retval;
        };

        onChannelsLoaded = function()
        {
            var chanColl = _x2._channelCollection;
            self._cc = chanColl;

            var onScheduleBlock = function()
            {
                var channels                 = self.filterChannels(), i, j, scheds, delta, index, entities = [];
                var whichTab                 = self.getLocalyticsTabName();
                var millisecondsLoadDuration = new Date() - self._timestampPageLoad;
                var filters                  = _x2._settings.getValue( Settings.Key.FILTERS );
                var now                      = new Date().getTime(), end = now + 6 * 30 * 60 * 1000;

                for( i = 0; i < channels.length; i++ )
                {
                    scheds = channels[i].getSchedules();

                    for( j = 0; j < scheds.length; j++ )
                    {
                        if( scheds[j].getStartTime() > end )
                            break;

                        if( scheds[j].hasFilter( self._filter ) === true && (filters.cc === false || scheds[j].isClosedCaptioned() === true) && (filters.sap === false || scheds[j].isSap() === true) && (filters.dvs === false || scheds[j].isDvs() === true) )
                        {
                            scheds[j].setChannel( channels[i] );

                            delta = (scheds[j].getStartTime() - time) / 1800000;
                            index = (delta < 0 ) ? -1 : parseInt( delta );

                            if( delta < 0 )
                            {
                                if( scheds[j].getEndTime() > time )
                                    blocks[0].push( scheds[j] );
                            }
                            else if( index < 7 )
                                blocks[index].push( scheds[j] );
                        }
                    }
                }

                self.showEmptyMessage();
                self._list.setA( 0 );

                for( i = 0; i < blocks.length; i++ )
                {
                    if( blocks[i].length > 0 )
                    {
                        self._gvfwEmpty.hide();
                        self._list.setA( 1 );
                    }

                    entities[i] = new GuideViewEntity( createTitle(i), blocks[i], "No " + createEmptyString() + " programs are available, adjust filters to view more titles." );
                }

                self._list.setData( entities );
                self._dataLoaded = true;

                if( _x2._focus === self && self._list.getA() > 0 )
                    _x2.requestFocus( self._list );

                if( self.getEndA() === 1 )
                    self._distractor.hide();

                if( whichTab )
                {
                    window.ll( "tagEvent", whichTab + " Load Time", { "Duration": millisecondsLoadDuration } );
                    _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: " + whichTab + ", Load Time: duration: " + millisecondsLoadDuration + " (ms)" ) : Config.NOP();

                    _x2._config.log( Config.CLASS_TELEMETRY, 2 ) ? console.info( "Telemetry: " + whichTab + ", Load Time: " + millisecondsLoadDuration + " (ms)" ) : Config.NOP();
                    _x2._telemetry.sendScreenLoadTime( millisecondsLoadDuration, whichTab );
                }
            };

            chanColl.fetch( time, time + 3 * 60 * 60 * 1000, time ).then( onScheduleBlock );
        };

        onChannelsFailed = function( error )
        {
            self._distractor.hide();
            self.showEmptyMessage( true );
            console.error( error );
        };

        onLingerComplete = function()
        {
            if( self.getEndA() === 1 )
            {
                var whichTab = self.getLocalyticsTabName();

                if( whichTab )
                {
                    window.ll( "tagEvent", "Linger", { "Source": whichTab } );
                    _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytlics: Live TV: Linger: " + whichTab ) : Config.NOP();
                }

                console.log( "LOAD " + self._filter );

                _x2._channelCollection.fetch( time ).then( onChannelsLoaded, onChannelsFailed );
            }
        };

        setTimeout( onLingerComplete, 1000 );
    };

    GuideViewFilterWidget.prototype.showEmptyMessage = function( isError )
    {
        var messageKey;

        if( isError )
            messageKey = EmptyMessageWidget.Key.GRID_ERROR;
        else
        {
            var filters = _x2._settings.getValue( Settings.Key.FILTERS );

            if( filters.hd === true || filters.cc === true || filters.dvs === true || filters.sap === true )
                messageKey = EmptyMessageWidget.Key.GRID_FILTER;
            else
                messageKey = EmptyMessageWidget.Key.GRID_EMPTY;
        }

        this._gvfwEmpty.setMessage( messageKey );
        this._gvfwEmpty.show();
    };

    GuideViewFilterWidget.prototype.startViewTimestamp = function()
    {
        this._timestampPageView = new Date();
    };

    GuideViewFilterWidget.prototype.stopViewTimestamp = function()
    {
        // Back Button while in gallery view, and view duration
        var whichTab = this.getLocalyticsTabName();
        this._durationPageView = new Date() - this._timestampPageView;
        window.ll( "tagEvent", "Back Button " + whichTab, { "Duration": this._durationPageView } );
        _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Back Button: " +  whichTab + ", duration: " + this._durationPageView ) : Config.NOP();
    };

    GuideViewFilterWidget.prototype.getDefaultWidget = function() {
        var listParams = {};
        listParams.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 0;
        listParams.type     = GuideViewRowWidget;
        listParams.hide     = true;

        return new ListWidget().init( listParams );
    }

    return GuideViewFilterWidget;

})();
