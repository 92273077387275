// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.AboutOverlay = ( function()
{
    "use strict";

    AboutOverlay.prototype = new Overlay();
    AboutOverlay.version   = environment.version;

    function AboutOverlay(){}

    AboutOverlay.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();

        this._speakTarget.setText( this._version.getText() + ". " + this._made.getText().replace( Strings.Glyphs.HEART, "love" ) );
        this._speakTarget.setSpeechParams( "Press back to dismiss.", undefined, this, false );
    };

    AboutOverlay.prototype.init = function()
    {
        var self         = this, layout;
        var versionStyle = new Style( { font:"regular" , color:"#a3a3a3", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", textAlign:"center" } );
        var madeStyle    = new Style( { font:"regular" , color:"#757575", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", textAlign:"center" } );

        Overlay.prototype.init.call( this );
        this._className  = "AboutOverlay";

        layout = function()
        {
            var vGap = _x2.scaleValInt( 18 );
            var y    = ( self._bg.getH() - self._version.getH() - vGap - self._made.getH() - vGap - self._deviceId.getH() ) / 2;
            var dy   = self._version.getH() + vGap;
            self._version.setY( y );
            self._deviceId.setY( y + dy );
            self._made.setY( y + 2*dy );
        };

        this._version = new StringWidget().init( { text:"Version: " + AboutOverlay.version, style:versionStyle } );
        this._version.setW( this._bg.getW() );
        this._bg.addWidget( this._version );

        this._deviceId = new StringWidget().init( { text:"Device Id: <b>" + (_x2._metricsSocket && _x2._metricsSocket._deviceId ? _x2._metricsSocket._deviceId : "Unknown") + "</b>", style:madeStyle } );
        this._deviceId.setW( this._bg.getW() );
        this._bg.addWidget( this._deviceId );

        this._made = new StringWidget().init( { text:"Made with <font color='#c90318'>" + Strings.Glyphs.HEART + "</font> in Philadelphia", style:madeStyle } );
        this._made.setW( this._bg.getW() );
        this._made.addReadyCallback( layout );
        this._bg.addWidget( this._made );

        return this;
    };

    return AboutOverlay;

})();
