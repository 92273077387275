// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.MessageOverlay = (function()
{
    "use strict";

    MessageOverlay.prototype = new Overlay();

    function MessageOverlay(){}

    MessageOverlay.prototype.gotFocus = function()
    {
        this._speakTarget.setText( this._msg + ". " );
        this._speakTarget.setSpeechParams( "Press back to dismiss.", undefined, this, false );
    };

    MessageOverlay.prototype.init = function( params )
    {
        Overlay.prototype.init.call( this, params );
        this._className = "MessageOverlay";
        this._msg       = params.message;

        var self       = this, onIconLoaded, msg, icon;
        var titleStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(42) } );

        onIconLoaded = function()
        {
            var x, w, h, tPad, bPad, lPad, rPad, textW, onTextReady;

            tPad  = bPad = lPad = rPad = _x2.scaleValInt( 80 );
            w     = _x2.scaleValInt( 1000 );
            x     = lPad + _x2.scaleValInt( 90 );
            textW = w - rPad - x;

            icon.setX( lPad );

            onTextReady = function()
            {
                h = tPad + msg.getH() + bPad;
                icon.setY( (h - icon.getH() + tPad - bPad) / 2 );
                msg.setY( tPad );
                self.setBgSize( w, h );
            };

            msg = new TextBlockWidget().init( { text:params.message, style:titleStyle, w:textW } );
            msg.addReadyCallback( onTextReady );
            self._bg.addWidget( msg, x );
            msg.patchWidget();
        };

        icon  = new ImageWidget().init( { url:_x2._config._imageRoot + "circleCheck.png", onLoad:onIconLoaded } );
        this._bg.addWidget( icon );

        return this;
    };

    return MessageOverlay;

})();
