// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PurchasesWidget = (function()
{
    "use strict";

    PurchasesWidget.prototype = new Widget();

    function PurchasesWidget(){}

    PurchasesWidget.prototype.getColumnCount = function()
    {
        return this._columnCount;
    };

    PurchasesWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        var focus;

        if( this._dataLoaded  )
        {
            if( this._list )
                focus = this.speak( _x2.requestFocus( this._list, newScreen ), newScreen );
            else
                focus = this.speak( _x2.requestFocus( this._buttons, newScreen ), newScreen );
        }
        else
            this.speak();

        return focus;
    };

    PurchasesWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this, params );

        var self = this;

        Widget.prototype.init.call( this );
        this._className   = "PurchasesWidget";
        this._path        = params && params.path;
        this._selectable  = true;

        this.setW( _x2._config._screenW );

        if( _x2._config._purchases === true )
        {
            var browse = function()
            {
                _x2.setScreen( new VodRootScreen ().init( { intialItem:"Movies" } ) );
            };

            var buttonStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000"  } );
            var browseStr   = new StringWidget().init( { text:"Browse", style:buttonStyle, onEnter:browse } );
            this._buttons   = new HorizPillButtonsWidget().init( { entries:[browseStr], spacing:_x2.scaleValInt(41) } );
        }

        this._emptyMessage = new EmptyMessageWidget().init( _x2._config._purchases ? EmptyMessageWidget.Key.PURCHASES_ON : EmptyMessageWidget.Key.PURCHASES_OFF, this._buttons );
        this.addWidget( this._emptyMessage, Style._safeLeft );

        var listParams          = {};
        listParams.w            = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        listParams.h            = _x2._config._screenH - 180;
        listParams.hide         = true;
        listParams.maxIndex     = 0;
        listParams.type         = VodGridRowWidget;
        listParams.obj          = this;
        listParams.sep          = false;
        listParams.renderStyle  = XtvApi.EntityRenderStyle.POSTER;

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( function(){ setTimeout( function() { self._distractor.centerOnWidget( self ) },1) } ); // TODO clean up
        this.addWidget( this._distractor );

        return this;
    };

    PurchasesWidget.prototype.loadData = function( listIndex, refresh )
    {
        var onPurchases, onRentals, ready, fail, hasFocus, self = this;

        if( this._list )
        {
            this._list.setA( 0 );
            this._list._selectable = false;
        }

        onPurchases = function( purchaseGroup )
        {
            self._tvSeries = purchaseGroup.getTvSeries();
            self._movies   = purchaseGroup.getMovies();
        };

        onRentals = function( response )
        {
            self._rentals = response;
        };

        ready = function()
        {
            var rows = [], types = [];

            if( self._rentals && self._rentals.getTotalEntityCount() )
            {
                rows.push( self._rentals );
                types.push( GalleryRowWidget );
            }

            if( self._movies && self._movies.getTotalEntityCount() )
            {
                rows.push( self._movies );
                types.push( GalleryRowWidget );
            }

            if( self._tvSeries && self._tvSeries.getTotalEntityCount() )
            {
                rows.push( self._tvSeries );
                types.push( GalleryRowWidget );
            }

            if( rows.length === 0 )
            {
                self._emptyMessage.show();
            }
            else
            {
                var listParams   = {};
                listParams.w     = _x2._config._screenW;
                listParams.h     = _x2._config._screenH;
                listParams.rows  = rows;
                listParams.types = types;
                listParams.max   = 12;
                listParams.indexListener = function( index ){ self._listIndex = index };

                if( self._list )
                {
                    hasFocus = self._list.containsWidget( _x2._focus );
                    self.removeWidget( self._list );
                }

                self._list = new StaticListWidget().init( listParams );
                self.addWidget( self._list, 0 );
                self._list.patchWidget();

                self._emptyMessage.hide();
            }

            self._dataLoaded = true;
            self.callbackSignalReady();
            self._distractor.hide();

            if( listIndex !== undefined )
            {
                if( listIndex >= rows.length - 1 )
                    listIndex = rows.length - 1;

                if( listIndex > 0 )
                    self._list.setJumpToIndex( listIndex );
            }

            if( self._list && ( _x2._focus === self || hasFocus ) ) {
                if( self._horizListIndex )
                {
                    var listObj = self._list.getSelectedWidget();
                    if( listObj && listObj.setListPosition )
                        listObj.setListPosition( self._horizListIndex );
                } //set focus of the widget, if it was selected in previous page
                self.speak( _x2.requestFocus( self._list ) );
            }

        };

        fail = function( error )
        {
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.SAVED } ) );
            self._distractor.hide();
        };

        this._distractor.show();

        _x2._data.cancelAllPendingRequests();

        var p = [];
        p.push( _x2._data.getRentals( refresh, true ).then( onRentals ).catch( fail ) );
        p.push( _x2._data.getPurchases().then( onPurchases ).catch( fail ) );
        Promise.all(p).then( ready );
    };

    PurchasesWidget.prototype.refresh = function( screen )
    {
        _x2._hi.fadeOut();

        if( this._list.containsWidget( screen._lastFocus ) === true )
            screen._lastFocus = this;

        var lastSelectedWidget = this._list.getSelectedWidget();
        if( lastSelectedWidget )
            this._horizListIndex = lastSelectedWidget.getListPosition ? lastSelectedWidget.getListPosition() : 0;

        this.loadData( this._list.getSelectedIndex(), true );
    };

    PurchasesWidget.prototype.speak = function( focus, newScreen )
    {
        var str;

        if( focus )
        {
            if( newScreen !== true )
            {
                if( this._list === undefined || this._list.getMaxIndex() === 0 )
                {
                    str = this._path      + ". " +
                          focus._speakStr + ". Button. " +
                          this._emptyMessage.getSpeechText();
                }
                else
                {
                    str = this._path      + ". " +
                          focus._speakStr + ". " +
                          "Press left, right, or down to browse content. Press OK to select. ";
                }

                this._distractor.setSpeechParams( str, undefined, this._distractor, false );
            }
        }
        else
        {
            if( this._dataLoaded === true )
                this._distractor.setSpeechParams( this._emptyMessage.getSpeechText(), undefined, this, false );
            else
                this._distractor.setSpeechParams( "Loading, please wait. ", undefined, this, false );
        }

        return focus;
    };

    return PurchasesWidget;

})();
