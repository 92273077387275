// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.NineSliceTestScreen = ( function()
{
    "use strict";

    NineSliceTestScreen.prototype = new Screen();

    function NineSliceTestScreen(){}

    NineSliceTestScreen.prototype.init = function()
    {
        var self = this;
        this._className = "NineSliceTestScreen";

        Screen.prototype.init.call( this );

        var inner2Str = [ "playProgressFilled_3_3_3_3.png", "playProgressFilled_3_3_3_3.png", "playProgressFilled_3_3_3_3.png", "playProgressFilled_5_5_5_5.png" ][_x2._config._layout];

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        // test inner positioning

        this._topInner = new RectWidget().init( { w:200, h:50, color:"#003333" } );
        this._botInner = new RectWidget().init( { w:200, h:50, color:"#003333" } );
        this._inner    = new NineSliceWidget().init( { url:_x2._config._imageRoot + "selection_0_0_5_5.png", w:100, h:50 } );

        this.addWidget( this._topInner, Style._safeLeft, Style._safeTop       );
        this.addWidget( this._botInner, Style._safeLeft, Style._safeTop + 100 );
        this.addWidget( this._inner                                           );

        this._inner.setPosition( { x:Style._safeLeft + 50, y:Style._safeTop + 50, w:100, h:50, inner:true } );

        // test outer positioning

        this._midOuter = new RectWidget().init( { w:200, h:50, color:"#003333" } );
        this._outer    = new NineSliceWidget().init( { url:_x2._config._imageRoot + "selection_0_0_5_5.png", w:100, h:50 } );

        this.addWidget( this._midOuter, Style._safeLeft + 200, Style._safeTop + 50 );
        this.addWidget( this._outer                                                );

        this._outer.setPosition( { x:Style._safeLeft + 250, y:Style._safeTop + 50, w:100, h:50, inner:false } );

        //

        this._leftInner2  = new RectWidget().init( { w:50, h:150, color:"#003333" } );
        this._rightInner2 = new RectWidget().init( { w:50, h:150, color:"#003333" } );
        this._inner2      = new NineSliceWidget().init( { url:_x2._config._imageRoot + inner2Str, w:100, h:50 } );
        this._topInner2   = new RectWidget().init( { w:50, h: 50, color:"#003333" } );
        this._botInner2   = new RectWidget().init( { w:50, h: 50, color:"#003333" } );

        this.addWidget( this._leftInner2 , Style._safeLeft      , Style._safeTop + 200 );
        this.addWidget( this._rightInner2, Style._safeLeft + 150, Style._safeTop + 200 );
        this.addWidget( this._inner2     , Style._safeLeft +  50, Style._safeTop + 250 );
        this.addWidget( this._topInner2  , Style._safeLeft +  75, Style._safeTop + 200 );
        this.addWidget( this._botInner2  , Style._safeLeft +  75, Style._safeTop + 300 );

        this._inner2.setPosition( { x:Style._safeLeft + 50, y:Style._safeTop + 250, w:100, h:50, inner:true } );

        //

        this._outer2  = new NineSliceWidget().init( { url:_x2._config._imageRoot + inner2Str, w:100, h:50 } );
        this._midOver = new RectWidget().init( { w:100, h:50, color:"#000055" } );

        this.addWidget( this._outer2  );
        this.addWidget( this._midOver, Style._safeLeft + 250, Style._safeTop + 250 );

        this._outer2.setPosition( { x:Style._safeLeft + 250, y:Style._safeTop + 250, w:100, h:50, inner:false } );

        var file = [ "", "", "thumb_6_6_7_7.png", "thumb_13_13_14_14" ][_x2._config._layout];

        var x = _x2._config._screenW * 0.5;
        var y = Style._safeTop;

        // Test Static setH()
        this._thumb = new NineSliceWidget().init( { url:_x2._config._imageRoot + file } );
        this.addWidget( this._thumb, x, y );
        this._thumb.setH( 200 );
        this._thumb.name = "setH200";

        // Test Dynamic setPosition()
        this._pos = new NineSliceWidget().init( { url:_x2._config._imageRoot + file } );
        this.addWidget( this._pos, x + 50, y );
        this._pos.name = "setPos200";

        //  HorizPillButton
        this._pill = new NineSliceWidget().init( { url:_x2._config._imageRoot + Style._pillFile } );
        this.addWidget( this._pill, x + 100, y );

        this._resize = new NineSliceWidget().init( { url:_x2._config._imageRoot + Style._pillHiFile } );
        this._resize.addReadyCallback( function() { self.queryNineSlice(); } );
        this.addWidget( this._resize, x + 160, y );

        this._selectable = true;

        return this;
    };

    NineSliceTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
             case Host.KEY_1:
                if( type === Host.KEY_PRESSED )
                    if( this._inner.getVal( Widget.ALPHA ) === 0 )
                    {
                        this._inner.setVal ( Widget.ALPHA, 1 );
                        this._outer.setVal ( Widget.ALPHA, 1 );
                        this._inner2.setVal( Widget.ALPHA, 1 );
                        this._outer2.setVal( Widget.ALPHA, 1 );
                    }
                    else
                    {
                        this._inner.setVal ( Widget.ALPHA, 0 );
                        this._outer.setVal ( Widget.ALPHA, 0 );
                        this._inner2.setVal( Widget.ALPHA, 0 );
                        this._outer2.setVal( Widget.ALPHA, 0 );
                    }
                break;

        // Dynamic Height
            case Host.KEY_CH_UP:
            case Host.KEY_EQUAL:
                if( type === Host.KEY_PRESSED )
                    this._pos.setPosition( { w:this._posW, h:this._posH, inner:true } );
                break;

            case Host.KEY_CH_DOWN:
            case Host.KEY_MINUS:
                if( type === Host.KEY_PRESSED )
                    this._pos.setPosition( { w:0, h:200, inner:true } );
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    NineSliceTestScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._posW = this._pos.getW();
        this._posH = this._pos.getH();

//console.log( "Static.setH()   : " + this._thumb.getW() + " x " + this._thumb.getH() );
//console.log( "Dynamic.setPos(): " + this._posW + " x " + this._posH );

        this._resize.callbackSignalReady();
    };

    NineSliceTestScreen.prototype.queryNineSlice = function()
    {
        var w1 = this._resize.getW();
        this._resize.setW( w1*2 );

        var w2 = this._resize.getW();

console.log( "Resized: " + w1 + " -> " + w1*2 );
console.log( "Actual.w = " + w2 );
        var fail = (w1*2) === w2;
        if( fail )
        {
            var border = this._resize.getInsetX();
            console.error( "ERROR: getW() is not accurate. Should be: " + 2*(w1+border) );
        }
    };

    return NineSliceTestScreen;

})();
