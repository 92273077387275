// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * Menu with round buttons that auto-hides when not focused
 * @class
 * @see HorizMenuWidget
 * @see HorizPillButtonsWidget
 */
window.TabbedPillButtonsWidget = ( function()
{
    "use strict";

    TabbedPillButtonsWidget.prototype = new Widget();

    function TabbedPillButtonsWidget(){}

    TabbedPillButtonsWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        var focus = this;

        Widget.prototype.gotFocus.call( this, losingFocus );

        if( this._lastFocus === undefined || this._lastFocus === this._buttons )
            focus = _x2.requestFocus( this._buttons, newScreen );
        else if( this._lastFocus === this._options )
            focus = _x2.requestFocus( this._options, newScreen );

        return focus;
    };

    /**
     * Initializer
     * @memberof TabbedPillButtonsWidget
     * @param   {Object}    params
     * @param   {Array}     params.entries  - entries in the menu
     * @param   {Number}   [params.index]   - initial index to receive focus
     * @param   {Widget}   [params.options] - add ability to have an option button in the menu
     * @returns {TabbedPillButtonsWidget}
     */
    TabbedPillButtonsWidget.prototype.init = function( params )
    {
        var self    = this;
        var onReady = function() { self._numReady++; self.layout(); };

        Widget.prototype.init.call( this, params );
        this._className = "TabbedPillButtonsWidget";

        this._numReady   = 0;
        this._bg         = new RectWidget().init( { w:_x2._config._screenW, h:0, color:"#000000" } );
        this._buttons    = new HorizPillButtonsWidget().init( { entries:params.entries, spacing:_x2.scaleValInt(41), index:params.index } );
        this._scrollPane = new ScrollWidget().init( { widget: this._buttons, w: _x2._config._screenW } );


        this._buttons.setSpeechCustomRole( "_filter" );
        this._buttons.addReadyCallback( onReady );

        this.addWidget( this._bg         );
        this.addWidget( this._scrollPane );

        if( _x2._config._cursorEnabled === true )
        {
            var onClick = function()
            {
                if( _x2._focus === self._buttons )
                    self.processEvent( Host.KEY_DOWN, Host.KEY_PRESSED );
                else
                    _x2.requestFocus( self );
            };

            this._tab = new RectWidget().init( { w:200, h:15, color:"#333333" } );
            this._tab.setMouseDownListener( onClick );
            this.addWidget( this._tab, (_x2._config._screenW - this._tab.getW()) / 2 );
        }

        var positionTab = function()
        {
            if( self._tab )
                self._tab.setY( -self.getY() );
        };

        var onGotFocus = function( losingFocus )
        {
            if( losingFocus !== self._buttons && losingFocus !== self._options )
                self.animate( { y:0, duration:500, onInc:positionTab } );
        };

        var onLostFocus = function( gettingFocus )
        {
            self._lastFocus = _x2._focus;

            if( gettingFocus !== self._options && gettingFocus !== self._buttons )
                self.animate( { y:-self.getH(), duration:500, onInc:positionTab } );
        };

        this._buttons.setFocusListeners( onGotFocus, onLostFocus );

        if( params.options )
        {
            this._options = new HorizPillButtonsWidget().init( { entries:[params.options] } );
            this._options.setFocusListeners( onGotFocus, onLostFocus );
            this._options.addReadyCallback( onReady );
            this.addWidget( this._options, Style._safeLeft );
        }

        this.setW( _x2._config._screenW );
        this._selectable = true;

        return this;
    };

    TabbedPillButtonsWidget.prototype.layout = function()
    {
        if( this._numReady === (this._options ? 2 : 1) )
        {
            var x, w;

            if( this._options )
            {
                x = this._options.getW() + 5 * Style._pad;
                w = this._bg.getW() - Style._safeLeft - Style._safeRight - x;

                if( this._buttons.getW() < w )
                {
                    this._scrollPane.setX( Style._safeLeft );
                    this._scrollPane.setW( w + x );
                    this._buttons.setX( (w + x - this._buttons.getW()) / 2 );
                }
                else
                {
                    this._scrollPane.setX( Style._safeLeft + x );
                    this._scrollPane.setW( w );
                    this._buttons._scrollW = w;
                    this._buttons.setX( 0 );
                }
            }
            else
            {
                w = this._bg.getW() - Style._safeLeft - Style._safeRight;

                this._scrollPane.setX( Style._safeLeft );
                this._scrollPane.setW( w );

                if( this._buttons.getW() < w )
                {
                    this._buttons.setX( (w - this._buttons.getW()) / 2 );
                }
                else
                {
                    this._buttons._scrollW = w;
                    this._buttons.setX( 0 );
                }
            }

            this._buttons.setY( (this._bg.getH() - this._buttons.getH()) / 2 );

            if( this._options )
                this._options.setY( this._buttons.getY() );

            this.callbackSignalReady();

            if( _x2._focus !== this._buttons )
                this.setY( -this.getH() );
        }
    };

    TabbedPillButtonsWidget.prototype.processEvent = function( val, type )
    {
        var retval;

        switch( val )
        {
            case Host.KEY_DOWN:
                retval = this._buttons.processEvent( Host.KEY_ENTER, type );
                break;

            case Host.KEY_LEFT:
                if( this._options && _x2._focus === this._buttons && type === Host.KEY_PRESSED )
                {
                    _x2.requestFocus( this._options );
                    retval = true;
                }
                break;

            case Host.KEY_RIGHT:
                if( this._options && _x2._focus === this._options && type === Host.KEY_PRESSED )
                {
                    _x2.requestFocus( this._buttons );
                    retval = true;
                }
                break;
        }

        if( retval === undefined )
            retval = Screen.prototype.processEvent.call( this, val, type );

        return retval;
    };

    TabbedPillButtonsWidget.prototype.setH = function( val )
    {
        Widget.prototype.setH.call( this, val );

        this._bg.setH( val );
        this._scrollPane.setH( val );
//this._tab.setY( val );
        this.layout();
    };

    return TabbedPillButtonsWidget;

})();
