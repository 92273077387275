// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.SafeBrowseScreen = ( function()
{
    "use strict";

    SafeBrowseScreen.prototype = new Screen();

    function SafeBrowseScreen(){}

    SafeBrowseScreen.prototype.entrySelected = function( key )
    {
        _x2._pc.setSafeBrowse( ParentalControls.SafeBrowse[key] );
        _x2._data.setParentalControlsSettings( _x2._pc );

        if( this._onEnter )
            this._onEnter( ParentalControls.SafeBrowse[key] );
    };

    SafeBrowseScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list );
        this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press up or down to review options. Press OK to select. ", focus._speakRole, this, false );
    };

    /**
     * memberof SafeBrowseScreen
     * @param {Object} params
     * @param {Function} params.onEnter - function to execute on enter
     * @returns {SafeBrowseScreen}
     */
    SafeBrowseScreen.prototype.init = function( params )
    {
        var listParams = {}, self = this;

        Screen.prototype.init.call( this );
        this._className     = "SafeBrowseScreen";
        this._telemetryName = "Safe Browse";
        this.setBreadCrumbs( "Settings / Parental Controls", "Safe Browse" );

        this._onEnter = params.onEnter;

        var rows = [ { title:"Off", key:"OFF" }, { title:"Medium Safety", key:"MEDIUM" }, { title:"High Safety", key:"HIGH" } ];

        listParams.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 1;
        listParams.rows     = rows;
        listParams.type     = RadioButtonRowWidget;
        listParams.obj      = new RadioButtonController( _x2._pc.getSafeBrowse(), function( key ) { self.entrySelected( key ) } );

        this._list = new ListWidget().init( listParams );
        this.addWidget( this._list, Style._safeLeft );

        return this;
    };

    SafeBrowseScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._list.setY( this.getHeaderBottom() );
        this._list.setH( _x2._config._screenH - this._list.getY() );
    };

    return SafeBrowseScreen;

})();
