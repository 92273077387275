// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.CaptionsScreen = ( function()
{
    "use strict";

    CaptionsScreen.prototype = new Screen();

    function CaptionsScreen(){}

    CaptionsScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list, true );
        this._speakTarget.setText( "Closed Captioning Options. " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press up or down to review settings. Press OK for options. ", focus._speakRole, this, false );
    };

/*
bottomInset
fontStyle
penItalicized
penSize
penUnderline
textBackgroundColor
textBackgroundOpacity
textEdgeColor
textEdgeStyle
textForegroundColor
textForegroundOpacity
windowBorderEdgeColor
windowBorderEdgeStyle
windowFillColor
windowFillOpacity
*/

/**
 * Closed Caption Colors. Legal values include "0x000000" - "0xFFFFFF" | "red" | "green" | "blue" |
 *     "cyan" | "magenta" | "yellow" | "white" | "black".
 * @typedef {string} ClosedCaptionColor
 */
/**
 * Closed Caption Edges. Legal values include "none" | "raised" | "depressed" | "uniform" |
 *     "drop_shadow_left" | "drop_shadow_right".
 * @typedef {string} ClosedCaptionEdge
 */
/**
 * Closed Caption Font Style. Legal values include "default" | "monospaced_serif" | "proportional_serif" |
 *     "monospaced_sanserif" | "proportional_sanserif" | "casual" | "cursive" | "smallcaps".
 * @typedef {string} ClosedCaptionFontStyle
 */
/**
 * Closed Caption Opacity. Legal values include "solid" | "flash" | "translucent" | "transparent".
 * @typedef {string} ClosedCaptionOpacity
 */
/**
 * Closed Caption Pen Size. Legal values include "small" | "standard" | "large".
 * @typedef {string} ClosedCaptionPenSize
 */


    CaptionsScreen.prototype.init = function()
    {
        Screen.prototype.init.call( this );
        this._className     = "CaptionsScreen";
        this._telemetryName = "Caption Settings";
        this.setBreadCrumbs( "Settings / Accessibility", "Closed Captioning Options" );

        var listParams    = {}, self = this, rows;
        var styling       = _x2._settings.getValue( Settings.Key.CC_STYLING );
        var colors        = [ "white"           , "black"            , "red"               , "green"              , "blue"                 , "yellow"           , "magenta"           , "cyan"            ];
        var colorStrs     = [ _x2._st.colorWhite, _x2._st.colorBlack , _x2._st.colorRed    , _x2._st.colorGreen   , _x2._st.colorBlue      , _x2._st.colorYellow, _x2._st.colorMagenta, _x2._st.colorCyan ];
        var fontStyles    = [ "default"         , "monospaced_serif" , "proportional_serif", "monospaced_sanserif", "proportional_sanserif", "casual"           , "cursive"                               ];
        var fontStyleStrs = [ _x2._st.default   , _x2._st.monoSerif  , _x2._st.propSerif   , _x2._st.monoSans     , _x2._st.propSans       , _x2._st.casual     , _x2._st.cursive                         ];
        var edgeStyles    = [ "none"            , "raised"           , "depressed"         , "uniform"            , "drop_shadow_left"     , "drop_shadow_right"                                          ];
        var edgeStyleStrs = [ _x2._st.none      , _x2._st.raised     , _x2._st.depressed   , _x2._st.uniform      , _x2._st.leftDrop       , _x2._st.rightDrop                                            ];
        var opacities     = [ "solid"           , "translucent"      , "transparent"                                                                                                                      ];
        var opacityStrs   = [ _x2._st.solid     , _x2._st.translucent, _x2._st.transparent                                                                                                                ];
        var sizes         = [ "small"           , "standard"         , "large"                                                                                                                            ];
        var sizeStrs      = [ _x2._st.small     , _x2._st.medium     , _x2._st.large                                                                                                                      ];

//         var reset = function()
//         {
//             styling =
//             {
//                 bgColor      : "black",
//                 bgOpacity    : "solid",
//                 color        : "white",
//                 edgeColor    : "white",
//                 edgeStyle    : "none",
//                 fontOpacity  : "solid",
//                 fontStyle    : "default",
//                 size         : "small",
//                 windowColor  : "black",
//                 windowOpacity: "transparent"
//             };
//
//             _x2._settings.setValue( Settings.Key.CC_STYLING, styling );
//             _x2.popScreen();
//         };

//         if( styling === undefined )  // set the default values
//             reset();

        console.log( styling );

        var onEnter = function( widget, key, choices, strs )
        {
            var selected  = 0;
            var onEnter = function( index )
            {
                styling[key] = choices[index];
                _x2._settings.setValue( Settings.Key.CC_STYLING, styling );
                widget.setValue( valueStr( key ) );
            };

            for( var i = 0; i < rows.length; i++ )
                if( styling[key] === choices[i] )
                {
                    selected = i;
                    break;
                }

            var bcName = widget.getTitle();
            var index  = bcName.lastIndexOf( ":" );

            if( index > -1 )
                bcName = bcName.slice( 0, index );

            _x2.pushScreen( new ChoiceScreen().init( { rows:strs, selectedIndex:selected, onEnter:onEnter, bcPath:self.getBreadCrumb(), bcScreen:bcName } ) );
        };

        var valueStr = function( key )
        {
            var index = 0, choices, strs;
            var val   = styling[key];

            switch ( key )
            {
                case "textBackgroundColor":
                case "textForegroundColor":
                case "textEdgeColor":
                case "windowColor":
                    choices = colors;
                    strs    = colorStrs;
                    break;

                case "textEdgeStyle":
                    choices = edgeStyles;
                    strs    = edgeStyleStrs;
                    break;

                case "textForegroundOpacity":
                case "textBackgroundOpacity":
                case "windowOpacity":
                    choices = opacities;
                    strs    = opacityStrs;
                    break;

                case "fontStyle":
                    choices = fontStyles;
                    strs    = fontStyleStrs;
                    break;

                case "penSize":
                    choices = sizes;
                    strs    = sizeStrs;
                    break;
            }

            for( var i = 0; i < choices.length; i++ )
                if( choices[i] === val )
                {
                    index = i;
                    break;
                }

            return strs[index];
        };

        rows =
        [
            { title:"Font Size: "         , showIcon:true , value:valueStr( "penSize"               ), onEnter:function( widget ) { onEnter( widget, "penSize"              , sizes     , sizeStrs      ); }, rowIndex:0 },
            { title:"Font Style: "        , showIcon:true , value:valueStr( "fontStyle"             ), onEnter:function( widget ) { onEnter( widget, "fontStyle"            , fontStyles, fontStyleStrs ); }, rowIndex:1 },
            { title:"Font Edge Style: "   , showIcon:true , value:valueStr( "textEdgeStyle"         ), onEnter:function( widget ) { onEnter( widget, "textEdgeStyle"        , edgeStyles, edgeStyleStrs ); }, rowIndex:2 },
            { title:"Font Opacity: "      , showIcon:true , value:valueStr( "textForegroundOpacity" ), onEnter:function( widget ) { onEnter( widget, "textForegroundOpacity", opacities , opacityStrs   ); }, rowIndex:3 },
            { title:"Font Color: "        , showIcon:true , value:valueStr( "textForegroundColor"   ), onEnter:function( widget ) { onEnter( widget, "textForegroundColor"  , colors    , colorStrs     ); }, rowIndex:4 },
            { title:"Font Edge Color: "   , showIcon:true , value:valueStr( "textEdgeColor"         ), onEnter:function( widget ) { onEnter( widget, "textEdgeColor"        , colors    , colorStrs     ); }, rowIndex:5 },
            { title:"Background Color: "  , showIcon:true , value:valueStr( "textBackgroundColor"   ), onEnter:function( widget ) { onEnter( widget, "textBackgroundColor"  , colors    , colorStrs     ); }, rowIndex:6 },
            { title:"Background Opacity: ", showIcon:true , value:valueStr( "textBackgroundOpacity" ), onEnter:function( widget ) { onEnter( widget, "textBackgroundOpacity", opacities , opacityStrs   ); }, rowIndex:7 }
//             { title:"Window Color: "      , showIcon:true , value:valueStr( "windowColor"   ), onEnter:function( widget ) { onEnter( widget, "windowColor"  , colors    , colorStrs     ); } },
//             { title:"Window Opacity: "    , showIcon:true , value:valueStr( "windowOpacity" ), onEnter:function( widget ) { onEnter( widget, "windowOpacity", opacities , opacityStrs   ); } },
//            { title:"Reset Style"         , showIcon:false, onEnter:reset }
        ];

        listParams.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 1;
        listParams.rows     = rows;
        listParams.type     = MenuRowWidget;

        this._list = new ListWidget().init( listParams );
        this.addWidget( this._list, Style._safeLeft );

        return this;
    };

    CaptionsScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._list.setY( this.getHeaderBottom() );
        this._list.setH( _x2._config._screenH - this._list.getY() );
    };

    return CaptionsScreen;

})();
