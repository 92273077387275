// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.Gradient3TestScreen = ( function()
{
    "use strict";

    Gradient3TestScreen.prototype = new Screen();

    function Gradient3TestScreen(){}

    Gradient3TestScreen.prototype.init = function()
    {
        var angle, delta;
        var alphas, colors;
        var alpha2, color2;
        var linear, radial;
        var params, gradient;

        var i;
        var x, y;
        var col, row;
        var w = 4/3 * 200, h = 200;

        Screen.prototype.init.call( this );
        this._className = "Gradient3TestScreen";

        //this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        //this.addWidget( this._bg );
        this.addWidget( new ImageWidget().init( { url: _x2._config._imageRoot + "welcomeBg.jpg" } ) );

        this._grads = [];

        alphas =
        [
            {
                opacity : 100,
                location: 50
            },
            {
                opacity : 50,
                location: 100
            }
        ];

        colors =
        [
            {
                color   : "#FF0000",
                location: 0
            },
            {
                color   : "#00FF00",
                location: 50
            },
            {
                color   : "#0000FF",
                location: 100
            }
        ];

        // reverse only alphas/colors, keep locations
        alpha2 = JSON.parse(JSON.stringify(alphas)).reverse(); // deep copy [] & {}
        for( i = 0; i < alphas.length; i++ )
            alpha2[ i ].location = 100 - alpha2[ i ].location;

        color2 = JSON.parse(JSON.stringify(colors)).reverse(); // deep copy [] & {}
        for( i = 0; i < colors.length; i++ )
            color2[ i ].location = 100 - color2[ i ].location;

        // TODO: Stress-Test bad radial: blue, green (circle), red outside
        //    alpha2[0].location = 0;
        //    color2[0].location = 0; // colors[0].location;

        linear =
        {
            w: w,
            h: h,
            type: GradientWidget.Type.Linear,
            angle: 0,
            alphas: alphas,
            colors: colors
        };

        radial =
        {
            w: w,
            h: h,
            type: GradientWidget.Type.Radial,
            alphas: alpha2,
            colors: color2
        };

        x = Style._safeLeft;
        y = Style._safeTop;

        angle = 0;
        delta = 45;

        for( i = 0; i < 9; i++ )
        {
            col = i % 3;
            row =(i / 3) | 0;

            if( col === 0 )
            {
                switch( row )
                {
                    case 0: angle = 135; delta = -45; break;
                    case 1: angle = 180; delta =  90; break;
                    case 2: angle = 225; delta = +45; break;
                }
            }

            if( i !== 4 )
            {
                params = Object.assign( {}, linear );
                params.angle = angle;
            }
            else
            {
                params = Object.assign( {}, radial );
            }

            gradient = new GradientWidget().init( params );

            this._grads.push( gradient );
            this.addWidget( gradient, x, y );

//console.log( "(" + i + ") %o", params );

            x += w;
            angle += delta;

            if( col === 2)
            {
                x = Style._safeLeft;
                y += h;
            }
        }

        this._selectable = true;

        return this;
    };

    return Gradient3TestScreen;

})();
