// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.RecordingRowWidget = ( function()
{
    "use strict";

    RecordingRowWidget.prototype = new ListRowWidget();

    var rawImW = 102;
    var rawImH = 136;
    var imGap  =  11;

    function RecordingRowWidget(){}

    /**
     * @memberof RecordingRowWidget
     * @returns {Number}
     */
    RecordingRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 2 * imGap + rawImH );
    };

    RecordingRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        var str, count = this._data.getRecordingsCount ? this._data.getRecordingsCount() : 1;

        //reset data. (count) may have changed if episodes were deleted.
        if( this._data )
            this.setData(this._data);

        ListRowWidget.prototype.gotFocus.call(this);

        if( this._isDeletedRow )
        {
            this._deleted.gotFocus();
            this._deleted.setSpeechParams( newScreen !== true ? "Press OK for options. " : undefined, undefined, this, newScreen );
        }
        else
        {
            str  = ((count > 1 ) ? " recordings. " : "") + this._subSpeechStr + ". " + ((newScreen !== true) ? ". Press OK for options. " : "");
            this._title.gotFocus();
            this._title.setSpeechParams( str, undefined, this, newScreen );
        }

        return this;
    };

    /**
     * Initializer
     * @memberof RecordingRowWidget
     * @param   params
     * @param   {Number} params.w - Width in pixels of row
     * @returns {RecordingRowWidget}
     * @see ListRowWidget
     */
    RecordingRowWidget.prototype.init = function( params )
    {
        params.chevron = true;
        ListRowWidget.prototype.init.call( this, params );

        this._className = "RecordingRowWidget";

        var self = this;
        var titleStyle    = new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#2ea0dd" } );
        var deletedStyle  = new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(40), whiteSpace:"nowrap", colorHi:"#2ea0dd"  } );
        var subTitleStyle = new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );
        var imW           = _x2.scaleValInt( rawImW );
        var imH           = _x2.scaleValInt( rawImH );
        var inset         = imW + _x2.scaleValInt( 24 );

        this._progressW   = _x2.scaleValInt(300);
        this._progressH   = _x2.scaleValInt(14);
        this._selectable  = true;

        this._title = new StringWidget().init( { text:"?", style:titleStyle } );
        this._title.addReadyCallback( function() { self.layout(); } );
        this.addWidget( this._title, inset );

        this._subTitle = new StringWidget().init( { text:"?", style:subTitleStyle } );
        this.addWidget( this._subTitle, inset );

        this._deleted = new StringWidget().init( { text:"Deleted Recordings", style:deletedStyle } );
        this.addWidget( this._deleted, inset );

        this._image = new ImageWidget().init( { w:imW, h:imH, onChange:function(){ self._image.setA( 1 ) } } );
        this.addWidget( this._image, 0, _x2.scaleValInt(imGap) );

        this._progressWidget = new ProgressBarWidget().init( { w:this._progressW, h:this._progressH, style:"recording" } );
        this._progressWidget.setA( 0 );
        this.addWidget( this._progressWidget );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        return this;
    };

    RecordingRowWidget.prototype.layout = function()
    {
        var cellW  = this.getW();
        var cellH  = this.getHeightUnfocused();
        var titleH = this._title.getH();
        var deletedH = this._deleted.getH();

        this._title.setY   ( cellH/2 - titleH );
        this._subTitle.setY( cellH/2 + Style._pad/2 );
        this._deleted.setY ( cellH/2 - deletedH/2 );

        this._progressWidget.setX( cellW - this._progressW * 1.33);
        this._progressWidget.setY( cellH / 2 - this._progressH / 2 );
    };

    RecordingRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );

        if( this._isDeletedRow )
            this._deleted.lostFocus();
        else
            this._title.lostFocus();
    };

    RecordingRowWidget.prototype.processEvent = function( val, type )
    {
        var retval = false;

        if( val === Host.KEY_ENTER && type === Host.KEY_PRESSED )
        {
            var group = this._data; //can be Recording or RecordingGroup

            if( group.getType && group.getType() === 'deleted' )
                _x2.pushScreen( new DeletedRecordingsScreen().init() );
            else if( group.getRecordingsCount && group.getRecordingsCount() === 1 )
                _x2.pushScreen( new EntityScreen().init( { entity:group.getRecordings()[0] } ) );
            else if( group instanceof Recording )
                _x2.pushScreen( new EntityScreen().init( { entity:group } ) );
            else
                _x2.pushScreen( new EpisodesScreen().init( { entity: group } ) );

            retval = true;
        }

        return retval;
    };

    /**
     * @memberof RecordingRowWidget
     * @param {Object} data - TODO: types?
     */
    RecordingRowWidget.prototype.setData = function( data )
    {
        var self = this, setPoster, recordings, count, title, date, progress;

        if( data !== undefined )
        {
            this._data             = data;
            this._isRecordingGroup = ( data instanceof RecordingGroup );
            this._isDeletedRow     = ( this._isRecordingGroup && data.getType() === 'deleted' );

            this._progressWidget.setA( 0 );
            this._image.setA( 0 );

            if( ! this._isDeletedRow )
            {
                recordings = data.getRecordings        ? data.getRecordings()        : undefined;
                count      = data.getRecordingsCount   ? data.getRecordingsCount()   : 1;
                title      = data.getName              ? data.getName()              : data.getTitle();
                date       = data.getLastRecordingDate ? data.getLastRecordingDate() : data.getRecordedOnDate();

                if( data instanceof RecordingGroup )
                {
                    setPoster = function( program )
                    {
                        if( self._data === data )
                        {
                            var onError = function()
                            {
                                self._image.setUrl( program.getFallbackImageLink( rawImW, rawImH ) );
                            };

                            self._image.setOnError( onError );
                            self._image.setUrl( program.getImageLink( rawImW, rawImH ) );
                        }
                    };
                    data.getProgram().then( setPoster );
                }
                else if( data instanceof Recording )
                {
                    var seriesTitle, season, episode, program, fullTitle = "";

                    seriesTitle = data.getSeriesTitle();
                    program = data.getProgram();

                    if( seriesTitle )
                        fullTitle = seriesTitle;

                    if( program )
                    {
                        season = program.getSeasonNumber();
                        episode = program.getEpisodeNumber();

                        if( season && episode )
                        {
                            if( fullTitle.length > 0 )
                                fullTitle += " - ";
                            fullTitle += "S" + season + " Ep" + episode;
                        }
                    }

                    if( seriesTitle !== title ) //NOTE: some entity types return the same string for series and episode.
                    {
                        if( fullTitle.length > 0 )
                            fullTitle += " - ";
                        fullTitle += title;
                    }

                    title = fullTitle;

                    this._image.setUrl( data.getImageLink( rawImW, rawImH ) );
                    this._image.setA(1);
                }

                if( count > 1 )
                    title += " (" + count + ")";

                this._title.setText( title );

                // var date = data.getLastRecordingDate();
                var dateString = "";

                if( date )
                {
                    dateString         = 'Recording added ' + _x2.createDateTimeStr( date );
                    this._subSpeechStr = "Recording added " + _x2.createDateStr( date ) + ", " + _x2.createTimeSpeechStr( _x2.createTimeStr( date ) );
                }

                this._subTitle.setText( dateString );

                //show recording progress if this is a group of 1 and recording is in progress.
                if( recordings && recordings.length === 1 )
                {
                    progress = recordings[0].getRecordingProgress();

                    if( progress && progress < 100 )
                    {
                        this._progressWidget.setProgress( progress );
                        this._progressWidget.setA( 1 )
                    }
                }
            }

            this._deleted.setA ( this._isDeletedRow ? 1 : 0 );
            this._title.setA   ( this._isDeletedRow ? 0 : 1 );
            this._subTitle.setA( this._isDeletedRow ? 0 : 1 );

            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    return RecordingRowWidget;

})();
