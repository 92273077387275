// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ParentalControlsScreen = ( function()
{
    "use strict";

    ParentalControlsScreen.prototype = new Screen();

    function ParentalControlsScreen(){}

    ParentalControlsScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list );
        this._speakTarget.setText( "Parental Controls. " + focus._speakStr + "." );
        this._speakTarget.setSpeechParams( "Press up or down to review options. Press OK to select.", focus._speakRole, this, false );
    };

    ParentalControlsScreen.prototype.init = function()
    {
        var entries, i, params = {}, onPinOnOff, onOffStr, onSafeBrowse, safeBrowseStr, onReset, onModifyPin, self = this;

        Screen.prototype.init.call( this );
        this._className     = "ParentalControlsScreen";
        this._telemetryName = "Parental Controls";
        this.setBreadCrumbs( "Settings", "Parental Controls" );

        onModifyPin = function()
        {
            PinOverlay.setPinFlow( PinOverlay.FlowType.PARENTAL_CONTROL, undefined, true );
        };

        onPinOnOff = function( widget )
        {
            var onEnter = function( val )
            {
                _x2._pc.setEnabled( val );
                _x2._data.setParentalControlsSettings( _x2._pc );
                widget.setValue( onOffStr() );
            };

            _x2.pushOverlay( new OnOffOverlay().init( { onEnter:onEnter, isOn:_x2._pc.isEnabled() } ) );
        };

        onOffStr = function()
        {
            return _x2._pc.isEnabled() ? "On" : "Off";
        };

        onReset = function()
        {
            var onEnter = function()
            {
                _x2._pc.setSafeBrowse     ( ParentalControls.SafeBrowse.OFF           );
                _x2._pc.setMovieRatingLock( ParentalControls.MpaaRatings.ADULT, false );
                _x2._pc.setMovieRatingLock( ParentalControls.MpaaRatings.NC17 , false );
                _x2._pc.setMovieRatingLock( ParentalControls.MpaaRatings.R    , false );
                _x2._pc.setMovieRatingLock( ParentalControls.MpaaRatings.PG13 , false );
                _x2._pc.setMovieRatingLock( ParentalControls.MpaaRatings.PG   , false );
                _x2._pc.setMovieRatingLock( ParentalControls.MpaaRatings.G    , false );
                _x2._pc.setTvRatingLocks  ( ParentalControls.TvRatings.TVMA   , false );
                _x2._pc.setTvRatingLocks  ( ParentalControls.TvRatings.TV14   , false );
                _x2._pc.setTvRatingLocks  ( ParentalControls.TvRatings.TVPG   , false );
                _x2._pc.setTvRatingLocks  ( ParentalControls.TvRatings.TVG    , false );
                _x2._pc.setTvRatingLocks  ( ParentalControls.TvRatings.TVY7   , false );
                _x2._pc.setTvRatingLocks  ( ParentalControls.TvRatings.TVY    , false );

                _x2._data.setParentalControlsSettings( _x2._pc );
                _x2._data.setParentalControlsRatings ( _x2._pc );

                self._list.setEntry( "title", "Safe Browse:", { title:"Safe Browse:", value:safeBrowseStr(), showIcon:true, onEnter:onSafeBrowse } );

                _x2.popOverlay();
            };

            _x2.pushOverlay( new ResetParentalOverlay().init( { onEnter:onEnter } ) );
        };

        onSafeBrowse = function( widget )
        {
            var onEnter = function()
            {
                widget.setValue( safeBrowseStr() );
                _x2.popScreen();
            };

            _x2.pushScreen( new SafeBrowseScreen().init( { onEnter:onEnter } ) );
        };

        safeBrowseStr = function()
        {
            var str = "????????";

            switch( _x2._pc.getSafeBrowse() )
            {
                case "OFF":
                    str = "Off";
                    break;

                case "MEDIUM":
                    str = "Medium Safety";
                    break;

                case "HIGH":
                    str = "High Safety";
                    break;
            }

            return str;
        };

        this._rows = [];
        entries    = _x2._config._parentalControlsMenu;

        for( i = 0; i < entries.length; i++ )
        {
            switch( entries[i] )
            {
                case "enable":
                    this._rows.push( { title:"Parental Control PIN:", value:onOffStr(), showIcon:true, onEnter:onPinOnOff } );
                    break;

                case "modify":
                    this._rows.push( { title:"Modify Parental Control PIN", showIcon:true, onEnter:onModifyPin } );
                    break;

                case "safeBrowse":
                    this._rows.push( { title:"Safe Browse:", value:safeBrowseStr(), showIcon:true, onEnter:onSafeBrowse } );
                    break;

                case "movieRatings":
                    this._rows.push( { title:"Movie Ratings", showIcon:true, onEnter:function() { _x2.pushScreen( new RatingsScreen().init( { type:RatingsScreen.Type.MOVIE } ) ); } } );
                    break;

                case "tvRatings":
                    this._rows.push( { title:"TV Ratings", showIcon:true, onEnter:function() { _x2.pushScreen( new RatingsScreen().init( { type:RatingsScreen.Type.TV } ) ); } } );
                    break;

                case "reset":
                    this._rows.push( { title:"Reset Parental Controls Restrictions", showIcon:false, onEnter:onReset } );
                    break;

                default:
                    console.error( "ERROR -> unknown menu entry = " + entries[i] );
                    break;
            }
        }

        params.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        params.h        = _x2._config._screenH;
        params.maxIndex = 1;
        params.rows     = this._rows;
        params.type     = MenuRowWidget;

        this._list = new ListWidget().init( params );
        this.addWidget( this._list, Style._safeLeft );

        window.ll( "tagScreen", "Parental Controls" );

        _x2._telemetry.sendScreenViewed( this._telemetryName );

        return this;
    };

    ParentalControlsScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._list.setY( this.getHeaderBottom() );
        this._list.setH( _x2._config._screenH - this._list.getY() );
    };

    return ParentalControlsScreen;

})();
