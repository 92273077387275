// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ScheduledWidget = ( function()
{
    "use strict";

    ScheduledWidget.prototype = new Widget();

    ScheduledWidget.MONTHS = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    ScheduledWidget.DAYS   = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];

    function ScheduledWidget(){}

    ScheduledWidget.prototype.gotFocus = function()
    {
        var focus;

        if( this._jumpToIndex !== undefined )
        {
            if( this._jumpToIndex !== -1 )
            {
                this._entityList.setJumpToIndex( this._jumpToIndex );
                focus = _x2.requestFocus( this._entityList, true );
            }
            else
            {
                this._list._expanded = false;
                focus = _x2.requestFocus( this._list, true );
            }
        }
        else
            focus = _x2.requestFocus( this._list, true );

        if( focus )
            this.speak( _x2.requestFocus( focus, true ) );
        else
            this.speak( this );
    };

    /**
     * Initializer
     * @memberof ScheduledWidget
     * @param   {Object} params
     * @returns {ScheduledWidget}
     */
    ScheduledWidget.prototype.init = function( params )
    {
        var self = this, listParams = {}, entityListParams = {};
        var w    = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        var x    = Style._safeLeft;

        Widget.prototype.init.call( this, params );
        this._className = "ScheduledWidget";
        this._path      = params.path;

        this.setW( _x2._config._screenW );

        this._progress  = new ProgressBarAndTextWidget().init( { type:"scheduled" } );
        this.addWidget( this._progress );
        var h = this._progress.getH();

        this._sep = new RectWidget().init( { w:w, h:ListRowWidget.DIVIDER_H, color:"#262626" } );
        this.addWidget( this._sep, x, h );

        this._selectable          = true;
        entityListParams.w        = w;
        entityListParams.h        = _x2._config._screenH - h;
        entityListParams.maxIndex = 1;
        entityListParams.type     = ScheduledRowWidget;
        this._entityList          = new ListWidget().init( entityListParams );

        listParams.w        = w;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 0;
        listParams.type     = ExpandingListRowWidget;
        listParams.obj      = { list:this._entityList, baseWidget:this };
        this._list          = new ListWidget().init( listParams );

        this.addWidget( this._list, x, h+ListRowWidget.DIVIDER_H );
        this._list.addWidget( this._entityList );

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget( self._list ); } );
        this._list.addWidget( this._distractor );

        this._emptyMessage = new EmptyMessageWidget().init( EmptyMessageWidget.Key.UPCOMING );
        this.addWidget( this._emptyMessage, Style._safeLeft );

        return this;
    };

    ScheduledWidget.prototype.removeRecording = function( recording )
    {
        var i, j, spliced, doBreak, id = recording.getId();

        this._progress.loadData( true );

        for( i = 0; i < this._entries.length; i++ )
        {
            var recordings = this._entries[i].entries;

            for( j = 0; j < recordings.length; j++ )
            {
                if( id === recordings[j].getId() )
                {
                    recordings.splice( j, 1 );

                    if( recordings.length === 0 )
                    {
                        this._entries.splice( i, 1 );
                        spliced = true;
                    }

                    if( this._entries.length > 1 || spliced )
                    {
                        //hide the episode list if there is more than one group (day), or we just deleted the selected season.
                        this._jumpToIndex = -1;
                        this._entityList.setA(0);
                    }
                    else
                    {
                        //send focus back to the group (day) if there is only one.
                        this._jumpToIndex = ( j > 0 ? j-1 : 0 );
                        this._entityList.setData( recordings );
                    }

                    this._list.setData( this._entries );

                    if( this._entries.length > 0 )
                        this._parent._lastFocus = this;
                    else
                    {
                        this._parent._lastFocus = this._parent._menu;
                        this._list._selectable = false;
                        this._list.setA(0);
                        this._emptyMessage.show();
                    }

                    doBreak = true;
                    break;
                }
            }
            if( doBreak )
                break;
        }
    };

    ScheduledWidget.prototype.loadData = function()
    {
        var success, fail, self = this;

        this._progress.loadData();

        if( this._entries === undefined )
        {
            success = function( entities )
            {
                self._distractor.hide();

                var entries = [], date, key, title, index = 0;

                self._entries = [];

                for( var i = 0; i < entities.length; i++ )
                {
                    date = entities[i].getRecordedOnDate();
                    date.setMilliseconds( 0 );
                    date.setSeconds( 0 );
                    date.setMinutes( 0 );
                    date.setHours( 0 );

                    key = date.getTime();

                    if( entries[key] === undefined )
                    {
                        title = ScheduledWidget.DAYS[date.getDay()] + ", " + ScheduledWidget.MONTHS[date.getMonth()] + " " + date.getDate();
                        self._entries[index++] = entries[key] = { title:title, entries:[] };
                    }

                    if( ! entities[i]._deleteListener )
                        entities[i]._deleteListener = function(episode){ self.removeRecording( episode ); return false };

                    entries[key].entries.push( entities[i] );
                }

                if( entities.length > 0 )
                    self._emptyMessage.hide();
                else
                    self._emptyMessage.show();

                if( entities.length > 0 )
                    self._list.setData( self._entries );
                else
                    self._list.setA(0);
            };

            fail = function( error )
            {
                var onRetry = function()
                {
                    self._distractor.show();
                    setTimeout( function (){ self.loadData() }, 3000 );
                };

                self._distractor.hide();
                console.error( error.toString() );
                _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.SAVED, onRetry:onRetry } ) );
            };

            this._distractor.show();
            _x2._data.cancelAllPendingRequests();
            _x2._data.getScheduledRecordings( true ).then( success ).catch( fail );
        }
    };

    ScheduledWidget.prototype.setH = function( val )
    {
        Widget.prototype.setH.call( this, val );
        this._list.setH( val );
    };

    ScheduledWidget.prototype.speak = function( focus )
    {
        var str;

        if( focus === this || focus === undefined )
        {
            if( this._emptyMessage.isShowing() === true )
                str = this._emptyMessage.getSpeechText();
            else
                str = "Loading, please wait. ";

            this._sep.setSpeechParams( str, undefined, this, false );
        }
        else
        {
            str = this._path + ". " +
                  this._progress.getTextStatus() + ". " +
                  focus._speakStr +
                  "Press up or down to review items. Press OK for options. ";

            this._sep.setSpeechParams( str, focus._speakRole, this, false );
        }
    };

    return ScheduledWidget;

})();
