// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.LoadingScreen = ( function()
{
    "use strict";

    LoadingScreen.prototype = new Screen();

    function LoadingScreen(){}

    LoadingScreen.prototype.gotFocus = function()
    {
        this._speakTarget.setText( "Loading X Finity Stream." );
        this._speakTarget.setSpeechParams( undefined, undefined, this, false );
    };

    LoadingScreen.prototype.init = function()
    {
        Screen.prototype.init.call( this, { noBg:true, noHeader:true, noSep:true } );
        this._className     = "LoadingScreen";
        this._telemetryName = "Loading";

        var params    = {
                            name : "SplashScreen",
                            url  : _x2._config._imageRootMso + "splashScreen.jpg"
                        };

        this.addWidget( new ImageWidget().init( params ) );

        return this;
    };

    return LoadingScreen;

})();
