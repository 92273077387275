// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

// TODO still needed features
// - flush and unlock the event queue if nothing happening
// - behavior when up at right edge of time window

/**
 * @class
 */
window.GridRowWidget = ( function()
{
    "use strict";

    GridRowWidget.prototype = new ListRowWidget();

    var pixPerMs;

    function GridRowWidget(){}

    GridRowWidget.prototype.createCell = function()
    {
        var retval = {};

//        retval.sep   = new RectWidget().init( { w:2, h:this.getHeightUnfocused(), color:"#303030" } );
        retval.sep   = new ImageWidget().init( { url:_x2._config._imageRoot + "textFade.png", w:this._sepW, h:this._sepH } );
        retval.title = new StringWidget().init( { text:"????", style:this._titleStyle } );
        retval.sep.setA( 0 );
        retval.title.setA( 0 );
        this.addWidget( retval.title, 0, this._titleY );
        this.addWidget( retval.sep );
        retval.title.patchWidget();
        retval.sep.patchWidget();

        return retval;
    };

    GridRowWidget.prototype.getPosAndDimUnfocused = function( repeating )
    {
        var x = 0, w;

        var gX  = this.getGlobalPos().x;
        var hiX = _x2._hi.getX();

        if( gX === hiX + this._favColOffset )
        {
            x = -this._favColOffset;
            w = this._chanColX;
        }
        else if( gX + this._chanColX === hiX )
        {
            x = this._chanColX;
            w = this._chanColW;
        }
        else if( repeating === true )
        {
            x = 0;
            w = this.getW();
        }
        else
        {
            var maxTime    = this._driver._gridTime + 7200000, cell, listing, startTime, endTime;
            var now        = new Date().getTime();
            var inFirstCol = false;

            if( this._driver._focusColTime < this._driver._gridTime )
                this._driver._focusColTime = this._driver._gridTime;
            else if( this._driver._focusColTime > maxTime )
                this._driver._focusColTime = maxTime;

            this._focusIndex = -1;

            if( now >= this._driver._focusColTime )
                inFirstCol = true;

            for( var i = 0; i < this._cells.length; i++ )
            {
                if( inFirstCol === true )
                {
                    if( now < this._cells[i].listing.getEndTime() && now >= this._cells[i].listing.getStartTime() )
                    {
                        this._focusIndex = i;
                        break;
                    }
                }
                else
                {
                    if( this._cells[i].listing.getEndTime() > this._driver._focusColTime )
                    {
                        this._focusIndex = i;
                        break;
                    }
                }
            }

            cell = this._cells[this._focusIndex];

            if( cell )
            {
                listing   = cell.listing;
                startTime = listing.getStartTime();
                endTime   = listing.getEndTime();
                x         = this._minX + (startTime - this._lastGridTime) * pixPerMs;

                if( x < this._minX )
                    x = this._minX;

                w = this._minX + (endTime - this._lastGridTime) * pixPerMs - x - ListRowWidget.DIVIDER_H;
            }
            else
            {
                x = 0;
                w = this.getW();
            }
        }

        return { pos:x, dim:w };
    };

    /**
     * @memberof GridRowWidget
     */
    GridRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 114 );
    };

    GridRowWidget.prototype.getIndex = function( time )
    {
        var i, retval = -1;

        for( i = 0; i < this._listings.length; i++ )  // TODO binary search
        {
            if( this._listings[i].getEndTime() > time )
            {
                if( this._listings[i].getStartTime() <= time )
                    retval = i;

                break;
            }
        }

        return retval;
    };

    /**
     * @memberof GridRowWidget
     */
    GridRowWidget.prototype.gotFocus = function( lostFocus, newScreen )
    {
        var lastScreen = _x2._screenRoot.getTop();
        if( lastScreen instanceof VideoScreen ) {
            return this;
        } //if still lastScreen is not destroy, we should not higlight the row
        var maxTime    = this._driver._gridTime + 7200000;
        var now        = new Date().getTime();
        var inFirstCol = false;
        var obj        = this.getGlobalPos();
        if( _x2._telemetry.getWatchButtonSource() === "Channel Finder" ) // NOTE: Keep in sync with GuideScreen, GridWidget, and GridRowWidget
        {
            if( _x2._telemetry.lastChannel !== this._channel.getNumber() )
                _x2._telemetry.setWatchButton( "Live TV: All Channels" );
        }

        _x2._telemetry.lastChannel = this._channel.getNumber();

        //setting the last channel, so that we can focus to that channel while scrolling left or right
        _x2._settings.setValue( Settings.Key.LAST_CHAN, { id: this._channel.getStationId(), num: this._channel.getNumber() } );

        if( obj.x === _x2._hi.getX() + this._favColOffset && this._chanColX === _x2._hi.getW() )
            this._favFocus = true;
        else if( obj.x + this._chanColX === _x2._hi.getX() && this._chanColW === _x2._hi.getW() )
            this._channelFocus = true;

        if( this._driver._focusColTime < this._driver._gridTime )
            this._driver._focusColTime = this._driver._gridTime;
        else if( this._driver._focusColTime > maxTime )
            this._driver._focusColTime = maxTime;

        this._focusIndex = -1;

        if( now >= this._driver._focusColTime )
            inFirstCol = true;

        for( var i = 0; i < this._cells.length; i++ )
        {
            if( inFirstCol === true )
            {
                if( now < this._cells[i].listing.getEndTime() && now >= this._cells[i].listing.getStartTime() )
                {
                    this._focusIndex = i;
                    break;
                }
            }
            else
            {
                if( this._cells[i].listing.getEndTime() > this._driver._focusColTime )
                {
                    this._focusIndex = i;
                    break;
                }
            }
        }

        if( this._focusIndex >= 0 && this._cells.length > 0)
            if( this._driver.onUpdateInfo( this._cells[this._focusIndex].listing, this._channel, this._channelFocus ) === true )
                this.setHighlight( 0, undefined, undefined, true, newScreen );

        return this;
    };

    /**
     * Initializer
     * @memberof GridRowWidget
     * @param   {Object} params
     * @param   {Object} params.obj - the grid that is using the this row
     * @param   {Number} params.w   - Width in pixels
     * @returns {GridRowWidget}
     */
    GridRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );
        this._className = "GridRowWidget";

        var self      = this;
        var chanStyle = new Style( { color:"#a3a3a3", font:"medium"  , fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#2b9cd8" } );
        var onReady   = function() { self._numElements++; self.layout(); };

        this._titleStyle   = new Style( { color:"#e8e8e8", font:"regular", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", overflow:"hidden", colorHi:"#2b9cd8" } );
        this._driver       = params.obj;
        this._lastGridTime = this._driver._gridTime;
        this._numElements  = 0;
        this._selectable   = true;
        this._queue        = [];
        this._blocked      = false;
        this._maxH         = Math.floor( this.getHeightUnfocused() / 2 );
        this._maxW         = Math.floor( this._maxH * 5 / 3 );
        this._strPad       = _x2.scaleValInt( 42 );
        this._focusIndex   = 0;

        // add the widgets for the channel information

        var starParams =
        {
            initial:0,
            onEnter:function() { console.log( "TOGGLE" ); },
            images :[ _x2._config._imageRoot + "starEmpty.png", _x2._config._imageRoot + "starEmptyHi.png", _x2._config._imageRoot + "star.png", _x2._config._imageRoot + "starHi.png" ]
        };

        this._chanImage   = new ImageWidget().init( { onError:function(){ self.onChanImageFail() }, onChange:function() { self.onChanImageLoad(); } } );
        this._callSignStr = new StringWidget().init( { style:chanStyle } );
        this._chanStr     = new StringWidget().init( { text:"0000", style:chanStyle } );
        this._chanSep     = new RectWidget().init( { w:ListRowWidget.DIVIDER_H, h:this.getHeightUnfocused(), color:"#262626" } );
        this._star        = new MultiStateButtonWidget().init( starParams );

        this._star.addReadyCallback( onReady );
        this._chanStr.addReadyCallback( onReady );

        this.addWidget( this._star );
        this.addWidget( this._chanImage );
        this.addWidget( this._callSignStr );
        this.addWidget( this._chanStr );
        this.addWidget( this._chanSep );

        // add the widgets for the programs information

        this._scroll = new ScrollWidget().init( { h:this.getHeightUnfocused()-ListRowWidget.DIVIDER_H } );
        this.addWidget( this._scroll );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        this._cells = [];
        this._pool  = new Array( 3 );

        for( var i = 0; i < this._pool.length; i++ )
        {
            this._pool[i]       = {};
//            this._pool[i].sep   = new RectWidget().init( { w:2, h:this.getHeightUnfocused(), color:"#303030" } );

            if( i === 0 )
                this._pool[i].sep = new ImageWidget().init( { url:_x2._config._imageRoot + "textFade.png", onLoad:onReady } );
            else
                this._pool[i].sep = new ImageWidget().init( { url:_x2._config._imageRoot + "textFade.png" } );

            this._pool[i].title = new StringWidget().init( { text:"????", style:this._titleStyle } );
            this._pool[i].sep.setA( 0 );
            this._pool[i].title.setA( 0 );
            this.addWidget( this._pool[i].title );
            this.addWidget( this._pool[i].sep, -100, 0 );

            if( i > 0 )
                this._pool[i-1].next = this._pool[i];
        }

        return this;
    };

    GridRowWidget.prototype.layout = function()
    {
        var i;

        if( this._numElements === 3 )
        {
            this._sepW = this._pool.length > 0 ? this._pool[0].sep.getW() : this._cells[0].sep.getW();
            this._sepH = this.getHeightUnfocused() - ListRowWidget.DIVIDER_H;
            this._titleY = (this.getHeightUnfocused() - this._chanStr.getH()) / 2;
            this._star.setY( (this.getHeightUnfocused() - ListRowWidget.DIVIDER_H - this._star.getH()) / 2 );
            this._chanColX = this._star.getW() + 3 * Style._pad;
            this._favColOffset = 3 * Style._pad / 2;
            this._chanStr.setX( this._chanColX + this._maxW + 3 * Style._pad );
            this._chanStr.setY( this._titleY );
            this._callSignStr.setX( this._chanColX );
            this._callSignStr.setY( this._titleY );
            this._chanColW = this._chanStr.getX() + this._chanStr.getW() + Style._pad - this._chanColX;
            this._minX     = this._chanColX + this._chanColW + ListRowWidget.DIVIDER_H;
            this._chanSep.setX( this._chanColX + this._chanColW );
            this._scroll.setX( this._chanColX + this._chanColW + this._chanSep.getW() );
            this._scroll.setW( this.getW() - this._scroll.getX() );

            for( i = 0; i < this._cells.length; i++ )
            {
                this._cells[i].title.setY( this._titleY );
                this._cells[i].sep.setW( this._sepW );
                this._cells[i].sep.setH( this._sepH );
            }

            for( i = 0; i < this._pool.length; i++ )
            {
                this._pool[i].title.setY( this._titleY );
                this._pool[i].sep.setW( this._sepW );
                this._pool[i].sep.setH( this._sepH );
            }

            this._layoutReady = true;
            this.callbackSignalReady();

            if( this._channel )
                this.setData( this._channel );
        }
    };

    GridRowWidget.prototype.lostFocus = function( gettingFocus )
    {
        ListRowWidget.prototype.lostFocus.call( this, gettingFocus );

        this._favFocus     = false;
        this._channelFocus = false;

        this._chanStr.lostFocus();

        if( this._star.inFocusState() === true )
            this._star.lostFocus();

        if( this._cells[this._focusIndex] )
            this._cells[this._focusIndex].title.lostFocus( gettingFocus );
    };


    GridRowWidget.prototype.onChanImageFail = function()
    {
        this._callSignStr.setText( this._channel.getCallSign() );
        this._callSignStr.setA( 1 );
    };

    /**
     * @memberof GridRowWidget
     */
    GridRowWidget.prototype.onChanImageLoad = function()
    {
        var dw, dh, scale;
        var w = this._chanImage.getW();
        var h = this._chanImage.getH();

        if( w > 0 && h > 0 )
        {
            dw    = this._maxW / w;
            dh    = this._maxH / h;
            scale = ( dh < dw ) ? dh : dw;

            this._chanImage.setX( (this._maxW                - this._chanImage.getW()) / 2 + this._chanColX );
            this._chanImage.setY( (this.getHeightUnfocused() - this._chanImage.getH()) / 2 );
            this._chanImage.setScale( scale );

            this._chanImage.setA( 1 );
        }
//         else if( this._channel ) // failed to load an image, use label instead.
//             this.setCallSignText();
    };

    GridRowWidget.prototype.positionCell = function( cell )
    {
        var sepX, titleX, w;
        var start = cell.listing.getStartTime();
        var end   = cell.listing.getEndTime();

        if( end > this._lastGridTime )
        {
            sepX = this._chanColX + this._chanColW + (end - this._lastGridTime) * pixPerMs;

            cell.sep.setX( sepX - this._sepW );
            cell.sep.setA( 1 );

            titleX = this._chanColX + this._chanColW + this._strPad + (start - this._lastGridTime) * pixPerMs;

            if( titleX < (this._chanColX + this._chanColW + this._strPad) )
                titleX = this._chanColX + this._chanColW + this._strPad;

            cell.title.setText( cell.listing.getGridTitle() );
            cell.title.setX( titleX );
            cell.title.setA( 1 );

            w = sepX - titleX;

            cell.title.setW( w );

            if( w < this._strPad )
                cell.title.setA( 0 );
            else
                cell.title.setA( 1 );
        }
    };

    /*
     * @suppress {suspiciousCode}
     */
    GridRowWidget.prototype.processEvent = function( val, type )
    {
        var retval = true, nextSep, metrics;

        if( this._driver._infoShowing ) {
            return retval;
        } // if grid info widget open, then we should focus the key for grid

        switch( val )
        {
            case Host.KEY_ENTER:
                if( this._blocked === false )
                {
                    if( type === Host.KEY_PRESSED )
                    {
                        retval = true;

                        if( this._favFocus === true )
                        {
                            this._star.processEvent( val, type );

                            metrics =
                            {
                                "Channel"    : this._channel.getCallSign(),
                                "Network"    : this._channel.getCompany(),
                                "Rights Type": "T6"
                            };

                            if( this._star.getState() === 0 )
                            {
                                _x2._favorites.removeFavorite( this._channel ).then( function() {
                                    this._star.setState( 0 ); 
                                });
                                this._chanSep.setSpeechParams( "Favorite unselected. ", undefined, this, false );
                                metrics["Action"] = "Remove";
                                _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: -Favorite Channel: %o:", metrics ) : Config.NOP();
                                window.ll( "tagEvent", "Favorite Channel", metrics );
                            }
                            else
                            {
                                _x2._favorites.addFavorite( this._channel ).then( function() {
                                    this._star.setState( 1 ); 
                                });
                                this._chanSep.setSpeechParams( "Favorite selected. ", undefined, this, false );
                                metrics["Action"] = "Add";
                                _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.warn( "Localytics: +Favorite Channel: %o", metrics ) :  Config.NOP();
                                window.ll( "tagEvent", "Favorite Channel", metrics );
                            }

                            _x2.refreshFavorites();
                        }
                        else
                        {
                            //allow user to select channel cell (so they can tune) even if current listing is TBD.
                            if( this._channelFocus === true ) {
                                this._channel.isFavorite = this._star.getState();
                                this._driver.onShowInfo( this._cells[this._focusIndex].listing, this._channel, true );
                            }
                            else if( this._focusIndex >= 0 )
                            {
                                if( this._cells[this._focusIndex].listing.isTbd() === false )
                                    this._driver.onShowInfo( this._cells[this._focusIndex].listing, this._channel );
                                else{
                                    this._driver.onShowInfo( this._cells[this._focusIndex].listing, this._channel, true );
                                }//if schedule not available, user are allow to watch the current live programs
                            }
                        }
                    }

                    this.processQueue();
                }
                else
                {
                    this._queue.push( [ val, type ] );
                    retval = true;
                }
                break;

            case Host.KEY_LEFT:
                if( type === Host.KEY_RELEASED )
                {
                    if( this._repeating === true )
                    {
                        this._repeatEnded = true;
                    }

                    retval = false;
                }
                else if( this._blocked === false )
                {
                    this._blocked = true;
                    if( type === Host.KEY_PRESSED )
                    {
_x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "grid time = " + new Date( this._driver._gridTime ) + " > " + new Date( this._driver._datum ) + " datum" ) : Config.NOP();

                        if( this._channelFocus === true )
                        {
                            this._favFocus = true;
                            this.setHighlight();
                        }
                        else if( this._focusIndex > 0 )
                        {
                            var sep = (this._cells[this._focusIndex].listing.getStartTime() - this._lastGridTime);

//                            if( sep < 300000 && this._driver._gridTime > this._driver._datum )
                            if( sep < 300000 && this._driver._gridTime > this._driver._minTime )
                            {
                                this._focusIndex--;
                                this.setHighlight( undefined, 1800000 * pixPerMs );
                                this._driver._focusColTime -= 1800000;
_x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "================> " + new Date( this._driver._focusColTime ) ) : Config.NOP();
                                retval = false;
                            }
                            else
                            {
                                this._cells[this._focusIndex] && this._cells[this._focusIndex].title.lostFocus();
                                this._focusIndex--;
                                this.setHighlight();
                                this._driver._focusColTime = Math.floor( this._cells[this._focusIndex].listing.getStartTime() / 1800000 ) * 1800000;

                                if( this._driver._focusColTime < this._driver._gridTime )
                                    this._driver._focusColTime = this._driver._gridTime;

_x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "================> " + new Date( this._driver._focusColTime ) ) : Config.NOP();
                                retval = true;
                            }
                        }
//                        else if( this._driver._gridTime > this._driver._datum )
                        else if( this._driver._gridTime > this._driver._minTime )
                        {
                            if( this._focusIndex >= 0 )
                            {
                                nextSep = (this._cells[this._focusIndex].listing.getStartTime() - this._lastGridTime);

                                _x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "NEXT SEP = " + nextSep ) : Config.NOP();

                                if( nextSep < 0 )
                                {
                                    this.setHighlight( undefined, 1800000 * pixPerMs );
                                    this._driver._focusColTime -= 1800000;
_x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "================> " + new Date( this._driver._focusColTime ) ) : Config.NOP();
                                }
                                else
                                {
                                    this._cells[this._focusIndex].title.lostFocus();

                                    for( var i = 0; i < this._listings.length; i++ )
                                    {
                                        if( this._listings[i] === this._cells[0].listing )
                                        {
                                            _x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "FOUND MATCH AT LISTING INDEX = " + i + ", FOCUS INDEX = " + this._focusIndex ) : Config.NOP();

                                            var prev = this._listings[i-1];

                                            if( i > 0 && prev.getEndTime() >= (this._listings[i].getStartTime() - 1800000) )
                                            {
                                                var cell = this._pool.pop() || this.createCell();
                                                cell.listing = this._listings[i-1];
                                                this._cells.unshift( cell );
                                                this.positionCell( cell );
                                                this.setHighlight( undefined, 1800000 * pixPerMs );
                                                if( this._focusIndex === 0 ) {
                                                    this._driver._focusColTime = this._driver._datum;    
                                                } else {
                                                    this._driver._focusColTime -= 1800000;
                                                }//If focusIndex is zero, that first cell should get highlighted
                                                _x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "================> " + new Date( this._driver._focusColTime ) ) : Config.NOP();
                                            }
                                            break;
                                        }
                                    }
                                }
                            }
                            else
                                this._blocked = false;

                            retval = false;
                        }
                        else
                        {
                            _x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "SELECT THE CHANNEL CELL - retval = " + retval ) : Config.NOP();

                            this._channelFocus = true;
                            this._blocked      = false;
                            if( this._cells[this._focusIndex] )
                                this._cells[this._focusIndex].title.lostFocus();
                            this.setHighlight();
                        }
                    }
                    else if( type === Host.KEY_REPEAT )
                    {
                        this._cells[this._focusIndex] && this._cells[this._focusIndex].title.lostFocus();
                        retval           = false;
                        this._blocked    = false;
                        this._repeating  = true;
                        this._focusIndex = -1;
                        this._selectable = false;
                        _x2._hi.fadeOut();
                    }
                }
                else
                {
                    this._queue.push( [ val, type ] );
                    retval = true;
                }
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_RELEASED )
                {
                    if( this._repeating === true )
                    {
                        this._repeatEnded = true;
                    }

                    retval = false;
                }
                else if( this._blocked === false )
                {
                    this._blocked = true;

                    if( type === Host.KEY_PRESSED )
                    {
                        if( this._favFocus === true )
                        {
                            this._favFocus     = false;
                            this._channelFocus = true;
                            this.setHighlight( undefined, undefined );
                        }
                        else if( this._channelFocus === true )
                        {
                            this._channelFocus = false;
                            this.setHighlight( undefined, undefined, true );
                        }
                        else
                        {
                            if( this._focusIndex < 0 )
                                nextSep = 0;
                            else
                                nextSep = (this._cells[this._focusIndex].listing.getEndTime() - this._lastGridTime);

                            if( nextSep <= 7200000 )
                            {
                                if( this._focusIndex < (this._cells.length - 1) )
                                {
if( this._cells[this._focusIndex] === undefined )
{
    console.error( "This should not be undefined for index = " + this._focusIndex );
    console.log( this._cells );
}
else
                                    this._cells[this._focusIndex].title.lostFocus();
                                    this._focusIndex++;
                                    this.setHighlight();
                                    retval = true;
                                }
                                else
                                {
                                    nextSep -= 1800000;

                                    if( nextSep < 1800000 )
                                        nextSep = 1800000;

                                    this.setHighlight( undefined, -1800000 * pixPerMs );
                                    retval = false;
                                }

                                this._driver._focusColTime = Math.floor( nextSep / 1800000 ) * 1800000 + this._lastGridTime;
_x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "================> " + new Date( this._driver._focusColTime ) ) : Config.NOP();
                            }
                            else
                            {
                                if( this._cells[this._focusIndex+1] !== undefined )
                                {
                                    this._cells[this._focusIndex].title.lostFocus();
                                    this._focusIndex++;
                                    this._driver._focusColTime = Math.floor( nextSep / 1800000 ) * 1800000 + this._lastGridTime;
                                }

                                this.setHighlight( undefined, -1800000 * pixPerMs );
_x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "================> " + new Date( this._driver._focusColTime ) ) : Config.NOP();
                                retval = false;
                            }
                        }
                    }
                    else if( type === Host.KEY_REPEAT )
                    {
                        this._cells[this._focusIndex] && this._cells[this._focusIndex].title.lostFocus();
                        retval           = false;
                        this._blocked    = false;
                        this._repeating  = true;
                        this._focusIndex = -1;
                        this._selectable = false;
                        _x2._hi.fadeOut();
                    }
                }
                else
                {
                    this._queue.push( [ val, type ] );
                    retval = true;
                }
                break;

            default:
                retval = ListRowWidget.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    GridRowWidget.prototype.processQueue = function()
    {
        var self = this;

        var key = this._queue.shift();

        _x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "##### PROCESS QUEUE #####, key = " + key ) : Config.NOP();

        if( key !== undefined )
            setTimeout( function() { self._blocked = false; _x2.routeKey( key[0], key[1] ); }, 1 );
        else
            this._blocked = false;
    };

    GridRowWidget.prototype.refresh = function()
    {
        var index = -1, cell, ii;

        // validate any cells currently allocated

        if( this._cells.length > 0 )
        {
            ii = index = this.getIndex( this._cells[0].listing.getStartTime() );

            for( var i = 0; i < this._cells.length; i++ )
            {
                if( this._cells[i].listing.getStartTime() !== this._listings[ii].getStartTime() || this._cells[i].listing.getEndTime() !== this._listings[ii].getEndTime() )
                {
                    if( _x2._focus === this )
                    {
                        console.error( "data did not match what was already being displayed, flushing existing cells" );
                        for( var j = 0; j < this._cells.length; j++ ) {
                            console.log( "==> " + j + " - " + new Date( this._cells[j].listing.getStartTime() ) );
                            if( this._cells[j].listing.isTbd() ) {
                                this._cells[j].sep.setA( 0 );
                                this._cells[j].title.setA( 0 );
                                this._cells.splice(j, 1);
                            } //remove tbd cell, if it is out of current grid window
                        }
                    }

                    while( this._cells.length > 0 )
                    {
                        cell = this._cells.pop();
                        cell.sep.setA( 0 );
                        cell.title.setA( 0 );
                        cell.title.setVal( Widget.FONT_COLOR, "#e8e8e8" );
                        this._pool.push( cell );
                    }

                    ii = this.getIndex( this._lastGridTime );

                    break;
                }
                else
                {
                    this._cells[i].listing = this._listings[ii];
                    ii++;
                }
            }
        }
        else
        {
            ii = this.getIndex( this._lastGridTime );
        }

        // add cells that needed to the right

        var maxTime = this._lastGridTime + 6 * 30 * 60 * 1000;

        while( this._listings[ii] && this._listings[ii].getStartTime() < maxTime )
        {
            cell         = this._pool.pop() || this.createCell();
            cell.listing = this._listings[ii];
            this.positionCell( cell );
            this._cells.push( cell );    
            ii++;
        }

        // add cells that are needed to the left

        var minIndex = this.getIndex( this._lastGridTime );

        for( i = index - 1; i >= minIndex; i-- )
        {
            if( this._listings[i] )
            {
                cell         = this._pool.pop() || this.createCell();
                cell.listing = this._listings[i];
                this.positionCell( cell );
                this._cells.unshift( cell );
            }
        }
        //After adding all cells, we need check again whether first block is TBD and
        //for which data are update from api, if so we need to hide that cell
        if( this._cells[0] && this._cells[0].listing.isTbd() ) {
            var lastUpdateGridTime = this._driver._gridTime;
            var gapStartTime = this._cells[0].listing.getStartTime();
            var gapEndTime = this._cells[0].listing.getEndTime();
            if(  gapStartTime <= lastUpdateGridTime || gapEndTime <= lastUpdateGridTime ) {
                this._cells[0].sep.setA( 0 );
                this._cells[0].title.setA( 0 );
                this._cells.shift();
            }
        }
        if( this._channel && _x2._focus._channel 
            && this._channel.getNumber() == _x2._focus._channel.getNumber() 
            && this._repeating !== true )
        {
            _x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "FOCUS INDEX = " + this._focusIndex ) : Config.NOP();
            this.gotFocus();
        }
    };

    GridRowWidget.prototype.refreshCell = function()
    {
        _x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "REFESH A GRID ROW" ) : Config.NOP();
        _x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( this )                : Config.NOP();

        this._lastGridTime = this._driver._gridTime;

        if( _x2._focus === this )
            this.lostFocus();

        if( this._channel )
            this.setData( this._channel );

        if( _x2._focus === this )
        {
            this._focusIndex = -1;

            for( var i = 0; i < this._cells.length; i++ )
            {
                if( this._cells[i].listing.getEndTime() > this._driver._focusColTime )
                {
                    this._focusIndex = i;
                    break;
                }
            }

            this.setHighlight();
        }
    };

    /**
     * @memberof GridRowWidget
     * @param {Channel} data - Must support: getLogoUrl() getNumber()
     */
    GridRowWidget.prototype.setData = function( data )
    {
        this._channel = data;

        if( data !== undefined && this._layoutReady === true )
        {
            var i, cell;

            this._chanImage.setA( 0 );
            this._chanImage.setW();
            this._chanImage.setH();
            this._chanImage.setUrl();
            this._chanImage.setUrl( data.getLogoUrl(50,30) );
            this._callSignStr.setA( 0 );

            if( _x2._favorites.isFavorite( this._channel ) === true )
                this._star.setState( 1 );
            else
                this._star.setState( 0 );

            this._chanStr.setText( data.getNumber() );
            this.setA( 1 );

            // reset all the listing cells

            while( this._cells.length > 0 )
            {
                cell = this._cells.shift();
                cell.sep.setA( 0 );
                cell.title.setA( 0 );
                cell.title.setVal( Widget.FONT_COLOR, "#e8e8e8" );
                this._pool.push( cell )
            }

            // set the listings

            this._listings     = data.getSchedules();
            this._lastGridTime = this._driver._gridTime;

            // find first record visible

            var index;

            for( i = 0; i < this._listings.length; i++ )
            {
                if( this._listings[i].getEndTime() > this._lastGridTime )
                {
                    index = i;
                    break;
                }
            }

            var maxTime = this._lastGridTime + 6 * 30 * 60 * 1000;

            if( pixPerMs === undefined )
                pixPerMs = _x2.scaleValInt( 291 ) / (30 * 60 * 1000);

            i = 0;

            while( this._listings[index] && this._listings[index].getStartTime() < maxTime )
            {
                this._cells[i]         = this._pool.pop() || this.createCell();
                this._cells[i].listing = this._listings[index];
                this.positionCell( this._cells[i] );
                
                if( i > 0 )
                    this._cells[i-1].next = this._cells[i];

                index++;
                i++;
            }

            this._needRefresh = true;
        }
        else
            this.setA( 0 );
    };

    GridRowWidget.prototype.refreshChannelInfo = function( setLastChannelFocus ) {

        if( setLastChannelFocus ) {
            _x2._hi.setA(1);
            this._channel.setFocus = false;
        }
        if( this._channel ) {
            if( _x2._favorites.isFavorite( this._channel ) === true )
                this._star.setState( 1 );
            else
                this._star.setState( 0 );
        }
        var isFocusContainsRow = _x2._focus.containsWidget( this );
        if( _x2._focus instanceof ListWidget ) {
            _x2._focus._rows = _x2._focus._rows || [];
            for( var i = 0; i < _x2._focus._rows.length; i++ ) {
                if( _x2._focus._rows[i].getNumber() == this._channel.getNumber() ) {
                    isFocusContainsRow = true;
                    break;
                }
            }
        }
        if( isFocusContainsRow ) {
            _x2.requestFocus(this);//for moving cursor to current cell
        }
    }

    GridRowWidget.prototype.setHighlight = function( time, offset, notChanCol, sayChannel, newScreen )
    {
        var self    = this;
        var obj     = this.getGlobalPos();
        var onHiEnd = function()
        {
            if( _x2._hi._onEnd[Widget.Axis.X] === onHiEnd )
            {
                _x2._hi._onEnd[Widget.Axis.X] = undefined;
                self.processQueue();
            }
        };

        if( this._favFocus === true )
        {
            this._star.gotFocus();
            this._chanStr.lostFocus();

            obj.x         -= this._favColOffset;
            obj.w          = this._chanColX; //this._chanColW;
            obj.h          = this.getHeightUnfocused() - ListRowWidget.DIVIDER_H;
            obj.duration   = (time === undefined) ? this._driver._stepTime : time;
            obj.easing     = Widget.Easing.LINEAR;
            obj.onEnd      = onHiEnd;
            obj.onEndAxis  = Widget.Axis.X;

            _x2._hi.setPosition( obj );

            if( this._star.getState() === 0 )
                this._chanSep.setSpeechParams( "Favorite unselected. Press OK to select. ", undefined, this, false );
            else
                this._chanSep.setSpeechParams( "Favorite selected. Press OK to unselect. ", undefined, this, false );
        }
        else if( notChanCol !== true && this._channelFocus === true )
        {
            this._channelFocus = true;

            this._chanStr.gotFocus();

            if( this._star.inFocusState() === true )
                this._star.lostFocus();

            obj.x         += this._chanColX;
            obj.w          = this._chanColW;
            obj.h          = this.getHeightUnfocused() - ListRowWidget.DIVIDER_H;
            obj.duration   = (time === undefined) ? this._driver._stepTime : time;
            obj.easing     = Widget.Easing.LINEAR;
            obj.onEnd      = onHiEnd;
            obj.onEndAxis  = Widget.Axis.X;

            _x2._hi.setPosition( obj );

            this._chanSep.setSpeechParams( "Channel " + this._chanStr.getText() + ". " + this._channel.getCallSign() + ". Press OK to view channel options. ", undefined, this, false );
        }
        else if( this._focusIndex >= 0 && this._cells.length > 0)
        {
            this._chanStr.lostFocus();

            if( this._star.inFocusState() === true )
                this._star.lostFocus();
            this._cells[this._focusIndex].title.gotFocus();

            offset = (offset === undefined) ? 0 : offset;

            var listing   = this._cells[this._focusIndex].listing;
            var startTime = listing.getStartTime();
            var endTime   = listing.getEndTime();
            var x         = this._minX + (startTime - this._lastGridTime) * pixPerMs + offset;

            if( x < this._minX )
                x = this._minX;

            obj.x         += x;
            obj.w          = this._minX + (endTime - this._lastGridTime) * pixPerMs + offset - x - ListRowWidget.DIVIDER_H;
            obj.h          = this.getHeightUnfocused() - ListRowWidget.DIVIDER_H;
            obj.duration   = (time === undefined) ? this._driver._stepTime : time;
            obj.easing     = Widget.Easing.LINEAR;
            obj.onEnd      = onHiEnd;
            obj.onEndAxis  = Widget.Axis.X;

            _x2._hi.setPosition( obj );

            this.speak( sayChannel, newScreen );
        }
        else
            self.processQueue();
    };

    GridRowWidget.prototype.speak = function( sayChannel, newScreen )
    {
        var obj     = this._cells[this._focusIndex];
        var now     = new Date().getTime();
        var str     = "";
        var start   = obj.listing.getStartTime();
        var end     = obj.listing.getEndTime();
        var timeStr = X2.prototype.createTimeStr( new Date( start ) );
        var hint;

        str += _x2.createTimeSpeechStr( timeStr ); //timeStr;
        str += obj.title.getText() + ". ";

        if( obj.listing )
        {
            if( now >= start && now < end )
            {
                str  += Math.floor((end - now) / 60000) + " minutes remaining. ";
                hint  = "Press OK for program details. "
            }
            else
            {
                str  += Math.floor((end - start) / 60000) + " minutes. ";
                hint  = "Press OK for program options. "
            }
        }

        if( sayChannel )
            str += "Channel " + this._chanStr.getText() + ". " + this._channel.getCallSign() + ". ";

        if( newScreen !== true )
            str += hint;

        this._chanSep.setSpeechParams( str, undefined, this, false );
    };

    GridRowWidget.prototype.update = function( step, alphaParent )
    {
        ListRowWidget.prototype.update.call( this, step, alphaParent );

        if( this._lastGridTime !== this._driver._gridTime )
        {
            var start, end, cell, toShift = 0, toPop = 0;
            var dir = this._driver._gridTime - this._lastGridTime;

            this._lastGridTime = this._driver._gridTime;

            var windowStart = this._driver._gridTime;
            var windowEnd   = this._driver._gridTime + 6 * 30 * 60 * 1000;

            for( var i = 0; i < this._cells.length; i++ )
            {
                if( this._cells[i].listing )
                {
                    start = this._cells[i].listing.getStartTime();
                    end   = this._cells[i].listing.getEndTime();

                    if( dir > 0 )
                    {
                        if( end > windowStart )
                            this.positionCell( this._cells[i] );
                        else
                            toShift++;
                    }
                    else
                    {
                        if( start < windowEnd )
                            this.positionCell( this._cells[i] );
                        else
                            toPop++;
                    }
                }
                else
                {
                    console.error( "======================>>>>>>>>>>>>>> Why is there no listing????" );
                    break;
                }
            }

            // shift off the cells that are off the left hand side

            while( toShift > 0 )
            {
                cell = this._cells.shift();
                cell.sep.setA( 0 );
                cell.title.setA( 0 );
                cell.title.setVal( Widget.FONT_COLOR, "#e8e8e8" );
                this._pool.push( cell );
                this._focusIndex--;
                toShift--;
            }

            // pop off the cells that are off the right hand side

            while( toPop > 0 )
            {
                cell = this._cells.pop();
                cell.sep.setA( 0 );
                cell.title.setA( 0 );
                cell.title.setVal( Widget.FONT_COLOR, "#e8e8e8" );
                this._pool.push( cell );

                toPop--;
            }

            // add any new cell that are needed on the left or the right

            if( dir > 0 )
            {
                var lastIndex = this._cells.length - 1;

                if( lastIndex > -1 && this._cells[lastIndex].listing.getEndTime() < windowEnd )
                {
                    // find listing

                    for( i = 0; i < this._listings.length; i++ )
                    {
                        if( this._listings[i].getStartTime() >= this._cells[lastIndex].listing.getEndTime() )
                        {
                            cell         = this._pool.pop() || this.createCell();
                            cell.listing = this._listings[i];
                            this._cells.push( cell );
                            this.positionCell( cell );

                            if( this._cells[this._cells.length - 1].listing.getEndTime() >= windowEnd )
                                break;
                        }
                    }

                    // if the listings did not get filled, then mark for refresh
                    if( this._needRefresh !== true )
                        this._needRefresh = true;
                }
            }
            else
            {
                if( this._cells.length > 0 && this._cells[0].listing.getStartTime() > windowStart )
                {
                    var index = this.getIndex( windowStart );

                    if( index >= 0 )
                    {
                        cell         = this._pool.pop() || this.createCell();
                        cell.listing = this._listings[index];
                        this._cells.unshift( cell );
                        this.positionCell( cell );
                        this._focusIndex++;
                        // if the listings did not get filled, then mark for refresh
                        if( this._needRefresh !== true )
                            this._needRefresh = true;
                    }
                } else if( this._cells[0] && this._cells[0].listing.isTbd() && this._needRefresh !== true ) {
                        this._needRefresh = true;
                }
            }
        }
        else
        {
            if( this._needRefresh === true && this._driver._dataReady === true )
            {
                this._needRefresh = false;
                this.refresh();
            }

            if( this._repeatEnded === true )
            {
                this._repeating   = false;
                this._repeatEnded = false;
                _x2._config.log( Config.CLASS_GRID, 1 ) ? console.log( "REPEAT MODE ENDED - " + this._focusIndex ) : Config.NOP();
                this._driver._focusColTime = this._lastGridTime || new Date().getTime(); //While focus should be moved to current grid time
                this._selectable = true;
                this.gotFocus();
            }
        }
    };

    return GridRowWidget;

})();
