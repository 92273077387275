// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ChannelInfoWidget = (function()
{
    "use strict";

    ChannelInfoWidget.prototype = new Widget();

    function ChannelInfoWidget(){}

    ChannelInfoWidget.prototype.getSpeechStr = function()
    {
        return this._speechStr;
    };

    ChannelInfoWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this, params );
        this._className = "ChannelInfoWidget";

        var self = this;
        var titleStyle, subTitleStyle, titleX, titleSize, layout, onNetworkLoaded;

        layout = function()
        {
            self._subTitleStr.setY( self._titleStr.getY() + self._titleStr.getH() + _x2.scaleValInt( 32 ) );
            self._layoutDone = true;

            if( self._onNowShowing )
                self.setData( self._onNowShowing, self._channel );
        };

        onNetworkLoaded = function()
        {
            var dw, dh, scale;
            var w = self._networkImage.getW();
            var h = self._networkImage.getH();

            if( w > 0 && h > 0 )
            {
                dw    = _x2.scaleValInt( 145 ) / w;
                dh    = _x2.scaleValInt(  87 ) / h;
                scale = ( dh < dw ) ? dh : dw;

                self._networkImage.setX( (self._networkBg.getW() - self._networkImage.getW()) / 2 );
                self._networkImage.setY( (self._networkBg.getH() - self._networkImage.getH()) / 2 );
                self._networkImage.setScale( scale );

                self._networkImage.setA( 1 );
            }
        };

        this.setW( _x2.scaleValInt( 1576 - 2 * 80 ) );
        titleX        = _x2.scaleValInt( 280 );
        titleSize     = _x2.scaleValInt( 48 );
        titleStyle    = new Style( { font: "light"  , color: "#e8e8e8", fontSize: titleSize, whiteSpace: "nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
        subTitleStyle = new Style( { font: "regular", color: "#e8e8e8", fontSize: _x2.scaleValInt( 27 ), whiteSpace: "nowrap", overflow:"hidden", textOverflow:"ellipsis" } );

        this.setA( 0 );

        //widgets
        this._titleStr          = new StringWidget().init( { style: titleStyle, text:"?????" } );
        this._subTitleStr       = new StringWidget().init( { style: subTitleStyle, text:"?????" } );
        this._networkImage      = new ImageWidget().init( { onChange: onNetworkLoaded, onError: function(){ self._networkImage.setA(0) } } );
        this._networkBg         = new RectWidget().init( { w: _x2.scaleValInt(240), h:_x2.scaleValInt(320), color:"#151515" } );

        this._titleStr.setW( this.getW() - titleX );
        this._subTitleStr.setW( this.getW() - titleX );
        this._titleStr.addReadyCallback( layout );

        this.addWidget( this._titleStr     , titleX, -parseInt( .3 * titleSize ) );
        this.addWidget( this._subTitleStr  , titleX                                 );
        this.addWidget( this._networkBg                                             );
        this.addWidget( this._networkImage                                          );

        return this;
    };

    ChannelInfoWidget.prototype.setData = function( onNowShowing, channel )
    {
        this._onNowShowing  = onNowShowing;
        this._channel       = channel;
        this._speechStr     = "";

        if( this._layoutDone )
        {
            var titleString, titles = [], entity = onNowShowing.getEntity();

            titles.push( onNowShowing.getTitle() );

            if( entity && entity.getType() === XtvApi.EntityType.EPISODE )
            {
                var series = entity.getSeries();
                if( series )
                {
                    if( titles.indexOf( series.getTitle() ) < 0 ) //avoid duplicates
                        titles.unshift( series.getTitle() );
                }
            }

            titleString = titles.join( " - ");

            this._titleStr.setText( channel.getNumber() + " " + channel.getCallSign() );
            this._subTitleStr.setText( "On Now: " + titleString );
            this._networkImage.setUrl();
            this._networkImage.setUrl( channel.getLogoUrl( _x2.scaleValInt( 145 ), _x2.scaleValInt( 87 ) ) );

            this._speechStr += "Channel " + channel.getNumber() + ", " + channel.getCallSign() + ". ";
            this._speechStr += this._subTitleStr.getText();

            if( this._speechDescStr !== undefined )
                this._speechStr += this._speechDescStr;

            this.animate( { a:1, duration:500 } );
        }
    };

    return ChannelInfoWidget;

})();
