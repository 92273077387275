// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.LastWatchedCellWidget = ( function()
{
    "use strict";

    LastWatchedCellWidget.prototype = new Widget();

    function LastWatchedCellWidget(){}

    LastWatchedCellWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 414 ) + _x2.scaleValInt( 19 );
    };

    LastWatchedCellWidget.prototype.getPosAndDimUnfocused = function()
    {
        return { pos:0, dim:this._focusH };
    };

    LastWatchedCellWidget.prototype.getTouchDim = function()
    {
        return _x2.scaleValInt( 360 ) * (_x2.scaleValInt( 270 ) + 2 * _x2.scaleValInt( 18 )) / _x2.scaleValInt( 270 );
    };

    LastWatchedCellWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        var obj;

        if( this._data )
        {
            this._title.setSpeechParams( undefined, undefined, this, newScreen );

            Widget.prototype.gotFocus.call( this );

            this._bg.animate   ( { xScale:this._scale, yScale:this._scale, duration:ListWidget.SCROLL_TIME_STEP } );
            this._title.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );

            obj          = this.getGlobalPos();
            obj.w        = this._focusW;
            obj.h        = this._focusH;
            obj.inner    = false;
            obj.duration = ListWidget.SCROLL_TIME_STEP;

            _x2._hi.setPosition( obj );
            _x2._shadow.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );
        }

        return this;
    };

    /*
     * @params {Object} params TODO:
     */
    LastWatchedCellWidget.prototype.init = function( params )
    {
        var self       = this;
        var titleStyle = new Style( { color:"#e8e8e8", font:"medium" , fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap" } );
        var chStyle    = new Style( { color:"#e8e8e8", font:"medium" , fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", textAlign:"center" } );

        Widget.prototype.init.call( this, params );
        this._className   = "LastWatchedCellWidget";
        this._selectable  = true;
        this._videoScreen = params.obj;
        this._imageX      = _x2.scaleValInt( 19 );
        this._srcImageW   = _x2.scaleValInt( 414 );
        this._srcImageH   = _x2.scaleValInt( 276 );
        this._actualImageW = 416;
        this._actualImageH = 312;
        this._logoImageW = _x2.scaleValInt( 180 );
        this._logoImageH = _x2.scaleValInt( 120 );
        this._imageCallbackDimensions = {
            'first': { width: 375, height: 500}, //3:4 ratio
            'second': { width: 414, height: 276}, //3:2 ratio
            'defaultImageUrl': '/select/default/image?width=416&height=312&entityType=Program&entitySubType=Other' //4:3 ratio
        }
        this._scale       = (this._srcImageW + 2 * this._imageX) / this._srcImageW;
        this._focusW      = this._scale * this._srcImageW;
        this._focusH      = this._scale * this._srcImageH;

        var onImageChanged = function()
        {
            var entity = self._data.entity;

            if( entity instanceof Recording || entity instanceof VodShowing || entity instanceof Entity )
            {
                self._image.setX( 0 );
                self._image.setY( 0 );
            }
            else if( entity instanceof Channel || entity instanceof LinearShowing )
            {
                self._image.setX( (self._bg.getW() - self._image.getW()) / 2 );
                self._image.setY( (self._bg.getH() - self._image.getH()) / 2 );
            }
            else
            {
                console.error( "Need to support entity type of " );
                console.error( entity );
            }
            var imageUrl = self._image.getUrl();
            if( imageUrl && imageUrl.indexOf('logo') > -1 ) { //if it is logo image, then we should not inherit from parent, because it will be of small size.
                self._image._div.style.width = 'unset';
                self._image._div.style.height =  'unset';
                self._image._div.style.top = _x2.scaleValInt( 59 ) + 'px';
                self._image._div.style.left = _x2.scaleValInt( 118 ) + 'px';//depends on screen size it get adjust
                if( self._image._div.width !== self._logoImageW ) {
                    self._image._div.style.left =  Math.ceil( (self._bg._div.clientWidth - self._image._div.clientWidth)/2 ) + 'px';
                }//if width is not match with request width, then we need to center it in vertical 
                if( self._image._div.height !== self._logoImageH ) {
                    self._image._div.style.top =  Math.ceil( (self._bg._div.clientHeight - self._image._div.clientHeight - 26 )/2 ) + 'px';
                }//if height is not match with request height, then we need to center it in horizontal
            } else {
                self._image._div.style.width = 'inherit';
                self._image._div.style.height = 'inherit';
                self._image._div.style.top = '0';
                self._image._div.style.left = '0';
            }
            self._image.setA(1);
        };

        //Last watch cell was changed to 3:2 ratio (300 X 200), 
        //so we request for 4:3 image for first time with _actualImageW X _actualImageH,
        //if 4:3 image not found, then we should request image with _imageCallBackDimensions['first'],
        //if 3:4 image not found, then we should request image with _imageCallBackDimensions['second'],
        //3:2 image not found, then we should request default image
        var onImageError = function()
        {
            var imageUrl = self._image.getUrl();

            if( !imageUrl || imageUrl.indexOf('default') > -1 ) {
                self._image.setA( 0 );//hide image, if image is not found  or not match last callback dimension
            } else if( imageUrl.indexOf('height=' + self._actualImageH ) > -1 ) {
                self._image.setUrlWithDimension( self._imageCallbackDimensions['first'].width, self._imageCallbackDimensions['first'].height );
            } else if( imageUrl.indexOf('height=' + self._imageCallbackDimensions['first'].height ) > -1 ) {
                self._image.setUrlWithDimension( self._imageCallbackDimensions['second'].width, self._imageCallbackDimensions['second'].height );
            } else {
                var callbackUrl = imageUrl.replace(/\/select(.*)/g, self._imageCallbackDimensions.defaultImageUrl );
                self._image.setUrl(  callbackUrl );
            }
        };

        this._bg = new RectWidget().init( { w:this._srcImageW, h:this._srcImageH, color:"#303030" } );
        this.addWidget( this._bg, this._imageX, (this._scale - 1) * this._srcImageH / 2 );

        this._image = new ImageWidget().init( { onError:onImageError, onChange:onImageChanged } );
        this._bg.addWidget( this._image );

        this._title = new StringWidget().init( { style:titleStyle } );
        this._title.setA( 0 );
        this.addWidget( this._title, 0, this._srcImageH + 2 * this._bg.getY() + _x2.scaleValInt( 20 ) );

        this._chNum = new StringWidget().init( { text:"?", style:chStyle } );
        this._chNum.setA( 0 );
        this._chNum.setW( this._srcImageW );
        this._chNum.addReadyCallback( function() { self._chNum.setY( self._srcImageH - self._chNum.getH() - 2 * Style._pad ); } );
        this._bg.addWidget( this._chNum );

//         var onImageError = function()
//         {
//             var fallback = self._data.getFallbackImageLink( self._imageW, self._imageH );
//             if( self._image.getUrl() === fallback )
//                 self._image.setA( 0 ); //fallback already tried. hide image.
//             else
//                 self._image.setUrl( fallback );
//
//             // AAECH-59
//             //    Where   Screen        self =
//             // 1. Search  SearchScreen  SearchEntity.
//             // 2. LiveTV  GuideScreen   LinearShowing.getFallbackImageLink() -> getProgram().getFallbackImageLink()
//             // self._image.setUrl( self._image.getUrl() + "&default=force" );
//         };

        this.setW( this.getHeightUnfocused() );
        this.setH( params.h );

        return this;
    };

    LastWatchedCellWidget.prototype.isListBlocking = function()
    {
        var retval = false, widget = this;

        while( widget )
        {
            var parent = widget._parent;

            if( parent instanceof ListWidget )
            {
                if( parent._blocked === true )
                    retval = true;
                break;
            }
            else
                widget = parent;
        }

        return retval;
    };

    LastWatchedCellWidget.prototype.lostFocus = function()
    {
        Widget.prototype.lostFocus.call( this );

        _x2._shadow.stopAnimation( Widget.ALPHA );
        _x2._shadow.setA( 0 );

        this._bg.stopAnimation( Widget.X_SCALE );
        this._bg.stopAnimation( Widget.Y_SCALE );
        this._bg.animate( { xScale:1, yScale:1, duration:ListWidget.SCROLL_TIME_STEP } );

        this._title.stopAnimation( Widget.ALPHA );
        this._title.animate( { alpha:0, duration:ListWidget.SCROLL_TIME_STEP } );
    };

    LastWatchedCellWidget.prototype.processEvent = function( val, type )
    {
        var retval, self = this;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    if( this.isListBlocking() === false && this._data )
                    {
                        var entity = null;
                        if( this._data.entity instanceof Channel ) {
                            entity = ( !this._sched )? this._data.entity._schedules[0] : this._sched;
                        } else {
                            entity = this._data.entity;
                        }

                        var data = this._data.data;
                        var rp   = entity.getResumePoint && entity.getResumePoint();
                        var play = function()
                        {
                            var obj =
                            {
                                url        :data.url,
                                mediaId    :data.mediaId,
                                programId  :data.programId,
                                paid       :data.paid,
                                mode       :data.mode,
                                entity     :entity,
                                resume     : rp ? rp.getPosition() : 0,
                                hasSap     :data.hasSap,
                                hasDvs     :data.hasDvs,
                                streamId   :data.streamId,
                                assetId    :data.assetId,
                                providerId :data.providerId,
                                mediaGuid  :data.mediaGuid,
                                recordingId:data.recordingId
                            };

                            if( self._videoScreen instanceof VideoScreen ) // If currently watching from video player
                                self._videoScreen.sendVideoPlayedLocalytics();

                            _x2._telemetry.watchPathReset();
                            _x2._telemetry.setWatchButton( "Player: Last Watched" ); // Can switch from Linear to Last Watched inside player

                            self._videoScreen.play( obj );
                        };

                        if( self._videoScreen instanceof VideoScreen && data.url === self._videoScreen._url )
                        {
                            self._videoScreen.hideLastWatched();
                        }
                        else
                        {
                            if( _x2._pc.isEnabled() === true )
                                _x2.checkParentalControls( entity, undefined, undefined ).then( play );
                            else
                                play();
                        }
                    }

                    retval = true;
                }
                break;

            default:
                retval = Widget.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    LastWatchedCellWidget.prototype.setData = function( data )
    {
        var entity, self = this;

        this._sched = undefined;
        this._data = data;

        if( data !== undefined )
        {
            entity = data.entity;

            this._chNum.setA( 0 );
            this._image.setUrl('');//Set empty for clear existing image
            if( entity instanceof Recording )
            {
                this._image.setUrl( entity.getImageLink( this._actualImageW, this._actualImageH ) );
                this._title.setText( entity.getTitle() );
            }
            else if( entity instanceof Channel )
            {
                var now       = new Date().getTime(), sched;
                var schedules = entity.getSchedules();

                if( schedules )
                    for( var i = 0; i < schedules.length; i++ )
                        if( schedules[i].getEndTime() > now )
                        {
                            this._sched = sched = schedules[i];
                            break;
                        }
                
                if( sched ) {
                    data.url      = sched._channel.getStreamUrl();
                    data.mode     = VideoScreen.Mode.LIVE;
                    data.streamId = sched._channel.getStreamId();
                }
                var imageLogoUrl = entity.getLogoUrl( self._logoImageW, self._logoImageH );
                imageLogoUrl = imageLogoUrl.replace('&extent=true', '');//Remove extent=true
                this._image.setUrl( imageLogoUrl );
                this._title.setText( "On Now: " +  (sched ? sched.getGridTitle() : "TBD") );
                this._chNum.setText( entity.getNumber() );
                this._chNum.setA( 1 );
            }
            else if( entity instanceof Entity )
            {
                this._image.setUrl( entity.getImageLink( this._actualImageW, this._actualImageH ) );
                this._title.setText( entity.getTitle() );
            }
            else if( entity instanceof VodShowing )
            {
                var onFetchProgram = function()
                {
                    self._image.setUrl( entity.getImageLink( self._actualImageW, self._actualImageH ) );
                    self._title.setText( entity.getTitle() );
                    if( _x2._config._showResumePoint ) {
                        var resumePoint = entity.getResumePoint && entity.getResumePoint();
                        var lastPosition = resumePoint && resumePoint.getPosition() || 0;
                        lastPosition = Math.floor( lastPosition / 1000 );
                        self._title.setText( "Resume from " + self.getLastDuration(lastPosition) + " - " + self._title.getText() );
                    }
                };

                var onFetchProgramFail = function( error )
                {
                    console.error( error );
                    self._title.setText( "" );
                };

                if( entity.getProgram() )
                    onFetchProgram();
                else
                    entity.fetchProgram().then( onFetchProgram, onFetchProgramFail );
            }
            else
            {
                console.error( "Need to support entity type of " );
                console.error( entity );
            }

            if( _x2._config._showResumePoint && !(entity instanceof VodShowing) ) {
                var resumePoint = entity.getResumePoint && entity.getResumePoint();
                var lastPosition = resumePoint && resumePoint.getPosition() || 0;
                lastPosition = Math.floor( lastPosition / 1000 );
                this._title.setText( "Resume from " + this.getLastDuration(lastPosition) + " - " + this._title.getText() );
            }
            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    LastWatchedCellWidget.prototype.getLastDuration = function(totalSeconds) {
        var hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        var minutes = Math.floor(totalSeconds / 60);
        var seconds = totalSeconds % 60;
        return hours + ":" + minutes + ":" + seconds ;
    }
    return LastWatchedCellWidget;

})();
