// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.LruCacheTestScreen = ( function()
{
    "use strict";

    LruCacheTestScreen.prototype = new Screen();

    function LruCacheTestScreen(){}

    LruCacheTestScreen.prototype.init = function()
    {
        this._className = "LruCacheTestScreen";

        Screen.prototype.init.call( this );

        this._cache = new LruCache( 5 );

//         this._cache.put( "one", "ONE" );
//         this._cache.dump();
//
//         this._cache.remove( "one" );
//         this._cache.dump();
//
//         this._cache.put( "one", "ONE" );
//         this._cache.put( "two", "TWO" );
//         this._cache.dump();
//
//         this._cache.put( "three", "THREE" );
//         this._cache.dump();

        return this;
    };

    LruCacheTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_1:
                if( type === Host.KEY_PRESSED )
                    this._cache.put( "one", "ONE", 1 );
                break;

            case Host.KEY_2:
                if( type === Host.KEY_PRESSED )
                    this._cache.put( "two", "TWO", 2 );
                break;

            case Host.KEY_3:
                if( type === Host.KEY_PRESSED )
                    this._cache.put( "three", "THREE", 1 );
                break;

            case Host.KEY_4:
                if( type === Host.KEY_PRESSED )
                    this._cache.put( "four", "FOUR", 2 );
                break;

            case Host.KEY_5:
                if( type === Host.KEY_PRESSED )
                    this._cache.put( "five", "FIVE", 1 );
                break;

            case Host.KEY_6:
                if( type === Host.KEY_PRESSED )
                    this._cache.remove( "one" );
                break;

            case Host.KEY_7:
                if( type === Host.KEY_PRESSED )
                    this._cache.remove( "two" );
                break;

            case Host.KEY_8:
                if( type === Host.KEY_PRESSED )
                    this._cache.remove( "three" );
                break;

            case Host.KEY_9:
                if( type === Host.KEY_PRESSED )
                    this._cache.remove( "four" );
                break;

            case Host.KEY_0:
                if( type === Host.KEY_PRESSED )
                    this._cache.remove( "five" );
                break;

            case Host.KEY_Q:
                if( type === Host.KEY_PRESSED )
                {
                    console.log( "GETTING ONE" );
                    console.log( "one = " + this._cache.get( "one" ) );
                }
                break;

            case Host.KEY_SOFT_D:
                if( type === Host.KEY_PRESSED )
                    this._cache.dump();
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    return LruCacheTestScreen;

})();
