// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.AuthCodeScreen = ( function()
{
    "use strict";

    AuthCodeScreen.prototype = new Screen();

    function AuthCodeScreen(){}

    AuthCodeScreen.prototype.getNewCode = function()
    {
        var self = this;

        for( var i = 0; i < this._chars.length; i++ )
            this._chars[i].setText( "" );

        this._getNewCodeFunc( function( params ) { self.setCode( params ); } );
    };

    AuthCodeScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._button );

        this._speakTarget.setText( this._titleStr.getText() + ", " + this._instructions + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press OK to select. Press left to repeat code. Press right to reread instructions. ", focus._speakRole, this, false );
    };

    AuthCodeScreen.prototype.setCode = function( params )
    {
        this._code = "";

        for( var i = 0; i < this._chars.length; i++ )
        {
            this._chars[i].setText( params.code.substr( i, 1 ) );
            this._code += this._chars[i].getText() + ". ";
        }

        this._speakTarget.setText( this._code );
        this._speakTarget.setSpeechParams( "", undefined, this, false );

        params.pollFunc( this._onSuccess, this._onError );
    };

    AuthCodeScreen.prototype.init = function( onAuth, params )
    {
        Screen.prototype.init.call( this, { noHeader:true, noSep:true } );
        this._className     = "AuthCodeScreen";

        this._getNewCodeFunc  = params.getNewCodeFunc;
        this._cells           = new Array( params.code.length );
        this._chars           = new Array( params.code.length );
        this._numImagesLoaded = 0;
        this._numImagesNeeded = params.code.length + 1;

        var i, self = this;
        var onLoad      = function() { self.layout(); };
        var titleStyle  = new Style( { font:"thin"   , color:"#e8e8e8", fontSize:_x2.scaleValInt(100), whiteSpace:"nowrap" } );
        var stepStyle   = new Style( { font:"bold"   , color:"#d1d1d1", fontSize:_x2.scaleValInt( 24), whiteSpace:"nowrap" } );
        var textStyle   = new Style( { font:"regular", color:"#8c8c8c", fontSize:_x2.scaleValInt( 30), whiteSpace:"nowrap" } );
        var codeStyle   = new Style( { font:"bold"   , color:"#000000", fontSize:_x2.scaleValInt( 70), whiteSpace:"nowrap", textAlign :"center" } );
        var buttonStyle = new Style( { font:"regular", color:"#e8e8e8", fontSize:_x2.scaleValInt( 32), whiteSpace:"nowrap", colorHi:"#000000" } );
        var url         = (params.url === "https://login.comcast.net/oauth/device/activate") ? "http://bit.ly/2cp8Ft4" : params.url;
        var button      = new StringWidget().init( { text:"Need a New Code?", style:buttonStyle, onEnter:function() { self.getNewCode() } } );

        this.addWidget( new ImageWidget().init( { url:_x2._config._imageRootMso + "activationBg.jpg", onLoad:onLoad } ) );

        this._container = new Widget().init();
        this._titleStr  = new StringWidget().init( { text:"Let's get started!", style:titleStyle } );
        this._step1Str  = new StringWidget().init( { text:"STEP 1", style:stepStyle } );
        this._text1Str  = new TextBlockWidget().init( { text:"Go to <font color='#e8e8e8'>" + url + "</font> on your computer or mobile device.", style:textStyle, w:_x2._config._screenW - _x2.scaleValInt(768) - Style._safeRight } );
        this._step2Str  = new StringWidget().init( { text:"STEP 2", style:stepStyle } );
        this._text2Str  = new StringWidget().init( { text:"Enter the following code:", style:textStyle } );

        this._code = "";

        for( i = 0; i < this._cells.length; i++ )
        {
            this._cells[i] = new ImageWidget().init( { url:_x2._config._imageRoot+"codeCell.png", onLoad:onLoad } );
            this._chars[i] = new StringWidget().init( { text:params.code.substr( i, 1 ), style:codeStyle } );
            this._container.addWidget( this._cells[i] );
            this._container.addWidget( this._chars[i] );
            this._code += this._chars[i].getText() + ". ";
        }

        this._button = new HorizPillButtonsWidget().init( { entries:[button], spacing:_x2.scaleValInt(60) } );

        this._container.setA( 0 );

        this.addWidget( this._container );
        this._container.addWidget( this._titleStr  );
        this._container.addWidget( this._step1Str  );
        this._container.addWidget( this._text1Str  );
        this._container.addWidget( this._step2Str  );
        this._container.addWidget( this._text2Str  );
        this._container.addWidget( this._button    );

        this._onSuccess = function()
        {
            _x2.pushScreen( new ProvisioningScreen().init( onAuth ) );
        };

        this._onError = function()
        {
            console.log( "ERROR -> AUTH TOKEN FAILED" );  // TODO need some sort of error overlay?

            for( var i = 0; i < self._chars.length; i++ )
                self._chars[i].setText( "" );
        };

        params.pollFunc( this._onSuccess, this._onError );

        _x2._config.log( Config.CLASS_LOCALYTICS, 1 ) ? console.log( "Localytics: Activation Code screen" ) : Config.NOP();
        window.ll( "tagScreen", "Activation Code" ); // Localytics 2.1.1

        this._instructions = this._step1Str.getText() + ". " + this._text1Str.getText().replace( /xfinity/gi, "x finity" ) + " " +
                             this._step2Str.getText() + ". " + this._text2Str.getText() + " " + this._code;

        return this;
    };

    AuthCodeScreen.prototype.layout = function()
    {
        if( ++this._numImagesLoaded === this._numImagesNeeded )
        {
            var xx, yy, i, w;

            var x = _x2.scaleValInt(768);
            var y = _x2.scaleValInt(231);
            this._titleStr.setX( x );
            this._titleStr.setY( y );

            y += this._titleStr.getH() + _x2.scaleValInt(40);
            this._step1Str.setX( x );
            this._step1Str.setY( y );

            y += this._step1Str.getH() + _x2.scaleValInt(14);
            this._text1Str.setX( x );
            this._text1Str.setY( y );

            y += this._text1Str.getH() + _x2.scaleValInt(50);
            this._step2Str.setX( x );
            this._step2Str.setY( y );

            y += this._step2Str.getH() + _x2.scaleValInt(14);
            this._text2Str.setX( x );
            this._text2Str.setY( y );

            xx  = x;
            y  += this._text2Str.getH() + _x2.scaleValInt(30);
            yy  = y + (this._cells[0].getH() - this._chars[0].getH()) / 2;
            w   = this._cells[0].getW();

            for( i = 0; i < this._cells.length; i++ )
            {
                this._cells[i].setX( xx );
                this._cells[i].setY( y  );
                this._chars[i].setX( xx );
                this._chars[i].setY( yy );
                this._chars[i].setW( w  );
                xx += this._cells[i].getW() + _x2.scaleValInt(12);
            }

            y += this._cells[0].getH() + _x2.scaleValInt(60);
            this._button.setX( x );
            this._button.setY( y );

            this._container.animate( { a:1, duration:500 } );
        }
    };

    AuthCodeScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        if( val === Host.KEY_LAST || val === Host.KEY_QUIT )
            retval = false;
        else if( type === Host.KEY_PRESSED && _x2._focus !== this._button )
            _x2.requestFocus( this._button );

        switch( val )
        {
            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                {
                    this._speakTarget.setText( this._code );
                    this._speakTarget.setSpeechParams( "", undefined, this, false );
                }
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                {
                    this._speakTarget.setText( this._instructions );
                    this._speakTarget.setSpeechParams( "", undefined, this, false );
                }
                break;
        }

        return retval;
    };

    return AuthCodeScreen;

})();
