// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PolicyLinksOverlay = ( function()
{
    "use strict";

    var sectionMap = {
      terms: function(descStyle, urlStyle) {
        return [
          [
            {
              text: 'To read Xfinity Stream\'s License Agreement go to',
              style: descStyle
            },
            {
              text: _x2._config._isSmbUser === true ? 'https://business.comcast.com/terms-conditions-smb' : 'my.xfinity.com/terms',
              style: urlStyle
            }
          ]
        ];
      },

      ccpaTerms: function(descStyle, urlStyle) {
        return [
          [
            {
              text: 'To read Xfinity Stream\'s License Agreement go to',
              style: descStyle
            },
            {
              text: _x2._config._isSmbUser === true ? 'https://business.comcast.com/terms-conditions-smb' : 'my.xfinity.com/terms',
              style: urlStyle
            }
          ]
        ];
      },

      privacy: function(descStyle, urlStyle) {
        return [
          [
            {
              text: 'Visit these links to learn more about Comcast Privacy:',
              style: descStyle
            }
          ],
          [
            {
              text: 'Privacy Center',
              style: descStyle
            },
            {
              text: 'www.xfinity.com/privacy',
              style: urlStyle
            }
          ],
          [
            {
              text: 'Privacy Policy',
              style: descStyle
            },
            {
              text: _x2._config._isSmbUser === true ? 'https://business.comcast.com/privacy-statement' : 'www.xfinity.com/privacy/policy',
              style: urlStyle
            }
          ],
          [
            {
              text: 'Cal. Civ. Code §1798.135: Do Not Sell My Info',
              style: descStyle
            },
            {
              text: 'www.xfinity.com/privacy/manage-preference',
              style: urlStyle
            }
          ]
        ];
      }
    };

    PolicyLinksOverlay.prototype = new Overlay();

    function PolicyLinksOverlay(){}

    PolicyLinksOverlay.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();

        var str = this._sections.map(function(section) {
          return section.map(function(line) {
            return line.element.getText();
          }).join(' ') + '.';
        }).join(' ');

        str = str.replace( /xfinity/gi, "x_finity" );

        this._speakTarget.setText( str );
        this._speakTarget.setSpeechParams( "Press back to dismiss.", undefined, this, false );
    };

    PolicyLinksOverlay.prototype.init = function(options)
    {
        Overlay.prototype.init.call( this );
        this._className  = "PolicyLinksOverlay";

        var self      = this;
        var descStyle = new Style( { font:"regular", color:"#a3a3a3", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", textAlign:"center" } );
        var urlStyle  = new Style( { font:"regular", color:"#e8e8e8", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap", textAlign:"center" } );
        var onLoad    = function() { self._numLoaded++; self.layoutPolicyLinksOverlay() };

        this._numLoaded = 0;

        this._sections = sectionMap[options.type](descStyle, urlStyle);

        this._sections.forEach(function(section) {
          section.forEach(function(line) {
            line.element = new StringWidget().init(line);
            this._bg.addWidget(line.element);
          }.bind(this));
        }.bind(this));

        var lastSection = this._sections[this._sections.length - 1];
        this._lastLine = lastSection[lastSection.length - 1];

        this._lastLine.element.addReadyCallback(onLoad);
        
        var iconImage = (options.type == 'privacy')? 'settingsTermsPolicyIcon.png' : 'termsIcon.png';
        this._image = new ImageWidget().init( { url:_x2._config._imageRoot + iconImage, onLoad:onLoad } );
        this._bg.addWidget( this._image );

        return this;
    };

    PolicyLinksOverlay.prototype.layoutPolicyLinksOverlay = function()
    {
        if( this._numLoaded === 2 )
        {
          var sectionSpacing = _x2.scaleValInt(54);

          this._sections.reduce(function(posY, section) {
            return sectionSpacing + section.reduce(function(posY, line) {
              line.w = line.element.getW();
              line.h = line.element.getH();
              line.y = posY;

              return posY + line.h;
            }, posY);
          }, 0);

          var textW = this._sections.reduce(function(memo, section) {
            return section.reduce(function(memo, line) {
              return Math.max(memo, line.w);
            }, memo);
          }, 0);
          var textH = this._lastLine.y + this._lastLine.h;
          var bgW = this._bg.getW();
          var bgH = this._bg.getH();
          var imW = this._image.getW();
          var imH = this._image.getH();
          var imX = (bgW - imW - textW) / 3;
          var imY = (bgH - imH) / 2;
          var w = bgW - imX - imW;
          var x = imX + imW;

          this._image.setX(imX);
          this._image.setY(imY);

          var textOffset = (bgH - textH) / 2;
          this._sections.forEach(function(section) {
            section.forEach(function(line) {
              line.element.setW(w);
              line.element.setX(x);
              line.element.setY(line.y + textOffset);
            });
          });
        }
    };

    return PolicyLinksOverlay;

})();
