// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Channel is just what you think it is. Maps to a XTV "TelevisionStation/Channel"
 */
window.Channel = (function()
{
    "use strict";

    Channel.prototype = new DataObject();

    function Channel(){}

    /**
     * Initialize this object
     * @memberOf Channel
     * @param {Object} data - XTV derived JSON object
     * @return {Channel}
     */
    Channel.prototype.init = function( data )
    {
        DataObject.prototype.init.call( this, data );

        this._schedules = [];
        return this;
    };

    /**
     * Get the channel number for the 'associated' channel. If this is a SD channel it will return the corresponding HD channel number.
     * If this is an HD channel it will return the SD channel number.
     * @return {string} channel number
     */
    Channel.prototype.getAssocChannelNumber = function()
    {
        var channelNumber, index, keys;

        var string = this.getPath(["_links", "associated", "href" ]);

        if( string )
            keys = string.split(":");

        if( keys && keys.length )
        {
            index = keys.indexOf('chNum');
            if( index >= 0 && keys.length > index )
                channelNumber = keys[index+1];
        }

        return channelNumber;
    };

    /**
     * Get the channels callsign. Example: "KCNC" (NOT "CBS").
     * @memberOf Channel
     * @return {String}
     */
    Channel.prototype.getCallSign = function()
    {
        return this.get("callSign");
    };

    /**
     * Get the currently airing showing on this channel
     * @returns {Promise} LinearShowing
     */
    Channel.prototype.getCurrentlyAiring = function()
    {
        var promise, self = this, now = Date.now();
        if( self._onNow )
        {
            if( self._onNow.getStartTime() <= now && self._onNow.getEndTime() > now )
                promise = Promise.resolve( self._onNow );
        }

        if( ! promise )
        {
            var resolver = function( resolve )
            {
                _x2._data.getLinearScheduleByTime( self.getId(), now ).then(
                    function( showing )
                    {
                        showing.setChannel( self );
                        self._onNow = showing;
                        resolve( showing );
                    }
                );
            };

            promise = new Promise( resolver );
        }

        return promise;
    };

    /**
     * Return the HD version of this channel
     * @return {Channel}
     */
    Channel.prototype.getHdComplement = function()
    {
        return this._hdComplment;
    };

    /**
     * Get the ID for this channel (Merlin? ID)
     * @return {String}
     */
    Channel.prototype.getId = function()
    {
        return this.get("channelId");
    };

    /**
     * Get the channel number
     * @memberOf Channel
     * @return {Number}
     */
    Channel.prototype.getNumber = function()
    {
        return this.get("number");
    };

    /**
     * Get all loaded schedules for this channel
     * @memberOf Channel
     * @return {Array}
     */
    Channel.prototype.getSchedules = function()
    {
        return this._schedules;
    };

    /**
     * Get the name of the company (network) for this channel. Example: CBS
     * @memberOf Channel
     * @return {String}
     */
    Channel.prototype.getCompany = function()
    {
        return this.get("branchOf/company/callSign");
    };

    /**
     * Get the company logo for this channel
     * @memberOf Channel
     * @param {Number} [width] - Image width. Defaults to 50
     * @param {Number} [height] - Image height. Defaults to 30
     * @return {String|undefined}
     */
    Channel.prototype.getLogoUrl = function( width, height )
    {
        var link = this.getLink("logo");
        if( link )
            return XtvApi.replaceUrlParams( link, { width: width || 50, height: height || 30 } );

        return undefined;
    };

    /**
     * Get the station id. Example: "7644483893567419117"
     * @memberOf Channel
     * @return {String}
     */
    Channel.prototype.getStationId = function()
    {
        return this.get("stationId");
    };

    Channel.prototype.getSelfLink = function()
    {
        return this.getLink("self");
    };

    Channel.prototype.getStreamId = function()
    {
        return this.get("streamId");
    };

    Channel.prototype.getStreamUrl = function()
    {
        var url, streams = this.getEmbedded("stream");

        if( streams )
            for( var i = 0; i < streams.length; i++ )
            {
                if( streams[i]["encodingFormat"] === "HLS" )
                {
                    url = this.getPath( ["_links","contentUrl","href"], streams[i] );
                    break;
                }
            }

        return url;
    };

    /**
     * Get the name of the voice hint for this channel. Example: Home Box Office
     * @memberOf Channel
     * @return {String}
     */
    Channel.prototype.getVoiceHint = function()
    {
        return this.get("callSignVoiceOverHint");
    };

    /**
     * Return true if this channel can be recorded
     * @memberOf Channel
     * @return {Boolean}
     */
    Channel.prototype.isRecordable = function()
    {
        return this.getBoolean("isRecordable");
    };

    /**
     * Return true if this channel is HD
     * @memberOf Channel
     * @return {Boolean}
     */
    Channel.prototype.isHD = function()
    {
        return this.getBoolean("isHD");
    };

    /**
     * Return true if this is a TV Everywhere channel
     * @memberOf Channel
     * @return {Boolean}
     */
    Channel.prototype.isTve = function()
    {
        return this.getBoolean("isTve");
    };

    /**
     * Return true if the user is entitled to this channel
     * @memberOf Channel
     * @return {Boolean}
     */
    Channel.prototype.isEntitled = function()
    {
        return this.getBoolean("entitled");
    };

    /**
     * Set the HD version of the channel
     * @param {Channel} channel
     */
    Channel.prototype.setHdComplement = function( channel )
    {
        this._hdComplment = channel;
    };

    /**
     * Copy the schedules from the given channel into this one.
     * @param channel
     * @param blockId
     */
    Channel.prototype.setSchedules = function( channel, blockId )
    {
        var i, listings, wasNotEmpty = ( this._schedules.length > 0 );

        listings = this.getPath( ["_data","_embedded","listings"], channel);

        if( listings )
        {
            for( i = 0; i < listings.length; i++ )
                this._schedules.push( new LinearShowing().init( listings[i], this, blockId ) );
        }

        if( this._schedules.length && wasNotEmpty )
        {
            this._schedules.sort( function( a, b )
            {
                return a.getStartTime() - b.getStartTime()
            } );

            for( i = 0; i < this._schedules.length; i++ )
            {
                if( i < this._schedules.length - 1 )
                {
                    if( this._schedules[i].getStartTime() === this._schedules[i+1].getStartTime() )
                        this._schedules.splice(i,1);
                }
            }
        }
    };

    return Channel;
})();
