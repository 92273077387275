// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.TextBlockTestScreen2 = ( function()
{
    "use strict";

    TextBlockTestScreen2.prototype = new Screen();

    function TextBlockTestScreen2(){}

    TextBlockTestScreen2.prototype.init = function()
    {
        var x, y;
        var width, height;
        var headStyle, bodyStyle;
        var fontSize = 36, dy = fontSize + 2;

        this._className = "TextBlockTestScreen2";

        Screen.prototype.init.call( this );

        this._size = fontSize;

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        this._text1 = undefined;
        this._text2 = "A prequel to the classic Robert Louis Stevenson novel \"Treasure Island,\" \"Black Sails\" is a pirate adventure that centers on the tales of Captain Flint, who has a reputation throughout the West Indies as being the most brilliant, most feared of all the Golden Age pirates. It's 1715, and as Flint fights for the survival of New Providence Island -- a debauched paradise teeming with pirates, prostitutes, thieves and fortune seekers -- in the wake of threats from British and Spanish forces, he aligns himself with Eleanor Guthrie, daughter of the local kingpin, to hunt the ultimate prize and ensure his people's survival. But standing in the way are rival captains, Eleanor's intrusive father, and perhaps the bigger obstacle of all: John Silver, a young, fast-talking, authority-flouting sailor recently added to Flint's crew.";

        headStyle = new Style( { color:"#2ea0dd", font:"light", fontSize:fontSize-6, whiteSpace:"nowrap" } );
//      bodyStyle = new Style( { color:"#e5e5e5", font:"light", fontSize:fontSize-6                      } );
        bodyStyle = new Style( { font: "light", color: "#e8e8e8", fontSize: _x2.scaleValInt( 30 ), whiteSpace: "nowrap" } );

        this._descriptionDims = { w: _x2.scaleValInt( 968 ), h: _x2.scaleValInt( 310 ) };

        width  = this._descriptionDims.w;
        height = this._descriptionDims.h;
        this._h = height;

        this._colorFAIL = "#FF0000";
        this._colorPASS = "#00FF00";
        this._colorSIZE = "#0000FF";

        this._rectF1 = new RectWidget().init( { w:width, h:height, color:"#000000", borderThickness:1, borderColor:this._colorFAIL } );
        this._rectP1 = new RectWidget().init( { w:width, h:height, color:"#000000", borderThickness:1, borderColor:this._colorPASS } );

        this._rectF1.setA( 0 );
        this._rectP1.setA( 0 );

        this._title1 = new StringWidget().init( { text: "Fix W, Fix H, no text" , style: headStyle } );

//      this._block1 = new TextBlockWidget().init( { text: this._text1, style:bodyStyle , w:width, h:height    } );
        this._block1 = new TextBlockWidget().init( { style: bodyStyle, w: this._descriptionDims.w, h: this._descriptionDims.h } );

        x = Style._safeLeft;
        y = Style._safeTop;

        this.addWidget( this._title1, x, y ); y += dy;
        this.addWidget( this._rectF1, x, y );
        this.addWidget( this._rectP1, x, y );
        this.addWidget( this._block1, x, y ); // y += dy + height;

        this._timer = new Widget().init();
        this.addWidget( this._timer );

        this._selectable = true;

        return this;
    };

    TextBlockTestScreen2.prototype.gotFocus = function()
    {
        _x2.requestFocus( this._block1 );
        this.startTimer();
    };

    TextBlockTestScreen2.prototype.processEvent = function( val, type )
    {
        var retval = true;

        if( type === Host.KEY_PRESSED )
            switch( val )
            {
                case Host.KEY_EQUAL:
                case Host.KEY_PAGE_DN:
                case Host.KEY_CH_DOWN:
                    this._block1.pageDown();
                    break;

                case Host.KEY_MINUS:
                case Host.KEY_PAGE_UP:
                case Host.KEY_CH_UP:
                    this._block1.pageUp();
                    break;

                case Host.KEY_UP:
                    this._block1.lineUp();
                    break;

                case Host.KEY_DOWN:
                    this._block1.lineDown();
                    break;

                case Host.KEY_1:
                    this._block1.setText();
                    break;

                case Host.KEY_2:
                    this.startTimer();
                    break;

                case Host.KEY_3:
                    this._block1.setText( "Lorem ipsum dolor sit amet" );
                    break;

                default:
                    retval = Screen.prototype.processEvent.call( this, val, type );
                    break;
            }

        return retval;
    };

    TextBlockTestScreen2.prototype.resourcesLoaded = function()
    {
        var a, h;

        Widget.prototype.resourcesLoaded.call( this );

        h = this._block1.getH();
        a = (h === this._h) ? 1 : 0;

        this._rectF1.setA( 1 - a );
        this._rectP1.setA(     a );
    };

    TextBlockTestScreen2.prototype.startTimer = function()
    {
        var self      = this;
        var onAnimEnd = function()
        {
            self._block1.setText( self._text2 );
            self._block1.resourcesLoaded();
        };

        this._block1.setText();
        this._timer.animate( { duration:2000, a:1.0, easing:Widget.Easing.LINEAR, onEnd:onAnimEnd, onEndAxis:Widget.ALPHA } );
    };

    return TextBlockTestScreen2;

})();

