/**
 * @class
 */
window.ScheduledRecordings = (function()
{
    "use strict";

    ScheduledRecordings.prototype = new DataObject();

    function ScheduledRecordings(){}

    ScheduledRecordings.prototype.init = function( data )
    {
        DataObject.prototype.init.call( this, data );

        this._recordings = [];

        var i, recCollection, key, object;

        recCollection = this.getEmbedded("recordings");
        if( recCollection )
        {
            for( i=0; i<recCollection.length; i++ )
            {
                object = new Recording().init( recCollection[i] );
                key = object.getListingId();
                this._recordings[key] = object;
            }
        }

        return this;
    };

    ScheduledRecordings.prototype.getRecording = function( listingId )
    {
        if( this._recordings )
            return this._recordings[ listingId ];
    };

    return ScheduledRecordings;
})();
