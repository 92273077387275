// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PurchaseOptionsOverlay = ( function()
{
    "use strict";

    PurchaseOptionsOverlay.prototype = new Overlay();

    function PurchaseOptionsOverlay(){}

    PurchaseOptionsOverlay.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list );
        this._speakTarget.setText( "Purchase Options for " + this._title.getText() + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press up or down to review options. Press OK to select. Press back to exit. ", focus._speakRole, this, false );
    };

    PurchaseOptionsOverlay.prototype.init = function( entity, onBuy )
    {
        Overlay.prototype.init.call( this );
        this._className = "PurchaseOptionsOverlay";

        this._entity  = entity;
        this._options = entity.getEntityOptions().getTransactionOffers();

        var title, series;
        var listParams = {};
        var titleStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
        var infoStyle  = new Style( { font: "medium" , color: "#a3a3a3", fontSize: _x2.scaleValInt( 24 ), whiteSpace: "nowrap" } );

        listParams.w        = this._bg.getW() - Style._safeLeft - Style._safeRight;
        listParams.h        = this._bg.getH() - Style._safeBottom - _x2.scaleValInt(120);
        listParams.maxIndex = 1;
        listParams.obj      = { entity:entity, overlay:this, onBuy:onBuy };
        listParams.rows     = this._options;
        listParams.type     = PurchaseOfferRowWidget;

        title = entity.getTitle();

        if( entity && entity.getSeries )
        {
            series = entity.getSeries();

            if( series )
                title = series.getTitle();
        }

        this._title         = new StringWidget().init({text:title, style:titleStyle});
        this._list          = new ListWidget().init( listParams );
        this._divider       = new RectWidget().init( { w:listParams.w, h:ListRowWidget.DIVIDER_H, color:"#262626" } );

        this._infoString    = new StringWidget().init( { text:"", style: infoStyle } );

        this._bg.addWidget( this._title     , Style._safeLeft, Style._safeTop                        );
        this._bg.addWidget( this._infoString, Style._safeLeft, Style._safeTop + _x2.scaleValInt( 60) );
        this._bg.addWidget( this._list      , Style._safeLeft, Style._safeTop + _x2.scaleValInt(110) );
        this._bg.addWidget( this._divider   , Style._safeLeft, Style._safeTop + _x2.scaleValInt(110) );

        return this;
    };

    PurchaseOptionsOverlay.prototype.setInfo = function( str )
    {
        this._infoString.setText( str );
    };

    return PurchaseOptionsOverlay;

})();
