// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PromoCellWidget = ( function()
{
    "use strict";

    PromoCellWidget.prototype = new CollectionCellWidget();

    function PromoCellWidget(){}

    PromoCellWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 558 ) + _x2.scaleValInt( 19 );
    };

    PromoCellWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        this._image.setSpeechParams( this._data.getTitle(), undefined, this, newScreen );

        CollectionCellWidget.prototype.gotFocus.call( this );

        if( this._image.getA() )
            this._image.animate( { xScale:this._scale, yScale:this._scale, duration:ListWidget.SCROLL_TIME_STEP } );

        return this;
    };

    /**
     * Initializer
     * @memberof PromoCellWidget
     * @param {Object} params - @see CollectionCellWidget
     * @returns {PromoCellWidget}
     */
    PromoCellWidget.prototype.init = function( params )
    {
        var self = this;

        this._imageX = params.bgX     = _x2.scaleValInt( 19 );
        this._imageW = params.bgW     = _x2.scaleValInt( 558 );
        this._imageH = params.bgH     = _x2.scaleValInt( 372 );
        this._scale  = params.bgScale = (this._imageW + 2 * this._imageX) / this._imageW;
        this._imageY = params.bgY     = (this._scale - 1) * this._imageH / 2;

        this._focusW = this._scale * this._imageW;
        this._focusH = this._scale * this._imageH;

        params.background = true;
        params.bgTextSize = _x2.scaleValInt(36);

        CollectionCellWidget.prototype.init.call( this, params );
        this._className  = "PromoCellWidget";

        var imageChanged = function()
        {
            self._image.setA(1);
            self.hideBackground();
        };

        var imageError = function()
        {
            self._image.setA(0);
            self.showBackground();
        };

        this._image = new ImageWidget().init( { w:this._imageW, h:this._imageH, onError:imageError, onChange:imageChanged } );
        this.addWidget( this._image, this._imageX, this._imageY );

        return this;
    };

    PromoCellWidget.prototype.lostFocus = function()
    {
        CollectionCellWidget.prototype.lostFocus.call( this );

        if( this._image.getA() )
        {
            this._image.stopAnimation( Widget.X_SCALE );
            this._image.stopAnimation( Widget.Y_SCALE );
            this._image.animate( { xScale:1, yScale:1, duration:ListWidget.SCROLL_TIME_STEP } );
        }
    };

    PromoCellWidget.prototype.setData = function( data )
    {
        if( data !== undefined )
        {
            this._data = data;
            this._image.setUrl( data.getPromoImageLink( 600, 400 ) );
            this.setBackgroundText( data.getTitle() );
            this.setA( 1 );
        }
        else
            this.setA( 0.0 );
    };

    return PromoCellWidget;

})();
