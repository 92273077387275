// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Maps to XTVAPI s:PurchaseGroup. Contains purchase data and aggregates groups of purchased entities.
 */
window.PurchaseGroup = (function()
{
    "use strict";

    PurchaseGroup.prototype = new DataObject();

    function PurchaseGroup(){}

    PurchaseGroup.prototype.init = function( data )
    {
        DataObject.prototype.init.call( this, data );
        return this;
    };

    /**
     * Add the purchase Object to this group.
     * @param {ProgramEntity} purchase
     */
    PurchaseGroup.prototype.addPurchase = function( purchase )
    {
        if( !this._purchases )
            this._purchases = [];

        this._purchases.push( purchase );
    };

    PurchaseGroup.prototype.getPurchases = function()
    {
        return this._purchases;
    };

    PurchaseGroup.prototype.getPurchaseCount = function()
    {
        return this._purchases.length;
    };

    PurchaseGroup.prototype.getPurchaseIds = function()
    {
        if( !this._purchaseIds )
        {
            var i, purchases = this.getPath(["_links", "purchases"]);

            this._purchaseIds = [];

            if( purchases )
            {
                if( purchases instanceof Array )
                {
                    for( i = 0; i < purchases.length; i++ )
                        this._purchaseIds.push( purchases[i].href );
                }
                else
                    this._purchaseIds.push( purchases.href );
            }
        }

        return this._purchaseIds;
    };

    PurchaseGroup.prototype.getEpisodeCount = function()
    {
        return this._purchases.length;
    };

    PurchaseGroup.prototype.getPurchasedOnDate = function()
    {
        return this.getDate("purchased" );
    };

    PurchaseGroup.prototype.getRottenTomatoReview = function()
    {
        var review, program;

        if( this.getPurchaseCount() === 1 )
        {
            program = this.getPurchases()[0].getEntity();
            if( program )
                review = program.getRottenTomatoReview();
        }

        return review;
    };

    PurchaseGroup.prototype.getImageLink = function( width, height )
    {
        var link, purchase = this._purchases[0];

        if( purchase )
            link = purchase.getEntity().getImageLink( width, height );

        return link;
    };

    PurchaseGroup.prototype.getFallbackImageLink = function()
    {
        var link, purchase = this._purchases[0];

        if( purchase )
            link = purchase.getEntity().getFallbackImageLink( width, height );

        return link;
    };

    PurchaseGroup.prototype.getTitle = function()
    {
        return this.get("name");
    };

    PurchaseGroup.prototype.getEntityType = function()
    {
        if( this._purchases )
        {
            if( this._purchases[0] )
                return this._purchases[0].getEntityType();
        }
    };

    PurchaseGroup.prototype.isWatched = function()
    {
        if( this._purchases && this._purchases.length === 1 )
            return this._purchases[0].isWatched();
    };

    return PurchaseGroup;
})();
