// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

"use strict";

window.Cima = (function()
{
    function Cima( onHasSession, onNeedsCredentials )
    {
        this._onNeedsCredentials = onNeedsCredentials;

        var token = Host.getLocalStorage( "cimaToken" );

        if( token )
        {
            _x2._config._token = token;
            _x2._config._tokenType =  'cima-token';
            onHasSession();
        }
        else
            setTimeout( function() { onNeedsCredentials(); }, 1 );
    }

    Cima.prototype.logout = function()
    {
        _x2._config._host.removeLocalStorage( "cimaToken" );
        _x2._config._token = undefined;
    };

    Cima.prototype.sendCredentials = function( username, password, onSuccess, onError )
    {
        var params = {
            type     : "GET",
            url      : _x2._config._authEndpoint,
            headers  : [{ name: "Authorization", value: "Basic " + btoa( username + ":" + password ) }]
        };

        var success = function ( response ) {

            var data = JSON.parse( response.data );
            console.log( data );

            var status, statusSummary, token;
            var xs    = new XMLSerializer();
            var s     = xs.serializeToString(data);
            var parse = function( entry )
            {
                switch( entry.attr( "key" ) )
                {
                    case "client:authnToken":
                        token = btoa( s );
                        break;

                    case "message:status":
                        status = entry.text();
                        break;

                    case "message:statusSummary":
                        statusSummary = entry.text();
                        break;
                }
            };

            //NOTE: this is now the only use of jQuery in coamX2, since we've stopped using $.ajax.
            $(s).find('attribute').each( function(){ parse( $(this) ); } );

            if( status == "0" ) //TODO: status is never set (assume undefined == 0 evals to true?)
            {
                console.log( token );

                _x2._config._host.setLocalStorage( "cimaToken", token );
                _x2._config._token = token;
                _x2._config._tokenType =  'cima-token';

                if( onSuccess )
                    onSuccess( token );
            }
            else
            {
                console.error( "ERROR -> status = " + status + ", " + statusSummary );
                onError( status, statusSummary );
            }
        };

        var error = function (error) {
            console.log( "CIMA object error: ", error );
            onError();
        };

        _x2._network.ajax( params ).then( success ).catch( error );
    };

    return Cima;

})();
