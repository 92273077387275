// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ShowingsScreen = (function()
{
    "use strict";

    ShowingsScreen.prototype = new Screen();

    function ShowingsScreen(){}

    ShowingsScreen.prototype.gotFocus = function()
    {
        if( this._ready === true )
            this.speak( _x2.requestFocus( this._list ) );
    };

    /**
     * Initializer
     * @memberof ShowingsScreen
     * @param   {Object} params
     * @param   {Entity} params.entity
     * @returns {ShowingsScreen}
     */
    ShowingsScreen.prototype.init = function( params )
    {
        Screen.prototype.init.call( this );
        this._className     = "ShowingsScreen";
        this._telemetryName = "Showings";

        var self     = this;
        var entity   = params.entity;
        var url      = entity.getImageLink( 303, 404 );
        var options  = entity.getEntityOptions();

        //we should always have the showing data since the showings are embedded in the ProgramEntity and we should not get here without having the ProgramEntity
        //extract the showings into an array and set the list data
        //this widget contains:
        //1. ProgramPoster
        //2. Showings List

        var titleStyle = new Style( { color:"#e8e8e8", colorHi:"#000000", font:"light", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap" } );

        //dimensions
        var posterDims = { w: _x2.scaleValInt( 303 ), h: _x2.scaleValInt( 404 ) };
        var xOffsets   = [ _x2.scaleValInt(355) ];
        var yOffsets   = [ 0,  _x2.scaleValInt(18),  _x2.scaleValInt(80),  _x2.scaleValInt(82) ];

        //font styles
        var posterError = function()
        {
            self._posterImage.setA(0);
        };

        var listW    = _x2._config._screenW -  xOffsets[0] - Style._safeLeft - Style._safeRight;
        var listH    = _x2._config._screenH -  yOffsets[1] - Style._safeBottom - Style._safeTop;
        var rows     = [];

        var showings = options.getVodShowings();
        showings = showings.concat( options.getOnNowListings() );
        showings = showings.concat( options.getRecordings() );

        // if( entity.getEpisodeRecording )
        // {
        //     var recording = entity.getEpisodeRecording();
        //     if( recording )
        //         showings = showings.concat( recording );
        // }

        for( var i = 0; i < showings.length; i++ )
            rows[i] = { entity:entity, showing:showings[i] } ;

        var listParams = {};
        listParams.w        = listW;
        listParams.h        = listH;
        listParams.maxIndex = 1;
        listParams.rows     = rows;
        listParams.type     = ShowingRowWidget;

        var title = "";
        if( entity.getType() === XtvApi.EntityType.EPISODE )
        {
            // var program = entity.getEntity();
            if( entity.getSeasonNumber() && entity.getEpisodeNumber() )
            {
                title = 'S' + entity.getSeasonNumber();
                title += ' - Ep' + entity.getEpisodeNumber();
                title += ' - ';
            }
        }
        title += entity.getTitle();

        this._list          = new ListWidget().init( listParams );
        this._title         = new StringWidget().init({style:titleStyle, text:title});
        this._posterImage   = new ImageWidget().init( { url:url, w:posterDims.w, h:posterDims.h, onError:posterError } );
        this._divider       = new RectWidget().init( { w:listW, h:ListRowWidget.DIVIDER_H, color:"#262626" } );

        this._container = new Widget().init();
        this._container.addWidget( this._posterImage, 0          , yOffsets[0] );
        this._container.addWidget( this._title      , xOffsets[0], yOffsets[0] ); // intentional y[0]
        this._container.addWidget( this._divider    , xOffsets[0], yOffsets[2] );
        this._container.addWidget( this._list       , xOffsets[0], yOffsets[2] + ListRowWidget.DIVIDER_H );

        this.addWidget( this._container, Style._safeLeft, 0 );
        this.addReadyCallback( function(){ self.layout(); } );

        this.setBreadCrumbs( entity.getTitle() );

        return this;
    };

    ShowingsScreen.prototype.layout = function()
    {
        this._container.setY( this.getHeaderBottom() + _x2.scaleValInt( 42 ) );

        this._ready = true;

        if( _x2._focus === this && this._list.getMaxIndex() > 0 )
            this.speak( _x2.requestFocus( this._list, true ) );
    }

    ShowingsScreen.prototype.speak = function( focus )
    {
        if( focus )
        {
            this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr + ". " );
            this._speakTarget.setSpeechParams( "Press up or down to review items. Press OK to play. ", focus._speakRole, this, false );
        }
    }

    return ShowingsScreen;

})();
