// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Maps to XTV _type "s:VodItem"
 */
window.VodShowing = (function()
{
    "use strict";

    VodShowing.prototype = new Showing();

    function VodShowing(){}

    /**
     * Initialize this object
     * @param {Object} data - XTV derived JSON object
     * @return {VodShowing}
     */
    VodShowing.prototype.init = function( data )
    {
        Showing.prototype.init.call( this, data );
        return this;
    };

    /**
     * Get the asset ID
     * @memberOf Showing
     * @return {String}
     */
    VodShowing.prototype.getAssetId = function()
    {
        return this.get("assetId");
    };

    /**
     * Get duration in milliseconds
     * @return {number}
     */
    VodShowing.prototype.getDurationMs = function()
    {
        var durationMs = 0, duration = this.get("duration");

        if( duration && !isNaN( duration ) )
            durationMs = parseInt( duration );

        return durationMs;
    };

    /**
     * Get the showing's media GUID
     * @memberOf VodShowing
     * @return {String}
     */
    VodShowing.prototype.getMediaGuid = function()
    {
        return this.get("mediaGuid");
    };

    /**
     * Get the provider ID
     * @memberOf Showing
     * @return {String}
     */
    VodShowing.prototype.getProviderId = function()
    {
        return this.get("providerId");
    };

    /**
     * Get the the showing window expiration time.
     * @return {Date}
     */
    VodShowing.prototype.getVodWindowExpireTime = function()
    {
        var ends, val = this.get("vodWindowExpireTime");

        if( val )
            ends = new Date( parseInt(val) );

        return ends;
    };

    /**
     * Return true if this showing is marked as adult content
     * @memberOf VodShowing
     * @returns {Boolean}
     */
    VodShowing.prototype.isAdult = function()
    {
        return this.get("isAdult");
    };

    /**
     * Return true if trick mode is restricted
     * @memberOf VodShowing
     * @returns {Boolean}
     */
    VodShowing.prototype.isTrickModeRestricted = function()
    {
        return this.getBoolean("isTrickModeRestricted" );
    };

    /**
     * Returns the release year of the entity.
     * @return {*}
     */
    VodShowing.prototype.getYear = function()
    {
        return this.get("year");
    };

    return VodShowing;
})();

