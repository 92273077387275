// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PurchasesFtueScreen = ( function()
{
    "use strict";

    PurchasesFtueScreen.prototype = new Screen();

    function PurchasesFtueScreen(){}

    PurchasesFtueScreen.prototype.gotFocus = function( losingFocus, newScreen )
    {
        _x2._hi.fadeOut();

        if( this._isReady === true )
            this.speak( _x2.requestFocus( this._buttons, newScreen ) );
    };

    PurchasesFtueScreen.prototype.init = function()
    {
        Screen.prototype.init.call( this, { noBg:false, noHeader:true, noSep:true, image:_x2._config._imageRoot + "/purchaseFtue.jpg", imageAlpha:1.0 } );
        this._className     = "PurchasesFtueScreen";
        this._telemetryName = "Main Menu";

        var self        = this, numReady = 0;
        var titleStyle  = new Style( { font:"light", color:"#000000", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap" } );
        var bodyStyle   = new Style( { font:"light", color:"#000000", fontSize:_x2.scaleValInt(30)                      } );
        var buttonStyle = new Style( { font:"light", color:"#000000", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000"  } );

        var onGotIt = function()
        {
            _x2._settings.setValue( Settings.Key.PURCHASES, true );
            _x2.setDefaultScreen();
        };

        var onSetPin = function()
        {
            PinOverlay.setPinFlow( PinOverlay.FlowType.PURCHASE, onGotIt );
        };

        var gotIt  = new StringWidget().init( { text:"Got It"          , style:buttonStyle, onEnter:onGotIt  } );
        var setPin = new StringWidget().init( { text:"Set Purchase PIN", style:buttonStyle, onEnter:onSetPin } );

        var layout = function()
        {
            if( ++numReady === 2 )
            {
                self._body.setY( self._title.getY() + self._title.getH() + _x2.scaleValInt(30) );
                self._buttons.setY( self._body.getY() + self._body.getH() + _x2.scaleValInt(50) );
            }
        };

        this._title = new StringWidget().init( { text:"Make Purchases Here", style:titleStyle } );
        this.addWidget( this._title, Style._safeLeft, _x2.scaleValInt(375) );

        this._body = new TextBlockWidget().init( { text:"Now you can rent and buy in the app.<br> Watch here, in our mobile apps, or on the web.", style:bodyStyle, w:_x2.scaleValInt(600) } );
        this._body.addReadyCallback( layout );
        this.addWidget( this._body, Style._safeLeft );

        this._buttons = new HorizPillButtonsWidget().init( { entries:[gotIt], spacing:_x2.scaleValInt(82) } );
        this._buttons.addReadyCallback( layout );
        this.addWidget( this._buttons, Style._safeLeft );

        var onIsPinEnabled = function( obj )
        {
            self._isReady = true;

            if( obj.isEnabled() === false )
                self._buttons.setButtons( [gotIt, setPin] );

            if( _x2._focus === self )
                self.speak( _x2.requestFocus( self._buttons, true ) );
        };

        _x2._data.getPurchasePin().then( onIsPinEnabled ).catch( onIsPinEnabled );

//         window.ll( "tagScreen", "Main Menu" ); // Localytics 2.1.1
//         _x2._config.log( Config.CLASS_LOCALYTICS, 1 ) ? console.log( "Localytics: Main Menu screen" ) : Config.NOP();
//         _x2.localyticsSendSplashPageAndProvisioning();

        return this;
    };

    PurchasesFtueScreen.prototype.speak = function( focus, newScreen )
    {
        if( newScreen !== false )
        {
            this._speakTarget.setText( this._title.getText() + ". " + focus._speakStr + ". " );
            this._speakTarget.setSpeechParams( this._body.getText() + "Press right or left to review options. Press OK to select.", focus._speakRole, this, false );
        }
    };

    return PurchasesFtueScreen;

})();
