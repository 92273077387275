// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * This is a simple horizontal or vertical layout container which arranges/positions
 * widgets with a consistent spacing.
 * @class
 */
window.ContainerWidget = (function()
{
    "use strict";

    function ContainerWidget(){}

    ContainerWidget.prototype = new Widget();

    /**
     * Initialize the widget
     * @memberOf ContainerWidget
     * @param {Object}   params
     * @param {Widget[]} [params.widgets]  - collection of widgets in container.
     * @param {Number}   [params.spacing]  - widget layout spacing.
     * @param {function} [params.onReady]  - callback when all are loaded.
     * @param {Boolean}  [params.ready]    - indicates all widgets are added - no intention to call addWidget()
     * @param {Boolean}  [params.vertical] - use vertical layout. horizontal is the default.
     * @param {Boolean}  [params.center]   - center align widgets
     * @return {ContainerWidget}
     */
    ContainerWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this, params );
        this._className = "ContainerWidget";

        var i;

        this._vertical      = params.vertical;
        this._spaceing      = params.spacing || 0;
        this._onReady       = params.onReady;
        this._center        = params.center;
        this._widgets       = [];
        this._callbackCount = 0;

        if( params.widgets )
        {
            for( i = 0; i < params.widgets.length; i++ )
                this.addWidget( params.widgets[i] )
        }

        if( params.ready )
            this.ready();

        this.setA( 0 );

        return this;
    };

    /**
     * Layout contained widgets
     * @private
     */
    ContainerWidget.prototype.layout = function()
    {
        var i, widget, offset = 0, maxW = this.getWidth();

        for( i = 0; i < this._widgets.length; i++ )
        {
            widget = this._widgets[i];

            if( this._vertical )
            {
                widget.setY( offset );
                offset += widget.getH() + this._spaceing;

                if( this._center === true )
                {
                    if( widget.getW() < maxW )
                        this._widgets[i].setX( maxW/2 - widget.getW()/2 );
                }
            }
            else
            {
                widget.setX( offset );
                offset += widget.getW() + this._spaceing;
            }
        }

        if( this._onReady )
            this._onReady();
    };

    /**
     * Add a widget to the container.
     * @memberOf ContainerWidget
     * @param {Widget} widget - Any Widget type that implements addReadyCallback
     */
    ContainerWidget.prototype.addWidget = function( widget )
    {
        var self = this;

        this._widgets.push( widget );

        if( widget.addReadyCallback )
            widget.addReadyCallback( function(){ self.widgetReady(); } );
        else
            console.log( "Error: Parameter to ContainerWidget.addWidget must implement addReadyCallback()" );

        Widget.prototype.addWidget.call( this, widget );
    };

    /**
     * Get the width of this container (NOTE: assumes vertical layout)
     * @return {number}
     */
    ContainerWidget.prototype.getWidth = function()
    {
        var i, maxW = 0;

        for( i=0; i<this._widgets.length; i++ )
            maxW = this._widgets[i].getW() > maxW ? this._widgets[i].getW() : maxW;

        return maxW;
    };

    /**
     * Get the height of this container (NOTE: assumes vertical layout)
     * @return {number}
     */
    ContainerWidget.prototype.getHeight = function()
    {
        var lastWidget = this._widgets[this._widgets.length-1];

        return lastWidget.getY() + lastWidget.getH();
    };

    /**
     * Mark this container as ready to render. i.e. all widgets have been added. NOTE: we could
     * also layout as stuff is ready, but could lead to "jumpy" behavior
     * @memberOf ContainerWidget
     */
    ContainerWidget.prototype.ready = function()
    {
        this._ready = true;

        if( this._pending )
        {
            if( this._callbackCount === this._widgets.length )
                this.layout();
        }
    };

    /**
     * Callback fires for each contained widget.
     */
    ContainerWidget.prototype.widgetReady = function()
    {
        this._callbackCount++;

        if( this._callbackCount === this._children.length )
        {
            if( this._ready )
                this.layout();
            else
                this._pending = true;
        }
    };

    return ContainerWidget;

})();
