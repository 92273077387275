// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.FindChannelRowWidget = ( function()
{
    "use strict";

    FindChannelRowWidget.prototype = new ListRowWidget();

    function FindChannelRowWidget(){}

    FindChannelRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 129 * _x2._config._text );
    };

    FindChannelRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        if( losingFocus instanceof FindChannelRowWidget )
            this._leftFocus = losingFocus._leftFocus;

        Widget.prototype.gotFocus.call( this );
        this.setHighlight();

        if( this._leftFocus === false )
            this._playIcon.gotFocus();
        else
            this._chanStr.gotFocus();
//             this._name.gotFocus();

        this.speak( newScreen);

        return this;
    };

    /**
     * Initializer
     * @memberof FindChannelRowWidget
     * @param   {Object}    params
     * @param   {Object}    params.obj - The guide screen that led this overlay so the selection cn be returned
     * @param   {Number}    params.w   - Width of the list
     * @returns {FindChannelRowWidget}
     * @see ListRowWidget
     */
    FindChannelRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );
        this._className   = "FindChannelRowWidget";
        this._selectable  = true;
        this._numElements = 0;
//         this._maxLogoH    = parseInt( this.getHeightUnfocused() / 2 );
//         this._maxLogoW    = parseInt( this._maxLogoH * 5 / 3 );
        this._maxLogoH    = _x2.scaleValInt( 72 );
        this._maxLogoW    = _x2.scaleValInt( 72 );
        this._leftFocus   = true;
        this._controller  = params.obj;

        var self      = this, layout, onLogoLoaded;
//        var nameStyle = new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#2ea0dd" } );
        var chanStyle = new Style( { color:"#a3a3a3", font:"medium"  , fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#2ea0dd" } );
        var onReady   = function() { self._numElements++; layout(); };

        layout = function()
        {
            if( self._numElements === 2 )
            {
//                var x = self._chanStr.getX() + self._chanStr.getW() + 3 * Style._pad;

//                self._sep.setX( x );
//                self._name.setX( x + self._sep.getW() + 3 * Style._pad );
                self._playIcon.setX( params.w - self._playIcon.getW() - _x2.scaleValInt( 36 ) );
                self._sep.setX( self._playIcon.getX() - _x2.scaleValInt( 36 ) );

                self._chanStr.setY( (self.getHeightUnfocused() - self._chanStr.getH()) / 2 );
//                self._name.setY( (self.getHeightUnfocused() - self._name.getH()) / 2 );
                self._playIcon.setY( (self.getHeightUnfocused() - self._playIcon.getH()) / 2 );

                self._focusSplit = self._sep.getX();
            }
        };

        onLogoLoaded = function()
        {
            var dw, dh, scale;
            var w = self._logo.getW();
            var h = self._logo.getH();

            if( w > 0 && h > 0 )
            {
                dw    = self._maxLogoW / w;
                dh    = self._maxLogoH / h;
                scale = ( dh < dw ) ? dh : dw;

                self._logo.setX( (self._maxLogoW            - self._logo.getW()) / 2 );
                self._logo.setY( (self.getHeightUnfocused() - self._logo.getH()) / 2 );
                self._logo.setScale( scale );
                self._logo.setA( 1 );
            }
        };

        this._logo = new ImageWidget().init( { onChange:onLogoLoaded } );
        this.addWidget( this._logo );

        this._chanStr = new StringWidget().init( { text:"0000", style:chanStyle } );
        this._chanStr.addReadyCallback( onReady );
        this.addWidget( this._chanStr, this._maxLogoW + _x2.scaleValInt( 62 ) );

        this._sep = new RectWidget().init( { w:ListRowWidget.DIVIDER_H, h:this.getHeightUnfocused(), color:"#262626" } );
        this.addWidget( this._sep );

//         this._name = new StringWidget().init( { text:"???????", style:nameStyle } );
//         this._name.addReadyCallback( onReady );
//         this.addWidget( this._name );

        this._playIcon = new DualImageWidget().init( { url:_x2._config._imageRoot + "Play-icon-min.png", urlHi:_x2._config._imageRoot + "Play-focus-state-icon-min.png" } );
        this._playIcon.addReadyCallback( onReady );
        this.addWidget( this._playIcon );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        return this;
    };

    FindChannelRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );

        if( this._leftFocus === false )
            this._playIcon.lostFocus();
        else
            this._chanStr.lostFocus();
//             this._name.lostFocus();
    };

    FindChannelRowWidget.prototype.processEvent = function( val, type )
    {
        var retval, self = this;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._leftFocus === true )
                        this._controller.setJumpToChannel( this._data );
                    else
                    {
                        var doTune = function()
                        {
                            self._controller.setTuneToChannel( self._data );
                        };

                        var i, schedules = this._data.getSchedules(), now = new Date().getTime();

                        if( schedules )
                                for( i = 0; i < schedules.length; i++ )
                                    if( schedules[i].getEndTime() > now )
                                        break;

                        if( _x2._pc.isEnabled() === true )
                            _x2.checkParentalControls( schedules ? schedules[i] : undefined, undefined, this._data ).then( function() { doTune( self._data, schedules[i] ); } );
                        else
                            doTune( this._data, schedules[i] );
                    }

                    retval = true;
                }
                break;

            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED && this._leftFocus === false )
                {
                    this._leftFocus = true;
                    this._playIcon.lostFocus();
                    this._chanStr.gotFocus();
//                    this._name.gotFocus();
                    this.setHighlight();
                    this.speak();
                    retval = true;
                }
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED && this._leftFocus === true )
                {
                    this._leftFocus = false;
                    this._playIcon.gotFocus();
                    this._chanStr.lostFocus();
//                    this._name.lostFocus();
                    this.setHighlight();
                    this.speak();
                    retval = true;
                }
                break;
        }

        if( retval === undefined )
            retval = ListRowWidget.prototype.processEvent.call( this, val, type );

        return retval;
    };

    /**
     * @memberof FindChannelRowWidget
     * @param {Channel} [data]
     */
    FindChannelRowWidget.prototype.setData = function( data )
    {
        this._data = data;

        if( data !== undefined )
        {
            this._chanStr.setText( data.getNumber() );
//            this._name.setText( data.getCallSign() );
            this._logo.setA( 0 );
            this._logo.setW();
            this._logo.setH();
            this._logo.setUrl( data.getLogoUrl(50,30) );  // TODO what is the right size???
            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    FindChannelRowWidget.prototype.setHighlight = function( time, h )
    {
        var obj = this.getGlobalPos();

        if( this._leftFocus !== true )
            obj.x += this._focusSplit;

        obj.w        = (this._leftFocus === true) ? this._focusSplit : this.getVal( Widget.W ) - this._focusSplit;
        obj.h        = (h === undefined) ? this.getHeightUnfocused() - ListRowWidget.DIVIDER_H : h;
        obj.inner    = false;
        obj.duration = (time === undefined) ? ListWidget.SCROLL_TIME_STEP : time;

        _x2._hi.setPosition( obj );
    };

    FindChannelRowWidget.prototype.speak = function( newScreen )
    {
        if( this._leftFocus === false )
            this._chanStr.setSpeechParams( "Press OK to tune.", undefined, this, newScreen );
        else
            this._chanStr.setSpeechParams( "Press OK to go to in the grid.", undefined, this, newScreen );
    };

    return FindChannelRowWidget;

})();
