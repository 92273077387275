// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.DeviceNameScreen = ( function()
{
    "use strict";

    DeviceNameScreen.prototype = new Screen();

    function DeviceNameScreen(){}

    DeviceNameScreen.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();
        var focus = _x2.requestFocus( this._keyboard, true );
        this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( undefined, focus._speakRole, this, false );
    };

    /**
     * Initializer
     * @memberof DeviceNameScreen
     * @param   {Object}    params
     * @param   {Boolean}  [params.hideCancel] - Determines whether cancel button is shown
     * @param   {Function} [params.onChange  ] - Callback when name change has been confirmed
     * @param   {String}   [params.path      ] - Path to put in the header
     * @returns {DeviceNameScreen}
     */
    DeviceNameScreen.prototype.init = function( params )
    {
        var self        = this, keyboardParams = {}, onConfirm, onCancel, accept, decline, entries;
        var layout      = function() { self._numReady++; self.layout(); };
        var buttonStyle = new Style( { font:"light", color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000" } );

        Screen.prototype.init.call( this );
        this._className     = "DeviceNameScreen";
        this._telemetryName = "Name Device";
        this._numReady      = 0;
        this.setBreadCrumbs( ((params && params.path) ? params.path : undefined), "Please Name Your Device" );

        keyboardParams.w       = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        keyboardParams.onReady = layout;
        keyboardParams.onEnter = function( char ) { self._text.onChar( char ); };

        this._keyboard = new KeyboardWidget().init( keyboardParams );
        this.addWidget( this._keyboard, Style._safeLeft );

        this._text = new TextEntryWidget().init( { w:this._keyboard.getW() } );
        this._text.addReadyCallback( layout );
        this.addWidget( this._text, Style._safeLeft );

        onConfirm = function()
        {
            self._buttons.setDisabled( true );

            var onError = function( obj )
            {
                console.log( obj );
                _x2.pushOverlay( new AlertOverlay().init( { title:"Not a Valid Device Name", message:"Please try again. The device name must contain at least one character." } ) );
                self._buttons.setDisabled( false );
            };

            var onSuccess = function()
            {
                if( params.onChange )
                    params.onChange();

                _x2.popScreen();
            };

            _x2._data.setDeviceName( self._text.getText() ).then( onSuccess, onError );
        };

        onCancel = function()
        {
            _x2.popScreen();
        };

        accept  = new StringWidget().init( { text:"Confirm", style:buttonStyle, onEnter:onConfirm } );
        decline = new StringWidget().init( { text:"Cancel" , style:buttonStyle, onEnter:onCancel  } );
        entries = ((params && params.hideCancel === true) ? [accept] : [accept,decline]);

        this._buttons  = new HorizPillButtonsWidget().init( { entries:entries, spacing:_x2.scaleValInt(60) } );
        this._buttons.addReadyCallback( function() { self.layout(); } );
        this.addWidget( this._buttons );

        this._onConfirm = onConfirm;

        window.ll( "tagScreen", "Name Device screen" ); // Localytics 2.1.1
        _x2._config.log( Config.CLASS_LOCALYTICS, 1 ) ? console.log( "Localytics: Name Device screen" ) : Config.NOP();

        _x2._telemetry.sendScreenViewed( this._telemetryName );

        return this;
    };

    DeviceNameScreen.prototype.layout = function()
    {
        if( this._numReady === 2 )
        {
            this._text.setY( this.getHeaderBottom() + _x2.scaleValInt( 42 ) );
            this._keyboard.setY( this._text.getY() + this._text.getH() + _x2.scaleValInt( 30 ) );
            this._buttons.setX( _x2._config._screenW - Style._safeLeft - this._buttons.getW() );
            this._buttons.setY( this._text.getY() );
        }
    };

    DeviceNameScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED && _x2._focus === this._buttons )
                    _x2.requestFocus( this._keyboard );
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED && _x2._focus === this._keyboard )
                    _x2.requestFocus( this._buttons );
                break;

            case Host.KEY_SOFT_A:
                if( type === Host.KEY_PRESSED )
                    this._onConfirm();
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    return DeviceNameScreen;

})();
