// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Represents a rating (i.e. MPAA). Maps to XTV "contentRating/detailed" object
 */
window.Rating = (function()
{
    "use strict";

    function Rating(){}

    /**
     * Initialize the object
     * @memberOf Rating
     * @param {Object} data - XTV derived JSON object
     * @returns {Rating}
     */
    Rating.prototype.init = function( data )
    {
        this._data = data;
        return this;
    };

    /**
     * Get the rating name/lable i.e. "TV-Y"
     * @memberOf Rating
     * @return {String}
     */
    Rating.prototype.getName = function()
    {
        return this._data.name;
    };

    /**
     * Get the sub ratings
     * @memberOf Rating
     * @return {Array} 
     */
    Rating.prototype.getSubRatings = function()
    {
        return this._data.subRating;
    };

    /**
     * Get the rating type (i.e. MPAA)
     * @memberOf Rating
     * @return {String}
     */
    Rating.prototype.getType = function()
    {
        return this._data._type; //TODO: use constants
    };
    
    return Rating;
})();
