// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.StringWidget = ( function()
{
    "use strict";

    StringWidget.prototype             = new Widget();
    StringWidget.prototype.constructor = StringWidget;
    StringWidget.SHADER                = Widget.SHADER_FONT;

    function StringWidget(){}

    /**
     * @memberof StringWidget
     * @returns {String}
     */
    StringWidget.prototype.getText = function()
    {
        return this._text;
    };

    // OUT: _tags has the number of discrete HTML tags or text lines
    StringWidget.prototype.glGetTags = function( text )
    {
        var tags = [];
        var tail = 0;
        var head = 0;

        var delimLT;
        var delimGT;
        var length;

        var subStr;
        var tagStr;
        var iParam;
        var eParam;
        var key;
        var val;
        var obj;

        while( head >= 0 && head < text.length )
        {
            delimLT = text.indexOf( "<", head      );
            delimGT = text.indexOf( ">", delimLT+1 );

            if( delimLT >= 0 )
            {
                // Handle case of plain text preceeding tag
                if( tail !== delimLT )
                {
                    tags.push({
                        name: 'text',
                        text: text.substr( tail, delimLT - tail )
                    });
                }

                head = delimGT;
                tail = head + 1;
                obj  = {};

                subStr  = text.substring( delimLT+1, delimGT );
                length  = delimGT - delimLT - 1;
                iParam  = subStr.indexOf( ' ' );
                tagStr  = iParam >= 0
                        ? subStr.substring( 0, iParam ).toLowerCase()
                        : subStr
                        ;
                eParam  = subStr.indexOf( '=' );
                key     = iParam >= 0 && eParam >= 0
                        ? subStr.substring( iParam+1, eParam )
                        : undefined
                        ;
                val     = iParam >= 0
                        ? subStr.substring( eParam+1 )
                        : undefined
                        ;

                if( val && val[0] === "'" )
                    val = subStr.substring( eParam+2, subStr.length - 1 );

                switch( tagStr )
                {
                    case '/font': // NOTE: val will be undefined for /font
                    case  'font': obj = { 'color' : val }; break;
                    case '/b':
                    case  'b':    obj = { 'bold'  : 1   }; break;
                    case '/i':
                    case  'i':    obj = { 'italic': 1   }; break;
                    case 'br':    obj = { 'break' : 1   }; break;
                    default:
                        console.warn( "*** WARNING: Defaulting HTML tag to text: " + tagStr );
                        tags.push({
                            name: 'text',
                            text: tagStr
                        });

                        head = tagStr.length; // force while() exit
                        break;
                }

                if( Object.keys( obj ).length > 0 )
                {
                    obj.open = tagStr.charAt( 0 ) !== '/';
                    obj.name = obj.open
                             ? tagStr
                             : tagStr.substr( 1, tagStr.length ) // strip '/' off of close tag
                             ;
                    tags.push( obj );
                }
            }
            else // handle remaining plain text
            {
                subStr = text.substr( head );
                tags.push({
                    name: 'text',
                    text: subStr
                });
                head += subStr.length;
            }

            head++;
        }

        this._tags = tags;
    };

    // OUT: Each child in the _container is a renderable text object
    StringWidget.prototype.glMakeLines = function( tags )
    {
        var child;
        var text;

        var nKids = 0;
        var dy    = this._font.getLineHeight( this._curVal[ Widget.FONT_SIZE ] );
        var x     = 0;
        var y     = 0;
        var color = this.getVal( Widget.FONT_COLOR );

        var x0      =   x;
        var aColors = [ color ];

        var head;
        var tag;

        var isBold   = false;
        var isItalic = false;

        for( head = 0; head < tags.length; head++ )
        {
            tag = tags[ head ];
            if( tag.name === 'text' )
            {
                text = this.glRemoveAmpTags( tag.text );

                // measure text
                var params  =
                {
                    text    : text,
                    offset  : 0,
                    length  : text.length,
                    fontSize: this._curVal[ Widget.FONT_SIZE ]
                };
                var metrics = this._font.getLineWidth( params );
                var width   = metrics.width;

                if( isNaN( width ) ) // Glyph not found
                    width = 0;

                child         = new Widget().init(); // { text:text, style:this._style } );
                child._text   = text;
                child._italic = isItalic;
                child._bold   = isBold;

                child.setW( width );
                child.animate( { 'color' : color } );
                child.resourcesLoad();

                this._container.addWidget( child, 0, 0 );
                x += width;
            }
            else
            {
                switch( tag.name )
                {
                    case 'font':
                        if( tag.open )
                            aColors.push( color = tag.color );
                        else
                        {
                            aColors.pop();
                            color = aColors[ aColors.length-1 ];
                        }
                        break;
                    case 'i'   :
                        isItalic = tag.open;
                        break;
                    case 'b'   :
                        isBold = tag.open;
                        break;
                    case 'br'  :
                        nKids = this._container._children.length;
                        if( nKids > 1 ) // Must match render() last child has relative coords
                        {
                            child = this._container._children[ nKids - 1 ];
                            child.setX( x0 - x );
                            child.setY( dy );
                        }

                        x  = x0;
                        y += dy;

                        break;
                    default:
                        console.error( "*** ERROR: Unhandled HTML tag: " + tag.name );
                }
            }
        }
    };

    /* Look for HTML tags: < >
        &lt;
        &gt;
       And convert to characters
    */
    StringWidget.prototype.glRemoveAmpTags = function( str )
    {
        var    div = document.createElement( 'div' );
               div.innerHTML = str;
        return div.innerText;
    };

    StringWidget.prototype.glRemoveChildColor = function()
    {
        var iLine = 0, nLine = this._container._children.length, child;

        // propogate no color to all children; render will use current color
        for( ; iLine < nLine; iLine++ )
            this._container._children[ iLine ]._curVal[ Widget.FONT_COLOR ] = undefined;
    };

    StringWidget.prototype.glSetChildrenWidth = function( width )
    {
        var iLine = 0, nLine = this._container._children.length;

        for( ; iLine < nLine; iLine++ )
            this._container._children[ iLine ].setW( width );
    };

    StringWidget.prototype.gotFocus = function()
    {
        Widget.prototype.gotFocus.call( this );

        if( this._selectSize === undefined )
            this._selectSize = this._baseSize;

        this.animate( { color:this._hiColor, fontSize:this._selectSize, duration:this._focusTime } );

        if( _x2._config._render === Config.RENDER_WEBGL )
            this.glRemoveChildColor();
    };

    StringWidget.prototype.hasText = function()
    {
        return this._text !== undefined;
    };

    /**
     * Initializer
     * @memberof StringWidget
     * @param {Object}    params                   - Parameters to define how the string should be displayed
     * @param {Number}   [params.focusTime=0]      - Duration (milliseconds) of animation scaling from not selected to selected.
     * @param {Number}   [params.lostFocusScale=1] - Normalized percentage scale of font size when widget is not selected.
     * @param {String}   [params.name]             - Class name
     * @param {Function} [params.onEnter]          - Callback when Enter is pressed
     * @param {Number}   [params.selectSize]       - Font size when string widget is selected
     * @param {Style}    [params.style]            - Alignment, Color, Font Type, Font Size, Whitespace
     * @param {String}   [params.text]             - Text to display; if setting later with setText() undefined is valid
     * @see HTMLStringWidget
     */
    StringWidget.prototype.init = function( params )
    {
        if( this._className === undefined )
            this._className = (params && params.name) ? params.name : "StringWidget";

        Widget.prototype.init.call( this );

        if( params.style )
            params.style.apply( this );

        if( params.onEnter )
            this._onEnter = params.onEnter;

        if( params.selectSize )
            this._selectSize = parseInt( params.selectSize * _x2._config._text );

        this._focusTime      = ( params.focusTime      !== undefined ) ? params.focusTime      : 0;
        this._lostFocusScale = ( params.lostFocusScale !== undefined ) ? params.lostFocusScale : 1;

        this._text      = params.text;
        this._baseColor = this.getVal( Widget.FONT_COLOR );
        this._baseSize  = this.getVal( Widget.FONT_SIZE  );
        this._hiColor   = this.getVal( Widget.FONT_COLOR_HI );

        if( _x2._config._render === Config.RENDER_WEBGL )
        {
            this._container = new Widget().init();
            this.addWidget( this._container );
        }

        return this;
    };

    StringWidget.prototype.layoutGL = function()
    {
        var params, align, right, width = this.getW() || 0, sLine, iLine, nLine, child, metric;
        var h, w, dx;
        var end, text;

        this._container.removeAllWidgets();

        // Note: setText() can be called before resourcesLoad() has finished
        if( !this._font || !this._text || !this._container._children )
            return;

        // Text may contain HTML
        this.glGetTags  ( this._text );
        this.glMakeLines( this._tags );

        nLine  = this._container._children.length;
        sLine  = this._container._children[0]._text;
        metric =
        {
            offset  : 0,
            fontSize: this._curVal[ Widget.FONT_SIZE ]
        };
        params =
        {
            text    : "",
            offset  : 0,
            length  : 0,
            fontSize: this._curVal[ Widget.FONT_SIZE ]
        };

        h = this._font.getLineHeight( this._curVal[ Widget.FONT_SIZE ] );
        w = 0;

        for( iLine = 0; iLine < nLine; iLine++ )
        {
            child         = this._container._children[ iLine ];
            metric.text   = child._text;
            metric.length = child._text.length;
            dx            = this._font.getLineWidth( metric ).width;

            if( isNaN( dx ) ) // Glyph not found
            {
                console.info( "Glyph not found: " + metric.text + ", char: " + metric.text.charCodeAt( 0 ).toString( 16 ) );
                dx = 0;
            }
            w += dx;
        }

        if( this._textOverflow === "ellipsis" ) // textOverflow: 'ellipsis' takes precedence over overflow: 'hidden' but in practice both should be set
        {
            if( width && (w > width) ) // If width undefined, then nothing to truncate
            {
                end = sLine.length;
                do
                {
                    end           = sLine.lastIndexOf( " ", end-1 );
                    params.text   = sLine.substring( 0, end ) + "...";
                    params.length = params.text.length;
                    text          = params.text;
                    w             = this._font.getLineWidth( params ).width;
                } while( w > width && (end >= 0) );

                if( w > width && (end < 0) ) // Handle edge case when ellipsis text still doesn't fit.
                    text = "";

                this._text = text;

                if( nLine === 1 )
                    this._container._children[0]._text = text;
            }
        }
        else
        {
            right = this.getX();
            align = this.getVal( Widget.TEXT_ALIGN );

            if( align === "right" && width )
                right += (width - w);
            else if( align === "center" && width )
                right += (width - w) * 0.5;

            Widget.prototype.setW.call( this, w ); // must not call StringWidget.setW() but base class

            // TODO: glSetChildrenWidth() ?

            this.setX( right );
        }

        this.setH( h );
    };

    StringWidget.prototype.lostFocus = function()
    {
        Widget.prototype.lostFocus.call( this );
        this.animate( { color:this._baseColor, fontSize:this._baseSize, duration:this._focusTime } );

        if( _x2._config._render === Config.RENDER_WEBGL )
            this.glRemoveChildColor();
    };

    /**
     * WebGL only: draw text
     * @StringWidget ImageWidget
     * @param {Number} alphaParent - parent's alpha * current alpha
     */
    StringWidget.prototype.render = function( alphaParent )
    {
        var fc, color, params, iLine = 0, nLine = this._container._children.length, child, width;

        if( !this._font || !nLine )
            return;

        for( ; iLine < nLine; iLine++ )
        {
            child = this._container._children[ iLine ];
            fc    = child._curVal[ Widget.FONT_COLOR ];
            color = Widget.glColorHexToFloat( fc || this._curVal[ Widget.FONT_COLOR ] );

            params =
            {
                text    : child._text,
                offset  : 0,
                length  : child._text.length,
                width   : child.getW(), // _curVal[ Widget.W ],
                fontSize: this._curVal[ Widget.FONT_SIZE ],
                r       : color[0],
                g       : color[1],
                b       : color[2],
                a       : color[3] * alphaParent,
                italic  : child._italic,
                bold    : child._bold
            };

            this._font.render( params );

            // Update uniform shader matrix -- must match glMakeLines()
            if( nLine > 1 )
            {
                Widget.glViewMatrix[ Widget.glViewDepth ][12] += child.getX() + child.getW();
                Widget.glViewMatrix[ Widget.glViewDepth ][13] += child.getY();
                Widget.gl.uniformMatrix4fv( Widget.glShaderThis.umView, false, Widget.glViewMatrix[ Widget.glViewDepth ] );
            }
        }
    };

    StringWidget.prototype.resourcesLoad = function()
    {
        Widget.prototype.resourcesLoad.call( this );

        if( _x2._config._render === Config.RENDER_DOM )
        {
            this._div.innerHTML        = this._text ? this._text : "";
            this._div.style.textAlign  = this.getVal( Widget.TEXT_ALIGN );
            this._div.style.color      = this.getVal( Widget.FONT_COLOR );
            this._div.style.fontFamily = this.getVal( Widget.FONT );
            this._div.style.fontSize   = this.getVal( Widget.FONT_SIZE ) + "px";
            this._div.style.whiteSpace = this.getVal( Widget.TEXT_WHITE_SPACE );

            if( this._textOverflow )
                this._div.style.textOverflow = this._textOverflow;

            if( this._overflow )
                this._div.style.overflow = this._overflow;
        }
        else if( _x2._config._render === Config.RENDER_WEBGL )
        {
            this._font = Widget.glFonts[ this.getVal( Widget.FONT ) ];
            this.layoutGL();
        }
    };

    StringWidget.prototype.resourcesLoaded = function()
    {
        var self = this;

        Widget.prototype.resourcesLoaded.call( this );
        setTimeout( function() { self.callbackSignalReady(); }, 1 );
    };

    /** Update text
     * @memberof StringWidget
     * @param {String} str - New text
     */
    StringWidget.prototype.setText = function( str )
    {
        this._text = (str !== undefined) ? '' + str : "";

        if( _x2._config._render === Config.RENDER_DOM )
        {
            if( this._div )
                this._div.innerHTML = this._text;
        }
        else if( _x2._config._render === Config.RENDER_WEBGL )
        {
            this.layoutGL();
        }
    };

    StringWidget.prototype.setW = function( val )
    {
        Widget.prototype.setW.call( this, val );

        if( _x2._config._render === Config.RENDER_WEBGL ) // && axis === Widget.W)
        {
            var align = this.getVal( Widget.TEXT_ALIGN );
            if( align === "center" || align === "right" )
                this.layoutGL();
            else
                this.glSetChildrenWidth( val );
        }
    };

    return StringWidget;

})();
