// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.VodBranchScreen = (function()
{
    "use strict";

    VodBranchScreen.prototype = new Screen();

    function VodBranchScreen(){}

    VodBranchScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._gallery, true );

        if( focus )
        {
            this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr + ". " );
            this._speakTarget.setSpeechParams( "Press up or down to review categories. ", focus._speakRole, this, false );
        }
        else
        {
            this._speakTarget.setText( this.getBreadCrumb() );
            this._speakTarget.setSpeechParams( "Press up or down to review categories. Loading. ", undefined, this, false );
        }
    };

    /**
     * @memberof VodBranchScreen
     * @param {BrowseCollection} folder
     * @param {String          } path
     * @returns {VodBranchScreen}
     */
    VodBranchScreen.prototype.init = function( folder, path )
    {
        Screen.prototype.init.call( this );
        this._className     = "VodBranchScreen";
        this._telemetryName = "Vod Branch";

        this._gallery = new VodBrowseWidget().init( folder );
        this.addWidget( this._gallery, 0, _x2.scaleValInt( 120 ) );

        this.setHeaderFilters( _x2._settings.getValue( Settings.Key.FILTERS ) );

        this._gallery.activate();

        this.setBreadCrumbs( path, folder.getTitle() );

        return this;
    };

    return VodBranchScreen;

})();
