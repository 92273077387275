// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.RatingsRowWidget = ( function()
{
    "use strict";

    RatingsRowWidget.prototype = new ListRowWidget();

    /**
     * @namespace     RatingsRowWidget.Type
     * @enum {Number} Type
     * @type {Number}
     * @property {Number}   RatingsRowWidget.MOVIE -
     * @property {Number}   RatingsRowWidget.TV    -
     */
    RatingsRowWidget.Type = Object.freeze
    ({
        MOVIE : 0,
        TV    : 1
    });

    function RatingsRowWidget(){}

    RatingsRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 114 * _x2._config._text );
    };

    RatingsRowWidget.prototype.gotFocus = function( lostFocus, newScreen )
    {
        ListRowWidget.prototype.gotFocus.call( this, lostFocus );
        this._title.gotFocus();
        this._locked.gotFocus();
        this._unlocked.gotFocus();
        this._title.setSpeechParams( this._isLocked === true ? "Locked." : "Not Locked.", undefined, this, newScreen );

        return this;
    };

    /**
     * Initializer
     * @memberof RatingsRowWidget
     * @param   {Object}            params
     * @param   {RatingsController} params.obj   - Object that manages to the collection of rows
     * @param   {String}            params.title - String to be displayed in the entry
     * @param   {Number}            params.w     - Display width of the row
     * @returns {RatingsRowWidget}
     * @see ListRowWidget
     */
    RatingsRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );
        this._className  = "RatingsRowWidget";
        this._selectable = true;

        var self       = this;
        var titleStyle = new Style( { color:"#b8b8b8", colorHi:"#2ea0dd", font:"light", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap" } );

        this._numElements = 0;
        this._driver      = params.obj;

        this._title = new StringWidget().init( { text:params.title, style:titleStyle } );
        this._title.addReadyCallback( function() { self._numElements++; self.layout(); } );
        this.addWidget( this._title );

        this._locked = new DualImageWidget().init( { url:_x2._config._imageRoot + "lockClosedGrey.png", urlHi:_x2._config._imageRoot + "lockClosedBlue.png" } );
        this._locked.setA( 0 );
        this._locked.addReadyCallback( function() { self._numElements++; self.layout(); } );
        this.addWidget( this._locked );

        this._unlocked = new DualImageWidget().init( { url:_x2._config._imageRoot + "lockOpenGrey.png", urlHi:_x2._config._imageRoot + "lockOpenBlue.png" } );
        this._unlocked.addReadyCallback( function() { self._numElements++; self.layout(); } );
        this.addWidget( this._unlocked );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        this._driver.registerEntry( this );

        return this;
    };

    RatingsRowWidget.prototype.layout = function()
    {
        if( this._numElements === 3 )
        {
            this._title.setX( 2 * this._locked.getW() );
            this._title.setY( (this.getHeightUnfocused() - this._title.getH()) / 2 );
            this._locked.setY( (this.getHeightUnfocused() - this.getSepH() - this._locked.getH()) / 2 );
            this._unlocked.setY( (this.getHeightUnfocused() - this.getSepH() - this._unlocked.getH()) / 2 );
        }
    };

    RatingsRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._title.lostFocus();
        this._locked.lostFocus();
        this._unlocked.lostFocus();
    };

    RatingsRowWidget.prototype.processEvent = function( val, type )
    {
        var retval;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    retval = true;
                    this._driver.entrySelected( this._key );
                }
                break;
        }

        if( retval === undefined )
            retval = ListRowWidget.prototype.processEvent.call( this, val, type );

        return retval;
    };

    RatingsRowWidget.prototype.setData = function( data )
    {
        if( data !== undefined )
        {
            this._title.setText( data.title );
            this._key = data.rating;
            this.setSelected();
            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    RatingsRowWidget.prototype.setSelected = function()
    {
        if( this._key )
        {
            if( this._driver._val <= this._key.val )
            {
                this._isLocked = true;
                this._locked.animate( { a:1, duration:250 } );
                this._unlocked.animate( { a:0, duration:250 } );

                if( _x2._focus === this )
                    this._title.setSpeechParams( this._isLocked === true ? "Locked." : "Not Locked.", undefined, this, false );
            }
            else
            {
                this._isLocked = false;
                this._locked.animate( { a:0, duration:250 } );
                this._unlocked.animate( { a:1, duration:250 } );

                if( _x2._focus === this )
                    this._title.setSpeechParams( this._isLocked === true ? "Locked." : "Not Locked.", undefined, this, false );
            }
        }
    };

    return RatingsRowWidget;

})();


/**
 * @class
 */
window.RatingsController = ( function()
{
    "use strict";

    /**
     * Constructor
     * @memberof RatingsController
     * @param   {RatingsRowWidget.Type} type - MOVIE, TV
     * @returns {RatingsController}
     */

    function RatingsController( type )
    {
        var prop;

        this._type    = type;
        this._entries = [];

        // find which rating is locked

        if( type === RatingsRowWidget.Type.MOVIE )
        {
            for( prop in ParentalControls.MpaaRatings )
            {
                if( _x2._pc.isMovieRatingLocked( ParentalControls.MpaaRatings[prop] ) === true )
                {
                    this._val = ParentalControls.MpaaRatings[prop].val;
                    break;
                }
            }
        }
        else if( type === RatingsRowWidget.Type.TV )
        {
            for( prop in ParentalControls.TvRatings )
            {
                if( _x2._pc.isTvRatingLocked( ParentalControls.TvRatings[prop] ) === true )
                {
                    this._val = ParentalControls.TvRatings[prop].val;
                    break;
                }
            }
        }
        else
            console.error( "ERROR -> unknown rating type = " + type );
    }

    RatingsController.prototype.entrySelected = function( rating )
    {
        this._val = (this._val <= rating.val) ? rating.val + 1: rating.val;

        for( var i = 0; i < this._entries.length; i++ )
        {
            this._entries[i].setSelected( this._val );

            if( this._entries[i]._key )
            {
                if( this._type === RatingsRowWidget.Type.MOVIE )
                    _x2._pc.setMovieRatingLock( this._entries[i]._key, (this._entries[i]._key.val >= this._val) );
                else if( this._type === RatingsRowWidget.Type.TV )
                    _x2._pc.setTvRatingLocks( this._entries[i]._key, (this._entries[i]._key.val >= this._val) );
                else
                    console.error( "ERROR -> unknown rating type = " + this._type );
            }
        }

        _x2._data.setParentalControlsRatings( _x2._pc );
    };

    RatingsController.prototype.registerEntry = function( entry )
    {
        this._entries.push( entry );
    };

    return RatingsController;

})();
