// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

// TODO
// - remove and insert entries, all entries must be in init function
// - repeat scrolling

/**
 * Horizontal Menu Bar with optional '<' and '>' if more items can fit that are visible
 * @class
 * @see HorizPillButtonsWidget
 * @see TabbedPillButtonsWidget
 */
window.HorizMenuWidget = ( function()
{
    "use strict";

    HorizMenuWidget.prototype = new Widget();

    function HorizMenuWidget(){}

    HorizMenuWidget.prototype.gotFocus = function()
    {
        this.setHighlight();
    };

    /**
     * Initializer
     * <p>
     * Handles LEFT, RIGHT, ENTER, navigation keypresses
     * @memberof HorizMenuWidget
     * @param   {Object}    params
     * @param   {Widget[]}  params.entries        - Widgets to display
     * @param   {Number}    params.spacing        - Spacing between menu items in pixels
     * @param   {Boolean}  [params.flex=0]        - true if we should nudge the menu items spacing to fit
     * @param   {Function}  params.onEntryChanged - Callback
     * @param   {Number}    params.w              - Total width all menu items need to fit within
     * @param   {Number}    params.h              - Total height of menu bar
     * @returns {HorizMenuWidget}
     */
    HorizMenuWidget.prototype.init = function( params )
    {
        var self = this, i, onPointDown;
        var entryLoaded = function() { self._numLoaded++; self.layout(); };

        if( this._className === undefined )
            this._className = (params && params.name) ? params.name : "HorizMenuWidget";

        Widget.prototype.init.call( this, params );

        this._entries = params.entries;
        this._spacing = params.spacing;
        this._flex    = params.flex;
        this._onEntryChanged = params.onEntryChanged;

        this._rect = new RectWidget().init( { w:params.w, h:0, borderColor:"#363636", borderThickness:[ 2, 0, 2, 0] } );
        this.addWidget( this._rect );

        this._strip = new Widget().init( { name:"Strip" } );

        this._scroll = new ScrollWidget().init( { widget:this._strip, w:params.w, h:params.h } );
        this._rect.addWidget( this._scroll );

        this._moreLeft  = new ImageWidget().init( { url:_x2._config._imageRoot + "moreLeft.png" , onLoad:entryLoaded } );
        this._moreRight = new ImageWidget().init( { url:_x2._config._imageRoot + "moreRight.png", onLoad:entryLoaded } );

        this._moreLeft.setVal ( Widget.ALPHA, 0 );
        this._moreRight.setVal( Widget.ALPHA, 0 );

        this.addWidget( this._moreLeft  );
        this.addWidget( this._moreRight );

        if( _x2._config._cursorEnabled === true )
        {
            onPointDown = function( index )
            {
                return function( event )
                {
                    event.stopPropagation();

                    if( self._onEntryChanged && index !== self._index )  // has a change listener
                    {
                        if( _x2._focus === self )
                            self._entries[self._index].lostFocus();

                        self._index = index;

                        var deltaX = self._maxHiX - self._entries[self._index].getVal( Widget.X );

                        if( deltaX > 0 )
                            deltaX = 0;

                        if( deltaX === 0 )
                            self._moreLeft.animate( { alpha:0, duration:Config.ANI_TIME_HORIZ_MENU } );
                        else
                            self._moreLeft.animate( { alpha:1, duration:Config.ANI_TIME_HORIZ_MENU } );

                        if( (self._strip.getVal( Widget.W ) + deltaX) > self.getVal( Widget.W ) )
                            self._moreRight.animate( { alpha:1, duration:Config.ANI_TIME_HORIZ_MENU } );
                        else
                            self._moreRight.animate( { alpha:0, duration:Config.ANI_TIME_HORIZ_MENU } );

                        self._scroll.setScroll( { x:deltaX, duration:Config.ANI_TIME_HORIZ_MENU } );

                        if( _x2._focus === self )
                        {
                            self._entries[self._index].gotFocus();
                            self.setHighlight();
                        }
                        else
                            _x2.requestFocus( self );
                    }
                    else  // call select
                    {
                        if( self._entries[index].onEnter() )
                            self._entries[index].onEnter();
                    }
                };
            };
        }

        this._numLoaded = 0;

        for( i = 0; i < this._entries.length; i++ )
        {
            this._entries[i].addReadyCallback( entryLoaded );
            this._entries[i].setVal( Widget.ALPHA, 0 );
            this._strip.addWidget( this._entries[i] );

            if( _x2._config._cursorEnabled === true )
                this._entries[i].setMouseDownListener( onPointDown( i ) );
        }

        this._index      = 0;
        this._selectable = true;

        this.setVal( Widget.W, params.w );

        return this;
    };

    HorizMenuWidget.prototype.layout = function()
    {
        var onExpanded, i, maxH = 0, x = 0, self = this, actW, maxW, spaceDelta, ratio, widget;

        if( this._numLoaded === (this._entries.length + 2) )
        {
            for( i = 0; i < this._entries.length; i++ )
                if( this._entries[i].getVal( Widget.H ) > maxH )
                    maxH = this._entries[i].getVal( Widget.H );

            maxH += 4 * Style._pad;

            this.setVal( Widget.H, maxH );

            for( i = 0; i < this._entries.length; i++ )
            {
                this._entries[i].setVal( Widget.X, x );
                this._entries[i].setVal( Widget.Y, (maxH - this._entries[i].getVal( Widget.H )) / 2 );

                x += this._entries[i].getVal( Widget.W ) + this._spacing;
            }

            this._moreLeft.setVal ( Widget.X, -this._moreLeft.getVal( Widget.W ) - Style._pad );
            this._moreLeft.setVal ( Widget.Y, (maxH - this._moreLeft.getVal ( Widget.H )) / 2 );
            this._moreRight.setVal( Widget.X, this.getVal( Widget.W ) + Style._pad );
            this._moreRight.setVal( Widget.Y, (maxH - this._moreRight.getVal( Widget.H )) / 2 );

            if( this._flex !== undefined )
            {
                maxW = this.getVal( Widget.W );
                actW = x - this._spacing;

                ratio = actW / maxW;

                if( ratio >= (1 - this._flex) && ratio <= (1 + this._flex ) )
                {
                    spaceDelta = (this.getVal( Widget.W ) - x + this._spacing) / (this._entries.length - 1);
                    x          = 0;

                    for( i = 0; i < this._entries.length - 1; i++ )
                    {
                        this._entries[i].setVal( Widget.X, x );
                        x += this._entries[i].getVal( Widget.W ) + this._spacing + spaceDelta;
                    }

                    this._entries[i].setVal( Widget.X, this.getVal( Widget.W ) - this._entries[this._entries.length-1].getVal( Widget.W ) );
                }
            }

            widget = this._entries[this._entries.length-1];

            this._strip.setVal( Widget.W, widget.getVal( Widget.X) + widget.getVal( Widget.W ) );
            this._strip.setVal( Widget.H, maxH );
            this._scroll.setVal( Widget.H, maxH );

            if( this._strip.getVal( Widget.W ) > this.getVal( Widget.W ) )
                this._moreRight.setVal( Widget.ALPHA, 1.0 );

            if( this._strip.getVal( Widget.W ) === this.getVal( Widget.W ) )
                this._maxHiX = this.getVal( Widget.W );
            else if( this._entries.length > 2 )
                this._maxHiX = this._entries[2].getVal( Widget.X );

            onExpanded = function ()
            {
                for( i = 0; i < self._entries.length; i++ )
                    self._entries[i].animate( { alpha:1, start:i*50, duration:500 } );

                if( _x2._focus === self )
                {
                    self._entries[self._index].animate( { color:"#2ea0dd", duration:500 } );
                    self.setHighlight( 0 );
                }
            };

            this._rect.animate( { h:maxH, duration:300, onEnd:onExpanded } );
        }
    };

    HorizMenuWidget.prototype.processEvent = function( val, type )
    {
        var retval = true;
        var deltaX;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                    this._entries[this._index].onEnter();
                break;

            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._index > 0 )
                    {
                        deltaX = this._maxHiX - this._entries[this._index-1].getVal( Widget.X );

                        if( deltaX > 0 )
                            deltaX = 0;

                        if( deltaX <= 0 )
                            this._scroll.setScroll( { x:deltaX, duration:Config.ANI_TIME_HORIZ_MENU } );

                        if( deltaX === 0 )
                            this._moreLeft.animate( { alpha:0, duration:Config.ANI_TIME_HORIZ_MENU } );

                        if( (this._strip.getVal( Widget.W ) + deltaX) > this.getVal( Widget.W ) )
                            this._moreRight.animate( { alpha:1, duration:Config.ANI_TIME_HORIZ_MENU } );

                        this._entries[this._index--].lostFocus();
                        this._entries[this._index].gotFocus();
                        this.setHighlight();
                    }
                }
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._index < this._entries.length - 1 )
                    {
                        deltaX = this._maxHiX - this._entries[this._index+1].getVal( Widget.X );

                        if( deltaX < 0 )
                        {
                            this._scroll.setScroll( { x:deltaX, duration:Config.ANI_TIME_HORIZ_MENU } );
                            this._moreLeft.animate( { alpha:1, duration:Config.ANI_TIME_HORIZ_MENU } );
                        }

                        if( (this._strip.getVal( Widget.W ) + deltaX) <= this.getVal( Widget.W ) )
                            this._moreRight.animate( { alpha:0, duration:Config.ANI_TIME_HORIZ_MENU } );

                        this._entries[this._index++].lostFocus();
                        this._entries[this._index].gotFocus();
                        this.setHighlight();
                    }
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    HorizMenuWidget.prototype.setHighlight = function( time )
    {
        var obj    = this.getGlobalPos();
        var entryX = this._entries[this._index].getVal( Widget.X );

        obj.x       += ((entryX > this._maxHiX) ? this._maxHiX : entryX);
        obj.y       += 2;
        obj.w        = this._entries[this._index].getVal( Widget.W );
        obj.h        = this.getVal( Widget.H );
        obj.inner    = false;
        obj.duration = (time === undefined) ? 300 : 0;

        _x2._hi.setPosition( obj );
    };

    return HorizMenuWidget;

})();
