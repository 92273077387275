// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.UpcomingRowWidget = ( function()
{
    "use strict";

    UpcomingRowWidget.prototype = new ListRowWidget();

    function UpcomingRowWidget(){}

    UpcomingRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt(120);
    };

    UpcomingRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        ListRowWidget.prototype.gotFocus.call( this );
        this._line1.gotFocus();

        this._line1.setSpeechParams( this._line2.getText() + ". Press OK to select.", undefined, this, newScreen );

        return this;
    };

    UpcomingRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );
        this._className = "UpcomingRowWidget";

        var line1Style = new Style( { color:"#e8e8e8", font:"light", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#2ea0dd" } );
        var line2Style = new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );

        this._line1 = new StringWidget().init( {style:line1Style});
        this._line2 = new StringWidget().init( {style:line2Style});

        this.addWidget( this._line1, 0, _x2.scaleValInt(20) );
        this.addWidget( this._line2, 0, _x2.scaleValInt(65) );

        return this;
    };

    UpcomingRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._line1.lostFocus();
    };

    UpcomingRowWidget.prototype.processEvent = function( val, type )
    {
        var retval = false;

        if( val === Host.KEY_ENTER && type === Host.KEY_PRESSED )
        {
            if( this._entity.getType() === XtvApi.EntityType.EPISODE )
                _x2.pushScreen( new EpisodeScreen().init( { entity: this._entity } ) );
            else
                _x2.pushScreen( new EntityScreen().init( { entity: this._entity } ) );

            retval = true;
        }

        return retval;
    };

    UpcomingRowWidget.prototype.setData = function( data )
    {
        if( data !== undefined )
        {
            this._entity = data;

            var season, episode;

            var line1 = "", line2 = "";

            var epTitle = data.getTitle();
            var start   = data.getStartTime();
            var end     = data.getEndTime();
            var program = data.getProgram();
            var channel = data.getChannel ? data.getChannel() : undefined;

            if( program )
            {
                season  = program.getSeasonNumber();
                episode = program.getEpisodeNumber();

                if(season)
                    line1 = "S" + season + " ";

                if(episode)
                    line1 += "Ep" + episode + " ";
            }

            if(epTitle)
                line1 += epTitle;

            if( start )
                line2 = _x2.createTimeStr( new Date(start));

            if( start && end )
                line2 += " - ";

            if( end )
                line2 += _x2.createTimeStr( new Date(end));

            if( channel )
                line2 += " on " + channel.getNumber() + " " + channel.getCallSign();

            this._line1.setText( line1 );
            this._line2.setText( line2 );

            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    return UpcomingRowWidget;

})();
