// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ViperPlayerPlatform = ( function()
{
    "use strict";

    function ViperPlayerPlatform( url, listener, resume, lang, contentOptions, videoEas )
    {
        var self = this, obj, onBitRateChanged, onBufferComplete, onBufferStart, onEasComplete, onEasFailure, onEasStarted, onMediaEnded, onMediaOpened, onMediaProgress, onPlaybackSpeedChanged, onPlayStateChanged;

        this._listener = listener;

        _x2._config.debugAddClass( Config.CLASS_VIPER, 5 );

        onBitRateChanged = function( event )
        {
            self._listener.onBitRateChanged( event.bitRate, self._playerPlatform.getVideoWidth(), self._playerPlatform.getVideoHeight() );
        };

        onBufferComplete = function()
        {
            self._listener.onBufferComplete();
        };

        onBufferStart = function()
        {
            self._listener.onBufferStart();
        };

        onEasComplete = function()
        {
            console.log( "Emergency Alert Complete" );
            self._listener.onEmergencyAlertComplete();
        };

        onEasFailure = function()
        {
            console.error( "Emergency Alert Failure" );
        };

        onEasStarted = function()
        {
            console.log( "Emergency Alert Started" );
            self._listener.onEmergencyAlertStarted();
        };

        onMediaEnded = function()
        {
            self._listener.onMediaEnded();
        };

        var onMediaFailed = function( event )
        {
            console.log( event );
            self._listener.onMediaFailed( event );
        };

        onMediaOpened = function( event )
        {
            _x2._config.log( Config.CLASS_VIPER, 9 ) ? console.log( event ) : Config.NOP();
            self._listener.onMediaOpened();

            console.log( "PlayerPlatform Version: " + self._playerPlatform.getVersion() );
        };

        onMediaProgress = function( event )
        {
            _x2._config.log( Config.CLASS_VIPER, 9 ) ? console.log( event ) : Config.NOP();
            self._listener.onMediaProgress( event.position, event.startposition, event.endposition );
        };

//         var onMediaRetry = function( event )
//         {
//             console.log( event );
// //                self._listener.onMediaEnded();
//         };

        onPlaybackSpeedChanged = function( event )
        {
            self._listener.onPlaybackSpeedChanged( event.playbackSpeed );
        };

        onPlayStateChanged = function( event )
        {
            _x2._config.log( Config.CLASS_VIPER, 9 ) ? console.log( event ) : Config.NOP();

            switch( event.playState )
            {
                case "buffering":
                case "complete":
                    break;

                case "error":
                    self._listener.onError( event );
                    break;

                case "idle":
                case "initializing":
                case "prepared":
                case "preparing":
                case "ready":
                case "released":
                    break;

                case "seeking":
                    break;

                case "initialized":
                    self._volume = self._playerPlatform.getVolume();
                    self._playerPlatform.setCurrentTimeUpdateInterval( 2000 );  // not working
// self._playerPlatform.setVolume( 0 );
// self._volume = 0;

self._listener.onInitialized( self._playerPlatform.getSupportedPlaybackSpeeds() );

// var speeds    = self._playerPlatform.getSupportedPlaybackSpeeds();
// var newSpeeds = [];
//
// console.log( speeds );
//
// for( var i = 0; i < speeds.length; i++ )
//     if( speeds[i] > 1 )
//         newSpeeds.push( speeds[i] );
//
// self._listener.onInitialized( newSpeeds );
                    break;

                case "paused":
                    self._state = VideoScreen.State.PAUSED;
                    self._listener.onPaused();
                    break;

                case "playing":
                    self._state = VideoScreen.State.PLAY;
                    self._listener.onPlay();
                    break;

                default:
                    console.error( "ERROR -> unknown PlayStateChanged event:" );
                    console.error( event );
                    break;
            }
        };

        var onHaveSecClient = function()
        {
            obj =
            {
                videoElement : _x2._videoRoot,
                secClient    : _x2._config._secClientV2,
                configuration:  {
                                    analyticsDeviceType   :"x2-html",
                                    licenseServerUrl      : _x2._config._licenseServer,
//                                 retryOnMediaFailed: "true",
//                                 defaultAsset: { initialBitrate: 0, initialBufferTime: 2000 },
//                                 logstashEndpoint: "http://96.118.37.206:8080/helio/js/"
                                    zipToFipsEndPoint     :"https://easip-client.ccp.xcal.tv/eas/api/fips/zip/",
                                    alertServiceEndPoint  :"https://easip-client.ccp.xcal.tv/eas/api/alert/active/fipscode/",
                                    easTextOverlay        :( videoEas !== true ) ? pp.ConfigurationManager.EAS_TEXT_OVERLAY : undefined,
                                    audioLanguage         :lang,
                                    helioEas              :true,

                                    defaultAsset:
                                    {
                                        "cdnRedundant"         : true,
                                        forceHttps             : (_x2._config._host.getType() !== Host.Type.PC && _x2._config._host.getType() !== Host.Type.ANDROID),
                                        easEnabled             : true,
                                        initialBitrate         : 5000000,
                                        initialBufferTime      : 6100,
                                        playingVODBufferTime   : 30000,
                                        playingLinearBufferTime: 6000,
                                        intersegmentDelay      : 300,
                                        playbackStalledEnabled : true
                                    },

                                    easAsset:
                                    {
                                        forceHttps: true
                                    }
                                }
            };

            // events not currently considered interesting
            // AD_BREAK_COMPLETE
            // AD_BREAK_EXITED
            // AD_BREAK_START
            // AD_BREAK_UPCOMING
            // AD_COMPLETE
            // AD_ERROR
            // AD_EXITED
            // AD_PROGRESS
            // AD_SEEK
            // AD_START
            // AD_SKIPPED
            // ADS_SKIPPED
            // EMERGENCY_ALERT_IDENTIFIED
            // EMERGENCY_ALERT_EXEMPTED
            // EMERGENCY_ALERT_ERRORED

            // events under consideration
            // DROPPED_FPS_CHANGED
            // DURATION_CHANGED
            // FPS_CHANGED
            // FRAGMENT_INFO
            // MEDIA_FALLBACK
            // MEDIA_RETRY
            // MEDIA_WARNING
            // MISSING_DRM_TOKEN
            // NUMBER_OF_ALTERNATIVE_AUDIO_STREAMS_CHANGED
            // NUMBER_OF_CLOSED_CAPTIONS_STREAMS_CHANGED
            // PLAYBACK_SPEEDS_CHANGED
            // PLAYBACK_STARTED
            // SEEK_COMPLETE
            // SEEK_START
            // DRM_METADATA
            // VPAID_AD_EVENT
            // STREAM_SWITCH

            self._playerPlatform = new pp.PlayerPlatformAPI( obj );

// console.log( self._playerPlatform.getSupportedClosedCaptionsOptions() );
//
//self._playerPlatform.setClosedCaptionsStyle( { textForegroundColor:"red" } );

            // configure events to be handled

            self._playerPlatform.on( pp.Events.BITRATE_CHANGED         , onBitRateChanged       );
            self._playerPlatform.on( pp.Events.BUFFER_COMPLETE         , onBufferComplete       );
            self._playerPlatform.on( pp.Events.BUFFER_START            , onBufferStart          );
            self._playerPlatform.on( pp.Events.EMERGENCY_ALERT_COMPLETE, onEasComplete          );
            self._playerPlatform.on( pp.Events.EMERGENCY_ALERT_FAILURE , onEasFailure           );
            self._playerPlatform.on( pp.Events.EMERGENCY_ALERT_STARTED , onEasStarted           );
            self._playerPlatform.on( pp.Events.MEDIA_ENDED             , onMediaEnded           );
            self._playerPlatform.on( pp.Events.MEDIA_FAILED            , onMediaFailed          );
            self._playerPlatform.on( pp.Events.MEDIA_OPENED            , onMediaOpened          );
            self._playerPlatform.on( pp.Events.MEDIA_PROGRESS          , onMediaProgress        );
//                self._playerPlatform.on( pp.Events.MEDIA_RETRY           , onMediaRetry           );
            self._playerPlatform.on( pp.Events.PLAYBACK_SPEED_CHANGED  , onPlaybackSpeedChanged );
            self._playerPlatform.on( pp.Events.PLAY_STATE_CHANGED      , onPlayStateChanged     );
//              self._playerPlatform.on( pp.Events.DURATION_CHANGED        , onDurationChanged      ); // Not valid until video is ended: event.target.duration

            var xsct = JSON.parse( Host.getLocalStorage( "XsctToken" ) );

            self._playerPlatform.configureAnalytics( xsct, AboutOverlay.version );
            self._playerPlatform.configureEmergencyAlerts( xsct.xsct );

            _x2._config.log( Config.CLASS_VIPER, 1 ) ? console.log( "PlayerPlatform Version: " + self._playerPlatform.getVersion() ) : Config.NOP();

            if( url )
                self.play( url, listener, resume, contentOptions );
        };

        if( _x2._config._secClientV2 === undefined )
        {
            var cryptoType = _x2._config._host.getCryptoType();
            var deviceType = _x2._config._host.getSecClientDeviceType();

            var secClientConfig =
            {
                crypto:
                {
                    type: cryptoType
                },
                timeout: 10000
            };

            if( deviceType )
                secClientConfig.deviceType = deviceType;

            _x2._config._secClientV2 = new window[ _x2._config._secClientV2Var ].SecClient();
            _x2._config._secClientV2.load( secClientConfig ).then( onHaveSecClient );
        }
        else
            onHaveSecClient();
    }

    ViperPlayerPlatform.prototype.destroy = function()
    {
        var children = _x2._videoRoot.childNodes;

        for( var i = 0; i < children.length; i++ )
            _x2._videoRoot.removeChild( children[i] );
    };

    ViperPlayerPlatform.prototype.getCaptions = function()
    {
        return this._playerPlatform.getAvailableClosedCaptionTracks();
    };

    ViperPlayerPlatform.prototype.getCurrentLanguage = function()
    {
        return this._playerPlatform.getCurrentAudioLanguage();
    };

    ViperPlayerPlatform.prototype.getCurrentPosition = function()
    {
        return this._playerPlatform.getCurrentPosition();
    };

//     ViperPlayerPlatform.prototype.getCurrentSpeed = function()
//     {
//         return this._playerPlatform.getCurrentPlaybackSpeed();
//     };

    ViperPlayerPlatform.prototype.getDuration = function()
    {
        return this._playerPlatform.getDuration();
    };

    ViperPlayerPlatform.prototype.getLanguages = function()
    {
        return this._playerPlatform.getAvailableAudioLanguages();
    };

    ViperPlayerPlatform.prototype.getPlaySpeed = function()
    {
        return this._playerPlatform.getCurrentPlaybackSpeed();
    };

    ViperPlayerPlatform.prototype.getState = function()
    {
        return this._state;
    };

    ViperPlayerPlatform.prototype.getVersion = function()
    {
        return this._playerPlatform.getVersion();
    };

    ViperPlayerPlatform.prototype.mute = function()
    {
        if( this._playerPlatform.getVolume() === 0 )
            this._playerPlatform.setVolume( this._volume );
        else
            this._playerPlatform.setVolume( 0 );
    };

    ViperPlayerPlatform.prototype.pause = function()
    {
        this._playerPlatform.pause();
    };

    ViperPlayerPlatform.prototype.setPcBlock = function( bool )
    {
//        this._playerPlatform.setBlock( bool );  // should be using this, but was not implemented

        if( bool === true )
        {
            _x2._videoRoot.style.display = "none";
            this._playerPlatform.setVolume( 0 );
        }
        else
        {
            _x2._videoRoot.style.display = "initial";
            this._playerPlatform.setVolume( 1 );
        }
    };

    ViperPlayerPlatform.prototype.play = function( url, listener, resume, contentOptions )
    {
        if( url !== undefined )
        {
            if( this._playerPlatform )
{
    console.log( this._playerPlatform.eas );
    this._playerPlatform.eas.start();
}
            this._listener = listener;

            var assetType;

            if( contentOptions.recordingId )
                assetType = "CDVR";
            else if( contentOptions.streamId )
                assetType = "T6_LINEAR";
            else
                assetType = "T6_VOD";

            var token   = JSON.parse( Host.getLocalStorage( "XsctToken" ) );
            var xact    = JSON.parse( Host.getLocalStorage( "XactToken" ) );
            var options =
            {
                authToken                 : token.xsct,
                deviceAuthenticationResult: xact,
                assetEngine               : "helio",
                keySystem                 : _x2._config._host.getDrmType(),
                streamId                  : contentOptions.streamId,
                recordingId               : contentOptions.recordingId,
                assetId                   : contentOptions.assetId,
                providerId                : contentOptions.providerId,
                mediaGuid                 : contentOptions.mediaGuid,
//                assetType                 : assetType
            };

            if( resume )
                options.resumePosition = resume;

            console.log("Playing url : ", url, " with options: ", options , options.resumePosition );
            this._playerPlatform.setAsset( pp.Asset.create( url, options ) );
            pp.setLogLevel( (_x2._config._ppLog === true) ? pp.LogLevel.ALL : pp.LogLevel.NONE );
        }
        else
            this._playerPlatform.play();
    };

    ViperPlayerPlatform.prototype.setCaption = function( track )
    {
        this._playerPlatform.setClosedCaptionsTrack( track );
    };

    ViperPlayerPlatform.prototype.setCaptionsEnabled = function( bool )
    {
        this._playerPlatform.setClosedCaptionsEnabled( bool );
    };

    ViperPlayerPlatform.prototype.setLanguage = function( lang )
    {
        this._playerPlatform.setPreferredAudioLanguage( lang );
    };

    ViperPlayerPlatform.prototype.setPosition = function( msec )
    {
        this._playerPlatform.setPosition( msec )
    };

    ViperPlayerPlatform.prototype.setSpeed = function( speed )
    {
        this._playerPlatform.setSpeed( speed )
    };

    ViperPlayerPlatform.prototype.skipBackward = function( msec )
    {
        this._playerPlatform.setPositionRelative( -msec );
    };

    ViperPlayerPlatform.prototype.skipForward = function( msec )
    {
        this._playerPlatform.setPositionRelative( msec );
    };

    ViperPlayerPlatform.prototype.stop = function()
    {
        this._playerPlatform.eas.stop();
        this._playerPlatform.stop();
    };

    ViperPlayerPlatform.prototype.volumeDown = function()
    {
        if( this._volume > 0 )
        {
            this._volume -= 0.05;

            if( this._volume < 0 )
                this._volume = 0;

            this._playerPlatform.setVolume( this._volume );
            _x2._config.log( Config.CLASS_VIPER, 3 ) ? console.log( "VOLUME = " + this._volume ) : Config.NOP();
        }
    };

    ViperPlayerPlatform.prototype.volumeUp = function()
    {
        if( this._volume < 1 )
        {
            this._volume += 0.05;

            if( this._volume > 1 )
                this._volume = 1;

            this._playerPlatform.setVolume( this._volume );
            _x2._config.log( Config.CLASS_VIPER, 3 ) ? console.log( "VOLUME = " + this._volume ) : Config.NOP();
        }
    };

    return ViperPlayerPlatform;

})();
