// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * Base for all screens
 * @class
 */
window.Screen = ( function()
{
    "use strict";

    Screen.prototype = new Widget();

    function Screen(){}

    Screen.prototype.destroy = function()
    {
        _x2.removeChangeCallback( this._timeCallback );

        Widget.prototype.destroy.call( this );
    };

    Screen.prototype.getBgImage = function()
    {
        return this._image;
    };

    Screen.prototype.getBreadCrumb = function()
    {
        var base   = this._bcBase.getText();
        var term   = this._bcTerm.getText();
        var retval = base ? base : "";

        if( term )
            retval += term;

        return retval;
    };

    /**
     * @memberof Screen
     * @returns {StringWidget}
     */
    Screen.prototype.getBreadCrumbsTermStr = function()
    {
        return this._termStr;
    };

    /**
     * Gets the y value of the bottom of the header
     * @memberof Screen
     * @returns {Number}
     */
    Screen.prototype.getHeaderBottom = function()
    {
        return (this._time) ? this._time.getY() + this._time.getH() + 3 * Style._pad : this._headerY;
    };

    Screen.prototype.hideDistractor = function()
    {
        this._distractorCount--;

        if( this._distractorCount === 0 )
            this._distractor.hide();
    };

    Screen.prototype.setHeaderFilters = function( filters )
    {
        this._filters = filters;
        this.setTimeStr();
    };

    Screen.prototype.setTimeStr = function()
    {
        var str, filters = this._filters;

        if( filters )
        {
            for( var key in filters )
                if( filters[key] === true )
                {
                    key = FilterChannelsOverlay.FilterStrs[key];

                    if( key )
                        str = (str === undefined) ? "Filtered by: <font color='#2B9CD8'>" + key : str + ", " + key;
                    else
                        console.error( "Unknown filter = " + key );
                }

            if( str )
                str += "</font> | ";
        }

        this._time.setText( str ? str + _x2.createTimeStr() : _x2.createTimeStr() );
    };

    Screen.prototype.showDistractor = function()
    {
        var self = this;

        if( ! this._distractor )
        {
            this._distractorCount = 0;

            this._distractor = new DistractorWidget().init();
            this._distractor.addReadyCallback( function() { self._distractor.centerOnScreen() } );
            this.addWidget( this._distractor );
            this._distractor.patchWidget();
        }

        this._distractorCount++;

        if( this._distractorCount === 1 )
            this._distractor.show();
    };

    /**
     * Initializer
     * <p>
     * Optional breadcrumb is displayed as: "<path> / <folder>"
     * @memberof Screen
     * @param {Object } [params]
     * @param {String } [params.baseStr       ] - "path" name
     * @param {String } [params.termStr       ] - "folder" name
     * @param {Boolean} [params.noHeader=false] - Hide breadcrumb header in top left along with time
     * @param {Boolean} [params.noBg=false    ] - Hide the solid background color
     * @param {Boolean} [params.noSep=false   ] - Hide the solid background color
     * @param {String } [params.image         ] - Load the specified image in front of the background
     * @param {String } [params.imageAlpha    ] - Set the alpha value of the image
     */
    Screen.prototype.init = function( params )
    {
        var timeStyle, bcStyle, touchW, headerY, bgParams, self = this;

//         if( this._className === undefined )
//             this._className = (params && params.name) ? params.name : "Screen";

        Widget.prototype.init.call( this );
        this._className = "Screen";

        if( !params || !params.noBg )
        {
            bgParams  = {
                            w     : _x2._config._screenW,
                            h     : _x2._config._screenH,
                            color : "#000000"
                        };

            this._bg = new RectWidget().init( bgParams );
//            this._bg.setVal( Widget.ALPHA, 0.8 );
            this.addWidget( this._bg );
        }

        if( params && params.image )
        {
            this._imageAlpha = params.imageAlpha !== undefined ? params.imageAlpha : 1;

            this._image = new ImageWidget().init( { url:params.image, onLoad:function() { self._image.animate( { a:self._imageAlpha, duration:500 } ); } } );
            this._image.setA( 0 );
            this.addWidget( this._image );
        }

        headerY = [ 31, 35, 47, 70 ][_x2._config._layout];

        if( !params || !params.noHeader )
        {
            bcStyle   = new Style( { font:"light", color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"pre" } );
            timeStyle = new Style( { font:"light", color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", textAlign:"right" } );
            touchW    = Math.floor( Style._safeLeft * 2 / 3 );


            this._bcBase = new StringWidget().init( { style:bcStyle } );
            this._bcTerm = new StringWidget().init( { style:bcStyle } );
            this._time   = new StringWidget().init( { text:_x2.createTimeStr(), style:timeStyle } );
            this._moreLeft  = new MoreTargetWidget().init( { dir:MoreTargetWidget.Dir.LEFT, size:MoreTargetWidget.Size.SMALL, h:100, w:touchW } );
            this._moreLeft.setA( 0 );
            this._moreLeft.setMouseDownListener ( function() { console.log( "BACK BACK BACK" ); _x2.popTopOfstack() } );

            this._bcTerm.setVal( Widget.FONT_COLOR, "#2ea0dd" );
            this._bcBase.addReadyCallback( function() { self.screenLayout(); } );
            this._time.setW( _x2._config._screenW/2 );

            this.addWidget( this._bcBase, Style._safeLeft, headerY );
            this.addWidget( this._bcTerm, Style._safeLeft, headerY );
            this.addWidget( this._time , _x2._config._screenW / 2 - Style._safeRight, headerY );
            this.addWidget( this._moreLeft, Style._safeLeft - touchW );

            if( params )
                this.setBreadCrumbs( params.baseStr, params.termStr );

            this._timeCallback = function() { self.setTimeStr(); };

            _x2.addChangeCallback( this._timeCallback );
        }
        else
            this._headerY = headerY;

        if( !params || !params.noSep )
        {
            this._sep = new RectWidget().init( { w:_x2._config._screenW - Style._safeLeft - Style._safeRight, h:ListRowWidget.DIVIDER_H, color:"#303030" } );
            this.addWidget( this._sep, Style._safeLeft );
        }

        if( this instanceof StackScreen === false )
        {
            this._speakTarget = new StringWidget().init( { style:timeStyle } );
            this._speakTarget.setA( 0 );
            this.addWidget( this._speakTarget );
        }

        this.setW( _x2._config._screenW );
        this.setH( _x2._config._screenH );
        this.setMouseDownListener( Config.NOP );

        this._selectable = true;

        return this;
    };

    Screen.prototype.screenLayout = function()
    {
        if( this._termStr )
            this._bcTerm.setX( this._baseStr ? Style._safeLeft + this._bcBase.getW() : Style._safeLeft );

        if( this._sep )
            this._sep.setY( this.getHeaderBottom() - this._sep.getH() );

        this._moreLeft.setH( 2 * this._time.getH() );
        this._moreLeft.setY( this._time.getY() - this._time.getH()/2 );
        this._moreLeft.setArrowH( this._time.getH() );
        this._moreLeft.animate( { a:1, duration:500 } );

        this.callbackSignalReady();
    };

    /**
     * @memberof Screen
     * @param {String|undefined} [baseStr] - "path" name
     * @param {String|undefined} [termStr] - "folder" name
     */
    Screen.prototype.setBreadCrumbs = function( baseStr, termStr )
    {
        this._baseStr = baseStr;
        this._termStr = termStr;

        this._bcBase.setText( (this._termStr && this._baseStr) ? this._baseStr + " / " : this._baseStr );
        this._bcTerm.setText( this._termStr );

        if( this._termStr )
            this._bcTerm.setX( this._baseStr ? Style._safeLeft + this._bcBase.getW() : Style._safeLeft );
    };

    return Screen;

})();
