// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.TextBlockTruncatedTestScreen = ( function()
{
    "use strict";

    TextBlockTruncatedTestScreen.prototype = new Screen();

    function TextBlockTruncatedTestScreen(){}

    TextBlockTruncatedTestScreen.prototype.init = function()
    {
        var headStyle, bodyStyle, textStyle;
        var text, widget;
        var borderWidth = 2;

        this._className = "TextBlockTruncatedTestScreen";
        Screen.prototype.init.call( this );

        this._deltaW      = 20;
        this._width       = 400;
        this._minWidth    = 50;
        this._maxWidth    = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        this._textWidgets = [];

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        headStyle = new Style( { color:"#2ea0dd", font:"medium", fontSize:_x2.scaleValInt(30), overflow:"hidden", whiteSpace:"nowrap" } );
        bodyStyle = new Style( { color:"#b8b8b8", font:"light" , fontSize:_x2.scaleValInt(41), overflow:"hidden"   } );
        textStyle = new Style( { color:"#00FF00", font:"medium", fontSize:_x2.scaleValInt(30), overflow:"hidden"   } );

        text   = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent magna tortor, tincidunt ac tristique sit amet, condimentum ut erat. Mauris sem mi, ultrices et hendrerit vitae, hendrerit non tortor. Aliquam erat volutpat. Morbi eget leo lorem, ut placerat nisl. Aenean auctor volutpat condimentum. Morbi adipiscing leo et felis faucibus suscipit nec at odio. Pellentesque convallis turpis non sapien facilisis quis volutpat magna venenatis. Etiam nisi metus, imperdiet vitae lobortis sit amet, pharetra ut leo.";
        widget = new TextBlockWidget().init( { text:text, style:bodyStyle, w:this._width, h:40*4, ellipsis:true } ); // lines: 4
        this._textWidgets.push( widget );
        this.addWidget( widget, Style._safeLeft );
        
        text   = "Spider-Man fights a new villain called Electro, and romances his high-school sweetheart Gwen Stacy. Meanwhile, his childhood friend Harry Osborn is named the CEO of Oscorp, a nefarious company connected to the disappearance of Peter Parker's parents.";
        widget = new TextBlockWidget().init( { text:text, style:bodyStyle, w:this._width, h:40*3, ellipsis:true } ); // lines: 3
        this._textWidgets.push( widget );
        
        text   = "Mitzi Gaynor and Rossano Brazzi team in this colorful adaptation of the Rodgers and Hammerstein classic. Billis: Ray Walston. Lt. Cable: John Kerr. Bloody Mary: Juanita Hall. Liat: France Nuyen. The songs include ''Bali Ha'i,'' ''Some Enchanted Evening,'' ''A Wonderful Guy,'' ''There Is Nothing like a Dame,'' ''Bloody Mary,'' ''I'm Gonna Wash That Man Right Outta My Hair,'' ''Carefully Taught,'' ''A Cockeyed Optimist,'' ''Younger than Springtime,'' ''Happy Talk'' and ''This Nearly Was Mine.'' Joshua Logan directed.";
        widget = new TextBlockWidget().init( { text:text, style:bodyStyle, w:this._width, h:40*2, ellipsis:true } ); // lines: 2
        this._textWidgets.push( widget );
        
        text   = "Eddie Murphy plays a womanizer who gets his comeuppance from a colleague (Robin Givens). Angela: Halle Berry. Gerard: David Alan Grier. Tyler: Martin Lawrence. Strange: Grace Jones. Lady Eloise: Eartha Kitt. Nelson: Geoffrey Holder. Bony T: Chris Rock. Directed by Reginald Hudlin.";
        widget = new TextBlockWidget().init( { text: text, style:bodyStyle, w:this._width, h:40*1, ellipsis:true } ); // lines: 1
        this._textWidgets.push( widget );
        
        textStyle._object.textOverflow = "ellipsis"; // add ellipsis style so text is clipped if unable to even display one word per line
        textStyle._object.overflow     = "hidden";

        text = "Cait is invited to her first all-girl sleepover by Candis; and she visits a support group for families with trans kids. Also: Kim and Khloé are critical of the comments Cait made about their mother in the Vanity Fair article.";
        text = unescape(escape(text)); // UTF-8 to Unicode
        widget = new TextBlockWidget().init( { text: text, style:textStyle, w:this._width, h:35*2, ellipsis:true } ); // width = 560, lines: 2
        this._textWidgets.push( widget );

        text = "Conclusion. Cait is challenged to break down her walls and get personal on her road trip with her new friends.";
        widget = new TextBlockWidget().init( { text: text, style:textStyle, w:this._width, h:35*2, ellipsis:true } ); // width = 560, lines: 2
        this._textWidgets.push( widget );

        text = "Manual 1 line / 2 lines";
        widget = new TextBlockWidget().init( { text: text, style:textStyle, w:this._width, h:35*2, ellipsis:true } ); // lines: 2
        this._textWidgets.push( widget );

        this._rects = new Array( this._textWidgets.length );

        for( var i = 0; i < this._rects.length; i++ )
        {
            this._rects[i] = new RectWidget().init( { w:this._width, h: 10, color:"#000", borderThickness:borderWidth, borderColor: "#007777" } );
            this.addWidget( this._rects[i]      , Style._safeLeft                 );
            this.addWidget( this._textWidgets[i], Style._safeLeft + 2+borderWidth );
        }
 
        text = "" + this._width;
        this._stringWidth = new StringWidget().init( { text:text, style:headStyle } );
        this.addWidget( this._stringWidth, Style._safeLeft );
      
        this._selectable = true;

        return this;    
    };

    TextBlockTruncatedTestScreen.prototype.layout = function()
    {
        var y = Style._safeTop/1.8, h;
    
        for( var i = 0; i < this._textWidgets.length; i++ )
        {
            this._textWidgets[i].setVal( Widget.Y, y );
            this._rects      [i].setVal( Widget.Y, y );
        
            h = this._textWidgets[i].getVal( Widget.H );
        
            this._rects[i].setVal( Widget.H, h );
            
            y += h + 4 * Style._pad;
        }

        this._stringWidth.setVal( Widget.Y, y );
    };
    
    TextBlockTruncatedTestScreen.prototype.resourcesLoaded = function()
    {
        Widget.prototype.resourcesLoaded.call( this );

        this.layout();
    };

    TextBlockTruncatedTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        if( type === Host.KEY_PRESSED )
            switch( val )
            {
                case Host.KEY_LEFT:
                    this._width -= 1;
                    this.updateWidth();
                    break;

                case Host.KEY_RIGHT:
                    this._width += 1;
                    this.updateWidth();
                    break;

                case Host.KEY_UP:
                    this._width -= this._deltaW;
                    this.updateWidth();
                    break;
                    
                case Host.KEY_DOWN:
                    this._width += this._deltaW;
                    this.updateWidth();
                    break;

                default:
                    retval = Screen.prototype.processEvent.call( this, val, type );
                    break;
            }

        return retval;
    };

    TextBlockTruncatedTestScreen.prototype.updateWidth = function()
    {
        if( this._width < this._minWidth )
            this._width = this._minWidth;
        else
        if( this._width > this._maxWidth )
            this._width = this._maxWidth;

        for( var i = 0; i < this._textWidgets.length; i++ )
        {
            this._rects      [i].setW( this._width );
            this._textWidgets[i].setW( this._width );
        }

        this._stringWidth.setText( "" + this._width );
    };

    return TextBlockTruncatedTestScreen;

})();

