// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * Animated circular distractor
 * @class
 */
window.RingDistractorWidget = ( function()
{
    "use strict";

    RingDistractorWidget.prototype = new Widget();

    function RingDistractorWidget(){}

    /**
     * Stop the spinning animation
     */
    RingDistractorWidget.prototype.clear = function()
    {
        this._aniWidget.stopAnimation( Widget.ALPHA, false );
        this._aniWidget.setA( 0 );

        this._blScroll.setW( 0 );
        this._blScroll.setH( 0 );
        this._brScroll.setW( 0 );
        this._brScroll.setH( 0 );
        this._tlScroll.setW( 0 );
        this._tlScroll.setH( 0 );
        this._trScroll.setW( 0 );
        this._trScroll.setH( 0 );

        this._tlFinished = false;
        this._trFinished = false;
        this._brFinished = false;
        this._blFinished = false;
    };

    /**
     * Initializer
     * @memberof RingDistractorWidget
     * @param {Object} params
     * @param {Number} params.period - time in ms how long a spin cycle is
     * @see DistractorWidget
     */
    RingDistractorWidget.prototype.init = function( params )
    {
        var self = this, imageLoaded;

        if( this._className === undefined )
            this._className = (params && params.name) ? params.name : "RingDistractorWidget";

        Widget.prototype.init.call( this );

        this._numLoaded = 0;
        this._period    = params.period;

        imageLoaded = function()
        {
            self._numLoaded++;
            self.layout();
        };

        this._bg   = new ImageWidget().init( { url: _x2._config._imageRoot    + "ring1.png"   , onLoad:imageLoaded } );
        this._logo = new ImageWidget().init( { url: _x2._config._imageRootMso + "ringLogo.png", onLoad:imageLoaded } );
        this._bl   = new ImageWidget().init( { url: _x2._config._imageRoot    + "ring2_BL.png", onLoad:imageLoaded } );
        this._br   = new ImageWidget().init( { url: _x2._config._imageRoot    + "ring2_BR.png", onLoad:imageLoaded } );
        this._tl   = new ImageWidget().init( { url: _x2._config._imageRoot    + "ring2_TL.png", onLoad:imageLoaded } );
        this._tr   = new ImageWidget().init( { url: _x2._config._imageRoot    + "ring2_TR.png", onLoad:imageLoaded } );

        this._blScroll = new ScrollWidget().init( { widget:this._bl } );
        this._brScroll = new ScrollWidget().init( { widget:this._br } );
        this._tlScroll = new ScrollWidget().init( { widget:this._tl } );
        this._trScroll = new ScrollWidget().init( { widget:this._tr } );

        this.addWidget( this._bg       );
        this.addWidget( this._logo     );
        this.addWidget( this._blScroll );
        this.addWidget( this._brScroll );
        this.addWidget( this._tlScroll );
        this.addWidget( this._trScroll );

        this._aniWidget = new Widget().init(); // Animation Controller
        this.addWidget( this._aniWidget );

        return this;
    };

    RingDistractorWidget.prototype.isAnimating = function()
    {
        return (this._aniWidget.getA() > 0) && !this._tlFinished;
    };

    RingDistractorWidget.prototype.layout = function()
    {
        if( this._numLoaded === 6 )
        {
            this._radius = this._tr.getVal( Widget.W );

            this.setW( this._bg.getW() );
            this.setH( this._bg.getH() );

            this._logo.setX( (this._bg.getW() - this._logo.getW()) / 2 );
            this._logo.setY( (this._bg.getH() - this._logo.getH()) / 2 );

            this._blScroll.setY( this._radius );
            this._blScroll.setW( this._radius );
            this._blScroll.setH( this._radius );

            this._brScroll.setX( this._radius );
            this._brScroll.setY( this._radius );
            this._brScroll.setW( this._radius );
            this._brScroll.setH( this._radius );

            this._tlScroll.setW( this._radius );
            this._tlScroll.setH( this._radius );

            this._trScroll.setX( this._radius );
            this._trScroll.setW( this._radius );
            this._trScroll.setH( this._radius );

            this.clear();
            this.callbackSignalReady();
        }
    };

    RingDistractorWidget.prototype.spin = function()
    {
        var self = this, onEnd, onInc;

        onEnd = function()
        {
            // See nota bene when a ~== 0.99
            // If there was a delay between restarting then
            // the '++cos' offset can be removed and these should be enabled
            //self._tlScroll.setW( self._radius );
            //self._tlScroll.setH( self._radius );
            //self._tlScroll.setY( 0 );
            //self._tlScroll.setScroll( { y:0, duration:0 } );
            self._tlFinished = true;

            self.clear();
            self.spin();
        };

        onInc = function()
        {
            var a   = self._aniWidget.getA();
            var rad = a * 2 * Math.PI;
            var sin = (self._radius * Math.sin( rad )) | 0; // Bugfix: Weird DOM pixel gap in TR/BR and TL/TR
            var cos = (self._radius * Math.cos( rad )) | 0; // NOTE: Math.floor() also works but is slower

            if( a <= 0.25 )
            {
                self._trScroll.setW(                sin );
                self._trScroll.setH( self._radius - cos );
            }
            else if( a <= 0.5 )
            {
                if( self._trFinished === false )
                {
                    self._trScroll.setW( self._radius );
                    self._trScroll.setH( self._radius );
                    self._trFinished = true;
                }

                self._brScroll.setScroll( { x:-sin, duration:0 } );
                self._brScroll.setX( self._radius + sin );
                self._brScroll.setW( self._radius - sin );
                self._brScroll.setH(              - cos );
            }
            else if( a <= 0.75 )
            {
                if( self._brFinished === false )
                {
                    self._brScroll.setScroll( { x:0, duration:0 } );
                    self._brScroll.setX( self._radius );
                    self._brScroll.setW( self._radius );
                    self._brScroll.setH( self._radius );
                    self._brFinished = true;
                }

                self._blScroll.setScroll( { x:-self._radius - sin, duration:0 } );
                self._blScroll.setScroll( { y:cos                , duration:0 } );
                self._blScroll.setX( self._radius + sin );
                self._blScroll.setW(              - sin );
                self._blScroll.setY( self._radius - cos );
                self._blScroll.setH( self._radius + cos );
            }
            else // a > 0.75
            {
                if( self._blFinished === false )
                {
                    self._blScroll.setScroll( { x:0, duration:0 } );
                    self._blScroll.setScroll( { y:0, duration:0 } );
                    self._blScroll.setX( 0 );
                    self._blScroll.setW( self._radius );
                    self._blScroll.setY( self._radius );
                    self._blScroll.setH( self._radius );
                    self._blFinished = true;
                }
                // N.B. Technically these +/-1 offsets are incorrect when a ~== 0.99
                // But since the animation is instantly reapplied
                // it looks bad when the end isn't reached before restarting
                // ergo we fudge an effective a=1
                ++cos;

                self._tlScroll.setScroll( { y:-self._radius + cos, duration:0 } );
                self._tlScroll.setW( self._radius + sin );
                self._tlScroll.setY( self._radius - cos );
                self._tlScroll.setH(                cos );
            }
        };

        this._aniWidget.animate( { a:1, duration:this._period, easing:Widget.Easing.QUAD_IN_OUT, onEnd:onEnd, onInc:onInc } );
    };

    return RingDistractorWidget;

})();
