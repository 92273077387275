// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.TransactionDetails = (function()
{
    "use strict";

    TransactionDetails.Type = {
        RENTAL   : 'rented',
        PURCHASE : 'purchased'
    };

    TransactionDetails.prototype = new DataObject();

    function TransactionDetails(){}

    TransactionDetails.prototype.init = function( data )
    {
        DataObject.prototype.init.call( this, data );
        return this;
    };

    TransactionDetails.prototype.getPrice = function()
    {
        return this.get("price");
    };

    TransactionDetails.prototype.getType = function()
    {
        return this.get("type");
    };

    TransactionDetails.prototype.getPurchasedOnDate = function()
    {
        return this.getDate("date");
    };

    return TransactionDetails;
})();
