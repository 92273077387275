// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.EpisodeRowWidget = ( function()
{
    "use strict";

    EpisodeRowWidget.prototype = new ListRowWidget();

    function EpisodeRowWidget(){}

    EpisodeRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        ListRowWidget.prototype.gotFocus.call( this );

        this._title.gotFocus();
        this._sep.setSpeechParams( this._title.getText().replace( /^Ep([\d])/, "Episode $1" ) + ". Press OK to select. ", undefined, this, newScreen );

        return this;
    };

    /**
     * Initializer
     * @memberof EpisodeRowWidget
     * @param   {Object} params
     * @param   {Number} params.w - Width
     * @returns {EpisodeRowWidget}
     * @see ListRowWidget
     */
    EpisodeRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );

        this._className = "EpisodeRowWidget";

        var self = this;
        var titleStyle = new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#2ea0dd" } );

        this._numElements = 0;
        this._selectable  = true;
        this._maxH        = Math.floor( this.getHeightUnfocused() / 2 );
        this._maxW        = Math.floor( this._maxH * 5 / 3 );

        this._title = new StringWidget().init( { text:"?", style:titleStyle } );
        this._title.addReadyCallback( function() { self.layout(); } );
        this.addWidget( this._title );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        return this;
    };

    EpisodeRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 132 );
    };

    EpisodeRowWidget.prototype.layout = function()
    {
        this._title.setY( (this.getHeightUnfocused() - this._title.getH()) / 2 );
    };

    EpisodeRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.gotFocus.call( this );
        this._title.lostFocus();
    };

    EpisodeRowWidget.prototype.processEvent = function( val, type )
    {
        var retval = false;

        if( val === Host.KEY_ENTER && type === Host.KEY_PRESSED )
        {
            _x2.pushScreen( new EpisodeScreen().init( { entity: this._entity } ) );
            retval = true;
        }

        return retval;
    };

    /**
     * @memberof EpisodeRowWidget
     * @param {Entity | Recording} data
     */
    EpisodeRowWidget.prototype.setData = function( data )
    {
        if( data !== undefined )
        {
            this._entity = data;

            var title = "";
            var program = data instanceof Entity ? data : data.getProgram();

            if( program )
            {
                var epNumber = program.getEpisodeNumber();
                if( epNumber !== undefined )
                    title += "Ep" + epNumber + " - ";

                //don't duplicate if the series and episode titles are the same (common with news/talk shows)
                if( title.indexOf(program.getTitle()) === -1 )
                    title += program.getTitle();
            }
            else
                title = data.getTitle();

            this._title.setText(title);
            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    return EpisodeRowWidget;

})();
