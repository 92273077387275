// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.HelpOverlay = ( function()
{
    "use strict";

    HelpOverlay.prototype = new Overlay();

    function HelpOverlay(){}

    HelpOverlay.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();

        var str = "";

        for( var i = 0; i < this._strings.length; i++ )
            str += this._strings[i].getText() + ". ";

        str = str.replace( /xfinity/gi, "x_finity" );

        this._speakTarget.setText( str );
        this._speakTarget.setSpeechParams( "Press back to dismiss.", undefined, this, false );
    };

    HelpOverlay.prototype.init = function( showNotEntitled )
    {
        Overlay.prototype.init.call( this, { hideExitStr:showNotEntitled } );
        this._className = "HelpOverlay";
        this._numLoaded = 0;
        this._strings   = [];

        var i;
        var self   = this;
        var onLoad = function() { self._numLoaded++; self.layoutHelpOverlay() };
        var styles =
        [
            new Style( { font:"medium" , color:"#a3a3a3", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", textAlign:"center" } ),
            new Style( { font:"regular", color:"#e8e8e8", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap", textAlign:"center" } )
        ];
        var texts =
        [
            "To learn more about the Xfinity Stream Beta, please visit",
            "xfinity.com/xfinitystreambeta",
            _x2._config._isSmbUser === true ? "To get help & support, please visit" : "For the latest announcements and to submit feedback, please visit",
            _x2._config._isSmbUser === true ? "https://business.comcast.com/help-and-support/tv/" : "forums.xfinity.com/betafeedback"
        ];

        if( showNotEntitled === true )
            texts.unshift( "To learn more about Xfinity Instant TV, please visit", "xfinity.com/learn/instant-tv" );

        for( i = 0; i < texts.length; i++ )
        {
            this._strings[i] = new StringWidget().init( { text:texts[i], style: styles[i & 1] } );
            this._strings[i].addReadyCallback( onLoad );
            this._bg.addWidget( this._strings[i] );
        }

        this._image = new ImageWidget().init( { url:_x2._config._imageRoot + "helpIcon.png", onLoad:onLoad } );
        this._bg.addWidget( this._image );

        if( showNotEntitled !== true )
        {
            window.ll( "tagScreen", "Help" );
            _x2._telemetry.sendScreenViewed( "Help" );
        }

        return this;
    };

    HelpOverlay.prototype.layoutHelpOverlay = function()
    {
        if( this._numLoaded === this._strings.length + 1 )
        {
            var i, y;
            var maxStringW = 0;
            var n          = this._strings.length;
            var h          = 0;

            for( i = 0; i < n; i++ )
                if( this._strings[i].getW() > maxStringW )
                    maxStringW = this._strings[i].getW();

            var bgW = this._bg.getW();
            var bgH = this._bg.getH();
            var imW = this._image.getW();
            var imX = (bgW - imW - maxStringW) / 3;
            var imY = (bgH   - this._image.getH()) / 2;
            var w   = bgW - imX - imW;
            var x   = imX + imW;
            var pad = _x2.scaleValInt(52);

            this._image.setX( imX );
            this._image.setY( imY );

            for( i = 0; i < n; i++ )
            {
                this._strings[i].setW( w );
                this._strings[i].setX( x );

                h += this._strings[i].getH();
            }

            h += pad;
            y = (this._bg.getH() - h) * 0.5;

            for( i = 0; i < n; i++ )
            {
                this._strings[i].setY( y );
                y += this._strings[i].getH();

                if( i % 2 === 1 )
                    y += pad;
            }
        }
    };

    return HelpOverlay;

})();
