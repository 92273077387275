// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.DeleteConfirmOverlay = ( function()
{
    "use strict";

    DeleteConfirmOverlay.prototype = new Overlay();

    function DeleteConfirmOverlay(){}

    DeleteConfirmOverlay.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._buttons, true );
        this._speakTarget.setText( this._title.getText() + ". " + this._body.getText() + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press right or left to review options. Press back to dismiss.", focus._speakRole, this, false );
    };

    /**
     * Init
     * @param params
     * @param params.title   {string}
     * @param params.message {string}
     * @param params.confirm {function}
     * @param params.cancel  {function}
     * @return {DeleteConfirmOverlay}
     */
    DeleteConfirmOverlay.prototype.init = function(params)
    {
        Overlay.prototype.init.call( this );
        this._className  = "DeleteConfirmOverlay";

        var self        = this, confirm, cancel;
        var titleStyle  = new Style( { font:"light"  , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap", textAlign:"center" } );
        var bodyStyle   = new Style( { font:"light"  , color:"#a3a3a3", fontSize:_x2.scaleValInt(30), textAlign:"center"  } );
        var buttonStyle = new Style( { font:"regular", color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#000000"  } );
        var w           = this._bg.getW();
        var inset       = _x2.scaleValInt( 80 );
        var buttons     = [];

        confirm = function()
        {
            self._buttons.setDisabled( true );

            if( params.confirm )
                params.confirm( self );
        };

        cancel = function()
        {
            _x2.popOverlay( self );
        };

        this._title = new StringWidget().init( { text:params.title, style:titleStyle } );
        this._title.setW( this._bg.getW() );
        this._bg.addWidget( this._title );

        this._body = new TextBlockWidget().init( { text:params.message, style:bodyStyle, w:w - 2 * inset } );
        this._bg.addWidget( this._body, inset );

        buttons.push( new StringWidget().init( { text:"Yes", style:buttonStyle, onEnter:confirm } ) );
        buttons.push( new StringWidget().init( { text:"No", style:buttonStyle, onEnter:cancel } ) );

        this._buttons = new HorizPillButtonsWidget().init( {entries:buttons, spacing:_x2.scaleValInt(41)});
        this._buttons.addReadyCallback(function() { self.layout(); } );
        this._bg.addWidget( this._buttons );

        return this;
    };

    DeleteConfirmOverlay.prototype.layout = function()
    {
        var totalH      = this._title.getH() + this._body.getH() + this._buttons.getH();
        var vGapBody    = _x2.scaleValInt( 40 );
        var vGapButtons = _x2.scaleValInt( 50 );
        var y           = (this._bg.getH() - totalH - vGapBody - vGapButtons) / 2;

        this._title.setY( y );
        y += this._title.getH() + vGapBody;
        this._body.setY( y );
        y += this._body.getH() + vGapButtons;
        this._buttons.setY( y );

        this._buttons.setX( (this._bg.getW() - this._buttons.getW()) / 2 );
    };

    return DeleteConfirmOverlay;

})();
