// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 * @type {Link}
 *
 * Use this class for all data fetching operations from links. Cleans
 */
window.Link = (function()
{
    "use strict";

    function Link(){}

    Link.prototype.init = function( data )
    {
        this._data = data;
        return this;
    };

    /**
     * Follow the link and fetch the data
     */
    Link.prototype.resolve = function( parameters, api, force )
    {
        var self = this;

        var resolver = function(resolve, reject)
        {
            var success = function( data )  { resolve( data  ) };
            var fail    = function( error ) { reject ( error ) };

            if( self._data && self._data.href )
            {
                var url = self._data.href;
                url = XtvApi.replaceUrlParams( url, parameters );
                url = XtvApi.trimPath( url );
                url = _x2._data._host + url.replace( /#/g, '' );

                _x2._network.ajax( { type: "GET", url: url, api:api, headers: [_x2._data._authHeader], accepts:Network.Accepts.xtv, bustCache:force } ).then( success ).catch( fail );
            }
            else
            {
                console.log( "WARNING: unresolved link");
                resolve();
            }
        };

        return new Promise(resolver);
    };

    return Link;
})();
