// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.LinearPromoCellWidget = ( function()
{
    "use strict";

    LinearPromoCellWidget.prototype = new CollectionCellWidget();

    function LinearPromoCellWidget(){}

    /**
     * Initializer
     * @memberof LinearPromoCellWidget
     * @param {Object} params - @see CollectionCellWidget
     * @returns {LinearPromoCellWidget}
     */
    LinearPromoCellWidget.prototype.init = function( params )
    {
        var self = this;
        var titleStyle    = new Style( { color:"#e8e8e8", font:"medium" , fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap" } );
        var subTitleStyle = new Style( { color:"#a3a3a3", font:"regular", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap" } );

        this._imageX = params.bgX     = _x2.scaleValInt( 19 );
        this._imageW = params.bgW     = _x2.scaleValInt( 558 );
        this._imageH = params.bgH     = _x2.scaleValInt( 372 );
        this._scale  = params.bgScale = (this._imageW + 2 * this._imageX) / this._imageW;
        this._imageY = params.bgY     = (this._scale - 1) * this._imageH / 2;

        this._logoW = _x2.scaleValInt(75);
        this._logoH = _x2.scaleValInt(53);

        this._focusW = this._scale * this._imageW;
        this._focusH = this._scale * this._imageH;

        params.background = true;
        params.bgTextSize = _x2.scaleValInt(36);

        CollectionCellWidget.prototype.init.call( this, params );
        this._className  = "LinearPromoCellWidget";

        var imageChanged = function()
        {
            self._image.setA(1);
            self.hideBackground();
        };

        var imageError = function()
        {
            var fallback = self._data.getFallbackImageLink( 320, 240 );
            if( self._image.getUrl() === fallback )
            {
                self._image.setA( 0 );
                self.showBackground();
            }
            else
                self._image.setUrl( fallback );
        };

        var logoLoaded = function()
        {
            self._networkLogo.setX( self._imageW - ( self._networkLogo.getW() * 1.5 ) );
            self._networkLogo.setY( self._imageH - ( self._networkLogo.getH() * 1.5 ) );
        };

        this._image = new ImageWidget().init( { w:this._imageW, h:this._imageH, onError:imageError, onChange:imageChanged } );
        this.addWidget( this._image, this._imageX, this._imageY );

        var alphas    = [ { opacity:40     , location:0 }, { opacity:0      , location:100 } ];
        var colors    = [ { color:"#000000", location:0 }, { color:"#000000", location:100 } ];

        this._gradient = new GradientWidget().init( { alphas:alphas, angle:90, colors:colors, w:this._imageW, h:this._imageH, type:GradientWidget.Type.Linear } );
        this.addWidget( this._gradient, this._imageX, this._imageY );

        this._title = new StringWidget().init( { style:titleStyle } );
        this._title.setA( 0 );

        // this._subTitle = new TextBlockWidget().init( { w:500, h:30, style:subTitleStyle } );
        this._subTitle = new StringWidget().init( { style:subTitleStyle } );
        this._subTitle.setA( 0 );

        this.addWidget( this._title   , 0, this._image.getH() + 2 * this._imageY + _x2.scaleValInt( 20 ) );
        this.addWidget( this._subTitle, 0, this._image.getH() + 2 * this._imageY + _x2.scaleValInt( 60 ) );

        this._speakTarget = new StringWidget().init( { style:titleStyle } );
        this._speakTarget.setA( 0 );
        this.addWidget( this._speakTarget );

        this._networkLogo = new ImageWidget().init( { w:this._logoW, h:this._logoH, onLoad:logoLoaded } );
        this._networkLogo.setA( 0 );
        this._gradient.addWidget( this._networkLogo );

        return this;
    };

    LinearPromoCellWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 558 ) + _x2.scaleValInt( 19 );
    };

    LinearPromoCellWidget.prototype.getTouchDim = function()
    {
        return _x2.scaleValInt( 372 ) * (_x2.scaleValInt( 558 ) + 2 * _x2.scaleValInt( 19 )) / _x2.scaleValInt( 558 );
    };

    LinearPromoCellWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        this._speakTarget.setSpeechParams( undefined, undefined, this, newScreen );

        CollectionCellWidget.prototype.gotFocus.call( this );

        // if( this._image.getA() ) //The image is not always loaded by the time we get focus. What is the harm in setting the size while alpha==0?
        this._image.animate( { xScale:this._scale, yScale:this._scale, duration:ListWidget.SCROLL_TIME_STEP } );
        this._gradient.animate( { xScale:this._scale, yScale:this._scale, duration:ListWidget.SCROLL_TIME_STEP } );

        this._title.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );
        this._subTitle.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );

        return this;
    };

    LinearPromoCellWidget.prototype.lostFocus = function()
    {
        CollectionCellWidget.prototype.lostFocus.call( this );

        if( this._image.getA() )
        {
            this._image.stopAnimation( Widget.X_SCALE );
            this._image.stopAnimation( Widget.Y_SCALE );
            this._image.animate( { xScale:1, yScale:1, duration:ListWidget.SCROLL_TIME_STEP } );

            this._gradient.stopAnimation( Widget.X_SCALE );
            this._gradient.stopAnimation( Widget.Y_SCALE );
            this._gradient.animate( { xScale:1, yScale:1, duration:ListWidget.SCROLL_TIME_STEP } );
        }

        this._title.animate( { alpha:0, duration:ListWidget.SCROLL_TIME_STEP } );
        this._subTitle.animate( { alpha:0, duration:ListWidget.SCROLL_TIME_STEP } );
    };

    /**
     * Override default (CollectionCellWidget) ENTER key handling (pushing info screen) and tune the
     * linear program when a user selects ENTER
     * @param {number} val
     * @param {number} type
     */
    LinearPromoCellWidget.prototype.processEvent = function( val, type )
    {
        var self = this;

        if( type === Host.KEY_PRESSED && val === Host.KEY_ENTER )
        {
            var onEntityLoaded = function( showing )
            {
                if( showing !== undefined )
                {
                    if( showing.getChannel() )
                    {
                        var channel = _x2._channelCollection.getChannel( showing.getChannel().getSelfLink() );
                        var program = showing.getEntity();

                        var playVideo = function()
                        {
                            _x2.pushScreen( new VideoScreen().init( { url:channel.getStreamUrl(), mode:VideoScreen.Mode.LIVE, entity:showing, streamId:channel.getStreamId() } ) );
                        };

                        if( channel )
                        {
                            if( _x2._pc.isEnabled() === true )
                                _x2.checkParentalControls( showing, program ).then( playVideo );
                            else
                                playVideo();
                        }
                    }
                    self.getHostScreen().hideDistractor();
                }
            };

            var onError = function( error )
            {
                console.log( error.toString() );
                _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.BROWSE_SEARCH } ) );
                self.getHostScreen().hideDistractor();
            };

            this._data.getLinearTvListing().then( onEntityLoaded ).catch( onError );

            this.getHostScreen().showDistractor();
        }
        else
            return CollectionCellWidget.prototype.processEvent.call( this );
    };

    LinearPromoCellWidget.prototype.setData = function( data )
    {
        if( data !== undefined )
        {
            var now, start, end, title, season, episode, time, channel, logoUrl, titleText="", subTitleText="", speakStr = "";

            this._data = data;
            this._image.setA( 0 );
            this._image.setUrl( data.getPromoImageLink( 320, 240 ) );

            now     = new Date();
            start   = new Date( parseInt(data.getStartTime()) );
            end     = new Date( parseInt(data.getEndTime()) );
            title   = data.getTitle();
            season  = data.getSeasonNumber();
            episode = data.getEpisodeNumber();
            logoUrl = data.getNetworkImageLink();

            if( logoUrl )
            {
                this._networkLogo.setUrl( logoUrl );
                this._networkLogo.setA( 1 );
            }
            else
                this._networkLogo.setA( 0 );

            if( season && episode )
            {
                titleText = "S" + season + " Ep" + episode + " - " + title;
                speakStr  = "Season " + season + ", Episode" + episode + ", " + title + ". ";
            }
            else
            {
                titleText = title;
                speakStr  = title + ". ";
            }

            if( start.getTime() < now.getTime() && end.getTime() > now.getTime() )
            {
                channel = data.getCallSign();
                time = _x2.createTimeRange( start, end );
                subTitleText = "On now " + time + " on " + channel;
                speakStr += "On now " + _x2.createTimeSpeechStr( time ) + " on " + channel + ". ";
            }

            this.setBackgroundText( title );
            this._title.setText( titleText );
            this._subTitle.setText( subTitleText );

            this.setA( 1 );

            this._speakTarget.setText( speakStr );
        }
        else
            this.setA( 0.0 );
    };

    return LinearPromoCellWidget;

})();
