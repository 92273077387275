// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Represents  a collection of {@link BrowseEntity} and/or other (@link BrowseCollection} objects.
 * Synonymous with VodFolder, but can contain objects other than VOD entities. Also Contains style
 * information for the collection.
 *
 * Maps to a XTV "BrowseCollection" (node._type = "BrowseCollection").
 *
 */

window.BrowseCollection = ( function()
{
    "use strict";

    BrowseCollection.prototype = new DataObject();

    function BrowseCollection(){}

    /**
     * Initialize the object
     * @memberOf BrowseCollection
     * @param {Object} data - XTV derived JSON object
     * @param {BrowseCollection} [collection] - collection which contains this collection (parent)
     * @return {BrowseCollection}
     */
    BrowseCollection.prototype.init = function( data, collection )
    {
        DataObject.prototype.init.call( this, data );

        this._embedded = undefined;

        if( collection ) //NOTE: don't overwrite parent we re-init this folder.
            this._parent = collection;

        return this;
    };

    /**
     * Get the rendering style for embedded content
     * @memberOf BrowseCollection
     * @return {String}
     * @todo we should probably return a constant
     */
    BrowseCollection.prototype.getChildRenderStyle = function()
    {
        var retval;

        if( this.getCollectionRenderStyle() === XtvApi.CollectionRenderStyle.NETWORK_ENTITY )
            retval = XtvApi.EntityRenderStyle.NETWORK; //NOTE: NETWORK_ENTITY collections are missing childTileRenderStyle, presumably b/c it's implied.
        else
            retval = this.get("childTileRenderStyle");

        return retval;
    };

    /**
     * Get the rendering style for this collection
     * @memberOf BrowseCollection
     * @return {String}
     * @todo we should probably return a constant
     */
    BrowseCollection.prototype.getCollectionRenderStyle = function()
    {
        return this.get("collectionRenderStyle");
    };

    /**
     * Shortcut to getEmbeddedBrowseItems to support (implicit) Collection interface
     * @memberOf BrowseCollection
     * @return {Array} Collection of {@link BrowseEntity} and/or {@link BrowseCollection} objects
     */
    BrowseCollection.prototype.getEntities = function()
    {
        return this.getEmbeddedBrowseItems();
    };

    /**
     * Get the fallback URL for the poster image
     * @memberOf BrowseCollection
     * @param {Number} width  - Image width
     * @param {Number} height - Image height
     * @return {String}
     */
    BrowseCollection.prototype.getFallbackImageLink = function( width, height )
    {
        var link = this.getProgramFallbackImageLink();
        return XtvApi.replaceUrlParams( link.href, { entityId: this.getId(), width: width, height: height } );
    };

    /**
     * Get the collection ID.
     * @memberOf BrowseCollection
     * @return {String}
     */
    BrowseCollection.prototype.getId = function()
    {
        return this.get("slug");
    };

    /**
     * Get the URL for the poster image
     * @memberOf BrowseCollection
     * @param {Number} width  - Image width
     * @param {Number} height - Image height
     * @return {String}
     */
    BrowseCollection.prototype.getImageLink = function( width, height )
    {
        var link;

        if( this.getCollectionRenderStyle() === XtvApi.CollectionRenderStyle.NETWORK_ENTITY )
            link = this.getNetworkImageLink( width, height );
        else
        {
            link = this.getProgramImageLink();
            if( link )
                link = XtvApi.replaceUrlParams( link, { entityId: this.getId(), width: width, height: height } );
        }

        return link;
    };

    /**
     * Get all embedded (child) objects for this collection.
     * @memberOf BrowseCollection
     * @return {Array} Collection of {@link BrowseEntity} and/or {@link BrowseCollection} objects
     */
    BrowseCollection.prototype.getEmbeddedBrowseItems = function()
    {
        if( !this._embedded )
        {
            var i, items, entity, itemCount;

            this._embedded = [];

            items = this.getEmbedded("browseItems");

            if( items && items.length )
            {
                itemCount = this.get("totalBrowseItems") ? this.get("totalBrowseItems") : items.length;

                //Add a "View All" entity the collection render style is BROWSE and there are more than 12 items.
                if( this.getCollectionRenderStyle() === XtvApi.CollectionRenderStyle.BROWSE && itemCount > 12 )
                {
                    if( this.getChildRenderStyle() !== "4X3_PROGRAM_LINEAR" && this.getChildRenderStyle() !== "3X4_PROGRAM_LINEAR" )
                    {
                        var all = new BrowseCollection().init( this._data );
                        all.setMeta( { all: true } );
                        this._embedded.push( all );
                    }
                }

                for( i = 0; i < items.length; i++ )
                {
                    entity = this.initObject( items[i] );
                    if( entity )
                        this._embedded.push( entity );
                }
            }
        }

        return this._embedded;
    };

    /**
     * Get the fallback image URL used to get program images for this and contained entities.
     * @memberOf BrowseCollection
     * @return {String} URL
     */
    BrowseCollection.prototype.getProgramFallbackImageLink = function()
    {
        var link = this.getLink("programFallbackImageLink");

        if( !link )
            link = this._parent.getProgramFallbackImageLink();

        return link;
    };

    /**
     * Get the network logo URL
     * @memberOf BrowseCollection
     * @param {Number} width
     * @param {Number} height
     * @return {String} URL
     */
    BrowseCollection.prototype.getNetworkImageLink = function( width, height )
    {
        var link, provider = this.getEmbedded("contentProvider");

        if( provider )
        {
            link = this.getPath(["_links", "logo", "href"], provider);
            if( link )
                link = XtvApi.replaceUrlParams( link, { width: width, height: height } );
        }
        else
            console.log( "Warning: No network image." );

        return link;
    };

    /**
     * Get the primary image URL used to get program images for this and contained entities.
     * @memberOf BrowseCollection
     * @return {String} URL
     */
    BrowseCollection.prototype.getProgramImageLink = function()
    {
        var link = this.getLink("programImageLink");

        if( !link && this._parent )
            link = this._parent.getProgramImageLink();

        return link;
    };

    /**
     * Get the (4:3) promo image link. For a collection it will be the same as the image link,
     * but this method is required for interface consistency
     * @param {Number} width
     * @param {Number} height
     * @return {String|undefined}
     */
    BrowseCollection.prototype.getPromoImageLink = function( width, height )
    {
        var link = this.getLink("image" );
        if( link )
            return XtvApi.replaceUrlParams( link, { width: width, height: height } );

        return undefined;
    };

    /**
     * Get the number of browse items (children) in this collection
     * @memberOf BrowseCollection
     * @return {Number}
     */
    BrowseCollection.prototype.getTotalEntityCount = function()
    {
        return this.get("totalBrowseItems");
    };

    /**
     * Get the title of this collection
     * @memberOf BrowseCollection
     * @return {String}
     */
    BrowseCollection.prototype.getTitle = function()
    {
        return this.get("title");
    };

    /**
     * Get the value of a property in meta
     * @memberOf BrowseCollection
     * @param {String} property
     * @return {Object|*}
     */
    BrowseCollection.prototype.getMetaValue = function( property )
    {
        var value;

        if( this._meta )
            value = this._meta[property];

        return value;
    };

    /**
     * Load the next page of entities
     * @return {Promise}
     */
    BrowseCollection.prototype.loadNextPage = function()
    {
        var self = this;

        var resolver = function( resolve, reject )
        {
            var success = function( obj )
            {
                var collection, entity, embedded, entities = [];

                collection = JSON.parse( obj.data );
                embedded   = self.getPath(["_embedded", "browseItems"], collection);

                //re-init this object with the the query result (we need the new 'next' link)
                self.init( collection );

                if( embedded && embedded.length )
                {
                    for( var i = 0; i < embedded.length; i++ )
                    {
                        entity = self.initObject( embedded[i] );
                        if( entity )
                            entities.push( entity )
                    }
                }

                if( entities.length )
                    self._embedded = (self._embedded ) ? self._embedded.concat( entities ) : entities;

                resolve( entities );
            };

            var error = function( error )
            {
                reject( error );
            };

            var link = self.getLinkObject("next");

            if( link )
                link.resolve().then( success ).catch( error );
        };

        return new Promise( resolver );
    };

    /**
     * @memberOf BrowseCollection
     * @return {boolean}
     */
    BrowseCollection.prototype.isFavoriteCollection = function()
    {
        var retval = false;
        var slug   = this.get("slug");

        if( slug )
            if( slug.indexOf('favorite') === 0 )
                retval = true;

        return retval;
    };

    /**
     * Initialize and return an the appropriate object according to type of data
     * @param {Object} data
     * @return {BrowseEntity|BrowseCollection}
     */
    BrowseCollection.prototype.initObject = function( data )
    {
        var retval, obj, tStyle, cStyle;

        if( data._type === "BrowseEntity" )
        {
            obj = new BrowseEntity().init( data, this );

            //make sure this entity has a recognized tile style, otherwise exclude it.
            tStyle = obj.getTileRenderStyle();

            if( tStyle && XtvApi.EntityRenderStyle[tStyle] )
                retval = obj;
        }
        else if( data._type === "BrowseCollection" )
        {
            obj = new BrowseCollection().init( data, this );

            //make sure this collection has a recognized render and tile style, otherwise exclude it. default to true if data does not define a style
            tStyle = obj.getChildRenderStyle();
            cStyle = obj.getCollectionRenderStyle();

            if( tStyle && XtvApi.EntityRenderStyle[tStyle] && cStyle && XtvApi.CollectionRenderStyle[cStyle] )
                retval = obj;
        }

        return retval;
    };

    /**
     * Reload the data in this collection using the source object's self link.
     * @returns {Promise}
     */
    BrowseCollection.prototype.refresh = function()
    {
        var promise, url, self = this;

        if( this._data._links && this.getLink("self") )
        {
            url = XtvApi.trimPath( this.getLink("self") );

            var success = function( result )
            {
                self._embedded = undefined;
                self._data = JSON.parse( result.data );
            };

            promise = _x2._network.ajax({type: "GET", url: _x2._data._host + url, headers: [_x2._data._authHeader], accepts:Network.Accepts.xtv}).then( success );
        }
        else
            promise = Promise.reject( "Collection does not contain a self link" );

        return promise;
    };

    return BrowseCollection;

})();
