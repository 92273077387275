// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ExpandingListRowWidget = ( function()
{
    "use strict";

    ExpandingListRowWidget.prototype = new ListRowWidget();

    function ExpandingListRowWidget(){}

    ExpandingListRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        ListRowWidget.prototype.gotFocus.call( this );
        this._title.gotFocus();

        var count = ( this._data.entityCount !== undefined ? this._data.entityCount : this._data.entries.length );
        var title = this._title.getText().replace( /\(([\d]+)\)/, count === 1 ? ", $1 item. "  : ", $1 items. " );

        this._sep.setSpeechParams( title + (newScreen !== true ? "Press OK to select." : ""), undefined, this, newScreen );

        return this;
    };

    ExpandingListRowWidget.prototype.contract = function( hListener, aListener )
    {
        var onEnd, self = this;

        onEnd = function()
        {
            var onStep;

            onStep = function()
            {
                aListener( self._aniWidget.getA() );
            };

            if( aListener )
                self._aniWidget.animate( { a:1, duration:500, onInc:onStep, onEnd:onStep } );
        };

        this._listObj.list.animate( { a:0, duration:500, onEnd:onEnd } );
    };

    /**
     * @memberof ExpandingListRowWidget
     * @param hListener TODO:
     * @param aListener TODO:
     */
    ExpandingListRowWidget.prototype.expand = function( hListener, aListener )
    {
        var onInc, onEnd, self = this;

        onEnd = function()
        {
            self._listObj.list.setY( self.getH() );
            self._listObj.list.animate( { a:1, duration:500 } );

            if( self._data.entries )
                self._listObj.list.setData( self._data.entries );

            if( self._listObj.onExpand )
                self._listObj.onExpand( self._data );

            _x2.requestFocus( self._listObj.list );
        };

        onInc = function()
        {
            if( aListener )
                aListener( self._aniWidget.getA() );
        };

        this._aniWidget.setA(1); //alpha can be 0 here resulting in onInc/onEnd never called. Example: return after deleting episode from season list
        this._aniWidget.animate( { a:0, duration:500, onInc:onInc, onEnd:onEnd } );
    };

    ExpandingListRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 100 );
    };

    /**
     * Initializer
     * @memberof ExpandingListRowWidget
     * @param   {Object} params
     * @param   {Widget} [params.obj] - TODO
     * @param   {Number} [params.w]
     * @returns {ExpandingListRowWidget}
     */
    ExpandingListRowWidget.prototype.init = function( params )
    {
        params.chevron = true;
        ListRowWidget.prototype.init.call( this, params );

        this._className = "ExpandingListRowWidget";

        if( params.obj )
            this._listObj = params.obj;

        var self = this;
        var titleStyle = new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap", colorHi:"#2ea0dd" } );

        this._numElements = 0;
        this._selectable  = true;
        this._maxH        = parseInt( this.getHeightUnfocused() / 2 );
        this._maxW        = parseInt( this._maxH * 5 / 3 );

        this._focusTime = ( params.focusTime !== undefined ) ? params.focusTime : 0;

        this._title = new StringWidget().init( { text:"?", style:titleStyle } );
        this._title.addReadyCallback( function() { self.layout(); } );
        this.addWidget( this._title );

        this._aniWidget = new Widget().init();
        this.addWidget( this._aniWidget );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        return this;
    };

    ExpandingListRowWidget.prototype.layout = function()
    {
        this._title.setY( (this.getHeightUnfocused() - this._title.getH()) / 2 );
    };

    ExpandingListRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._title.lostFocus();
    };

    ExpandingListRowWidget.prototype.refreshCell = function()
    {
        if( this._data )
            this._listObj.list.setData( this._data.entries );
    };

    ExpandingListRowWidget.prototype.setData = function( data )
    {
        if( data !== undefined )
        {
            this._data = data;
            this._title.setText( data.title );
            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    return ExpandingListRowWidget;

})();
