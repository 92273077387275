// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ChannelRowWidget = (function(){

    "use strict";

    ChannelRowWidget.prototype = new ListRowWidget();

    function ChannelRowWidget(){}

    ChannelRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 162 );
    };

    ChannelRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        var remain, str, channel, callSign;

        ListRowWidget.prototype.gotFocus.call( this );
        this._title.gotFocus();
        this._channel.gotFocus();

        channel = this._entity.getChannel();

        if( channel )
            callSign = channel.getCallSign();

        remain  = Math.floor( (this._entity.getEndTime() - new Date().getTime()) / (60 * 1000) );
        str     = (isNaN(remain) === false) ? remain + " minutes remaining. " : "";
        str    += "Channel " + this._channel.getText() + ". ";

        if( callSign )
            str += callSign + ". ";

        this._title.setSpeechParams( str, undefined, this, newScreen );

        return this;
    };

    ChannelRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );
        this._className = "ChannelRowWidget";
        this._imageW    = _x2.scaleValInt(135);
        this._imageH    = _x2.scaleValInt(101);

        var self          = this, layout;
        var titleStyle    = new Style( { color:"#e8e8e8", font:"regular", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#2b9cd8" } );
        var subTitleStyle = new Style( { color:"#a3a3a3", font:"medium" , fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );
        var imageY        = (this.getHeightUnfocused() - this._imageH) / 2;

        layout = function()
        {
            var rowH = self.getHeightUnfocused();
            var vPad = _x2.scaleValInt( 8 );
            var h    = self._title.getH() + vPad + self._subTitle.getH();

            self._channel.setY( (rowH - self._channel.getH()) / 2 );
            self._title.setY( (rowH - h) / 2 );
            self._subTitle.setY( self._title.getY() + self._title.getH() + vPad );
        };

        this._image = new ImageWidget().init();
        this.addWidget( this._image, 0, imageY );

        this._channel = new StringWidget().init({ text:"?", style:titleStyle });
        this.addWidget( this._channel, this._imageW + _x2.scaleValInt(40), _x2.scaleValInt(45) );

        this._title = new StringWidget().init({ text:"?", style:titleStyle });
        this.addWidget( this._title, this._imageW + _x2.scaleValInt(176), _x2.scaleValInt(30) );

        this._subTitle = new StringWidget().init({ text:"?", style:subTitleStyle });
        this._subTitle.addReadyCallback( layout );
        this.addWidget( this._subTitle, this._title.getX(), _x2.scaleValInt(75) );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        this._distractor = new DistractorWidget().init( {} );
        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget( self ); } );
        this.addWidget( this._distractor );

        return this;
    };

    ChannelRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._title.lostFocus();
        this._channel.lostFocus();
    };

    ChannelRowWidget.prototype.processEvent = function( val, type )
    {
        var retval = false;

        if( val === Host.KEY_ENTER && type === Host.KEY_PRESSED )
        {
            var self      = this;
            var url       = this._entity.getStreamUrl();
            var channel   = this._entity.getChannel();
            var streamId  = channel.getStreamId();
            var channelId = channel.getId();

            var onError = function( error )
            {
                self._distractor.hide();
                self._inProgress = false;
                _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.LINEAR } ) );
            };

            var onReady = function( schedule )
            {
                self._distractor.hide();
                self._inProgress = false;

                var playVideo = function()
                {
                    _x2.pushScreen( new VideoScreen().init( { url:url, mode:VideoScreen.Mode.LIVE, entity:schedule, streamId:streamId } ) );
                };

                if( schedule )
                {
                    //the ChannelCollection has channels with HD complements assigned. look for a complement so that VideoScreen can tune correctly based on auto-tune HD settings
                    var channelObj = _x2._channelCollection.getChannel( channel.getLink('self') );

                    schedule.setChannel( channelObj ? channelObj : channel );

                    var program = schedule.getProgram();

                    if( _x2._pc.isEnabled() === true )
                        _x2.checkParentalControls( schedule, program ).then( playVideo );
                    else
                        playVideo();
                }
            };

            if( ! this._inProgress )
            {
                this._distractor.show();
                this._inProgress = true;
                _x2._data.getLinearScheduleByTime( channelId, Date.now() ).then( onReady ).catch( onError );
            }

            retval = true;
        }

        return retval;
    };

    ChannelRowWidget.prototype.setData = function(data)
    {
        if( data )
        {
            this._entity = data;

            var channel = data.getChannel();
            var onNow   = data.getSubtitle();
            var start   = data.getStartTime();
            var end     = data.getEndTime();
            var range   = _x2.createTimeRange(start, end);

            if( onNow )
                onNow = onNow.replace('On Now:', '');
            else
                onNow = '';

            this._image.setUrl( data.getImageLink( this._imageW, this._imageH ) );
            this._channel.setText( channel.getNumber() );
            this._title.setText( onNow );
            this._subTitle.setText( range );

            this.setA(1);
        }
        else
            this.setA(0);
    };

    return ChannelRowWidget;

})();
