// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 */
window.Showing = (function()
{
    "use strict";

    Showing.prototype = new DataObject();

    /**
     * @enum AudioType
     * @property {Number} DOLBY_5_1 Dolby 5.1
     * @property {Number} UNKNOWN
     */
    Showing.AudioType = Object.freeze
    ({
        DOLBY_5_1 : 0,
        UNKNOWN   : 9
    });

    function Showing(){}

    /**
     * Initialize this object
     * @memberOf Showing
     * @param {Object} data - XTV derived JSON object
     * @return {Showing}
     */
    Showing.prototype.init = function( data )
    {
        DataObject.prototype.init.call( this, data );
        return this;
    };

    /**
     *
     * @return {Promise<any>}
     */
    Showing.prototype.fetchProgram = function()
    {
        var self = this;

        var resolver = function( resolve, reject )
        {
            var success = function( obj )
            {
                //TODO: replace getProgram with getEntity and fix this.
                self._entity = new Entity().init( JSON.parse(obj.data));
                resolve();
            };

            self.getLinkObject("encodesCreativeWork").resolve({}).then(success).catch(reject);
        };

        return new Promise( resolver );
    };

    /**
     * Get the content provider
     * @return {ContentProvider}
     */
    Showing.prototype.getContentProvider = function()
    {
        if( ! this._contentProvider )
        {
            var data = this.getEmbedded("contentProvider");

            if( ! data )
                data = this.getEmbedded("provider/channel"); //TODO: (MATT) exercise both paths. Are they really the same data

            this._contentProvider = new ContentProvider().init( data );
        }
        return this._contentProvider;
    };

    /**
     * Get the showing duration in minutes
     * @return {Number} duration in minutes;
     */
    Showing.prototype.getDuration = function()
    {
        var duration = 0, durationMs = this.getDurationMs();

        if( durationMs )
            duration = Math.round(durationMs/60000);

        return duration;
    };

    /**
     * Get embedded entity
     * @return {Entity}
     */
    Showing.prototype.getEntity = function()
    {
        if( ! this._entity )
        {
            var data = this.getEmbedded("encodesCreativeWork");
            if( data )
                this._entity = new Entity().init( data );
        }

        return this._entity;
    };

    /**
     * Return true if this showing has been watched
     * @memberOf Recording
     * @return {Boolean}
     */
    Showing.prototype.isWatched = function()
    {
        return this.get("watched");
    };


    /**
     * Get the showing entity id
     * @memberOf Showing
     * @return {String}
     */
    Showing.prototype.getEntityId = function()
    {
        return this.get("entityId");
    };

    /**
     * Get the episode sort number
     * @return {Number}
     */
    Showing.prototype.getEpisodeSortNumber = function()
    {
        return ( this._entity ? this._entity.getEpisodeSortNumber() : -1 );
    };

    Showing.prototype.getEntityImageLink = function(width, height, fallback)
    {
        var link;
        var entity = this.getEntity();

        var w = width  ? width  : 240;
        var h = height ? height : 320;

        if( entity )
            link = ( fallback ? entity.getFallbackImageLink(w, h) : entity.getImageLink(w,h) );

        if( link === undefined )
            link = "https://edge.myriad-gn-xcr.xcr.comcast.net/select/image?priority=true&entityId=" + this.getProgramId() + "&width=" + w + "&height=" + h + (fallback ? "&default=force" : "");

        return link;
    };

    Showing.prototype.getFallbackImageLink = function(width, height)
    {
        return this.getEntityImageLink( width, height, true );
    };

    Showing.prototype.getImageLink = function(width, height)
    {
        return this.getEntityImageLink( width, height, false );
    };

    /**
     *
     * @return {XtvApi.EntityType}
     */
    Showing.prototype.getType = function()
    {
        var type, program = this.getEntity();

        if( program )
            type = program.getType();

        if( ! type )
            type = this.get("type");

        return type;
    };

    /**
     * TODO: replace all calls to getProgram() with getEntity() and remove this
     * @return {Entity}
     */
    Showing.prototype.getProgram = function()
    {
        return this.getEntity();
    };

    Showing.prototype.getProgramType = function()
    {
        var type, program = this.getEntity();

        if( program )
            type = program.getType();
        else
            type = this.get("type");

        return type;
    };

    /**
     * Get the showing media id
     * @memberOf Showing
     * @return {String}
     */
    Showing.prototype.getMediaId = function()
    {
        return this.get("mediaId");
    };

    /**
     *
     * @return {number}
     */
    Showing.prototype.getMinsRemaining = function()
    {
        var remaining = 0, resumePoint = this.getResumePoint();

        if( resumePoint )
        {
            var pos = resumePoint.getPosition();
            var duration = this.getDurationMs();

            if( !isNaN( duration ) && pos > 0 )
                remaining = Math.round( ( parseInt( duration ) - parseInt( pos ) ) / ( 60000 ) );
        }

        return remaining;
    };

    /**
     * Get the showing paid
     * @memberOf Showing
     * @return {String}
     */
    Showing.prototype.getPaid = function()
    {
        return this.get("paid");
    };

    /**
     * Get the showing program id
     * @memberOf Showing
     * @return {String}
     */
    Showing.prototype.getProgramId = function()
    {
        //TODO: (Entity Refactor) should we override this in child classes? Check if VOD showing has "programId" and linear showing has "entityId"
        return this.getByPriority("programId", "entityId");
    };

    /**
     * Get the program rating string
     * @return {String}
     */
    Showing.prototype.getRating = function()
    {
        var rating, object = this.get("contentRating/detailed");

        if( object )
            rating = object.name;
        else
        {
            var entity = this.getEntity();
            if( entity )
                rating = entity.getRating()
        }
        return rating;
    };

    /**
     *
     * @return {*}
     */
    Showing.prototype.getResumePoint = function()
    {
        var i, resumePoint;
        if( _x2._resume && _x2._resume.length )
        {
            for( i = 0; i < _x2._resume.length; i++ )
            {
                if( this._data.mediaId === _x2._resume[i].getMediaId() )
                {
                    resumePoint = _x2._resume[i];
                    resumePoint.setShowing( this );
                    break;
                }
            }
        }
        return resumePoint;
    };

    /**
     * Get the m3u8 streaming content url
     * @return {String}
     */
    Showing.prototype.getStreamUrl = function()
    {
        var url, streams = this.getEmbedded("streams");

        if( streams )
        {
            for( var i=0; i<streams.length; i++ )
            {
                if( streams[i]['encodingFormat'] === 'M3U' )
                {
                    url = streams[i]._links['contentUrl'].href;
                    break;
                }
            }
        }
        return url;
    };

    /**
     * Get the showing title (program title)
     * @memberOf Showing
     * @return {String}
     */
    Showing.prototype.getTitle = function()
    {
        return this.getByPriority("title","derivedTitle")
    };

    /**
     * Return true if this showing has closed captions
     * @memberOf Showing
     * @return {Boolean}
     */
    Showing.prototype.isClosedCaptioned = function()
    {
        return this.get("closedCaption");
    };

    /**
     * Return true if this showing is DVS
     * @memberOf Showing
     * @return {Boolean}
     */
    Showing.prototype.isDvs = function()
    {
        return this.get("hasDVS");
    };

    /**
     * Return true if this showing is HD
     * @memberOf Showing
     * @return {Boolean}
     */
    Showing.prototype.isHD = function()
    {
        return this.get("isHD");
    };

    /**
     * Return true if this showing has a SAP
     * @memberOf Showing
     * @return {Boolean}
     */
    Showing.prototype.isSap = function()
    {
        return this.get("isSAP");
    };

    /**
     * Return true if this showing is HD
     * @memberOf Showing
     * @return {Boolean}
     */
    Showing.prototype.isThreeD = function()
    {
        return this.get("isThreeD");
    };

    /**
     * Returns true. By default assume a showing is watchable. Intended to be overridden in the case add'l logic is required.
     * @return {boolean}
     */
    Showing.prototype.isWatchable = function()
    {
        return true;
    };

    /**
     * Set the entity for this showing.
     */
    Showing.prototype.setEntity = function( entity )
    {
        this._entity = entity;
    };

    return Showing;

})();

