// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.TwoOptionsOverlay = (function()
{
    "use strict";

    TwoOptionsOverlay.prototype = new Overlay();

    function TwoOptionsOverlay(){}

    TwoOptionsOverlay.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._buttons );
        var str   = this._titleString.getText() + ". ";

        if( this._subTitleString )
            str += this._subTitleString.getText() + ". ";

        this._speakTarget.setText( str + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press left or right to review options. Press OK to select. Press back to dismiss.", focus._speakRole, this, false );
    };

    /**
     * Initialize TwoOptionsOverlay
     * @param params
     * @param params.title {string}
     * @param params.subTitle {string}
     * @param params.callback {function}
     * @param params.button1 {Object}
     * @param params.button2 {Object}
     */
    TwoOptionsOverlay.prototype.init = function( params )
    {
        Overlay.prototype.init.call( this );
        this._className = "TwoOptionsOverlay";

        var self    = this;
        var buttonY = _x2.scaleValInt(260);

        var titleStyle    = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap", textAlign:"center" } );
        var subTitleStyle = new Style( { font:"light" , color:"#a3a3a3", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", textAlign:"center" } );
        var buttonStyle   = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#000000"  } );

        var buttons = [
            new StringWidget().init( { text:params.button1.text, style:buttonStyle, onEnter:function(){ onSubmit(params.button1.key) } } ),
            new StringWidget().init( { text:params.button2.text, style:buttonStyle, onEnter:function(){ onSubmit(params.button2.key) } } )
        ];

        var onSubmit = function( choice )
        {
            params.callback( choice );
        };

        this._titleString = new StringWidget().init({text:params.title, style:titleStyle});
        this._bg.addWidget(this._titleString, Style._safeLeft, Style._safeTop + _x2.scaleValInt(163) );

        if( params.subTitle && params.subTitle.length )
        {
            var w = this._bg.getW() - Style._safeLeft - Style._safeRight;
            var h = _x2.scaleValInt( 80 );

            this._subTitleString = new TextBlockWidget().init( { w:w, h:h, text:params.subTitle, style:subTitleStyle, ellipsis:true } );
            this._bg.addWidget(this._subTitleString, Style._safeLeft, Style._safeTop + _x2.scaleValInt(245) );

            buttonY =_x2.scaleValInt( 360 );
        }

        this._buttons = new HorizPillButtonsWidget().init( { entries:buttons, spacing:_x2.scaleValInt(41) } );
        this._bg.addWidget(this._buttons, Style._safeLeft, Style._safeTop + buttonY );

        this._buttons.addReadyCallback(function(){self.layout()});

        return this;
    };

    TwoOptionsOverlay.prototype.layout = function()
    {
        var center = this._bg.getW() / 2;

        this._buttons.setX( center - this._buttons.getW() / 2 );
        this._titleString.setX( center - this._titleString.getW() / 2 );

        if( this._subTitleString )
            this._subTitleString.setX( center - this._subTitleString.getW() / 2 );
    };

    return TwoOptionsOverlay;

})();
