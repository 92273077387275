// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PinOverlay = ( function()
{
    "use strict";

    PinOverlay.prototype = new Overlay();

    PinOverlay.Type = Object.freeze
    ({
        PC_CONFIRM         : 0,
        PC_CREATE          : 1,
        PC_VALIDATE        : 2,
        PC_VALIDATE_FAIL   : 3,
        PC_VERIFY_FAIL     : 4,
        PURC_CONFIRM       : 5,
        PURC_CREATE        : 6,
        PURC_VALIDATE      : 7,
        PURC_VALIDATE_FAIL : 8,
        PURC_VERIFY_FAIL   : 9
    });

    PinOverlay.FlowType = Object.freeze
    ({
        PARENTAL_CONTROL : 0,
        PURCHASE         : 1,
    });

    function PinOverlay(){}

    PinOverlay.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._pinPad );
        var str   = this._speechStr ? this._speechStr : "";

        str += this._title.getText() + ". ";

        if( this._desc )
            str += this._desc.getText() + ". ";

        str = str.replace( /xfinity/gi, "x finity" );

        this._speakTarget.setText( str + focus._speakStr + "." );
        this._speakTarget.setSpeechParams( undefined, focus._speakRole, this, false );
    };

    /**
     * Initializer
     * @memberof PinOverlay
     * @param {Object}          [params]
     * @param {String}          [params.speechStr]         - String to read before reading the the Pin overlay text
     * @param {PinOverlay.Type} [params.type]              - Which version of the overlay to display
     * @param {Number}          [params.attemptsRemaining] - Number of remaining purchase attempts
     * @returns {PinOverlay}
     */

    PinOverlay.prototype.init = function( params )
    {
        Overlay.prototype.init.call( this, params );
        this._className  = "PinOverlay";

        var self       = this, title, desc, onReady;
        var titleStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(45), whiteSpace:"nowrap", textAlign:"center" } );
        var descStyle  = new Style( { font:"light" , color:"#9b9b9b", fontSize:_x2.scaleValInt(27), whiteSpace:"nowrap", textAlign:"center" } );
        var w          = this._bg.getW();

        this._numReady  = 0;
        this._speechStr = params.speechStr;

        onReady = function() { self._numReady++; self.layoutPinOverlay() };

        switch( params.type )
        {
            case PinOverlay.Type.PC_CONFIRM:
                title = "Confirm a Parental Control PIN";
                break;

            case PinOverlay.Type.PC_CREATE:
                title = "Create a Parental Control PIN";
                desc  = "Set a Parental Control PIN that will be required to access content based on rating, channel, title, and more.";
                break;

            case PinOverlay.Type.PC_VALIDATE:
                title = "Please Enter Your Parental Control PIN";
                break;

            case PinOverlay.Type.PC_VALIDATE_FAIL:
                title = "Please Enter Your Parental Control PIN";
                desc  = "Incorrect PIN. Please try again.";
                break;

            case PinOverlay.Type.PC_VERIFY_FAIL:
                title = "Please Verify Your New PIN";
                desc  = "The numbers you entered did not match, please try again.";
                break;

            case PinOverlay.Type.PURC_CONFIRM:
                title = "Verify Your PIN";
                break;

            case PinOverlay.Type.PURC_CREATE:
                title = "Create Your Purchase PIN";
                desc  = "This PIN will protect your account across all Xfinity Stream apps.";
                break;

            case PinOverlay.Type.PURC_VALIDATE:
                title = "Please Enter Your Purchase PIN";
                break;

            case PinOverlay.Type.PURC_VALIDATE_FAIL:
                title = "Please Enter Your Purchase PIN";
                desc  = "The PIN you entered was incorrect. You may try " + params.attemptsRemaining + " more " + ( params.attemptsRemaining > 1 ?  "times." : "time." );
                break;

            case PinOverlay.Type.PURC_VERIFY_FAIL:
                title = "Create Your Purchase PIN";
                desc  = "Let's try that again. The numbers you've entered don't match.";
                break;

            default:
                console.error( "ERROR -> unknown PIN overlay type = " + params.type );
                break;
        }

        this._title = new StringWidget().init( { text:title, style:titleStyle } );
        this._title.setW( w );
        this._title.addReadyCallback( onReady );
        this._bg.addWidget( this._title );

        if( desc !== undefined )
        {
            this._desc = new TextBlockWidget().init( { text:desc, style:descStyle, w:w/2 } );
            this._bg.addWidget( this._desc, w/4 );
        }

        this._pinPad = new PinPadWidget().init( params );
        this._pinPad.addReadyCallback( onReady );
        this._bg.addWidget( this._pinPad );

        return this;
    };

    PinOverlay.prototype.layoutPinOverlay = function()
    {
        if( this._numReady === 2 )
        {
            var y0     = 0;
            var y1     = y0 + this._title.getH();
            var y2     = y1 + ((this._desc === undefined) ? 0 : this._desc.getH());
            var offset = (this._bg.getH() - y2 - _x2.scaleValInt( 24 ) - this._pinPad.getH()) / 2;

            this._title.setY ( y0 + offset );

            if( this._desc !== undefined )
                this._desc.setY( y1 + offset );

            this._pinPad.setY( y2 + offset + _x2.scaleValInt( 24 ) );
            this._pinPad.setX( (this._bg.getW() - this._pinPad.getW()) / 2 );
        }
    };

    PinOverlay.setPinFlow = function( type, onFlowComplete, isModify, pin, onDismiss )
    {
        var params = {}, firstOverlay, secondOverlay, thirdOverlay;

        var onGotPin = function( data )
        {
            if( data )
            {
                if( type === PinOverlay.FlowType.PARENTAL_CONTROL )
                {
                    if( data )
                        _x2._pc = data;
                }

                if( data.isEnabled() === false || isModify === true )
                {
                    var onSetPin = function( setPinStr )
                    {
                        var onConfirmPin = function( confirmPinStr )
                        {
                            if( setPinStr === confirmPinStr )
                            {
                                if( type === PinOverlay.FlowType.PARENTAL_CONTROL )
                                {
                                    _x2.popOverlay( secondOverlay, function(){ _x2._hi.fadeOut( 300 ); _x2.pushOverlay( new PinSetOverlay().init( { text:"Your Parental Control PIN is set" } ) ) } );

                                    _x2._pc.setEnabled( true );
                                    _x2._pc.setPin( setPinStr );
                                    _x2._data.setParentalControlsSettings( _x2._pc );
                                }
                                else
                                {
                                    var onGotPin = function( obj )
                                    {
                                        _x2.popOverlay( secondOverlay, function(){ _x2._hi.fadeOut( 300 ); _x2.pushOverlay( new PinSetOverlay().init( { onDismiss:function() { onFlowComplete( obj, setPinStr ); }, text:"Your Purchase PIN is set" } ) ) } );
                                    };

                                    var onGotPinError = function( e )
                                    {
                                        console.error( e );
                                    };

                                    data.setPin( pin, setPinStr ). then( function() { _x2._data.getPurchasePin().then( onGotPin ); }, onGotPinError );
                                }
                            }
                            else
                            {
                                params.type      = ( type === PinOverlay.FlowType.PARENTAL_CONTROL ) ? PinOverlay.Type.PC_VERIFY_FAIL : PinOverlay.Type.PURC_VERIFY_FAIL;
                                params.onCapture = onConfirmPin;
                                params.onDismiss = onDismiss;
                                thirdOverlay     = new PinOverlay().init( params );

                                _x2.pushOverlay( thirdOverlay );
                                _x2.popOverlay( secondOverlay );

                                secondOverlay = thirdOverlay;
                            }
                        };

                        if( type === PinOverlay.Type.PC_CREATE && setPinStr === "0000" )
                        {
                            firstOverlay._desc.setText( "This PIN is reserved and cannot be used. Please try a different PIN." );
                            firstOverlay._pinPad.clear();

                            firstOverlay._speakTarget.setText( firstOverlay._desc.getText() );
                            firstOverlay._speakTarget.setSpeechParams( undefined, undefined, firstOverlay, false );
                        }
                        else
                        {
                            params.type      = ( type === PinOverlay.FlowType.PARENTAL_CONTROL ) ? PinOverlay.Type.PC_CONFIRM : PinOverlay.Type.PURC_CONFIRM;
                            params.onCapture = onConfirmPin;
                            params.onDismiss = onDismiss;
                            secondOverlay    = new PinOverlay().init( params );

                            _x2.pushOverlay( secondOverlay );
                            _x2.popOverlay( firstOverlay );
                        }
                    };

                    params.type      = ( type === PinOverlay.FlowType.PARENTAL_CONTROL ) ? PinOverlay.Type.PC_CREATE : PinOverlay.Type.PURC_CREATE;
                    params.onCapture = onSetPin;
                    params.onDismiss = onDismiss;
                    firstOverlay     = new PinOverlay().init( params );

                    _x2.pushOverlay( firstOverlay );
                }
                else
                {
                    var overlay, lastOverlay;

                    var onValidatePin = function( validatePinStr )
                    {
                        if( type === PinOverlay.FlowType.PARENTAL_CONTROL && _x2._pc.checkPin( validatePinStr ) === true )
                        {
                            _x2.popOverlay( overlay, onFlowComplete );
                        }
                        else if( type === PinOverlay.FlowType.PURCHASE && data.isEnabled() === true )
                        {
                            var onPinValid = function()
                            {
                                _x2.popOverlay( overlay, function() { onFlowComplete( data, validatePinStr ); } );
                            };

                            var onPinError = function( e )
                            {
                                console.log( "PIN not valid" );
                                console.log( e );

                                var remaining;

                                try
                                {
                                    remaining = JSON.parse( e.getDescription() ).attemptsLeft;

                                    console.log( remaining );
                                }
                                catch( error )
                                {
                                    remaining = "?";
                                }

                                if( remaining === "0" )
                                {
                                    _x2.pushOverlay( new AlertOverlay().init( { title:"Your Purchase PIN is locked.", message:"Your Purchase PIN was entered incorrectly five times. You must wait 60 minutes to try again or visit xfin.tv/pin to reset it." } ) );
                                    _x2.popOverlay( overlay );
                                }
                                else
                                {
                                    params.type              = ( type === PinOverlay.FlowType.PARENTAL_CONTROL ) ? PinOverlay.Type.PC_VALIDATE_FAIL : PinOverlay.Type.PURC_VALIDATE_FAIL;
                                    params.attemptsRemaining = remaining;
                                    lastOverlay              = overlay;
                                    _x2.pushOverlay( overlay = new PinOverlay().init( params ) );
                                    _x2.popOverlay( lastOverlay );
                                }
                            };

                            data.verifyPin( validatePinStr ).then( onPinValid, onPinError );
                        }
                        else
                        {
                            params.type = ( type === PinOverlay.FlowType.PARENTAL_CONTROL ) ? PinOverlay.Type.PC_VALIDATE_FAIL : PinOverlay.Type.PURC_VALIDATE_FAIL;
                            lastOverlay = overlay;
                            _x2.pushOverlay( overlay = new PinOverlay().init( params ) );
                            _x2.popOverlay( lastOverlay );
                        }
                    };

                    params.type      = ( type === PinOverlay.FlowType.PARENTAL_CONTROL ) ? PinOverlay.Type.PC_VALIDATE : PinOverlay.Type.PURC_VALIDATE;
                    params.onCapture = onValidatePin;
                    params.onDismiss = onDismiss;

                    _x2.pushOverlay( overlay = new PinOverlay().init( params ) );
                }
            }
        };

        _x2.requestFocus( new KeyEater().init( _x2._focus ) );

        if( type === PinOverlay.FlowType.PARENTAL_CONTROL )
            _x2._data.getParentalControls().then( onGotPin ).catch( onGotPin );
        else if( type === PinOverlay.FlowType.PURCHASE )
            _x2._data.getPurchasePin().then( onGotPin ).catch( onGotPin );
        else
            console.error( "Unsupported pin flow = " + type );
    };

    return PinOverlay;

})();
