// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.Widget = ( function()
{
    "use strict";

    Widget.prototype = new Callbacks();

    // Independent animation axis
    /**
     * @namespace     Axis
     * @enum {Number} Axis
     * @type {Number}
     * @property {Number}               Widget.ALPHA            - Normalized percentage opacity. 0.0 = invisible, 1.0 = visible.
     * @property {Number}               Widget.W                - width
     * @property {Number}               Widget.H                - height
     * @property {Number}               Widget.X                - offset from left edge
     * @property {Number}               Widget.Y                - offset from top edge
     * @property {Number}               Widget.Z                - Force widget to be on top or behind
     * @property {Number}               Widget.X_SCALE          - Scale factor. 1 = original size
     * @property {Number}               Widget.Y_SCALE          - Scale factor. 1 = original size
     * @property {String}               Widget.FONT             - "light", "medium", "bold"
     * @property {String}               Widget.FONT_COLOR       - 7 character HTML 6 hex digit color. '#FF0000'
     * @property {String}               Widget.FONT_COLOR_HI    - 7 character HTML 6 hex digit color  '#00FF00'
     * @property {Number}               Widget.FONT_SIZE        - Font size in pixels (Doesn't include ascenders nor descenders)
     * @property {('nowrap'|'normal')}  Widget.TEXT_WHITE_SPACE - If text should wrap or not. Default: normal. {@link http://www.w3schools.com/jsref/prop_style_whitespace.asp}
     * @property {('left'|'center')}    Widget.TEXT_ALIGN       - Alignment. Default: left.                    {@link http://www.w3schools.com/jsref/prop_style_textalign.asp}
     * @property {('visible'|'hidden')} Widget.OVERFLOW         - If contents should extend past the region    {@link http://www.w3schools.com/jsref/prop_style_overflow.asp}
     */
    Widget.ALPHA               =  0;
    Widget.W                   =  1;
    Widget.H                   =  2;
    Widget.X                   =  3;
    Widget.Y                   =  4;
    Widget.Z                   =  5;
    Widget.X_SCALE             =  6;
    Widget.Y_SCALE             =  7;
    Widget.Z_SCALE             =  8;
    Widget.X_ROT               =  9;
    Widget.Y_ROT               = 10;
    Widget.Z_ROT               = 11;
    Widget.FONT                = 12;
    Widget.FONT_COLOR          = 13;
    Widget.FONT_COLOR_HI       = 14;
    Widget.FONT_SIZE           = 15;
    Widget.TEXT_WHITE_SPACE    = 16;
    Widget.TEXT_ALIGN          = 17;
    Widget.OVERFLOW            = 18;
    Widget.NUM_VALS            = 19;

    Widget.Axis = Object.freeze  // This will eventually replace the above constants
    ({
        A            :  0,
        W            :  1,
        H            :  2,
        X            :  3,
        Y            :  4,
        Z            :  5,
        X_SCALE      :  6,
        Y_SCALE      :  7,
        Z_SCALE      :  8,
        X_ROT        :  9,
        Y_ROT        : 10,
        Z_ROT        : 11,
        FONT_COLOR   : 13,
        FONT_COLOR_HI: 14,
        FONT_SIZE    : 15,
        NUM_VALS     : 19,

        Str:
        {
             0: "alpha",
             1: "w",
             2: "h",
             3: "x",
             4: "y",
             5: "z",
             6: "xScale",
             7: "yScale",
             8: "zScale",
             9: "xRot",
            10: "yRot",
            11: "zRot",
            12: "DEPRECATED",
            13: "fontColor",
            14: "fontColorHi",
            15: "fontSize",
            16: "DEPRECATED",
            17: "DEPRECATED",
            18: "DEPRECATED"
        }
    });

    /**
     * @namespace         Easing
     * @enum     {Number} Easing
     * @property {Number} Widget.Easing.LINEAR
     * @property {Number} Widget.Easing.QUAD_IN_OUT
     * @property {Number} Widget.Easing.QUAD_OUT
     */

    Widget.Easing = Object.freeze // This will eventually replace the above constants
    ({
        BACK_IN        :  1,
        BACK_IN_OUT    :  2,
        BACK_OUT       :  3,
        BOUNCE_IN      :  4,
        BOUNCE_IN_OUT  :  5,
        BOUNCE_OUT     :  6,
        CIRC_IN        :  7, // circle
        CIRC_IN_OUT    :  8,
        CIRC_OUT       :  9,
        CUBIC_IN       : 10, // t^3
        CUBIC_IN_OUT   : 11,
        CUBIC_OUT      : 12,
        ELASTIC_IN     : 13, // elastic
        ELASTIC_IN_OUT : 14,
        ELASTIC_OUT    : 15,
        EXPO_IN        : 16, // See note about canonical Robert's 'expo' and jQuery UI mis-labeling 'sextic'
        EXPO_IN_OUT    : 17,
        EXPO_OUT       : 18,
        LINEAR         : 19, // t^1 Note: in = out = inout
        OCTIC_IN       : 20, // t^8
        OCTIC_IN_OUT   : 21,
        OCTIC_OUT      : 22,
        QUAD_IN        : 23, // t^2
        QUAD_IN_OUT    : 24,
        QUAD_OUT       : 25,
        QUART_IN       : 26, // t^4
        QUART_IN_OUT   : 27,
        QUART_OUT      : 28,
        QUINT_IN       : 29, // t^5
        QUINT_IN_OUT   : 30,
        QUINT_OUT      : 31,
        SEPTIC_IN      : 32, // t^7
        SEPTIC_IN_OUT  : 33,
        SEPTIC_OUT     : 34,
        SEXTIC_IN      : 35, // t^6
        SEXTIC_IN_OUT  : 36,
        SEXTIC_OUT     : 37,
        SINE_IN        : 38, // sin
        SINE_IN_OUT    : 39,
        SINE_OUT       : 40,
    });

    function Widget(){}

    function easeBounceIn( p ) // https://github.com/nicolasgramlich/AndEngine/blob/GLES2/src/org/andengine/util/modifier/ease/EaseBounceIn.java
    {
        return 1 - easeBounceOut( 1-p );
    }

    function easeBounceOut( p )  // 3 bounces (not including initial position)
    {
        var k1 = 1   / 2.75; // 36.36% // reciprocal
        var k2 = 2     * k1; // 72.72%
        var k3 = 1.5   * k1; // 54.54%
        var k4 = 2.5   * k1; // 90.90%
        var k5 = 2.25  * k1; // 81.81%
        var k6 = 2.625 * k1; // 95.45%
        var k0 = 7.5625, t;

        if     ( p < k1 ) {             return k0 * p*p;            }
        else if( p < k2 ) { t = p - k3; return k0 * t*t + 0.75;     } // 48/64
        else if( p < k4 ) { t = p - k5; return k0 * t*t + 0.9375;   } // 60/64
        else              { t = p - k6; return k0 * t*t + 0.984375; } // 63/64
    }

    function easeElasticIn( p )  // Unoptimized Reference: http://code.google.com/p/andengine/source/browse/src/org/anddev/andengine/util/modifier/ease/EaseElasticIn.java
    {                            // Moved to: https://github.com/nicolasgramlich/AndEngine/blob/GLES2/src/org/andengine/util/modifier/ease/EaseElasticIn.java
        var m = p-1;
        return -Math.pow( 2, 10*m ) * Math.sin( (m*40 - 3) * Math.PI/6 );
    }

    function easeElasticOut( p )  // Unoptimized Reference: http://code.google.com/p/andengine/source/browse/src/org/anddev/andengine/util/modifier/ease/EaseElasticOut.java
    {                             // Optimized reference  : http://www.joshondesign.com/2013/03/01/improvedEasingEquations
        // Alt.. return 1 - easeElasticIn( 1 - p );
        if( p <= 0 ) return 0;
        if( p >= 1 ) return 1;
        return 1+(Math.pow( 2,10*-p ) * Math.sin( (-p*40 - 3) * Math.PI/6 ));
    }

/*
    Widget.ID  = 0; // DEBUG Widget Animation Trace & WebGL Debug Widget Inspector
    Widget.OBJ = [];

    // DEBUG Widget Animation Trace
    Widget._animTrace = {};

    Widget.animTraceAdd = function( widget )
    {
        Widget._animTrace[ widget._uid ] = true;
    }

    Widget.animTraceClear = function ()
    {
        Widget._animTrace = {};
    }

    Widget.animTraceKids = function( widget )
    {
        Widget.animTraceAdd( widget );

        for( var iChild = 0; iChild < widget._children.length; iChild++ )
            Widget.animTraceKids( widget._children[ iChild ] );
    }

    Widget.animTraceLog = function( widget, axis, val, isEnd, time )
    {
        if( Widget._animTrace[ widget._uid ] )
            if( isEnd )
                console.log( "%s.onEnd()  _[ %d ].set%s( %o ) //       @ %o" , widget._className, widget._uid, Widget.Axis.Str[axis], val,                      time );
            else
                console.log( "%s.onInc()  _[ %d ].set%s( %o ) // <- %o @ %o", widget._className, widget._uid, Widget.Axis.Str[axis], val, widget._curVal[axis], time );
    }

    Widget.animTraceSet = function( widget, axis, val )
    {
        if( Widget._animTrace[ widget._uid ] )
            console.log( "%s.setVal()  _[ %d ].set%s( %o ) // <- %o", widget._className, widget._uid, Widget.Axis.Str[axis], val, widget._curVal[axis] );
    }

// */

    Widget.ShaderType = Object.freeze
    ({
        CKV                 : 0, // Vertex Color Mesh -- technically nothing to do with gradients. Mesh manually enlarged to cover all 4 corners
        KV                  : 1, // Constant Color, Vertex coordinates
        KVT                 : 2, // Constant Color, Vertex coordinates, Texture coordinates & sampler
        FONT                : 3, // Signed Distance Field Font in fixed-point
        GRADIENT_LINEAR_2AA : 4, // 2 colors, Primary/Principal X or Y axis-aligned, angle = 0, 90, 180, 270
        GRADIENT_LINEAR_4ROT: 5, // max 4 colors @ any stop, Gradient on Line centered
        GRADIENT_RADIAL_2   : 5, // 2 colors @ 0% and 100%, Ellipse at Center
        GRADIENT_RADIAL_4   : 6, // max 4 colors @ any stop, Ellipse at Center
        NUM_SHADERS         : 7
    });

    Widget.SHADER_CKV                  = 0;
    Widget.SHADER_KV                   = 1;
    Widget.SHADER_KVT                  = 2;
    Widget.SHADER_FONT                 = 3;
    Widget.SHADER_GRADIENT_LINEAR_2AA  = 4; // 2 colors, Primary/Principal X or Y axis-aligned, angle = 0, 90, 180, 270
    Widget.SHADER_GRADIENT_LINEAR_4ROT = 5; // max 4 colors @ any stop, Gradient on Line centered
    Widget.SHADER_GRADIENT_RADIAL_2    = 6; // 2 colors @ 0% and 100%, Ellipse at Center
    Widget.SHADER_GRADIENT_RADIAL_4    = 7; // max 4 colors @ any stop, Ellipse at Center

    Widget.NUM_SHADERS = 8;

    function glOrtho( left, right, bottom, top, near, far )
    {
        var dst = Widget.glProjMatrix[ Widget.glProjDepth ];

        var dx = right - left;
        var dy = top   - bottom;
        var dz = far   - near;

        var sx = right + left;
        var sy = top   + bottom;
        var sz = far   + near;

        dst[ 0] =  2. / dx; dst[ 4] =  0.     ; dst[ 8] =  0.     ; dst[12] = -sx / dx;
        dst[ 1] =  0.     ; dst[ 5] =  2. / dy; dst[ 9] =  0.     ; dst[13] = -sy / dy;
        dst[ 2] =  0.     ; dst[ 6] =  0.     ; dst[10] = -2. / dz; dst[14] = -sz / dz;
        dst[ 3] =  0.     ; dst[ 7] =  0.     ; dst[11] =  0.     ; dst[15] =  1.     ;
    }

    function glShadersInit()
    {
        var iShader, gl = Widget.gl, flags, shader, program;

        var ShaderLocation = Object.freeze
        ({
            FLAG_A  : 1 << 0, // unAngle - in radians
            FLAG_C  : 1 << 1, // avVertColor: vertex.rgba
            FLAG_D  : 1 << 2, // uvDimension, vvCoord - normalized pixel coordinates from vertex coordinate
            FLAG_G2 : 1 << 3, // uvGrad0..1
            FLAG_G4 : 1 << 4, // uvGrad0..3
            FLAG_GS : 1 << 5, // uvStops for Gradient
            FLAG_H  : 1 << 6, // unHeight -- in pixels
            FLAG_K  : 1 << 7, // uvColor
            FLAG_OAR: 1 << 8, // unooAspectRatio = one over aspect ratio = h/w
            FLAG_V  : 1 << 9, // avPosition: vertex.xyz
            FLAG_T  : 1 <<10, // avTexCoord, utDiffuse1
            FLAG_W  : 1 <<11, // unWeight -- font thickness/sharpness
        });

        var INC_FRAG_GRADIENT =
                    //    uvStop.x  uvStop.y  uvStop.z  uvStop.w
                    //      0.0 |---------|---------|---------| 1.0  Alpha Location
                    //      0.0 |---------|---------|---------| 1.0  Color Location
                    //          ^         ^         ^         ^
                    //        uvGrad0   uvGrad1   uvGrad2   uvGrad3  Colors (rgba)
                    //
                    // width1   |->d
                    // width2   <---w2--->
                    // width1             |------>d
                    // width2             <---w2--->
                    // width1                       |->d
                    // width2                       <---w2--->
                    "uniform lowp vec4  uvColor;                              \n"+ // FLAG_K
                    "uniform lowp vec4  uvGrad0;                              \n"+ // FLAG_G4 color at  0 %
                    "uniform lowp vec4  uvGrad1;                              \n"+ // FLAG_G4 color at x1 %
                    "uniform lowp vec4  uvGrad2;                              \n"+ // FLAG_G4 color at x2 %
                    "uniform lowp vec4  uvGrad3;                              \n"+ // FLAG_G4 color at 100%
                    "uniform lowp vec4  uvStops;                              \n"+ // FLAG_GS Location of 4 gradient stops
                    "lowp vec4 getGradientOnLine( lowp float d )              \n"+
                    "{                                                        \n"+
                    "    lowp vec4  color1;                                   \n"+
                    "    lowp vec4  color2;                                   \n"+
                    "    lowp float width1;                                   \n"+
                    "    lowp float width2;                                   \n"+ // See above diagram
                    "    lowp float t;                                        \n"+
                  //"if (d > 1.0) return vec4( 1.0, 0.0, 1.0, 1.0 );          \n"+ // DEBUG: HOT PINK
                    "    if( d >= uvStops.z ) {                               \n"+ // ** 4 Gradients **
                    "        color1 = uvGrad2;                                \n"+
                    "        color2 = uvGrad3;                                \n"+
                    "        width1 = d         - uvStops.z;                  \n"+
                    "        width2 = 1.0       - uvStops.z;                  \n"+ // OPTIMIZATION: uvStops.w=1.0 - uvStops.z
                    "    } else                                               \n"+
                    "    if( (d >= uvStops.y) && (d < uvStops.z ) ) {         \n"+ // ** 3 Gradients **
                    "        color1 = uvGrad1;                                \n"+
                    "        color2 = uvGrad2;                                \n"+
                    "        width1 = d         - uvStops.y;                  \n"+
                    "        width2 = uvStops.z - uvStops.y;                  \n"+
                    "    } else {                                             \n"+ // ** 2 Gradients ** d < uvStops.y
                    "        color1 = uvGrad0;                                \n"+
                    "        color2 = uvGrad1;                                \n"+
                    "        width1 = d;                                      \n"+ // OPTIMIZATION: d         - uvStops.x=0.0
                    "        width2 = uvStops.y;                              \n"+ // OPTIMIZATION: uvStops.y - uvStops.x=0.0
                    "    }                                                    \n"+
                    "    t = min( width1 / width2, 1.0 );                     \n"+ // Clamp radius to 1.0
                    "    return mix( color1, color2, t );                     \n"+
                    "}                                                        \n";

        var SHADER_SOURCE =
        [
            {
                flag: 0 // SHADER_CKV
                    | ShaderLocation.FLAG_C  // Vertex Color
                    | ShaderLocation.FLAG_K  // Constant Color
                    | ShaderLocation.FLAG_V, // Vertex Position
                vert:
                    "attribute     vec3 avVertColor;                          \n"+ // FLAG_C
                    "attribute     vec3 avPosition;                           \n"+ // FLAG_V

                    "uniform       mat4 umProj;                               \n"+
                    "uniform       mat4 umView;                               \n"+

                    "varying lowp  vec3 vvColor;                              \n"+ // FLAG_C
                    "                                                         \n"+
                    "void main() {                                            \n"+
                    "    gl_Position = umProj * umView * vec4(avPosition,1.0);\n"+
                    "    vvColor = avVertColor;                               \n"+ // FLAG_C Pass color from vertex to fragment shader
                    "}",
                frag:
                    "uniform lowp vec4 uvColor;                               \n"+ // FLAG_K

                    "varying lowp vec3 vvColor;                               \n"+ // FLAG_C Vertex Color
                    "varying lowp vec2 vvCoord;                               \n"+ // FLAG_D
                    "                                                         \n"+
                    "void main() {                                            \n"+
                    "    gl_FragColor = vec4( vvColor, uvColor.a ) * uvColor; \n"+
                    "}"
            },
            {
                flag: 0 // SHADER_KV
                    | ShaderLocation.FLAG_K
                    | ShaderLocation.FLAG_V,
                vert:
                    "attribute vec3 avPosition;"+ // FLAG_V

                    "uniform   mat4 umProj;    "+
                    "uniform   mat4 umView;    "+

                    "void main() {             "+
                    "    gl_Position = umProj * umView * vec4(avPosition,1.0);"+
                    "}",
                frag:
                    "uniform lowp vec4 uvColor; "+ // FLAG_K

                    "void main() {              "+
                    "    gl_FragColor = uvColor;"+
                    "}"
            },
            {
                flag: 0 // SHADER_KVT
                    | ShaderLocation.FLAG_K
                    | ShaderLocation.FLAG_V
                    | ShaderLocation.FLAG_T,
                vert:
                    "attribute vec3 avPosition;  "+ // FLAG_V
                    "attribute vec2 avTexCoord;  "+ // FLAG_T vertex
                    "uniform   mat4 umProj;      "+
                    "uniform   mat4 umView;      "+
                    "varying   vec2 vvTexCoord;  "+ // FLAG_T vertex -> fragment

                    "void main() {               "+
                    "    gl_Position = umProj * umView * vec4(avPosition,1.0);"+
                    "    vvTexCoord = avTexCoord;"+
                    "}",
                frag:
                    "uniform lowp vec4 uvColor;                                "+
                    "uniform sampler2D utDiffuse1;                             "+
                    "varying lowp vec2 vvTexCoord;                             "+ // FLAG_T
                    "void main() {                                             "+
                    "    lowp vec4 color = texture2D( utDiffuse1, vvTexCoord );"+
                    "    color.xyz *= color.w;                                 "+ // premultiplied alpha: remove halo fringe in "xfinity_logo.png" at MainMenuScreen
                    "    gl_FragColor = uvColor * color;                       "+ // needs premultiplied alpha: gl.blendFunc( gl.ONE, gl.ONE_MINUS_SRC_ALPHA );
                    "}"
            },
            {
                flag: 0 // SHADER_FONT
                    | ShaderLocation.FLAG_H
                    | ShaderLocation.FLAG_K
                    | ShaderLocation.FLAG_V
                    | ShaderLocation.FLAG_T
                    | ShaderLocation.FLAG_W,
                vert:
                    "attribute mediump vec3 avPosition;                    "+ // FLAG_V vertex: fixed-point  fragment: float
                    "attribute mediump vec2 avTexCoord;                    "+ // FLAG_T vertex: fixed-point  fragment: float
                    "uniform           mat4 umProj    ;                    "+
                    "uniform           mat4 umView    ;                    "+
                    "varying   mediump vec3 vvTexCoord;                    "+ // FLAG_T vertex: fixed-point  fragment: float
                    "void main() {                                         "+
                    "    mediump vec4 nvPosition    = vec4( avPosition.xy, 0.0, 1.0 ); "+ // fonts always at <x,y,0>
                    "                 vvTexCoord.xy = avTexCoord.xy / 32768.0;         "+ // convert from fixed point 1.15 to float
                    "                 vvTexCoord.z  = min( 1.0, avPosition.z/16384.0 );"+ // pass vertex alpha to fragment shader via normally unused 3rd texture coordinate
                    "                 gl_Position   = umProj * umView * nvPosition;    "+
                    "}",
                frag:
                    // https://github.com/KhronosGroup/WebGL/blob/master/extensions/OES_standard_derivatives/extension.xml
                    // https://github.com/KhronosGroup/WebGL/blob/master/sdk/tests/conformance/extensions/oes-standard-derivatives.html
                    "#ifdef GL_OES_standard_derivatives                           \n"+
                    "    #extension GL_OES_standard_derivatives : enable          \n"+
                    "#endif                                                       \n"+
                    "                                                             \n"+
                    "precision mediump float;                                     \n"+ // http://stackoverflow.com/questions/13780609/what-does-precision-mediump-float-mean
                    "uniform lowp    vec4      uvColor    ;                       \n"+
                    "uniform         sampler2D utDiffuse1 ;                       \n"+
                    "varying mediump vec3      vvTexCoord ;                       \n"+ // vertex: fixed-point  fragment: float
                    "uniform         float     unHeight;                          \n"+
                    "uniform         float     unWeight;                          \n"+ // 0.0 = Normal, 0.5 = Bold
                    "                                                             \n"+
                    /*
                    https://github.com/Chlumsky/msdfgen/issues/22
                        vec3 sample = texture( uTex0, TexCoord ).rgb;
                        ivec2 sz = textureSize( uTex0, 0 );
                        float dx = dFdx( TexCoord.x ) * sz.x;
                        float dy = dFdy( TexCoord.y ) * sz.y;
                        float toPixels = 8.0 * inversesqrt( dx * dx + dy * dy );
                        float sigDist = median( sample.r, sample.g, sample.b ) - 0.5;
                        float opacity = clamp( sigDist * toPixels + 0.5, 0.0, 1.0 );
                    */
                    "void main() {                                                \n"+
                    "    mediump vec2  st    = vec2( vvTexCoord.x, vvTexCoord.y );\n"+ // needs GL_LINEAR
                    "    mediump float dist  = texture2D( utDiffuse1, st ).r;     \n"+ // gl.ALPHA texture // or .r for .jpg
                    "    mediump float fade  = vvTexCoord.z;                      \n"+
                    "    mediump vec2  dim   = vec2( 1024.0, unHeight );          \n"+ // GLSL 1.3: textureSize( utDiffuse1, 0 ) -- NOT supported on GL ES 2.x
                    "    mediump float dx    = dFdx( vvTexCoord.x ) * dim.x;      \n"+ // convert gradient to screen space
                    "    mediump float dy    = dFdy( vvTexCoord.y ) * dim.y;      \n"+
                    "    mediump float scale = 8.0 * inversesqrt( dx*dx + dy*dy );\n"+ // 8 is sharpness: 0 = blurry, 16 = sharp
                    "    mediump float d     = (dist-0.5)*scale + 0.5 + unWeight; \n"+ // For 3 channel SDF median = max(min(a,b), min(max(a,b),c));
                    "    mediump float alpha = clamp( d, 0.0, 1.0 );              \n"+
                    "    gl_FragColor = uvColor * fade * uvColor.a * alpha;       \n"+ // needs premultiplied alpha: gl.blendFunc( gl.ONE, gl.ONE_MINUS_SRC_ALPHA );

                    "}"
            },
            {
                flag: 0 // GRADIENT_LINEAR_2AA // 2 Color, Principal X or Y Axis-Aligned -- Horizontal or Vertical
                    | ShaderLocation.FLAG_D
                    | ShaderLocation.FLAG_G4
                    | ShaderLocation.FLAG_K
                    | ShaderLocation.FLAG_V,
                vert:
                    "attribute     vec3 avPosition;                           \n"+ // FLAG_V

                    "uniform lowp  vec2 uvDimension;                          \n"+ // FLAG_D
                    "uniform       mat4 umProj;                               \n"+
                    "uniform       mat4 umView;                               \n"+

                    "varying lowp  vec2 vvCoord;                              \n"+ // FLAG_D
                    "                                                         \n"+
                    "void main() {                                            \n"+
                    "    gl_Position = umProj * umView * vec4(avPosition,1.0);\n"+
                    "    vvCoord = avPosition.xy / uvDimension;               \n"+ // FLAG_D
                    "}",
                frag:
                    "uniform lowp vec4 uvColor;                            \n"+ // FLAG_K
                    "uniform lowp vec4 uvGrad0;                            \n"+ // FLAG_G4 x0y0 Bottom Left
                    "uniform lowp vec4 uvGrad1;                            \n"+ // FLAG_G4 x1y0 Bottom Right
                    "uniform lowp vec4 uvGrad2;                            \n"+ // FLAG_G4 x0y1 Top Left
                    "uniform lowp vec4 uvGrad3;                            \n"+ // FLAG_G4 x1y1 Top Right

                    "varying lowp vec2 vvCoord;                            \n"+ // FLAG_D
                    "                                                      \n"+
                    "void main() {                                         \n"+
                    "    lowp vec4 x0 = mix( uvGrad0, uvGrad1, vvCoord.x );\n"+
                    "    lowp vec4 x1 = mix( uvGrad2, uvGrad3, vvCoord.x );\n"+
                    "    lowp vec4 y  = mix( x0     , x1     , vvCoord.y );\n"+
                    "    gl_FragColor = y * uvColor.a;                     \n"+ // FLAG_
                    "}"
            },
            {
                flag: 0 // GRADIENT_LINEAR_4ROT
                    | ShaderLocation.FLAG_A
                    | ShaderLocation.FLAG_D
                    | ShaderLocation.FLAG_G4
                    | ShaderLocation.FLAG_GS
                    | ShaderLocation.FLAG_K
                    | ShaderLocation.FLAG_OAR
                    | ShaderLocation.FLAG_V,
                vert:
                    "attribute    vec3 avPosition;                            \n"+ // FLAG_V

                    "uniform lowp vec2 uvDimension;                           \n"+ // FLAG_D
                    "uniform      mat4 umProj;                                \n"+
                    "uniform      mat4 umView;                                \n"+

                    "varying lowp vec2 vvCoord;                               \n"+ // FLAG_D
                    "                                                         \n"+
                    "void main() {                                            \n"+
                    "    gl_Position = umProj * umView * vec4(avPosition,1.0);\n"+
                    "    vvCoord = avPosition.xy / uvDimension;               \n"+ // FLAG_D
                    "}",
                frag:
                    "uniform lowp float unAngle;                              \n"+ // FLAG_A radians
                    "uniform lowp vec2  uvDimension;                          \n"+ // FLAG_D
                    "uniform lowp float unooAspectRatio;                      \n"+ // FLAG_OAR
                    "varying lowp vec2  vvCoord;                              \n"+ // FLAG_D

                    INC_FRAG_GRADIENT +

                    "void main() {                                                  \n"+
                    "    lowp vec2  vG    = vec2( cos( unAngle ), sin( unAngle ) ); \n"+ // sc.x = Cos, sc.y = Sin
                    "    lowp mat2  mZ    = mat2( vG.x, vG.y, -vG.y, vG.x );        \n"+ // mRotateZ in Column Major
                    "    lowp vec2  q     = 2.0*vvCoord - 1.0;                      \n"+ // FLAG_D Trans -= <0.5,0.5> = Remap 0.0 .. 1.0 -> -1.0 ... +1.0
                    "               q.y  *= unooAspectRatio;                        \n"+ // Compensate for aspect ratio
                    "    lowp vec2  r     = mZ * q;                                 \n"+ // Rotate around <0.5>
                    "               r.x  /= (abs(vG.x) + unooAspectRatio*abs(vG.y));\n"+ // mRotZ * <1,iar> = 1*abs(cos(GradAngle)) + iar*abs(sin(GradAngle))
                    "               r.x  += 1.0;                                    \n"+ // -1 .. +1 -> 0 .. 2
                    "               r.x  *= 0.5;                                    \n"+ //  0 ..  2 -> 0 .. 1
                    "    lowp vec4  c     = getGradientOnLine( r.x );               \n"+ // DEBUG: vvCoord.x
                    "    gl_FragColor     = c * uvColor.a;                          \n"+ // FLAG_K
                    "}"
            },
            {
                flag: 0 // SHADER_GRADIENT_RADIAL_2 // 2 Color Ellipse at center
                    | ShaderLocation.FLAG_D
                    | ShaderLocation.FLAG_G2
                    | ShaderLocation.FLAG_K
                    | ShaderLocation.FLAG_V,
                vert:
                    "attribute     vec3 avPosition;                           \n"+ // FLAG_V

                    "uniform lowp  vec2 uvDimension;                          \n"+ // FLAG_D
                    "uniform       mat4 umProj;                               \n"+
                    "uniform       mat4 umView;                               \n"+

                    "varying lowp  vec2 vvCoord;                              \n"+ // FLAG_D
                    "                                                         \n"+
                    "void main() {                                            \n"+
                    "    gl_Position = umProj * umView * vec4(avPosition,1.0);\n"+
                    "    vvCoord = avPosition.xy / uvDimension;               \n"+ // FLAG_V FLAG_D normalize in object space
                    "}",
                frag:
                    "uniform lowp vec4 uvColor;                    \n"+ // FLAG_K
                    "uniform lowp vec4 uvGrad0;                    \n"+ // FLAG_G2 color @ 0%
                    "uniform lowp vec4 uvGrad1;                    \n"+ // FLAG_G2 color @ 100%

                    "varying lowp vec2 vvCoord;                    \n"+ // FLAG_D

                    "void main() {                                 \n"+
                    "    lowp vec2  d = 2.0*vvCoord - 1.0;         \n"+ // FLAG_D Remap 0.0 .. 1.0 -> -1.0 ... +1.0
                    "    lowp float r = sqrt( dot( d, d ) );       \n"+ // abs( distance )
                    "    lowp vec4  c = mix( uvGrad0, uvGrad1, r );\n"+
                    "    gl_FragColor = c * uvColor.a;             \n"+ // FLAG_K
                    "}"
            },
            {
                flag: 0 // SHADER_GRADIENT_RADIAL_4 // max 4 colors @ any stop, Ellipse at Center
                    | ShaderLocation.FLAG_D
                    | ShaderLocation.FLAG_G4
                    | ShaderLocation.FLAG_GS
                    | ShaderLocation.FLAG_K
                    | ShaderLocation.FLAG_V,
                vert:
                    "attribute     vec3 avPosition;                           \n"+ // FLAG_V

                    "uniform lowp  vec2 uvDimension;                          \n"+ // FLAG_D
                    "uniform       mat4 umProj;                               \n"+
                    "uniform       mat4 umView;                               \n"+

                    "varying lowp  vec2 vvCoord;                              \n"+ // FLAG_D
                    "                                                         \n"+
                    "void main() {                                            \n"+
                    "    gl_Position = umProj * umView * vec4(avPosition,1.0);\n"+
                    "    vvCoord = avPosition.xy / uvDimension;               \n"+ // FLAG_V FLAG_D normalize in object space: <w,h> -> 0 .. 1
                    "}",
                frag:
                    "varying lowp vec2  vvCoord;                              \n"+ // FLAG_D

                    INC_FRAG_GRADIENT +

                    "void main() {                                            \n"+
                    "    lowp vec2  d = 2.0*vvCoord - 1.0;                    \n"+ // FLAG_D Remap 0.0 .. 1.0 -> -1.0 ... +1.0
                    "               d*= 0.70710678118655;                     \n"+ // replicate DOM behavior: inversesqrt(2.0) = 1.0/sqrt(2.0) = 0.70710678118655 = we want the radius to be 1.0 at the (diagonal) corner not at principal x or y axis edge
                    "    lowp float r = sqrt( dot( d, d ));                   \n"+ // abs( distance )
                    "    lowp vec4  c = getGradientOnLine( r );               \n"+
                    "    gl_FragColor = c * uvColor.a;                        \n"+ // FLAG_K
                    "}"
            }
        ];

        Widget.glShaders    = [];
        Widget.glShaderThis = null; // active shader
        Widget.glShaderNext = -1; // enum of next
        Widget.glShaderPrev = -1; // enum of last

        var shaderCompile = function( shaderSource, shaderType )
        {
            shader = gl.createShader( shaderType );

            gl.shaderSource( shader, shaderSource );
            gl.compileShader( shader );

//console.info( "WebGL COMPILE: " + gl.getShaderInfoLog( shader ) );

            if( !gl.getShaderParameter( shader, gl.COMPILE_STATUS ) )
            {
                console.error( "WebGL ERROR -> Failed to compile shader: \n'" + shaderSource + "'\n  OpenGL: '" + gl.getShaderInfoLog( shader ) + "'" );

                switch( shaderType )
                {
                    case gl.FRAGMENT_SHADER: console.log( "  WebGL Shader Type: 'FRAGMENT_SHADER' :" + shaderSource ); break;
                    case gl.VERTEX_SHADER  : console.log( "  WebGL Shader Type: 'VERTEX_SHADER'   :" + shaderSource ); break;
                    default                : console.log( "  WebGL UNKNOWN shader type! '" + shaderType + "'" ); break;
                }

                gl.deleteShader( shader );
            }

            return shader;
        };

        var assertLocation = function( name, type ) // @params {String},{String}
        {
            if( (shader[ name ] === null) || (shader[ name ] === undefined) )
                console.info( "WebGL INFO -> Shader " + iShader + "/" + (Widget.NUM_SHADERS-1) + ", type: " + type + ", var: `" + name + "`, variable couldn't be bound -- not used?" )
        };

        var getAttribute = function( name ) // @param {String}
        {
            shader[ name ] = gl.getAttribLocation ( program, name );
            assertLocation( name, "Attribute" ); // DEBUG only
        };

        var getUniform = function( name ) // @param {String}
        {
            shader[ name ] = gl.getUniformLocation ( program, name );
            assertLocation( name, "Uniform" ); // DEBUG only
        };

        for( iShader = 0; iShader < Widget.NUM_SHADERS; ++iShader )
        {
            flags  = SHADER_SOURCE[ iShader ].flag;
            shader =
            {
                vert: shaderCompile( SHADER_SOURCE[ iShader ].vert, gl.VERTEX_SHADER   ),
                frag: shaderCompile( SHADER_SOURCE[ iShader ].frag, gl.FRAGMENT_SHADER )
            };

            program = gl.createProgram();

            gl.attachShader( program, shader.vert );
            gl.attachShader( program, shader.frag );

            gl.linkProgram( program );
            gl.useProgram ( program );

//console.log( "Shader: " + iShader + " Flags: 0x" + flags.toString(16) );
//console.info( "WebGL LINK: " + gl.getProgramInfoLog( program ) );

            if( flags & ShaderLocation.FLAG_A )
            {
                getUniform( "unAngle" );
            }

            if( flags & ShaderLocation.FLAG_C )
            {
                getAttribute( "avVertColor" );
            }

            if( flags & ShaderLocation.FLAG_D )
            {
                getUniform( "uvDimension" );
            }

            if( flags & ShaderLocation.FLAG_G2 )
            {
                getUniform( "uvGrad0" );
                getUniform( "uvGrad1" );
            }

            if( flags & ShaderLocation.FLAG_G4 )
            {
                getUniform( "uvGrad0" );
                getUniform( "uvGrad1" );
                getUniform( "uvGrad2" );
                getUniform( "uvGrad3" );
            }

            if( flags & ShaderLocation.FLAG_GS )
            {
                getUniform( "uvStops" );
            }

            if( flags & ShaderLocation.FLAG_H )
            {
                getUniform( "unHeight" );
            }

            if( flags & ShaderLocation.FLAG_K )
            {
                getUniform( "uvColor" );
            }

            if( flags & ShaderLocation.FLAG_OAR )
            {
                getUniform( "unooAspectRatio");
            }

            if( flags & ShaderLocation.FLAG_V )
            {
                getAttribute( "avPosition" );
            }

            if( flags & ShaderLocation.FLAG_T )
            {
                getAttribute( "avTexCoord" );
                getUniform  ( "utDiffuse1" );
            }

            if( flags & ShaderLocation.FLAG_W )
            {
                getUniform( "unWeight" );
            }

            // Common to all shaders
            shader.umProj  = gl.getUniformLocation( program, "umProj" );
            shader.umView  = gl.getUniformLocation( program, "umView" );
            shader.program = program;

            Widget.glShaders[ iShader ] = shader;
        }
    }

    /**
     * @memberof Widget
     * @param {String} hexColor - '#rrggbb' or '#rgb'
     * @returns Float[4]
     */
    Widget.glColorHexToFloat = function( hexColor )
    {
        var retval = [1, 1, 1, 1], len = hexColor && hexColor.length || 0;

        if( ((len === 7) || (len === 4)) && hexColor[0] === '#' )
        {
            var log2base  = ( len === 7 ) ? 8 : 4;
            var base      = 1 << log2base;
            var num       = parseInt( hexColor.substring( 1 ), 16 );
            var normalize = 1 / (base - 1);

            for( var i = 2; i >= 0; i-- )
            {
                retval[i] = (num % base) * normalize;
                num     >>= log2base;
            }
        }
        else
            console.log( "ERROR -> did not receive a well formed hex color = " + hexColor );

        return retval;
    };

    Widget.glInit = function()
    {
        var canvas, gl, i, resolver;

        resolver = function( resolve, reject )
        {
            if( _x2._config._render !== Config.RENDER_WEBGL )
                return reject(); // "silent error" -- DOM already active

            canvas                       = document.createElement( 'canvas' );
            canvas.id                    = "WebGLroot";
            canvas.width                 = _x2._config._screenW; // canvas.clientWidth
            canvas.height                = _x2._config._screenH; // canvas.clientHeight
            canvas.style.left            = "0px";
            canvas.style.top             = "0px";
            canvas.style.position        = "absolute";
            document.body.appendChild( canvas );
            Widget.glCanvas              = canvas; // x2.init() needs to remove if WebGL fails

            // https://www.khronos.org/registry/webgl/specs/1.0/#5.2.1
            gl = canvas.getContext( "webgl", { stencil:true } );

            if( !gl )
                return reject( 'Unable to create WebGL context' ); // fallback to DOM

            // SHADER_FONT needs partial derivative fwidth() for sharpness at scale > 1.0
            gl.getExtension( "OES_standard_derivatives" );

            Widget.gl    = gl;
            Widget.glW   = canvas.width;
            Widget.glH   = canvas.height;
            Widget.glOOH = 1 / Widget.glH;

            Widget.glStencilId = 0;

            Widget.glClearR = 0;
            Widget.glClearG = 0;
            Widget.glClearB = 0;
            Widget.glClearA = 0;

            Widget.glProjDepth = 0;
            Widget.glViewDepth = 0;

            Widget.glProjMatrix = [];
            Widget.glViewMatrix = [];

            Widget.glProjMatrix[0] = new Float32Array( [ 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1 ] );

            for( i = 0; i < 32; ++i ) // pre-allocate matrix stack
                Widget.glViewMatrix[ i ] = new Float32Array( [ 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1 ] );

            glShadersInit();

            gl.disable( gl.DEPTH_TEST );
            gl.disable( gl.STENCIL_TEST ); // ScrollPane will enable/disable as needed

            gl.enable( gl.BLEND );
            gl.blendFunc( gl.ONE, gl.ONE_MINUS_SRC_ALPHA ); // premultiplied alpha

            // https://www.opengl.org/wiki/Face_Culling
            gl.frontFace( gl.CCW ); // Default: GL_CCW
            gl.disable( gl.CULL_FACE ); // Default: disable

            gl.clearColor( Widget.glClearR, Widget.glClearG, Widget.glClearB, Widget.glClearA );
            gl.clearStencil( 0 );

            gl.viewport( 0, 0, Widget.glW, Widget.glH );
            glOrtho( 0, Widget.glW, Widget.glH, 0, -100, +100 );

            gl.clear( gl.COLOR_BUFFER_BIT | gl.STENCIL_BUFFER_BIT );
            gl.flush();

            Widget.glFonts         = {};
            Widget.glFonts.bold    = new GlFont( "Bold"    );
            Widget.glFonts.light   = new GlFont( "Light"   );
            Widget.glFonts.medium  = new GlFont( "Medium"  );
            Widget.glFonts.regular = new GlFont( "Regular" );
            Widget.glFonts.thin    = new GlFont( "Thin"    );

            Promise.all([
                Widget.glFonts.light  .load(),
                Widget.glFonts.medium .load(),
                Widget.glFonts.bold   .load(),
                Widget.glFonts.regular.load(),
                Widget.glFonts.thin   .load()
            ]).then( resolve ).catch( reject );
        };

        return new Promise( resolver );
    };

    /** Create an WebGL texture with clamp to edge and bilinear filtering
     * @memberof Widget
     * @param   {Number}      w       - width in pixels
     * @param   {Number}      h       - height in pixels
     * @param   {Uint8Array} [texels] - pixels to initialize texture with
     * @param   {Number}     [type]   - gl.ALPHA
     * @returns {WebGLTexture}
     * Note: Widget.glInit() must be called first
     */
    Widget.glMakeTexture = function( w, h, texels, type )
    {
        var gl = Widget.gl;
        var texture = gl.createTexture();

        if( type === undefined )
            console.error( "ERROR: WebGL unknown texture type" );

        gl.bindTexture( gl.TEXTURE_2D, texture );
        gl.pixelStorei( gl.UNPACK_ALIGNMENT, 1 );

        if( texels )
        {
            if( type === gl.RGBA ) // texels === new Image()
                gl.texImage2D( gl.TEXTURE_2D, 0, type, type, gl.UNSIGNED_BYTE, texels );
            else // texels === new Uint8Array()
                gl.texImage2D( gl.TEXTURE_2D, 0, type, w, h, 0, type, gl.UNSIGNED_BYTE, texels );
        }

        // http://www.khronos.org/registry/webgl/specs/latest/
        // http://www.khronos.org/webgl/wiki/WebGL_and_OpenGL_Differences
        // non-power-of-2 textures ARE supported IF no mipmaps and clamp to edge are used
        gl.texParameteri( gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR        );
        gl.texParameteri( gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR        );
        gl.texParameteri( gl.TEXTURE_2D, gl.TEXTURE_WRAP_S    , gl.CLAMP_TO_EDGE );
        gl.texParameteri( gl.TEXTURE_2D, gl.TEXTURE_WRAP_T    , gl.CLAMP_TO_EDGE );

        gl.bindTexture( gl.TEXTURE_2D, null );

        return texture;
    };

    /**
     * Add child widget to parent
     * @memberof Widget
     * @param {Widget}  widget - Child widget
     * @param {Number} [x]     - Offset from left. Defaults to 0 px.
     * @param {Number} [y]     - Offset from top.  Defaults to 0 px.
     * @memberof Widget
     */
    Widget.prototype.addWidget = function( widget, x, y )
    {
        widget._parent = this;

        if( !x )
            x = 0;

        if( !y )
            y = 0;

        widget._curVal[Widget.X] = x;
        widget._curVal[Widget.Y] = y;

        widget._endVal[Widget.X] = x;
        widget._endVal[Widget.Y] = y;

        this._children.push( widget );
    };

    /** Starts an animation on the specified axis.
     * @param {Object}    params
     * @param {Number}   [params.a]         - Opacity; 0.0 = invisible, 1.0 = visible
     * @param {Number}   [params.alpha]     - Opacity; 0.0 = invisible, 1.0 = visible
     * @param {String}   [params.color]     - HTML 6 hex digit color
     * @param {Number}   [params.duration]  - Length of animation in milliseconds. Default is 0 ms.
     * @param {Easing}   [params.easing]    - Type of easing between start and end. Don't use Easing.LINEAR; default is Easing.QUAD_OUT if not specified
     * @param {Number}   [params.fontSize]  - Size of font in pixels
     * @param {Function} [params.onEnd]     - Callback to trigger when animation is complete
     * @param {Axis}     [params.onEndAxis] - Which axis the callback should be triggered on
     * @param {Function} [params.onInc]     - Callback to trigger while animation is active
     * @param {Number}   [params.x]         - Offset from left edge
     * @param {Number}   [params.y]         - Offset from top edge
     * @param {Number}   [params.w]         - Width
     * @param {Number}   [params.h]         - Height
     * @param {Number}   [params.xScale]    - 1.0 no scaling
     * @param {Number}   [params.yScale]    - 1.0 no scaling
     * @memberof Widget
     */
    Widget.prototype.animate = function( params )
    {
        if( params.duration === undefined || params.duration === 0 )
        {
            if( params.a !== undefined )
                this.setVal( Widget.ALPHA, params.a );

            if( params.alpha !== undefined )
                this.setVal( Widget.ALPHA, params.alpha );

            if( params.color !== undefined )
                this.setVal( Widget.FONT_COLOR, params.color );

            if( params.fontSize !== undefined )
                this.setVal( Widget.FONT_SIZE, params.fontSize );

            if( params.h !== undefined )
                this.setVal( Widget.H, params.h );

            if( params.w !== undefined )
                this.setVal( Widget.W, params.w );

            if( params.x !== undefined )
                this.setVal( Widget.X, params.x );

            if( params.xScale !== undefined )
                this.setVal( Widget.X_SCALE, params.xScale );

            if( params.y !== undefined )
                this.setVal( Widget.Y, params.y );

            if( params.yScale !== undefined )
                this.setVal( Widget.Y_SCALE, params.yScale );

            if( params.onEnd !== undefined )
                params.onEnd();
        }
        else
        {
            this._aniParams.push( params );
        }
    };

    /**
     * @memberof Widget
     */
    Widget.prototype.containsWidget = function( widget )
    {
        var retval = false;

        if( widget !== this )
        {
            for( var i = 0; i < this._children.length; i++ )
            {
                if( this._children[i].containsWidget( widget ) === true )
                {
                    retval = true;
                    break;
                }
            }
        }
        else
            retval = true;

        return retval;
    };

    Widget.prototype.destroy = function()
    {
        for( var i = 0; i < this._children.length; i++ )
            if( this._children[i].destroy )
                this._children[i].destroy();
    };

    Widget.prototype.enableMouseDownListener = function( bool )
    {
        if( _x2._config._cursorEnabled === true )
        {
            this._mouseDownListenerEnabled = bool;

            if( this._div )
            {
                this._div.style.pointerEvents = ( bool === true )? "auto" : "none";
            }
            else {
                window.setTimeout( function() {
                    this._div.style.pointerEvents = ( bool === true )? "auto" : "none";
                }.bind(this), 500 );
            }
        }
    };

    Widget.prototype.forceUpdate = function( bool )
    {
        this._forceUpdate = bool;
    };

    /**
     * Gets the absolute x and y position of a widget
     * @memberof Widget
     * @return {Object} { x: Number, y: Number }
     * @see getX(), getY()
     */
    Widget.prototype.getGlobalPos = function()
    {
        var retval = { x: 0, y: 0 };
        var widget = this;

        while( widget )
        {
            retval.x += widget.getVal( Widget.X );
            retval.y += widget.getVal( Widget.Y );
            widget    = widget._parent;
        }

        return retval;
    };

    Widget.prototype.getHostScreen = function()
    {
        var screen, widget = this._parent;

        while( widget )
        {
            if( widget instanceof Screen )
            {
                screen = widget;
                break;
            }
            widget = widget._parent;
        }

        return screen;
    };

    Widget.prototype.getA = function()
    {
        return this.getVal( Widget.ALPHA );
    };

    Widget.prototype.getEndA = function()
    {
        return this._endVal[Widget.Axis.A];
    };

    Widget.prototype.getH = function()
    {
        return this.getVal( Widget.H );
    };

    function getRoleStr( speakRole )
    {
        var retval;

        switch( speakRole )
        {
            case "button":
                retval = "Button";
                break;

            case "listitem":
            case "_filter":
            case "_radio":
                break;

            default:
                if( speakRole )
                    console.warn( "Need to do something for speakRole = " + speakRole );
                break;
        }

        return retval;
    }

    Widget.prototype.getSpeechCustomRole = function()
    {
        return this._customSpeakRole;
    };

    Widget.prototype.getVal = function( axis )
    {
        var retval = this._endVal[axis];  // only return end value, not intermediate value???

        if( _x2._config._render === Config.RENDER_DOM )
        {
            if( this._div ) // && (_css == true || retval == undefined) )
                switch( axis )
                {
                    case Widget.ALPHA:
                        retval = parseFloat( this._div.style.opacity );
                        break;

                    case Widget.FONT_COLOR:
                        if( this._div.color )
                            retval = this._div.color;
                        break;

                    case Widget.FONT_SIZE:
                        if( this._div.style.fontSize )
                            retval = parseInt( this._div.style.fontSize );
                        break;

                    case Widget.H:
                        retval = parseFloat( this._div.offsetHeight );
                        break;

                    case Widget.OVERFLOW:
                        retval = this._div.style.overflow;
                        break;

                    case Widget.W:
                        retval = parseFloat( this._div.offsetWidth );
                        break;

                    case Widget.X:
                        retval = parseFloat( this._div.style.left );
                        break;

                    case Widget.Y:
                        retval = parseFloat( this._div.style.top );
                        break;
                }
        }
        else if( _x2._config._render === Config.RENDER_WEBGL )
        {
            retval = this._curVal[axis];  // intermediate value
        }

        return retval;
    };

    Widget.prototype.getW = function()
    {
        return this.getVal( Widget.W );
    };

    Widget.prototype.getWhiteSpace = function()
    {
        return this.getVal( Widget.TEXT_WHITE_SPACE );
    };

    Widget.prototype.getX = function()
    {
        return this.getVal( Widget.X );
    };

    Widget.prototype.getY = function()
    {
        return this.getVal( Widget.Y );
    };

    Widget.prototype.gotFocus = function( losingFocus )
    {
        if( this._onGotFocus )
            this._onGotFocus( losingFocus );
    };

    Widget.prototype.hasFocusListener = function()
    {
        return this._onGotFocus !== undefined;
    };

    /**
     * Initializer
     * @memberof Widget
     * @param {Object} [params]
     * @param {String} [params.name] - Class name over-ride
     * @returns {Widget}
     */
    Widget.prototype.init = function( params ) //TODO: get rid of params. we only need a onEnter callback.
    {
        if( params && params.name )
            this._className = params.name;

        if( this._className === undefined )
            this._className = "Widget";

//      Widget.OBJ[ this._uid = Widget.ID++ ] = this; // DEBUG Widget Animation Trace & WebGL Debug Widget Inspector

        this._selectable = false;
        this._children   = [];
        this._aniParams  = [];
        this._startTime  = new Array( Widget.NUM_VALS );
        this._startVal   = new Array( Widget.NUM_VALS );
        this._duration   = new Array( Widget.NUM_VALS );
        this._easing     = new Array( Widget.NUM_VALS );
        this._endVal     = new Array( Widget.NUM_VALS );
        this._onEnd      = new Array( Widget.NUM_VALS );
        this._onInc      = new Array( Widget.NUM_VALS );

        this._endVal[Widget.ALPHA]   = 1;
        this._endVal[Widget.X]       = 0;
        this._endVal[Widget.X_SCALE] = 1;
        this._endVal[Widget.Y]       = 0;
        this._endVal[Widget.Y_SCALE] = 1;

        this._curVal = this._endVal.slice(); // Note: WebGL only as DOM uses _div for current values

        if( params )
        {
            if( params.onEnter )
                this._onEnter = params.onEnter;
        }

        return this;
    };

    Widget.prototype.isAnimating = function( axis )
    {
        var i, retval = false;

        if( axis !== undefined )
        {
            if( this._startTime[axis] !== undefined )
                retval = true;
        }
        else
        {
            for( i = 0; i < Widget.NUM_VALS; i++ )
                if( this._startTime[i] !== undefined )
                {
                    retval = true;
                    break;
                }
        }

        return retval;
    };

    Widget.prototype.lostFocus = function( gettingFocus )
    {
        if( this._onLostFocus )
            this._onLostFocus( gettingFocus );
    };

    Widget.prototype.onEnter = function()
    {
        if( this._onEnter )
            this._onEnter();
    };

    Widget.prototype.patchWidget = function()
    {
        this.resourcesLoad();
        this.resourcesLoaded();
    };

    Widget.prototype.processEvent = function( val, type )
    {
        var retval = false;  // default

//         switch( val )
//         {
//             case KEY_DOWN:
//                 if( this._downListener && type === KEY_PRESSED )
//                 {
//                     retval = true;
//                     _x2.requestFocus( this._downListener, val );
//
//                 }
//
//                 //NOTE: _downListenerFn should be independent of _downListener. there are situations where we want to call a function but don't have a focus listener
//                 if( this._downListenerFn && type === KEY_PRESSED )
//                     this._downListenerFn();
//
//                 break;
//
//             case KEY_LEFT:
//                 if( this._leftListener && type === KEY_PRESSED )
//                 {
//                     retval = true;
//                     _x2.requestFocus( this._leftListener, val );
//
//                     if( this._leftListenerFn )
//                         this._leftListenerFn();
//                 }
//                 break;
//
//             case KEY_RIGHT:
//                 if( this._rightListener && type === KEY_PRESSED )
//                 {
//                     retval = true;
//                     _x2.requestFocus( this._rightListener, val );
//
//                     if( this._rightListenerFn )
//                         this._rightListenerFn();
//                 }
//                 break;
//
//             case KEY_UP:
//                 if( this._upListener && type === KEY_PRESSED )
//                 {
//                     retval = true;
//                     _x2.requestFocus( this._upListener, val );
//
//                 }
//
//                 if( this._upListenerFn && type === KEY_PRESSED )
//                     this._upListenerFn();
//
//                 break;
//         }

        return retval;
    };

    Widget.prototype.removeAllWidgets = function()
    {
        this._children = [];

        if( _x2._config._render === Config.RENDER_DOM )
        {
            if( this._div )
                while( this._div.childNodes.length > 0 )
                    this._div.removeChild( this._div.childNodes[0] );
        }
    };

    Widget.prototype.removeWidget = function( child )
    {
        var index = -1;
        var type  = typeof( child );
        var widget;

        if( this._children.length > 0 )
        {
            if( type === "object" )
            {
                for( var i = 0; i < this._children.length; i++ )
                    if( this._children[i] === child )
                    {
                        index  = i;
                        widget = this._children[i];
                        break;
                    }
            }
            else if( type === "number" )
            {
                index  = child;
                widget = this._children[index];
            }
            else
                console.error( "ERROR -> don't know how to remove type = " + type );

            if( index >= 0 && index < this._children.length )
                this._children.splice( index, 1 );
            else
                console.error( "ERROR -> attempting to remove an invalid index = " + index + ", num children = " + this._children.length );

            if( widget )
            {
                if( _x2._config._render === Config.RENDER_DOM )
                    if( widget._div )
                        this._div.removeChild( widget._div );

                widget.destroy();
            }
        }
        else
            console.error( "ERROR -> trying to remove from a widget with no children, child = " + child );
    };

    Widget.prototype.resourcesLoad = function()
    {
        if( _x2._config._render === Config.RENDER_DOM )
        {
            if( this._div === undefined )
                this._div = document.createElement( 'div' );

            if( this._className )
                this._div.id = this._className;

            this._curVal[Widget.ALPHA] = this._endVal[Widget.ALPHA];

            this._div.style.position  = 'absolute';
            this._div.style.opacity   = this._endVal[Widget.ALPHA];
            this._div.style.left      = this._endVal[Widget.X] + "px";
            this._div.style.top       = this._endVal[Widget.Y] + "px";
            this._div.style.transform = "scale(" + this._endVal[Widget.X_SCALE] + "," + this._endVal[Widget.Y_SCALE] + ")";

            if( this._endVal[Widget.W] !== undefined )
                this._div.style.width = this._endVal[Widget.W] + "px";

            if( this._endVal[Widget.H] !== undefined )
                this._div.style.height = this._endVal[Widget.H] + "px";

            if( this._endVal[Widget.OVERFLOW] !== undefined )
                this._div.style.overflow = this._endVal[Widget.OVERFLOW];

            if( _x2._config._cursorEnabled === true )
            {
                if( this._clickListener )
                    this._div.onclick = this._clickListener;

                if( this._mouseDownListener )
                    this._div.onmousedown = this._mouseDownListener;

                if( this._mouseMoveListener )
                    this._div.onmousemove = this._mouseMoveListener;

                if( this._mouseOutListener )
                    this._div.onmouseout = this._mouseOutListener;

                if( this._mouseOverListener )
                    this._div.onmouseover = this._mouseOverListener;

                if( this._mouseEnterListener )
                    this._div.onmouseenter = this._mouseEnterListener;

                if( this._mouseUpListener )
                    this._div.onmouseup = this._mouseUpListener;

                if( this._mouseWheelListener )
                    this._div.onmousewheel = this._mouseWheelListener;

                if( (this._clickListener || this._mouseDownListener || this._mouseMoveListener || this._mouseUpListener || this._mouseWheelListener || this._mouseEnterListener ) && this._mouseDownListenerEnabled )
                    this._div.style.pointerEvents = "auto";
                else
                    this._div.style.pointerEvents = "none";
            }

            if( this._onClickEvent ) {
                this._div.classList.add('customClickEvent');
                this._div.customClickEvent = this._onClickEvent;
            }
            this._div.style.WebkitUserSelect = "none";
            this._div.style.UserSelect       = "none";
            this._div.style.cursor           = "default";

            for( var i = 0; i < this._children.length; i++ )
                this._children[i].resourcesLoad( this._div );

            if( this._parent )
                this._parent._div.appendChild( this._div );
        }
        else if( _x2._config._render === Config.RENDER_WEBGL )
        {
            for( i = 0; i < this._children.length; ++i )
                this._children[i].resourcesLoad( this );
        }
    };

    Widget.prototype.resourcesLoaded = function()
    {
        for( var i = 0; i < this._children.length; i++ )
            if( this._children[i].resourcesLoaded )
                this._children[i].resourcesLoaded();
    };

    Widget.prototype.resourcesUnload = function()
    {
        for( var i = 0; i < this._children.length; i++ )
            if( this._children[i].resourcesUnload )
                this._children[i].resourcesUnload();
    };

    Widget.prototype.setA = function( val )
    {
//      Widget.animTraceSet( this, Widget.ALPHA, val ); // DEBUG Widget Animation Trace
        this.setVal( Widget.ALPHA, val );
        return this;
    };

    Widget.prototype.setClickListener = function( listener )
    {
        if( _x2._config._cursorEnabled === true )
        {
            this._clickListener = listener;

            if( this._div )
                this._div.onclick = listener;
            else {
                window.setTimeout( function() {
                    this._div.onclick = listener;
                }.bind(this), 500 );
            }

            this.enableMouseDownListener( true );
        }
    };

    Widget.prototype.setFocusListeners = function( onGotFocus, onLostFocus )
    {
        this._onGotFocus  = onGotFocus;
        this._onLostFocus = onLostFocus;
    };

    Widget.prototype.setH = function( val )
    {
//      Widget.animTraceSet( this, Widget.H, val ); // DEBUG Widget Animation Trace
        this.setVal( Widget.H, val );
        return this;
    };

    Widget.prototype.setMouseDownListener = function( listener )
    {
        if( listener ) {
            this._onClickEvent = listener;
        }

        if( _x2._config._cursorEnabled === true )
        {
            this._mouseDownListener = listener;

            if( this._div )
                this._div.onmousedown = listener;

            this.enableMouseDownListener( true );
        }
    };

    Widget.prototype.setMouseMoveListener = function( listener )
    {
        if( _x2._config._cursorEnabled === true )
        {
            this._mouseMoveListener = listener;

            if( this._div )
                this._div.onmousemove = listener;
            else {
                window.setTimeout( function() {
                    this._div.onmousemove = listener;
                }.bind(this), 500);
            }

            this.enableMouseDownListener( true );
        }
    };

    Widget.prototype.setMouseOutListener = function( listener )
    {
        if( _x2._config._cursorEnabled === true )
        {
            this._mouseOutListener = listener;

            if( this._div )
                this._div.onmouseout = listener;
            else {
                window.setTimeout( function() {
                    this._div.onmouseout = listener;
                }.bind(this), 500);
            }

            this.enableMouseDownListener( true );
        }
    };

    Widget.prototype.setMouseOverListener = function( listener )
    {
        if( _x2._config._cursorEnabled === true )
        {
            this._mouseOverListener = listener;

            if( this._div )
                this._div.onmouseover = listener;
            else {
                window.setTimeout( function() {
                    this._div.onmouseover = listener;
                }.bind(this), 500);
            }

            this.enableMouseDownListener( true );
        }
    };

    Widget.prototype.setMouseEnterListener = function( listener )
    {
        if( _x2._config._cursorEnabled === true )
        {
            this._mouseEnterListener = listener;

            if( this._div )
                this._div.onmouseenter = listener;
            else {
                window.setTimeout( function() {
                    this._div.onmouseenter = listener;
                }.bind(this), 500);
            }

            this.enableMouseDownListener( true );
        }
    };

    Widget.prototype.setMouseUpListener = function( listener )
    {
        if( _x2._config._cursorEnabled === true )
        {
            this._mouseUpListener = listener;

            if( this._div )
                this._div.onmouseup = listener;

            this.enableMouseDownListener( true );
        }
    };

    Widget.prototype.setMouseWheelListener = function( listener )
    {
        if( _x2._config._cursorEnabled === true )
        {
            this._mouseWheelListener = listener;

            if( this._div )
                this._div.onwheel = listener;

            this.enableMouseDownListener( true );
        }
    };

    Widget.prototype.setScale = function( val )
    {
        this.setVal( Widget.X_SCALE, val );
        this.setVal( Widget.Y_SCALE, val );
    };

    Widget.prototype.setScaleX = function( val )
    {
        this.setVal( Widget.X_SCALE, val );
    };

    Widget.prototype.setScaleY = function( val )
    {
        this.setVal( Widget.Y_SCALE, val );
    };

    Widget.prototype.setSpeechCustomRole = function( speakRole )
    {
        this._customSpeakRole = speakRole;
    };

    Widget.prototype.setSpeechParams = function( speakLabel, speakRole, focusTarget, noFocus, selected )
    {
        if( this._div && _x2._config._speech === true )
        {
            var role  = getRoleStr( speakRole );
            var isStr = this instanceof StringWidget;

            if( speakRole === "_filter" )
            {
                speakLabel = "Filter. Selected";
                speakRole  = "listitem";
            }
            else if( speakRole === "_radio" )
            {
                speakLabel = "Button. " + (selected === true ? "Selected" : "Unselected");
                speakRole  = "listitem";
            }

            focusTarget._speakRole = speakRole;
            this._div.removeAttribute( "aria-label" );

            if( isStr === true && this.getText() )
                focusTarget._speakStr = this.getText().replace( /(<([^>]+)>)/ig, "" );
            else
                focusTarget._speakStr = undefined;

            if( speakLabel !== undefined )
            {
                if( focusTarget._speakStr )
                    focusTarget._speakStr += ". " + speakLabel;
                else
                    focusTarget._speakStr = speakLabel;

                if( _x2._modSpeech !== true && isStr === true && this.getText() )
                {
                    var str = this.getText().replace( /(<([^>]+)>)/ig, "" ) + " ";

                    if( role )
                        str += role + ". ";

                    this._div.setAttribute( "aria-label", str + speakLabel );
                }
                else
                    this._div.setAttribute( "aria-label", speakLabel );
            }

            if( _x2._modSpeech !== true && isStr === true && speakLabel !== undefined )
                this._div.setAttribute( "role", _x2._useListitem === true? "listItem" : undefined );
            else if( speakRole === undefined && _x2._useListitem === true )
                this._div.setAttribute( "role", "listitem" );
            else
                this._div.setAttribute( "role", speakRole );

            this._div.setAttribute( 'tabindex', '0' );
            this._div.style.outline = "none";

            if( noFocus !== true )
            {
                if( document.activeElement === this._div )
                    this._div.blur();

                this._div.focus();
            }
        }
    };

    Widget.prototype.setVal = function( axis, val )
    {
        this._endVal[axis] = val;

        if( _x2._config._render === Config.RENDER_DOM )
        {
            if( this._div )
            {
                switch( axis )
                {
                    case Widget.ALPHA:
                        this._div.style.opacity = val;
                        this._curVal[axis]      = val;
                        break;

                    case Widget.FONT_COLOR:
                        this._div.style.color = val;
                        break;

                    case Widget.FONT_SIZE:
                        this._div.style.fontSize = val + "px";
                        break;

                    case Widget.H:
                        this._div.style.height =  ( val === undefined ) ? "" : val + "px";
                        break;

                    case Widget.TEXT_WHITE_SPACE:
                        this._div.style.whiteSpace = val;
                        break;

                    case Widget.OVERFLOW:
                        this._div.style.overflow = val;
                        break;

                    case Widget.W:
                        this._div.style.width = ( val === undefined ) ? "" : val + "px";
                        break;

                    case Widget.X:
                        this._div.style.left = val + "px";
                        break;

                    case Widget.X_SCALE:
                        this._div.style.transform = "scale(" + val + "," + this._endVal[Widget.Y_SCALE] + ")";
                        break;

                    case Widget.Y:
                        this._div.style.top = val + "px";
                        break;

                    case Widget.Y_SCALE:
                        this._div.style.transform = "scale(" + this._endVal[Widget.X_SCALE] + "," + val + ")";
                        break;
                }
            }
        }
        else if( _x2._config._render === Config.RENDER_WEBGL )
        {
            this._startVal[axis] = val;
            this._curVal  [axis] = val;
        }
    };

    Widget.prototype.setW = function( val )
    {
//      Widget.animTraceSet( this, Widget.W, val ); // DEBUG Widget Animation Trace
        this.setVal( Widget.W, val );
        return this;
    };

    Widget.prototype.setWhiteSpace = function( val )
    {
        this.setVal( Widget.TEXT_WHITE_SPACE, val );
        return this;
    };

    Widget.prototype.setX = function( val )
    {
//      Widget.animTraceSet( this, Widget.X, val ); // DEBUG Widget Animation Trace
        this.setVal( Widget.X, val );
        return this;
    };

    Widget.prototype.setY = function( val )
    {
//      Widget.animTraceSet( this, Widget.Y, val ); // DEBUG Widget Animation Trace
        this.setVal( Widget.Y, val );
        return this;
    };

    /**
     * @memberof Widget
     * @param {Number}  [axis]         - Which axis to stop animating, or all of them if undefined
     * @param {Boolean} [callEnd=true] - If the axis has an end callback, should we call it
     */
    Widget.prototype.stopAnimation = function( axis, callEnd )
    {
        var i;

        if( axis !== undefined )
        {
            for( i = 0; i < this._aniParams.length; i++ )
                delete this._aniParams[i][Widget.Axis.Str[axis]];

            this._startTime[axis] = undefined;

            if( callEnd === true || callEnd === undefined )
            {
                if( this._onEnd[axis] )
                    this._onEnd[axis]();

                this._onEnd[axis] = undefined;
            }
        }
        else
        {
            this._aniParams = [];

            for( i = 0; i < Widget.Axis.NUM_VALS; i++ )
            {
                this._startTime[i] = undefined;

                if( callEnd === true || callEnd === undefined )
                {
                    if( this._onEnd[i] )
                        this._onEnd[i]();

                    this._onEnd[i] = undefined;
                }
            }
        }
    };

    Widget.prototype.interpolateColor = function( fraction, start, finish )
    {
        var retval;

        if( start && finish )
        {
            var startNum  = parseInt( start.substring ( 1 ), 16 );
            var finishNum = parseInt( finish.substring( 1 ), 16 );
            var r         = (((startNum & 0xff0000) + fraction * ((finishNum & 0xff0000) - (startNum & 0xff0000))) & 0xff0000) >> 16;
            var g         = (((startNum & 0x00ff00) + fraction * ((finishNum & 0x00ff00) - (startNum & 0x00ff00))) & 0x00ff00) >>  8;
            var b         = (((startNum & 0x0000ff) + fraction * ((finishNum & 0x0000ff) - (startNum & 0x0000ff))) & 0x0000ff);
            var rStr      = (r < 16) ? "0" + r.toString( 16 ) : r.toString( 16 );
            var gStr      = (g < 16) ? "0" + g.toString( 16 ) : g.toString( 16 );
            var bStr      = (b < 16) ? "0" + b.toString( 16 ) : b.toString( 16 );

            retval = "#" + rStr + gStr + bStr;
        }

        return retval;
    };

    Widget.prototype.update = function( step )
    {
        var child, axis, val, params, xScale, yScale, w, h, curVal;
        var p, v, t, m, k;

        while( params = this._aniParams.shift() )
        {
            for( var key in params )
            {
                val = params[key];

                switch( key )
                {
                    case "a":
                    case "alpha":
                        axis = Widget.ALPHA;
                        break;

                    case "color":
                        axis = Widget.FONT_COLOR;
                        break;

                    case "fontSize":
                        axis = Widget.FONT_SIZE;
                        break;

                    case "h":
                        axis = Widget.H;
                        break;

                    case "w":
                        axis = Widget.W;
                        break;

                    case "x":
                        axis = Widget.X;
                        break;

                    case "xScale":
                        axis = Widget.X_SCALE;
                        break;

                    case "y":
                        axis = Widget.Y;
                        break;

                    case "yScale":
                        axis = Widget.Y_SCALE;
                        break;
                }

                if( axis !== undefined )
                {
                    this._startTime[axis] = params.start ? step + params.start : step;
                    this._startVal [axis] = this._endVal[axis];
                    this._duration [axis] = params.duration;
                    this._easing   [axis] = params.easing;
                    this._endVal   [axis] = val;
                    this._onEnd    [axis] = params.onEndAxis !== undefined ? ( axis === params.onEndAxis ? params.onEnd : undefined ) : params.onEnd;
                    this._onInc    [axis] = params.onInc;

                    // TODO: Decide if this is the bast place to set a valid start value for an animation of a widget axis.
                    // Don't like Widget having to be aware of ImageWidget or any other future widgets that can have a valid
                    // value of undefined. Should be cleaner.

                    if( this._startVal[axis] === undefined )
                    {
                        if( this instanceof ImageWidget )
                        {
                            if( axis === Widget.W )
                                this._startVal[axis] = this.getW();
                            else if( axis === Widget.H )
                                this._startVal[axis] = this.getH();
                            else
                            {
                                console.error( "ERROR -> animation initiated without a valid start value on axis = " + axis );
                                console.log( this );
                            }
                        }
                        else
                        {
                            console.error( "ERROR -> animation initiated without a valid start value on axis = " + axis );
                            console.log( this );
                        }
                    }
                }

                axis = undefined;
            }

            params = undefined;
        }

        for( axis = 0; axis < Widget.NUM_VALS; ++axis )
        {
            if( step > this._startTime[axis] )
            {
                if( step < (this._startTime[axis] + this._duration[axis]) )
                {
                    // In   : function(p) { return ...          }
                    // Out  : function(p) { return 1 - In(1-p); } // flipped Y - mirrored X
                    // InOut: function(p) { if (t < 1) return Left ;
                    //                      else       return Right; }
                    //        Left : remap p=[0,0.5] to p=[0,1] =       {1/2 * In (2*p)  } =       (0.5 * In (t  ));
                    //        Right: remap p=[0.5,1] to p=[0,1] = 1/2 + {1/2 * Out(2*p-1)} = 0.5 + (0.5 * Out(t-1));
                    //        Left and Right are joined together so they are continuous at p=0.5 -> v=0.5 (Exception is Elastic InOut)
                    // NOTE: Reference: cirrusTools/easing_verification/easing.js for original jQuery and optimized single-argument verification
                    p = (step - this._startTime[axis]) / this._duration [axis]; // normalized percent (usually named standard 't' blend param)
                    v =     0; // calculated blend value based on elapsed percentage: In(p), Out(p), InOut(p) -> In(2*p) or Out(2*p-1)
                    t = p * 2; // Times2 = EaseInOut: In(t) or Out(t-1); (t<1) = Left half, (t>1) = Right half
                    m = p - 1; // Minus  = shift x offset: p=<0,1> -> <-1,0>; common refactor term for 1+(1-p)^# and 1-(p-1)^#

                    // TODO: FIXME: Clamp (p<=0) v=0 (p>=1) v=1 ??
                    // if( p <= 0 ) v = 0;
                    // else
                    // if( p >= 1 ) v = 1;
                    // else
                    switch( this._easing[axis] || Widget.Easing.QUAD_OUT )
                    {
                        // NOTE: jQuery UI defines a non-standard 'Back' using "constants swapped" smoothstep via K=2: function Back( p ) { return p*p*(3*p - 2); },
                        // The magic Number K = 1.70158 is chosen where minimum of f(x) is -10%
                        case Widget.Easing.BACK_IN       : k = 1.70158        ;             v = p*p*(p*(k+1) - k);                                     break;
                        case Widget.Easing.BACK_IN_OUT   : k = 1.70158 * 1.525; if( t < 1 ) v = p*t*(t*(k+1) - k); else v = 1 + 2*m*m*(2*m*(k+1) + k); break; // Factored 0.5 out
                        case Widget.Easing.BACK_OUT      : k = 1.70158        ;                                         v = 1 +   m*m*(  m*(k+1) + k); break;

                        case Widget.Easing.BOUNCE_IN     :             v =     easeBounceIn( p );                                          break;
                        case Widget.Easing.BOUNCE_IN_OUT : if( t < 1 ) v = 0.5*easeBounceIn( t ); else v = 0.5 + 0.5*easeBounceOut( t-1 ); break;
                        case Widget.Easing.BOUNCE_OUT    :                                             v =           easeBounceOut(  p  ); break;

                        case Widget.Easing.CIRC_IN       :             v = 1-Math.sqrt( 1 - p*p   );                                              break;
                        case Widget.Easing.CIRC_IN_OUT   : if( t < 1 ) v = 1-Math.sqrt( 1 - t*t ); else v = Math.sqrt( 1 - 4*m*m ) + 1; v *= 0.5; break;  // Factored 0.5 out
                        case Widget.Easing.CIRC_OUT      :                                              v = Math.sqrt( 1 - m*m );                 break;

                        case Widget.Easing.ELASTIC_IN    : k = (40*m    - 3) * Math.PI/6 ; if( p <= 0 ) v = 0; else if( p >= 1 ) v = 1; else             v =       -Math.pow( 2, 10*m    ) * Math.sin( k );                                                           break;
                        case Widget.Easing.ELASTIC_IN_OUT: k = (80*(t-1)- 9) * Math.PI/18; if( p <= 0 ) v = 0; else if( p >= 1 ) v = 1; else if( t < 1 ) v = -0.5 * Math.pow( 2, 10*(t-1)) * Math.sin( k ); else v = 1 + 0.5*Math.pow( 2,-10*(t-1)) * Math.sin( k );  break;
                        case Widget.Easing.ELASTIC_OUT   : k = (40*-p   - 3) * Math.PI/6 ; if( p <= 0 ) v = 0; else if( p >= 1 ) v = 1; else                                                                     v = 1     +(Math.pow( 2, 10*-p   ) * Math.sin( k )); break;

                        // NOTE: jQuery UI mislabels 'sextic' as 'expo' which is different from Robert Penner's original 'expo'
                        case Widget.Easing.EXPO_IN       : if( p <= 0 ) v = 0; else             v = Math.pow( 2, 10*m );                                            break;
                        case Widget.Easing.EXPO_IN_OUT   : if( p <= 0 ) v = 0; else
                                                         if( p >= 1 ) v = 1; else if( t < 1 ) v = Math.pow( 2, 10*(t-1)-1); else v = 1-Math.pow( 2, -10*(t-1)-1); break; // Factored 0.5 out
                        case Widget.Easing.EXPO_OUT      : if( p >= 1 ) v = 1; else                                                v = 1-Math.pow( 2, -10*p );      break;

                        case Widget.Easing.LINEAR        : v = p;               break; // v=p^1
                        case Widget.Easing.QUAD_IN       : v = p*p;             break; // v=p^2 = Math.pow(p,2)
                        case Widget.Easing.CUBIC_IN      : v = p*p*p;           break; // v=p^3 = Math.pow(p,3)
                        case Widget.Easing.QUART_IN      : v = p*p*p*p;         break; // v=p^4 = Math.pow(p,4)
                        case Widget.Easing.QUINT_IN      : v = p*p*p*p*p;       break; // v=p^5 = Math.pow(p,5)
                        case Widget.Easing.SEXTIC_IN     : v = p*p*p*p*p*p;     break; // v=p^6 = Math.pow(p,6)
                        case Widget.Easing.SEPTIC_IN     : v = p*p*p*p*p*p*p;   break; // v=p^7 = Math.pow(p,7)
                        case Widget.Easing.OCTIC_IN      : v = p*p*p*p*p*p*p*p; break; // v=p^8 = Math.pow(p,8)

                        case Widget.Easing.QUAD_OUT      : v = 1-m*m;             break; // 1-(1-p)^2 = 1-(1-2p+p^2) = 1-(p-1)^2 = 1-m*m    Alternate:. p*(2-p)
                        case Widget.Easing.CUBIC_OUT     : v = 1+m*m*m;           break; // 1-(1-p)^3 = 1-(1-...p^3) = 1-(p-1)^3 = 1+m*m*m
                        case Widget.Easing.QUART_OUT     : v = 1-m*m*m*m;         break;
                        case Widget.Easing.QUINT_OUT     : v = 1+m*m*m*m*m;       break;
                        case Widget.Easing.SEXTIC_OUT    : v = 1-m*m*m*m*m*m;     break;
                        case Widget.Easing.SEPTIC_OUT    : v = 1+m*m*m*m*m*m*m;   break;
                        case Widget.Easing.OCTIC_OUT     : v = 1-m*m*m*m*m*m*m*m; break;

                        case Widget.Easing.QUAD_IN_OUT   : if( t < 1 ) v = p*t;             else v = 1-m*m            *  2; break;
                        case Widget.Easing.CUBIC_IN_OUT  : if( t < 1 ) v = p*t*t;           else v = 1+m*m*m          *  4; break;
                        case Widget.Easing.QUART_IN_OUT  : if( t < 1 ) v = p*t*t*t;         else v = 1-m*m*m*m        *  8; break;
                        case Widget.Easing.QUINT_IN_OUT  : if( t < 1 ) v = p*t*t*t*t;       else v = 1+m*m*m*m*m      * 16; break;
                        case Widget.Easing.SEXTIC_IN_OUT : if( t < 1 ) v = p*t*t*t*t*t;     else v = 1-m*m*m*m*m*m    * 32; break;
                        case Widget.Easing.SEPTIC_IN_OUT : if( t < 1 ) v = p*t*t*t*t*t*t;   else v = 1+m*m*m*m*m*m*m  * 64; break;
                        case Widget.Easing.OCTIC_IN_OUT  : if( t < 1 ) v = p*t*t*t*t*t*t*t; else v = 1-m*m*m*m*m*m*m*m*128; break;

                        case Widget.Easing.SINE_IN       : v =        1 - Math.cos( p * Math.PI*0.5 ) ; break;
                        case Widget.Easing.SINE_IN_OUT   : v = 0.5 * (1 - Math.cos( p * Math.PI     )); break;
                        case Widget.Easing.SINE_OUT      : v =            Math.sin( p * Math.PI*0.5 ) ; break;
                    }

                    val = this._startVal[axis] + v * (this._endVal[axis] - this._startVal[axis]);

/* // WebGL Animation Inspector
                    if( X2.SingleStep ) {
                        console.log( "#%d  @%d  $%d.Widget: %s  Axis: %s  Start: %o  End: %o  Delta: %o  v: %o  val: %o"
                            , X2.SingleStepFrame
                            , X2.SingleStepStartTime
                            , this._uid
                            , this._className
                            , Widget.Axis.Str[axis]
                            , this._startVal[axis]
                            , this._endVal[axis]
                            ,(this._endVal[axis] - this._startVal[axis])
                            , v
                            , val
                        );
                    }
// */
//                  Widget.animTraceLog( this, axis, val, false, step ); // DEBUG Widget Animation Trace

                    if( _x2._config._render === Config.RENDER_DOM  && this._div )
                    {
                        switch( axis )
                        {
                            case Widget.ALPHA:
                                curVal                  = this._curVal[Widget.ALPHA];
                                this._div.style.opacity = val;
                                this._curVal[axis]      = val;

                                if( this._deepHide === true )
                                {
                                    if( curVal !== 0 && val === 0 )
                                        this._div.style.display = "none";
                                    else if( curVal === 0 && val !== 0 )
                                        this._div.style.display = "";
                                }
                                break;

                            case Widget.FONT_COLOR:
                                this._div.style.color = this.interpolateColor( v, this._startVal[axis], this._endVal[axis] );
                                break;

                            case Widget.FONT_SIZE:
                                this._div.style.fontSize = val + "px";
                                break;

                            case Widget.H:
                                this._div.style.height = val + "px";
                                break;

                            case Widget.W:
                                this._div.style.width = val + "px";
                                break;

                            case Widget.X:
                                this._div.style.left = val + "px";
                                break;

                            case Widget.X_SCALE:
                                xScale = val;
                                break;

                            case Widget.Y:
                                this._div.style.top = val + "px";
                                break;

                             case Widget.Y_SCALE:
                                yScale = val;
                                break;
                        }
                    }
                    else if( _x2._config._render === Config.RENDER_WEBGL )
                    {
                        if( axis === Widget.FONT_COLOR )
                            this._curVal[axis] = this.interpolateColor( v, this._startVal[axis], this._endVal[axis] );
                        else
                            this._curVal[axis] = val; // Do NOT use setVal() as that sets endVal
                    }

                    if( this._onInc[axis] )
                        this._onInc[axis]( v );
                }
                else
                {
                    val = this._endVal[axis];

//                  Widget.animTraceLog( this, axis, val, true, step ); // DEBUG Widget Animation Trace

                    if( _x2._config._render === Config.RENDER_DOM && this._div )
                    {
                        switch( axis )
                        {
                            case Widget.ALPHA:
                                curVal                  = this._curVal[Widget.ALPHA];
                                this._div.style.opacity = val;
                                this._curVal[axis]      = val;

                                if( this._deepHide === true )
                                {
                                    if( curVal !== 0 && val === 0 )
                                        this._div.style.display = "none";
                                    else if( curVal === 0 && val !== 0 )
                                        this._div.style.display = "";
                                }
                                break;

                            case Widget.FONT_COLOR:
                                this._div.style.color = val;
                                break;

                            case Widget.FONT_SIZE:
                                this._div.style.fontSize = val + "px";
                                break;

                            case Widget.H:
                                this._div.style.height = val + "px";
                                break;

                            case Widget.W:
                                this._div.style.width = val + "px";
                                break;

                            case Widget.X:
                                this._div.style.left = val + "px";
                                break;

                            case Widget.X_SCALE:
                                xScale = val;
                                break;

                            case Widget.Y:
                                this._div.style.top = val + "px";
                                break;

                            case Widget.Y_SCALE:
                                yScale = val;
                                break;
                        }
                    }
                    else if( _x2._config._render === Config.RENDER_WEBGL )
                    {
                        this._curVal[axis] = val;
                    }

                    this._startTime[axis] = undefined;

                    if( this._onEnd[axis] )
                        this._onEnd[axis]();

                    this._onEnd[axis] = undefined;
                }

                if( xScale !== undefined || yScale !== undefined )
                {
                    if( xScale === undefined )
                        xScale = 1;

                    if( yScale === undefined )
                        yScale = 1;

                    if( _x2._config._render === Config.RENDER_DOM )
                        this._div.style.transform = "scale(" + xScale + "," + yScale + ")";
                }
            }
        }

        for( child = 0; child < this._children.length; child++ )
            if( _x2._config._minDraw !== true || this._children[child]._forceUpdate === true || this._children[child]._curVal[0] > 0 || this._children[child]._endVal[0] > 0 || this._children[child]._aniParams.length > 0 )
                this._children[child].update( step );
    };

    Widget.prototype.glRender = function( alphaParent )
    {
        var child, val = this._curVal[ Widget.ALPHA ] * alphaParent, xScale, yScale, w, h;

        Widget.glViewMatrix[ Widget.glViewDepth + 1 ]  = Widget.glViewMatrix[ Widget.glViewDepth++ ].slice();
        Widget.glViewMatrix[ Widget.glViewDepth ][12] += this._curVal[ Widget.X ]; // current interpolated values, not end vals
        Widget.glViewMatrix[ Widget.glViewDepth ][13] += this._curVal[ Widget.Y ];

        xScale = this._curVal[Widget.X_SCALE];
        yScale = this._curVal[Widget.Y_SCALE];

        if( xScale !== 1 )
        {
            w = this._curVal[ Widget.W ] || 0;
            Widget.glViewMatrix[ Widget.glViewDepth ][ 0] *= xScale;
            Widget.glViewMatrix[ Widget.glViewDepth ][12] -= (xScale-1)*w*0.5;
        }

        if( yScale !== 1 )
        {
            h = this._curVal[ Widget.H ] || 0;
            Widget.glViewMatrix[ Widget.glViewDepth ][ 5] *= yScale;
            Widget.glViewMatrix[ Widget.glViewDepth ][13] -= (yScale-1)*h*0.5;
        }

/* WebGL Debug Widget Inspector
            if( _x2._config.log( Config.CLASS_WEBGL, 1 ) ) // WebGL Widget Inspector: ALT, [, ]
            {
                if( Widget.glDebugIdx === Widget.glDebugInspect )
                {
                    w = this.getW(); // _curVal[ Widget.W ];
                    h = this.getH(); // _curVal[ Widget.H ];

                    if( !w ) w = _x2._config._screenW;
                    if( !h ) h = _x2._config._screenH;

                    w--;
                    h--;

                    xScale = Widget.glViewMatrix[ Widget.glViewDepth ][12];
                    yScale = Widget.glViewMatrix[ Widget.glViewDepth ][13];

                    Widget.glDebugOutline[0].setX( xScale ); // Top
                    Widget.glDebugOutline[0].setY( yScale );
                    Widget.glDebugOutline[0].setW( w );

                    Widget.glDebugOutline[1].setX( xScale     ); // Bot
                    Widget.glDebugOutline[1].setY( yScale + h );
                    Widget.glDebugOutline[1].setW( w );

                    Widget.glDebugOutline[2].setX( xScale ); // Left
                    Widget.glDebugOutline[2].setY( yScale );
                    Widget.glDebugOutline[2].setH( h );

                    Widget.glDebugOutline[3].setX( xScale + w ); // Right
                    Widget.glDebugOutline[3].setY( yScale );
                    Widget.glDebugOutline[3].setH( h );
                }

                if( Widget.glWidgetCapture )
                    Widget.glWidgetsRendered.push( this );

                Widget.glDebugIdx++;
            }
// */
        // Note: Every Widget that has a render() also has Widget.prototype.constructor.SHADER
        // except for ScrollWidget which has a dummy ScrollWidget.prototype.constructor.SHADER
        if( this.render && (val > 0) )
        {
            Widget.glShaderPrev  = Widget.glShaderNext;
            Widget.glShaderNext  = this.constructor.SHADER;
            if( Widget.glShaderNext != Widget.glShaderPrev )
            {
                Widget.glShaderThis = Widget.glShaders[ Widget.glShaderNext ];
                Widget.gl.useProgram( Widget.glShaderThis.program );
            }

            Widget.gl.uniformMatrix4fv( Widget.glShaderThis.umProj, false, Widget.glProjMatrix[ Widget.glProjDepth ] );
            Widget.gl.uniformMatrix4fv( Widget.glShaderThis.umView, false, Widget.glViewMatrix[ Widget.glViewDepth ] );

            this.render( val );
        }

        for( child = 0; child < this._children.length; child++ )
            this._children[child].glRender( val ); // val = current alpha --> parent's alpha of child

        if( this.postRender && (val > 0) )
            this.postRender( val );

        --Widget.glViewDepth;
    };

    return Widget;

})();
