// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

window.MultiStateButtonTestScreen = ( function()
{
    "use strict";

    MultiStateButtonTestScreen.prototype = new Screen();

    function MultiStateButtonTestScreen(){}

    MultiStateButtonTestScreen.prototype.gotFocus = function()
    {
        _x2.requestFocus( this._buttons[this._index] );
    };

    MultiStateButtonTestScreen.prototype.init = function()
    {
        Screen.prototype.init.call( this );
        this._className = "MultiStateButtonTestScreen";

        this._index   = 0;
        this._buttons = [];

        var onEnter = function( state )
        {
            console.log( "==============> STATE = " + state );
        };

        var buttonData =
        [
            {
                initial:0,
                onEnter:onEnter,
                label  :"Closed Captioning: ",
                images :[
                            _x2._config._imageRoot + "cc.png",
                            _x2._config._imageRoot + "ccHi.png",
                            _x2._config._imageRoot + "ccSelected.png",
                            _x2._config._imageRoot + "ccSelectedHi.png"
                        ]
            },
            {
                initial:1,
                label  :"Video Description: ",
                images :[
                            _x2._config._imageRoot + "dv.png",
                            _x2._config._imageRoot + "dvHi.png",
                            _x2._config._imageRoot + "dvSelected.png",
                            _x2._config._imageRoot + "dvSelectedHi.png"

                        ]
            },
            {
                initial:0,
                label  :"Secondary Audio: ",
                images :[
                            _x2._config._imageRoot + "sap.png",
                            _x2._config._imageRoot + "sapHi.png",
                            _x2._config._imageRoot + "sapSelected.png",
                            _x2._config._imageRoot + "sapSelectedHi.png"
                        ]
            }
        ];

        for( var i = 0; i < buttonData.length; i++ )
        {
            this._buttons[i] = new MultiStateButtonWidget().init( buttonData[i] );

            this.addWidget( this._buttons[i], Style._safeLeft + i * 75, _x2._config._screenH / 4 );
        }

        return this;
    };

//     MultiStateButtonTestScreen.prototype.layout = function()
//     {
//         this._ring.setX( (this.getW() - this._ring.getW()) / 2 );
//         this._ring.setY( (this.getH() - this._ring.getH()) / 2 );
//
//         this._ready = true;
//     };

    MultiStateButtonTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._index > 0 )
                        _x2.requestFocus( this._buttons[--this._index] );
                }
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._index < this._buttons.length - 1 )
                        _x2.requestFocus( this._buttons[++this._index] );
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    return MultiStateButtonTestScreen;

})();
