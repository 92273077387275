// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

"use strict";

window.FormFieldOption = (function()
{
    function FormFieldOption(){}
    
    FormFieldOption.prototype.init = function( name, data )
    {
        this._name = name;
        this._data = data;
        
        return this;
    };
    
    FormFieldOption.prototype.getName = function()
    {
        return this._name;
    };
    
    FormFieldOption.prototype.getLabel = function()
    {
        return this._data.label;   
    };
    
    FormFieldOption.prototype.getOrder = function()
    {
        return this._data.order;
    };
    
    return FormFieldOption;
})();
