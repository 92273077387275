// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.AccessibilityScreen = ( function()
{
    "use strict";

    AccessibilityScreen.prototype = new Screen();

    function AccessibilityScreen(){}

    AccessibilityScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list, true );
        this._speakTarget.setText( "Accessibility. " + focus._speakStr + "." );
        this._speakTarget.setSpeechParams( "Press up or down to review settings. Press OK to select.", focus._speakRole, this, false );
    };

    AccessibilityScreen.prototype.init = function()
    {
        var i, listParams = {}, onCaptions, captionsStr, onDescription, descriptionStr, onCaptionOptions, rows, entries;

        Screen.prototype.init.call( this );
        this._className     = "AccessibilityScreen";
        this._telemetryName = "Accessibility";
        this.setBreadCrumbs( "Settings", "Accessibility" );


        onCaptions = function( widget )
        {
            var onEnter = function( on )
            {
                _x2._settings.setValue( Settings.Key.CC_ENABLED, on );
                widget.setValue( captionsStr() );
            };

            _x2.pushOverlay( new OnOffOverlay().init( { onEnter:onEnter, isOn:_x2._settings.getValue( Settings.Key.CC_ENABLED ) } ) );
        };

        captionsStr = function()
        {
            return _x2._settings.getValue( Settings.Key.CC_ENABLED ) === true ? "On" : "Off";
        };

        onCaptionOptions = function()
        {
            _x2.pushScreen( new CaptionsScreen().init() );
        };

        onDescription = function( widget )
        {
            var onEnter = function( on )
            {
                _x2._settings.setValue( Settings.Key.DV_ENABLED, on );
                widget.setValue( descriptionStr() );
            };

            _x2.pushOverlay( new OnOffOverlay().init( { onEnter:onEnter, isOn:_x2._settings.getValue( Settings.Key.DV_ENABLED ) } ) );
        };

        descriptionStr = function()
        {
            return _x2._settings.getValue( Settings.Key.DV_ENABLED ) === true ? "On" : "Off";
        };

        rows    = [];
        entries = _x2._config._accessibilityMenu;

        for( i = 0; i < entries.length; i++ )
        {
            switch( entries[i] )
            {
                case "captions":
                    rows.push( { title:"Caption Settings:", value:captionsStr(), onEnter:onCaptions } );
                    break;

                case "captionOptions":
                    rows.push( { title:"Closed Captioning Options", showIcon:true, onEnter:onCaptionOptions } );
                    break;

                case "videoDesc":
                    rows.push( { title:"Video Description:", value:descriptionStr(), onEnter:onDescription } );
                    break;

                default:
                    console.error( "ERROR -> unknown menu entry = " + entries[i] );
                    break;
            }
        }

        listParams.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 1;
        listParams.rows     = rows;
        listParams.type     = MenuRowWidget;

        this._list = new ListWidget().init( listParams );
        this.addWidget( this._list, Style._safeLeft );

        window.ll( "tagScreen", "Accessibility" );
        _x2._telemetry.sendScreenViewed( this._telemetryName );

        return this;
    };

    AccessibilityScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._list.setY( this.getHeaderBottom() );
        this._list.setH( _x2._config._screenH - this._list.getY() );
    };

    return AccessibilityScreen;

})();
