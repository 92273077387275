// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * Show the highlighted image when it has focus, else show the unfocused image.
 * @class
 */
window.DualImageWidget = ( function()
{
    "use strict";

    DualImageWidget.prototype = new Widget();

    function DualImageWidget(){}

    DualImageWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        Widget.prototype.gotFocus.call( this );
        this._image.animate  ( { duration:this._focusTime, xScale:1.0, yScale:1.0          } );
        this._imageHi.animate( { duration:this._focusTime, xScale:1.0, yScale:1.0, alpha:1 } );

        if( this._str )
            this._str.setSpeechParams( undefined, "button", this, newScreen );
    };

    DualImageWidget.prototype.imageLoaded = function()
    {
        this.setVal( Widget.W, this._image.getVal( Widget.W ) );
        this.setVal( Widget.H, this._image.getVal( Widget.H ) );

        this.callbackSignalReady();
    };

    /**
     * Initializer
     * @memberof DualImageWidget
     * @param   {Object}    params
     * @param   {Number}   [params.focusTime=0     ] - time in milliseconds
     * @param   {Number}   [params.lostFocusScale=1] - scale value when focus is lost
     * @param   {Function} [params.onEnter         ] - function to cll on enter
     * @param   {Function} [params.onFocus         ] - function to call on focus
     * @param   {String}   [params.speechName      ] - string to speak when using text-to-speech
     * @param   {String}    params.url               - URL of image when not focused
     * @param   {String}    params.urlHi             - URL of image when has focus
     * @returns {DualImageWidget}
     */
    DualImageWidget.prototype.init = function( params )
    {
        var self = this;

        Widget.prototype.init.call( this );
        this._className  = "DualImageWidget";
        this._selectable = true;

        if( params.onEnter )
        {
            this._onEnter = params.onEnter;
            this.setMouseDownListener( params.onEnter );
            this.setMouseEnterListener( function() {
                this._parent._children.forEach( function(siblingsWidget) {
                    siblingsWidget._imageHi.setA(0);
                });//only one image widget should get highlight at a time
                this._imageHi.setA( 1 );
            });

            this.setMouseOutListener( function() {
                this._imageHi.setA( 0 );
            });
        }

        if( params.onFocus )
            this.setFocusListeners( params.onFocus );

        this._focusTime      = ( params.focusTime      !== undefined ) ? params.focusTime      : 0;
        this._lostFocusScale = ( params.lostFocusScale !== undefined ) ? params.lostFocusScale : 1;

        this._image = new ImageWidget().init( { url:params.url, xScale:this._lostFocusScale, yScale:this._lostFocusScale, onLoad:function() { self.imageLoaded(); } } );
        this.addWidget( this._image );

        this._imageHi = new ImageWidget().init( { url:params.urlHi, xScale:this._lostFocusScale, yScale:this._lostFocusScale } );
        this._imageHi.setVal( Widget.ALPHA, 0 );
        this.addWidget( this._imageHi );

        if( params.speechName )
        {
            this._str = new StringWidget().init( { text:params.speechName, style:new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(18) } ) } );
            this._str.setA( 0 );
            this.addWidget( this._str );
        }

        return this;
    };

    DualImageWidget.prototype.lostFocus = function()
    {
        Widget.prototype.lostFocus.call( this );
        this._image.animate  ( { duration:this._focusTime, xScale:this._lostFocusScale, yScale:this._lostFocusScale          } );
        this._imageHi.animate( { duration:this._focusTime, xScale:this._lostFocusScale, yScale:this._lostFocusScale, alpha:0 } );
    };

    DualImageWidget.prototype.processEvent = function( val, type )
    {
        var retval;

        if( val === Host.KEY_ENTER && type === Host.KEY_PRESSED && this._onEnter )
        {
            this._onEnter();
            retval = true;
        }
        else
            retval = Widget.prototype.processEvent.call( this, val, type );

        return retval;
    };

    return DualImageWidget;

})();
