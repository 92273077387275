// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.HorizPillButtonTestScreen = ( function()
{
    "use strict";

    HorizPillButtonTestScreen.prototype = new Screen();

    function HorizPillButtonTestScreen(){}

    HorizPillButtonTestScreen.prototype.init = function()
    {
        var self = this;
        this._className = "HorizPillButtonTestScreen";

        Screen.prototype.init.call( this );

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        var onEnter = function()
        {
            _x2.requestFocus( self );
        };

        var style   = new Style( { color:"#e5e5e5", colorHi:"#000000", font:"light", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap" } );
        var string1 = new StringWidget().init( { text: "ABC", style: style, onEnter: onEnter } );
        var string2 = new StringWidget().init( { text: "123", style: style, onEnter: onEnter } );
        var string3 = new StringWidget().init( { text: "abc", style: style, onEnter: onEnter } );

        string1._selectable = true;
        string2._selectable = true;
        string3._selectable = true;

        var entries =
        [
            string1,
            string2,
            string3
        ];
        this._entries = entries;

        this._menu = new HorizPillButtonsWidget().init( { entries:entries, spacing:_x2.scaleValInt(60) } );
        this._menu.addReadyCallback ( function() { self.layout(); self._buttonsReady = true; } );

        var onGotFocus = function()
        {
//            self.animate( { y:0, duration:500 } );
        };
        var onLostFocus = function()
        {
//            self.animate( { y:-self.getH(), duration:500 } );
        };

        this._menu.setFocusListeners( onGotFocus, onLostFocus );
        this.addWidget( this._menu, Style._safeLeft, Style._safeTop );

        this._rectHasFocus = new RectWidget().init( { w: 300, h: 400, color: "#22AA22" } );
        this._rectNotFocus = new RectWidget().init( { w: 300, h: 400, color: "#AA2222" } );
        this._rectHasFocus.setA( 0 );
        this._rectNotFocus.setA( 0 );

        this._item = new Widget().init();
        this._item._selectable  = true;
        this._item._onGotFocus  = function() { self._rectHasFocus.setA( 1 ); self._rectNotFocus.setA( 0 ); };
        this._item._onLostFocus = function() { self._rectHasFocus.setA( 0 ); self._rectNotFocus.setA( 1 ); };
        this._item.addWidget( this._rectHasFocus );
        this._item.addWidget( this._rectNotFocus );
        this.addWidget( this._item, Style._safeLeft, Style._safeTop*3 );

        return this;
    };

    HorizPillButtonTestScreen.prototype.gotFocus = function()
    {
        _x2.requestFocus( this._item );
    };

    HorizPillButtonTestScreen.prototype.layout = function()
    {
        var y = this.getHeaderBottom();
        this._menu.setH( y );
        this._item.setY( y );

        _x2.requestFocus( this._item );
    };

    HorizPillButtonTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._menu.containsWidget( _x2._focus ) === false )
                        _x2.requestFocus( this._menu );
                    else
                        _x2.requestFocus( this._item );
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    return HorizPillButtonTestScreen;

})();

