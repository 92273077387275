// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.EpisodeScreen = (function()
{
    "use strict";

    EpisodeScreen.prototype = new Screen();

    function EpisodeScreen(){}

    EpisodeScreen.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();

        if( this._dataReady )
            this.speak( _x2.requestFocus( this._actionButtons ) );
    };

    /**
     * memberof EpisodeScreen
     * @param {Object} params
     * @param {BrowseEntity, Recording} params.entity     = entity to display
     * @param {BrowseEntity} params.infoButton - show the info button
     * @returns {EpisodeScreen}
     */
    EpisodeScreen.prototype.init = function( params )
    {
        Screen.prototype.init.call( this );
        this._className     = "EpisodeScreen";
        this._telemetryName = "Entity Episode";

        var self = this;
        var episode = ( params.entity instanceof Entity ? params.entity : params.entity.getEntity() );
        var infoButton = params.infoButton || params.entity instanceof Recording || params.entity instanceof Purchase || params.entity instanceof Purchase;
        var p = [];

        var w = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        var h = _x2._config._screenH - Style._safeBottom - 200;

        var onRefresh = function( updatedEntity )
        {
            if( updatedEntity )
                self._entityDetail.setData( updatedEntity );
        };

        // XC-13472
        if( episode && episode.getSeries )
        {
            var sNumber  = episode.getSeasonNumber();
            var epNumber = episode.getEpisodeNumber();
            var series   = episode.getSeries();
            var path     = series ? series.getTitle() : episode.getTitle();

            if( sNumber !== undefined || epNumber !== undefined )
            {
                path += " / Episodes";

                if( sNumber !== undefined )
                    path += " / Season " + sNumber;

                if( epNumber !== undefined )
                    path += " / Episode " + epNumber;
            }
            else
            {
                if( path === undefined && episode.getAirDateAsString )
                    path = "Airdate: " + episode.getAirDateAsString();
            }

            this.setBreadCrumbs( path );
        }
        else
            this.setBreadCrumbs( episode ? episode.getSeriesTitle() : params.entity.getTitle() ); //getTitle() or getSeriesTitle() ?

        this.addReadyCallback( function(){ self.layout(); } );

        this._entityDetail = new EntityInfoWidget().init( {} );
        this._entityDetail.setW( w );
        this._entityDetail.setH( h );
        this.addWidget( this._entityDetail, Style._safeLeft, 100 );

        this._actionButtons = new EntityActionButtonsWidget().init();
        this._actionButtons.setRefreshListener( onRefresh );
        this.addWidget( this._actionButtons, Style._safeLeft + _x2.scaleValInt( 350 ), 350 );

        this._distractor = new DistractorWidget().init( {} );
        this._distractor.addReadyCallback( function()
        {
            self._distractor.centerOnWidget( self );
            self._distractor.show();
        } );
        this.addWidget( this._distractor );

        var ready = function()
        {
            self.setData(params.entity, infoButton);
        };

        p.push(_x2.refreshResumePoints());

        //note: if we get a showing without a embedded entity, resolve the entity link (encodesCreativeWork) and patch the entity data
        if( episode === undefined )
        {
            var entityLink = params.entity.getLinkObject("encodesCreativeWork");
            if( entityLink )
            {
                var resolver = function( resolve, reject )
                {
                    var patchEntity = function( result )
                    {
                        params.entity.addPath( ["_embedded","encodesCreativeWork"], JSON.parse( result.data ) );
                        resolve()
                    };
                    entityLink.resolve().then(patchEntity).catch(reject);
                };
                p.push(new Promise(resolver));
            }
        }

        Promise.all(p).then(ready).catch(ready);

        return this;
    };

    EpisodeScreen.prototype.setData = function( episode, infoButton )
    {
        var onReady, onError, self = this;
        this._entityDetail.setData( episode );

        onReady = function()
        {
            self._actionButtons.setData( { entity:episode, infoButton:infoButton } );
            self._entityDetail.setData( episode );
            self._distractor.hide();

            if( _x2._focus === self )
                self.speak( _x2.requestFocus( self._actionButtons ) );

            self._dataReady = true;
        };

        onError = function( error )
        {
            self._distractor.hide();
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.COMMON } ) )
        };

        if( episode instanceof Entity )
            episode.fetchEntityOptions().then( onReady ).catch( onError );
        else if( episode instanceof LinearShowing )
            episode.fetchEpisodeRecording().then( onReady ).catch( onError );
        else
            onReady();

        if( _x2._telemetry.watchPathDepth() > 1 ) // Don't reset if we don't have a path (Saved/For You/Featured)
            _x2._telemetry.setWatchButton( "Entity Info" );
    };

    EpisodeScreen.prototype.layout = function()
    {
        var y = this.getHeaderBottom() + _x2.scaleValInt( 42 ); // XC-13188 space between Breadcrumb line separator

        this._entityDetail.setY( y );
        this._actionButtons.setY( y + _x2.scaleValInt( 490 ) ); // Same as VodEntityScreen ?
    };

    EpisodeScreen.prototype.speak = function( focus )
    {
        console.log( focus );

        this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( this._entityDetail.getSpeechStr() + "Press right or left to review program options. Press OK to select. ", focus._speakRole, this, false );
    };

    return EpisodeScreen;

})();
