// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.SportsTeamScreen = (function()
{
    "use strict";

    SportsTeamScreen.prototype = new Screen();

    function SportsTeamScreen(){}

    SportsTeamScreen.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();
    };

    /**
     * Init the widget
     * @param {BrowseEntity} entity
     * @return {SportsTeamScreen}
     */
    SportsTeamScreen.prototype.init = function( entity )
    {
        Screen.prototype.init.call( this );
        this._className     = "SportsTeamScreen";
        this._telemetryName = "Sports Team";
        this._browseEntity  = entity;

        var self = this;
        var team = new SportsTeam().init( { entityId:entity.getEntityId() } );

        var y = 0; // XC-13188: Can't use 'y = {Screen}.getHeaderBottom()'
        var x = Style._safeLeft;

        var yOffsets = [ y, y + _x2.scaleValInt( 80 ) , y + _x2.scaleValInt( 130 ), y + _x2.scaleValInt( 320 )];
        var xOffsets = [ x, x + _x2.scaleValInt( 340 ), x + _x2.scaleValInt( 840 ) ];

        var onPosterError = function()
        {
            var fallback = self._entity.getFallbackImageLink( 303, 404 );

            if( !fallback || ( self._posterImg.getUrl() === fallback ) ) //hide if no fallback or fallback == current
                self._posterImg.setA(0);
            else
                self._posterImg.setUrl (fallback );
        };

        var titleStyle = new Style( { font: "light"  , color: "#e8e8e8", fontSize: _x2.scaleValInt( 48 ), whiteSpace: "nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
        var textStyle  = new Style( { font: "regular", color: "#a3a3a3", fontSize: _x2.scaleValInt( 30 ), whiteSpace: "nowrap" } );

        //add poster
        this._titleStr  = new StringWidget().init( { style: titleStyle } );
        this._posterImg = new ImageWidget().init( { w:_x2.scaleValInt( 303 ), h:_x2.scaleValInt( 404 ), onError:onPosterError } );
        this._posterImg.setA(0);

        //add coach/venue/location/conference
        this._s1 = new StringWidget().init( { style: textStyle } );
        this._s2 = new StringWidget().init( { style: textStyle } );
        this._s3 = new StringWidget().init( { style: textStyle } );
        this._s4 = new StringWidget().init( { style: textStyle } );

        this._menu = new Widget().init();
        this._distractor = new DistractorWidget().init( {} );
        this._distractor.addReadyCallback( function()
        {
            self._distractor.centerOnWidget( self );
            self._distractor.show();
        } );

        this.addWidget( this._titleStr , xOffsets[1], yOffsets[0] );
        this.addWidget( this._s1       , xOffsets[1], yOffsets[1] );
        this.addWidget( this._s2       , xOffsets[1], yOffsets[2] );
        this.addWidget( this._s3       , xOffsets[2], yOffsets[1] );
        this.addWidget( this._s4       , xOffsets[2], yOffsets[2] );
        this.addWidget( this._menu     , xOffsets[1], yOffsets[3] );
        this.addWidget( this._posterImg, xOffsets[0], yOffsets[0] );
        this.addWidget( this._distractor );

        var error = function( error )
        {
            self._distractor.hide();
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.ENTITY } ) );
            console.log( error.toString() );
        };

        var setDetail = function( team )
        {
            var y      = self.getHeaderBottom() + _x2.scaleValInt( 42 ); // XC-13188 space between Breadcrumb line separator
            var league = team.getLeague() ? " (" + team.getLeague() + ")" : "";

            self.setBreadCrumbs( team.getName() );

            self._titleStr.setText( team.getName() + league );
            self._posterImg.setUrl( team.getImageLink(180,240) );

            self._s1.setY( y + yOffsets[1] );
            self._s2.setY( y + yOffsets[2] );
            self._s3.setY( y + yOffsets[1] );
            self._s4.setY( y + yOffsets[2] );

            self._s1.setText( "Coach: "      + ( team.getCoach()      ? team.getCoach()      : "NA" ) );
            self._s2.setText( "Venue: "      + ( team.getVenue()      ? team.getVenue()      : "NA" ) );
            self._s3.setText( "Location: "   + ( team.getLocation()   ? team.getLocation()   : "NA" ) );
            self._s4.setText( "Conference: " + ( team.getConference() ? team.getConference() : "NA" ) );

            self._speechStr = self._titleStr.getText() + ". " + self._s1.getText() + ". " + self._s2.getText() + ". " + self._s3.getText() + ". " + self._s4.getText() + ". ";

            self._posterImg.setA(1);

            self._entity = team;

            self.setActionButtons( _x2._favorites.isFavorite( team ) );

            self._distractor.hide();
        };

        team.getDetail().then(setDetail).catch(error);

        this.addReadyCallback( function(){ self.layout(); } );

        return this;
    };

    SportsTeamScreen.prototype.setActionButtons = function( isFavorite )
    {
        var buttonWidget, buttons = [];
        var self        = this;
        var team        = this._entity;
        var buttonStyle = new Style( { font: "light", color: "#e8e8e8", fontSize: _x2.scaleValInt( 30 ), whiteSpace: "nowrap", colorHi:"#000000" } );

        var selectReplays = function()
        {
            _x2._telemetry.setWatchButton( "Entity Replays" );
            if( self._entity )
                _x2.pushScreen( new EpisodesScreen().init( { entity:self._entity } ) );
        };

        var selectGames = function()
        {
            _x2._telemetry.setWatchButton( "Entity Games" );
            if( self._entity )
                _x2.pushScreen( new UpcomingScreen().init( { entity:self._entity, listings:team.getGames() } ) );
        };

        var selectUpcoming = function()
        {
            _x2._telemetry.setWatchButton( "Entity Upcoming" );
            if( self._entity )
                _x2.pushScreen( new UpcomingScreen().init( { entity:self._entity, listings:team.getUpcomingListings() } ) );
        };

        var onFavoriteError = function( error )
        {
            self._distractor.hide();
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.COMMON } ) );
        };

        var setFavorite = function()
        {
            self._distractor.show();
            _x2._favorites.addFavorite( self._entity ).then(
                function()
                {
                    self._distractor.hide();
                    self.setActionButtons( true );
                    _x2.localyticsSendFavoriteEntityOrViewed( self._entity, "Add" );
                    _x2.refreshFavorites();
                }
            ).catch( onFavoriteError );
        };

        var removeFavorite = function()
        {
            self._distractor.show();
            _x2._favorites.removeFavorite( self._entity ).then(
                function()
                {
                    self._distractor.hide();
                    self.setActionButtons( false );
                    _x2.localyticsSendFavoriteEntityOrViewed( self._entity, "Remove" );
                    _x2.refreshFavorites();

                    if( self._browseEntity._changeCallback )
                        self._browseEntity._changeCallback();
                }
            ).catch( onFavoriteError );
        };

        if( team.getGames().length )
            buttons.push( new StringWidget().init( { text:"Games", style:buttonStyle, onEnter:selectGames } ) );

        if( team.getEpisodes().length )
            buttons.push( new StringWidget().init( { text:"Replays", style:buttonStyle, onEnter:selectReplays } ) );

        if( isFavorite )
        {
            if( !_x2._telemetry._watchButtonPath.length && !this._sentViewed ) // Any location aside from favorites will have an first entry "Browse: TV", "Search", etc.
            {
                _x2.localyticsSendFavoriteEntityOrViewed( this._entity, "Viewed" );
                this._sentViewed = true;
            }

            buttons.push( new StringWidget().init( { text:"Remove Favorite", style:buttonStyle, onEnter:removeFavorite } ) );
        }
        else
            buttons.push( new StringWidget().init( { text:"Favorite", style:buttonStyle, onEnter:setFavorite } ) );

        buttonWidget = new HorizPillButtonsWidget().init( { entries:buttons, spacing:_x2.scaleValInt( 60 ) } );

        this._menu.removeAllWidgets();
        this._menu.addWidget( buttonWidget );
        this._menu.patchWidget();

        if( _x2._focus === this || _x2._focus === this._buttonWidget )
        {
            var focus = _x2.requestFocus( buttonWidget, true );
            this._speakTarget.setText( this._speechStr + focus._speakStr + ". " );
            this._speakTarget.setSpeechParams( "Press left or right to review options. Press OK to select. ", focus._speakRole, this, false );
        }

        this._buttonWidget = buttonWidget;
    };

    SportsTeamScreen.prototype.layout = function()
    {
        var y = this.getHeaderBottom() + _x2.scaleValInt( 42 ); // XC-13188 space between Breadcrumb line separator

        this._titleStr .setY( y );
        this._menu     .setY( this._menu     .getY() + y );
        this._posterImg.setY( this._posterImg.getY() + y );
    };

    return SportsTeamScreen;

})();
