// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.GuideScreen = ( function()
{
    "use strict";

    GuideScreen.prototype = new Screen();

    function GuideScreen(){}

    GuideScreen.prototype.filtersChanged = function()
    {
        var self = this, onPopped;

        console.log( ">>>>>>>>>>>>>>>>>>>> FILTER HAVE CHANGED <<<<<<<<<<<<<<<<<<<<<" );

        this.setHeaderFilters( _x2._settings.getValue( Settings.Key.FILTERS ) );

        onPopped = function()
        {
            _x2.popOverlay( self._toPop );
            self._gridListings.filtersChanged();
            self._favListings.filtersChanged();
        };

//         window.ll( "tagEvent", "Live TV: Find Channel Jump button", { channel: channel.getCallSign() } ); // Localytics 2.3.8
//         _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Live TV: Find Channel Jump button: " + channel.getCallSign() ) : Config.NOP();

        _x2.popOverlay( this._overlay, onPopped );
    };

    GuideScreen.prototype.gotFocus = function()
    {
        if( this._ready === true )
            this.speak( _x2.requestFocus( this._menu, true ) );
    };

    GuideScreen.prototype.init = function()
    {
        var menuItems, i, str, body, onEnter, onGotFocus, makeOnEnter, makeOnGotFocus, widget, entries = [], self = this;
        var buttonStyle = new Style( { font:"regular" , color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#000000" } );
        var localytics;

        Screen.prototype.init.call( this, { noSep:true } );
        this._className     = "GuideScreen";
        this._telemetryName = "Guide";
        this._bodyWidgets   = [];

        makeOnEnter = function( which, localytics )
        {
            return function()
            {
                window.ll( "tagScreen", localytics ); // OK or DOWN
                _x2._config.log( Config.CLASS_LOCALYTICS, 1 ) ? console.log( "Localytics: " + localytics + " tab" ) : Config.NOP();

                _x2._config.log( Config.CLASS_TELEMETRY , 2 ) ? console.info( "Telmetry: Screen Viewed: " + localytics ) : Config.NOP();
                _x2._telemetry.sendScreenViewed( localytics ); // NOTE: Same as Localytics

                if( which.startViewTimestamp )
                    which.startViewTimestamp(); // Localytics
                _x2.requestFocus( which );
            };
        };

        makeOnGotFocus = function( which, bc, telemetry )
        {
            return function()
            {
                if( self._activeWidget !== which )
                {
                    self.setBreadCrumbs( "Live TV", bc );

                    _x2._config.log( Config.CLASS_TELEMETRY, 1 ) ?  console.log( "Telemetry: Watch Button:" + telemetry ) : Config.NOP();

                     _x2._telemetry.watchPathReset();
                     _x2._telemetry.watchPathPush( telemetry ); // setWatchButton
                     _x2._telemetry.setWatchButton();

                    if( self._activeWidget )
                        self._activeWidget.animate( { a:0, duration:500 } );

                    self._activeWidget = which;

                    which.animate( { a:1, duration:500 } );

                    if( which instanceof GuideViewFilterWidget ) // other tabs loaded when focus switched
                        self._distractor.show();
                    else
                        self._distractor.hide();

                    if( which.loadData && !( which instanceof GridListingsWidget) ) {
                        if( which._list ) {
                            which.addWidget( which._list, Style._safeLeft );
                            which._list.patchWidget();//add widget dynamically
                            window.setTimeout( function() {
                                which.loadData();
                            }, 200);
                        } else {
                            which.loadData();
                        }
                    } else {
                        which.refresh();
                    }
                }
            };
        };
        _x2._data.cancelAllPendingRequests();//cancel the pending request, which make delay in displaying the rows
        
        this._distractor = new DistractorWidget().init();

        menuItems = _x2._config._guideMenu;
        for( i = 0; i < menuItems.length; i++ )
        {
            switch( menuItems[i] )
            {
                case "all":
                    str                = "All Channels";
                    body               = new GridListingsWidget().init();
                    localytics         = "Live TV: All Channels"; // GuideScreen, GridWidget, and GridRowWidget
                    this._gridListings = body;
                    break;

                case "favs":
                    str               = "Favorite Channels";
                    body              = new GridListingsFavoritesWidget().init();
                    localytics        = "Live TV: Favorites";
                    this._favListings = body;
                    break;

                case "kids":
                    str        = "Kids";
                    body       = new GuideViewFilterWidget().init( { filter:GuideViewFilterWidget.Filter.KIDS, distractor:this._distractor } );
                    localytics = "Live TV: Kids";
                    break;

                case "movies":
                    str        = "Movies";
                    body       = new GuideViewFilterWidget().init( { filter:GuideViewFilterWidget.Filter.MOVIE, distractor:this._distractor } );
                    localytics = "Live TV: Movies";
                    break;

                case "sports":
                    str        = "Sports";
                    body       = new GuideViewFilterWidget().init( { filter:GuideViewFilterWidget.Filter.SPORTS, distractor:this._distractor } );
                    localytics = "Live TV: Sports";
                    break;

                default:
                    str        = undefined;
                    body       = undefined;
                    localytics = "???";
                    console.error( "ERROR -> unknown menu entry = " + menuItems[i] );
                    break;
            }

            onEnter     = makeOnEnter( body, localytics );
            onGotFocus  = makeOnGotFocus( body, str,  localytics );

            widget = new StringWidget().init( { text:str, style:buttonStyle, onEnter:onEnter, focusTime:300 } );
            widget.setFocusListeners( onGotFocus );
            entries.push( widget );

            body.setA( 0 );
            this.addWidget( body );
            this._bodyWidgets.push( body );
        }

        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget( self ); } );
        this.addWidget( this._distractor );

        var onOptions = function()
        {
            window.ll( "tagEvent", "Live TV Options Button" ); // Localytics 2.3.7
            _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Live TV: Options Button" ) : Config.NOP();

            self._overlay = new GuideOptionsOverlay().init( { screen:self } );
            _x2.pushOverlay( self._overlay );
        };

        var onFocus = function()
        {
            if( self._url && self._schedule )
            {
                var ready = function()
                {
                    _x2.pushScreen( new VideoScreen().init( { url:self._url, mode:VideoScreen.Mode.LIVE, entity:self._schedule, streamId:self._streamId } ) );
                    self._distractor.hide();
                    self._url      = undefined;
                    self._schedule = undefined;
                };

                self._distractor.show();
                self._schedule.getDetail().then( ready );
            }
        };

        var options = new StringWidget().init( { text:"Options", style:buttonStyle, onEnter:onOptions } );
        options.setFocusListeners( onFocus );

        this._menu = new TabbedPillButtonsWidget().init( { entries:entries, options:options } );
        this._menu.setA( 0 );
        this._menu.addReadyCallback( function() { self.layoutMenu(); } );
        this.addWidget( this._menu );

        this.setHeaderFilters( _x2._settings.getValue( Settings.Key.FILTERS ) );

        this._timePageViewStart = new Date(); // Localytics "All Channels" Page View duration

        return this;
    };

    GuideScreen.prototype.layoutMenu = function()
    {
        var y = this.getHeaderBottom();
        y = isNaN(y)? _x2.scaleValInt( 137 ) : y ;//if there is no header, then set default height

        var h = _x2._config._screenH - y;

        this._menu.setH( y );
        this._menu.setA( 1 );

        for( var i = 0; i < this._bodyWidgets.length; i++ )
        {
            this._bodyWidgets[i].setY( y );
            this._bodyWidgets[i].setH( h );
        }

        if( _x2._focus === this )
            this.speak( _x2.requestFocus( this._menu, true ) );

        this._ready = true;
    };

    GuideScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_LAST:
            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                {

                    if( this._menu.containsWidget( _x2._focus ) === false )
                    {
                        if( this._activeWidget.stopViewTimestamp )
                            this._activeWidget.stopViewTimestamp(); // Localytics

                        _x2.requestFocus( this._menu );
                    }
                    else
                    {
                        // Localytics Back Button and total time user viewed LiveTV: All
                        // Focus can be on:
                        //   GridListingsWidget    -- All channels
                        //   GuideViewFilterWidget -- Kids, Sports, or Movies
                        if( this._timePageLoadStart && this._activeWidget instanceof GridListingsWidget )
                        {
                            var millisecondsViewDuration = new Date() - this._timePageViewStart;
                            window.ll( 'tagEvent', 'Back Button LiveTV', { "Duration": millisecondsViewDuration } );
                            _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics Back Button, LiveTV: All view, duration: " + millisecondsViewDuration  + " (ms)" ) : Config.NOP();
                        }

                        retval = Screen.prototype.processEvent.call( this, val, type );
                    }
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    GuideScreen.prototype.refreshCheck = function()
    {
        if( this._activeWidget.refresh )
            this._activeWidget.refresh( this );
    };

    GuideScreen.prototype.setJumpToChannel = function( channel )
    {
        var self = this, onPopped;

        onPopped = function()
        {
            _x2._telemetry.lastChannel = channel.getNumber();
            _x2.popOverlay( self._toPop );
            self._gridListings.setJumpToChannel( channel );
            self._favListings.setJumpToChannel( channel );
        };

        window.ll( "tagEvent", "Live TV: Find Channel Jump button", { channel: channel.getCallSign() } ); // Localytics 2.3.8
        _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Live TV: Find Channel Jump button: " + channel.getCallSign() ) : Config.NOP();

        _x2.popOverlay( this._overlay, onPopped );
    };

    GuideScreen.prototype.setToPop = function( overlay )
    {
        this._toPop = overlay;
    };

    GuideScreen.prototype.setTuneToChannel = function( channel )
    {
        var self = this, onPopped;

        onPopped = function()
        {
            var schedules = channel.getSchedules();
            var now       = new Date().getTime();

            for( var i = 0; i < schedules.length; i++ )
            {
                if( now >= schedules[i].getStartTime() && now < schedules[i].getEndTime() )
                {
                    self._schedule = schedules[i];
                    _x2._config.log( Config.CLASS_TELEMETRY, 1 ) ?  console.log( "Telemetry: Previous Watch Source:" + _x2._telemetry.getWatchButtonSource() ) : Config.NOP();
                    _x2._telemetry.setWatchButton( "Channel Finder" ); // NOTE: Keep in sync with GuideScreen and GridRowWidget
                    break;
                }
            }

            self._url      = channel.getStreamUrl();
            self._streamId = channel.getStreamId();

            _x2._telemetry.lastChannel = channel.getNumber();
            _x2.popOverlay( self._toPop );
        };

        window.ll( "tagEvent", "Live TV: Find Channel Play button", { channel: channel.getCallSign() } ); // Localytics 2.3.8
        _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Live TV: Find Channel Play button: " + channel.getCallSign() ) : Config.NOP();

        _x2.popOverlay( this._overlay, onPopped );
    };

    GuideScreen.prototype.speak = function( focus )
    {
        this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press left or right to change filters. Go all the way left for options. Press up or down to review channels. ", focus._speakRole, this, false );
    };

    return GuideScreen;

})();
