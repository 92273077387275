// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.NineSliceShadowTestScreen = ( function()
{
    "use strict";

    NineSliceShadowTestScreen.prototype = new Screen();

    function NineSliceShadowTestScreen(){}

    NineSliceShadowTestScreen.prototype.init = function()
    {
        var i, x = Style._safeLeft, obj, colorGrad0, colorGrad1;

        Screen.prototype.init.call( this );
        this._className = "NineSliceShadowTestScreen";

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        // GalleryCellWidget
        var params = {};
        this._imageX = params.bgX = _x2.scaleValInt( 18 );
        this._imageW = params.bgW = _x2.scaleValInt( 270 );
        this._imageH = params.bgH = _x2.scaleValInt( 360 );
        this._scale  = params.bgScale = (this._imageW + 2 * this._imageX) / this._imageW;
        this._imageY = params.bgY = (this._scale - 1) * this._imageH / 2;

        // CollectionCellWidget
        var gradientParams    = {};
        gradientParams.w      = params.bgW;
        gradientParams.h      = params.bgH;
        gradientParams.type   = GradientWidget.Type.Linear;
        gradientParams.angle  = -224;
        gradientParams.alphas = [{ location: 0, opacity: 100 }, { location: 100, opacity: 75 }];
        gradientParams.colors = [{ color: '#202A33', location: 0 }, { color: '#160A1A', location: 100 }];

        this._focusObj = { inner:false, duration:ListWidget.SCROLL_TIME_STEP, w:params.bgW, h:params.bgH, easing:Widget.Easing.LINEAR };
        this._objs     = [];

        for( i = 0; i < 7; i++ )
        {
            if( (i >= 3) && (i < 7) )
            {
                if (i === 3) { colorGrad0 = '#800'; colorGrad1 = '#F00'; }
                if (i === 4) { colorGrad0 = '#080'; colorGrad1 = '#0F0'; }
                if (i === 5) { colorGrad0 = '#008'; colorGrad1 = '#00F'; }
                if (i === 6) { colorGrad0 = '#888'; colorGrad1 = '#FFF'; }
                gradientParams.colors = [{ color: colorGrad0, location: 0 }, { color: colorGrad1, location: 100 }];
            }

            if( i === 0 )
                obj = new ImageWidget().init( { url:_x2._config._imageRoot + "view_all_tile.png", w:params.bgW, h:params.bgH } );
            else
                obj = new GradientWidget().init( gradientParams );

            this._objs.push( obj );
            this.addWidget( obj, x, Style._safeTop );

            x +=  params.bgW + Style._pad;
        }

        this._col = 0;

        this._selectable = true;

        return this;
    };

    NineSliceShadowTestScreen.prototype.gotFocus = function()
    {
        _x2._shadow.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );
        this.updateHighlight( false );
    };

    NineSliceShadowTestScreen.prototype.lostFocus = function()
    {
        _x2._shadow.stopAnimation( Widget.ALPHA );
        _x2._shadow.setA( 0 );
    };

    NineSliceShadowTestScreen.prototype.processEvent = function( val, type )
    {
        var retval;

        switch( val )
        {
            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._col > 0 )
                        this._col--;

                    this.updateHighlight( true );
                }
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._col < (this._objs.length - 1) )
                        this._col++;
                    this.updateHighlight( true );
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    NineSliceShadowTestScreen.prototype.updateHighlight = function( animate )
    {
        var xy       = this._objs[ this._col ].getGlobalPos();
        var focusObj = this._focusObj; // NOTE: Isn't deep copy ...
            focusObj.x        = xy.x;
            focusObj.y        = xy.y;
            focusObj.duration = animate ? ListWidget.SCROLL_TIME_STEP : 0;

        _x2._hi.stopAnimation( Widget.X );
        _x2._hi.stopAnimation( Widget.Y );

        if( animate )
            _x2._hi.animate( focusObj );
        else
            _x2._hi.setPosition( focusObj ); // ... (re)sets duration to zero if not specified
    };

    return NineSliceShadowTestScreen;

})();
