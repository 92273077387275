// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.CirrusVideo = ( function()
{
    "use strict";

    function CirrusVideo( url, listener, resume, lang, contentOptions, videoEas )
    {
        var self = this, onLoad;

        this._listener = listener;

//         _x2._config.debugAddClass( Config.CLASS_VIPER, 5 );
//
        onLoad = function()
        {
            console.log( "CirrusVideo LOADED" );

            var onMediaProgress, onPlayStateChanged;

//             var obj, onBitRateChanged, onBufferComplete, onBufferStart, onEasComplete, onEasFailure, onEasStarted, onMediaEnded, onMediaOpened, onPlaybackSpeedChanged;
//
//             onBitRateChanged = function( event )
//             {
//                 self._listener.onBitRateChanged( event.bitRate, self._playerPlatform.getVideoWidth(), self._playerPlatform.getVideoHeight() );
//             };
//
//             onBufferComplete = function()
//             {
//                 self._listener.onBufferComplete();
//             };
//
//             onBufferStart = function()
//             {
//                 self._listener.onBufferStart();
//             };
//
//             onEasComplete = function()
//             {
//                 console.log( "Emergency Alert Complete" );
//                 self._listener.onEmergencyAlertComplete();
//             };
//
//             onEasFailure = function()
//             {
//                 console.error( "Emergency Alert Failure" );
//             };
//
//             onEasStarted = function()
//             {
//                 console.log( "Emergency Alert Started" );
//                 self._listener.onEmergencyAlertStarted();
//             };
//
//             onMediaEnded = function()
//             {
//                 self._listener.onMediaEnded();
//             };
//
//             var onMediaFailed = function( event )
//             {
//                 console.log( event );
//                 self._listener.onMediaFailed( event );
//             };
//
//             onMediaOpened = function( event )
//             {
//                 _x2._config.log( Config.CLASS_VIPER, 9 ) ? console.log( event ) : Config.NOP();
//                 self._listener.onMediaOpened();
//
//                 if( self._resume )
//                 {
//                     self._playerPlatform.setPosition( self._resume );
//                     self._resume = undefined;
//                 }
//
//                 console.log( "PlayerPlatform Version: " + self._playerPlatform.getVersion() );
//             };

            onMediaProgress = function( event )
            {
//                _x2._config.log( Config.CLASS_VIPER, 9 ) ? console.log( event ) : Config.NOP();
                self._listener.onMediaProgress( event.time * 1000, 0, (event.time + event.timeToEnd) * 1000 );
            };

//             var onMediaRetry = function( event )
//             {
//                 console.log( event );
// //                self._listener.onMediaEnded();
//             };
//
//             onPlaybackSpeedChanged = function( event )
//             {
//                 self._listener.onPlaybackSpeedChanged( event.playbackSpeed );
//             };

            onPlayStateChanged = function( event )
            {
                console.log( event );

//                 _x2._config.log( Config.CLASS_VIPER, 9 ) ? console.log( event ) : Config.NOP();
//
//                 switch( event.playState )
//                 {
//                     case "buffering":
//                     case "complete":
//                         break;
//
//                     case "error":
//                         self._listener.onError( event );
//                         break;
//
//                     case "idle":
//                     case "initializing":
//                     case "prepared":
//                     case "preparing":
//                     case "ready":
//                     case "released":
//                         break;
//
//                     case "seeking":
//                         break;
//
//                     case "initialized":
//                         self._volume = self._playerPlatform.getVolume();
//                         self._playerPlatform.setCurrentTimeUpdateInterval( 2000 );  // not working
// // self._playerPlatform.setVolume( 0 );
// // self._volume = 0;
//
// self._listener.onInitialized( self._playerPlatform.getSupportedPlaybackSpeeds() );
//
// // var speeds    = self._playerPlatform.getSupportedPlaybackSpeeds();
// // var newSpeeds = [];
// //
// // console.log( speeds );
// //
// // for( var i = 0; i < speeds.length; i++ )
// //     if( speeds[i] > 1 )
// //         newSpeeds.push( speeds[i] );
// //
// // self._listener.onInitialized( newSpeeds );
//                         break;
//
//                     case "paused":
//                         self._state = VideoScreen.State.PAUSED;
//                         self._listener.onPaused();
//                         break;
//
//                     case "playing":
//                         self._state = VideoScreen.State.PLAY;
//                         self._listener.onPlay();
//                         break;
//
//                     default:
//                         console.error( "ERROR -> unknown PlayStateChanged event:" );
//                         console.error( event );
//                         break;
//                 }
            };

            var onHaveSecClient = function()
            {
console.log( "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&" );
console.log( _x2._config._secClient );

// var payload =
// {
//             serviceHostUrl: this.licenseServerURL,
//             licenseRequest: challenge,
//             keySystem: keySystem,
//             mediaUsage: "stream",
//             accessToken: this.sandbox.asset.authToken || this.sandbox.asset.drmKey,
//             deviceAuthenticationResult: this.sandbox.asset.deviceAuthenticationResult,
//             contentMetadata: contentMetaData,
//             requestMetadata: {
//                 "X-MoneyTrace": SessionManager_1.SessionManager.instance.moneyTrace.createTraceMessage(),
//             }
// };

var liceneObj =
{
    serviceHostUrl: _x2._config._licenseServer,
//    licenseRequest: challenge,
    keySystem     : "com.widevine.alpha",  //com.microsoft.playready
    mediaUsage    : "stream",
    accessToken   : JSON.parse( Host.getLocalStorage( "XsctToken" ) ),
//    deviceAuthenticationResult: this.sandbox.asset.deviceAuthenticationResult,
//    contentMetadata: contentMetaData,
};


//                 var serviceHostUrl             = _x2._config._licenseServer;
//                 var requestMetadata            //= ;
//                 var keySystem                  = "com.widevine.alpha";  //com.microsoft.playready
//                 var licenseRequest             //= ;
//                 var contentMetadata            //= ;
//                 var mediaUsage                 = "stream";
//                 var accessToken                = JSON.parse( Host.getLocalStorage( "XsctToken" ) );
//                 var accessAttributes           //= ;
//                 var deviceAuthenticationResult //= ;

var ret = _x2._config._secClient.acquireLicense( liceneObj );

console.log( ret );


                var video = document.createElement( "video" );

                video.style.width  = "1280px";
                video.style.height =  "720px";

//            data-dashjs-player
//            video.autoplay           = true;
//            video.preload = "auto";
//            video.src     = "http://assets.player.xcal.tv/super8sapcc/index.mpd"; //"https://dash.akamaized.net/envivio/EnvivioDash3/manifest.mpd";
//            video.controls = true;

                _x2._videoRoot.appendChild( video );

                console.log( video );

                var player = self._player = dashjs.MediaPlayer().create();
                var url    = "https://dash.akamaized.net/envivio/EnvivioDash3/manifest.mpd";
    //            var url    = "http://assets.player.xcal.tv/super8sapcc/index.mpd";

                console.log( player );
                console.log( dashjs );

//             player.initialize( video, url, true );
//
//             console.log( player.events );

                player.on( dashjs.MediaPlayer.events.PLAYBACK_PLAYING     , onPlayStateChanged );
                player.on( dashjs.MediaPlayer.events.PLAYBACK_TIME_UPDATED, onMediaProgress    );

                player.initialize( video, url, true );



//                 obj =
//                 {
//                     videoElement : _x2._videoRoot,
//                     secClient    : _x2._config._secClient,
//                     configuration:  {
//                                         analyticsDeviceType :"x2-html",
//                                         licenseServerUrl   : _x2._config._licenseServer,
//     //                                 retryOnMediaFailed: "true",
//     //                                 defaultAsset: { initialBitrate: 0, initialBufferTime: 2000 },
//     //                                 logstashEndpoint: "http://96.118.37.206:8080/helio/js/"
//                                         zipToFipsEndPoint   :"https://easip-client.ccp.xcal.tv/easws/api/fips/zip/",
//                                         alertServiceEndPoint:"https://easip-client.ccp.xcal.tv/easws/api/alert/active/fipscode/",
//                                         easTextOverlay      :( videoEas !== true ) ? pp.ConfigurationManager.EAS_TEXT_OVERLAY : undefined,
//                                         audioLanguage       :lang,
//                                         helioEas            :true,
//
//                                         defaultAsset:
//                                         {
//                                             "cdnRedundant"   : true,
//                                             forceHttps       : (_x2._config._host.getType() !== Host.Type.PC),
//                                             easEnabled       : true,
//                                             initialBitrate   : 5000000,
//                                             initialBufferTime: 2000
//                                         },
//
//                                         easAsset:
//                                         {
//                                             forceHttps: true
//                                         }
//                                     }
//                 };
//
//                 // events not currently considered interesting
//                 // AD_BREAK_COMPLETE
//                 // AD_BREAK_EXITED
//                 // AD_BREAK_START
//                 // AD_BREAK_UPCOMING
//                 // AD_COMPLETE
//                 // AD_ERROR
//                 // AD_EXITED
//                 // AD_PROGRESS
//                 // AD_SEEK
//                 // AD_START
//                 // AD_SKIPPED
//                 // ADS_SKIPPED
//                 // EMERGENCY_ALERT_IDENTIFIED
//                 // EMERGENCY_ALERT_EXEMPTED
//                 // EMERGENCY_ALERT_ERRORED
//
//                 // events under consideration
//                 // DROPPED_FPS_CHANGED
//                 // DURATION_CHANGED
//                 // FPS_CHANGED
//                 // FRAGMENT_INFO
//                 // MEDIA_FALLBACK
//                 // MEDIA_RETRY
//                 // MEDIA_WARNING
//                 // MISSING_DRM_TOKEN
//                 // NUMBER_OF_ALTERNATIVE_AUDIO_STREAMS_CHANGED
//                 // NUMBER_OF_CLOSED_CAPTIONS_STREAMS_CHANGED
//                 // PLAYBACK_SPEEDS_CHANGED
//                 // PLAYBACK_STARTED
//                 // SEEK_COMPLETE
//                 // SEEK_START
//                 // DRM_METADATA
//                 // VPAID_AD_EVENT
//                 // STREAM_SWITCH
//
//                 self._playerPlatform = new pp.PlayerPlatformAPI( obj );
//
// // console.log( self._playerPlatform.getSupportedClosedCaptionsOptions() );
// //
// //self._playerPlatform.setClosedCaptionsStyle( { textForegroundColor:"red" } );
//
//                 // configure events to be handled
//
//                 self._playerPlatform.on( pp.Events.BITRATE_CHANGED         , onBitRateChanged       );
//                 self._playerPlatform.on( pp.Events.BUFFER_COMPLETE         , onBufferComplete       );
//                 self._playerPlatform.on( pp.Events.BUFFER_START            , onBufferStart          );
//                 self._playerPlatform.on( pp.Events.EMERGENCY_ALERT_COMPLETE, onEasComplete          );
//                 self._playerPlatform.on( pp.Events.EMERGENCY_ALERT_FAILURE , onEasFailure           );
//                 self._playerPlatform.on( pp.Events.EMERGENCY_ALERT_STARTED , onEasStarted           );
//                 self._playerPlatform.on( pp.Events.MEDIA_ENDED             , onMediaEnded           );
//                 self._playerPlatform.on( pp.Events.MEDIA_FAILED            , onMediaFailed          );
//                 self._playerPlatform.on( pp.Events.MEDIA_OPENED            , onMediaOpened          );
//                 self._playerPlatform.on( pp.Events.MEDIA_PROGRESS          , onMediaProgress        );
// //                self._playerPlatform.on( pp.Events.MEDIA_RETRY           , onMediaRetry           );
//                 self._playerPlatform.on( pp.Events.PLAYBACK_SPEED_CHANGED  , onPlaybackSpeedChanged );
//                 self._playerPlatform.on( pp.Events.PLAY_STATE_CHANGED      , onPlayStateChanged     );
// //              self._playerPlatform.on( pp.Events.DURATION_CHANGED        , onDurationChanged      ); // Not valid until video is ended: event.target.duration
//
//                 var xsct = JSON.parse( Host.getLocalStorage( "XsctToken" ) );
//
//                 self._playerPlatform.configureAnalytics( xsct, AboutOverlay.version );
//                 self._playerPlatform.configureEmergencyAlerts( xsct.xsct );
//
//                 _x2._config.log( Config.CLASS_VIPER, 1 ) ? console.log( "PlayerPlatform Version: " + self._playerPlatform.getVersion() ) : Config.NOP();
//
//                 if( url )
//                     self.play( url, listener, resume, contentOptions );
            };

            var onSecClientLoad = function()
            {
console.log( "LOADING SEC CLIENT" );
                var secClientConfig =
                {
                    crypto:
                    {
                        type:"dh"
                    },
                    timeout: 10000,
                    deviceType: _x2._config._host.getSecClientDeviceType()
                };

                _x2._config._secClient = new secclient.SecClient();
                _x2._config._secClient.load( secClientConfig ).then( onHaveSecClient );
            };

            var onSecClientLoadError = function( e )
            {
                console.error( e )
            };

            if( _x2._config._secClient === undefined )
                _x2.getScript( "lib/secclient.js", onSecClientLoad, onSecClientLoadError );
            else
                onHaveSecClient();
        };

        _x2.getScript( "https://cdn.dashjs.org/latest/dash.all.min.js", onLoad );
    }

    CirrusVideo.prototype.destroy = function()
    {
        var children = _x2._videoRoot.childNodes;

        for( var i = 0; i < children.length; i++ )
            _x2._videoRoot.removeChild( children[i] );
    };

//     CirrusVideo.prototype.getCaptions = function()
//     {
//         return this._playerPlatform.getAvailableClosedCaptionTracks();
//     };
//
//     CirrusVideo.prototype.getCurrentLanguage = function()
//     {
//         return this._playerPlatform.getCurrentAudioLanguage();
//     };
//
//     CirrusVideo.prototype.getCurrentPosition = function()
//     {
//         return this._playerPlatform.getCurrentPosition();
//     };
//
// //     CirrusVideo.prototype.getCurrentSpeed = function()
// //     {
// //         return this._playerPlatform.getCurrentPlaybackSpeed();
// //     };
//
//     CirrusVideo.prototype.getDuration = function()
//     {
//         return this._playerPlatform.getDuration();
//     };
//
//     CirrusVideo.prototype.getLanguages = function()
//     {
//         return this._playerPlatform.getAvailableAudioLanguages();
//     };
//
//     CirrusVideo.prototype.getPlaySpeed = function()
//     {
//         return this._playerPlatform.getCurrentPlaybackSpeed();
//     };

    CirrusVideo.prototype.getState = function()
    {
        return this._state;
    };

    CirrusVideo.prototype.getVersion = function()
    {
        return this._player.getVersion();
    };

    CirrusVideo.prototype.mute = function()
    {
        if( this._player.isMuted() === true )
            this._player.setMute( false );
        else
            this._player.setMute( true );
    };

    CirrusVideo.prototype.pause = function()
    {
        console.log( "PAUSE" );
//        this._playerPlatform.pause();
    };

//     CirrusVideo.prototype.setPcBlock = function( bool )
//     {
// //        this._playerPlatform.setBlock( bool );  // should be using this, but was not implemented
//
//         if( bool === true )
//         {
//             _x2._videoRoot.style.display = "none";
//             this._playerPlatform.setVolume( 0 );
//         }
//         else
//         {
//             _x2._videoRoot.style.display = "initial";
//             this._playerPlatform.setVolume( 1 );
//         }
//     };
//
//     CirrusVideo.prototype.play = function( url, listener, resume, contentOptions )
//     {
//         if( url !== undefined )
//         {
// console.log( this._playerPlatform.eas );
// this._playerPlatform.eas.start();
//
//             this._listener = listener;
//             this._resume   = resume;
//
//             var token   = JSON.parse( Host.getLocalStorage( "XsctToken" ) );
//             var xact    = JSON.parse( Host.getLocalStorage( "XactToken" ) );
//             var options =
//             {
//                 authToken                 : token.xsct,
//                 deviceAuthenticationResult: xact,
//                 assetEngine               : "helio",
//                 keySystem                 : _x2._config._host.getDrmType(),
//                 streamId                  : contentOptions.streamId,
//                 recordingId               : contentOptions.recordingId,
//                 assetId                   : contentOptions.assetId,
//                 providerId                : contentOptions.providerId,
//                 mediaGuid                 : contentOptions.mediaGuid
//             };
//
//             this._playerPlatform.setAsset( pp.Asset.create( url, options ) );
//             pp.setLogLevel( (_x2._config._ppLog === true) ? pp.LogLevel.ALL : pp.LogLevel.NONE );
//         }
//         else
//             this._playerPlatform.play();
//     };
//
//     CirrusVideo.prototype.setCaption = function( track )
//     {
//         this._playerPlatform.setClosedCaptionsTrack( track );
//     };
//
//     CirrusVideo.prototype.setCaptionsEnabled = function( bool )
//     {
//         this._playerPlatform.setClosedCaptionsEnabled( bool );
//     };
//
//     CirrusVideo.prototype.setLanguage = function( lang )
//     {
//         this._playerPlatform.setPreferredAudioLanguage( lang );
//     };
//
//     CirrusVideo.prototype.setPosition = function( msec )
//     {
//         this._playerPlatform.setPosition( msec )
//     };
//
//     CirrusVideo.prototype.setSpeed = function( speed )
//     {
//         this._playerPlatform.setSpeed( speed )
//     };
//
//     CirrusVideo.prototype.skipBackward = function( msec )
//     {
//         this._playerPlatform.setPositionRelative( -msec );
//     };
//
//     CirrusVideo.prototype.skipForward = function( msec )
//     {
//         this._playerPlatform.setPositionRelative( msec );
//     };
//
    CirrusVideo.prototype.stop = function()
    {
        this._player.reset();

//         this._playerPlatform.eas.stop();
//         this._playerPlatform.stop();
    };

//     CirrusVideo.prototype.volumeDown = function()
//     {
//         if( this._volume > 0 )
//         {
//             this._volume -= 0.05;
//
//             if( this._volume < 0 )
//                 this._volume = 0;
//
//             this._playerPlatform.setVolume( this._volume );
//             _x2._config.log( Config.CLASS_VIPER, 3 ) ? console.log( "VOLUME = " + this._volume ) : Config.NOP();
//         }
//     };
//
//     CirrusVideo.prototype.volumeUp = function()
//     {
//         if( this._volume < 1 )
//         {
//             this._volume += 0.05;
//
//             if( this._volume > 1 )
//                 this._volume = 1;
//
//             this._playerPlatform.setVolume( this._volume );
//             _x2._config.log( Config.CLASS_VIPER, 3 ) ? console.log( "VOLUME = " + this._volume ) : Config.NOP();
//         }
//     };

    return CirrusVideo;

})();
