// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PurchasePinScreen = ( function()
{
    "use strict";

    PurchasePinScreen.prototype = new Screen();

    function PurchasePinScreen(){}

    PurchasePinScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list );
        this._speakTarget.setText( "X finity Purchase PIN. " + focus._speakStr + "." );
        this._speakTarget.setSpeechParams( "Press up or down to review options. Press OK to select.", focus._speakRole, this, false );
    };

    PurchasePinScreen.prototype.init = function( params )
    {
        var entries, i, onPinOnOff, onOffStr, onModifyPin;

        Screen.prototype.init.call( this );
        this._className     = "PurchasePinScreen";
        this._telemetryName = "Purchase Pin";
        this.setBreadCrumbs( "Settings", "Purchase PIN" );

        onModifyPin = function()
        {
            var onFlowComplete = function( obj, pin )
            {
                params.pin = pin;
            };

            PinOverlay.setPinFlow( PinOverlay.FlowType.PURCHASE, onFlowComplete, true, params.pin );
        };

        onPinOnOff = function( widget )
        {
            var onEnter = function( val )
            {
                var onGotPin = function( obj )
                {
                    params.pinObj = obj;
                    widget.setValue( onOffStr() );
                };

                var onSetPin = function()
                {
                    _x2._data.getPurchasePin().then( onGotPin );
                };

                if( val === true )
                    params.pinObj.setPin( undefined, params.pin ).then( onSetPin );
                else
                    params.pinObj.disablePin( params.pin ).then( onSetPin );
            };

            _x2.pushOverlay( new OnOffOverlay().init( { onEnter:onEnter, isOn:params.pinObj.isEnabled() } ) );
        };

        onOffStr = function()
        {
            return params.pinObj.isEnabled() ? "On" : "Off";
        };

        this._rows = [];
        entries    = _x2._config._purchasePinMenu;

        for( i = 0; i < entries.length; i++ )
        {
            switch( entries[i] )
            {
                case "enable":
                    this._rows.push( { title:"Purchase PIN:", value:onOffStr(), showIcon:true, onEnter:onPinOnOff } );
                    break;

                case "modify":
                    this._rows.push( { title:"Modify Purchase PIN", showIcon:true, onEnter:onModifyPin } );
                    break;

                default:
                    console.error( "ERROR -> unknown menu entry = " + entries[i] );
                    break;
            }
        }

        params.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        params.h        = _x2._config._screenH;
        params.maxIndex = 1;
        params.rows     = this._rows;
        params.type     = MenuRowWidget;

        this._list = new ListWidget().init( params );
        this.addWidget( this._list, Style._safeLeft );

//        window.ll( "tagScreen", "Parental Controls" );
//        _x2._telemetry.sendScreenViewed( this._telemetryName );

        return this;
    };

    PurchasePinScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._list.setY( this.getHeaderBottom() );
        this._list.setH( _x2._config._screenH - this._list.getY() );
    };

    return PurchasePinScreen;

})();
