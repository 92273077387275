// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.RingDistractorTestScreen = ( function()
{
    "use strict";

    RingDistractorTestScreen.prototype = new Screen();

    function RingDistractorTestScreen(){}

    RingDistractorTestScreen.prototype.init = function()
    {
        var self = this;
        this._className = "RingDistractorTestScreen";

        Screen.prototype.init.call( this );

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        this._ring = new RingDistractorWidget().init( {period:2000} );
        this._ring.addReadyCallback( function() { self.layout(); } );
        this.addWidget( this._ring );

        return this;
    };

    RingDistractorTestScreen.prototype.layout = function()
    {
        this._ring.setX( (this.getW() - this._ring.getW()) / 2 );
        this._ring.setY( (this.getH() - this._ring.getH()) / 2 );

        this._ready = true;
    };

    RingDistractorTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._ring.isAnimating() )
                        this._ring.clear();
                    else
                    {
                        this._ring.clear();
                        this._ring.spin();
                    }
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    return RingDistractorTestScreen;

})();

