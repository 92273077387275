// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

"use strict";

/**
 * @class
 */
window.ProgressBarWidget = ( function()
{
    ProgressBarWidget.prototype = new Widget();

    function ProgressBarWidget(){}

    /**
     * Initialize the widget
     * @memberOf ProgressBarWidget
     * @param {Object} params
     * @param {Number} params.w         - bar width
     * @param {Number} params.h         - bar height
     * @param {string} params.style     - ["recording", "watched", "full"]
     * @param {Number} [params.percent] - "full" integer percent (0-100 inclusive)
     * @return {ProgressBarWidget}
     */
    ProgressBarWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this );
        this._className = "ProgressBarWidget";
        this._style     = params.style;
        this._percent   = params.percent;

        var fgColor = this.getFgColor(), bgColor = "#303030";

        var w = params.w ? params.w : 150; // TODO: _x2.scaleValInt(150)
        var h = params.h ? params.h : 14;  // TODO: _x2.scaleValInt(12) // Saved-HTML-Specs.pdf

        this._background = new RectWidget().init( {w:w, h:h, color:bgColor} ); // TODO: This should be 9-slice image
        this._foreground = new RectWidget().init( {w:0, h:h, color:fgColor} ); // TODO: This should be 9-slice gradient

        this.addWidget(this._background);
        this.addWidget(this._foreground);

        this._baseW = w;
        this.setW( w );
        this.setH( h );

        return this;
    };

    /**
     * Set the position of the progress bar.
     * @memberOf ProgressBarWidget
     * @param {Number} percent - integer percent (0-100 inclusive)
     */
    ProgressBarWidget.prototype.setProgress = function( percent )
    {
        this._percent = percent;
        this._foreground.setW( percent / 100 * this._baseW );

        var fgColor = this.getFgColor();
        this._foreground.setColor( fgColor ); // may need to update rect color post init()
    };

    /**
     * @return {String} - 7 character HTML color string
     */
    ProgressBarWidget.prototype.getFgColor = function()
    {
        var fgColor;

        switch( this._style )
        {
            // https://etwiki.sys.comcast.net/pages/viewpage.action?spaceKey=XTV&title=Progress+bar
            case "full":
                if( this._percent < 80 )
                    fgColor ="#5BC942"; // Green  #19A926 #5BC942
                else if( this._percent < 90 )
                    fgColor ="#FECD00"; // Yellow #FFA700 #FECD00
                else
                    fgColor ="#FA523E"; // Red    #C90318 #FA523E
                break;
            case "watched":
                fgColor ="#2ea0dd";     // Blue   #1A6992 #2B9CD8
                break;
            case "recording":
                fgColor ="#dd1a1a";     // Red    #C90318 #FA523E
                break;
        }

        return fgColor;
    };

    return ProgressBarWidget;

})();
