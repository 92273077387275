// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.MetricsTestScreen = ( function()
{
    "use strict";

    MetricsTestScreen.prototype = new Screen();

    function MetricsTestScreen(){}

    MetricsTestScreen.prototype.init = function()
    {
        var x = Style._safeLeft;
        var y = Style._safeTop;

        Screen.prototype.init.call( this );
        this._className = "MetricsTestScreen";

        this._queuedNow = 0;
        this._queuedOld = 0;

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        var fontSize = 36;
        var align    = "left";
        var color1   = "#e5e500";
        var color2   = "#e5e5e5";
        var color3   = "#e50000";
        var style1   = new Style( { color:color1, font:"light", fontSize:fontSize, whiteSpace:"nowrap", textAlign: align } );
        var style2   = new Style( { color:color2, font:"light", fontSize:fontSize, whiteSpace:"nowrap", textAlign: align } );
        var style3   = new Style( { color:color3, font:"light", fontSize:fontSize, whiteSpace:"nowrap", textAlign: align } );

        this._label  = new StringWidget().init( { text:"Queued:", style: style1 } );
        this.addWidget( this._label, x, y );

        x = _x2._config._screenW * 0.2;
        this._text   = new StringWidget().init( { text:"?", style: style2 } );
        this.addWidget( this._text, x, y );

        x  = Style._safeLeft;
        y += fontSize;
        this._error  = new StringWidget().init( { text:"Error sending", style: style3 } );
        this._error.setA( 0 );
        this.addWidget( this._error, x, y );

        return this;
    };

    MetricsTestScreen.prototype.layout = function()
    {
        this._ready = true;
    };

    MetricsTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                    this.queueChange( 'tagScreen', 'Search' );
                break;

            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                    this.queueChange( 'tagScreen', 'Help' );
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    MetricsTestScreen.prototype.queueChange = function( key, val )
    {
        var before, after;

        before = ll.q.length;
        ll( key, val );
        after  = ll.q.length;

        this._queuedOld = this._queuedNow;
        this._queuedNow = after;

        if( (after - before) > 0)
            this._error.setA( 0 );
        else
            this._error.setA( 1 );

        this._text.setText( this._queuedNow );
    }

    MetricsTestScreen.prototype.update = function( step, alphaParent )
    {
        var now = ll.q.length;

        if( now != this._queuedNow )
        {
            this._text.setText( '' + now + ' ( ' + this._queuedNow + ' )' );
            this._queuedNow = now;
        }

        Screen.prototype.update.call( this, step, alphaParent );
    };

    return MetricsTestScreen;

})();

