// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.Overlay = ( function()
{
    "use strict";

    Overlay.prototype = new Widget();

    function Overlay(){}

    /**
     * Initializer
     * @memberof Overlay
     * @param   {Object}    params
     * @param   {Boolean}  [params.hideExitStr]
     * @param   {Function} [params.onDismiss] - Optional callback that will be called when the overlay is dismissed
     * @returns {Overlay}
     */
    Overlay.prototype.init = function( params )
    {
        var self      = this, insetX, insetY;
        var backStyle = new Style( { font:"medium" , color:"#8c8c8c", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );

        if( params )
            this._onDismiss = params.onDismiss;

        Widget.prototype.init.call( this );
        this._className    = "Overlay";
        this._selectable   = true;
        this._suppressBack = !!(params && params.hideExitStr);

        insetX = _x2.scaleValInt( 170 );
        insetY = _x2.scaleValInt( 155 );

        this._bg = new RectWidget().init( { w:_x2._config._screenW - 2 * insetX, h:_x2._config._screenH - 2 * insetY, color:"#000000" } );
        this.addWidget( this._bg, insetX, insetY );

        this._backStr = new StringWidget().init( { text:"Press [Back] to exit", style:backStyle } );
        this._backStr.addReadyCallback( function() { self._backStr.setX( self._bg.getW() - self._backStr.getW() - _x2.scaleValInt(34) ); } );
        this._bg.addWidget( this._backStr, 0, _x2.scaleValInt(34) );

        if( this._suppressBack )
            this._backStr.setA( 0 );

        this._speakTarget = new StringWidget().init( { style:backStyle } );
        this._speakTarget.setA( 0 );
        this._bg.addWidget( this._speakTarget );

        this.setW( _x2._config._screenW );
        this.setH( _x2._config._screenH );
        this.setMouseDownListener( function() {  _x2.popOverlay( self, self._onDismiss ); } );

        return this;
    };

    Overlay.prototype.processEvent = function( val, type )
    {
        if( (val === Host.KEY_LAST || val === Host.KEY_ENTER) && type === Host.KEY_PRESSED && this.getA() > 0 )
            if( this._suppressBack === false )
                _x2.popOverlay( this, this._onDismiss );

        return true;
    };

    Overlay.prototype.setBgSize = function( w, h )
    {
        this._bg.setW( w );
        this._bg.setH( h );
        this._bg.setX( (this.getW() - w)/2 );
        this._bg.setY( (this.getH() - h)/2 );
        this._backStr.setX( this._bg.getW() - this._backStr.getW() - _x2.scaleValInt(34) );
    };

    return Overlay;

})();
