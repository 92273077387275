// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.HorizSelectionWidget = (function()
{
    "use strict";

    HorizSelectionWidget.prototype = new Widget();

    function HorizSelectionWidget(){}

    HorizSelectionWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        for( var i=0; i<this._strings.length; i++ )
            this._strings[i].gotFocus();

        if( this._index < this._strings.length-1 )
            this._chevronRight.animate({alpha:1, duration:600});

        if( this._index > 0 )
            this._chevronLeft.animate({alpha:1, duration:600});

        this.setHighlight();
        this._strings[this._index].setSpeechParams( undefined, undefined, this, newScreen );

        return this;
    };

    /**
     * Initialize the widget. Note that there are two ways to set the selected option: (1) the 'default' parameter
     * passing the index of the selected entry, and (2) adding the selected:true pair to any of the entries
     * @param {Object}    params
     * @param {Number}    params.width         - widget width.
     * @param {Number}   [params.height]       - widget height.
     * @param {Array<{}>} params.entries       - the collection of objects to present
     * @param {*}         params.entries.key   - the key value of the entry
     * @param {String}    params.entries.label - the entry label to be displayed
     * @param {*}        [params.entries.obj]  - the object that will be passed in the callback when a change occurs. If omitted, key will be passed to callback.
     * @param {Number}   [params.defaultIndex] - set the default selected index
     * @param {*}        [params.defaultValue] - set the value of default selected entry
     * @param {Function}  params.onChange      - callback called passing selected 'key' (from param.entries) when the selection changes
     */
    HorizSelectionWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this, params );

        this._className = "HorizSelectionWidget";
        this._fontSize  = _x2.scaleValInt(30);

        this.setW( params.width );
        this.setH( params.height ? params.height : _x2.scaleValInt(110) );

        var self  = this;
        var style = new Style( { font:"medium" , color:"#d0d0d0", colorHi:"#2b9cd8", fontSize:this._fontSize, whiteSpace:"nowrap"} );
        var obj, key, label, defaultVal;

        this._index      = 0;
        this._onChange   = params.onChange;
        this._selectable = true;

        this._objs         = [];
        this._strings      = [];
        this._scrollWidget = new Widget().init();

        if( params.defaultIndex >= 0 )
            this._index = params.defaultIndex;
        else if( params.defaultValue !== undefined )
            defaultVal = params.defaultValue;

        for( var i=0; i<params.entries.length; i++ )
        {
            key   = params.entries[i].key;
            label = params.entries[i].label;
            obj   = ( params.entries[i].obj ? params.entries[i].obj : key);

            this._objs[i]    = obj;
            this._strings[i] = new StringWidget().init({ style:style, text:label });
            this._scrollWidget.addWidget( this._strings[i] );

            if( params.entries[i].selected || ( defaultVal && defaultVal === key ) )
                this._index = i;
        }

        this._optionsScroll = new ScrollWidget().init( { h:0, w:0, widget:this._scrollWidget } );
        this._chevronLeft   = new ImageWidget().init( { url: _x2._config._imageRoot + "moreLeft.png", onLoad:function(){ self.layout() }});
        this._chevronRight  = new ImageWidget().init( { url: _x2._config._imageRoot + "moreRight.png" } );

        this._chevronRight.setA(0);
        this._chevronLeft.setA(0);

        this.addWidget( this._chevronLeft );
        this.addWidget( this._chevronRight );
        this.addWidget( this._optionsScroll );

        return this;
    };

    HorizSelectionWidget.prototype.layout = function()
    {
        var w  = this.getW();
        var h  = this.getH();
        var cw = this._chevronLeft.getW();
        var ch = this._chevronLeft.getH();
        var cy = h/2 - ch/2;
        var sx = cw + Style._pad;
        var sh = this._fontSize * 1.2;

        //position chevrons
        this._chevronLeft.setY( cy );
        this._chevronRight.setY( cy );
        this._chevronRight.setX( w - cw );

        //dimension and position scroll container
        this._optionsScroll.setW( w - 2 * sx );
        this._optionsScroll.setH( sh );
        this._optionsScroll.setX( sx );
        this._optionsScroll.setY( h/2 - sh/2 );

        //layout options strings within scroll widget
        for( var i=0; i<this._strings.length; i++ )
        {
            var sw = this._strings[i].getW();
            var pos = w * i + w / 2 - sw / 2 - sx;
            this._strings[i].setX( pos );
        }

        this.setScroll(0);
    };

    HorizSelectionWidget.prototype.setHighlight = function()
    {
        var obj;
        obj          = this.getGlobalPos();
        obj.w        = this.getW();
        obj.h        = this.getH();
        obj.inner    = true;
        obj.duration = 300;

        _x2._hi.setPosition( obj );
    };

    HorizSelectionWidget.prototype.onChange = function(direction)
    {
        if( this._onChange && direction !== 0 )
        {
            this._onChange( this._objs[this._index] );
            this._strings[this._index].setSpeechParams( undefined, undefined, this, false );
        }
    };

    HorizSelectionWidget.prototype.lostFocus = function()
    {
        for( var i=0; i<this._strings.length; i++ )
            this._strings[i].lostFocus();

        this._chevronRight.setA(0);
        this._chevronLeft.setA(0);
    };

    HorizSelectionWidget.prototype.setScroll = function( direction )
    {
        var self = this;
        var duration = ( direction === 0 ? 0 : 300 );

        this._index += direction;

        var offset = (-1 * this._index * this.getW());

        this._optionsScroll.setScroll( { x:offset, duration:duration, onEnd:function(){ self.onChange(direction) } } );

        if( _x2._focus === this )
        {
            this._chevronRight.setA( this._index < this._strings.length-1 ? 1 : 0 );
            this._chevronLeft.setA( this._index > 0 ? 1 : 0);
        }
    };

    HorizSelectionWidget.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._index < this._strings.length - 1)
                        this.setScroll(1);
                }
                break;

            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._index > 0 )
                        this.setScroll(-1);
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;

    };

    return HorizSelectionWidget;
})();
