// Copyright (C) 2020 Comcast Corporation, All Rights Reserved

window.DevicesList = (function()
{
    "use strict";

    function DevicesList( data )
    {
        
        this._data = [];
        this._currentDevice = null;
        this._isDataLoaded = false;
        if( data ) {
            this._data = data;

            _x2._config.log( Config.CLASS_X2, 7 ) ? console.log( "########## ACCOUNT DEVICES ##########" ) : Config.NOP();

            for( var i = 0; i < this._data.length; i++ )
            {
                _x2._config.log( Config.CLASS_X2, 7 ) ? console.log( this._data[i] ) : Config.NOP();

                if( this._data[i].isCurrent === true || ( _x2._metricsSocket._deviceId && this._data[i].deviceId === _x2._metricsSocket._deviceId ) )
                {
                    this._currentDevice = this._data[i];
                    break;
                }
            }
            this._isDataLoaded = true;
            _x2._config.log( Config.CLASS_X2, 7 ) ? console.log( "=====================================" ) : Config.NOP();
            _x2._config.log( Config.CLASS_X2, 7 ) ? console.log( "Total Devices: ", this._data.length          ) : Config.NOP();

        }
    }

    DevicesList.prototype.getCurrentDevice = function( )
    {
        return this._currentDevice || null;
    };

    DevicesList.prototype.isDataLoaded = function() {
        return this._isDataLoaded;
    }

    return DevicesList;

})();
