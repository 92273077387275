// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.DeletedRecordingsScreen = ( function()
{
    "use strict";

    DeletedRecordingsScreen.prototype = new Screen();

    function DeletedRecordingsScreen(){}

    DeletedRecordingsScreen.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut(0);
    };

    DeletedRecordingsScreen.prototype.init = function()
    {
        Screen.prototype.init.call( this );
        this._className     = "DeletedRecordingsScreen";
        this._telemetryName = "Deleted Recordings";

        var self = this;
        var messageStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), textAlign:"center", whiteSpace:"nowrap" } );

        var listParams      = {};
        listParams.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 1;
        listParams.axis     = ListWidget.Axis.Y;
        listParams.type     = RecordingRowWidget;
        listParams.obj      = this;

        this._list = new ListWidget().init( listParams );
        this.addWidget( this._list, Style._safeLeft );

        this.setBreadCrumbs( "Deleted Recordings" );
        this.addReadyCallback( function(){ self.layout(); } );

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget( self._list ); self._distractor.show(); } );
        this._list.addWidget( this._distractor );

        this._emptyMessage = new StringWidget().init( { style:messageStyle, text:"There are no deleted recordings" } );
        this._emptyMessage.setA(0);
        this.addWidget( this._emptyMessage );

        this.setData();

        return this;
    };

    DeletedRecordingsScreen.prototype.setData = function( refresh )
    {
        var success, fail, self = this;

        success = function( recordings )
        {

            if( recordings.length > 0 )
            {
                self._list.setA( 1 );
                self._list.setData( recordings );

                //NOTE: on refresh (refreshCheck() called after deleting a recording) _focus will be a KeyEater. Bypass _focus === self check in that case.
                if( _x2._focus === self || refresh )
                    self.speak( _x2.requestFocus( self._list, true ) );

                self._emptyMessage.setA( 0 );
            }
            else
            {
                _x2._hi.fadeOut( 0 );
                self._list.setA( 0 );
                self._emptyMessage.setA( 1 );
            }

            self._distractor.hide();
        };

        fail = function( error )
        {
            self._distractor.hide();
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.SAVED } ) );
        };

        this._distractor.show();
        _x2._data.getDeletedRecordings().then( success ).catch( fail );
    };

    DeletedRecordingsScreen.prototype.speak = function( focus )
    {
        if( focus === this )
        {
            this._speakTarget.setText( this._emptyMessage.getText() + ". " );
            this._speakTarget.setSpeechParams( undefined, undefined, this, false );
        }
        else
        {
            this._speakTarget.setText( this.getBreadCrumb() + focus._speakStr + ". " );
            this._speakTarget.setSpeechParams( "Press up or down to review items. Press OK for options. ", focus._speakRole, this, false );
        }
    };

    DeletedRecordingsScreen.prototype.refreshCheck = function()
    {
        _x2._hi.fadeOut(0);
        this.setData( true );
    };

    DeletedRecordingsScreen.prototype.layout = function()
    {
        var y = this.getHeaderBottom() - 2;
        this._list.setY(y);

        var mw = this._emptyMessage.getW();
        var mh = this._emptyMessage.getH();
        this._emptyMessage.setX( _x2._config._screenW/2 - mw/2);
        this._emptyMessage.setY( _x2._config._screenH/2 - mh );
    };

    return DeletedRecordingsScreen;

})();
