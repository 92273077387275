// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Represents the showing for a linear program.
 *
 * Maps to XTV "TVListing" objects.
 */
window.LinearShowing = (function()
{
    "use strict";

    LinearShowing.prototype = new Showing();

    function LinearShowing(){}

    /**
     * Initialize the object
     * @memberOf LinearShowing
     * @param {Object} data - XTV derived JSON object
     * @param {Object} [channel] - Reference to the channel this showing belongs to
     * @param {Number} [blockId] - Reference to the schedule block this belongs to.
     * @return {LinearShowing}
     */
    LinearShowing.prototype.init = function( data, channel, blockId )
    {
        Showing.prototype.init.call( this, data );

        this._channel   = channel;
        this._gridTitle = data.title; //work-around for detail query stepping on title.
        this._blockId   = blockId;

        return this;
    };

    /**
     * Return true if this episode or series it belongs to can be recorded
     * NOTE: Must call getDetail() first.
     * @memberOf {LinearShowing}
     * @return {boolean}
     */
    LinearShowing.prototype.isRecordable = function()
    {
        var recordable = false;

        var now = _x2._config._startTime ? new Date( _x2._config._startTime ).getTime()+1 : Date.now();
        var end = this.getInteger("endTime" );

        if( end && end > now )
            recordable = ( this.getEpisodeRecordingForm() !== undefined ); //NOTE: we're only concerned with the showings recordable status

        return recordable;
    };

    /**
     * Return true if this episode or series it belongs to is recorded.
     * NOTE: Must call getDetail() first.
     * NOTE: This will return true for a future recording.
     * @memberOf {LinearShowing}
     * @return {boolean}
     */
    LinearShowing.prototype.isRecorded = function()
    {
        return this.isSeriesRecorded() || this.isEpisodeRecorded();
    };

    /**
     * Return true if this episode is recorded
     * @memberOf LinearShowing
     * @return {boolean}
     */
    LinearShowing.prototype.isEpisodeRecorded = function()
    {
        var isRecorded = false, recording = this.getEpisodeRecording();

        if( recording )
            isRecorded = recording.isScheduled();

        return isRecorded;
    };

    /**
     * Return true if this series is recorded
     * @memberOf LinearShowing
     * @return {boolean}
     */
    LinearShowing.prototype.isSeriesRecorded = function()
    {
        var isRecorded = false, recording = this.getSeriesRecording();

        if( recording )
            isRecorded = recording.isScheduled();

        return isRecorded;
    };

    /**
     * Get the embedded entity recording object.
     * @return {EntityRecording}
     */
    LinearShowing.prototype.getEntityRecording = function()
    {
        var entityRecording, embedded = this.getEmbedded("entityRecording");

        if( embedded )
            entityRecording = new EntityRecording().init(embedded);

        return entityRecording;
    };

    /**
     * Return the Recording object for this episode
     * @memberOf LinearShowing
     * @return {Recording}
     */
    LinearShowing.prototype.getEpisodeRecording = function()
    {
        var recordingsMeta = _x2._data.getScheduledRecordingMetadata();
        if( recordingsMeta )
            return recordingsMeta.getRecording(this.getLink("self"));
    };

    /**
     * Return the modify form if the recording is set, the schedule form if available or undefined if there are no available forms.
     * @memberOf LinearShowing
     * @return {Form}
     */
    LinearShowing.prototype.getEpisodeRecordingForm = function()
    {
        var form = this.getForm("schedule");

        if( ! form )
            form = this.getForm("scheduleDateTime");

        return form;
    };

    /**
     * Get the series recording object for the series this program belongs to
     * @memberOf LinearShowing
     * @return {EntityRecording}
     */
    LinearShowing.prototype.getSeriesRecording = function()
    {
        if( ! this._seriesRecording )
        {
            var recording = this.getEmbedded("entityRecording");
            if( recording )
                this._seriesRecording = new EntityRecording().init( recording );
        }

        return this._seriesRecording;
    };

    /**
     * The the series recording form for the series this program belongs to
     * @memberOf LinearShowing
     * @return {Form}
     */
    LinearShowing.prototype.getSeriesRecordingForm = function()
    {
        return this.getForm("scheduleSeries");
    };

    /**
     *
     * @return {number}
     */
    LinearShowing.prototype.getDurationMs = function()
    {
        var durationMs = 0;
        var start = this.get("startTime");
        var end   = this.get("endTime");

        if( start && end )
            durationMs = end - start;

        if( durationMs && !isNaN( durationMs ) )
            durationMs = parseInt( durationMs );

        return durationMs;
    };

    LinearShowing.prototype.getAirDateAsString = function()
    {
        return this.get("derivedAirDate");
    };

    LinearShowing.prototype.getChannel = function()
    {
        if( this._channel === undefined )
        {
            var channelLink = this.getLink('channel');
            if( channelLink !== undefined )
                this._channel = _x2._channelCollection.getChannel( channelLink.replace('#','') );
        }

        return this._channel;
    };

    LinearShowing.prototype.getBlockId = function()
    {
        return this._blockId;
    };

    LinearShowing.prototype.getChannelLink = function()
    {
        return this.getLink("channel");
    };

    LinearShowing.prototype.getDetail = function( force )
    {
        var promise, self = this;

        if( this._hasDetail && !force )
            promise = Promise.resolve(self);
        else
        {
            var resolver = function( resolve, reject )
            {
                var success = function( response )
                {
                    self._hasDetail = true;
                    self._data = JSON.parse(response.data);

                    if( force === true )
                    {
                        //reset stored properties.
                        self._program             = undefined;
                        self._recording           = undefined;
                        self._episodeRecording    = undefined;
                        self._epRecordingForm     = undefined;
                        self._seriesRecording     = undefined;
                        self._seriesRecordingForm = undefined;
                    }

                    resolve( self );
                };

                var error = function( error )
                {
                    reject( error );
                };
                //TODO: (Entity Refactor) use link.resolve
                var url = self.getSelfLink();
                url = XtvApi.trimPath( url );
                url = url.replace( /#/g, '' );

                _x2._network.ajax({type: "GET", url: _x2._data._host + url, headers: [_x2._data._authHeader], accepts:Network.Accepts.xtv, api:"getTvListingDetail" }).then(success).catch(error);
            };

            promise = new Promise( resolver );
        }

        return promise;
    };

    /**
     * Get the showing end time
     * @memberOf LinearShowing
     * @return {Number} Time in epoch timestamp
     */
    LinearShowing.prototype.getEndTime = function()
    {
        return this.getInteger("endTime");
    };

    LinearShowing.prototype.getFilters = function()
    {
        return this.get("filters") !== undefined ? this.get("filters") : [];
    };

    /**
     * The title provided from a "grid chunk" query, which is different from the title returned from a query for showing details.
     */
    LinearShowing.prototype.getGridTitle = function()
    {
        return this._gridTitle;
    };

    LinearShowing.prototype.getListingId = function()
    {
        return this.get("listingId");
    };

    LinearShowing.prototype.getRecording = function()
    {
        var recording, embedded = this.getEmbedded("recording");

        if( embedded )
            recording = new Recording().init(embedded);

        return recording;
    };

    /**
     * Get the HAL link used to pull details of this showing
     * @memberOf LinearShowing
     * @return {String}
     */
    LinearShowing.prototype.getSelfLink = function()
    {
        var link = this.getLink("self");

        if( link )
            link = XtvApi.trimPath( link );

        return link;
    };

    LinearShowing.prototype.getSeriesId = function()
    {
        return this.get("seriesId");
    };

    LinearShowing.prototype.getStationId = function()
    {
        return this.get("stationId");
    };

    /**
     * Get the showing start time
     * @memberOf LinearShowing
     * @return {Number} Time in epoch timestamp
     */
    LinearShowing.prototype.getStartTime = function()
    {
        return this.getInteger("startTime");
    };

    LinearShowing.prototype.hasFilter = function( filter )
    {
        var filterMatch  = false;
        var filters = this.getFilters();

        for( var i = 0; i < filters.length; i++ )
            if( filters[i] === filter )
            {
                filterMatch = true;
                break;
            }

        return filterMatch;
    };

    /**
     * Return true if this showing has subtitles
     * @memberOf LinearShowing
     * @return {Boolean}
     */
    LinearShowing.prototype.isSubtitled = function()
    {
        return this.getBoolean( "isSubtitled");
    };

    /**
     * Return true if this is a placeholder schedule.
     * @returns {boolean}
     */
    LinearShowing.prototype.isTbd = function()
    {
        return this.getBoolean( "isTbd" );
    };

    /**
     * Return true if the showing is live. Overrides Showing.isWatchable();
     * @return {boolean}
     */
    LinearShowing.prototype.isWatchable = function()
    {
        var retval = false;
        var now    = _x2._config._startTime ? new Date(_x2._config._startTime).getTime()+1 : Date.now();
        var start  = this._data.startTime   ? parseInt(this._data.startTime)               : 0;
        var end    = this._data.endTime     ? parseInt(this._data.endTime)                 : 0;

        if( start < now && end > now )
            retval = true;

        return retval;
    };

    LinearShowing.prototype.fetchSeriesRecording = function( force )
    {
        return this.getDetail( force );
    };

    LinearShowing.prototype.fetchEpisodeRecording = function( force )
    {
        var self = this;

        var resolver = function( resolve, reject )
        {
            var onMetaLoaded = function()
            {
                var path      = ["_embedded", "recording"];
                var recording = _x2._data.getScheduledRecordingMetadata().getRecording( self.getListingId() );

                if( recording && recording.getWontRecordReason() === undefined )
                    self.addPath( path, recording.getData() );
                else if ( self.getPath( path ) )
                    self.removePath( path );

                resolve();
            };

            // only fetch recording meta if user is entitled to cDVR.
            if( _x2._features.hasEntitlement( Features.Entitlement.C_DVR ) )
                _x2._data.fetchScheduledRecordingMetadata( force ).then( onMetaLoaded ).catch( reject );
            else
                resolve()
        };

        return new Promise(resolver);
    };

    LinearShowing.prototype.setChannel = function( channel )
    {
        this._channel = channel;
    };

    return LinearShowing;

})();

