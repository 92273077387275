// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.EntityInfoWidget = (function()
{
    "use strict";

    EntityInfoWidget.prototype = new Widget();

    function EntityInfoWidget(){}

    EntityInfoWidget.prototype.getSpeechStr = function()
    {
        return this._speechStr;
    };

    /**
     * Initializer
     * @memberof EntityInfoWidget
     * @param   {Object}   params
     * @param   {Boolean} [params.grid=false] - render using grid info layout
     * @returns {EntityInfoWidget}
     */
    EntityInfoWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this, params );
        this._className = "EntityInfoWidget";
        this._numLoaded = 0;
        this._grid      = params ? params.grid : false;

        var self = this;
        var titleStyle, subTitleStyle, descStyle, detailStyle, infoStyle;
        var titleX, titleSize, divX, layout, onPosterError, onPosterLoad, detailX;

        layout = function()
        {
            self._numLoaded++;

            if( self._numLoaded === 3 || self._readyToDisplay === true )
            {
                self._readyToDisplay = true;

                var y              = self._titleStr.getY() + self._titleStr.getH() + _x2.scaleValInt( 32 );
                var descY          = y + self._subTitleStr.getH() + _x2.scaleValInt( 14 );
                var subTitleString = self._subTitleStr.getText();

                self._subTitleStr.setY( y );
                self._descrStr.setY( descY );
                self._descrStr.setH( self._posterH - descY );
                self._vertDiv.setY( y );
                self._vertDiv.setH( self._posterH - y );
                self._progressWidget.setY( y );
                self._recProgressWidget.setY( y );
                self._detailStr.setY( y );
                self._infoStr.setY( y + self._detailStr.getH() + _x2.scaleValInt( 18 ) );

                if( self._progressStr )
                {
                    var offset = self._progressStr.getH() / 2 - self._progressWidget.getH() / 2;
                    self._progressStr.setY( y - offset );
                    self._progressStr.setX( self._progressWidget.getX() + self._progressWidget.getW() + Style._pad * 2 );
                }

                self._descY = descY;

                if( self._hasTitleStr === true )
                    self._titleStr.setA( 1 );
                else
                    self._titleStr.setA( 0 );

                if( self._hasSubTitleStr === true )
                    self._subTitleStr.setA( 1 );
                else
                    self._subTitleStr.setA( 0 );

                if( subTitleString === undefined || subTitleString.length === 0 )
                    self._descrStr.setY( self._subTitleStr.getY() );
                else
                    self._descrStr.setY( self._descY );

                if( self._hasProgressWidget === true )
                {
                    self._detailStr.setY( self._descY );
                    self._progressWidget.setA( 1 );
                }
                else
                    self._progressWidget.setA( 0 );

                if( self._hasRecProgressWidget === true )
                {
                    self._detailStr.setY( self._descY );
                    self._recProgressWidget.setA( 1 );
                }
                else
                    self._recProgressWidget.setA( 0 );

                if( self._progressStr )
                    if( self._hasProgressStr === true )
                        self._progressStr.setA( 1 );
                    else
                        self._progressStr.setA( 0 );

                y = self._detailStr.getY() + self._detailStr.getH() + _x2.scaleValInt( 13 );

                if( self._hasInfoStr === true )
                {
                    self._infoStr.setY( y );
                    y += self._infoStr.getH();
                    self._infoStr.setA( 1 );
                }
                else
                    self._infoStr.setA( 0 );

                if( self._hasInfoGlyphs === true )
                {
                    self._infoGlyphs.setY( y );
                    y += self._infoGlyphs.getH() + _x2.scaleValInt( 6 );
                    self._infoGlyphs.setA( 1 );
                }
                else
                    self._infoGlyphs.setA( 0 );

                if( self._hasCsmRating === true )
                {
                    self._csmContainer.setY( y );
                    y += self._csmIcon.getH() + _x2.scaleValInt( 12 );
                    self._csmContainer.setA( 1 );
                }
                else
                    self._csmContainer.setA( 0 );

                if( self._hasTomatoWidget === true )
                {
                    self._tomatoWidget.setY( y );
                    y += self._tomatoWidget.getH() + _x2.scaleValInt( 12 );
                    self._tomatoWidget.setA( 1 );
                }
                else
                    self._tomatoWidget.setA( 0 );

                if( self._grid !== true )
                    self._netIconImage.setY( y );

                self.animate( { a:1, duration:500 } );
            }
        };

        this._layout = layout;

        onPosterError = function()
        {
            if( self._posterImage.getUrl() !== self._fallbackImage )
                self._posterImage.setUrl( self._fallbackImage );
        };

        onPosterLoad = function()
        {
            self._posterImage.animate( { a:1, duration:250 } );
        };

        if( this._grid )
        {
            this.setW( _x2.scaleValInt( 1576 - 2 * 80 ) );
            this._posterW = _x2.scaleValInt( 240 );
            this._posterH = _x2.scaleValInt( 320 );
            titleX        = _x2.scaleValInt( 280 );
            divX          = _x2.scaleValInt( 1165 );
            detailX       = divX + _x2.scaleValInt( 32 );
            titleSize     = _x2.scaleValInt( 48 );
            titleStyle    = new Style( { font: "light"  , color: "#e8e8e8", fontSize: titleSize, whiteSpace: "nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
            subTitleStyle = new Style( { font: "regular", color: "#e8e8e8", fontSize: _x2.scaleValInt( 27 ), whiteSpace: "nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
            descStyle     = new Style( { font: "light"  , color: "#e8e8e8", fontSize: _x2.scaleValInt( 27 ), overflow:"hidden", textOverflow:"ellipsis" } );
            detailStyle   = new Style( { font: "medium" , color: "#8b8b8b", fontSize: _x2.scaleValInt( 24 ), whiteSpace: "nowrap" } );
            infoStyle     = new Style( { font: "medium" , color: "#a3a3a3", fontSize: _x2.scaleValInt( 24 ), whiteSpace: "nowrap" } );
        }
        else
        {
            this.setW( _x2._config._screenW - Style._safeLeft - Style._safeRight );
            this._posterW = _x2.scaleValInt( 303 );
            this._posterH = _x2.scaleValInt( 404 );
            titleX        = this._posterW + _x2.scaleValInt( 52 );
            divX          = _x2.scaleValInt( 1386 );
            detailX       = divX + _x2.scaleValInt( 32 );
            titleSize     = _x2.scaleValInt( 60 );
            titleStyle    = new Style( { font: "light"  , color: "#e8e8e8", fontSize: titleSize, whiteSpace: "nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
            subTitleStyle = new Style( { font: "regular", color: "#a3a3a3", fontSize: _x2.scaleValInt( 30 ), whiteSpace: "nowrap" } );
            descStyle     = new Style( { font: "light"  , color: "#e8e8e8", fontSize: _x2.scaleValInt( 30 ), overflow:"hidden", textOverflow:"ellipsis" } );
            detailStyle   = new Style( { font: "medium" , color: "#8b8b8b", fontSize: _x2.scaleValInt( 30 ), whiteSpace: "nowrap" } );
            infoStyle     = new Style( { font: "medium" , color: "#a3a3a3", fontSize: _x2.scaleValInt( 24 ), whiteSpace: "nowrap" } );
        }

        this.setA( 0 );

        //widgets
        this._posterImage       = new ImageWidget().init( { w:this._posterW, h:this._posterH, onError:onPosterError, onChange:onPosterLoad } );
        this._titleStr          = new StringWidget().init( { style: titleStyle, text:"?????" } );
        this._subTitleStr       = new StringWidget().init( { style: subTitleStyle, text:"?????" } );
        this._descrStr          = new TextBlockWidget().init( { style: descStyle, w: divX - titleX - _x2.scaleValInt( 32 ), h:100, ellipsis: true } );
        this._vertDiv           = new RectWidget().init( { w: 2, h:_x2.scaleValInt(234), color:"#5e5e5e" } );
        this._recProgressWidget = new ProgressBarWidget().init( { w:this.getW() - divX, h:_x2.scaleValInt(14), style:"recording" } );
        this._detailStr         = new StringWidget().init( { style: detailStyle, text: "DETAILS" } );
        this._infoStr           = new StringWidget().init( { style: infoStyle } );
        this._infoGlyphs        = new StringWidget().init( { style: infoStyle } );
        this._tomatoWidget      = new TomatoWidget().init();
        this._netIconImage      = new ImageWidget().init( { onChange: function(){ self.netIconLoaded() }, onError: function(){ self._netIconImage.setA(0) } } );
        this._csmRating         = new StringWidget().init( { style: subTitleStyle } );
        this._csmIcon           = new ImageWidget().init ( { url:_x2._config._imageRoot + "common_sense_icon.png" } );
        this._csmContainer      = new ContainerWidget().init( { widgets:[this._csmIcon, this._csmRating], spacing:_x2.scaleValInt(10), ready:true } );

        if( this._grid )
            this._progressWidget = new ProgressBarWidget().init( { w:this.getW() - divX, h:_x2.scaleValInt(14), style:"watched" } );
        else
        {
            this._progressWidget = new ProgressBarWidget().init( { w:this.getW() - divX -_x2.scaleValInt(180), h:_x2.scaleValInt(14), style:"watched" } );
            this._progressStr    = new StringWidget().init( { style: infoStyle, text:"?" } );
            this._progressStr.setA( 0 );
        }

        this._titleStr.setW( this.getW() - titleX );
        this._subTitleStr.setW( this.getW() - titleX );
        this._titleStr.addReadyCallback( layout );
        this._tomatoWidget.addReadyCallback( layout );
        this._csmIcon.addReadyCallback( layout );

        this._posterImage.setA      ( 0 );
        this._titleStr.setA         ( 0 );
        this._subTitleStr.setA      ( 0 );
        this._recProgressWidget.setA( 0 );
        this._progressWidget.setA   ( 0 );
        this._infoStr.setA          ( 0 );
        this._infoGlyphs.setA       ( 0 );
        this._csmContainer.setA     ( 0 );
        this._tomatoWidget.setA     ( 0 );

        this.addWidget( this._posterImage                                                       );
        this.addWidget( this._titleStr         , titleX, -parseInt( .3 * titleSize )            );
        this.addWidget( this._subTitleStr      , titleX                                         );
        this.addWidget( this._descrStr         , titleX                                         );
        this.addWidget( this._vertDiv          , divX                                           );
        this.addWidget( this._progressWidget   , detailX                                        );
        this.addWidget( this._recProgressWidget, detailX                                        );
        this.addWidget( this._detailStr        , detailX                                        );
        this.addWidget( this._infoStr          , detailX                                        );
        this.addWidget( this._infoGlyphs       , detailX                                        );
        this.addWidget( this._tomatoWidget     , detailX                                        );
        this.addWidget( this._csmContainer     , detailX                                        );
        this.addWidget( this._netIconImage     , detailX, this._posterH + _x2.scaleValInt( 20 ) );

        if( this._progressStr )
            this.addWidget( this._progressStr );

        return this;
    };

    /**
     * Set and display data
     * @param {Entity, LinearShowing, Recording} data
     */
    EntityInfoWidget.prototype.setData = function( data )
    {
        this._speechStr = "";
        this._data = data;

        this._hasTitleStr          = false;
        this._hasSubTitleStr       = false;
        this._hasProgressWidget    = false;
        this._hasRecProgressWidget = false;
        this._hasProgressStr       = false;
        this._hasInfoStr           = false;
        this._hasInfoGlyphs        = false;
        this._hasCsmRating         = false;
        this._hasTomatoWidget      = false;

        var entity = data; //NOTE: data can be the Entity or the Entity may be embedded in data, in which case it's extracted below.

        //showing properties
        var start, end, duration, isHd, isSAP, isCC, isDVS, minRemaining, provider, showings;

        //recording properties
        var isRecording, recordedOn, percentRecorded, recordingStatus, isSeriesRecording, isCheckedOut, checkedOutToDevice, recordingEndTime, seriesRecording, episodeRecording;

        //other properties
        var pctComplete, locked=false, glyphs="", subTitleString="", subSpeechStr="", infoString, recording, now, networkLogoUrl, entityOptions, rentalExpires, purchasedOn, expires;

        if( data instanceof Entity )
        {
            seriesRecording = data.getEntityRecording();
            if( seriesRecording && seriesRecording.getRecordingStatus() === "scheduled" )
                isRecording = isSeriesRecording = true;

            entityOptions = data.getEntityOptions();
            if( entityOptions )
            {
                provider = entityOptions.getContentProvider();

                if( data.getType() === XtvApi.EntityType.EPISODE )
                {
                    recording = entityOptions.getRecordings()[0];
                    isRecording = ( recording !== undefined );
                }

                isHd         = entityOptions.hasHdShowing();
                isSAP        = entityOptions.hasSapShowing();
                isCC         = entityOptions.hasCcShowing();
                isDVS        = entityOptions.hasDvsShowing();
                minRemaining = entityOptions.getMinsRemaining();
                showings     = entityOptions.getVodShowings();

                for( var i=0; i<showings.length; i++ )
                {
                   if( showings[i] instanceof Purchase )
                   {
                       if( showings[i].getTransactionDetails() )
                            purchasedOn = "Purchased " + ( showings[i].isHD() ? "HD" : "SD" ) + " " + _x2.createDateStr( showings[i].getTransactionDetails().getPurchasedOnDate(), true );
                       break;
                   }
                   else if( showings[i] instanceof Rental )
                   {
                       if( showings[i].getExpires() )
                       {
                           expires = _x2.createExpiresString( showings[i].getExpires() );

                           if( expires.length > 0 )
                               rentalExpires = "Rental " + expires;
                       }
                       break;
                   }
                }
            }
        }
        else
        {
            if( data instanceof Showing )
            {
                entity       = data.getProgram();
                duration     = data.getDuration();
                isHd         = data.isHD();
                isSAP        = data.isSap();
                isCC         = data.isClosedCaptioned();
                isDVS        = data.isDvs();
                minRemaining = data.getMinsRemaining();
                provider     = data.getContentProvider();
            }

            if( data instanceof LinearShowing )
            {
                start     = data.getStartTime();
                end       = data.getEndTime();
                recording = data.getRecording();
                provider  = data.getChannel();

                if( recording )
                {
                    if( recording.getWontRecordReason() === undefined )
                    {
                        isRecording = true;
                        isSeriesRecording = ( recording.getRecordingType() === "Series" );
                    }
                }
            }

            if( data instanceof Purchase )
            {
                var date;

                if( data.getGroup() )
                    date = data.getGroup().getPurchasedOnDate();
                else if( data.getTransactionDetails() )
                    date = data.getTransactionDetails().getPurchasedOnDate();

                if( date )
                    purchasedOn = "Purchased " + ( data.isHD() ? "HD " : "SD " ) + _x2.createDateStr( date, true );
            }

            if( data instanceof Rental )
            {
                expires = _x2.createExpiresString( data.getExpires() );

                if( expires.length > 0 )
                    rentalExpires = "Rental " + expires;
            }

            if( data instanceof Recording )
            {
                isRecording       = true;
                recording         = data;
                isSeriesRecording = data.isSeriesRecorded();
                isCheckedOut      = data.isCheckedOut();

                if( isCheckedOut )
                    checkedOutToDevice = data.getCheckedOutDeviceName();
            }
        }

        if( recording )
        {
            provider          = recording.getChannel();
            duration          = recording.getDuration();
            recordedOn        = recording.getRecordedOnDate();
            percentRecorded   = recording.getRecordingProgress();
            recordingStatus   = recording.getRecordingStatus();
            recordingEndTime  = new Date( recordedOn.getTime() + ( duration * 60000 ) );
        }

        var type        = entity.getType();
        var title       = entity.getTitle();
        var credits     = entity.getCredits();
        var year        = entity.getYear();
        var description = entity.getDescription();
        var rtReview    = entity.getRottenTomatoReview();
        var csmReview   = entity.getCommonSenseMediaReview();
        var series      = entity.getSeries();
        var rating      = entity.getRating();

        var url         = this._grid === true ? entity.getImageLink( 240, 320 ) : entity.getImageLink( 303, 404 );
        var fallbackUrl = this._grid === true ? entity.getFallbackImageLink( 240, 320 ) : entity.getFallbackImageLink( 303, 404 );

        if( ! duration && type !== XtvApi.EntityType.SERIES ) //priority to showing or recording duration. don't show duration for series.
            duration = entity.getDuration();

        //TODO: check entity title for TV Series while recording (Merge conflict bc988e2a)

        if( series && series.getTitle() )
            title = series.getTitle() + " - " + title;

        if( rating )
            locked = _x2._pc.isRatingLocked( rating );

        if( locked === false )
            locked = _x2._pc.isTitleLocked( entity.getEntityId() );

        if( locked === false )
        {
            if( entity.getSeries() )
            {
                series = entity.getSeries();

                if( series )
                    locked = _x2._pc.isTitleLocked( series.getEntityId() );
            }
        }

        if( provider )
        {
            networkLogoUrl = provider.getLogoUrl( _x2.scaleValInt( 156 ), _x2.scaleValInt( 117 ) );

            if( networkLogoUrl )
            {
                networkLogoUrl = networkLogoUrl.replace( /&extent=true/, "" );
                networkLogoUrl = networkLogoUrl.replace( /&gravity/, "" );
            }
        }

        //move logic up here and set elements below.

        this._progressWidget.setA( 0 );
        this._recProgressWidget.setA( 0 );
        this._descrStr.setA( 1 );
        this._detailStr.setA( 1 );
        this._vertDiv.setA( 1 );
        this._netIconImage.setA( 1 );

        // *** image & fallback ***
        if( this._posterImage.getUrl() !== url ) //avoid flashing image on secondary call to setData()
        {
            this._posterImage.setA( 0 );
            this._posterImage.setUrl( url );
            this._fallbackImage = fallbackUrl;
        }

        // *** title ***
        if( title )
        {
            this._hasTitleStr = true;
            this._speechStr += title + ". ";
            this._titleStr.setText( title );
        }
        else
            this._hasTitleStr = false;

        // *** description ***
        if( ! recording  && ( type === XtvApi.EntityType.MOVIE || type === XtvApi.EntityType.SERIES ) )
        {
            if( year )
            {
                if( description !== undefined )
                    description = "(" + year + ") " + description;
                else
                    description = "(" + year + ")";
            }
        }

        if( description )
            this._speechDescStr = description;

        if( credits && credits.length )
        {
            this._speechDescStr = credits.join( ", " ) + ". " + description;
            description = "<font color='#a3a3a3'>" + credits.join( ", " ) + "</font><br>" + description;
        }

        this._descrStr.setText( description );

        // *** subtitle ***
        if( isCheckedOut )
        {
            subTitleString  = "<font color='#77993e'>" + Strings.Glyphs.DOWNLOAD + "</font> Downloaded to " + checkedOutToDevice;
            subSpeechStr   += "Downloaded to " + checkedOutToDevice + ". ";
        }
        else if( rentalExpires )
        {
            subTitleString  = rentalExpires;
            subSpeechStr   += rentalExpires;
        }
        else if( purchasedOn )
        {
            subTitleString  = purchasedOn;
            subSpeechStr   += purchasedOn;
        }
        else
        {
            if( isRecording )
            {
                if( isSeriesRecording )
                    subTitleString = "<font color='#c90318'>" + Strings.Glyphs.SERIES + "</font>";
                else
                    subTitleString = "<font color='#c90318'>" + Strings.Glyphs.RECORD + "</font>";

                if( recordingStatus === "STARTED" )
                {
                    subTitleString   = " Recording Now " + _x2.createTimeRange( recordedOn, recordingEndTime );
                    subSpeechStr    += _x2.createTimeSpeechStr( subTitleString );
                }
                else if( recordingStatus === "COMPLETE" || recordingStatus === "DELETED" )
                {
                    subTitleString = " Recorded " + ( recordedOn.getMonth() + 1 ) + "/" + recordedOn.getDate() + " " + _x2.createTimeStr( recordedOn ); // XC-14205: Don't show Red recording dots if recording has completed
                    subSpeechStr  += " Recorded " + ( recordedOn.getMonth() + 1 ) + "/" + recordedOn.getDate() + " at " + _x2.createTimeSpeechStr( _x2.createTimeStr( recordedOn ) );
                }
                else
                {
                    if( isSeriesRecording )
                    {
                        subTitleString += " Series Recording Scheduled";
                        subSpeechStr   += " Series Recording Scheduled";
                    }
                    else
                    {
                        subTitleString += " Recording Scheduled";
                        subSpeechStr   += " Recording Scheduled";
                    }
                }

                if( provider )
                {
                    if( provider instanceof Channel )
                    {
                        var chNumber = provider.getNumber();
                        var callSign = provider.getCallSign();

                        if( chNumber && callSign )
                        {
                            subTitleString += " on "         + chNumber + " "  + callSign;
                            subSpeechStr   += " on channel " + chNumber + ", " + callSign;
                        }
                    }
                }
            }
            else if( start && end )
            {
                subTitleString = _x2.createTimeRange( new Date( start ), new Date( end ) );
                subSpeechStr   = _x2.createTimeSpeechStr( subTitleString );
            }
        }

        this._hasSubTitleStr = (subTitleString !== undefined);
        this._subTitleStr.setText( subTitleString );
        this._speechStr += subSpeechStr;

        // *** progress ***
        if( percentRecorded !== undefined )
        {
            if ( percentRecorded < 100 )
            {
                this._hasRecProgressWidget = true;
                this._recProgressWidget.setProgress( percentRecorded === 0 ? 1 : percentRecorded ); //always show at least 1% of a recording is in progress (may be < 1 immediately after being set)
            }

            if( this._progressStr )
                this._hasProgressStr = false;
        }
        else
        {
            if( minRemaining )
            {
                var pct = Math.max( 1, Math.round( 100 * ( 1 - minRemaining / duration ) ) );
                pctComplete = pct <= 100 ? pct : 100;
            }
            else if( start && end ) //show progress if entity is currently airing
            {
                now = Date.now();

                if( start < now && end > now )
                {
                    pctComplete  = Math.max( 1, Math.round( ( ( now - start ) / ( end - start ) ) * 100 ) );
                    minRemaining = Math.round( ( end - now ) / 60000 );
                }
            }

            if( pctComplete && pctComplete < 100 )
            {
                this._hasProgressWidget = true;
                this._progressWidget.setProgress( pctComplete );

                if( this._progressStr && minRemaining )
                {
                    this._hasProgressStr = true;
                    this._progressStr.setText( minRemaining + " min left" );
                }
            }
            else
            {
                this._hasProgressWidget = false;

                if( this._progressStr )
                    this._hasProgressStr = false;
            }
        }
        
        infoString = "";
        if( provider instanceof Channel ) {
            infoString += provider.getNumber() + ' ' + provider.getCallSign() + ', ';
        }
        // *** infoString ***
        if( duration )
        {
            infoString += duration + " mins";
            this._speechStr += infoString.replace( "mins", "minutes. " );
        }


        this._infoStr.setText( infoString );

        this._hasInfoStr = infoString && infoString.length > 0;

        // *** info glyphs ***
        if( locked )
        {
            glyphs += Strings.Glyphs.LOCKED + " ";
            this._speechStr += "Locked by rating. ";
        }

        if( rating )
        {
            glyphs += Strings.Glyphs[rating] + " ";
            this._speechStr += "Rating " + rating + ". ";
        }
        if( isHd )
        {
            glyphs += Strings.Glyphs.HD + " ";
            this._speechStr += "HD available . ";
        }

        if( isSAP )
        {
            glyphs += Strings.Glyphs.SAP + " ";
            this._speechStr += "Secondary audio available. ";
        }

        if( isCC )
        {
            glyphs += Strings.Glyphs.CC + " ";
            this._speechStr += "Closed captioning available. ";
        }

        if( isDVS )
        {
            glyphs += Strings.Glyphs.DV + " ";
            this._speechStr += "Video description available. ";
        }

        this._infoGlyphs.setText( glyphs );

        this._hasInfoGlyphs = glyphs.length > 0;

        //*** reviews ***

        if( csmReview )
        {
            this._hasCsmRating = true;
            this._csmRating.setText( csmReview.getCommonSenseMediaScore() );
        }
        else
            this._hasCsmRating = false;

        if( rtReview && ( rtReview.getFanSummaryScore() > 0 || rtReview.getCriticSummaryScore() > 0 ) )
        {
            this._hasTomatoWidget = true;
            this._tomatoWidget.setData( rtReview );

            this._speechStr += this._tomatoWidget.getSpeechStr();
        }
        else
            this._hasTomatoWidget = false;

        this._netIconImage.setUrl();

        //*** network image ***

        if( networkLogoUrl )
            this._netIconImage.setUrl( networkLogoUrl );

        if( this._speechDescStr !== undefined )
            this._speechStr += this._speechDescStr;

        if( this._readyToDisplay === true )
            this._layout();
    };

    EntityInfoWidget.prototype.netIconLoaded = function()
    {
        if( this._grid !== true )
        {
            this._netIconImage.setH( undefined );

            var fullH   = this._netIconImage.getH();
            var scaledH = this._posterH - this._netIconImage.getY();
            scaledH = (scaledH < 78 ) ? 78 : scaledH; //minimum height should be 78, else logo get shrunk
            if( fullH > scaledH )
                this._netIconImage.setH( scaledH );
        }
        else
            this._netIconImage.setX( (this._posterW - this._netIconImage.getW()) / 2 );

        this._netIconImage.animate( { a:1, duration:500 } );
    };

    return EntityInfoWidget;

})();
