// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.GuideOptionsOverlay = ( function()
{
    "use strict";

    GuideOptionsOverlay.prototype = new Overlay();

    function GuideOptionsOverlay(){}

    GuideOptionsOverlay.prototype.gotFocus = function()
    {
         Overlay.prototype.gotFocus.call( this );

         if( this._ready === true )
            this.speak( _x2.requestFocus( this._list ) );
    };

    GuideOptionsOverlay.prototype.init = function( params )
    {
        var self       = this, layout, listParams = {}, rows, onFilterChannels, onFindChannel;
        var hPad       = _x2.scaleValInt(79);
        var vPad       = _x2.scaleValInt(80);
        var titleStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap" } );

        Overlay.prototype.init.call( this );
        this._className  = "GuideOptionsOverlay";

        layout = function()
        {
            var y = self._title.getY() + self._title.getH() + _x2.scaleValInt(46);

            self._sep.setY( y );
            self._list.setY( y );
            self._bg.animate( { a:1, duration:500 } );
            self._ready = true;

            if( _x2._focus === self )
                self.speak( _x2.requestFocus( self._list ) );
        };

        this._bg.setA( 0 );

        this._title = new StringWidget().init( { text:"Options", style:titleStyle } );
        this._title.addReadyCallback( layout );
        this._bg.addWidget( this._title, hPad, vPad );

        this._sep = new RectWidget().init( { w:this._bg.getW() - 2 * hPad, h:ListRowWidget.DIVIDER_H, color:"#303030" } );
        this._bg.addWidget( this._sep, hPad );

        onFilterChannels = function()  // TODO needs Localytics
        {
            var overlay = new FilterChannelsOverlay().init( { screen:params.screen, which:Settings.Key.FILTERS } );

            if( params.screen )
                params.screen.setToPop( overlay );

            _x2.pushOverlay( overlay );
        };

        onFindChannel = function()
        {
            window.ll( "tagEvent", "Live TV Find Channel Button" ); // Localytics 2.3.8
            _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Live TV: Find Channel button" ) : Config.NOP();

            var overlay = new FindChannelOverlay().init( { screen:params.screen } );

            if( params.screen )
                params.screen.setToPop( overlay );

            _x2.pushOverlay( overlay );
        };

        rows =
        [
            { title:"Find Channels"     , onEnter:onFindChannel    },
            { title:"Filter Preferences", onEnter:onFilterChannels }
        ];

        listParams.w        = this._bg.getW() - 2 * hPad;
        listParams.h        = this._bg.getH() - 2 * vPad;
        listParams.maxIndex = 1;
        listParams.rows     = rows;
        listParams.type     = MenuRowWidget;

        this._list = new ListWidget().init( listParams );
        this._bg.addWidget( this._list, hPad );

        return this;
    };

    GuideOptionsOverlay.prototype.speak = function( focus )
    {
        this._speakTarget.setText( "Options. " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Pres OK to select. Press back to dismiss.", focus._speakRole, this, false );
    };

    return GuideOptionsOverlay;

})();
