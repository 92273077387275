// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

"use strict";

/**
 * @class
 *
 */
window.SportsTeam = (function()
{
    SportsTeam.prototype = new DataObject();

    function SportsTeam(){}

    /**
     * Initialize this object
     * @memberOf SportsTeam
     * @param {Object} data - XTV derived JSON object
     */
    SportsTeam.prototype.init = function(data)
    {
        DataObject.prototype.init.call( this, data );
        return this;
    };

    SportsTeam.prototype.getCoach = function()
    {
        return this.get("coach");
    };

    SportsTeam.prototype.getConference = function()
    {
        return this.get("conference");
    };

    SportsTeam.prototype.getDetail = function()
    {
        var self = this;

        var resolver = function( resolve, reject )
        {
            var entityLoaded = function( response )
            {
                self._data = JSON.parse(response);
                self._options = new EntityOptions().init( self );
                self._options.fetch().then( function(){ resolve( self ) } ).catch( reject );
            };

            var id = self.get("entityId") || self.get("merlinId");

            _x2._data.getSportsTeamEntity(id).then(entityLoaded).catch(reject);
        };

        return new Promise( resolver );
    };

    SportsTeam.prototype.getEntityId = function()
    {
        return this.get("merlinId");
    };

    SportsTeam.prototype.getEpisodes = function()
    {
        var episodes = [];

        if( this._options )
            episodes = this._options.getEpisodes();
        else
            console.log("WARNING -> SportsTeam.EntityOptions not initialized" );

        return episodes;
    };

    SportsTeam.prototype.getFallbackImageLink = function( w, h )
    {
        return this.getLink( "fallbackImage", { width: w, height: h } );
    };

    SportsTeam.prototype.getGames = function()
    {
        var games = [];

        if( this._options )
            games = this._options.getUpcomingListings();
        else
            console.log("WARNING -> SportsTeam.EntityOptions not initialized" );

        return games;
    };

    SportsTeam.prototype.getImageLink = function( w, h )
    {
        return this.getLink( "image", { width: w, height: h } );
    };

    SportsTeam.prototype.getLeague = function()
    {
        return this.get("league");
    };

    SportsTeam.prototype.getLocation = function()
    {
        var strings = [];
        var locality = this.getPath(["location","addressLocality"]);
        var region   = this.getPath(["location","addressRegion"]);

        if( locality )
            strings.push( locality );

        if( region )
            strings.push( region );

        return strings.join( ', ' );
    };

    SportsTeam.prototype.getName = function()
    {
        var strings = [];

        if( this.get("representingName") )
            strings.push( this.get("representingName") );

        if( this.get("name") )
            strings.push( this.get("name") );

        return strings.join(' ');
    };

    SportsTeam.prototype.getProgram = function()
    {
        return this;
    };

    SportsTeam.prototype.getSelfLink = function()
    {
        return this.getLink("self");
    };

    SportsTeam.prototype.getTitle = function()
    {
        return this.getName();
    };

    SportsTeam.prototype.getType = function()
    {
        return XtvApi.EntityType.TEAM;
    };

    SportsTeam.prototype.getUpcomingListings = function()
    {
        return this._games;
    };

    SportsTeam.prototype.getVenue = function()
    {
        return this.get("venue");
    };

    return SportsTeam;
})();
