// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ManageDevicesScreen = ( function()
{
    "use strict";

    ManageDevicesScreen.prototype = new Screen();

    function ManageDevicesScreen(){}

    ManageDevicesScreen.prototype.gotFocus = function()
    {
       _x2.requestFocus( this._list );
    };

    ManageDevicesScreen.prototype.init = function()
    {
        var i, listParams = {}, rows, entries, nameStr, onDownloads, onName, onStreams, self = this;

        Screen.prototype.init.call( this );
        this._className     = "ManageDevicesScreen";
        this._telemetryName = "Manage Devices"; // TODO: Is this correct?
        this.setBreadCrumbs( "Settings", "Manage Devices" );

        nameStr = function()
        {
            var onGotList;

            /**
             * @param {Object[]} data
             * @param {Boolean}  data[].isCurrent - indicates that this is device on which the application is currently running
             */
            onGotList = function( data )
            {
                var name;

                if( data && data.length )
                {
                    for( i = 0; i < data.length; i++ )
                        console.log( "Device" + (i+1) + ":" + JSON.stringify(data[i]) );

                    for( i = 0; i < data.length; i++ )
                        if( data[i].isCurrent === true )
                        {
                            name = data[i].deviceName;
                            break;
                        }
                }

                self._nameRowData.value = ( name === undefined ) ? "No Device Name" : name;
                self._list.refreshCells();
            };

            _x2._data.getAllDeviceList().then( onGotList );

            return "...";
        };

        onDownloads = function()
        {
            _x2.pushScreen( new DownloadDevicesScreen().init( { path:"Settings / Manage Devices" } ) );
        };

        onName = function()
        {
            _x2.pushScreen( new DeviceNameScreen().init( { path:"Settings / Manage Devices", onChange:nameStr } ) );
        };

        onStreams = function()
        {
            _x2.pushScreen( new StreamingDevicesScreen().init( { path:"Settings / Manage Devices" } ) );
        };

        rows    = [];
        entries = _x2._config._manageDevicesMenu;

        for( i = 0; i < entries.length; i++ )
        {
            switch( entries[i] )
            {
                case "deviceName":
                    this._nameRowData = { title:"Device Name:", showIcon:true, onEnter:onName, value:nameStr() };
                    rows.push( this._nameRowData );
                    break;

                case "deviceDownloads":
                    rows.push( { title:"Devices with Downloads", showIcon:true, onEnter:onDownloads } );
                    break;

                case "deviceStreams":
                    rows.push( { title:"Devices Currently Streaming", showIcon:true, onEnter:onStreams } );
                    break;

                default:
                    console.error( "ERROR -> unknown menu entry = " + entries[i] );
                    break;
            }
        }

        listParams.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 1;
        listParams.rows     = rows;
        listParams.type     = MenuRowWidget;

        this._list = new ListWidget().init( listParams );
        this.addWidget( this._list, Style._safeLeft );

         _x2._config.log( Config.CLASS_LOCALYTICS, 1 ) ? console.log( "Localytics: Manage Devices screen" ) : Config.NOP();
        window.ll( "tagScreen", "Manage Devices screen" ); // Localytics ?.?.?

        _x2._telemetry.sendScreenViewed( this._telemetryName );

        return this;
    };

    ManageDevicesScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._list.setY( this.getHeaderBottom() );
        this._list.setH( _x2._config._screenH - this._list.getY() );
    };

    return ManageDevicesScreen;

})();
