// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.AlertOverlay = ( function()
{
    "use strict";

    AlertOverlay.prototype = new Overlay();

    function AlertOverlay(){}

    AlertOverlay.prototype.gotFocus = function()
    {
        this._speakTarget.setText( this._title.getText() + ". " + this._body.getText() );
        this._speakTarget.setSpeechParams( "Press back to dismiss.", undefined, this, false );
    };

    /**
     * Initializer
     * @memberof AlertOverlay
     * @param   {Object}        params
     * @param   {String}        params.message - Message
     * @param   {String}        params.title   - Title
     * @returns {AlertOverlay}
     */
    AlertOverlay.prototype.init = function( params )
    {
        var self        = this;
        var titleStyle  = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), textAlign:"center", whiteSpace:"nowrap" } );
        var bodyStyle   = new Style( { font:"light" , color:"#a3a3a3", fontSize:_x2.scaleValInt(30), textAlign:"center"                      } );
        var onReady     = function() { self._numLoaded++; self.layoutAlertOverlay(); };

        Overlay.prototype.init.call( this, params );
        this._className  = "AlertOverlay";

        var hPad = Math.floor( this._bg.getW() / 10 );

        this._numLoaded = 0;

        this._title = new StringWidget().init( { text:params.title, style:titleStyle } );
        this._title.setW( this._bg.getW() );
        this._title.addReadyCallback( onReady );
        this._bg.addWidget( this._title, 0, _x2.scaleVal( 243 ) );

        this._body = new TextBlockWidget().init( { text:params.message, style:bodyStyle, w:this._bg.getW() - 2 * hPad } );
        this._body.addReadyCallback( onReady );
        this._bg.addWidget( this._body, hPad );

        return this;
    };

    AlertOverlay.prototype.layoutAlertOverlay = function()
    {
        if( this._numLoaded === 2 )
        {
            var hPad = _x2.scaleVal( 40 );

            this._title.setY( (this._bg.getH() - this._title.getH() - hPad - this._body.getH()) / 2 );
            this._body.setY( this._title.getY() + this._title.getH() + hPad );
        }
    };

    return AlertOverlay;

})();
