// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.GridInfoWidget = (function()
{
    "use strict";

    GridInfoWidget.prototype = new Widget();

    function GridInfoWidget(){}

    GridInfoWidget.prototype.getChannel = function()
    {
        return this._channel;
    };

    GridInfoWidget.prototype.getIsChannel = function()
    {
        return this._isChannel;
    };

    GridInfoWidget.prototype.getShowing = function()
    {
        return this._showing;
    };

    GridInfoWidget.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut( 0 );
    };

    /**
     * Initializer
     * @memberof GridInfoWidget
     * @returns {GridInfoWidget}
     */
    GridInfoWidget.prototype.init = function()
    {
        var self      = this, insetX, insetY, w, h;
        var backStyle = new Style( { font:"medium" , color:"#8c8c8c", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );
        var onLoad    = function() { self._numLoaded++; self.layout() };

        Widget.prototype.init.call( this );
        this._className  = "GridInfoWidget";
        this._selectable = true;
        this._numLoaded  = 0;

        this.setW( _x2._config._screenW );
        this.setH( _x2._config._screenH );
        this.setA( 0 );

        this._dim = new RectWidget().init( { name: "Dimming", w:_x2._config._screenW, h:_x2._config._screenH, color:"#303030" } );
        this._dim.setVal( Widget.ALPHA, 0.8 );
        this.addWidget( this._dim );

        insetX = _x2.scaleValInt( 172 );
        insetY = _x2.scaleValInt( 155 );

        w = _x2._config._screenW - 2 * insetX;
        h = _x2._config._screenH - 2 * insetY;

        this._bg = new RectWidget().init( { w:w, h:h, color:"#000000" } );
        this.addWidget( this._bg, insetX, insetY );

        this._detail = new EntityInfoWidget().init( { grid:true } );
        this.addWidget( this._detail, insetX + _x2.scaleValInt(80), insetY + _x2.scaleValInt(180) );

        this._channelDetail = new ChannelInfoWidget().init( { grid:true } );
        this.addWidget( this._channelDetail, insetX + _x2.scaleValInt(80), insetY + _x2.scaleValInt(180) );

        this._back = new StringWidget().init( { text:"Press [Back] to exit", style:backStyle } );
        this._back.addReadyCallback( onLoad );
        this._bg.addWidget( this._back, 0, _x2.scaleValInt(34) );

        this._speakTarget = new StringWidget().init( { style:backStyle } );
        this._speakTarget.setA( 0 );
        this._bg.addWidget( this._speakTarget );

        this._actionButtons = new EntityActionButtonsWidget().init();
        this.addWidget( this._actionButtons, _x2.scaleValInt(530), _x2.scaleValInt(683) );

        this._actionButtons.setRefreshListener( function( entity ){ 
            var lastChannel = _x2._settings.getValue( Settings.Key.LAST_CHAN );       
            if( entity.getChannel().getNumber() == lastChannel.num ) {
                self.refresh( entity );
            } else {
                self._parent._parent.setChannels();
            }//If last channel is not equals to last grid entity, then it was changed in mini-guide, so we need to refresh the grid
        } );

        this._prev = new ImageWidget().init( { url: _x2._config._imageRoot + "channelUp.png" } );
        this._next = new ImageWidget().init( { url: _x2._config._imageRoot + "channelDown.png" } );

        this._prev.setA( 0 );
        this._next.setA( 0 );

        this._prev.addReadyCallback( onLoad );
        this._next.addReadyCallback( onLoad );

        this.addWidget( this._prev, insetX, insetY +     _x2.scaleValInt(63) );
        this.addWidget( this._next, insetX, insetY + h - _x2.scaleValInt(63) );

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget( self ); } );
        this.addWidget( this._distractor );

       return this;
    };

    GridInfoWidget.prototype.layout = function()
    {
        if( this._numLoaded === 3 )
        {
            this._back.setX( this._bg.getW() - this._back.getW() - _x2.scaleValInt(34) );

            this._prev.setX( (_x2._config._screenW - this._prev.getW()) * 0.5 );
            this._next.setX( (_x2._config._screenW - this._next.getW()) * 0.5 );
        }
    };

    GridInfoWidget.prototype.processEvent = function( val )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_DOWN:
            case Host.KEY_LAST:
            case Host.KEY_UP:
                retval = false;
                break;
        }

        return retval;
    };

    GridInfoWidget.prototype.refresh = function( entity )
    {
        if( entity )
        {
            if( this._isChannel ) {       
                this._channelDetail.setData( entity, this._channel );
            }
            else {
                this._detail.setData( entity, this._channel );
            }
        }
    };

    GridInfoWidget.prototype.setEntity = function( showing, channel, indexCurMax, isChannel )
    {
        this._distractor.hide();
        this._showing   = showing;
        this._isChannel = isChannel;

        if( channel )
            this._channel = channel;

        this._prev.setA( (indexCurMax.current !==                    0 ) ? 1 : 0 );
        this._next.setA( (indexCurMax.current !== (indexCurMax.max - 1)) ? 1 : 0 );

        if( isChannel )
        {
            this._channelDetail.setData(showing, channel);
            this._channelDetail.setA(1);
            this._detail.setA(0);
        }
        else
        {
            this._detail.setData(showing, channel);
            this._detail.setA(1);
            this._channelDetail.setA(0);
        }

        this._actionButtons.setData( { entity:showing, channel:channel, infoButton:true, isChannel:isChannel } );
        this._actionButtons.setA(1);

        this._menuReady = true;

        if( _x2._focus === this )
            this.speak( _x2.requestFocus( this._actionButtons, true ) );
        else if( this._actionButtons.containsWidget( _x2._focus ) === true )
            this.speak( _x2._focus );
    };

    GridInfoWidget.prototype.speak = function( focus )
    {
        var detail = this._isChannel ? this._channelDetail : this._detail;
        var str    = detail.getSpeechStr();
        this._speakTarget.setText( focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( str + ". Press right or left to review program options. Press OK to select. Press up or down to move between channels. Press back to exit. ", focus._speakRole, this, false );
    };

    GridInfoWidget.prototype.unsetEntity = function(isUpdate)
    {
        this._channelDetail.setA(0);
        this._detail.setA(0);
        this._distractor.show();

        if( this._actionButtons )
            this._actionButtons.setA(0);

        var lastScreen = _x2._screenRoot.getTop();
        if( isUpdate && !(lastScreen instanceof VideoScreen) )
            this.updateParentRow();
        else if( isUpdate && lastScreen instanceof VideoScreen ) {
            window.setTimeout( function() {
                this.updateParentRow();
            }.bind(this), 500);//if last screen is not destroy, wait for another 700 ms
        }
    };
    GridInfoWidget.prototype.updateParentRow = function() 
    {
        //if it is channel, then we need to update that channel row through cells
        if( this._parent ) {
            var lastChannel = _x2._settings.getValue( Settings.Key.LAST_CHAN );//this._channelDetail._channel || ( this._detail._data && this._detail._data.getChannel() );
            for( var i = 0; i < this._parent._cells.length; i++ ) {
                if( this._parent._cells[i]._channel && lastChannel &&
                    (this._parent._cells[i]._channel.getNumber() == lastChannel.num ) ) {
                    this._channel.setFocus = true;
                    this._parent._cells[i].refreshChannelInfo( true );
                    break;
                }
            }
        }
    };

    return GridInfoWidget;

})();
