// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 * 
 * Maps to XTV "ResumePoint"
 */
window.ResumePoint = (function()
{
    "use strict";
    
    function ResumePoint(){}

    /**
     * Initialize this object
     * @param {Object} data - XTV derived JSON object
     * @memberOf ResumePoint
     * @return {ResumePoint}
     */
    ResumePoint.prototype.init = function( data )
    {
        this._data = data;
        
        return this;
    };

    /**
     * Get the id of the media asset related to this resume point
     * @memberOf ResumePoint
     * @return {String}
     */
    ResumePoint.prototype.getMediaId = function()
    {
        return this._data.mediaId;
    };

    /**
     * Get the the resume point position
     * @memberOf ResumePoint
     * @return {Number} Millisecond offset
     */
    ResumePoint.prototype.getPosition = function()
    {
        var position = 0;

        if( !isNaN( this._data.position ) )
            position = parseInt(this._data.position);

        return position;
    };

    ResumePoint.prototype.getShowing = function()
    {
        return this._showing;
    };

    ResumePoint.prototype.setPosition = function( position )
    {
        this._data.position = position;
    };

    ResumePoint.prototype.setShowing = function( showing )
    {
        this._showing = showing;
    };
    
    return ResumePoint;
})();

