// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.GridListingsFavoritesWidget = (function()
{
    "use strict";

    GridListingsFavoritesWidget.prototype = new Widget();

    function GridListingsFavoritesWidget(){}

    GridListingsFavoritesWidget.prototype.filtersChanged = function()
    {
        this._grid.filtersChanged();
    };

    GridListingsFavoritesWidget.prototype.getLocalyticsTabName = function()
    {
        return "Live TV: Favorites";
    };

    GridListingsFavoritesWidget.prototype.gotFocus = function()
    {
        _x2.requestFocus( this._grid );
    };

    /**
     * Initializer
     * @memberof GridListingsFavoritesWidget
     * @returns {GridListingsFavoritesWidget}
     */
    GridListingsFavoritesWidget.prototype.init = function()
    {
        Widget.prototype.init.call( this );
        this._className  = "GridListingsFavoritesWidget";
        this._selectable = true;

        this.setW( _x2._config._screenW );

        this._grid = new GridWidget().init( { w:_x2._config._screenW - Style._safeLeft, h:_x2._config._screenH, hasInfo:true, view:"favs" } );
        this.addWidget( this._grid, Style._safeLeft );

        return this;
    };

    GridListingsFavoritesWidget.prototype.loadData = function()
    {
        this._grid.refresh( screen );
    };

    GridListingsFavoritesWidget.prototype.refresh = function( screen )
    {
        this._grid.refresh( screen );
    };

    GridListingsFavoritesWidget.prototype.setJumpToChannel = function( channel )
    {
        this._grid.setJumpToChannel( channel );
    };

    GridListingsFavoritesWidget.prototype.startViewTimestamp = function()
    {
        this._timestampPageView = new Date();
    };

    GridListingsFavoritesWidget.prototype.stopViewTimestamp = function()
    {
        // Back Button while in gallery view, and view duration
        var whichTab = this.getLocalyticsTabName();
        this._durationPageView = new Date() - this._timestampPageView;
        window.ll( "tagEvent", "Back Button " + whichTab, { "Duration": this._durationPageView } );
        _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Live TV: Back Button: " +  whichTab + ", duration: " + this._durationPageView ) : Config.NOP();
    };

    return GridListingsFavoritesWidget;

})();
