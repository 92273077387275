// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.Features = (function()
{
    "use strict";

    Features.Key = Object.freeze
    ({
        TRANSACTIONS : "transactions",
    });

    Features.Entitlement = Object.freeze
    ({
        EST        : "est",
        C_VOD      : "cvod",
        C_DVR      : "cdvr",
        C_LINEAR   : "clinear",
        TVE_VOD    : "tve-vod",
        TVE_LINEAR : "tve-linear"
    });

    var LOCAL_STORAGE_KEY = "features";

    function Features()
    {
        var jsonString = Host.getLocalStorage( LOCAL_STORAGE_KEY );
        if( jsonString )
            this._data = JSON.parse( jsonString )
    }

    /**
     * Return true if we have features data.
     * @return {boolean}
     */
    Features.prototype.isDefined = function()
    {
        return this._data !== undefined;
    };

    Features.prototype.setData = function( data )
    {
        this._data = data;
    };

    Features.prototype.getFeature = function( key )
    {
        var data   = this._data,
            retval = data && data.features && data.features[ key ];

        return retval;
    };

    Features.prototype.getUserType = function()
    {
        return this._data['userType'];
    };

    Features.prototype.hasEntitlement = function( entitlement )
    {
        var data   = this._data,
            retval = data && data.entitlements && data.entitlements.indexOf( entitlement );

        return retval >= 0;
    };

    Features.prototype.saveToLocalStorage = function()
    {
        _x2._config._host.setLocalStorage( LOCAL_STORAGE_KEY, JSON.stringify( this._data ) );
    };

    return Features;
})();
