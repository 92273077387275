// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.FontScaleTestScreen = ( function()
{
    "use strict";

    FontScaleTestScreen.prototype = new Screen();

    function FontScaleTestScreen(){}

    FontScaleTestScreen.prototype.init = function()
    {
        var width, height;
        var headStyle, bodyStyle;
        var fontSize   = 28;
        var fontWeight = "light"; // "bold", "medium", "light"

        this._className = "FontScaleTestScreen";
        Screen.prototype.init.call( this );

        this._size    = fontSize;
        this._strings = [];
        this._lines   = [];

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        this._scale        = 224 / fontSize; // 224/28pt font = 8x scaling
        this._leadingScale = 1.0;
        this.buildFontStrings( fontWeight, fontSize * this._scale, Style._safeLeft*0.5, Style._safeTop ); // native 28 pt

        this._selectable = true;

        return this;
    };

    FontScaleTestScreen.prototype.buildFontStrings = function( fontWeight, fontSize, x, y )
    {
        var i, lines = [], label = [], rect, string;
        var styleBlue  = "#2ea0dd";
        var styleWhite = "#ffffff";
        var a_ring     = String.fromCharCode( 0xC5 );
        var para       = String.fromCharCode( 0xB6 );
        var o_acute    = String.fromCharCode( 0xD3 );

        label.push( para + a_ring + "g" + " @ " );
        lines.push( "" + fontSize + " " + a_ring );

        // NOTE: These strings
        //   Silicon Valley
        //   Pill
        // are specifically chosen to stress test 16 px sub-pixel accuracy
        label.push( o_acute + "Silicon Valley" ); // "" + (fontSize) );
        lines.push( "/O " + a_ring );

        label.push( "Pilldgy" );
        lines.push( "@? " + a_ring );

        var keyStyle  = [
            new Style( { font:fontWeight, color:"#FFFF00", fontSize:fontSize, overflow:"hidden" } ), // yellow
            new Style( { font:fontWeight, color:styleBlue, fontSize:fontSize, overflow:"hidden" } ), // blue
            new Style( { font:fontWeight, color:"#FF0000", fontSize:fontSize, overflow:"hidden" } )  // red
        ];
        var valStyle  = [
            new Style( { font:fontWeight, color:styleWhite, fontSize:fontSize, overflow:"hidden" } ), // white
            new Style( { font:fontWeight, color:"#8000FF" , fontSize:fontSize, overflow:"hidden" } ), // purple
            new Style( { font:fontWeight, color:"#00FF00" , fontSize:fontSize, overflow:"hidden" } )  // green
        ];

        var w = _x2._config._screenW;
        for( i = 0; i < lines.length; ++i )
        {
            string = new StringWidget().init( { text: label[i], style: keyStyle[ i % keyStyle.length ] } );
            this._strings.push( string );
            this.addWidget( string, x, y );

            if( i === 0 )
            {
                rect = new RectWidget().init( { w: w, h: 1, color: "#FFFFFF" } );
                this._lines.push( rect );
                this.addWidget( rect, 0, y );
            }

            rect = new RectWidget().init( { w: w, h: 1, color: i % 2 === 0 ? "#FF0000" : "#00FF00" } );
            this._lines.push( rect );
            this.addWidget( rect, 0, y + fontSize );

            string = new StringWidget().init( { text: lines[i], style: valStyle[ i % valStyle.length ] } );
            this._strings.push( string );
            this.addWidget( string, w*0.5, y );

            y += this._leadingScale*fontSize;
        }
        return y;
    };

    FontScaleTestScreen.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();
    };

    FontScaleTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true, size, ds, i, n;

        if( type === Host.KEY_PRESSED )
            switch( val )
            {
                case Host.KEY_LEFT:
                    this.resize( 16 );
                    break;

                case Host.KEY_RIGHT:
                    this.resize( 224 );
                    break;

                case Host.KEY_UP:
                    size = this._strings[0].getVal( Widget.FONT_SIZE );
                    ds   = (size / 10) | 0;
                    if((size +  ds) <= 224)
                        size += ds;
                    this.resize( size );
                    break;

                case Host.KEY_DOWN:
                    size = this._strings[0].getVal( Widget.FONT_SIZE );
                    ds   = (size / 10) | 0;
                    if((size - ds) >= 10)
                        size -= ds;
                    this.resize( size );
                    break;

                case Host.KEY_PAGE_DN:
                case Host.KEY_CH_DOWN:
                    n = this._strings.length;
                    for( i = 0; i < n; i++ )
                        this._strings[i].setVal( Widget.FONT_COLOR, "#FFFFFF" );
                    break;

                default:
                    retval = Screen.prototype.processEvent.call( this, val, type );
                    break;
            }

        return retval;
    };

    FontScaleTestScreen.prototype.resize = function( fontSize )
    {
        var i, n = this._strings.length, top = Style._safeTop, y, dy = this._leadingScale*fontSize;
        var a_ring = String.fromCharCode( 0xC5 );

        y = top;
        for( i = 0; i < n; i++ )
        {
            this._strings[i].setVal( Widget.FONT_SIZE, fontSize );
            this._strings[i].setY( y );

            if( (i > 0) && ((i & 1) === 1) )
                y += dy;
        }

        this._strings[1].setText( "" + fontSize  + " " + a_ring );
        //this._strings[2].setText( "" + fontSize );

        y = top + dy;
        for( i = 1; i < this._lines.length; i++ )
        {
            this._lines[ i ].setY( y );
            this._lines[ i ].setA( 0.25 + 0.75*fontSize/256 );
            y += dy;
        }
    };

    return FontScaleTestScreen;

})();

