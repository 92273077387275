// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.GuideViewRowWidget = ( function()
{
    "use strict";

    GuideViewRowWidget.prototype = new CollectionRowWidget();

    function GuideViewRowWidget(){}

    GuideViewRowWidget.prototype.init = function( params )
    {
        params.type = GalleryCellWidget;
        params.y    = _x2.scaleVal( 160 ) - _x2.scaleVal( 12 );
        params.h    = _x2.scaleValInt( 530 );

        CollectionRowWidget.prototype.init.call( this, params );
        this._className  = "GuideViewRowWidget";

        var style = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap" } );
        this._gvrwEmpty = new StringWidget().init( { style:style } );
        this._gvrwEmpty.setA( 0 );
        this.addWidget( this._gvrwEmpty );

        return this;
    };

    GuideViewRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 690 );
    };

    GuideViewRowWidget.prototype.setData = function( data )
    {
        CollectionRowWidget.prototype.setData.call( this, data );

        if( data )
        {
            if( data.getEntities().length === 0 )
            {
                this._gvrwEmpty.setText( data.getEmptyString() );
                this._gvrwEmpty.setY( this._title.getY() + this._title.getH() + 3 * Style._pad );
                this._gvrwEmpty.setA( 1 );
            }
            else
                this._gvrwEmpty.setA( 0 );
        }
    };

    return GuideViewRowWidget;

})();
