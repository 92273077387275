// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ProgressBarAndTextWidget = ( function()
{
    "use strict";

    ProgressBarAndTextWidget.prototype = new Widget();

    function ProgressBarAndTextWidget(){}

    ProgressBarAndTextWidget.prototype.getTextStatus = function()
    {
        return this._progressStr.getText();
    };

    /**
     * @param {Object} params
     * @param {String} params.type - "recordings", "scheduled", or undefined
     * @memberof ProgressBarAndTextWidget
     */
    ProgressBarAndTextWidget.prototype.init = function( params )
    {
        var self         = this;
        var percentStyle = new Style( { font:"regular", color:"#a3a3a3", fontSize:_x2.scaleValInt(20), whiteSpace:"nowrap" } );
        var h            = _x2.scaleValInt( 31 + 12 + 29 ); // padAbove + heightProgress + padBelow

        Widget.prototype.init.call( this, params );
        this._className  = "ProgressBarAndTextWidget";
        this._type       = params.type;

        this._progressBar = new ProgressBarWidget().init( { w: _x2.scaleValInt(150), h: _x2.scaleValInt(12), style:"full", percent:0 } );
        this.addWidget( this._progressBar, 0, _x2.scaleValInt( 31 ) );

        this._progressStr = new StringWidget().init( { text:"", style:percentStyle } );
        this.addWidget( this._progressStr );

        this.setH( h );
        this.setA( 0 );

        _x2._data.getRecorderSummary().then( function( result ){ self.onData( result ) } );

        return this;
    };

    /**
     * Start a new query and update the UI when ready
     * @memberof ProgressBarAndTextWidget
     * @param force - break caching
     */
    ProgressBarAndTextWidget.prototype.loadData = function( force )
    {
        var self = this;
        _x2._data.getRecorderSummary( force ).then( function( result ){ self.onData( result ) } );
    };

    /**
     * @memberof ProgressBarAndTextWidget
     * @param {RecorderSummary} summary
     */
    ProgressBarAndTextWidget.prototype.onData = function( summary )
    {
/*
        console.log( "*** Summary: %o", summary );
        console.table(
            [
                [ "Free %:"    , summary.getPercentFree()    ],
                [ "Used %:"    , summary.getPercentUsed()    ],
                [ "Recorded: " , summary.getTotalRecorded()  ],
                [ "Scheduled: ", summary.getTotalScheduled() ]
            ]
        );
// */
        this._summary = summary;

        var percent = 0;
        var total   = 0;

        switch( this._type )
        {
            case "recordings":
                percent = summary.getPercentUsed();
                total   = summary.getTotalRecorded();

                this.setProgress( percent, total + " Recordings" );
                break;

            case "scheduled":
                percent = summary.getPercentUsed();
                total   = summary.getTotalScheduled();

                this.setProgress( percent, total + " Scheduled" );
                break;

            default:
                break;
        }
    };


    /**
     * @memberof ProgressBarAndTextWidget
     * @param {Number} percent
     * @param {String} total along with description - i.e. "1 Scheduled", "2 Recordings"

     */
    ProgressBarAndTextWidget.prototype.setProgress = function( percent, total )
    {
        this._progressBar.setProgress( percent );
        this._progressStr.setText( "" + percent + "% Full | " + total );

        var w1 = this._progressBar.getW();
        var w2 = _x2.scaleValInt(18);
        var w3 = this._progressStr.getW();
        var x  = (_x2._config._screenW - (w1 + w2 + w3)) * 0.5;
        var y  = (this.getH() - this._progressStr.getH()) * 0.5;

        this._progressBar.setX( x           );
        this._progressStr.setX( x + w1 + w2 );

        this._progressStr.setY( y );

        this.animate( { a:1, duration:500 } );
    };

    return ProgressBarAndTextWidget;

})();

