// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ProgramPromoCellWidget = ( function()
{
    "use strict";

    ProgramPromoCellWidget.prototype = new CollectionCellWidget();

    function ProgramPromoCellWidget(){}

    ProgramPromoCellWidget.prototype.init = function( params )
    {
        var self = this;

        var titleStyle    = new Style( { color:"#e8e8e8", font:"medium" , fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap" } );
        var subTitleStyle = new Style( { color:"#a3a3a3", font:"regular", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap" } );

        var posterTitleStyle    = new Style( { color:"#e8e8e8", font:"medium" , fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", textAlign:"center" } );
        var posterSubTitleStyle = new Style( { color:"#a3a3a3", font:"regular", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", textAlign:"center" } );

        this._imageX = params.bgX     = _x2.scaleValInt( 19 );
        this._imageW = params.bgW     = _x2.scaleValInt( 480 );
        this._imageH = params.bgH     = _x2.scaleValInt( 270 );
        this._scale  = params.bgScale = (this._imageW + 2 * this._imageX) / this._imageW;
        this._imageY = params.bgY     = (this._scale - 1) * this._imageH / 2;

        this._focusW = this._scale * this._imageW;
        this._focusH = this._scale * this._imageH;

        params.background = true;
        params.bgTextSize = _x2.scaleValInt(32);

        CollectionCellWidget.prototype.init.call( this, params );
        this._className  = "ProgramPromoCellWidget";

        var imageError = function()
        {
            self._image.setA(0);
            self.showBackground();
        };

        var imageChanged = function()
        {
            self._image.setA(1);
            self.hideBackground();
        };

        this._image = new ImageWidget().init( { w:this._imageW, h:this._imageH, onError:imageError, onChange:imageChanged } );
        this.addWidget( this._image, this._imageX, this._imageY );

        this._title = new StringWidget().init( { style:titleStyle } );
        this._title.setA( 0 );

        this._subTitle = new StringWidget().init( { style:subTitleStyle } );
        this._subTitle.setA( 0 );

        this._posterText = new StringWidget().init( { style:posterTitleStyle } );
        this._posterText.setW( this._image.getW() );

        this._posterSubText = new StringWidget().init( { style:posterSubTitleStyle } );
        this._posterSubText.setW( this._image.getW() );

        this.addWidget( this._title   , 0, this._image.getH() + 2 * this._imageY + _x2.scaleValInt( 20 ) );
        this.addWidget( this._subTitle, 0, this._image.getH() + 2 * this._imageY + _x2.scaleValInt( 60 ) );
        this.addWidget( this._posterText   , this._imageX       , this._image.getH() * 2 / 3 );
        this.addWidget( this._posterSubText, this._imageX       , this._image.getH() * 2 / 3 + _x2.scaleValInt(38) );

        return this;
    };

    ProgramPromoCellWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 480 ) + _x2.scaleValInt( 19 );
    };

    ProgramPromoCellWidget.prototype.getTouchDim = function()
    {
        return _x2.scaleValInt( 270 ) * (_x2.scaleValInt( 480 ) + 2 * _x2.scaleValInt( 19 )) / _x2.scaleValInt( 480 );
    };

    ProgramPromoCellWidget.prototype.gotFocus = function()
    {
        CollectionCellWidget.prototype.gotFocus.call( this );

        if( this._image.getA() )
            this._image.animate( { xScale:this._scale, yScale:this._scale, duration:ListWidget.SCROLL_TIME_STEP } );

        if( this._posterText.getText() )
        {
            this._posterText.stopAnimation( Widget.X_SCALE );
            this._posterText.stopAnimation( Widget.Y_SCALE );
            this._posterText.animate( { xScale:this._scale, yScale:this._scale, duration:ListWidget.SCROLL_TIME_STEP } );

            this._posterSubText.stopAnimation( Widget.X_SCALE );
            this._posterSubText.stopAnimation( Widget.Y_SCALE );
            this._posterSubText.animate( { xScale:this._scale, yScale:this._scale, duration:ListWidget.SCROLL_TIME_STEP } );
        }

        this._title.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );
        this._subTitle.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );

        return this;
    };

    ProgramPromoCellWidget.prototype.lostFocus = function()
    {
        CollectionCellWidget.prototype.lostFocus.call( this );

        if( this._image.getA() )
        {
            this._image.stopAnimation( Widget.X_SCALE );
            this._image.stopAnimation( Widget.Y_SCALE );
            this._image.animate( { xScale: 1, yScale: 1, duration: ListWidget.SCROLL_TIME_STEP } );
        }

        if( this._posterText.getText() )
        {
            this._posterText.stopAnimation( Widget.X_SCALE );
            this._posterText.stopAnimation( Widget.Y_SCALE );
            this._posterText.animate( { xScale:1, yScale:1, duration:ListWidget.SCROLL_TIME_STEP } );

            this._posterSubText.stopAnimation( Widget.X_SCALE );
            this._posterSubText.stopAnimation( Widget.Y_SCALE );
            this._posterSubText.animate( { xScale:1, yScale:1, duration:ListWidget.SCROLL_TIME_STEP } );
        }

        this._title.animate( { alpha:0, duration:ListWidget.SCROLL_TIME_STEP } );
        this._subTitle.animate( { alpha:0, duration:ListWidget.SCROLL_TIME_STEP } );
    };

    ProgramPromoCellWidget.prototype.setData = function( data )
    {
        if( data !== undefined )
        {
            this._data = data;

            var link, url;

            if( this._data instanceof BrowseCollection )
            {
                this._image.setUrl( _x2._config._imageRoot + "view_all_wide.png" );

                this._title.setText("");
                this._subTitle.setText("");
                this._backgroundText.setText("");

                this._posterText.setText( "View All" );
                this._posterSubText.setText( data.getTotalEntityCount() + " available" );
            }
            else
            {
                link = this._data.getCollection().getProgramImageLink();

                if( link )
                    url  = XtvApi.replaceUrlParams( link, { entityId: data.getEntityId(), width: 320, height: 180 } );

                this._image.setUrl( url );
                this._title.setText( "" );
                this._subTitle.setText( "" );
                this._posterText.setText( "" );
                this._posterSubText.setText( "" );

                var title = this._data.getTitle();
                var subTitle = this._data.getSubtitle();

                if( title && subTitle )
                {
                    this._title.setText( title );
                    this._subTitle.setText( subTitle );
                }
                else if( title )
                    this._title.setText( title );
                else if( subTitle )
                    this._title.setText( subTitle );

                this.setBackgroundText( title );
            }

            this.setA( 1 );
        }
        else
            this.setA( 0.0 );
    };

    return ProgramPromoCellWidget;

})();
