// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ParentalControls = (function()
{
    "use strict";

    ParentalControls.SafeBrowse = {
        OFF   : { level: 0, key: "OFF" },
        MEDIUM: { level: 1, key: "MEDIUM" },
        HIGH  : { level: 2, key: "HIGH" }
    };

    /* Defines canonical XtvApi TV rating strings */
    ParentalControls.TvRatings = Object.freeze
    ({
        TVY : { key: 'TV-Y' , val:0 },
        TVY7: { key: 'TV-Y7', val:1 },
        TVG : { key: 'TV-G' , val:2 },
        TVPG: { key: 'TV-PG', val:3 },
        TV14: { key: 'TV-14', val:4 },
        TVMA: { key: 'TV-MA', val:5 }
    });

    /* Defines canonical XtvApi MPAA rating strings */
    ParentalControls.MpaaRatings = Object.freeze
    ({
        G    : { key: 'G'    , val:0 },
        PG   : { key: 'PG'   , val:1 },
        PG13 : { key: 'PG-13', val:2 },
        R    : { key: 'R'    , val:3 },
        NC17 : { key: 'NC-17', val:4 },
        ADULT: { key: 'ADULT', val:5 }
    });

    /* Defines canonical XtvApi rating advisories */
    ParentalControls.Advisories = {
        D : 'D',
        L : 'L',
        S : 'S',
        V : 'V',
        FV: 'FV'
    };

    ParentalControls.MOVIE_LOCK_KEY = 'mpaa';
    ParentalControls.TV_LOCK_KEY    = 'v-chip';

    function ParentalControls( data )
    {
        this._data = data;
    }

    /**
     * Return true if pin matches saved pin
     * @param pin {String}
     * @returns {Boolean}
     */
    ParentalControls.prototype.checkPin = function( pin )
    {
        return ( pin === this._data.pin );
    };

    /**
     * Get the current safe browse level
     * @param {ParentalControls.SafeBrowse} level
     * @returns {Boolean}
     */
    ParentalControls.prototype.checkSafeBrowse = function( level )
    {
        var safeBrowse = false;

        switch( this._data.safeBrowse )
        {
            case "MEDIUM":
                safeBrowse = ( ParentalControls.SafeBrowse.MEDIUM.level >= level );
                break;

            case "HIGH":
                safeBrowse = ( ParentalControls.SafeBrowse.HIGH.level >= level );
                break;
        }

        return safeBrowse;
    };

    ParentalControls.prototype.getSafeBrowse = function()
    {
        return this._data.safeBrowse;
    };

    /**
     * Return true if the channel is marked as locked
     * @param {Number} channelId
     * @returns {Boolean}
     */
    ParentalControls.prototype.isChannelLocked = function( channelId )
    {
        return ( this._data.enabled === true && this._data.channelLocks[channelId] === true );
    };

    /**
     * Return true if parental controls are enabled
     * @returns {Boolean}
     */
    ParentalControls.prototype.isEnabled = function()
    {
        return this._data.enabled;
    };

    /**
     * Return true if the mpaa rating level is locked
     * @param {ParentalControls.MpaaRatings | String} rating object or string (i.e. "PG")
     * @returns {Boolean}
     */
    ParentalControls.prototype.isMovieRatingLocked = function( rating )
    {
        var key = ( typeof rating === "object" ? rating.key : rating );
        var mpaaLock = this._data.ratingLocks[ParentalControls.MOVIE_LOCK_KEY][key];

        if( mpaaLock )
            return this._data.enabled === true && mpaaLock.locked;
        else
            return false;
    };

    /**
     * Return true if the network is locked
     * @param {Number} networkId
     * @returns {boolean}
     */
    ParentalControls.prototype.isNetworkLocked = function( networkId )
    {
        return ( this._data.enabled === true && this._data.networkLocks[networkId] === true );
    };

    /**
     * Return true if the TV rating or MPAA rating is locked
     * @param {ParentalControls.MpaaRatings|ParentalControls.TvRatings} rating
     * @param {ParentalControls.Advisories} advisories
     * @returns {Boolean}
     */
    ParentalControls.prototype.isRatingLocked = function( rating, advisories )
    {
        return this._data.enabled === true && ( this.isTvRatingLocked( rating, advisories ) || this.isMovieRatingLocked( rating ) );
    };

    /**
     * Return true if there is a matching title lock set
     * @param {Number} programId
     * @returns {Boolean}
     */
    ParentalControls.prototype.isTitleLocked = function( programId )
    {
        var lock = this._data.titleLocks[programId];

        if( lock )
            return this._data.enabled === true && lock.locked;
        else
            return false;
    };

    /**
     * Return true if the TV rating lock is set
     * @param {ParentalControls.TvRatings | String} rating object or string (i.e. "TV-MA")
     * @param {ParentalControls.Advisories} advisories
     * @returns {Boolean}
     */
    ParentalControls.prototype.isTvRatingLocked = function( rating, advisories )
    {
        var key = ( typeof rating === "object" ? rating.key : rating );
        var tvLock = this._data.ratingLocks[ParentalControls.TV_LOCK_KEY][key];

        if( this._data.enabled === true && tvLock )
        {
            if( tvLock.locked )
                return true;
            else if( advisories )
            {
                for( var i = 0; i < advisories.length; i++ )
                {
                    if( tvLock.advisories[advisories[i]] )
                        return true;
                }
            }
        }

        return false;
    };

    /**
     * Lock a channel
     * @param {Number} channelId
     * @param {Boolean} locked
     */
    ParentalControls.prototype.setChannelLocks = function( channelId, locked )
    {
        this._data.channelLocks[channelId] = locked;
    };

    /**
     * Turn on parental controls
     * @param {Boolean} enabled
     */
    ParentalControls.prototype.setEnabled = function( enabled )
    {
        this._data.enabled = enabled;
    };

    /**
     * Set a MPAA rating lock
     * @param {ParentalControls.MpaaRatings.key} rating
     * @param {Boolean} locked
     */
    ParentalControls.prototype.setMovieRatingLock = function( rating, locked )
    {
        var prop, valid = false;

        // validate rating string against ParentalControls.MpaaRatings
        for( prop in ParentalControls.MpaaRatings )
        {
            if( ParentalControls.MpaaRatings[prop]._key === rating._key )
                valid = true;
        }

        if( valid )
            this._data.ratingLocks[ParentalControls.MOVIE_LOCK_KEY][rating.key].locked = locked;
        else
            console.log( "Error: Cannot set movie rating lock. Invalid rating string: " + rating );
    };

    /**
     * Set a network lock
     * @param {Number} networkId
     * @param {Boolean} locked
     */
    ParentalControls.prototype.setNetworkLocks = function( networkId, locked )
    {
        this._data.networkLocks[networkId] = locked;
    };

    ParentalControls.prototype.setPin = function( pin )
    {
        this._data.pin = pin;
    };

    /**
     * Set the safe browse level
     * @param {ParentalControls.SafeBrowse} level
     */
    ParentalControls.prototype.setSafeBrowse = function( level )
    {
        _x2._network.resetGlobalCache();
        this._data.safeBrowse = level.key;
    };

    /**
     * Set a title lock
     * @param {Number} programId
     * @param {String} programTitle
     * @param {Boolean} locked
     */
    ParentalControls.prototype.setTitleLock = function( programId, programTitle, locked )
    {
        this._data.titleLocks[programId] = { locked: locked, title: programTitle };
    };

    /**
     * Set a TV ratings lock
     * @param {ParentalControls.TvRatings.key} rating
     * @param {Boolean} locked
     */
    ParentalControls.prototype.setTvRatingLocks = function( rating, locked )
    {
        var prop, valid = false, locks;

        // validate rating string against ParentalControls.TvRatings
        for( prop in ParentalControls.TvRatings )
            if( ParentalControls.TvRatings[prop] === rating )
                valid = true;

        if( valid )
        {
            locks        = this._data.ratingLocks[ParentalControls.TV_LOCK_KEY][rating.key];
            locks.locked = locked;

            for( prop in locks.advisories )
                locks.advisories[prop].locked = locked;
        }
        else
            console.log( "Error: Cannot set TV rating lock. Invalid rating string: " + rating );
    };

    return ParentalControls;

})();
