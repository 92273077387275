// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * Usage:
 *     sendGeneralError()
 *     sendVideoAttempt()
 *     sendVideoError()
 *     sendVideoStart()
 *
 * NOTE: Video Player Version is string in this format:
 *     BUILD_TYPE=prod VIPER_VERSION=##.##.## undefined
 *     BUILD_TYPE=prod VIPER_VERSION=##.##.## ##.##.##
 */
window.Splunk = ( function()
{
    "use strict";

    /**
     * Convert a string log message to an object.
     * Example: Converts 'key.1="val1" key2="val2"' to object { "key_1":"val1", "key2":"val2" }
     */
    Splunk.logMessageToObject = function( text )
    {
        var retval = {};
        var beg = 0, mid = text.indexOf( '=' ), end;
        var key, val;

        while( mid > 0 )
        {
            key = text.substring( beg  , mid   );
            end = text.indexOf  ( '"'  , mid+2 );
            val = text.substring( mid+2, end   );
            mid = text.indexOf  ( '='  , end+2 );
            beg = end + 2;
            retval[ key.replace( /\./g, '_' ) ] = val;
        }

        return retval;
    };

    function Splunk( token )
    {
        if( _x2._config )
            _x2._config.debugAddClass( Config.CLASS_SPLUNK, 0 );

        this._ua       = this.userAgentSplit();
        this._browser  = this.userAgentParse( this._ua );

        if( token === undefined )
            this._tokenSum = "";
        else
            this._tokenSum =
            [
                'user.analyticsId="'   + token.tokenSummary.analyticsId             + '"',
                'user.deviceId="'      + token.tokenSummary.deviceId                + '"',
                'user.mso="'           + token.tokenSummary.mso                     + '"',
                'user.partnerId="'     + token.tokenSummary.partnerId               + '"',
                'user.userType="'      + token.tokenSummary.userType                + '"',
                'user.xboAccountId="'  + token.tokenSummary.xboAccountId            + '"',
                'user.account_state="' + token.tokenSummary.accountState            + '"',
                'user.entitlements="'  + token.tokenSummary.entitlements.join(', ') + '"',
                'in_home="'  + (token.tokenSummary.inHomeStatus === 'in-home') + '"'
            ].join( " " );

//      _x2._config.log( Config.CLASS_SPLUNK, 1 ) ? console.log( "Protocol = " + this._protocol ) : Config.NOP();
//      _x2._config.log( Config.CLASS_SPLUNK, 1 ) ? console.log( "inHome = "   + this._inHome   ) : Config.NOP();
    }

    /**
     * @param {Object} entity - LinearShowing, ProgramEntity, Purchase, Recording, etc.
     * @param {Object} mode   - VideoScreen.Mode
     * @returns {String}      - one of Telemetry.VideoType
     */
    Splunk.prototype.getAssetType = function( entity, mode )
    {
        var retval = "Unknown";

        if( entity instanceof Purchase )
            retval = Telemetry.VideoType.PURCHASE;
        else
        {
            switch( mode ) // TVE not yet supported
            {
                case VideoScreen.Mode.DVR : retval = Telemetry.VideoType.RECORDING; break;
                case VideoScreen.Mode.LIVE: retval = Telemetry.VideoType.T6_LINEAR; break;
                case VideoScreen.Mode.VOD : retval = Telemetry.VideoType.T6_VOD   ; break;
            }
        }

        return retval;
    };

    /**
     * @param  {String} module          - "Video", "test"
     * @param  {String} type            - "Attempt", "Start", "Error"
     * @param  {String} [playerVersion] - Video player version, see note above
     * @return {String}                 - Flattened array of strings (key="val") separated by a space each
     */
    Splunk.prototype.getCommonLogMessage = function( module, type, playerVersion )
    {
        var playerVer = playerVersion ? playerVersion.indexOf( "VIPER_VERSION") : 0;
        var retval    =
        [
            'appname="stream-smart_tv"',
            'env="'                + environment.ENV               + '"',
            'user.appVersion="'    + AboutOverlay.version                       + '"',
            'user.userAgent.ua="'  + navigator.userAgent                        + '"'
        ];

        var featureStr = Host.getLocalStorage( "features" );
        var featureObj = featureStr && JSON.parse( featureStr );
        if( featureObj && featureObj.features )
        {
            var aKeys = Object.keys( featureObj.features );
            var nKeys = aKeys.length;
            var iKey;

            for( iKey = 0; iKey < nKeys; iKey++ )
            {
//console.log( "" + iKey + "): " + aKeys[ iKey ] + ": " + featureObj.features[ aKeys[ iKey ] ] );
                retval.push( 'user.features.' + aKeys[ iKey ] + '="' + featureObj.features[ aKeys[ iKey ] ] + '"' );
            }
        }

        if( module )
            retval.push( 'module="' + module + '"' );

        if( type )
            retval.push( 'type="' + type + '"' );

        if( this._browser.browserName && this._browser.browserVers )
        {
            retval.push( 'user.userAgent.browser.name="'    + this._browser.browserName       + '"' );
            retval.push( 'user.userAgent.browser.version="' + this._browser.browserVers       + '"' );
        }

        if( this._browser.browserMaj )
            retval.push( 'user.userAgent.browser.major="'   + this._browser.browserMaj        + '"' );

        if( this._browser.osName )
            retval.push( 'user.userAgent.os.name="'         + this._browser.osName            + '"' );

        if( this._browser.osVers )
            retval.push( 'user.userAgent.os.version="'      + this._browser.osVers            + '"' );

        if( this._browser.browserEngineName )
            retval.push( 'user.userAgent.engine.name="'     + this._browser.browserEngineName + '"' );

        if( this._browser.browserEngineVers )
            retval.push( 'user.userAgent.engine.version="'  + this._browser.browserEngineVers + '"' );
        if( _x2._config._host._product ) {
            var platformInfo = _x2._config._host.getPlatformInfo();
            retval.push( 'user.productInfo="'  + platformInfo.product  +  '"' );
            retval.push( 'user.devicePlatform="'  + platformInfo.product.toLowerCase()  +  '"' );
            retval.push( 'user.modelInfo="'  + platformInfo.model  +  '"' );
        }
        if( playerVer > 0 )
            retval.push( 'user.playerVersion="' + playerVersion.substring( playerVer + 14 ) + '"' ); // NOTE: #.#.# not the full build version description

        return retval.join( " " ) + ' ' + this._tokenSum;
    };

    /**
      * @param {Boolean}          success
      * @param {Entity}           entity
      * @param {TransactionOffer} offer
      * @param {Error}            error - or response
      * @param {Showing}         [showing]
      */
    Splunk.prototype.getTransactLogMessage = function( success, entity, offer, error, showing )
    {
        var purchase  = offer.getPurchaseType(),
            offerType = offer.getOfferType(),
            buyOrRent = (purchase === TransactionOffer.PurchaseType.BUY ? "buy" : "rent"),
            option    = buyOrRent + (offer.isHD() ? " HD" : " SD"),
            isSeason  = (offerType === TransactionOffer.OfferType.SEASON_BUNDLE ),
            series    = isSeason && entity.getSeries && entity.getSeries(),
            progType  = (entity.getType() === XtvApi.EntityType.MOVIE)
                      ? "Single Movie"    // *sigh* Someday this will be standardized across Localytics, Telemetry, and Splunk
                      : isSeason
                          ? "Season Bundle"
                          : "Single Episode",
            title     = isSeason
                          ? series.getTitle()
                          : entity.getTitle(),
            type      = isSeason
                          ? "Series"          // showing.getType() -> "Episode", series.getType() -> "SeriesMaster"
                          : entity.getType(),
            retval    =
            [
                'action="transact"'                              ,
                'transact.button="'      + buyOrRent        + '"',
                'transact.programType="' + progType         + '"',
                'transact.option="'      + option           + '"',
                'transact.price="'       + offer.getPrice() + '"',
                'asset.title="'          + title            + '"',
                'asset.type="'           + type             + '"'
            ];

        if( showing )
        {
            var mediaId = showing.getMediaId   && showing.getMediaId(),
                progId  = showing.getProgramId && showing.getProgramId(),
                stream  = showing.getStreamUrl && showing.getStreamUrl();

            if( mediaId ) retval.push( 'asset.mediaId="'           + showing.getMediaId()   + '"' );
            if( progId  ) retval.push( 'asset.programId="'         + showing.getProgramId() + '"' );
            if( stream  ) retval.push( 'asset.resolvedStreamUrl="' + showing.getStreamUrl() + '"' );
        }

        return retval.join( " " );
    };

    /** Union of Common and Video fields
     * @param {String} [module]           - Defaults to "Video" if not specified
     * @param {String}  type              - "Attempt", "Start", or over-ridden "Error"
     * @param {Object}  entity            - LinearShowing, ProgramEntity, Purchase, Recording, etc.
     * @param {Object}  mode              - VideoScreen.Mode
     * @param {String}  url               - URL of video asset
     * @param {String} [playerVersion]    - Video player version, see note above
     * @param {Number} [msAttemptToStart] - Time from Video Attempt to Video Start
     * @param {number} [duration]         - Video player duration of asset (not required for Linear)
     * @returns {String}                  - Flattened array of strings (key="val") separated by a spaces
     */
    Splunk.prototype.getVideoLogMessage = function( module, type, entity, mode, url, playerVersion, msAttemptToStart, duration )
    {
        var asType = this.getAssetType( entity, mode );
        var format = url
                   ? (url.indexOf( '.m3u8' ) > 0 ? "M3U" : "Unknown")
                   : "Unknown";
        var linear = mode === VideoScreen.Mode.LIVE;
        var retval = this.getCommonLogMessage( module ? module : 'Video', type, playerVersion );
        var stream = entity._data && entity._data._embedded && entity._data._embedded.encodesCreativeWork ? entity._data._embedded.encodesCreativeWork._type : undefined;

        var video  =
        [
            'vsid="'                        + this._vsid        + '"',
            'playerVersion="'               + playerVersion     + '"',
            'asset.type="'                  + asType            + '"',
            'asset.title="'                 + this.getTitleByType(entity) + '"',
            'asset.resolvedStreamUrl="'     + url               + '"',
            'asset.stream.encodingFormat="' + format            + '"',
            'isDownload="false"'
        ];
        if( entity.getChannel && entity.getChannel() && entity.getChannel().getStreamId() ) // linear
            video.push( 'asset.stream.streamId="' + entity.getChannel().getStreamId() + '"' );

        if( !stream ) // TODO: Always use???
            stream = entity._data && entity._data._type;

        if( stream )
            video.push( 'asset.stream._type="' + stream + '"' );

        if( entity.getMediaId && entity.getMediaId() )
            video.push( 'asset.mediaId="' + entity.getMediaId() + '"' );

        if( msAttemptToStart !== undefined )
            video.push( 'videoStartTime="' + msAttemptToStart + '"' );

        if( !linear )
        {
            var entityDuration = entity.getDurationMs && entity.getDurationMs();
            if( entityDuration && (duration > entityDuration) )
                duration = entityDuration;

            if( entityDuration !== undefined ) video.push( 'asset.totalDuration="'    + Math.floor(entityDuration / 1000) + '"' );
            if( duration                     ) video.push( 'asset.timelineDuration="' + Math.floor(duration       / 1000) + '"' );
        }

        retval += ' ' + video.join( " " );

//console.log( retval );
        return retval;
    };

    /*** This is unique per playback attempt
     * @return {String}
     */
    Splunk.prototype.getVSID = function()
    {
        var now       = Date.now(), rnd, dig;
        var onReplace = function( c )
        {
            rnd = (now + Math.random() * 16) % 16 | 0;
            now = Math.floor( now / 16 );
            dig = (c === 'x') ? rnd : (rnd & 0x3 | 0x8);

            return dig.toString( 16 );
        };

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, onReplace );
    };

    /**
     * @param {Boolean}         success
     * @param {String}          api - such as "getChannelMap", "transact", etc.
     * @param {Object|ApiError} response
     */
    Splunk.prototype.getXtvApiLogMessage = function( success, api, response )
    {
        var retval = this.getCommonLogMessage( "XTVAPI", success ? "Success" : "Error", undefined ) + " " +
            [
                'serviceName="'    + api                          + '"',
                'httpStatusCode="' + response.statusCode          + '"',
                'responseTime="'   + response.responseTimeNetwork + '"',
                'endpoint="'       + response.path                + '"'
            ].join(" ");

        return retval;
    };

    /** Make a Splunk object ready for send()
     * @param {String} logMessage - syntax is '<key>="<val1>" <key2>="<val2>" ...'
     * @return {Object}
     */
    Splunk.prototype.makeEvent = function( logMessage )
    {
        var retval =
        {
            "splunkEvent": // subSourceType and logLevel will be added when send() is called
            {
                "logMessage": logMessage
            }
        };

        return retval;
    };

    /**
     * Normally called by XtvApi.get() when the promise if resolved or rejected
     * @param {Boolean}  success
     * @param {String}   api
     * @param {Object}   responseOrError  - Network.ajax response or ApiError: must have fields 'path', 'responseTimeMS', and 'statusCode'
     * @param {Object}  [params]          - passed when called by sendTransact
     * @param {Object}  [transformedData] - used by getChannelMap
     */
    Splunk.prototype.onXtvApi = function( success, api, responseOrError, params, transformedData )
    {
        var log;

        if( api === undefined || responseOrError === undefined )
            return;

        if( api === "getChannelMap" )
        {
/*
            var channel = 0, entitled = 0, total = 0, valid = (transformedData !== undefined) && Array.isArray( transformedData );
            log = this.getXtvApiLogMessage( success, api, responseOrError );

            if( valid )
            {
                total = transformedData.length;
                for( ; channel < transformedData.length; channel++ )
                    entitled += (transformedData[channel].isEntitled() | 0);
            }

            log += " " +
            [
                'channels.total="'      + total              + '"',
                'channels.entitled="'   + entitled           + '"',
                'channels.unentitled="' + (total - entitled) + '"',
                'channels.diff="0"'
            ].join(" ");

            this.send( this.makeEvent( log ) )
*/;
        }
        else if( api === "transact" )
        {
            log = this.getXtvApiLogMessage( success, api, responseOrError ) + " "
                + this.getTransactLogMessage( success, params.entity, params.offer, responseOrError, params.showing );

            this.send( this.makeEvent( log ) );
        }
    };

    Splunk.prototype.parseOSVersion = function( offset )
    {
        var retval;
        var text = this._ua.comment_0;
        var i, n = text && text.length;
        var state = 0;

        for( i = offset; i < n; i++ )
        {
            switch( state )
            {
                case 0: // while( !digit )
                    if( (text[ i ] >= '0') && (text[ i ] <= '9') )
                    {
                        retval = text[ i ];
                        state  = 1; // found digit
                    }
                    else if( text[i] === ')' ) // couldn't find version
                        state = 2;
                    break;
                case 1: // while( digit )
                    if( (text[ i ] >= '0') && (text[ i ] <= '9') )
                        retval += text[ i ];
                    else if( (text[ i ] === '.') || (text[ i ] === '_') )
                        retval += '.';
                    else
                        state = 2; // end of version
                    break;
                case 2:
                    i = n;
                    break;
            }
        }

        return retval;
    };

    Splunk.prototype.send = function( obj )
    {
        if( _x2._isSplunkLogging && obj )
        {
            if( obj.lType === undefined )
                obj.lType = "SPLUNK";

            if( obj.mType === undefined )
                obj.mType = "xstream.SPLUNK_LOG";

            if( obj.splunkEvent === undefined )
                obj.splunkEvent = {};

            if( obj.splunkEvent && obj.splunkEvent.subSourceType === undefined )
                obj.splunkEvent.subSourceType = "xstream-smart_tv";

            if( obj.splunkEvent && obj.splunkEvent.logLevel === undefined )
                obj.splunkEvent.logLevel = "WARN"; // Note: needs to be WARN or higher

            if( obj.splunkEvent && obj.splunkEvent.logMessage === undefined )
                obj.splunkEvent.logMessage = "ERROR: Missing Log Message";
            _x2._metricsSocket.send( obj );
        }
    };

    /**
     * Log a general error to Splunk
     * @param {String}  errorDesc  - description of the error
     * @param {String} [errorCode] - TVAPP-###### such as TVAPP-00100
     */
    Splunk.prototype.sendGeneralError = function( errorDesc, errorCode )
    {
        var screen = _x2.findScreen( VideoScreen );
        var common = screen && screen._entity
                   ? this.getVideoLogMessage( "Diagnostic", "VideoPlayer", screen._entity, screen._mode, screen._url, undefined, undefined, undefined )
                   : this.getCommonLogMessage( "General", "Error", undefined )
                   ;
        var extra  =
        [
            'error.level="' + (screen === undefined ? 'fatal' : 'warn') + '"',
            'error.msg="'   + errorDesc                                 + '"'
        ];

        if( errorCode )
            extra.push( 'error.code="' + errorCode + '"' );

        if( screen )
        {
            extra.push( 'traceMessage="Video Exception"' );
            extra.push( 'videoCurrentPosition="' + screen._current  + '"' ); // Don't typecast to number as undefined is valid -- stream hasn't started
            extra.push( 'videoAttempts="'        + screen._attempts + '"' ); // Ditto.
        }

        var multiLine = errorDesc.indexOf( '\n' ); // Stack trace has multiple lines
        if( multiLine > 0 )
        {
            var summary = errorDesc.substr( 0, multiLine );
            extra.push( 'error.reason="' + summary + '"' );
        }

        extra.push( 'error.appurl="' + window.location.href + '"' );

        var log = common + ' ' + extra.join(" ");

        this.send( this.makeEvent( log ) );
    };

    /**
     * Send on the result of an XTVAPI 'transact'
     * @see TransactionOffer.executeTransaction()
     * @param {Boolean}          success - whether the XTV API transact call was successful or not
     * @param {Entity}           entity
     * @param {TransactionOffer} offer
     * @param {Object}          [responseOrError] - ApiError or response
     */
    Splunk.prototype.sendTransact = function( success, entity, offer, responseOrError )
    {
        var self = this,
            onShowing = function( response )
            {
                var showing = new VodShowing().init( JSON.parse( response.data ) );
                self.onXtvApi( success, "transact", responseOrError, { entity:entity, offer:offer, showing:showing }, undefined );
            };

        if( offer )
            offer.getLinkObject("featureAsset").resolve().then( onShowing );
    };

    /**
     * Generates VSID and sends Video Attempt
     * @param {Object}  entity         - LinearShowing, ProgramEntity, Purchase, Recording, etc.
     * @param {Object}  mode           - VideoScreen.Mode
     * @param {String}  url            - URL of asset
     * @param {String} [playerVersion] - Video player version, see note above
     * @param {number} [duration]      - Video player duration of asset (not required for Linear)
     */
    Splunk.prototype.sendVideoAttempt = function( entity, mode, url, playerVersion, duration )
    {
        this._vsid = this.getVSID();

        var log = this.getVideoLogMessage( 'Video', 'Attempt', entity, mode, url, playerVersion, undefined, duration );
        this.send( this.makeEvent( log ) );
    };

    /**
     * @param {Object}  entity            - LinearShowing, ProgramEntity, Purchase, Recording, etc.
     * @param {Object}  mode              - VideoScreen.Mode
     * @param {String}  url               - URL of asset
     * @param {String} [playerVersion]    - Video player version, see note above
     * @param {number} [msAttemptToStart] - Time from Video Attempt to Video Start
     * @param {number} [duration]         - Video player duration of asset (not required for Linear)
     */
    Splunk.prototype.sendVideoStart = function( entity, mode, url, playerVersion, msAttemptToStart, duration )
    {
        var log = this.getVideoLogMessage( 'Video', 'Start', entity, mode, url, playerVersion, msAttemptToStart, duration );
        this.send( this.makeEvent( log ) );
    };

    /**
     * @param {Object}  entity         - LinearShowing, ProgramEntity, Purchase, Recording, etc.
     * @param {String}  errorCode      - "TVAPP-#####"
     * @param {String}  errorDesc      - Description of the error
     * @param {String}  errorMajor     - major code for the error
     * @param {String}  errorMinor     - minor code for the error
     * @param {Object}  mode           - VideoScreen.Mode
     * @param {String}  url            - URL of asset
     * @param {String} [playerVersion] - Video player version, see note above
     * @param {number} [duration]      - Video player duration of asset (not required for Linear)
     */
    Splunk.prototype.sendVideoError = function( entity, errorCode, errorDesc, errorMajor, errorMinor, mode, url, playerVersion, duration )
    {
        if( typeof errorMajor === "number" )
            errorMajor = errorMajor.toString();

        var video = this.getVideoLogMessage( 'Video', 'Error', entity, mode, url, playerVersion, undefined, duration );
        var split = errorMajor ? errorMajor.indexOf( '.' ) : 0; // VideoScreen.onMediaFailed() can call us without a valid major error code
        var major = split > 0 ? errorMajor.substring(       0, split             )                                        : errorMajor;
        var minor = split > 0 ? errorMajor.substring( split+1, errorMajor.length ) + (errorMinor ? "." + errorMinor : '') : errorMinor ? errorMinor : "0";
        // 'TVAPP-00417' - notEntitledToVOD
        // 'TVAPP-00401' -  notEntitledToStation
        errorCode = (errorCode === 'TVAPP-00401')? 'TVAPP-00417' : errorCode;
        var errorLevel = (errorCode === 'TVAPP-00417') ? 'warn' : 'fatal';
        var error =
        [
            'error.level="' + errorLevel + '"',
            'error.code="'  + errorCode  + '"',
            'error.msg="'   + errorDesc  + '"'
        ];

        if( errorMajor !== undefined )
        {
            error.push( 'error.major="' + major + '"' );
            error.push( 'error.minor="' + minor + '"' );
        }

        var log = video + ' ' + error.join(" ");
        this.send( this.makeEvent( log ) );
    };
    /**
     * Splunk log to send provision failure error
     * @param {*} traceObj 
     * @param {*} errorCode
     */
    Splunk.prototype.sendProvisionError = function( errorCode, traceObj ) {
        var common = this.getCommonLogMessage( "Diagnostic", "ProvisionError", undefined );
        var extra  =
        [
            'error.level="warn"',
            'error.msg="Provision not completed."'
        ];

        if( errorCode )
            extra.push( 'error.code="' + errorCode + '"' );
        if( traceObj )
            extra.push( 'error.traceObj="' + JSON.stringify(traceObj) + '"')

        extra.push( 'error.appurl="' + window.location.href + '"' );

        var log = common + ' ' + extra.join(" ");

        this.send( this.makeEvent( log ) );
    }
    /**
     * Splunk log to send provision success
     * @param {*} userObj
     */
    Splunk.prototype.sendProvisionSuccess = function(userInfo) {
        var common = this.getCommonLogMessage( "Diagnostic", "ProvisionSuccess", undefined );
        var extra  =
        [
            'user.accountInfo="'+ JSON.stringify(userInfo) + '"',
            'in_home="' + (userInfo.inHomeStatus === 'in-home') + '"'
        ];

        var log = common + ' ' + extra.join(" ");

        this.send( this.makeEvent( log ) );
    }
    /**
     * Splunk log for head up messages in 2017 models
     */
    Splunk.prototype.sendScreenViewedEvent = function(screenName) {
        var common = this.getCommonLogMessage( "AppMetrics", "screen_viewed", undefined );
        var extra  =
        [
            'screen="'+ screenName + '"',
            'appUrl="' + window.location.href + '"'
        ];

        var log = common + ' ' + extra.join(" ");

        this.send( this.makeEvent( log ) );
    }
    /**
     * Map the Browser's User Agent object into an Splunk format friendly object
     * @param {Object} ua - Object of key/val's from the user agent
     * @returns {Object}
     * @see userAgentParse() userAgentSplit()
     */
    Splunk.prototype.userAgentParse = function( ua )
    {
        var retval = {}, i;

        if( ua )
        {
            if( ua.Chrome )
            {
                retval.browserName = 'Chrome';
                retval.browserVers = ua.Chrome;
            }
            else if( ua.Firefox )
            {
                retval.browserName = 'Firefox';
                retval.browserVers = ua.Firefox;
            }
            else if( ua.Safari )
            {
                retval.browserName = 'Safari';
                retval.browserVers = ua.Safari;
            }

            if( retval.browserVers )
                retval.browserMaj = parseInt( retval.browserVers );

            if( ua.comment_0 )
            {
                if(      (i = ua.comment_0.indexOf( 'Mac OS X' )) !== -1 ) retval.osName = 'Mac OS';
                else if( (i = ua.comment_0.indexOf( 'Android'  )) !== -1 ) retval.osName = 'Android';
                else if( (i = ua.comment_0.indexOf( 'iPhone'   )) !== -1 ) retval.osName = 'iOS';
                else if( (i = ua.comment_0.indexOf( 'Windows'  )) !== -1 ) retval.osName = 'Windows';

                if( retval.osName )
                    retval.osVers = this.parseOSVersion( i );
            }

            if( ua.AppleWebKit )
            {
                retval.browserEngineName = 'WebKit';
                retval.browserEngineVers = ua.AppleWebKit;
            }
            else
            if( ua.Gecko )
            {
                retval.browserEngineName = 'Gecko';
                retval.browserEngineVers = ua.Gecko;
            }
        }

        return retval;
    };

    /**
     * Split a Browser's User Agent string (navigator.userAgent) into an object.
     * The UA string is in the form:
     *     "key1/val1 (optional) key2/val2 (optional2) ..."
     * The resultant object may include fields as:
     * {
     *    Chrome: "#.#.#.#"
     *    Mozilla: "#.#"
     *    _comments: n
     *    comment_1: "(Macintosh; Intel Mac OS X 10_13_4)"
     *     :
     *    comment_n: "(KHTML, like Gecko)"
     * }
     * @returns {Object}
     * @see userAgentParse() userAgentSplit()
     */
    Splunk.prototype.userAgentSplit = function()
    {
        // RFC2616 Spec
        //   https://www.ietf.org/rfc/rfc2616.txt
        // Examples:
        //   https://deviceatlas.com/blog/list-of-user-agent-strings
        // TL:DR;
        //   key/val: <text>/<text>
        //   comment: ( ... )
        //
        //     sep             end
        //     v               v
        // key1/val1 (optional) key2...
        //           ^        ^
        //           idx      end
        var retval = {};
        var ua = navigator ? navigator.userAgent : "";
        var idx, end, sep, key, val, len = ua.length, comment = 0;

        for( idx = 0; idx < len; idx++ )
        {
            key = undefined;

            if( ua[ idx ] === '(' )
            {
                end = ua.indexOf( ')', idx+1 ) + 1;
                key = 'comment_' + comment++;
                val = ua.substring( idx, end );
            }
            else
            {
                sep = ua.indexOf( '/', idx );
                end = ua.indexOf( ' ', idx );

                if( end < 0 )
                    end = len;

                key = ua.substring( idx  , sep );
                val = ua.substring( sep+1, end );
            }

            if( end > 0 )
            {
                idx = end;
                if( key )
                    retval[key] = val;
            }
        }

        retval._comments = comment;
        return retval;
    };
    /**
     *  In splunk log the title for episode should be sent in below format
     *  - "Outlander S5 Ep8 - Famous Last Words" (TV Show name + Sesson # Episode # + - + Episode name)
     *  - For other video type, this should sent as it from getTitle()
     */
    Splunk.prototype.getTitleByType = function(entity) {
        var title = '';
        if( entity.getType() == 'Episode' ) {
            var program = entity.getProgram();
            if( program ) {
                var series = program.getSeries();
                title += series.getTitle();
                title += ' S' + program.getSeasonNumber();
                title += ' Ep' + program.getEpisodeNumber();
                title += ' - ' + entity.getTitle();
            }
        }
        if( title == '') {
            title = entity.getTitle();
        }
        return title;
    }

    return Splunk;

})();

