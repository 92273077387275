// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.DepreciationNoticeOverlay = (function () {
    "use strict";

    DepreciationNoticeOverlay.prototype = new Overlay();

    function DepreciationNoticeOverlay() { }

    DepreciationNoticeOverlay.prototype.gotFocus = function () {
        var focus = _x2.requestFocus(this._buttons, true);
        var str = this._title.getText();

        this._speakTarget.setText(str + ". " + focus._speakStr + ". ");
        this._speakTarget.setSpeechParams("Press OK to dismiss.", focus._speakRole, this, false);
    };

    DepreciationNoticeOverlay.prototype.init = function (params) {
        var accept;
        var self = this;
        params = params || {};
        if (params.onClosed) {
            this.onClosed = params.onClosed;
        }
        var titleStyle = new Style({ font: "light", color: "#e8e8e8", fontSize: _x2.scaleValInt(48), textAlign: "center" });
        var buttonStyle = new Style({ font: "regular", color: "#e8e8e8", fontSize: _x2.scaleValInt(32), whiteSpace: "nowrap", colorHi: "#000000" });

        Overlay.prototype.init.call(this, { onDismiss: this.onClosed });
        this._className = "DepreciationNoticeOverlay";

        this._title = new StringWidget().init({
            text: "Soon Xfinity Stream will no longer be available on this TV.\n Learn more at xfin.tv/streamontv",
            style: titleStyle
        });
        this._title.setW(this._bg.getW());
        this._bg.addWidget(this._title, 0, _x2.scaleVal(243));
        accept = new StringWidget().init({ text: "OK", style: buttonStyle, onEnter: self.dismiss.bind(self) });

        this._buttons = new HorizPillButtonsWidget().init({ entries: [accept], spacing: _x2.scaleValInt(40) });
        this._buttons.addReadyCallback(function () { self.layoutDepreciationNoticeOverlay(); });
        this._bg.addWidget(this._buttons);

        return this;
    };
    DepreciationNoticeOverlay.prototype.dismiss = function () {
        _x2.popOverlay(this);
        if (this.onClosed)
            this.onClosed()
    };
    DepreciationNoticeOverlay.prototype.layoutDepreciationNoticeOverlay = function () {
        var gap = _x2.scaleVal(50);
        var h = this._title.getH() + gap + this._buttons.getH();
        var y = (this._bg.getH() - h) / 2;

        this._title.setY(y);
        this._buttons.setY(y + this._title.getH() + gap);
        this._buttons.setX((this._bg.getW() - this._buttons.getW()) / 2);
    };

    return DepreciationNoticeOverlay;

})();
