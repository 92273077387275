// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

"use strict";

window.Network = ( function()
{
    Network.Accepts = {
        all : "*",
        text: "text/plain",
        html: "text/html",
        xml : "application/xml, text/xml",
        json: "application/json, text/javascript",
        xtv : "application/comcast+hal+json, application/json, */*;"
    };

    /**
     * Initialize network
     * @constructor
     */
    function Network()
    {
        _x2._config.debugAddClass( Config.CLASS_NETWORK, 0 );
        this._globalCacheInc = 0;
        this._urlCacheTimestamps = [];
        this._pendingRequests = [];
    }

    /**
     * Convenience method for ajax get
     * @param url - string - network url to call
     * @returns Promise
     */
    Network.prototype.get = function( url, timeout )
    {
        return this.ajax( { type:"GET", url:url, timeout:timeout } );
    };

    /**
     * Convenience method for ajax put
     * @param url
     * @param data
     * @returns Promise
     */
    Network.prototype.put = function( url, data )
    {
        return this.ajax( { type:"PUT", url:url, data:data } );
    };

    Network.prototype.resetGlobalCache = function()
    {
        this._globalCacheInc++
    };

    /**
     * Make an AJAX call
     * @param {*}        params
     * @param {string}   params.url           - resource URL
     * @param {[*]}     [params.headers]      - array of key:value pair objects to set headers
     * @param {string}  [params.type]         - call type (GET, PUT, POST)
     * @param {string}  [params.accepts]      - set 'Accept' header with this value (see Network.Accepts above)
     * @param {*}       [params.responseType] - set http req responseType
     * @param {string}  [params.contentType]  - set 'Content-Type' header with this value
     * @param {*}       [params.data]         - data to send
     * @param {*}       [params.api]          - api name. useful for mapping errors to a url pattern (for example when following links). XtvApi method calls normally have api set in XtvApi.get()
     * @param {boolean} [params.bustCache]    - increment the cache version for this url and force a network fetch
     * @returns Promise
     */
    Network.prototype.ajax = function( params )
    {
        if( ! this._xClientInfo )
            this._xClientInfo = _x2._config._host.getClientInfo();

        if( !params || !params.url )
            return Promise.reject( new ApiError().init( { local:"Network.ajax: Url is required"} ) );
        else
        {
            var self = this;
            var type = params.type ? params.type : 'GET';
            var promise = new Promise(function (resolve, reject)
            {
                var token;
                var req = new XMLHttpRequest();

                var load = function ()
                {
                    if( req.status < 400 )
                        resolve( { data:req.response, path:req.responseURL, statusCode:req.status, responseTimeNetwork:Date.now() - self._responseTimeStart } );
                    else
                    {
                        var error = new ApiError().init( { http:req, api:params.api } );

                        error.path                = req.responseURL;
                        error.statusCode          = req.status;
                        error.responseTimeNetwork = Date.now() - self._responseTimeStart;

                        if( error.getMajor() === "403" && error.getMinor() === "103" ) //403-103 = Bad Cloud DVR Token
                        {
                            console.log( "TOKEN REFRESH" );
                            self._pendingRequests.forEach( function (pendingRequest){
                                pendingRequest.abort();
                            });
                            var onSession = function( response )
                            {
                                self._refreshPromise = undefined;

                                console.log( response );
                                console.log( params );

                                if( response )
                                {
                                    _x2._config._host.setLocalStorage( "XsctToken", response );
                                    _x2._data.setAuthHeader();
                                }
                                token = response && JSON.parse( response )['xsct'];
                                //If there is pending cancelled request, then we need to call all api again, instead of wait for api response one by one & resend it
                                if( self._pendingRequests.length ) {
                                    _x2.sessionCreated();
                                    self._pendingRequests = [];
                                } else {
                                    _x2._data.getParentalControls().then( function( pc ) { console.log( pc ); _x2._pc = pc; } );
                                    if( params.headers && token )
                                    {
                                        for( var i = 0; i < params.headers.length; i++ )
                                            if( params.headers[i].name === "Authorization" )
                                            {
                                                params.headers[i].value = "CCP " + token;
                                                break;
                                            }
                                    }
    
                                    self.ajax( params ).then( resolve, reject );
                                }
                            };

                            var onSessionError = function( e )
                            {
                                console.log( e.toString() );
                                reject( new ApiError().init( { http:e } ) );
                            };

                            if( self._refreshPromise )
                                self._refreshPromise.then( onSession, onSessionError );
                            else
                                self._refreshPromise = _x2.provision().then( onSession, onSessionError );
                        }
                        else
                            reject( error );
                    }
                };

                var error = function ()
                {
                    reject( new ApiError().init( { local:"Network.ajax: Network Error" } ) );
                };

                var onTimeout = function () {
                    console.log("Network timeout", params.url );
                    reject({ data: null, url: params.url , msg: "Network timeout"});
                }

                var isXtvApiCall = params.url.indexOf( "xtvapi" ) !== -1;

                //set universal cache-busting parameter for all xtvapi "GET" calls if _globalCacheInc has been set.
                if( self._globalCacheInc > 0 && isXtvApiCall === true && params.type === "GET" ) {
                    if( params.url.indexOf('cirrusCacheBust=') > -1 ) { //if url already has cirrusCacheBust, then we need to replace value instead of append it
                        params.url = params.url.replace(/cirrusCacheBust=\d{1,3}/, 'cirrusCacheBust=' + self._globalCacheInc );
                    } else {
                        params.url += (params.url.indexOf( "?" ) !== -1 ? "&" : "?") + "cirrusCacheBust=" + self._globalCacheInc;
                    }
                }

                var timestamp = self._urlCacheTimestamps[params.url];

                //if bustCache parameter is set then set a new timestamp for this url to force a server round trip
                if( params.bustCache )
                {
                    timestamp = Date.now();
                    self._urlCacheTimestamps[params.url] = timestamp;
                }

                //if there is a timestamp for this url always set the url param so we get the most recent version.
                if( timestamp )
                    params.url += (params.url.indexOf( "?" ) !== -1 ? "&" : "?") + "time=" + timestamp;

                req.addEventListener( "load", load );
                req.addEventListener( "error", error );
                req.addEventListener( "timeout", onTimeout );
                req.open(type, params.url);

                if( params.url.indexOf("xtvapi") > 0 )
                {
                    var clientPlatform;

                    if( _x2._config._itv )
                        clientPlatform = "web; linear-tve";
                    else if( _x2._config._xocPartner )
                        clientPlatform = "roku; linear-ftm; standard-sports-images; session";  // HACK using roku until html5 specific tag available
                    else
                        clientPlatform = "html5; linear-ftm; standard-sports-images; session";

                    if( _x2._config._purchases && _x2._features.hasEntitlement( Features.Entitlement.EST ) )
                        clientPlatform += "; est";
                    if( _x2._config._appendHeaders ) {
                        clientPlatform += "; " + _x2._config._appendHeaders;
                    }
                    req.setRequestHeader("Client-Platform", clientPlatform);
                }

                if( params.responseType )
                    req.responseType = params.responseType;

                if( params.contentType )
                    req.setRequestHeader('Content-Type', params.contentType );

                if( params.accepts )
                    req.setRequestHeader( 'Accept', params.accepts );

                if( params.timeout !== undefined )
                    req.timeout = params.timeout;

                if( isXtvApiCall )
                    req.setRequestHeader( 'X-Finity-Client-Info', self._xClientInfo );

                if( params.headers && params.headers.length )
                {
                    for( var i=0; i<params.headers.length; i++ )
                        req.setRequestHeader(params.headers[i].name, params.headers[i].value);
                }

                self._responseTimeStart = Date.now();
                if( params.cancelOnDemand ) {
                    self._pendingRequests.push(req);
                }
                if( params.data && params.data.length )
                    req.send(params.data);
                else
                    req.send();
            });
        }

        return promise;
    };

    return Network;

})();
