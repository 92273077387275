// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.TabbedPillButtonTestScreen = ( function()
{
    "use strict";

    TabbedPillButtonTestScreen.prototype = new Screen();

    function TabbedPillButtonTestScreen(){}

    // Testing for: Menu item with focus has white text instead of colored text -- test String.gotFocus() fontSize: this._selectSize === undefined
    TabbedPillButtonTestScreen.prototype.init = function()
    {
        var self = this;
        this._className = "TabbedPillButtonTestScreen";

        Screen.prototype.init.call( this );

        var onEnter = function()
        {
//console.log( "Focus = %o", _x2._focus );
            _x2.requestFocus( self._item );
        };

        var buttonStyle = new Style( { color:"#e5e5e5", colorHi:"#1199FF", font:"light", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap" } );

        var string1 = new StringWidget().init( { text: "ABC", style: buttonStyle, onEnter: onEnter, focusTime:300  } );
        var string2 = new StringWidget().init( { text: "123", style: buttonStyle, onEnter: onEnter, focusTime:300  } );

        var entries =
        [
            string1,
            string2
        ];
        this._entries = entries;

// SavedScreen does: widget.setFocusListeners( onGotFocus, onLostFocus );
//    for( var i = 0; i < this._entries.length; i++ )
//        entries[ i ].setFocusListeners( onGotFocus, onLostFocus );

        this._menu = new TabbedPillButtonsWidget().init( { entries:entries, spacing:_x2.scaleValInt(60) } );
        this._menu.addReadyCallback ( function() { self.layoutMenu(); self._buttonsReady = true; } );

//this._menu.setFocusListeners( onGotFocus, onLostFocus );

        this.addWidget( this._menu, Style._safeLeft, Style._safeTop*2 );
        this.addReadyCallback( function() { self.layoutMenu(); } );

        this._rectHasFocus = new RectWidget().init( { w: 300, h: 400, color: "#22AA22" } );
        this._rectNotFocus = new RectWidget().init( { w: 300, h: 400, color: "#AA2222" } );

        this._rectHasFocus.setA( 0 );
        this._rectNotFocus.setA( 1 );

        this._item = new Widget().init();
        this._item._selectable  = true;
        this._item._onGotFocus  = function() { self._rectHasFocus.setA( 1 ); self._rectNotFocus.setA( 0 ); };
        this._item._onLostFocus = function() { self._rectHasFocus.setA( 0 ); self._rectNotFocus.setA( 1 ); };
        this._item.addWidget( this._rectHasFocus );
        this._item.addWidget( this._rectNotFocus );
        this.addWidget( this._item, Style._safeLeft, Style._safeTop );

        string1._id            = 1;
        string2._id            = 2;
        this._menu._id         = 3;
        this._rectHasFocus._id = 4;
        this._rectNotFocus._id = 5;

        return this;
    };

    TabbedPillButtonTestScreen.prototype.layoutMenu = function()
    {
        var y = this.getHeaderBottom();
        this._menu.setH( y );

        this._item.setY( y );
        _x2.requestFocus( this._item );
    };

    TabbedPillButtonTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._menu.containsWidget( _x2._focus ) === false )
                        _x2.requestFocus( this._menu );
                    else
                        _x2.requestFocus( this._item );
                }
                break;

            case Host.KEY_LAST:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._menu.containsWidget( _x2._focus ) === false )
                        _x2.requestFocus( this._menu );
                    else
                        retval = Screen.prototype.processEvent.call( this, val, type );
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    return TabbedPillButtonTestScreen;

})();

