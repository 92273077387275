// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.LastWatchedWidget = (function()
{
    "use strict";

    LastWatchedWidget.prototype = new Widget();

    var LOCAL_STORAGE_KEY = "lastWatched";
    var MAX_ENTRIES       = 7;

    function LastWatchedWidget(){}

    LastWatchedWidget.prototype.addEntry = function( obj )
    {
console.log( "***************************************************************************" );
console.log( "***************************************************************************" );
console.log( "***************************************************************************" );
        var struct = this.read();
        var array  = struct.entries;
        var newObj = {};

        for( var key in obj )
        {
            console.log( key );

            if( key === "entity" )
            {
                var entity = obj[key];

                if( entity instanceof Channel )
                {
                    newObj.dataType = "Channel";
                    newObj.data = entity._data;
                }
                else if( entity instanceof Recording )
                {
                    newObj.dataType = "Recording";
                    newObj.data     = entity._data;
                }
                else if( entity instanceof LinearShowing )
                {
                    var channel = entity._channel;

                    if( channel )
                    {
                        newObj.dataType = "Channel";
                        newObj.data = channel._data;
                    }
                    else
                        console.error( "LinearShowing does not contain a channel" );
                }
                else if( entity instanceof Entity )
                {
                    newObj.dataType    = "Entity";
                    newObj.data        = entity._data;
                }
                else if( entity instanceof VodShowing )
                {
                    newObj.dataType    = "VodShowing";
                    newObj.data        = entity._data;
                }
                else
                {
                    console.error( "Need to support entity type of " );
                    console.error( entity );
                }
            }
            else
                newObj[key] = obj[key];
        }

        // Remove duplicates

        var newEntity = createEntity( newObj.data, newObj.dataType ), tmp;

        console.log( newEntity );
        console.log( newObj, newObj.dataType);
        delete newObj.resume; //delete resume from localStorage, it should be fetch from resumePoint api.
        if( newEntity )
        {
            for( var i = array.length - 1; i >= 0; i-- )
            {
                if( newObj.dataType === array[i].dataType )
                {
                    tmp = createEntity( array[i].data, array[i].dataType );
                    if( newObj.dataType === "Channel" )
                    {
                        //Last watched channel should be focus on LiveTV next time.
                        _x2._settings.setValue( Settings.Key.LAST_CHAN, { id:newEntity.getStationId(), num:newEntity.getNumber() } );
                        if( tmp.getId() === newEntity.getId() )
                            array.splice( i, 1 );
                    }
                    else if( newObj.dataType === "Entity" )
                    {
                        if( tmp.getEntityId() === newEntity.getEntityId() )
                            array.splice( i, 1 );
                    }
                    else if( newObj.dataType === "Recording" )
                    {
                        if( tmp.getId() === newEntity.getId() )
                            array.splice( i, 1 );
                    }
                    else if( newObj.dataType === "VodShowing" )
                    {
console.log( tmp.getAssetId() + " ==> " + newEntity.getAssetId() );
                        if( tmp.getAssetId() === newEntity.getAssetId() )
                            array.splice( i, 1 );
                    }
                    else
                        console.error( "Need to support entity type of " + newObj.dataType );
                }
            }
        }

        if( newObj.dataType )
            array.unshift( newObj );

        if( array.length > MAX_ENTRIES )
            array.splice( MAX_ENTRIES, array.length - MAX_ENTRIES );

        this.write( struct );
    };

    function createEntity( obj, type )
    {
        var retval;

        if( type === "Channel" )
            retval = new Channel().init( obj );
        else if( type === "Entity" )
            retval = new Entity().init( obj );
        else if( type === "Recording" )
            retval = new Recording().init( obj );
        else if( type === "VodShowing" )
            retval = new VodShowing().init( obj );
        else
            console.error( "Need to support data type = " + type );

        return retval;
    }

    LastWatchedWidget.prototype.gotFocus = function()
    {
//         var entries = this.read().entries;
//
//         if( entries.length > 0 )
//             _x2.requestFocus( this._list );
    };

    LastWatchedWidget.prototype.hide = function()
    {
        this.animate( { a:0, duration:500 } );
    };

    /**
     * Initializer
     * @memberof LastWatchedWidget
     * @param   {Object} params
     * @returns {LastWatchedWidget}
     */
    LastWatchedWidget.prototype.init = function( params )
    {
        var self           = this, layout;
        var buttonStyle    = new Style( { font:"regular", color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000" } );
        var noneTitleStyle = new Style( { font:"light"  , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap" } );
        var noneBodyStyle  = new Style( { font:"medium" , color:"#a3a3a3", fontSize:_x2.scaleValInt(30), textAlign:"center"  } );
        var noneTitle      = "Nothing new here.";
        var noneBody       = "Keep track of your previously watched programs and channels, start watching and check back soon.";
        var button         = new StringWidget().init( { text:"Clear History", style:buttonStyle, onEnter:function() { console.log( "CLEAR CLEAR" ); self._onHide(); _x2._config._host.removeLocalStorage( LOCAL_STORAGE_KEY ); } } );

        Widget.prototype.init.call( this, params );
        this._className   = "LastWatchedWidget";
        this._selectable  = true;
        this._onHide      = params.onHide;
        this._numReady    = 0;

        layout = function()
        {
            self._numReady++;

            if( self._numReady === 2 )
            {
                var vGap = _x2.scaleValInt(32);
                var y    = Math.round( (_x2._config._screenH - self._noneTitleStr.getH() - self._noneBodyStr.getH() - vGap) / 2 );

                self._noneTitleStr.setX( (_x2._config._screenW - self._noneTitleStr.getW()) / 2 );
                self._noneTitleStr.setY( y );
                self._noneBodyStr.setY( y + self._noneTitleStr.getH() + vGap );

                self._list.setY( (_x2._config._screenH - self._list.getH()) / 2 );

                self._button.setY( self._list.getY() + self._list.getH() );
                self._button.setX( (_x2._config._screenW - self._button.getW()) / 2 );
            }
        };

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this._bg.setA( 0.8 );
        this.addWidget( this._bg );

        this._list = new LastWatchedRowWidget().init( { w: _x2._config._screenW - Style._safeLeft - Style._safeRight, sep: false, obj:params.videoScreen } );
        this.addWidget( this._list, Style._safeLeft );

        this._button = new HorizPillButtonsWidget().init( { entries:[button], spacing:_x2.scaleValInt(60) } );
        this._button.addReadyCallback( layout );
        this.addWidget( this._button );

        this._noneTitleStr = new StringWidget   ().init( { text:noneTitle, style:noneTitleStyle } );
        this._noneBodyStr  = new TextBlockWidget().init( { text:noneBody , style:noneBodyStyle, w:Math.round( 0.42 * _x2._config._screenW ) } );
        this._noneTitleStr.setA( 0 );
        this._noneBodyStr.setA( 0 );
        this._noneBodyStr.addReadyCallback( layout );
        this.addWidget( this._noneTitleStr );
        this.addWidget( this._noneBodyStr, (_x2._config._screenW - this._noneBodyStr.getW()) / 2 );

        if( params.params )
            this.addEntry( params.params );

        this.setW( _x2._config._screenW );
        this.setH( _x2._config._screenH );
        this.setA( 0 );

        return this;
    };

    LastWatchedWidget.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED && this._list.containsWidget( _x2._focus ) === true )
                    _x2.requestFocus( this._button );
                break;

            case Host.KEY_LAST:
                if( type === Host.KEY_PRESSED )
                {
                    this._onHide();
                }
                break;

            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED && _x2._focus === this._button )
                    _x2.requestFocus( this._list );
                break;
        }

        return retval;
    };

    LastWatchedWidget.prototype.read = function()
    {
        var retval = { version:1, entries:[] }, parsed;
        var json   = Host.getLocalStorage( LOCAL_STORAGE_KEY );

        if( json )
        {
            parsed = JSON.parse( json );

            var version = parsed.version;

            if( version === undefined )
            {
                console.error( "Unsupported version of last watched = " + version + ". Resetting." );
                this.write( retval );
            }
            else
                retval = parsed;
        }
        else
            this.write( retval );

        return retval;
    };

    LastWatchedWidget.prototype.removeEntry = function( array, entity )
    {
        var id     = entity.getEntityId(); // ProgramEntity

        for( var i = 0; i < array.length; i++ )
        {
            if( array[i].id === id )
            {
                array.splice( i, 1 );
                break;
            }
        }
    };

    LastWatchedWidget.prototype.show = function()
    {
        var self    = this, i, date, onChannelsLoaded, onChannelsFailed;
        var struct  = this.read();
        var entries = struct.entries;

        if( entries.length === 0 )
        {
            this._list.setData();
            this._noneTitleStr.setA( 1 );
            this._noneBodyStr.setA( 1 );
        }
        else
        {
            this._noneTitleStr.setA( 0 );
            this._noneBodyStr.setA( 0 );

            var data = [];

            for( i = 0 ; i < entries.length; i++ )
            {
                if( entries[i].dataType === "Channel" )
                {
                    data.push( { entity:new Channel().init( entries[i].data ), data:entries[i] } );
                }
                else if( entries[i].dataType === "Entity" )
                {
                    data.push( { entity:new Entity().init( entries[i].data ), data:entries[i] } );
                }
                else if( entries[i].dataType === "Recording" )
                {
                    data.push( { entity:new Recording().init( entries[i].data ), data:entries[i] } );
                }
                else if( entries[i].dataType === "VodShowing" )
                {
                    var entity = new VodShowing().init( entries[i].data );

                    var rentalTime = entity.get("rentalDateTime");
                    if( rentalTime )
                    {
                        var expires = new Date( rentalTime ).getTime() + ( entity.getInteger("rentalPeriod") * 60000 );
                        if( Date.now() < expires )
                            data.push( { entity:entity, data:entries[i] } );
                    }
                    else
                        data.push( { entity:entity, data:entries[i] } );
                }
                else
                {
                    console.error( "Need to support data type = " + entries[i].dataType + ", index =  " + i );
                    console.log( entries );
                }
            }

            onChannelsLoaded = function()
            {
                var chanColl = _x2._channelCollection;

                for( i = data.length - 1; i >= 0; i-- )
                {
                    console.log( data[i] );

                    if( data[i].entity instanceof Channel )
                    {
                        console.error( "Found channel at index = " + i );

                        var found    = false;
                        var channel  = data[i].entity;
                        var channels = chanColl ? chanColl.getChannels() : [];

                        for( var j = 0; j < channels.length; j++ )
                        {
                            if( channel.getNumber() === channels[j].getNumber() )
                            {
                                console.error( channels[j] );
                                found = true;
                                data[i].entity = channels[j];
                                break;
                            }
                        }

                        if( found === false )
                        {
                            console.error( "NO MATCH: DELETE ENTRY" );
                            data.splice( i, 1 );
                        }
                    }
                }

                self._list.setData( { getTitle:function() { return "Last Watched"; }, getEntities:function() { return data; } } );

                if( _x2._focus === self && entries.length > 0 )
                    _x2.requestFocus( self._list );
            };

            onChannelsFailed = function( error )
            {
                console.error( "Unable to load channels: " + error );

                onChannelsLoaded();



            //     var onDismiss = function()
            //     {
            //         _x2.popScreen( self );
            //     };
            //
            //     var onRetry = function()
            //     {
            //         var retry = function()
            //         {
            //             _x2._data.getChannelCollection( date.getTime() ).then( onChannelsLoaded, onChannelsFailed );
            //         };
            //
            //         _x2.popOverlay( self._errorOverlay, retry );
            //     };
            //
            //     self._errorOverlay = _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.GUIDE, onDismiss:onDismiss, onRetry:onRetry } ) );
            };

            // get the list of channels
            _x2._channelCollection && _x2._channelCollection.fetch().then( onChannelsLoaded, onChannelsFailed );
        }

        this._button.setA( entries.length > 0 ? 1 : 0 );
        this.animate( { a:1, duration:500 } );
    };

    LastWatchedWidget.prototype.write = function( obj )
    {
        _x2._config._host.setLocalStorage( LOCAL_STORAGE_KEY, JSON.stringify( obj ) );
    };

    return LastWatchedWidget;

})();
