// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Aggregates entity-related data and provides convenience methods for extracting them. The point of this
 * object is to consolidate all async Entity calls into one. It can also take advantage of some
 * redundancy in entityOptions and upcomingListings calls to provide some durability if one call fails.
 */
window.EntityOptions = (function()
{
    "use strict";

    EntityOptions.prototype = new DataObject();

    EntityOptions.programsPerPage = 25;

    function EntityOptions(){}

    EntityOptions.prototype.init = function( entity )
    {
        this._entity = entity;

        this._episodes    = [];
        this._seasons     = [];
        this._vodShowings = [];
        this._recordings  = [];
        this._upcoming    = [];
        this._offers      = [];

        return this;
    };

    EntityOptions.prototype.fetch = function( force )
    {
        var p = [];
        p.push( this.fetchUpcoming() );
        p.push( this.fetchWatchOptions( force ) );
        return Promise.all( p );
    };

    /**
     * @private
     */
    EntityOptions.prototype.fetchWatchOptions = function( force )
    {
        var self = this;
        var parameters = {};
        parameters.freetome                = ( _x2._config._purchases && _x2._features.hasEntitlement( Features.Entitlement.EST ) ) ? "off" : "on";
        parameters.embedSeasonWatchOptions = "none";
        parameters.embedWatchNow           = true;
        parameters.programsPerPage         = EntityOptions.programsPerPage;

        var resolver = function(resolve, reject)
        {
            var success = function( response )
            {
                var watchOptions = JSON.parse( response.data );
                self.setWatchOptions( watchOptions._embedded );

                resolve()
            };

            var fail = function(error)
            {
                reject( error );
            };

            var link = self._entity.getLinkObject("watchOptions");

            if( link )
                link.resolve( parameters, {}, force ).then(success).catch(fail);
            else
                resolve();
        };

        return new Promise(resolver);
    };

    EntityOptions.prototype.getSeriesRecordingForm = function()
    {
        if( this._upcoming ) //NOTE: every episode should have the same series recording form
            if( this._upcoming.length )
                return this._upcoming[0].getSeriesRecordingForm();
    };

    /**
     * @private
     */
    EntityOptions.prototype.fetchUpcoming = function()
    {
        var self = this;
        var parameters = {};
        parameters.freetome = ( _x2._config._purchases && _x2._features.hasEntitlement( Features.Entitlement.EST ) ) ? "off" : "on";

        this._upcoming = [];

        var resolver = function(resolve, reject)
        {
            var success = function( response )
            {
                var upcoming = JSON.parse( response.data );
                self.setUpcoming( upcoming._embedded );

                resolve()
            };

            var fail = function(error)
            {
                console.log( error );
                reject( error );
            };

            var link = self._entity.getLinkObject("upcomingListings");

            if( link )
                link.resolve( parameters ).then( success ).catch( fail );
            else
                resolve();
        };

        return new Promise(resolver);
    };

    EntityOptions.prototype.getOnNowListings = function()
    {
        var upcoming, i, listing, onNow = [], now = Date.now();

        upcoming = this.getUpcomingListings();
        for( i = 0; i < upcoming.length; i++ )
        {
            listing = upcoming[i];
            if( listing.getStartTime() < now && listing.getEndTime() > now )
                onNow.push( listing );
        }

        return onNow;
    };

    EntityOptions.prototype.getContentProvider = function()
    {
        return this._contentProvider;
    };

    EntityOptions.prototype.getEpisodes = function()
    {
        return this._episodes;
    };

    EntityOptions.prototype.getRecordings = function()
    {
        return this._recordings;
    };

    EntityOptions.prototype.getSeasons = function()
    {
        return this._seasons;
    };

    EntityOptions.prototype.getTransactionOffers = function()
    {
        return this._offers;
    };

    EntityOptions.prototype.getUpcomingListings = function()
    {
        if( this._upcoming.length === 0 )
        {
            //For some entities (sporting events) the upcoming listings are embedded rather than linked
            if( this._entity )
            {
                var listings = this.getPath(["_embedded", "upcomingListings", "_embedded", "listings"], this._entity._data );

                if( listings && listings.length )
                {
                    for( var i=0; i<listings.length; i++ )
                        this._upcoming.push( new LinearShowing().init(listings[i]) );
                }
            }
        }

        return this._upcoming;
    };

    EntityOptions.prototype.getVodShowings = function()
    {
        return this._vodShowings;
    };

    /**
     * Return true if this entity has a transactional BUY option
     * @return {boolean}
     */
    EntityOptions.prototype.hasBuyOption = function()
    {
        var i, buyOption = false;

        for( i=0; i<this._offers.length; i++ )
        {
            if( this._offers[i].getPurchaseType() === "Buy" )
            {
                buyOption = true;
                break;
            }
        }

        return buyOption;
    };

    /**
     * Return true if this entity has a transactional RENT option
     * @return {boolean}
     */
    EntityOptions.prototype.hasRentOption = function()
    {
        var i, rentOption = false;

        for( i=0; i<this._offers.length; i++ )
        {
            if( this._offers[i].getPurchaseType() === "Rent" )
            {
                rentOption = true;
                break;
            }
        }

        return rentOption;
    };

    /**
     * Return true if this entity has a transactional SUBSCRIBE option
     * @return {boolean}
     */
    EntityOptions.prototype.hasSubscribeOption = function()
    {
        return this._subscribeOffer !== undefined;
    };

    EntityOptions.prototype.isWatchable = function()
    {
        var watchable = false;

        if( this._entity.getType() !== XtvApi.EntityType.SERIES )
        {
            watchable = this._vodShowings.length > 0 || this._recordings.length > 0;

            if( watchable !== true )
            {
                if( this._upcoming.length > 0 )
                {
                    for( var i=0; i<this._upcoming.length; i++ )
                    {
                        if( this._upcoming[i].isWatchable() )
                        {
                            watchable = true;
                            break;
                        }
                    }
                }
            }

            return watchable;
        }
    };

    EntityOptions.prototype.getEpisodeCount = function()
    {
        return this._episodes.length;
    };

    EntityOptions.prototype.getSeasonCount = function()
    {
        return this._seasons.length;
    };

    EntityOptions.prototype.getResumePoint = function()
    {
        var i, j, rpMediaId, sMediaId, showing, resumePoint, showings = [];

        if( _x2._resume && _x2._resume.length )
        {
            showings = showings.concat( this.getRecordings() );
            showings = showings.concat( this.getVodShowings() );

            for( i=0; i<_x2._resume.length; i++ )
            {
                rpMediaId = _x2._resume[i].getMediaId();

                for( j=0; j<showings.length; j++ )
                {
                    showing = showings[j];
                    sMediaId = showing.getMediaId();

                    if( sMediaId )
                    {
                        if( rpMediaId === sMediaId )
                        {
                            showing.setEntity( this._entity ); //add the entity reference to the resume point. needed by watch()
                            resumePoint = _x2._resume[i];
                            resumePoint.setShowing( showing );
                            break;
                        }
                    }
                }
                if( resumePoint )
                    break;
            }
        }

        return resumePoint;
    };

    EntityOptions.prototype.getMinsRemaining = function()
    {
        var remaining = 0, showing, rp = this.getResumePoint();
        if( rp )
        {
            showing = rp.getShowing();
            if( showing )
                remaining = showing.getMinsRemaining();
        }

        return remaining;
    };

    EntityOptions.prototype.getSubscribeOffer = function()
    {
        return this._subscribeOffer;
    };

    EntityOptions.prototype.hasShowingWithProperty = function( property )
    {
        var i, showings = this.getVodShowings();
        if( showings )
            for( i=0; i<showings.length; i++ )
                if( showings[i].get(property) === true )
                    return true;
    };

    EntityOptions.prototype.hasCcShowing = function()
    {
        return this.hasShowingWithProperty( "closedCaption" );
    };

    EntityOptions.prototype.hasDvsShowing = function()
    {
        return this.hasShowingWithProperty( "hasDVS" );
    };

    EntityOptions.prototype.hasHdShowing = function()
    {
        return this.hasShowingWithProperty( "isHD" );
    };

    EntityOptions.prototype.hasSapShowing = function()
    {
        return this.hasShowingWithProperty( "isSAP" );
    };

    /**
     * Set upcoming listings data
     * @param upcoming
     */
    EntityOptions.prototype.setUpcoming = function( upcoming )
    {
        var i, j, program, listing, listings, programs;

        listings = upcoming["listings"];
        if( listings ) //Grid > Series Info
        {
            for( i=0; i<listings.length; i++ )
                this._upcoming.push( new LinearShowing().init( listings[i]) );
        }

        programs = upcoming["programs"];
        if( programs )
        {
            for( i=0; i<programs.length; i++  )
            {
                program = new Entity().init(programs[i]);

                //if this is episode, add a series reference
                if( program.getType() === XtvApi.EntityType.EPISODE )
                    program.setSeries( this._entity );

                listings = program.getPath(["_embedded", "upcomingListings", "_embedded","listings"]);

                for( j=0; j<listings.length; j++ )
                {
                    listing = new LinearShowing().init( listings[j] );

                    //add the program reference to each showing
                    listing.setEntity( program );

                    this._upcoming.push( listing );
                }
            }
        }
    };

    /**
     * Set watch options data
     * @param watchOptions
     */
    EntityOptions.prototype.setWatchOptions = function( watchOptions )
    {
        this._episodes    = [];
        this._seasons     = [];
        this._vodShowings = [];
        this._recordings  = [];
        this._offers      = [];

        if( watchOptions )
        {
            //get content provider
            this._contentProvider = new ContentProvider().init( watchOptions["contentProvider"] );

            //parse VOD showings
            var i, transaction, vodShowings = watchOptions["vodItems"];
            if( vodShowings )
            {
                for( i=0; i<vodShowings.length; i++ )
                {
                    transaction = this.getPath( ["_embedded","transactionDetails"], vodShowings[i] );
                    if( transaction )
                    {
                        if( transaction.type === TransactionDetails.Type.RENTAL )
                            this._vodShowings.push( new Rental().init( vodShowings[i]) );
                        else
                            this._vodShowings.push( new Purchase().init( vodShowings[i]) );
                    }
                    else
                        this._vodShowings.push( new VodShowing().init( vodShowings[i]) );
                }

                if( this._vodShowings.length > 1 )
                    this._vodShowings.sort( function( a, b ) { return a.isHD() ? -1 : 1 } );
            }

            //parse recordings
            var recording, recordings = watchOptions["recordings"];
            if( recordings )
            {
                for( i=0; i<recordings.length; i++ )
                {
                    recording = new Recording().init(recordings[i]);
                    if( recording.isCheckedOut() !== true )
                        this._recordings.push( new Recording().init( recordings[i]) );
                }
            }

            //parse rent/buy offers
            var offer, offers = watchOptions["offers"];
            if( offers )
            {
                for( i=0; i<offers.length; i++ )
                {
                    offer = new TransactionOffer().init( offers[i] );

                    //NOTE: a subscribe offer can have the type "unsupportedUpsell", since we're only pushing an info overlay for Subscribe, don't consider if the offer is supported.
                    if( offer.getPurchaseType() === TransactionOffer.PurchaseType.SUBSCRIBE )
                        this._subscribeOffer = offer;
                    else if( offer.isSupported() )
                        this._offers.push( offer );
                }

                if( this._offers.length > 1 )
                {
                    var offerSort = function( a, b )
                    {
                        //order by HD>SD then Rent>Buy then Episode>Season
                        if( a.getVideoQuality() !== b.getVideoQuality() )
                            return ( a.getVideoQuality() === "HD"     ? -1 : 1 );
                        else if( a.getPurchaseType() !== b.getPurchaseType() )
                            return ( a.getPurchaseType() === "Rent"   ? -1 : 1 );
                        else
                            return ( a.getOfferType()    === "Single" ? -1 : 1 );
                    };

                    this._offers.sort( offerSort );
                }
            }

            //parse seasons
            var seasonList = watchOptions["tvSeasons"];
            if( seasonList )
            {
                for( i = 0; i < seasonList.length; i++ )
                    this._seasons.push( new Season().init( seasonList[i] ) );
            }
        }
    };

    return EntityOptions;
})();
