// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.Callbacks = (function()
{
    "use strict";

    function Callbacks(){}

    /**
     * @memberof Callbacks
     * @param {Function} callback - function to trigger when item has changed
     * @see Callbacks.callbackSignalChange()
     */
    Callbacks.prototype.addChangeCallback = function( callback )
    {
        if( this._changeCallbacks === undefined )
            this._changeCallbacks = [];

        this._changeCallbacks.push( callback );
    };

    /**
     * @memberof Callbacks
     * @param {Function} callback - function to trigger when item is ready
     * @see Callbacks.callbackSignalReady()
     */
    Callbacks.prototype.addReadyCallback = function( callback )
    {
        if( this._callbackSignaled )
        {
            callback();
        }
        else
        {
            if( this._readyCallbacks === undefined )
                this._readyCallbacks = [];

            this._readyCallbacks.push( callback );
        }
    };

    /** Call all the changed callbacks registered
     * @memberof Callbacks
     * @see Callbacks.addChangeCallback()
     */
    Callbacks.prototype.callbackSignalChange = function()
    {
        if( this._changeCallbacks )
            for( var i = 0; i < this._changeCallbacks.length; i++ )
                this._changeCallbacks[i]();
    };

    /** Call all the ready callbacks registered
     * @memberof Callbacks
     * @see Callbacks.addReadyCallback()
     */
    Callbacks.prototype.callbackSignalReady = function()
    {
        var callback;

        this._callbackSignaled = true;

        if( this._readyCallbacks )
            while( callback = this._readyCallbacks.shift() )
                callback();
    };

    /**
     * @memberof Callbacks
     * @param {Function} callback - remove the function from the changed callbacks if it exists
     */
    Callbacks.prototype.removeChangeCallback = function( callback )
    {
        if( this._changeCallbacks && callback )
            for( var i = 0; i < this._changeCallbacks.length; i++ )
                if( this._changeCallbacks[i] === callback )
                {
                    this._changeCallbacks.splice( i, 1 );
                    break;
                }
    };

    return Callbacks;

})();
