// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.RadioButtonRowWidget = ( function()
{
    "use strict";

    RadioButtonRowWidget.prototype = new ListRowWidget();

    function RadioButtonRowWidget(){}

    /**
     * Initializer
     * @memberof RadioButtonRowWidget
     * @param   {Object}                params
     * @param   {RadioButtonController} params.obj   - Object that manages to the collection of rows
     * @param   {String}                params.title - String to be displayed in the entry
     * @param   {Number}                params.w     - Display width of the row
     * @returns {RadioButtonRowWidget}
     * @see ListRowWidget
     */
    RadioButtonRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );
        this._className  = "RadioButtonRowWidget";
        this._selectable = true;

        var self       = this;
        var titleStyle = new Style( { color:"#b8b8b8", colorHi:"#2ea0dd", font:"light", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap" } );

        this._numElements = 0;
        this._driver      = params.obj;

        this._title = new StringWidget().init( { text:params.title, style:titleStyle } );
        this._title.addReadyCallback( function() { self._numElements++; self.layout(); } );
        this.addWidget( this._title );

        this._check = new DualImageWidget().init( { url:_x2._config._imageRoot + "check_white.png", urlHi:_x2._config._imageRoot + "check.png" } );
        this._check.setA( 0 );
        this._check.addReadyCallback( function() { self._numElements++; self.layout(); } );
        this.addWidget( this._check );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        this._driver.registerEntry( this );

        this._currentRowIndex = params.rowIndex;

        return this;
    };

    RadioButtonRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 114 * _x2._config._text );
    };

    RadioButtonRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        ListRowWidget.prototype.gotFocus.call( this, losingFocus );
        this._title.gotFocus();
        this._check.gotFocus();
        this._title.setSpeechParams( undefined, "_radio", this, newScreen, this._selected );
        this._parent._parent._parent._currentFocusIndex = this._currentRowIndex;

        return this;
    };

    RadioButtonRowWidget.prototype.layout = function()
    {
        if( this._numElements === 2 )
        {
            this._title.setX( 2 * this._check.getW() );
            this._title.setY( (this.getHeightUnfocused() - this._title.getH()) / 2 );
            this._check.setY( (this.getHeightUnfocused() - this.getSepH() - this._check.getH()) / 2 );
        }
    };

    RadioButtonRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._title.lostFocus();
        this._check.lostFocus();
        this._parent._parent._parent._lastFocusIndex = this._currentRowIndex;
    };

    RadioButtonRowWidget.prototype.processEvent = function( val, type )
    {
        var retval;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    retval = true;
                    this._driver.entrySelected( this._key );

                    if( this._selected === false )
                        this.setSelected( true, true );

                    this._title.setSpeechParams( undefined, "_radio", this, false, this._selected );
                }
                break;
        }

        if( retval === undefined )
            retval = ListRowWidget.prototype.processEvent.call( this, val, type );

        return retval;
    };

    RadioButtonRowWidget.prototype.setData = function( data )
    {
        var self = this;

        if( data !== undefined )
        {
            this._title.setText( data.title );
            this._key = data.key;

            if( this._key === this._driver._key )
                this.setSelected( true );

            this.setA( 1 );
            this.setMouseDownListener( function() { self.processEvent( Host.KEY_ENTER, Host.KEY_PRESSED ); } );
            this.setMouseEnterListener( function() { 
                this._parent._parent._parent.moveCursorToRow( this._currentRowIndex );
             } );
        }
        else
        {
            this.setA( 0 );
            this.enableMouseDownListener( false );
        }
    };

    RadioButtonRowWidget.prototype.setSelected = function( val, animate )
    {
        this._selected = val;

        var alpha = val ? 1 : 0 ;

        if( animate )
            this._check.animate( { a:alpha, duration:300 } );
        else
            this._check.setA( alpha );

    };

    return RadioButtonRowWidget;

})();


/**
 * @class
 */
window.RadioButtonController = ( function()
{
    "use strict";

    /**
     * Constructor
     * @memberof RadioButtonController
     * @param   {String}   selectedKey     - Key of currently selected entry
     * @param   {Function} onEntrySelected - Called when an entry is selected
     * @returns {RadioButtonController}
     */

    function RadioButtonController( selectedKey, onEntrySelected )
    {
        this._key             = selectedKey;
        this._onEntrySelected = onEntrySelected;
        this._entries         = [];
    }

    RadioButtonController.prototype.entrySelected = function( key )
    {
        this._onEntrySelected( key );

        for( var i = 0; i < this._entries.length; i++ )
            this._entries[i].setSelected( false );
    };

    RadioButtonController.prototype.registerEntry = function( entry )
    {
        this._entries.push( entry );
    };

    return RadioButtonController;

})();
