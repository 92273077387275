// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.MainMenuScreen = ( function()
{
    "use strict";

    MainMenuScreen.prototype = new Screen();

    function MainMenuScreen(){}

    MainMenuScreen.prototype.getBgInfo = function()
    {
        // morning    4:00 am - 11:59 am
        // afternoon 12:00 pm -  6:00 pm
        // evening    6:00 pm -  3:59 am

        var retval;
        var now    = new Date();
        var mins   = now.getHours() * 60 + now.getMinutes();

        if( mins >= 0 && mins < 240 )
            retval = _x2._config._imageRoot + "mainMenu/evening.jpg";
        else if( mins >= 240 && mins < 720 )
            retval = _x2._config._imageRoot + "mainMenu/morning.jpg";
        else if( mins >= 720 && mins < 1080 )
            retval = _x2._config._imageRoot + "mainMenu/afternoon.jpg";
        else if( mins >= 1080 && mins < 1440 )
            retval = _x2._config._imageRoot + "mainMenu/evening.jpg";

        return retval;
    };

    MainMenuScreen.prototype.gotFocus = function( losingFocus, newScreen )
    {
        _x2._hi.fadeOut();

        if( newScreen !== false )
        {
            this._speakTarget.setText( "Main Menu. " + this._entries[this._index].getText() + ". " );
            this._speakTarget.setSpeechParams( "Press right or left to review options. Press down for content options. Press OK to select.", "button", this, false );
        }

        if( this._ready === true && this._inTransition === false ) {
            this.setHighlighted( true, newScreen !== false );
            window.setTimeout( function() {
                _x2._hi.fadeOut();
            }, 300);//sometimes, highlight widget is not hide when come back from Live TV
        }

        _x2._telemetry.setWatchButton(); // intentional undefined param
        _x2._telemetry.watchPathReset();
    };

    MainMenuScreen.prototype.init = function()
    {
        this._inTransition = false;
        this._numLoaded    = 0;
        this._numNeeded    = 1;
        this._vertIndex    = 0;
        this._entries      = [];
        this._buttons      = [];
        this._scrollRate   = _x2.scaleVal( 1.8 ); // px / msec
        this._wrapperTop   = _x2.scaleValInt( 180 );

        var self        = this, menuItems, i, icons = [];
        var style       = new Style( { font:"regular", color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", textAlign:"center" } );
        var dayPartData = this.getBgInfo();
        var guide       = function() { _x2.pushScreen( new GuideScreen   ().init() ); };
        var saved       = function() { _x2.pushScreen( new SavedScreen   ().init() ); };
        var search      = function() { _x2.pushScreen( new SearchScreen  ().init() ); };
        var settings    = function() { _x2.pushScreen( new SettingsScreen().init() ); };
        var onDemand    = function()
        {
            if( _x2._vodRoot )
                _x2.pushScreen( new VodRootScreen ().init() );
            else
            {
                console.error( "NO VOD ROOT" );
                console.error( _x2._vodRootError );
                _x2.pushOverlay( new ErrorOverlay().init( { error:_x2._vodRootError, context:ErrorOverlay.Context.VOD } ) );

                var setVod = function( root )
                {
                    _x2._vodRoot = root;
                };

                var onVodError = function( error )
                {
                    console.log( "ERROR -> unable to load VOD" );
                    console.log( error );

                    _x2._vodRootError = error;
                };

                _x2._data.getVodRoot(1,0).then( setVod ).catch( onVodError );
            }
        };
        window.setTimeout( function() {
            var screenName = 'Heads-Up Message for 2017 Models';
            _x2._splunk.sendScreenViewedEvent(screenName);
            _x2._telemetry.sendScreenViewed(screenName);
            _x2.pushOverlay( new DepreciationNoticeOverlay().init() );
        }.bind(this), 0);

        var layout = function()
        {
            if( ++self._numLoaded === self._numNeeded )
            {
                var imW     = self._buttons[0].getW();
                var imH     = self._buttons[0].getH();
                var x       = (_x2._config._screenW - self._buttons.length * imW) / 2;
                var y       = _x2.scaleValInt( 220 );
                var offset  = _x2.scaleValInt( 114 );

                for( var i = 0; i < self._buttons.length; i++ )
                {
                    self._buttons[i].setX( x );
                    self._entries[i].setW( imW );
                    self._entries[i].setY( offset );

                    icons[i].setX( (imW - icons[i].getW()) / 2 );
                    icons[i].setY( 0.21875 * imH );

                    self._buttons[i].setA(1);
                    x += self._buttons[i].getW();
                }

                self._vertOffsets = [ 0 ];
                self._rowsOffset = y + self._menuContainer.getH() + _x2.scaleValInt( 40 );
                self._lastWatched.setY( _x2._config._screenH );
                self._featured.setY( _x2._config._screenH );
                self._ready = true;
                self._brand.animate( { alpha:1, duration:400, start:400 } );

                if( _x2._focus === self )
                    self.setHighlighted( true, true );

                self._container.animate( { a:1, duration:1000 } );

                _x2.addChangeCallback( function() { self.tick(); } );

                self.setMouseWheelListener( function( event ) { self.processEvent( ( event.deltaY > 0 ) ? Host.KEY_DOWN : Host.KEY_UP, Host.KEY_PRESSED ); } );
            }
        };

        Screen.prototype.init.call( this, { noBg:false, noHeader:false, noSep:true, image:dayPartData } );
        this._className     = "MainMenuScreen";
        this._telemetryName = "Main Menu";

        this._brand = new ImageWidget().init( { url:_x2._config._imageRootMso + "msoLogo.png", onLoad:layout } );
        this._brand.setA( 0 );
        this.addWidget( this._brand, Style._safeLeft, Style._safeTop );

        this._menuContainer = new Widget().init();
        this._menuContainer.setA( 0 );
        
        this._container = new Widget().init();
        this._container.setA( 0 );

        // Adding the wrapper container with position as relative and overflow as hidden, 
        // so that animation on widget container will not overlapped the app logo or header.
        this._wrapperContainer = new Widget().init();
        this._wrapperContainer.setA( 1 );
        this._wrapperContainer.setY( this._wrapperTop );
        this._wrapperContainer.setH( _x2._config._screenH );
        this.addWidget( this._wrapperContainer );
        
        this._wrapperContainer.addWidget( this._menuContainer );
        this._wrapperContainer.addWidget( this._container );

        menuItems = _x2._config._mainMenu;

        for( i = 0; i < menuItems.length; i++ )
        {
            switch( menuItems[i] )
            {
                case "guide":
                    this._numNeeded += 2;
                    this._entries.push( new StringWidget().init( { text:"Live TV", style:style } ) );
                    this._buttons.push( new DualImageWidget().init( { url:_x2._config._imageRoot + "mainMenu/button.png", urlHi:_x2._config._imageRoot + "mainMenu/buttonHi.png", onEnter:guide, focusTime:300, lostFocusScale:0.94 } ) );
                    icons.push(  new ImageWidget().init( { url:_x2._config._imageRoot + "mainMenu/live.png", onLoad:layout } ) );
                    this._buttons[this._buttons.length-1].addWidget( icons[icons.length-1] );
                    break;

                case "onDemand":
                    this._numNeeded += 2;
                    this._entries.push( new StringWidget().init( { text:"Browse", style:style } ) );
                    this._buttons.push( new DualImageWidget().init( { url:_x2._config._imageRoot + "mainMenu/button.png", urlHi:_x2._config._imageRoot + "mainMenu/buttonHi.png", onEnter:onDemand, focusTime:300, lostFocusScale:0.94 } ) );
                    icons.push(  new ImageWidget().init( { url:_x2._config._imageRoot + "mainMenu/browse.png", onLoad:layout } ) );
                    this._buttons[this._buttons.length-1].addWidget( icons[icons.length-1] );
                    break;

                case "saved":
                    this._numNeeded += 2;
                    this._entries.push( new StringWidget().init( { text:"Saved", style:style } ) );
                    this._buttons.push( new DualImageWidget().init( { url:_x2._config._imageRoot + "mainMenu/button.png", urlHi:_x2._config._imageRoot + "mainMenu/buttonHi.png", onEnter:saved, focusTime:300, lostFocusScale:0.94 } ) );
                    icons.push(  new ImageWidget().init( { url:_x2._config._imageRoot + "mainMenu/saved.png", onLoad:layout } ) );
                    this._buttons[this._buttons.length-1].addWidget( icons[icons.length-1] );
                    break;

                case "search":
                    this._numNeeded += 2;
                    this._entries.push( new StringWidget().init( { text:"Search", style:style } ) );
                    this._buttons.push( new DualImageWidget().init( { url:_x2._config._imageRoot + "mainMenu/button.png", urlHi:_x2._config._imageRoot + "mainMenu/buttonHi.png", onEnter:search, focusTime:300, lostFocusScale:0.94 } ) );
                    icons.push(  new ImageWidget().init( { url:_x2._config._imageRoot + "mainMenu/search.png", onLoad:layout } ) );
                    this._buttons[this._buttons.length-1].addWidget( icons[icons.length-1] );
                    break;

                case "settings":
                    this._numNeeded += 2;
                    this._entries.push( new StringWidget().init( { text:"Settings", style:style } ) );
                    this._buttons.push( new DualImageWidget().init( { url:_x2._config._imageRoot + "mainMenu/button.png", urlHi:_x2._config._imageRoot + "mainMenu/buttonHi.png", onEnter:settings, focusTime:300, lostFocusScale:0.94 } ) );
                    icons.push(  new ImageWidget().init( { url:_x2._config._imageRoot + "mainMenu/settings.png", onLoad:layout } ) );
                    this._buttons[this._buttons.length-1].addWidget( icons[icons.length-1] );
                    break;

                default:
                    console.error( "ERROR -> unknown main menu entry = " + menuItems[i] );
                    break;
            }
        }

        for( i = 0; i < this._buttons.length; i++ )
        {
            this._buttons[i].addReadyCallback( layout );
            this._buttons[i].addWidget( this._entries[i] );
            this._menuContainer.addWidget( this._buttons[i], 0, self._wrapperTop );
            this._buttons[i].setA(0);
        }
        this._wrapperContainer.setY(self._wrapperTop);
        this._menuContainer.setY(-self._wrapperTop);
        this._index = 0;

        this._lastWatched = new LastWatchedRowWidget().init( { w:_x2._config._screenW - Style._safeLeft - Style._safeRight, sep:false, obj:this } );
        this._lastWatched.setA( 0 );
        this._container.addWidget( this._lastWatched, Style._safeLeft );

        this._featured = new SmallPromoRowWidget().init( { w:_x2._config._screenW - Style._safeLeft - Style._safeRight, sep:false } );
        this._featured.setA( 0 );
        this._container.addWidget( this._featured, Style._safeLeft );

        this.refreshCheck();

        window.ll( "tagScreen", "Main Menu" ); // Localytics 2.1.1
        _x2._config.log( Config.CLASS_LOCALYTICS, 1 ) ? console.log( "Localytics: Main Menu screen" ) : Config.NOP();

        _x2.localyticsSendSplashPageAndProvisioning();

        return this;
    };

    MainMenuScreen.prototype.lostFocus = function()
    {
       this.setHighlighted( false );
    };

    MainMenuScreen.prototype.play = function( params )
    {
        _x2.pushScreen( new VideoScreen().init( params ) );
    };

    MainMenuScreen.prototype.processEvent = function( val, type )
    {
        var retval = true, self = this, duration, i;

        var setWatchSource = function( name )
        {
            _x2._telemetry.watchPathReset();
            _x2._telemetry.watchPathPush( name );
            _x2._telemetry.setWatchButton();
        };

        var onTransitionEnd = function()
        {
            self._inTransition = false;
            if( self._vertIndex === 0 )
            {
                self._index = (self._index)? self._index : 0;
                _x2.requestFocus( self._buttons[self._index] );
                self.setHighlighted( true, true );
            }
            else if( self._vertIndex === 1 )
            {
                
                self.setHighlighted( false, true );
                if( self._hasLastWatched === true )
                {
                    setWatchSource( "Player: Last Watched" );
                    _x2.requestFocus( self._lastWatched );
                }
                else
                {
                    setWatchSource( "Featured" );
                    _x2.requestFocus( self._featured );
                }
            }
            else if( self._vertIndex === 2 )
            {
                setWatchSource( "Featured" );
                _x2.requestFocus( self._featured );
            }
        };
        var overlayScreen = _x2._overlayRoot.getTop(); 
        var hasOverlay = ( overlayScreen instanceof ExitAppOverlay );
        if( hasOverlay ) {
            _x2.requestFocus( overlayScreen._buttons );
        }
        if( this._inTransition === false && !hasOverlay )
            switch( val )
            {
                case Host.KEY_DOWN:
                    if( type === Host.KEY_PRESSED && this._inTransition === false )
                    {
                        if( this._vertIndex < this._vertOffsets.length - 1 )
                        {
                            _x2._hi.fadeOut();
                            _x2._hi.setA(0);
                            duration = (this._vertOffsets[this._vertIndex] - this._vertOffsets[this._vertIndex + 1]) / this._scrollRate;
                            this._inTransition = true;

                            if( this._vertIndex === 0 )
                            {
                                this._menuContainer.animate( { a:0, duration:duration } );
                            }

                            this._vertIndex++;
                            this._container.animate( { y:this._vertOffsets[this._vertIndex], duration:duration, onEnd:onTransitionEnd } );
                        }
                    }
                    break;

                case Host.KEY_ENTER:
                    if( type === Host.KEY_PRESSED && this._inTransition === false )
                         this._buttons[this._index].onEnter();
                    break;

                case Host.KEY_LEFT:
                    if( type === Host.KEY_PRESSED && this._inTransition === false )
                    {
                        if( this._index > 0 )
                        {
                            this.setHighlighted( false );
                            this._index--;
                            this.setHighlighted( true );
                        }
                    }
                    break;

                case Host.KEY_RIGHT:
                    if( type === Host.KEY_PRESSED && this._inTransition === false )
                    {
                        if( this._index < this._buttons.length - 1 )
                        {
                            this.setHighlighted( false );
                            this._index++;
                            this.setHighlighted( true );
                        }
                    }
                    break;

                case Host.KEY_UP:
                    if( type === Host.KEY_PRESSED && this._inTransition === false )
                    {
                        if( this._vertIndex > 0 )
                        {
                            _x2._hi.fadeOut();
                            _x2._hi.setA( 0 );
                            duration = (this._vertOffsets[this._vertIndex - 1] - this._vertOffsets[this._vertIndex]) / this._scrollRate;
                            this._inTransition = true;

                            if( this._vertIndex === 1 )
                            {
                                this._menuContainer.animate( { a:1, duration:duration } );
                            }
                            this._vertIndex--;
                            this._container.setY(this._vertOffsets[this._vertIndex]);
                            onTransitionEnd();
                        }
                    }
                    break;

                default:
                    retval = Screen.prototype.processEvent.call( this, val, type );
                    break;
            }

        return retval;
    };

    MainMenuScreen.prototype.refreshCheck = function()
    {
        var self = this;

        var onFeatured = function( data )
        {
            self._hasFeatured = data.getTotalEntityCount() > 0;
            self._featured.setData( data );
        };

        var onFeaturedFailed = function( error )
        {
            console.error( error );
        };

        var onLastWatched = function( data )
        {
            self._hasLastWatched = data.length > 0;
            self._lastWatched.setData( self._hasLastWatched ? { getTitle: function() { return "Last Watched"; }, getEntities: function() { return data; } } : undefined );
        };

        var onLastWatchedFailed = function( error )
        {
            console.error( error );
        };

        var onRowsReady = function()
        {
            var y       = self._rowsOffset;
            var offsets = [ 0 ];
            var inset   = self._brand.getY() + self._brand.getH();
            var offset  = inset - y;

            self._wrapperContainer._div.style.position = 'relative';
            self._wrapperContainer._div.style.overflow = 'hidden';

            var onEnd = function()
            {
                self._vertOffsets = offsets;
            };

            if( self._hasLastWatched )
            {
                self._lastWatched.animate( { y:y, duration:750, onEnd:self._hasFeatured === true ? undefined : onEnd } );
                y += self._lastWatched.getH();
                offsets.push( offset - self._wrapperTop );
                offset = inset - y;
            }

            if( self._hasFeatured )
            {
                self._featured.animate( { y:y, duration:750, onEnd:onEnd } );
                offsets.push( offset - self._wrapperTop );
            }
        };

        if( self._lastWatched.containsWidget( _x2._focus ) === true || self._featured.containsWidget( _x2._focus ) === true )
            _x2.requestFocus( this );

        _x2._hi.fadeOut();
        this._lastFocus   = this;
        this._vertIndex   = 0;
        this._vertOffsets = [ 0 ];
        this._container.setY( 0 );
        this._lastWatched.setY( _x2._config._screenH );
        this._featured.setY( _x2._config._screenH );

        if( self._menuContainer.getA() == 0 ) {
            this._menuContainer.animate( { a:1, duration: 750 } );
        }//animate only when menu container not visible
        
        var lastWatched = new LastWatched().getEntries().then( onLastWatched, onLastWatchedFailed );
        var featured    = _x2._data.getForYouFeaturedMenu(true).then( onFeatured   , onFeaturedFailed    );

        Promise.all( [ lastWatched, featured ] ).then( onRowsReady );
    };

    MainMenuScreen.prototype.setHighlighted = function( bool, newScreen )
    {
        if( bool === true )
        {
            _x2.requestFocus( this._buttons[this._index] );
            if( newScreen !== true )
                this._entries[this._index].setSpeechParams( undefined, "button", this, false );
        }
        else
            this._buttons[this._index].lostFocus();
    };

    MainMenuScreen.prototype.tick = function()
    {
        var shouldUrl = this.getBgInfo();
        var image     = this.getBgImage();
        var isUrl     = image.getUrl();

        if( isUrl !== shouldUrl )  // don't fade all the way to 0 since the tick may occur while screen is invisible, this ensures update when visible
            image.animate( { a:0.01, duration:500, onEnd: function() { image.setUrl( shouldUrl ); image.animate( { a:1, duration:500 } ); } } );
    };

    return MainMenuScreen;

})();
