// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.SapLanguageScreen = ( function()
{
    "use strict";

    SapLanguageScreen.prototype = new Screen();

    function SapLanguageScreen(){}

    SapLanguageScreen.prototype.entrySelected = function( key )
    {
        _x2._settings.setValue( Settings.Key.SAP_LANG, key );
    };

    SapLanguageScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list );

        this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr + "." );
        this._speakTarget.setSpeechParams( "Press up or down to review options. Press OK to select.", focus._speakRole, this, false );
    };

    SapLanguageScreen.prototype.init = function()
    {
        var entries, i, params = {}, self = this;

        Screen.prototype.init.call( this );
        this._className     = "SapLanguageScreen";
        this._telemetryName = "Languages"; // TODO: "Languages" or "Sap Language" ?
        this.setBreadCrumbs( "Settings", "Language" );

        this._rows = [];
        entries    = _x2._config._sapLanguages;

        for( i = 0; i < entries.length; i++ )
        {
            switch( entries[i] )
            {
                case "cafrench":
                    this._rows.push( { title:"Canadian French", key:entries[i], rowIndex:i } );
                    break;

                case "en":
                    this._rows.push( { title:"English", key:entries[i], rowIndex:i } );
                    break;

                case "es":
                    this._rows.push( { title:"Spanish", key:entries[i], rowIndex:i } );
                    break;

                case "french":
                    this._rows.push( { title:"French", key:entries[i], rowIndex:i } );
                    break;

                case "german":
                    this._rows.push( { title:"German", key:entries[i], rowIndex:i } );
                    break;

                case "italian":
                    this._rows.push( { title:"Italian", key:entries[i], rowIndex:i } );
                    break;

                case "portuguese":
                    this._rows.push( { title:"Portuguese", key:entries[i], rowIndex:i } );
                    break;

                default:
                    console.error( "ERROR -> unknown menu entry = " + entries[i] );
                    break;
            }
        }

        params.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        params.h        = _x2._config._screenH;
        params.maxIndex = 1;
        params.rows     = this._rows;
        params.type     = RadioButtonRowWidget;
        params.obj      = new RadioButtonController( _x2._settings.getValue( Settings.Key.SAP_LANG ), function( key ) { self.entrySelected( key ) } );

        this._list = new ListWidget().init( params );
        this.addWidget( this._list, Style._safeLeft );

        window.ll( "tagScreen", "Languages" );

        _x2._telemetry.sendScreenViewed( this._telemetryName );

        return this;
    };

    SapLanguageScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._list.setY( this.getHeaderBottom() );
        this._list.setH( _x2._config._screenH - this._list.getY() );
    };

    return SapLanguageScreen;

})();
