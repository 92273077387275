// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PurchasePin = (function()
{
    "use strict";

    PurchasePin.prototype = new DataObject();

    function PurchasePin(){}

    /**
     * Initialize PurchasePin object
     * @param data
     * @return {PurchasePin}
     */
    PurchasePin.prototype.init = function( data )
    {
        DataObject.prototype.init.call( this, data );

        return this;
    };

    /**
     * Set the purchase Pin
     * @param {string} currentPin
     * @param {string} newPin
     * @return {Promise}
     */
    PurchasePin.prototype.setPin = function( currentPin, newPin )
    {
        var form = this.getForm("setPurchasePin");

        form.getFields();

        if( currentPin )
            form.setFieldValue( "currentPin", currentPin );

        form.setFieldValue( "newPin", newPin );

        return form.submit();
    };

    /**
     * Return true if the purchase pin is enabled for stream
     * @return {boolean}
     */
    PurchasePin.prototype.isEnabled = function()
    {
        return ( this.get("enabled") === "stream" );
    };

    /**
     * Disable purchase pin
     * @param {string} pin - current pin
     * @return {Promise}
     */
    PurchasePin.prototype.disablePin = function( pin )
    {
        var form = this.getForm("disablePurchasePin");
        return form.submit( { currentPin:pin } );
    };

    /**
     * Verify purchase pin
     * @param {string} pin - current pin
     * @return {Promise} - valid pin on success callback, invalid pin on error callback.
     */
    PurchasePin.prototype.verifyPin = function( pin )
    {
        var form = this.getForm("verifyPurchasePin");

        form.getFields();
        form.setFieldValue("currentPin", pin);

        return form.submit();
    };

    return PurchasePin;
})();
