// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ChurnedMainMenuScreen = ( function()
{
    "use strict";

    ChurnedMainMenuScreen.prototype = new Screen();

    function ChurnedMainMenuScreen(){}

    ChurnedMainMenuScreen.prototype.gotFocus = function()
    {
        if( this._dataReady === true )
            this.speak( _x2.requestFocus( self._purchases, true ) );
        else
            this.speak();
    };

    ChurnedMainMenuScreen.prototype.init = function()
    {
        var self        = this;
        var buttonStyle = new Style( { font: "medium", color: "#e8e8e8", fontSize: _x2.scaleValInt(32), whiteSpace: "nowrap", colorHi: "#000000" } );

        Screen.prototype.init.call( this );
        this._className     = "ChurnedMainMenuScreen";
        this._telemetryName = "Churned Main Menu";

        var layout = function()
        {
            var y = self.getHeaderBottom();
            var h = _x2._config._screenH - y;

            self._purchases.setY( y );
            self._purchases.setH( h );

            self._options.setX( (_x2._config._screenW - self._options.getW()) / 2 );
            self._options.setY( y - self._options.getH() - 2 * Style._pad );

            self._purchases.setA( 1 );
        };

        var onReady = function()
        {
            self._dataReady = true;

            if( _x2._focus === self )
                self.speak( _x2.requestFocus( self._purchases, true ) );
        };

        this.setBreadCrumbs( undefined, "Purchases" );

        this._purchases = new PurchasesWidget().init( { path: "Purchases. " } );
        this._purchases.addReadyCallback( onReady );
        this._purchases.setA( 0 );
        this.addWidget( this._purchases );
        this._purchases.loadData();

        var onEnter = function()
        {
            _x2.pushScreen( new SettingsScreen().init( { path:"Purchases" } ) );
        };

        this._options = new HorizPillButtonsWidget().init( { entries:[ new StringWidget().init( { text:"Settings", style:buttonStyle, onEnter:onEnter } ) ] } );
        this.addWidget( this._options );

        this._options.addReadyCallback( layout );

        _x2.localyticsSendSplashPageAndProvisioning();

        return this;
    };

    ChurnedMainMenuScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED )
                    if( _x2._focus === this._options )
                        _x2.requestFocus( this._purchases );
                break;

            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                    if( _x2._focus !== this._options )
                        _x2.requestFocus( this._options );
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    ChurnedMainMenuScreen.prototype.speak = function( focus )
    {
        if( focus )
        {
            this._speakTarget.setText( "Purchases. " + focus._speakStr + ". " );
            this._speakTarget.setSpeechParams( "Press up to change settings. Press left, right, or down to browse content. Press OK to select. ", focus._speakRole, this, false );
        }
        else
        {
            this._speakTarget.setText( "Loading purchases, please wait. " );
            this._speakTarget.setSpeechParams( undefined, undefined, this, false );
        }
    };

    return ChurnedMainMenuScreen;

})();
