// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.CollectionRowWidget = ( function()
{
    "use strict";

    CollectionRowWidget.prototype = new ListRowWidget();

    function CollectionRowWidget(){}

    /**
     * @param {Object}  params
     * @param {Number}  params.w     - Width in pixels
     * @param {Number}  params.h     - Height in pixels
     * @param {Widget}  params.type  - Widget
     * @param {Number}  params.y     - Offset of list
     * @param {Number} [params.max]  - Max number of items to display in the row
     * @param {Object} [params.data] - Collection such as BrowseCollection
     * @param {Object} [params.obj]  - Object past to the list cell
     */
    CollectionRowWidget.prototype.init = function( params )
    {
        var listParams = {};
        var titleStyle = new Style( { color:"#e8e8e8", font:"light", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap" } );
        var instance   = new params.type();

        ListRowWidget.prototype.init.call( this, params );
        this._className  = "CollectionRowWidget";
        this._selectable = true;

        this._title = new StringWidget().init( { style:titleStyle } );
        this.addWidget( this._title, 0, _x2.scaleVal( 50 ) );

        this._speakTarget = new StringWidget().init( { style:titleStyle } );
        this._speakTarget.setA( 0 );
        this.addWidget( this._speakTarget );

        listParams.w        = params.w;
        listParams.h        = params.h;
        listParams.maxIndex = 1;
        listParams.axis     = ListWidget.Axis.X;
        listParams.stepTime = 250;
        listParams.type     = params.type;
        listParams.obj      = params.obj;
        listParams.touchH   = instance.getTouchDim ? instance.getTouchDim() : instance.getHeightUnfocused();
        listParams.touchW   = Math.floor( Style._safeLeft * 2 / 3 );

        this._list          = new ListWidget().init( listParams );
        this.addWidget( this._list, 0, params.y );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        if( params.data )
            this.setData( params.data, params.max );

        return this;
    };

    /**
     * @memberof CollectionRowWidget
     * @returns Number
     */
    CollectionRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 100 );
    };

    /**
     * @memberOf CollectionRowWidget
     * @return {number}
     */
    CollectionRowWidget.prototype.getListPosition = function()
    {
        return this._list ? this._list.getCurrentAndMaxIndex().current : 0;
    };

    /**
     * @memberOf CollectionRowWidget
     * @param index
     */
    CollectionRowWidget.prototype.setListPosition = function( index )
    {
        if( this._list )
            this._list.setJumpToIndex( index );
    };

    CollectionRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        var focus = _x2.requestFocus( this._list, true );

        if( focus )
        {
            var title  = this._data.getTitle();
            var parsed = title ? title.match( /\d?\d:\d\d[ap]?\s?-\s?\d?\d:\d\d[ap]/i ) : "";

            if( parsed )
                title = _x2.createTimeSpeechStr( parsed[0] );

            this._speakTarget.setText( title + ". " );
            this._speakTarget.setSpeechParams( focus._speakStr + ". Press left or right to review items. Press OK to select.", focus._speakRole, this, newScreen );
        }

        return newScreen === true ? this : focus;
    };

    /**
     * @memberof CollectionRowWidget
     * @param {Object} data - Collection such as BrowseCollection
     * @param {Number} max  - Cap the number of items in the row
     */
    CollectionRowWidget.prototype.setData = function( data, max )
    {
        if( data !== undefined )
        {
            this._data = data;
            this._title.setText( data.getTitle() );
            this._list.setData( data.getEntities( max ) );

            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    return CollectionRowWidget;

})();
