// Copyright (C) 2013 Comcast Corporation, All Rights Reserved

"use strict";

window.CiscoSaml = (function()
{
    function CiscoSaml( onHasSession, onNeedsCredentials )
    {
        this._onNeedsCredentials = onNeedsCredentials;

        var token = Host.getLocalStorage( "coxSaml" );

        if( token )
        {
            _x2._config._token = token;
            _x2._config._tokenType = "cisco-saml";
            onHasSession();
        }
        else
            setTimeout( function() { onNeedsCredentials(); }, 1 );
    }

    CiscoSaml.prototype.logout = function()
    {
        _x2._config._host.removeLocalStorage( "coxSaml" );
        _x2._config._token = undefined;
    };

    CiscoSaml.prototype.sendCredentials = function( username, password, onSuccess, onError )
    {
        var params = {
            type     : "GET",
            url      : _x2._config._authEndpoint,
            headers  : [{ name: "Authorization", value: "Basic " + btoa( username + ":" + password ) }]
        };

        var success = function( response )
        {
            var data = JSON.parse( response.data );
            console.log( data );

            var token = data.samlResponse;

            _x2._config._host.setLocalStorage( "coxSaml", token );
            _x2._config._token = token;
            _x2._config._tokenType = "cisco-saml";

            onSuccess();
        };

        var error = function( e ) {
            console.log( "Cisco SAML object error: ", e);
            onError();
        };

        _x2._network.ajax( params ).then( success ).catch( error );
    };

    return CiscoSaml;

})();
