// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Maps to the version of s:VodItem returned from the XTVAPI getPurchases query.
 */
window.Purchase = (function()
{
    "use strict";

    Purchase.prototype = new VodShowing();

    function Purchase(){}

    /**
     * Get the group this purchase belongs to
     * @return {PurchaseGroup}
     */
    Purchase.prototype.getGroup = function()
    {
        return this._group;
    };

    /**
     * Get the the series associated with this asset
     * @return {Entity}
     */
    Purchase.prototype.getSeries = function()
    {
        return this._series;
    };

    /**
     * Get identifier
     * @return {string}
     */
    Purchase.prototype.getId = function()
    {
        return this.getLink("self");
    };

    Purchase.prototype.getEntityType = function()
    {
        var entity = this.getEntity();
        if( entity )
            return entity.getType();
    };

    Purchase.prototype.getTransactionDetails = function()
    {
        if( this._transactionDetails === undefined )
        {
            var data = this.getEmbedded("transactionDetails");
            this._transactionDetails = new TransactionDetails().init( data );
        }

        return this._transactionDetails;
    };

    /**
     * Set the group for this purchase.
     * @param {PurchaseGroup} group
     */
    Purchase.prototype.setGroup = function( group )
    {
        this._group = group;
    };

    /**
     * Set the series for this asset
     * @param series
     */
    Purchase.prototype.setSeries = function( series )
    {
        this._series = series;
    };

    /**
     * Override from ProgramEntity - user cannot mark purchases as favorite.
     * @return {boolean}
     */
    Purchase.prototype.isFavoriteable = function()
    {
        return false;
    };

    return Purchase;
})();
