// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.SettingsScreen = ( function()
{
    "use strict";

    SettingsScreen.prototype = new Screen();

    function SettingsScreen(){}

    SettingsScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list, true );
        this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr );
        this._speakTarget.setSpeechParams( "Press up or down to review settings. Press OK for options. ", focus._speakRole, this, false );
    };

    SettingsScreen.prototype.init = function( params )
    {
        var entries, i, listParams = {}, onParentalControl, onPurchase;

        Screen.prototype.init.call( this );
        this._className     = "SettingsScreen";
        this._telemetryName = "Settings";
        this.setBreadCrumbs( params && params.path, "Settings" );

        onParentalControl = function()
        {
            console.log( "??????????????????? PARENTAL CONTROLS ???????????????????" );
            console.log( _x2._pc );

            var onGotIt = function()
            {
                _x2.pushScreen( new ParentalControlsScreen().init() );
            };

            PinOverlay.setPinFlow( PinOverlay.FlowType.PARENTAL_CONTROL, onGotIt );
        };

        onPurchase = function()
        {
            var onGotIt = function( pinObj, pin )
            {
                _x2.pushScreen( new PurchasePinScreen().init( { pinObj:pinObj, pin:pin } ) );
            };

            PinOverlay.setPinFlow( PinOverlay.FlowType.PURCHASE, onGotIt );
        };

        this._rows = [];
        entries    = _x2._config._settingsMenu.slice();  // make a copy so as not to persist splice across logout/login

        if( _x2._config._purchases === true && _x2._features.hasEntitlement( Features.Entitlement.EST ) && entries[2] !== "purchase" )
            entries.splice( 2, 0, "purchase" );

        for( i = 0; i < entries.length; i++ )
        {
            switch( entries[i] )
            {
                case "about":
                    this._rows.push( { title:_x2._st.about, onEnter: function() { _x2.pushOverlay( new AboutOverlay().init() ); } } );
                    break;

                case "accessibility":
                    this._rows.push( { title:"Accessibility", showIcon:true, onEnter:function() { _x2.pushScreen( new AccessibilityScreen().init() ); } } );
                    break;

                case "account":
                    this._rows.push( { title:"My Account" } );
                    break;

                case "device":
                    this._rows.push( { title:"Device Settings" } ); // TODO:  ll( "tagScreen", "Manage Devices" );
                    break;

                case "format":
                    this._rows.push( { title:"Format", showIcon:true, onEnter:function() { _x2.pushScreen( new FormatScreen().init() ); } } );
                    break;

                case "help":
                    this._rows.push( { title:"Help", onEnter: function() { _x2.pushOverlay( new HelpOverlay().init() ); } } );
                    break;

                case "language":
                    this._rows.push( { title:"Language", showIcon:true, onEnter: function() { _x2.pushScreen( new SapLanguageScreen().init() ); } } );
                    break;

                case "manage":
                    this._rows.push( { title:"Manage Devices", showIcon:true, onEnter:function() { _x2.pushScreen( new ManageDevicesScreen().init() ); } } );
                    break;

                case "parental":
                    this._rows.push( { title:"Parental Controls", showIcon:true, onEnter:onParentalControl } );
                    break;

                case "preferences":
                    this._rows.push( { title:"Preferences" } );
                    break;

                case "signOut":
                    this._rows.push( { title:"Sign Out", onEnter: function() { _x2.pushOverlay( new SignOutOverlay().init() ); } } );
                    break;

                case "terms":
                    this._rows.push( { title:"Terms & Policies", onEnter: function() { _x2.pushOverlay( new PolicyLinksOverlay().init({ type: 'terms' }) ); } } );
                    break;

                case "privacy":
                    this._rows.push( { title:"Privacy", onEnter: function() { _x2.pushOverlay( new PolicyLinksOverlay().init({ type: 'privacy' }) ); } } );
                    break;

                case "purchase":
                    this._rows.push( { title:"Purchase PIN", showIcon:true, onEnter:onPurchase } );
                    break;

                default:
                    console.error( "ERROR -> unknown menu entry = " + entries[i] );
                    break;
            }
        }

        listParams.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 1;
        listParams.rows     = this._rows;
        listParams.type     = MenuRowWidget;

        this._list = new ListWidget().init( listParams );
        this.addWidget( this._list, Style._safeLeft );

        window.ll( "tagScreen", "Settings" );
        _x2._telemetry.sendScreenViewed( this._telemetryName );

        return this;
    };

    SettingsScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._list.setY( this.getHeaderBottom() );
        this._list.setH( _x2._config._screenH - this._list.getY() );
    };

    return SettingsScreen;

})();
