// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ScheduledRowWidget = ( function()
{
    "use strict";

    ScheduledRowWidget.prototype = new ListRowWidget();
    ScheduledRowWidget.DAYS   = [ "Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat" ];

    var rawImW = 102;
    var rawImH = 136;
    var imGap  =  11;

    function ScheduledRowWidget(){}

    ScheduledRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        ListRowWidget.prototype.gotFocus.call( this );
        this._title.gotFocus();
        this._sep.setSpeechParams( this._title.getText().replace( /[\s]Ep([\d])/, "Episode $1" ) + ". " + this._subTitle.getText() + ". Press OK to select. ", undefined, this, newScreen );

        return this;
    };

    /**
     * Initializer
     * @memberof ScheduledRowWidget
     * @param   {Object}   params
     * @param   {Number}   params.w - Width
     * @returns {ScheduledRowWidget}
     * @see ListRowWidget
     */
    ScheduledRowWidget.prototype.init = function( params )
    {
        params.chevron = true;
        ListRowWidget.prototype.init.call( this, params );
        this._className = "ScheduledRowWidget";

        var self          = this;
        var titleStyle    = new Style( { color:"#e8e8e8", font:"light", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#2ea0dd", overflow:"hidden", textOverflow:"ellipsis" } );
        var subTitleStyle = new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );
        var imW           = _x2.scaleValInt( rawImW );
        var imH           = _x2.scaleValInt( rawImH );

        this._progressW   = _x2.scaleValInt(300);
        this._progressH   = _x2.scaleValInt(14);
        this._numElements = 0;
        this._selectable  = true;
        this._maxH        = parseInt( this.getHeightUnfocused() / 2 );
        this._maxW        = parseInt( this._maxH * 5 / 3 );

        this._time  = new StringWidget().init( { text:"12:00pm", style:subTitleStyle } );
        this.addWidget( this._time );

        this._title = new StringWidget().init( { text:"?", style:titleStyle } );
        this.addWidget( this._title );

        this._subTitle = new StringWidget().init( { text:"?????????", style:subTitleStyle } );
        this._subTitle.addReadyCallback( function() { self.layout(); } );
        this.addWidget( this._subTitle );

        // AAECH-74
        var onLoad = function()
        {
            self._image.setA(1);
        };

        this._image = new ImageWidget().init( { w:imW, h:imH, onLoad:onLoad, onChange:onLoad } );
        this._image.setA(0);
        this.addWidget( this._image, 0, _x2.scaleValInt(imGap) );

        this._progressWidget = new ProgressBarWidget().init( { w:this._progressW, h:this._progressH, style:"recording" } );
        this._progressWidget.setA( 0 );
        this.addWidget( this._progressWidget );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        return this;
    };

    ScheduledRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 2 * imGap + rawImH );
    };

    ScheduledRowWidget.prototype.layout = function()
    {
        var    h = this.getHeightUnfocused();
        var gapX = _x2.scaleValInt( 24 );
        var gapY = _x2.scaleValInt( 12 );

        this._time.setY( (h - this._time.getH()) / 2 );
        this._image.setX( this._time.getX() + this._time.getW() + gapX );
        this._title.setX( this._image.getX() + this._image.getW() + gapX );
        this._title.setY( (h - this._title.getH() - this._subTitle.getH() - gapY) / 2 );
        this._subTitle.setX( this._title.getX() );
        this._subTitle.setY( this._title.getY() + this._title.getH() + gapY );

        this._progressWidget.setX( this.getW() - this._progressW * 1.33 );
        this._progressWidget.setY( this.getHeightUnfocused() / 2 - this._progressH / 2 );
    };

    ScheduledRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._title.lostFocus();
    };

    ScheduledRowWidget.prototype.processEvent = function( val, type )
    {
        var retval = false;

        if( val === Host.KEY_ENTER && type === Host.KEY_PRESSED )
        {
            _x2.pushScreen( new EpisodeScreen().init( { entity: this._entity, infoButton:true } ) );
            retval = true;
        }

        return retval;
    };

    /**
     * @memberof ScheduledRowWidget
     * @param {ProgramEntity | Recording} data
     */
    ScheduledRowWidget.prototype.setData = function( data )
    {
        var self = this, url, title, subTitle, timeStr, date, now, daysOffset, channel, onError, program, sNumber, epNumber, onErrorFallback, progress;

        if( data !== undefined )
        {
            this._entity = data;
            this._progressWidget.setA( 0 );

            title    = "";
            subTitle = "Recording ";

            if( data instanceof Recording ) //TODO: what else can this be in "ScheduledRowWidget" !?!?
            {
                timeStr = data.getRecordedOnDate();
                date    = new Date( timeStr );

                this._time.setText( _x2.createTimeStr( date ) );

                if( data.getSeriesTitle() )
                    title = data.getSeriesTitle(); // XC-13301: Example: "CBS4 This Morning - 530A"
                else
                    title = data.getTitle();

                now         = new Date();
                daysOffset  = date.getDate() - now.getDate();

                if( daysOffset < 0 )
                    daysOffset += new Date( now.getFullYear(), now.getMonth() + 1, 0).getDate();

                if( daysOffset === 0 )
                    subTitle += "today, ";
                else if( daysOffset === 1 )
                    subTitle += "tommorow, ";
                else if( daysOffset < 7 )
                    subTitle += ScheduledRowWidget.DAYS[date.getDay()] + ", ";
                else
                    subTitle += (date.getMonth()+1) + "/" + date.getDate() + ", ";

                subTitle += _x2.createTimeRange( date, new Date( date.getTime() + data.getDurationMs() ) );

                channel = data.getChannel();

                subTitle += " on " + channel.getNumber() + " " + channel.getCallSign();

                this._subTitle.setText( subTitle );

                // AAECH-74
                onErrorFallback = function()
                {
                    self._image.setA( 0 );
                };

                onError = function()
                {
                    self._image.setOnError( onErrorFallback );
                    self._image.setUrl( data.getFallbackImageLink( rawImW, rawImH ) );
                };

                this._image.setUrl();
                this._image.setOnError( onError );

                url = data.getImageLink( rawImW, rawImH );
                this._image.setUrl( url );

                progress = data.getRecordingProgress();
                if( progress > 0 && progress < 100 )
                {
                    this._progressWidget.setProgress( data.getRecordingProgress() );
                    this._progressWidget.setA( 1 );
                    this._title.setW( _x2.scaleValInt(1050) );
                }
                else
                    this._title.setW( _x2.scaleValInt(1345) );
            }

            program = data.getProgram();

            if( program )
            {
                sNumber  = program.getSeasonNumber();
                epNumber = program.getEpisodeNumber();

                if( sNumber !== undefined || epNumber !== undefined )
                    title += " -";

                if( sNumber !== undefined )
                    title += " S" + sNumber;

                if( epNumber !== undefined )
                    title += " Ep" + epNumber + " "; // XC-13301

                //don't duplicate if the series and episode titles are the same (common with news/talk shows)
                if( title.indexOf(program.getTitle()) === -1 )
                    title += program.getTitle();
            }

            this._title.setText(title);
            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    return ScheduledRowWidget;

})();
