// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ChoiceScreen = ( function()
{
    "use strict";

    ChoiceScreen.prototype = new Screen();

    function ChoiceScreen(){}

    ChoiceScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list );
        this._speakTarget.setText( this.getBreadCrumbsTermStr() + ". " + focus._speakStr + "." );
        this._speakTarget.setSpeechParams( "Press up or down to review options. Press OK to select.", focus._speakRole, this, false );
    };

    /**
     * @memberof ChoiceScreen
     * @param   {Object}   params
     * @param   {String}   params.bcPath        - path to use for bread crumb
     * @param   {String}   params.bcScreen      - screen name to use for bread crumb
     * @param   {Function} params.onEnter       - function to execute on enter, will receive the index of the selected row
     * @param   {Array}    params.rows          - data object for each entry to display
     * @param   {Number}   params.selectedIndex - index if the currently active entry
     * @returns {ChoiceScreen}
     */
    ChoiceScreen.prototype.init = function( params )
    {
        Screen.prototype.init.call( this );
        this._className     = "ChoiceScreen";
        this._telemetryName = "Choice";
        this.setBreadCrumbs( params.bcPath, params.bcScreen );

        var self = this, listParams = {}, rows = [];

        for( var i = 0; i < params.rows.length; i++ )
            rows[i] = { title:params.rows[i], rowIndex:i, selected:(i === params.selectedIndex), onEnter:function() { params.onEnter( self._list.getCurrentAndMaxIndex().current ); _x2.popScreen(); } };

        listParams.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 1;
        listParams.rows     = rows;
        listParams.type     = ChoiceRowWidget;

        this._list = new ListWidget().init( listParams );
        this.addWidget( this._list, Style._safeLeft );

        return this;
    };

    ChoiceScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._list.setY( this.getHeaderBottom() );
        this._list.setH( _x2._config._screenH - this._list.getY() );
    };

    return ChoiceScreen;

})();
