// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ListWidget = ( function()
{
    "use strict";

    ListWidget.prototype = new Widget();

    /**
     * @enum Axis
     * @property {Number} X - Horizontal List
     * @property {Number} Y - Vertical List
     */
    ListWidget.Axis = Object.freeze
    ({
        X : 0,
        Y : 1
    });

    ListWidget.SCROLL_TIME_STEP = 300;

    var SCROLL = Object.freeze
    ({
        BACK     : 0,
        BACK_STOP: 1,
        FWD      : 2,
        FWD_STOP : 3
    });

    function ListWidget(){}

    ListWidget.prototype.appendData = function( rows )
    {
        var lastDataIndex = this._rows.length - 1;

        this._rows = this._rows.concat( rows );

        for( var i = 0; i < this._cells.length; i++ )
            if( this._cells[i]._dataIndex > lastDataIndex )
                this._cells[i].setData( this._rows[this._cells[i]._dataIndex] );
    };

    ListWidget.prototype.doFocusTransition = function( index, repeating )
    {
        if( this._cells[0].getPosAndDimUnfocused )
        {
            if( index === undefined )
                index = 0;

            var posAndDim = this._cells[index].getPosAndDimUnfocused( repeating );

            if( this._axis === ListWidget.Axis.X )
            {
                var y = this._cells[index].getGlobalPos().y + posAndDim.pos - _x2._hi._top;
                var h = posAndDim.dim;

                _x2._hi.stopAnimation( Widget.Y );
                _x2._hi.stopAnimation( Widget.H );
                _x2._hi.animate( { y:y, h:h, duration:this._stepTime, inner:false, easing:Widget.Easing.LINEAR } );
            }
            else
            {
                var x = this._cells[index].getGlobalPos().x + posAndDim.pos;
                var w = posAndDim.dim;

                _x2._hi.stopAnimation( Widget.X );
                _x2._hi.stopAnimation( Widget.W );
                _x2._hi.animate( { x:x, w:w, duration:this._stepTime, inner:false, easing:Widget.Easing.LINEAR } );
            }
        }
    };

    ListWidget.prototype.getCurrentAndMaxIndex = function()
    {
        var retval;

        if( this._cells && this._rows )
            retval = { current:this._cells[(this._cellOffset + this._index) % this._cells.length]._dataIndex, max:this._rows.length, rows:this._rows };
        else
            retval = { current:0, max:0 };

        return retval;
    };

    ListWidget.prototype.getMaxIndex = function()
    {
        return this._rows !== undefined ? this._rows.length : 0;
    };

    ListWidget.prototype.getVisibleRowCount = function()
    {
        return this._numVis;
    };

    ListWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        var retval;

        if( this._rows && this._rows.length && this._blocked !== true )
            retval = _x2.requestFocus( this._cells[(this._cellOffset + this._index) % this._cells.length], newScreen );

        return retval;
    };

    /**
     * Initializer
     * @memberof ListWidget
     * @param   {Object}        params
     * @param   {Number}        params.axis               - Orientation of list
     * @param   {Number}        params.h                  - Height of the list
     * @param   {Boolean}       params.hide               - Hide the selection widget on transition between cells
     * @param   {Number}        params.maxIndex           - Max index in the list to which the selection box will move before scrolling list
     * @param   {Object}        [params.obj]              - Object that is passed though to the list cells
     * @param   {Number}        [params.rows]             - Array of data abjects to display
     * @param   {Function}      [params.stepListener]     - Function called on each animation step of the list with the current index combined with percentage to the next
     * @param   {Function}      [params.stepStartListener]- Function called at the beginning of a single step
     * @param   {Function}      [params.stepStopListener] - Function called at the end on scroll
     * @param   {Number}        [params.stepTime=300]     - Number of milliseconds to move one cell
     * @param   {Number}        [params.touchH=0]         - Height of the more items touch area
     * @param   {Number}        [params.touchW=0]         - Width of the more items touch area
     * @param   {ListRowWidget} params.type               - The specific type of ListRowWidget this list contains
     * @param   {Number}        params.w                  - Width of the list
     * @returns {ListWidget}
     */
    ListWidget.prototype.init = function( params )
    {
        var i, numCells, dimension, self = this;

        Widget.prototype.init.call( this );
        this._className = "ListWidget";

        this.setW( params.w );
        this.setH( params.h );
        this._stepTime          = (params.stepTime !== undefined) ? params.stepTime : ListWidget.SCROLL_TIME_STEP;
        this._stepListener      = params.stepListener;
        this._stepStartListener = params.stepStartListener;
        this._stepStopListener  = params.stepStopListener;
        this._rows              = params.rows;
        this._axis              = (params.axis === ListWidget.Axis.X) ? ListWidget.Axis.X : ListWidget.Axis.Y;
        this._selectable        = true;
        this._cellOffset        = 0;
        this._blocked           = false;
        this._hide              = params.hide;
        this._queue             = [];
        this._cells             = [];
        this._focusObj          = { inner:false, duration:0 };
        this._maxFocusIndex     = params.maxIndex;
        this._index             = this._maxFocusIndex;
        this._cellH             = new params.type().getHeightUnfocused();
        this._widgetType        = params.type && params.type.name;
        this._container         = new Widget().init();
        this._scroll            = new ScrollWidget().init( { widget:this._container, w:params.w, h:params.h } );
        this.addWidget( this._scroll );

        dimension    = ( this._axis === ListWidget.Axis.X ) ? params.w : params.h;
        this._numVis = Math.floor( dimension / this._cellH ) + ((dimension % this._cellH > 0) ? 1 : 0);
        numCells     = this._numVis + this._maxFocusIndex + 2;

        for( i = 0; i < numCells; i++ )
        {
            if( this._axis === ListWidget.Axis.X )
            {
                this._cells.push( new params.type().init( { h:params.h, obj:params.obj, rowIndex:i } ) );
                this._cells[i].setH( params.h );
                this._container.addWidget( this._cells[i], i * this._cellH );
            }
            else
            {
                this._cells.push( new params.type().init( { w:params.w, obj:params.obj, rowIndex:i } ) );
                this._cells[i].setW( params.w );
                this._container.addWidget( this._cells[i], 0, i * this._cellH );
            }

            this._cells[i]._dataIndex = i - this._index;
            this._cells[i].forceUpdate( true );

            if( !this._rows )
                this._cells[i].setA( 0 );
        }

        this._cells[this._cells.length-1]._dataIndex -= this._cells.length;

        if( this._axis === ListWidget.Axis.X )
        {
            this._scroll.setScroll( { x:-this._index*this._cellH, duration:0 } );

            if( params.touchH >= 0 && params.touchW >= 0 )
            {
                this._moreLeft  = new MoreTargetWidget().init( { dir:MoreTargetWidget.Dir.LEFT , h:params.touchH, w:params.touchW } );
                this._moreRight = new MoreTargetWidget().init( { dir:MoreTargetWidget.Dir.RIGHT, h:params.touchH, w:params.touchW } );
                this._moreLeft.setA( 0 );
                this._moreRight.setA( 0 );
                this.addWidget( this._moreLeft, -params.touchW );
                this.addWidget( this._moreRight, params.w );
                this._moreLeft.setMouseDownListener ( function() { self.processEvent( Host.KEY_LEFT , Host.KEY_PRESSED ); self.processEvent( Host.KEY_LEFT , Host.KEY_RELEASED ); } );
                this._moreRight.setMouseDownListener( function() { self.processEvent( Host.KEY_RIGHT, Host.KEY_PRESSED ); self.processEvent( Host.KEY_RIGHT, Host.KEY_RELEASED ); } );
            }
        }
        else
            this._scroll.setScroll( { y:-this._index*this._cellH, duration:0 } );

        this.setPosition( this._cells[this._cells.length-1], -this._cellH );

        this.setMouseWheelListener( function( event ) { if( self._blocked === false ) self.processEvent( ( event.deltaY > 0 ) ? Host.KEY_DOWN : Host.KEY_UP, Host.KEY_PRESSED ); } );

        if( params.rows )
            this.setData( params.rows );
        return this;
    };

    ListWidget.prototype.getPosition = function( obj )
    {
        return ( this._axis === ListWidget.Axis.X ) ? obj.getX() : obj.getY();
    };

    ListWidget.prototype.processEvent = function( val, type )
    {
        var retval, onDownEnd, onDownInc, onUpEnd, onUpInc, numVis, updateIndex, nextDataIndex, self = this, aListener, hListener, startBack, startFwd, collapse, currIndex;

        onDownEnd = function()
        {
            if( self._currentFocusIndex ) {
                if( self._cellOffset != self._currentFocusIndex ) {
                    self._cells[ self._currentFocusIndex ].lostFocus();
                }
                self._currentFocusIndex -= 1;
            }
            onDownInc( 1 );
            self._cellOffset = (self._cellOffset + self._cells.length - 1) % self._cells.length;
            if( self._supressSelection !== true )
                _x2.requestFocus( self._cells[(self._cellOffset + self._index) % self._cells.length] );
            else
                self._cells[(self._cellOffset + self._index) % self._cells.length].gotFocus();
            self.processQueue();
        };

        onDownInc = function( inc )
        {
            var start, end, i, gPos;
            var dataIndex = self._cells[(self._cellOffset + self._index) % self._cells.length]._dataIndex;
            var delta     = self.getPosition( self._cells[self._cellOffset] );
            var offset    = (dataIndex - self._maxFocusIndex) * self._cellH - delta;

            if( self._stepListener )
                self._stepListener( dataIndex - inc );

            start = (self._cellOffset + 1) % self._cells.length;
            end   = start + self._cells.length - 3;  // exclude _cellOffset cell, very bottom and very top cells

            for( i = start; i < end; i++ )
                self.setPosition( self._cells[i % self._cells.length], self.getPosition( self._cells[self._cellOffset] ) + (i - start + 1) * self._cellH );

            start = end;
            end   = start + 2;

            for( i = start; i < end; i++ )
                self.setPosition( self._cells[i % self._cells.length], self.getPosition( self._cells[self._cellOffset] ) - (start + 2 - i) * self._cellH );

            if( dataIndex <= self._maxFocusIndex )
            {
                gPos = self.getGlobalPos();

                if( self._axis === ListWidget.Axis.X )
                    self._focusObj.x = gPos.x - self.getPosition( self._cells[self._maxFocusIndex] ) + self._cellH * self._maxFocusIndex;
                else
                    self._focusObj.y = gPos.y - self.getPosition( self._cells[self._maxFocusIndex] ) + self._cellH * self._maxFocusIndex;

                self.setPosition( self._container, offset );
                if( self._supressSelection !== true )
                    _x2._hi.setPosition( self._focusObj );
            }
        };

        onUpEnd = function()
        {
            if( self._currentFocusIndex ) {
                if( self._cellOffset != self._currentFocusIndex ) {
                    self._cells[ self._currentFocusIndex ].lostFocus();
                }
                
                self._currentFocusIndex += 1;
            }
            onUpInc( 1 );
            self._cellOffset = (self._cellOffset + 1) % self._cells.length;
            if( self._supressSelection !== true )
                _x2.requestFocus( self._cells[(self._cellOffset + self._index) % self._cells.length] );
            else
                self._cells[(self._cellOffset + self._index) % self._cells.length].gotFocus();
            self.processQueue();
        };

        onUpInc = function( inc )
        {
            var index, i, gPos;
            var dataIndex = self._cells[(self._cellOffset + self._index) % self._cells.length]._dataIndex;
            var delta     = self.getPosition( self._cells[self._cellOffset] );
            var offset    = (dataIndex - self._maxFocusIndex) * self._cellH - delta;

            if( self._stepListener )
                self._stepListener( dataIndex + inc );

            for( i = 0; i < self._cells.length; i++ )
                if( i !== self._cellOffset )
                {
                    index = (i-self._cellOffset+self._cells.length) % self._cells.length;
                    self.setPosition( self._cells[i], index * self._cellH + self.getPosition( self._cells[self._cellOffset] ) );
                }

            if( dataIndex < self._maxFocusIndex )
            {
                gPos = self.getGlobalPos();

                if( self._axis === ListWidget.Axis.X )
                    self._focusObj.x = gPos.x - self.getPosition( self._cells[self._maxFocusIndex] ) + self._cellH * self._maxFocusIndex;
                else
                    self._focusObj.y = gPos.y - self.getPosition( self._cells[self._maxFocusIndex] ) + self._cellH * self._maxFocusIndex;

                self.setPosition( self._container, offset );

                if( self._supressSelection !== true )
                    _x2._hi.setPosition( self._focusObj );
            }
        };

        startBack = function( type, val )
        {
            var obj, retval = true;

            if( type === Host.KEY_PRESSED )
            {
                if( self._blocked === false && self._cellOffset > -1 )
                {
                    currIndex   = self._cells[self._cellOffset]._dataIndex;
                    updateIndex = (self._cellOffset + self._cells.length - 2) % self._cells.length;
                    numVis      = self._rows.length - 1 - self._cells[self._cellOffset]._dataIndex;

                    if( self._moreLeft && currIndex === 1 )
                        self._moreLeft.animate( { a:0, duration:500 } );

                    if( self._moreRight && (currIndex + self._numVis) === self._rows.length )
                        self._moreRight.animate( { a:1, duration:500 } );

                    if( self._cells[updateIndex]._dataIndex >= 0 )
                        nextDataIndex = self._cells[updateIndex]._dataIndex - self._cells.length;
                    else
                        nextDataIndex = self._cells[updateIndex]._dataIndex;

                    if( nextDataIndex >= -1 - self._maxFocusIndex )
                    {
                        if( self._hide === true )
                            _x2._hi.fadeOut( 0 );
                        else
                            self.doFocusTransition( (self._cellOffset + self._index + self._cells.length - 1) % self._cells.length );

                        self._cells[(self._cellOffset + self._index) % self._cells.length].lostFocus();
                        self._cells[updateIndex].setData( self._rows[nextDataIndex] );
                        self._cells[updateIndex]._dataIndex = nextDataIndex;
                        self._blocked = true;

                        obj = { duration:self._stepTime, easing:Widget.Easing.LINEAR, onInc:onDownInc, onEnd:onDownEnd };

                        if( self._axis === ListWidget.Axis.X )
                            obj.x = self._cellH;
                        else
                            obj.y = self._cellH;

                        self._cells[self._cellOffset].animate( obj );

                        if( self._stepStartListener )
                            self._stepStartListener( -1 );

                        if( self._supressSelection !== true )
                            _x2.requestFocus( self );
                    }
                    else
                        retval = false;
                }
                else
                    self._queue.push( [ val, type ] );
            }
            else if( type === Host.KEY_RELEASED )
            {
                if( self._queue.length > 0 )
                {
                    if( self._queue[self._queue.length-1][1] === Host.KEY_REPEAT )
                        self._queue.pop();
                }
                else if( self._scrollMode !== undefined ) // && self._stop === undefined )
                {
                    if( self._stop === undefined )
                    {
                        if( self._datum === undefined )
                            self._datum = 1;

                        self._scrollMode = SCROLL.BACK_STOP;
                    }
                    else
                        console.error( "_STOP is defined, IGNORE" );
                }
            }
            else if( type === Host.KEY_REPEAT )
            {
                if( self._blocked === false )
                {
                    if( self._queue.length > 0 )
                    {
                        self._queue.push( [ val, type ] );
                    }
                    else
                    {
                        if( self._hide === true )
                            _x2._hi.fadeOut( 0 );
                        else
                            self.doFocusTransition( 0, true );

                        self._cells[(self._cellOffset + self._index) % self._cells.length].lostFocus();
                        self._blocked    = true;
                        self._scrollMode = SCROLL.BACK;

                        if( self._supressSelection !== true )
                            _x2.requestFocus( self );
                    }
                }
                else
                    self._queue.push( [ val, type ] );
            }

            return retval;
        };

        startFwd = function( type, val )
        {
            var obj, retval = true;

            if( type === Host.KEY_PRESSED )
            {
                if( self._blocked === false && ! self._dataBlocking )
                {
                    currIndex     = self._cells[self._cellOffset]._dataIndex;
                    updateIndex   = (self._cellOffset + self._cells.length - 1) % self._cells.length;
                    nextDataIndex = self._cells[updateIndex]._dataIndex + self._cells.length;
                    numVis        = self._rows.length - 1 - currIndex;

                    if( self._moreLeft && currIndex === 0 )
                        self._moreLeft.animate( { a:1, duration:500 } );

                    if( self._moreRight && (currIndex + self._maxFocusIndex + self._numVis) === self._rows.length )
                        self._moreRight.animate( { a:0, duration:500 } );

                    if( numVis >= self._maxFocusIndex + 1 )
                    {
                        if( self._hide === true )
                            _x2._hi.fadeOut( 0 );
                        else
                            self.doFocusTransition( (self._cellOffset + self._index + 1) % self._cells.length );

                        self._cells[(self._cellOffset + self._index) % self._cells.length].lostFocus();
                        self._cells[updateIndex].setData( (nextDataIndex < self._rows.length) ? self._rows[nextDataIndex] : undefined );
                        self._cells[updateIndex]._dataIndex = nextDataIndex;
                        self._blocked = true;

                        obj = { duration:self._stepTime, easing:Widget.Easing.LINEAR, onInc:onUpInc, onEnd:onUpEnd };

                        if( self._axis === ListWidget.Axis.X )
                            obj.x = -self._cellH;
                        else
                            obj.y = -self._cellH;

                        self._cells[self._cellOffset].animate( obj );

                        if( self._stepStartListener )
                            self._stepStartListener( 1 );

                        if( self._supressSelection !== true )
                            _x2.requestFocus( self );
                    }
                    else
                        retval = false;
                }
                else
                    self._queue.push( [ val, type ] );
            }
            else if( type === Host.KEY_RELEASED )
            {
                if( self._queue.length > 0 )
                {
                    if( self._queue[self._queue.length-1][1] === Host.KEY_REPEAT )
                        self._queue.pop();
                }
                else if( self._scrollMode !== undefined ) // && self._stop === undefined )
                {
                    if( self._stop === undefined )
                    {
                        if( self._datum === undefined )
                            self._datum = 1;

                        self._scrollMode = SCROLL.FWD_STOP;
                    }
                    else
                        console.error( "_STOP is defined, IGNORE" );
                }
            }
            else if( type === Host.KEY_REPEAT )
            {
                if( self._blocked === false )
                {
                    if( self._queue.length > 0 )
                    {
                        self._queue.push( [ val, type ] );
                    }
                    else
                    {
                        if( self._hide === true )
                            _x2._hi.fadeOut( 0 );
                        else
                            self.doFocusTransition( 0, true );

                        self._cells[(self._cellOffset + self._index) % self._cells.length].lostFocus();
                        self._blocked    = true;
                        self._scrollMode = SCROLL.FWD;

                        if( self._supressSelection !== true )
                            _x2.requestFocus( self );
                    }
                }
                else
                    self._queue.push( [ val, type ] );
            }

            return retval;
        };

        collapse = function()
        {
            aListener = function( a )
            {
                for( var i = 0; i < self._cells.length; i++ )
                {
                    if( self._cells[i]._dataIndex < self._rows.length && i !== self._cellOffset  )
                        self._cells[i].setA( a );
                }
            };

            hListener = function( h )
            {
                var focusY = _x2._focus.getY();

                for( var i = 0; i < self._cells.length; i++ )
                {
                    if( self._cells[i]._baseY > focusY )
                        self._cells[i].setY( self._cells[i]._baseY + h - self._cellH );
                }
            };

            self._expanded = false;

            if( self._cells[self._cellOffset + self._index].contract )
                self._cells[self._cellOffset + self._index].contract( hListener, aListener );

            if( _x2._focus !== self._cells[self._cellOffset + self._index] )
                _x2.requestFocus( self );
        };

        switch( val )
        {
            case Host.KEY_DOWN:
                if( this._rows && this._expanded !== true && this._axis === ListWidget.Axis.Y )
                    retval = startFwd( type, Host.KEY_DOWN );
                else
                    retval = Widget.prototype.processEvent.call( this, val, type );
                break;

            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._expanded !== true && _x2._focus !== this )
                    {
                        aListener = function( a )
                        {
                            for( var i = 0; i < self._cells.length; i++ )
                            {
                                if( self._cells[i] !== _x2._focus && self._cells[i].getA() > 0 )
                                    self._cells[i].setA( a );
                            }
                        };

                        hListener = function( h )
                        {
                            var focusY = _x2._focus.getY();

                            for( var i = 0; i < self._cells.length; i++ )
                            {
                                if( self._cells[i]._baseY > focusY )
                                    self._cells[i].setY( self._cells[i]._baseY + h - self._cellH );
                            }
                        };

                        this._expanded = true;

                        for( var i = 0; i < this._cells.length; i++ )
                            this._cells[i]._baseY = this._cells[i].getY();

                        if( _x2._focus.expand )
                            _x2._focus.expand( hListener, aListener );
                    }
                }
                else
                    retval = Widget.prototype.processEvent.call( this, val, type );
                break;

            case Host.KEY_LAST:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._expanded === true )
                        collapse();
                    else
                        retval = Widget.prototype.processEvent.call( this, val, type );
                }
                break;

            case Host.KEY_LEFT:
                if( this._rows && this._expanded !== true && this._axis === ListWidget.Axis.X )
                    startBack( type, Host.KEY_LEFT );
                else {
                    retval = Widget.prototype.processEvent.call( this, val, type );
                } 
                break;

            case Host.KEY_RIGHT:
                if( this._rows && this._expanded !== true && this._axis === ListWidget.Axis.X )
                    startFwd( type, Host.KEY_RIGHT );
                else
                    retval = Widget.prototype.processEvent.call( this, val, type );
                break;

            case Host.KEY_UP:
                if( this._expanded === true )
                {
                    if( type === Host.KEY_PRESSED )
                        collapse();
                }
                else if( this._rows && this._expanded !== true && this._axis === ListWidget.Axis.Y && (this._cells[this._cellOffset]._dataIndex + this._maxFocusIndex) > 0 )
                    startBack( type, Host.KEY_UP );
                else
                    retval = Widget.prototype.processEvent.call( this, val, type );
                break;

            default:
                retval = Widget.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    ListWidget.prototype.processQueue = function()
    {
        var key = this._queue.shift();

        this._blocked = false;

        if( key !== undefined )
            this.processEvent( key[0], key[1] );
    };

    ListWidget.prototype.refreshCells = function()
    {
        for( var i = 0; i < this._cells.length; i++ )
            if( this._cells[i].refreshCell )
                this._cells[i].refreshCell();
    };

    ListWidget.prototype.setBlockingOnData = function( blocking )
    {
        this._dataBlocking = blocking;
    };

    ListWidget.prototype.setData = function( rows )
    {
        this._rows       = rows;
        this._cellOffset = 0;
        this._index      = this._maxFocusIndex;

        for( var i = 0; i < this._cells.length; i++ )
        {
            this._cells[i]._dataIndex = i-this._index;
            this._cells[i].setData( rows ? rows[this._cells[i]._dataIndex] : undefined );

            this.setPosition( this._cells[i], i * this._cellH );
        }

        this._cells[this._cells.length-1]._dataIndex -= this._cells.length;

        if( this._axis === ListWidget.Axis.X )
            this._scroll.setScroll( { x:-this._index*this._cellH, duration:0 } );
        else
            this._scroll.setScroll( { y:-this._index*this._cellH, duration:0 } );

        this.setPosition( this._cells[this._cells.length-1], -this._cellH );

        if( this._moreLeft )
            this._moreLeft.animate( { a:0, duration:500 } );

        if( this._moreRight )
            if( rows && rows.length > this._numVis )
                this._moreRight.animate( { a:1, duration:500 } );
            else
                this._moreRight.animate( { a:0, duration:500 } );

        if( _x2._focus === this )
            this.gotFocus();
    };

    ListWidget.prototype.setEntry = function( tag, val, newRow )
    {
        var row, whichCell;

        if( this._rows )
            for( var i = 0; i < this._rows.length; i++ )
            {
                row = this._rows[i];

                if( row[tag] === val )
                {
                    this._rows[i] = newRow;
                    whichCell     = (this._maxFocusIndex + i) % this._cells.length;

                    if( this._cells[whichCell]._dataIndex === i )
                        this._cells[whichCell].setData( newRow );

                    break;
                }
            }
    };

    ListWidget.prototype.setJumpToIndex = function( dataIndex )
    {
        if( dataIndex > this._rows.length - 1 )
            dataIndex = this._rows.length - 1;

        var index, i, start, pos;
        var cellIndex = (dataIndex + this._maxFocusIndex) % this._cells.length;
        var shifts    = (dataIndex < this._maxFocusIndex) ? this._maxFocusIndex - dataIndex : 0;

        this.setPosition( this._container, -shifts * this._cellH );

        for( i = 0; i < this._cells.length; i++ )
        {
            index = (cellIndex + i + this._cells.length) % this._cells.length;

            this._cells[index]._dataIndex = dataIndex + i;
            this._cells[index].setData( this._rows ? this._rows[this._cells[index]._dataIndex] : undefined );
            this.setPosition( this._cells[index], (i + this._maxFocusIndex + shifts) * this._cellH + this.getPosition( this._container ) );
        }

        start = cellIndex + this._cells.length - 1;
        pos   = this.getPosition( this._cells[cellIndex] );

        for( i = start; i > start - this._maxFocusIndex - 1; i-- )
        {
            index = i % this._cells.length;

            this._cells[index]._dataIndex -= this._cells.length;
            this._cells[index].setData( this._rows ? this._rows[this._cells[index]._dataIndex] : undefined );
            this.setPosition( this._cells[index], pos - (start - i + 1) * this._cellH );
        }

        this._cellOffset = dataIndex % this._cells.length;

        if( this.containsWidget( _x2._focus ) === true )
            _x2.requestFocus( this );
    };
    
    ListWidget.prototype.setH = function( val )
    {
        Widget.prototype.setH.call( this, val );

        if( this._scroll )
            this._scroll.setH( val );
    };

    ListWidget.prototype.setPosition = function( obj, val )
    {
        return ( this._axis === ListWidget.Axis.X ) ? obj.setX( val ) : obj.setY( val );
    };

    ListWidget.prototype.setW = function( val )
    {
        Widget.prototype.setW.call( this, val );

        if( this._scroll )
            this._scroll.setW( val );

        if( this._moreRight )
            this._moreRight.setX( val );
    };

    ListWidget.prototype.suppressSelection = function( bool )
    {
        this._supressSelection = bool;
    };

    ListWidget.prototype.update = function( step, alphaParent )
    {
        var intervals, delta, h, y, i, yNow, nextDataIndex, currIndex, incs, index, dataIndex, gPos, round;

        switch( this._scrollMode )
        {
            case SCROLL.BACK_STOP:
                if( this._stop === undefined && this._datum !== undefined )
                {
                    intervals = Math.floor( (step - this._datum) / this._stepTime ) + 1;
                    this._stop = this._datum + intervals * this._stepTime;
                    this._scrollMode = SCROLL.BACK;
                    this.update( step );
                }
                break;

            case SCROLL.BACK:
                if( this._datum !== undefined )
                {
                    if( (this._stop !== undefined && step > this._stop) || step >= this._maxTime )
                    {
                        if( this._stop > this._maxTime )
                            this._stop = this._maxTime;

                        step             = this._stop || this._maxTime;
                        this._blocked    = false;
                        this._scrollMode = undefined;
                        this._stop       = undefined;
                        this._maxTime    = undefined;
                    }

                    delta = (step - this._datum) * this._cellH / this._stepTime;
                    h     = this._cellH * this._cells.length;

                    if( this._stepListener )
                        this._stepListener( this._repeatIndex - (step - this._repeatDatum) / this._stepTime );

                    for( i = 0; i < this._cells.length; i++ )
                    {
                        yNow = this.getPosition( this._cells[i] );
                        y    = (delta + this._cellH * ( i + 2 )) % h - 2 * this._cellH;

                        if( yNow > y )
                        {
                            nextDataIndex = this._cells[i]._dataIndex - this._cells.length;
                            this._cells[i]._dataIndex = nextDataIndex;
                            this._cells[i].setData( (nextDataIndex >= 0) ? this._rows[nextDataIndex] : undefined );
                        }

                        this.setPosition( this._cells[i], y );

                        if( (this._cells[i]._dataIndex === 0) && (this.getPosition( this._cells[i] ) >= 0) )
                        {
                            gPos = this.getGlobalPos();

                            this.setPosition( this._container, -this.getPosition( this._cells[i] ) );

                            if( this._axis === ListWidget.Axis.X )
                                this._focusObj.x = gPos.x + this._maxFocusIndex * this._cellH - this.getPosition( this._cells[i] );
                            else
                                this._focusObj.y = gPos.y + this._maxFocusIndex * this._cellH - this.getPosition( this._cells[i] );

                            if( this._cells[i]._selectable === true && this._supressSelection !== true )
                                _x2._hi.setPosition( this._focusObj );
                        }
                    }

                    if( this._scrollMode === undefined )
                    {
                        this._datum      = undefined;
                        this._cellOffset = (this._cells.length - ((h + this.getPosition( this._cells[0] )) % h) / this._cellH) % this._cells.length;
                        round            = Math.round( this._cellOffset );

                        if( this._cellOffset !== round )
                        {
                            console.error( "CELL OFFSET IS NOT AN INTEGER" + this._cellOffset );
                            this._cellOffset = round;
                        }

                        index            = (this._cellOffset + this._cells.length - 2) % this._cells.length;
                        dataIndex        = this._cells[index]._dataIndex + this._cells.length;

                        this.setPosition( this._cells[index], (this._cells.length - 2) * this._cellH );
                        this._cells[index]._dataIndex = dataIndex;
                        this._cells[index].setData( this._rows[dataIndex] );

                        if( this._stepStopListener )
                            this._stepStopListener();

                        if( this._supressSelection !== true )
                            _x2.requestFocus( this._cells[(this._cellOffset + this._index) % this._cells.length] );
                        else
                            this._cells[(this._cellOffset + this._index) % this._cells.length].gotFocus();

                        if( this._moreRight && (this._cells[this._cellOffset]._dataIndex + this._numVis) < this._rows.length  )
                            this._moreRight.animate( { a:1, duration:500 } );

                        if( this._moreLeft && this._cells[this._cellOffset]._dataIndex <= this._maxFocusIndex )
                            this._moreLeft.animate( { a:0, duration:500 } );
                    }
                }
                else
                {
                    currIndex         = this._cells[(this._cellOffset+this._maxFocusIndex) % this._cells.length]._dataIndex;
                    incs              = currIndex + this._cells.length - this._cellOffset;
                    this._datum       = step - (this._cells.length - this._cellOffset) * this._stepTime;
                    this._repeatDatum = step;
                    this._repeatIndex = this._cells[this._cellOffset]._dataIndex;
                    this._maxTime     = this._datum + this._stepTime * incs;
                }
                break;

            case SCROLL.FWD_STOP:
                if( this._stop === undefined && this._datum !== undefined )
                {
                    intervals = Math.floor( (step - this._datum) / this._stepTime ) + 1;
                    this._stop = this._datum + intervals * this._stepTime;
                    this._scrollMode = SCROLL.FWD;
                    this.update( step );
                }
                break;

            case SCROLL.FWD:
                if( this._datum !== undefined )
                {
                    if( (this._stop !== undefined && step > this._stop) || step >= this._maxTime )
                    {
                        if( this._stop > this._maxTime )
                            this._stop = this._maxTime;

                        step             = this._stop || this._maxTime;
                        this._blocked    = false;
                        this._scrollMode = undefined;
                        this._stop       = undefined;
                        this._maxTime    = undefined;
                    }

                    delta = (step - this._datum) * this._cellH / this._stepTime;
                    h     = this._cellH * this._cells.length;

                    if( this._stepListener )
                        this._stepListener( this._repeatIndex + (step - this._repeatDatum) / this._stepTime );

                    for( i = 0; i < this._cells.length; i++ )
                    {
                        yNow = this.getPosition( this._cells[i] );
                        y    = this._cellH * (this._cells.length - 1) - (delta + this._cellH * (this._cells.length - 1 - i)) % h;

                        if( yNow < y )
                        {
                            nextDataIndex = this._cells[i]._dataIndex + this._cells.length;
                            this._cells[i]._dataIndex = nextDataIndex;
                            this._cells[i].setData( (nextDataIndex < this._rows.length) ? this._rows[nextDataIndex] : undefined );
                        }

                        this.setPosition( this._cells[i], y );

                        if( this._cells[i]._dataIndex === 0 )
                        {
                            if( this.getPosition( this._cells[i] ) >= 0 )
                            {
                                gPos = this.getGlobalPos();

                                this.setPosition( this._container, -this.getPosition( this._cells[i] ) );

                                if( this._axis === ListWidget.Axis.X )
                                    this._focusObj.x = gPos.x + this._maxFocusIndex * this._cellH - this.getPosition( this._cells[i] );
                                else
                                    this._focusObj.y = gPos.y + this._maxFocusIndex * this._cellH - this.getPosition( this._cells[i] );

                                if( this._supressSelection !== true )
                                    _x2._hi.setPosition( this._focusObj );
                            }
                            else
                                this.setPosition( this._container, 0 );
                        }
                    }

                    if( this._scrollMode === undefined )
                    {
                        this._datum      = undefined;
                        this._cellOffset = (this._cells.length - this.getPosition( this._cells[0] ) / this._cellH) % this._cells.length;
                        round            = Math.round( this._cellOffset );

                        if( this._cellOffset !== round )
                        {
                            console.error( "CELL OFFSET IS NOT AN INTEGER " + this._cellOffset );
                            this._cellOffset = round;
                        }

                        index            = (this._cells.length + this._cellOffset - 1) % this._cells.length;
                        dataIndex        = this._cells[index]._dataIndex - this._cells.length;

                        this.setPosition( this._cells[index], -this._cellH );
                        this._cells[index]._dataIndex = dataIndex;
                        this._cells[index].setData( this._rows[dataIndex] );

                        if( this._stepStopListener )
                            this._stepStopListener();

                        if( this._supressSelection !== true )
                            _x2.requestFocus( this._cells[(this._cellOffset + this._index) % this._cells.length] );
                        else
                            this._cells[(this._cellOffset + this._index) % this._cells.length].gotFocus();

                        if( this._moreRight && (dataIndex + this._maxFocusIndex + this._numVis) >= this._rows.length  )
                            this._moreRight.animate( { a:0, duration:500 } );

                        if( this._moreLeft && this._cells[this._cellOffset]._dataIndex > this._maxFocusIndex && this._moreLeft.getA() < 1 )
                            this._moreLeft.animate( { a:1, duration:500 } );
                    }
                }
                else
                {
                    currIndex         = this._cells[(this._cellOffset+this._maxFocusIndex) % this._cells.length]._dataIndex;
                    incs              = this._rows.length - 1 - currIndex;
                    this._datum       = step - this._cellOffset * this._stepTime;
                    this._repeatDatum = step;
                    this._repeatIndex = this._cells[this._cellOffset]._dataIndex;
                    this._maxTime     = step + this._stepTime * incs;
                }
                break;

            default:
                if( this._blocked === false )
                    this.processQueue();
                break;
        }

        Widget.prototype.update.call( this, step, alphaParent );
    };

    ListWidget.prototype.moveCursorToRow = function( rowIndex ) {
        var lastRowIndex = this._currentFocusIndex;
        var currentRowIndex = rowIndex;
        var totalRows = this._cells.length;
        if( currentRowIndex !== lastRowIndex ) {
            var focusToIndex = (currentRowIndex) % totalRows;
            var y = this._cells[focusToIndex].getGlobalPos().y - _x2._hi._top;
            var h = this._cells[focusToIndex].getH();
            _x2._hi.setY( y );
            _x2._hi.setH( h );
            this._cells[lastRowIndex] && this._cells[lastRowIndex].lostFocus();
            this._cells[focusToIndex].gotFocus();
        }
    }

    return ListWidget;

})();
