// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.FormField = (function()
{
    "use strict";
    
    function FormField(){}
    
    FormField.prototype.init = function( name, data )
    {
        this._name = name;
        this._data = data;  
        
        return this;
    };
    
    FormField.prototype.getName = function()
    {
        return this._name;
    };
    
    FormField.prototype.getLabel = function()
    {
        return this._data.label;
    };
    
    FormField.prototype.getValue = function()
    {
        var retval;
        
        if( this._value !== undefined )
            retval = this._value;
        else 
            retval = this._data.default;
        
        return retval;
    };

    FormField.prototype.getType = function()
    {
        return this._data.fieldType;
    };
    
    FormField.prototype.setValue = function( value )
    {
        this._value = value;
    };
    
    FormField.prototype.getOptions = function()
    {
        if( ! this._options )
        {
            var option;
            this._options = [];

            for( option in this._data.options )
            {
                if( this._data.options.hasOwnProperty( option ) )
                    this._options.push( new FormFieldOption().init(option, this._data.options[option]) );
            }
        }
        
        return this._options;
    };
    
    return FormField;
})();
