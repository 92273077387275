// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.TermsScreen = ( function()
{
    "use strict";

    TermsScreen.prototype = new Screen();

    function TermsScreen(){}

    TermsScreen.prototype.gotFocus = function()
    {
        if( this._ready === true )
            this.speak( _x2.requestFocus( this._buttons ) );
        else
            this.speak();
    };

    TermsScreen.prototype.init = function()
    {
        Screen.prototype.init.call( this, { noHeader:true, noSep:true } );
        this._className     = "TermsScreen";
        this._telemetryName = "Terms & Policies"; // TODO: "Terms & Policies" or "Terms Acceptance" ?

        var self = this, onAccept, onDecline, hPad, welcomeStr, welcomeBodyStr, titleStr, termsBodyStr, titleStyle, bodyStyle, buttonStyle, accept, decline;

        onAccept = function()
        {
            window.ll( "tagEvent" , "Terms Of Activation", { Response: "Accepted", Viewed: "Yes" } );
            _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Terms: Accepted" ) : Config.NOP();

            self._buttons.setDisabled( true );
            _x2._showTerms = false;
            _x2.sessionCreated();
        };

        onDecline = function()
        {
            window.ll( "tagEvent" , "Terms Of Activation", { Response: "Rejecting", Viewed: "Yes" } );
            _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Terms: Rejecting" ) : Config.NOP();

            
            _x2._config._host.removeAllTokenLocalStorage();
            _x2._config._host.removeLocalStorage( "XtvAuthToken" );
            _x2._config._host.removeLocalStorage( "features"     );
            _x2._data = undefined;
            _x2.authorize();
        };

        hPad           = _x2.scaleValInt( 250 );
        welcomeStr     = "Welcome to the Xfinity Stream App Beta Trial!";
        welcomeBodyStr = "If during the trial you use this device as your primary TV box (i.e., no other Xfinity TV box is on your account) you either will see a credit on your account for Customer Owned Equipment, or will not be charged separately for Xfinity equipment, depending upon the market. Otherwise, during the trial, no fees will be charged by Comcast for your using this device with your Xfinity TV service as a non-primary TV box. On the conclusion of the trial, you will be informed of any other charges that will apply and will have the opportunity to opt in." + "<br><br>By activating you acknowledge that Comcast may, at its discretion, provide complimentary access to Live TV networks for periods of time and those networks may air programming with an R or TV-MA rating.  You can always restrict access to programming using the Parental Control settings in the Xfinity Stream beta channel.";
        titleStr       = "Terms of Activation";
        termsBodyStr   = "By activating, you agree that you want to enable cloud DVR technology to access your Xfinity TV subscription on additional supported devices like computers and tablets, as well as the TV connected to your set-top DVR via Comcast’s network. If subscribed to X1 DVR with cloud technology, your acceptance also confirms that when you record programs on your set-top DVR you also want to save and store them automatically in Comcast’s network in several formats to (1) enable you to play them back (and navigate within them) on additional supported devices like computers and tablets, as well as the TV connected to your set-top DVR, and (2) optimize the video and audio quality of your viewing experience during playback.";
        titleStyle     = new Style( { font:"light"  , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), textAlign :"center", whiteSpace:"nowrap" } );
        bodyStyle      = new Style( { font:"regular", color:"#a3a3a3", fontSize:_x2.scaleValInt(30) } );
        buttonStyle    = new Style( { font:"regular", color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#000000" } );
        accept         = new StringWidget().init( { text:"Yes" , style:buttonStyle, onEnter:onAccept  } );
        decline        = new StringWidget().init( { text:"No Thanks", style:buttonStyle, onEnter:onDecline } );

        this._offset    = 0;
        this._step      = _x2.scaleValInt( 40 );
        this._stepWheel = _x2.scaleValInt( 15 );

        this._container = new Widget().init();
        this._container.setW( _x2._config._screenW - 2 * hPad );

        this._welcomeTitle = new StringWidget().init( { text:welcomeStr, style:titleStyle } );
        this._welcomeTitle.setW( this._container.getW() );
        this.addWidget( this._welcomeTitle, hPad, _x2.scaleValInt( 248 ) );

        this._welcomeBody = new TextBlockWidget().init( { text:welcomeBodyStr , style:bodyStyle, w:this._container.getW() } );
        this._container.addWidget( this._welcomeBody );

        if( _x2._config._isCdvr === true )
        {
            this._termsTitle = new StringWidget().init( { text:titleStr, style:titleStyle } );
            this._termsTitle.setW( this._container.getW() );
            this._container.addWidget( this._termsTitle );

            this._termsBody = new TextBlockWidget().init( { text:termsBodyStr , style:bodyStyle, w:this._container.getW() } );
            this._container.addWidget( this._termsBody );
        }

        this._scrollPane = new ScrollWidget().init( { widget: this._container, w: this._container.getW(), h:parseInt( _x2._config._screenW/4 )  } );
        this.addWidget( this._scrollPane, hPad, 0 );

        this._buttons = new HorizPillButtonsWidget().init( { entries:[accept,decline], spacing:_x2.scaleValInt(60) } );
        this._buttons.addReadyCallback( function() { self.layout(); } );
        this.addWidget( this._buttons );

        _x2._config.log( Config.CLASS_LOCALYTICS, 1 ) ? console.log( "Localytics: Terms of Activation screen" ) : Config.NOP();
        window.ll( "tagScreen", "Terms of Activation" ); // Localytics 2.1.1: "Terms of Activation"; NOTE: other platforms: "Terms & Policies"

        // NOTE: Can't send SCREEN_VIEWED until _x2._telemetry has been instantiated

        return this;
    };

    TermsScreen.prototype.scroll = function( step, duration )
    {
        console.log( step );

        if( step > 0 )
        {
            var max = this._scrollPane.getH() - this._container.getH();

            this._offset = this._offset - step;

            if( this._offset < max )
            {
                this._offset = max;

                if( _x2._focus !== this._buttons )
                    this.speak( _x2.requestFocus( this._buttons ), true );
            }
        }
        else
        {
            this._offset = this._offset - step;

            if( this._offset > 0 )
                this._offset = 0;
        }

        this._scrollPane.setScroll( { y:this._offset, duration:duration } );
    };

    TermsScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_DOWN:
            case Host.KEY_CH_DOWN:
            case Host.KEY_PAGE_DN:
                if( type === Host.KEY_PRESSED )
                    this.scroll( (val === Host.KEY_CH_DOWN || val === Host.KEY_PAGE_DN) ? 5000 : this._step, 300 );
                break;

            case Host.KEY_LAST:
                if( type === Host.KEY_PRESSED )
                {
                    _x2.logoutOnExit = true;
                    retval = false;
                }
                break;

            case Host.KEY_UP:
            case Host.KEY_CH_UP:
            case Host.KEY_PAGE_UP:
                if( type === Host.KEY_PRESSED )
                    this.scroll( (val === Host.KEY_CH_UP || val === Host.KEY_PAGE_UP) ? -5000 : -this._step, 300 );
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    TermsScreen.prototype.layout = function()
    {
        if( _x2._config._isCdvr === true )
        {
            this._termsTitle.setY( this._welcomeBody.getH() + _x2.scaleValInt( 50 ) );
            this._termsBody.setY( this._termsTitle.getY() + this._termsTitle.getH() + _x2.scaleValInt( 40 ) );
            this._container.setH( this._termsBody.getY() + this._termsBody.getH() );
        }
        else
            this._container.setH( this._welcomeBody.getY() + this._welcomeBody.getH() );

        this._buttons.setX( (_x2._config._screenW - this._buttons.getW()) / 2 );
        this._buttons.setY( _x2._config._screenH - _x2.scaleValInt( 231 ) - this._buttons.getH() );
        this._scrollPane.setY( this._welcomeTitle.getY() + this._welcomeTitle.getH() + _x2.scaleValInt( 40 ) );

        var self = this;
        var h1   = this._buttons.getY  () - this._scrollPane.getY() - _x2.scaleValInt( 50 );
        var h2   = this._container.getH();
        var h    = h1 < h2 ? h1 : h2;

        this._scrollPane.setH( h );

        if( this._container.getH() <= this._scrollPane.getH() && _x2._focus === this )
        {
            this.speak( _x2.requestFocus( this._buttons ) );
            this._ready = true;
        }

        this.setMouseWheelListener( function( event ) { self.scroll( event.deltaY > 0 ? self._stepWheel : -self._stepWheel ); } );
    };

    TermsScreen.prototype.speak = function( focus, skipTerms )
    {
        var str = this._welcomeTitle.getText() + ". " + this._welcomeBody.getText();

        if( this._termsTitle )
            str += this._termsTitle.getText() + ". " + this._termsBody.getText();

        str = str.replace( /xfinity/gi, "x finity" );

        if( focus )
        {
            this._speakTarget.setText( (skipTerms === true ? "" : str) + focus._speakStr + ". " );
            this._speakTarget.setSpeechParams( "Press right or left to review options. Press OK to select. ", focus._speakRole, this, false );
        }
        else
        {
            this._speakTarget.setText( str );
            this._speakTarget.setSpeechParams( "Press down to continue reading. ", undefined, this, false );
        }
    };

    return TermsScreen;

})();
