/**
 * @class
 */
window.Rental = (function()
{
    Rental.prototype = new VodShowing();

    function Rental(){}

    Rental.prototype.init = function( data )
    {
        VodShowing.prototype.init.call( this, data );
        return this;
    };

    Rental.prototype.getExpires = function()
    {
        return new Date(this.getRentedOn().getTime() + ( this.getRentalPeriod() * 60000 ) );
    };

    Rental.prototype.getPrice = function()
    {
        return this.get("price");
    };

    Rental.prototype.getRentalPeriod = function()
    {
        return this.get("rentalPeriod");
    };

    Rental.prototype.getRentedOn = function()
    {
        return this.getDate("rentalDateTime");
    };

    Rental.prototype.isWatchable = function()
    {
        return ( Date.now() < this.getExpires().getTime() );
    };


    return Rental;
})();
