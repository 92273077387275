// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.FilterChannelsOverlay = ( function()
{
    "use strict";

    FilterChannelsOverlay.prototype = new Overlay();

    FilterChannelsOverlay.FilterStrs = Object.freeze
    ({
        hd  : "HD",
        cc  : "CC",
        sap : "SAP",
        dvs : "VDS"
    });

    function FilterChannelsOverlay(){}

    FilterChannelsOverlay.prototype.createTitle = function()
    {
        var str, result = "Filtered by: <font color='#2ea0dd'>", first = true;

        this._str = "Filtered by: ";

        for( var i = 0; i < this._rows.length; i++ )
        {
            var key = this._rows[i].key;

            if( this._rows[i].selected === true )
            {
                str = FilterChannelsOverlay.FilterStrs[key];

                if( str )
                {
                    if( first !== true )
                    {
                        result    += ", ";
                        this._str += ", ";
                    }
                    else
                        first = false;

                    result += str;

                    this._str += this._rows[i].title;
                }
            }
        }

        this._str += ". ";
        this._title.setText( result + "</font>" );
    };

    FilterChannelsOverlay.prototype.init = function( params )
    {
        Overlay.prototype.init.call( this );
        this._className = "FilterChannelsOverlay";

        var self       = this;
        var listParams = {};
        var filters    = _x2._settings.getValue( params.which );
        var pathStyle  = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap" } );
        var titleStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap" } );
        var hPad       = _x2.scaleValInt(78);
        var vPad       = _x2.scaleValInt(80);

        var layout     = function()
        {
            self._numReady++;

            if( self._numReady === 2 )
            {
                self._title.setY( self._path.getY() + self._path.getH() + 5 * Style._pad );

                var y = self._title.getY() + self._title.getH() + _x2.scaleValInt(46);

                self._sep.setY( y );
                self._list.setY( y );
                self._buttons.setY( y + self._list.getH() + 5 * Style._pad );
                self._buttons.setX( (self._bg.getW() - self._buttons.getW()) / 2 );
                self._ready = true;

                self.setBgSize( self._bg.getW(), self._buttons.getY() + self._buttons.getH() + 6 * Style._pad );

                if( _x2._focus === self )
                    self.speak( _x2.requestFocus( self._list ) );
            }
        };

        var save = function()
        {
            var saved   = _x2._settings.getValue( params.which );
            var current = self._rows;
            var changed = false;

            for( var i = 0; i < current.length; i++ )
            {
                var key = current[i].key;

                if( current[i].selected !== saved[key] )
                {
                    changed    = true;
                    saved[key] = current[i].selected;

                    if( current[i].selected )
                        window.ll( "tagEvent", "Filtered", { Type: current[i].localytics } );
                }
            }

            if( changed === true )
            {
                _x2._settings.setValue( params.which, saved );
                params.screen.filtersChanged();
            }
            else
                cancel();
        };

        var cancel = function()
        {
            _x2.popOverlay( self );
        };

        this._numReady = 0;
        this._rows     =
        [
            { title:"High Definition"        , selected:filters.hd , key:"hd" , glyph:Strings.Glyphs.HD , localytics: "HD"  },
            { title:"Closed Captioning"      , selected:filters.cc , key:"cc" , glyph:Strings.Glyphs.CC , localytics: "CC"  },
            { title:"Secondary Audio Program", selected:filters.sap, key:"sap", glyph:Strings.Glyphs.SAP, localytics: "SAP" },
            { title:"Video Description"      , selected:filters.dvs, key:"dvs", glyph:Strings.Glyphs.DV , localytics: "DVS" }
        ];

        this._path = new StringWidget().init( { text:"Options", style:pathStyle } );
        this._bg.addWidget( this._path, hPad, vPad );

        this._title = new StringWidget().init( { style:titleStyle } );
        this._title.addReadyCallback( layout );
        this._bg.addWidget( this._title, hPad, vPad );

        this._sep = new RectWidget().init( { w:this._bg.getW() - 2 * hPad, h:ListRowWidget.DIVIDER_H, color:"#303030" } );
        this._bg.addWidget( this._sep, hPad );

        listParams.w        = this._bg.getW() - 2 * hPad;
        listParams.h        = 4 * new CheckBoxRowWidget().getHeightUnfocused();
        listParams.maxIndex = 3;
        listParams.rows     = this._rows;
        listParams.type     = CheckBoxRowWidget;

        this._list = new ListWidget().init( listParams );
        this._bg.addWidget( this._list, hPad );

        var buttonStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000"  } );
        var confirmStr  = new StringWidget().init( { text:"Confirm", style:buttonStyle, onEnter:save   } );
        var cancelStr   = new StringWidget().init( { text:"Cancel" , style:buttonStyle, onEnter:cancel } );

        this._buttons = new HorizPillButtonsWidget().init( { entries:[confirmStr,cancelStr], spacing:_x2.scaleValInt(41) } );
        this._buttons.addReadyCallback( layout );
        this._bg.addWidget( this._buttons );

        this.createTitle();

        return this;
    };

    FilterChannelsOverlay.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED && _x2._focus !== this._buttons )
                    _x2.requestFocus( this._buttons, false );
                break;

            case Host.KEY_ENTER:
                if( type === Host.KEY_RELEASED )
                    this.createTitle();
                break;

            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED && _x2._focus !== this._list )
                    _x2.requestFocus( this._list, false );
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    FilterChannelsOverlay.prototype.speak = function( focus )
    {
        this._speakTarget.setText( this._str + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press up or down to review options. Pres OK to select. Press back to dismiss. ", focus._speakRole, this, false );
    };

    return FilterChannelsOverlay;

})();
