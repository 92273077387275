// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.StringEllipsisTestScreen = ( function()
{
    "use strict";

    StringEllipsisTestScreen.prototype = new Screen();

    function StringEllipsisTestScreen(){}

    StringEllipsisTestScreen.prototype.init = function()
    {
        var self = this;

        Screen.prototype.init.call( this );
        this._className = "StringEllipsisTestScreen";

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        var w         = _x2._config._screenW*0.5,
            h         = _x2._config._screenH,
            fontSize  = 36,
            makeStyle = function( params )
            {
                var style = { color:"#e5e5e5", font:"light", fontSize:fontSize, overflow:"hidden", textAlign:"left", textOverflow:"ellipsis", whiteSpace: "nowrap" };
                if( params && params.color )
                    style.color = params.color;

                if( params && params.textAlign )
                    style.textAlign = params.textAlign;

                return new Style( style );
            },
            wrap      = "Spider-Man fights a new villain called Electro, and romances his high-school sweetheart Gwen Stacy. Meanwhile, his childhood friend Harry Osborn is named the CEO of Oscorp, a nefarious company connected to the disappearance of Peter Parker's parents.";

        this._divider = new RectWidget().init( { color:"#777777", w: 1, h:h } );
        this._edge    = new RectWidget().init( { color:"#8000FF", w: 1, h:1.5*3*fontSize } );
        this._header1 = new StringWidget().init( { text: "Width = 1/2 Screen"   , style:makeStyle( { color:"#0AF"       } ) } );
        this._header2 = new StringWidget().init( { text: "Width = undefined "   , style:makeStyle( { color:"#0AF"       } ) } );
        this._left    = new StringWidget().init( { text:wrap                    , style:makeStyle( { textAlign:"left"   } ) } );
        this._center  = new StringWidget().init( { text:wrap                    , style:makeStyle( { textAlign:"center" } ) } );
        this._right   = new StringWidget().init( { text:wrap                    , style:makeStyle( { textAlign:"right"  } ) } );
        this._center2 = new StringWidget().init( { text:wrap                    , style:makeStyle(                        ) } );
        this._center3 = new StringWidget().init( { text:"Ellipsis layout:width" , style:makeStyle(                        ) } );
        this._width   = w;

        this._left  .setW( w );
        this._center.setW( w );
        this._right .setW( w );
/*
        this._left  .setH( fontSize );
        this._center.setH( fontSize );
        this._right .setH( fontSize );
*/
        // center2 does NOT have a width specified
        this._center3.addReadyCallback( function() { self.layout(); } );

        var x = 0; // Style._safeLeft;
        var y = Style._safeTop;
        var t = y + 1.5*fontSize;

        this.addWidget( this._header1, x, y ); y += 1.5*fontSize;
        this.addWidget( this._left   , x, y ); y += 1.5*fontSize;
        this.addWidget( this._center , x, y ); y += 1.5*fontSize;
        this.addWidget( this._right  , x, y ); y += 2  *fontSize;

        this.addWidget( this._header2, w, y ); y += 1.5*fontSize;
        this.addWidget( this._center2, w, y ); y += 1.5*fontSize;
        this.addWidget( this._center3, w, y );

        this.addWidget( this._divider, w, 0 );
        this.addWidget( this._edge   , w, t );

// Add Bounding Box Rects ?

        return this;
    };

    StringEllipsisTestScreen.prototype.layout = function()
    {
        this._center3.setW( _x2._config._screenW * 0.5 );

        this.updateWidth( 0 );
    };


    StringEllipsisTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                    this.updateWidth( -1 );

                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                    this.updateWidth( +1 );

                break;

            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                    this.updateWidth( -10 );

                break;

            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED )
                    this.updateWidth( +10 );

                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
        }

        return retval;
    };

    StringEllipsisTestScreen.prototype.updateWidth = function( deltaWidth )
    {
        this._width += deltaWidth;
        if( this._width < 0 )
            this._width = 0;

        if( this._width > _x2._config._screenW * 0.5 )
            this._width = _x2._config._screenW * 0.5;

        this._left  .setW( this._width );
        this._center.setW( this._width );
        this._right .setW( this._width );

        this._edge.setX( this._width );

        this._header1.setText( "Width = 1/2 Screen ( <font color='#FFFF00'>" + this._width + "</font> )" );
    };

    return StringEllipsisTestScreen;

})();
