// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.DevicesRowWidget = ( function()
{
    "use strict";

    DevicesRowWidget.prototype = new ListRowWidget();

    function DevicesRowWidget(){}

    DevicesRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 122 * _x2._config._text );
    };

    DevicesRowWidget.prototype.gotFocus = function()
    {
        if( this._data )
        {
            ListRowWidget.prototype.gotFocus.call( this );
            this._title.gotFocus();
        }
        else
            this._obj.onRowLostFocus();
    };

    /**
     * Initializer
     * @memberof DevicesRowWidget
     * @param   {Object}    params
     * @param   {Number|undefined}   [params.focusTime=0] - TODO
     * @param   {Object}              params.obj          - Object that manages the lists contents
     * @param   {Function}           [params.onEnter]     - Function to execute on selection
     * @param   {String}             [params.title]       - Title to display in the cell
     * @param   {String}             [params.subTitle]    - Sub-title to display in the cell
     * @param   {Number}              params.w            - width of cell
     * @returns {DevicesRowWidget}
     * @see ListRowWidget
     */
    DevicesRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );
        this._className  = "DevicesRowWidget";
        this._selectable = true;

        var self        = this;
        var fontSize    = 42;
        var titleStyle  = new Style( { color:"#e8e8e8", font:"light", fontSize:_x2.scaleValInt(       fontSize), whiteSpace:"nowrap", colorHi:"#2ea0dd" } );
        var valueStyle  = new Style( { color:"#a3a3a3", font:"light", fontSize:_x2.scaleValInt(0.74 * fontSize), whiteSpace:"nowrap" } );
        var removeStyle = new Style( { color:"#e8e8e8", font:"light", fontSize:_x2.scaleValInt(0.74 * fontSize), whiteSpace:"nowrap" } );

        this._obj       = params.obj;
        this._onEnter   = params.onEnter;
        this._focusTime = ( params.focusTime !== undefined ) ? params.focusTime : 0;

        this._title = new StringWidget().init( { text:params.title, style:titleStyle } );
        this.addWidget( this._title );

        this._subTitle = new StringWidget().init( { text:params.subTitle, style:valueStyle } );
        this._subTitle.addReadyCallback( function() { self.layout(); } );
        this.addWidget( this._subTitle );

        this._image = new NineSliceWidget().init( { url:_x2._config._imageRoot + [ "", "", "remove_20_20_20_20.png", "remove_30_30_30_30.png" ][_x2._config._layout] } );
        this.addWidget( this._image );

        this._remove = new StringWidget().init( { text:"Remove", style:removeStyle } );
        this.addWidget( this._remove );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        return this;
    };

    DevicesRowWidget.prototype.layout = function()
    {
        var pad;

        if( this._title.getText() === undefined )  // make sure string widget has a valid height
            this._title.setText( "????" );

        if( this._subTitle.getText() === undefined )  // make sure string widget has a valid height
            this._subTitle.setText( "????" );

        pad = (this.getHeightUnfocused() - ListRowWidget.DIVIDER_H - this._title.getH() - this._subTitle.getH()) / 2;

        this._title.setY( pad );
        this._subTitle.setY( this._title.getY() + this._title.getH() );

        this._image.setW( this._remove.getW() );
        this._image.setX( this.getW() - this._image.getW() );
        this._image.setY( (this.getH() - this._image.getH() - ListRowWidget.DIVIDER_H) / 2 );

        this._remove.setX( this._image.getX() + (this._image.getW() - this._remove.getW()) / 2 );
        this._remove.setY( this._image.getY() + (this._image.getH() - this._remove.getH()) / 2 );
    };

    DevicesRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._title.lostFocus();
    };

    DevicesRowWidget.prototype.processEvent = function( val, type )
    {
        var retval;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    retval = true;

                    if( this._onEnter )
                        this._onEnter( this );
                }
                break;
        }

        if( retval === undefined )
            retval = ListRowWidget.prototype.processEvent.call( this, val, type );

        return retval;
    };

    /**
     * @memberof DevicesRowWidget
     * @param {Object}   [data]
     * @param {Function}  data.onEnter   - Callback
     * @param {String}   [data.title]    - Title to display in the cell
     * @param {String}   [data.subTitle] - Sub-title to display in the cell
     */
    DevicesRowWidget.prototype.setData = function( data )
    {
        this._data = data;

        if( data !== undefined )
        {
            this._title.setText   ( data.title    );
            this._subTitle.setText( data.subTitle );
            this._onEnter = data.onEnter;

            if( this._onEnter )
            {
                this._remove.setA( 1 );
                this._image.setA ( 1 );
            }
            else
            {
                this._remove.setA( 0 );
                this._image.setA ( 0 );
            }

            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    return DevicesRowWidget;

})();
