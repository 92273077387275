// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.TextEntryWidget = ( function()
{
    "use strict";

    TextEntryWidget.prototype = new Widget();

    function TextEntryWidget(){}

    TextEntryWidget.prototype.getInstText = function()
    {
        var retval = "";

        if( this._instStr )
            retval = this._instStr.getText();

        return retval;
    };

    TextEntryWidget.prototype.getText = function()
    {
        return this._str.getText();
    };

    /**
     * Initializer
     * @memberof TextEntryWidget
     * @param   {Object}    params
     * @param   {String}   [params.instStr]  - Instructional text to display when no text has been entered
     * @param   {Number}    params.w         - Width
     * @param   {Function} [params.onChange] - Called on every change caused by onChar
     * @returns {TextEntryWidget}
     */
    TextEntryWidget.prototype.init = function( params )
    {
        var self = this;
        var charsStyle = new Style( { font:"light", color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"pre" } );

        Widget.prototype.init.call( this );
        this._className = "TextEntryWidget";

        if( params.onChange )
            this._onChange = params.onChange;

        this._rect = new RectWidget().init( { w:params.w, h:50, color:"#262626" } );
        this.addWidget( this._rect );

        this._str = new StringWidget().init( { text:"???????????", style:charsStyle } );
        this._str.addReadyCallback( function() { self.layoutTextEntryWidget(); } );
        this._str.setW( params.w - _x2.scaleValInt(16) );
        this._str.setA( 0 );
        this.addWidget( this._str, _x2.scaleValInt(16), _x2.scaleValInt(18) );

        if( params.instStr )
        {
            this._instStr = new StringWidget().init( { text:params.instStr, style:new Style( { font:"light", color:"#a3a3a3", fontSize:_x2.scaleValInt(32) } ) } );
            this.addWidget( this._instStr, this._str.getX(), this._str.getY() );
        }

        this.setW( params.w );

        return this;
    };

    TextEntryWidget.prototype.layoutTextEntryWidget = function()
    {
        this._rect.setH( 2 * _x2.scaleValInt(18) + this._str.getH() );
        this.setH( this._rect.getH() );
        this._str.setText();
        this._str.setA( 1 );

        this.callbackSignalReady();
    };

    TextEntryWidget.prototype.onChar = function( char )
    {
        var str    = this._str.getText();
        var strLen = str.length;

        switch( char )
        {
            case "del":
                this._str.setText( str.substr( 0, str.length - 1 ) );
                break;

            case "sp":
                this._str.setText( this._str.getText() + " " );
                break;

            case "tr":
                this._str.setText();
                break;

            default:
                this._str.setText( this._str.getText() + char );
                break;
        }

        str = this._str.getText();

        if( this._instStr )
        {
            if( strLen > 0 && str.length === 0 )
                this._instStr.animate( { a:1, duration:500 } );
            else if( strLen === 0 && str.length > 0 )
                this._instStr.animate( { a:0, duration:500 } );
        }

        if( this._onChange )
            this._onChange( this._str.getText() );
    };

    TextEntryWidget.prototype.setW = function( val )
    {
        Widget.prototype.setW.call( this, val );
        this._rect.setW( val );
        this._str.setW( val - 2 * _x2.scaleValInt(16) );
    };

    return TextEntryWidget;

})();
