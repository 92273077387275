// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.MenuRowWidget = ( function()
{
    "use strict";

    MenuRowWidget.prototype = new ListRowWidget();

    function MenuRowWidget(){}

    MenuRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 114 * _x2._config._text );
    };

    MenuRowWidget.prototype.getTitle = function()
    {
        return this._title.getText();
    };

    MenuRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        ListRowWidget.prototype.gotFocus.call( this );
        this._title.gotFocus();
        this._subIcon.gotFocus();

        var str = (this._data && this._data.showIcon === true && newScreen !== true) ? "Press OK for options. " : "";

        if( this._data && this._data.value )
            str = this._data.value + ". " + str;
        this._parent._parent._parent._currentFocusIndex = this._currentRowIndex;
        this._title.setSpeechParams( str, undefined, this, newScreen );

        return this;
    };

    /**
     * Initializer
     * @memberof MenuRowWidget
     * @param   {Object}    params
     * @param   {Number}   [params.focusTime=0] - Time to animate the focus action
     * @param   {Function} [params.onEnter]     - Function to execute on selection
     * @param   {Boolean}  [params.showIcon]    - Show the sub-menu icon
     * @param   {String}    params.title        - Title of the row
     * @param   {String}   [params.value]       - Value to show next to the title
     * @param   {Number}    params.w            - Width of the row
     * @returns {MenuRowWidget}
     * @see ListRowWidget
     */
    MenuRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );
        this._className  = "MenuRowWidget";
        this._selectable = true;

        var self       = this;
        var titleStyle = new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap", colorHi:"#2ea0dd" } );
        var valueStyle = new Style( { color:"#2ea0dd", font:"light", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap" } );

        this._numElements = 0;
        this._onEnter     = params.onEnter;
        this._focusTime   = ( params.focusTime !== undefined ) ? params.focusTime : 0;

        this._title = new StringWidget().init( { text:params.title, style:titleStyle } );
        this._title.addReadyCallback( function() { self._numElements++; self.layout(); } );
        this.addWidget( this._title );

        this._value = new StringWidget().init( { text:params.value, style:valueStyle } );
        this.addWidget( this._value );

        this._subIcon = new DualImageWidget().init( { url:_x2._config._imageRoot + "menuRowSub.png", urlHi:_x2._config._imageRoot + "menuRowSubHi.png" } );
        this._subIcon.addReadyCallback( function() { self._numElements++; self.layout(); } );
        this.addWidget( this._subIcon );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        this._currentRowIndex = params.rowIndex;

        return this;
    };

    MenuRowWidget.prototype.layout = function()
    {
        if( this._numElements === 2 )
        {
            this._subIcon.setX( this.getW() - this._subIcon.getW() );
            this._subIcon.setY( (this.getHeightUnfocused() - this.getSepH() - this._subIcon.getH()) / 2 );

            if( this._title.getText() === undefined )  // make sure string widget has a valid height
                this._title.setText( "????" );

            this._titleBaseY = (this.getHeightUnfocused() - this._title.getH()) / 2;
            this._title.setY( this._titleBaseY );
            this._value.setX( this._title.getX() + this._title.getW() + Style._pad );
            this._value.setY( this._titleBaseY );
        }
    };

    MenuRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._title.lostFocus();
        this._subIcon.lostFocus();
        this._parent._parent._parent._lastFocusIndex = this._currentRowIndex;
    };

    MenuRowWidget.prototype.processEvent = function( val, type )
    {
        var retval;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED && this._onEnter )
                {
                    retval = true;
                    this._onEnter( this );
                }
                break;
        }

        if( retval === undefined )
            retval = ListRowWidget.prototype.processEvent.call( this, val, type );

        return retval;
    };

    MenuRowWidget.prototype.refreshCell = function()
    {
        if( this._data && this._data.value !== this._value.getText() ) {
            this._value.setText( this._data.value );
        }
    };

    /**
     * @memberof MenuRowWidget
     * @param {Object}   [data]
     * @param {Function}  data.onEnter   - callback
     * @param {Boolean}  [data.showIcon] -
     * @param {String}    data.title     - Row title
     * @param {Object}   [data.value]    - Value to show next to the title
     */
    MenuRowWidget.prototype.setData = function( data )
    {
        var self = this;
        this._data = data;

        if( data !== undefined )
        {
            this._title.setText( data.title );
            this._onEnter = data.onEnter;

            if( data.showIcon === true )
                this._subIcon.setA( 1 );
            else
                this._subIcon.setA( 0 );

            if( data.value !== undefined )
            {
                this._value.setText( data.value );
                this._value.setX( this._title.getX() + this._title.getW() + Style._pad );
                this._value.setA( 1 );
            }
            else
                this._value.setA( 0 );

            this.setA( 1 );
            this.setMouseDownListener( function() { self._onEnter( self ); } );
            this.setMouseEnterListener( function() { 
                this._parent._parent._parent.moveCursorToRow( this._currentRowIndex );
             } );
        }
        else
        {
            this.setA( 0 );
            this.enableMouseDownListener( false );
        }
    };

    MenuRowWidget.prototype.setValue = function( str )
    {
        if( this._data )
        {
            this._data.value = str;
            this._value.setText( str );
        }
    };

    return MenuRowWidget;

})();
