// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.RecordingOptionsOverlay = ( function()
{
    "use strict";

    RecordingOptionsOverlay.prototype = new Overlay();

    function RecordingOptionsOverlay(){}

    RecordingOptionsOverlay.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._buttons, true );
        this._speakTarget.setText( "Record Options. " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press right or left to review options. Press OK to select. Press down to review recording options. Press back to dismiss.", focus._speakRole, this, false );
    };

    /**
     * Initialize RecordingOptionsOverlay
     * @param params
     * @param params.entity   {Entity}   - the entity
     * @param params.form     {Form}     - the recording form to use where
     * @param params.callback {function} - callback function to call after updating recording
     */
    RecordingOptionsOverlay.prototype.init = function( params )
    {
        Overlay.prototype.init.call( this );
        this._className = "RecordingOptionsOverlay";
        this._rows = [];

        var self = this,
            title,
            thumbUrl,
            thumbFallbackUrl,
            entity;

        if( params.entity.getType() === XtvApi.EntityType.EPISODE )
            entity = params.entity.getSeries();

        if( entity === undefined )
            entity = params.entity;

        title            = entity.getTitle();
        thumbUrl         = entity.getImageLink(303,404);
        thumbFallbackUrl = entity.getFallbackImageLink(303,404);

        var onOptionChange = function( obj, form )
        {
            form.setFieldValue( obj.f.getName(), obj.o.getName() )
        };

        var onConfirm = function( form )
        {
            self._distractor.show();
            params.callback( form, self );
        };

        var onPosterError = function()
        {
            if( thumbFallbackUrl )
            {
                if( posterImage.getUrl() === thumbFallbackUrl )
                    posterImage.setA(0);
                else
                    posterImage.setUrl(thumbFallbackUrl)
            }
            else
                posterImage.setA(0);
        };

        var onPosterLoaded = function()
        {
            posterImage.setA(1);
        };

        var form   = params.form;
        var fields = form.getFields();

        var titleStyle     = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
        var subTitleStyle  = new Style( { font:"light" , color:"#a3a3a3", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", textAlign:"center" } );
        var buttonStyle    = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#000000"  } );

        this._titleString  = new StringWidget().init({text:title, style:titleStyle});
        var subTitleString = new StringWidget().init({text:"Record Options", style:subTitleStyle});
        var posterImage    = new ImageWidget().init({url:thumbUrl, w:_x2.scaleValInt(303), h:_x2.scaleValInt(404), onError:onPosterError, onLoad:onPosterLoaded });

        var btnConfirm     = new StringWidget().init( { text:"Confirm", style:buttonStyle, onEnter:function(){ onConfirm(form) } } );
        var btnClose       = new StringWidget().init( { text:"Close" , style:buttonStyle, onEnter:function() { _x2.popOverlay(); } } );
        this._buttons      = new HorizPillButtonsWidget().init( { entries:[btnConfirm,btnClose], spacing:_x2.scaleValInt(41) } );

        posterImage.setA( 0 );

        this._bg.addWidget(this._titleString, Style._safeLeft, Style._safeTop );
        this._bg.addWidget(subTitleString, Style._safeLeft, Style._safeTop + _x2.scaleValInt(60) );
        this._bg.addWidget(posterImage   , Style._safeLeft, Style._safeTop + _x2.scaleValInt(120) );
        this._bg.addWidget(this._buttons , _x2.scaleValInt(500), Style._safeTop );

        this._buttons.addReadyCallback(function(){self.layout()});

        //configure and add the recording options UI
        var label, opt, opts, keys, val;

        var w0 = _x2.scaleValInt(450);
        var h0 = _x2.scaleValInt(90);
        var x1 = _x2.scaleValInt(303) + Style._safeLeft + _x2.scaleValInt(52);
        var x0 = this._bg.getW() - w0 - Style._safeLeft;
        var w1 = this._bg.getW() - x1 - Style._safeRight;
        var y0 = Style._safeTop + _x2.scaleValInt(120);
        var labelCenter = h0/2 - _x2.scaleValInt(30)/2;

        this._fields = [];
        this._fieldIdx = -1;

        //for each option, add a new HorizSelectionWidget with form field options as entries
        for( var i=0; i<fields.length; i++ )
        {
            if( fields[i].getType() !== 'HIDDEN' )
            {
                keys = [];
                opts = fields[i].getOptions();
                val  = fields[i].getValue();

                for( var q=0; q<opts.length; q++ )
                    keys.push( { obj:{ f:fields[i], o:opts[q] }, key:opts[q].getName(), label:opts[q].getLabel() } )

                label = new StringWidget().init( { style:subTitleStyle, text:fields[i].getLabel() } );
                opt   = new HorizSelectionWidget().init( { width:w0, height:h0, entries:keys, defaultValue:val, onChange:function(obj){ onOptionChange(obj, form) } } );

                this._rows.push( label );
                this._fields.push(opt);

                this._bg.addWidget( new RectWidget().init({w:w1, h:1, color:'#363636'}), x1, y0 );
                this._bg.addWidget( label, x1, y0 + labelCenter  );
                this._bg.addWidget( opt  , x0, y0 );

                y0 += h0;
            }
        }

        this._bg.addWidget(new RectWidget().init({w:w1, h:1, color:'#363636'}), x1, y0);

        //enlarge and recenter overlay _bg in case the options exceed the bounds of _bg
        if( y0 > ( this._bg.getH() - Style._safeBottom ) )
        {
            var offsetHeight = ( y0 - this._bg.getH() + Style._safeBottom ) / 2;
            this._bg.setH( y0 + Style._safeBottom);
            this._bg.setY( this._bg.getY() - offsetHeight);
        }

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget( self ) } );
        this.addWidget(this._distractor);

        return this;
    };

    RecordingOptionsOverlay.prototype.layout = function()
    {
        var buttonX = this._bg.getW() - this._buttons.getW() - Style._safeRight;
        var titleW  = buttonX - Style._safeLeft - Style._pad;

        this._buttons.setX( buttonX );
        this._titleString.setW( titleW );
    };

    RecordingOptionsOverlay.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._fieldIdx >= 0 )
                        this._fieldIdx--;

                    if( this._fieldIdx < 0 )
                        _x2.requestFocus( this._buttons );
                    else
                        this.speakFields( _x2.requestFocus( this._fields[this._fieldIdx] ), this._fieldIdx );
                }
                break;

            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._fieldIdx < this._fields.length - 1)
                    {
                        this._fieldIdx++;
                        this.speakFields( _x2.requestFocus( this._fields[this._fieldIdx], true ), this._fieldIdx );
                    }
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    RecordingOptionsOverlay.prototype.speakFields = function( focus, index )
    {
        this._speakTarget.setText( this._rows[index].getText() + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press left or right to review items. ", focus._speakRole, this, false );
    };

    return RecordingOptionsOverlay;

})();
