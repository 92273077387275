// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.RecorderSummary = (function()
{
    "use strict";

    RecorderSummary.prototype = new DataObject();

    function RecorderSummary(){}

    /**
     * Initialize this object
     * @param {Object} data - XTV derived JSON object
     * @memberOf RecorderSummary
     * @return {RecorderSummary}
     */
    RecorderSummary.prototype.init = function( data )
    {
        DataObject.prototype.init.call( this, data );
        return this;
    };

    /**
     * Get the percent used
     * @memberOf RecorderSummary
     * @return {number}
     */
    RecorderSummary.prototype.getPercentUsed = function()
    {
        return this.get("percentUsed");
    };

    /**
     * Get the number of programs recorded
     * @memberOf RecorderSummary
     * @return {number}
     */
    RecorderSummary.prototype.getTotalRecorded = function()
    {
        return this.get("totalRecorded");
    };

    /**
     * Get number of recordings scheduled
     * @memberOf RecorderSummary
     * @return {number}
     */
    RecorderSummary.prototype.getTotalScheduled = function()
    {
        return this.get("totalScheduled");
    };

    return RecorderSummary;
})();
