// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ErrorOverlay = ( function()
{
    "use strict";

    ErrorOverlay.prototype = new Overlay();

    function ErrorOverlay(){}

    /**
     * Initializer
     * @memberof ErrorOverlay
     * @param   {Object} params
     * @param   {ApiError} [params.error]  - Error object that triggered the error.
     * @param   {string}   [params.reason] - Display string instead of error object
     * @param   {string}   [params.context] - Where this error originated
     * @returns {ErrorOverlay}
     */

    ErrorOverlay.Context = {
        COMMON        : 'common',
        LINEAR        : 'linear',
        DVR           : 'dvr',
        VOD           : 'vod',
        GUIDE         : 'guide',
        BROWSE_SEARCH : 'browse_search',
        ENTITY        : 'entity',
        SAVED         : 'saved',
        TRANSACT      : 'transact'
    };

    /* Three dimensions to the error: (1) where you are in the app (context), (2) what API was called and (3) http response from that call.
       There are three error formats that we need to map:
       (1) flash major.minor       i.e. '3329.501'
       (2) api.http_response       i.e. 'updateResumePoint.503'
       (3) xtvapi status.subStatus i.e. '403.111'
       Mappings base on classes of errors defined here:
       * https://etwiki.sys.comcast.net/display/AAE/Error+Format
       Player Platform Errors documented here:
       * http://release.player.xcal.tv/js/latest/docs/#/errors

       The first mapped value is the error code, that second is the mapping for the error messages.
    */
    ErrorOverlay.errorMatrix = Object.freeze({
        'common' : {
            'DEFAULT'                              :  100,
            '3329.401'                             : [107, 'ERR_19'],
            '3329.500'                             : [102, 'ERR_19'],
            '3329.501'                             : [103, 'ERR_19'],
            '3329.502'                             : [104, 'ERR_19'],
            '3329.503'                             : [105, 'ERR_19'],
            '3329.504'                             : [106, 'ERR_19'],
            '3329.12005'                           : [108, 'ERR_14'],
            '3329.12007'                           : [101, 'ERR_15'],
            '412.401'                              : [107, 'ERR_19'],
            '412.500'                              : [102, 'ERR_19'],
            '412.501'                              : [103, 'ERR_19'],
            '412.502'                              : [104, 'ERR_19'],
            '412.503'                              : [105, 'ERR_19'],
            '412.504'                              : [106, 'ERR_19'],
            '412.12005'                            : [108, 'ERR_14'],
            '412.12007'                            : [101, 'ERR_15'],
            '401.101'                              :  120,
            '403.108'                              :  111,
            '403.112'                              :  110,
            '403.203'                              :  113,
            'createDrmSession.500'                 :  113,
            'createDrmSession.503'                 :  113,
            'provisionAndCreateSession.500'        :  114,
            'acceptTOS.500'                        :  118,
            'inHomeCheck.503'                      :  119,
            'getSamlToken.404'                     :  121,
            'acceptTOS.503'                        :  122,
            'getResumePoints.503'                  :  123,
            'getDeviceList.503'                    :  130,
            'getDeviceList.500'                    :  130,
            'getAllDevicesList.503'                :  131,
            'deleteDevice.500'                     :  132,
            'deleteDevice.503'                     :  132,
            'getParentalControlsNetworkMap.500'    : [135, 'ERR_6'],
            'getParentalControlsNetworkMap.503'    : [135, 'ERR_6'],
            'getParentalControls2Settings.500'     : [136, 'ERR_6'],
            'getParentalControls2Settings.503'     : [136, 'ERR_6'],
            'getParentalControls2Descriptions.503' : [137, 'ERR_6'],
            'updateParentalControls2Settings.500'  : [138, 'ERR_5'],
            'updateParentalControls2Settings.503'  : [138, 'ERR_5']
        },
        'linear' : {
            'DEFAULT'                          : [200, 'ERR_19'],
            '3329.12011'                       : [202, 'ERR_18'],
            '3329.12012'                       : [203, 'ERR_16'],
            '3329.12017'                       : [204, 'ERR_17'],
            '3329.12400'                       : [205, 'ERR_19'],
            '3329.12000'                       : [209, 'ERR_19'],
            '412.12011'                        : [202, 'ERR_18'],
            '412.12012'                        : [203, 'ERR_16'],
            '412.12017'                        : [204, 'ERR_17'],
            '412.12400'                        : [205, 'ERR_19'],
            '412.12000'                        : [209, 'ERR_19'],
            // Player Platform Errors
            '1.0'                              : [206, 'ERR_19'],
            '1.1'                              : [206, 'ERR_19'],
            '1.2'                              : [206, 'ERR_19'],
            '1.4'                              : [206, 'ERR_19'],
            '1.5'                              : [206, 'ERR_19'],
            '1.6'                              : [206, 'ERR_19'],
            '1.7'                              : [206, 'ERR_19'],
            '1.8'                              : [206, 'ERR_19'],
            '1.9'                              : [206, 'ERR_19'],
            '2.1'                              : [259, 'ERR_19'],
            '3.2'                              : [270, 'ERR_19'],
            '3.3'                              : [270, 'ERR_19'],
            '3.6'                              : [270, 'ERR_19'],
            '3.8'                              : [270, 'ERR_19'],
            '4.0'                              : [271, 'ERR_19'],
            '5.0'                              : [272, 'ERR_19'],
            '6.1'                              : [273, 'ERR_19'],
            '6.2'                              : [273, 'ERR_19'],
            '6.3'                              : [273, 'ERR_19'],
            '6.4'                              : [273, 'ERR_19'],
            '6.5'                              : [273, 'ERR_19'],
            '8.1'                              : [275, 'ERR_19'],
            '9.1'                              : [276, 'ERR_19'],
            '9.2'                              : [276, 'ERR_19'],
            '10.1'                             : [277, 'ERR_19'],
            '10.2'                             : [277, 'ERR_19'],
            '10.3'                             : [277, 'ERR_19'],
            '10.4'                             : [277, 'ERR_19'],
            '10.5'                             : [277, 'ERR_19'],
            '10.6'                             : [277, 'ERR_19'],
            '11.0'                             : [278, 'ERR_19'],
            '11.1'                             : [278, 'ERR_19'],
            '11.2'                             : [278, 'ERR_19'],
            '11.3'                             : [278, 'ERR_19'],
            '11.4'                             : [278, 'ERR_19'],
            '11.5'                             : [278, 'ERR_19'],
            '11.6'                             : [278, 'ERR_19'],
            '11.7'                             : [278, 'ERR_19'],
            '11.8'                             : [278, 'ERR_19'],
            '11.9'                             : [278, 'ERR_19'],
            '12.0'                             : [279, 'ERR_19'],
            '12.1'                             : [278, 'ERR_19'],
            '14.0'                             : [281, 'ERR_19'],
            '14.1'                             : [281, 'ERR_19'],
            '14.2'                             : [281, 'ERR_19'],
            '14.3'                             : [281, 'ERR_19'],
            '15.0'                             : [282, 'ERR_19'],

            'tveLinearStreamHeartbeat.503'     : [212, 'ERR_18'],
            'linearStreamHeartbeat.503'        : [213, 'ERR_18'],
            'stopWatchingLinearStreamTve.503'  : [214, 'ERR_19'],
            'stopWatchingLinearStream.503'     : [215, 'ERR_19'],
            'stopWatchingLinearStream.400'     : [215, 'ERR_19'],
            'startExternalTveLinearStream.500' : [216, 'ERR_19'],
            'startExternalTveLinearStream.403' : [217, 'ERR_16'],
            'linearStreamHeartbeat.403'        : [220, 'ERR_18'],
            'updateResumePoint.503'            : [221, 'ERR_19'],
            'getResumePoints.500'              : [222, 'ERR_19']
        },
        'dvr' : {
            'DEFAULT'                  :  300,
            '3329.12001'               : [301, 'ERR_19'],
            '3329.12002'               : [302, 'ERR_19'],
            '3329.12003'               : [303, 'ERR_19'],
            '3329.12004'               : [304, 'ERR_19'],
            '3329.12009'               : [305, 'ERR_19'],
            '3329.12000'               : [309, 'ERR_19'],
            '3329.12010'               : [310, 'ERR_13'],
            '412.12001'                : [301, 'ERR_19'],
            '412.12002'                : [302, 'ERR_19'],
            '412.12003'                : [303, 'ERR_19'],
            '412.12004'                : [304, 'ERR_19'],
            '412.12009'                : [305, 'ERR_19'],
            '412.12000'                : [309, 'ERR_19'],
            '412.12010'                : [310, 'ERR_13'],
            // Player Platform Errors
            '1.0'                      : [306, 'ERR_19'],
            '1.1'                      : [306, 'ERR_19'],
            '1.2'                      : [306, 'ERR_19'],
            '1.4'                      : [306, 'ERR_19'],
            '1.5'                      : [306, 'ERR_19'],
            '1.6'                      : [306, 'ERR_19'],
            '1.7'                      : [306, 'ERR_19'],
            '1.8'                      : [306, 'ERR_19'],
            '1.9'                      : [306, 'ERR_19'],
            '2.1'                      : [359, 'ERR_19'],
            '3.2'                      : [370, 'ERR_19'],
            '3.3'                      : [370, 'ERR_19'],
            '3.6'                      : [370, 'ERR_19'],
            '3.8'                      : [370, 'ERR_19'],
            '3.9'                      : [370, 'ERR_19'],
            '4.0'                      : [371, 'ERR_19'],
            '5.0'                      : [372, 'ERR_19'],
            '6.1'                      : [373, 'ERR_19'],
            '6.2'                      : [373, 'ERR_19'],
            '6.3'                      : [373, 'ERR_19'],
            '6.4'                      : [373, 'ERR_19'],
            '6.5'                      : [373, 'ERR_19'],
            '8.1'                      : [375, 'ERR_19'],
            '9.1'                      : [376, 'ERR_19'],
            '9.2'                      : [376, 'ERR_19'],
            '10.1'                     : [377, 'ERR_19'],
            '10.2'                     : [377, 'ERR_19'],
            '10.3'                     : [377, 'ERR_19'],
            '10.4'                     : [377, 'ERR_19'],
            '10.5'                     : [377, 'ERR_19'],
            '10.6'                     : [377, 'ERR_19'],
            '11.0'                     : [378, 'ERR_19'],
            '11.1'                     : [378, 'ERR_19'],
            '11.2'                     : [378, 'ERR_19'],
            '11.3'                     : [378, 'ERR_19'],
            '11.4'                     : [378, 'ERR_19'],
            '11.5'                     : [378, 'ERR_19'],
            '11.6'                     : [378, 'ERR_19'],
            '11.7'                     : [378, 'ERR_19'],
            '11.8'                     : [378, 'ERR_19'],
            '11.9'                     : [378, 'ERR_19'],
            '12.0'                     : [379, 'ERR_19'],
            '12.1'                     : [379, 'ERR_19'],
            '14.0'                     : [381, 'ERR_19'],
            '14.1'                     : [381, 'ERR_19'],
            '14.2'                     : [381, 'ERR_19'],
            '14.3'                     : [381, 'ERR_19'],
            '15.0'                     : [382, 'ERR_19'],

            'recordingHeartbeat.503'   : [313, 'ERR_19'],
            'stopWatchingRecording.503': [315, 'ERR_19'],
            'stopWatchingRecording.403': [316, 'ERR_19'],
            'updateResumePoint.503'    : [321, 'ERR_19'],
            'getResumePoints.500'      : [322, 'ERR_19']
        },
        'vod' : {
            'DEFAULT'                       :  400,
            '3329.12013'                    : [401, 'ERR_16'],
            '3329.12002'                    : [402, 'ERR_19'],
            '3329.12014'                    : [403, 'ERR_19'],
            '3329.12015'                    : [404, 'ERR_19'],
            '3329.12016'                    : [405, 'ERR_14'],
            '3329.12000'                    : [409, 'ERR_19'],
            '3329.12010'                    : [410, 'ERR_13'],
            '412.12013'                     : [401, 'ERR_16'],
            '412.12002'                     : [402, 'ERR_19'],
            '412.12014'                     : [403, 'ERR_19'],
            '412.12015'                     : [404, 'ERR_19'],
            '412.12016'                     : [405, 'ERR_14'],
            '412.12000'                     : [409, 'ERR_19'],
            '412.12010'                     : [410, 'ERR_13'],
            '403.102'                       : [420, 'ERR_19'],
            // Player Platform Errors
            '1.0'                           : [406, 'ERR_19'],
            '1.1'                           : [406, 'ERR_19'],
            '1.2'                           : [406, 'ERR_19'],
            '1.4'                           : [406, 'ERR_19'],
            '1.5'                           : [406, 'ERR_19'],
            '1.6'                           : [406, 'ERR_19'],
            '1.7'                           : [406, 'ERR_19'],
            '1.8'                           : [406, 'ERR_19'],
            '1.9'                           : [406, 'ERR_19'],
            '2.1'                           : [459, 'ERR_19'],
            '3.2'                           : [470, 'ERR_19'],
            '3.3'                           : [470, 'ERR_19'],
            '3.6'                           : [470, 'ERR_19'],
            '3.8'                           : [470, 'ERR_19'],
            '3.9'                           : [470, 'ERR_19'],
            '4.0'                           : [471, 'ERR_19'],
            '5.0'                           : [472, 'ERR_19'],
            '6.1'                           : [473, 'ERR_19'],
            '6.2'                           : [473, 'ERR_19'],
            '6.3'                           : [473, 'ERR_19'],
            '6.4'                           : [473, 'ERR_19'],
            '6.5'                           : [473, 'ERR_19'],
            '8.1'                           : [475, 'ERR_19'],
            '9.1'                           : [476, 'ERR_19'],
            '9.2'                           : [476, 'ERR_19'],
            '10.1'                          : [477, 'ERR_19'],
            '10.2'                          : [477, 'ERR_19'],
            '10.3'                          : [477, 'ERR_19'],
            '10.4'                          : [477, 'ERR_19'],
            '10.5'                          : [477, 'ERR_19'],
            '10.6'                          : [477, 'ERR_19'],
            '11.0'                          : [478, 'ERR_19'],
            '11.1'                          : [478, 'ERR_19'],
            '11.2'                          : [478, 'ERR_19'],
            '11.3'                          : [478, 'ERR_19'],
            '11.4'                          : [478, 'ERR_19'],
            '11.5'                          : [478, 'ERR_19'],
            '11.6'                          : [478, 'ERR_19'],
            '11.7'                          : [478, 'ERR_19'],
            '11.8'                          : [478, 'ERR_19'],
            '11.9'                          : [478, 'ERR_19'],
            '12.0'                          : [479, 'ERR_19'],
            '12.1'                          : [479, 'ERR_19'],
            '14.0'                          : [481, 'ERR_19'],
            '14.1'                          : [481, 'ERR_19'],
            '14.2'                          : [481, 'ERR_19'],
            '14.3'                          : [481, 'ERR_19'],
            '15.0'                          : [482, 'ERR_19'],

            'tveVodStreamHeartbeat.503'     : [412, 'ERR_19'],
            'vodStreamHeartbeat.503'        : [413, 'ERR_18'],
            'stopWatchingTveVod.503'        : [414, 'ERR_19'],
            'stopWatchingVod.503'           : [415, 'ERR_19'],
            'startExternalTveVodStream.403' :  416,
            'vodStreamHeartbeat.403'        : [417, 'ERR_19'],
            'finishWatchingTveVod.503'      : [418, 'ERR_19'],
            'finishWatchingVod.503'         : [419, 'ERR_19'],
            'updateResumePoint.503'         : [421, 'ERR_19'],
            'getResumePoints.500'           : [422, 'ERR_19']
        },
        'guide' : {
            'DEFAULT'                :  500,
            'getTvGridChunk.503'     : [501, 'ERR_11'],
            'getTvGridChunk.500'     : [501, 'ERR_11'],
            'getTvGridChunk.400'     : [501, 'ERR_11'],
            'getChannelMap.503'      : [502, 'ERR_3'],
            'getChannelMap.500'      : [502, 'ERR_3'],
            'getTvListingDetail.503' : [503, 'ERR_19'],
            'getTvListingDetail.404' : [503, 'ERR_19'],
            'getTvListingDetail.500' : [503, 'ERR_19']
        },
        'browse_search' : {
            'DEFAULT'               :  600,
            'browseNode.503'        : [601, 'ERR_2'],
            'browseNode.404'        : [601, 'ERR_2'],
            'browseNode.500'        : [601, 'ERR_2'],
            'browseNode.400'        : [601, 'ERR_2'],
            'searchByTerm.500'      : [602, 'ERR_12'],
            'searchByTerm.503'      : [602, 'ERR_12'],
            'searchByEntity.500'    :  603,
            'searchByEntity.503'    :  603,
            'browseVodTvSeries.500' :  606,
            'browseVodTvSeries.503' :  606,
            'browseNodeByAlias.503' : [607, 'ERR_2'],
            'browseNodeByAlias.400' : [607, 'ERR_2'],
            'browseNodeByAlias.404' : [607, 'ERR_2'],
            'browseProgramMenu.404' : [608, 'ERR_2'],
            'browseProgramMenu.500' : [608, 'ERR_2'],
            'browseProgramMenu.503' : [608, 'ERR_2'],
            'getBrowseRoot.503'     :  611
        },
        'entity' : {
            'DEFAULT'                       :  700,
            'getProgramEntityDetail.503'    : [701, 'ERR_7'],
            'getProgramEntityDetail.404'    : [701, 'ERR_7'],
            'getEntityRecordings.503'       :  702,
            'getEntityRecordings.500'       :  702,
            'getWatchOptionsForProgram.503' :  703,
            'getWatchOptionsForProgram.500' :  703,
            'getWatchOptionsForProgram.404' :  703
        },
        'saved' : {
            'DEFAULT'                                :  800,
            'getAllFavorites.503'                    :  801,
            'getAllFavorites.500'                    :  801,
            'getAllFavorites.404'                    :  801,
            'browseFavorites.503'                    : [802, 'ERR_1'],
            'addFavoriteItems.503'                   :  803,
            'getRecentlyWatched.503'                 :  805,
            'getRecentlyWatched.500'                 :  805,
            'getRecentlyWatched.404'                 :  805,
            'getVodWatched.503'                      :  806,
            'getVodWatched.500'                      :  806,
            'getWatchedPrograms.503'                 :  807,
            'getForYouMenu.503'                      :  810,
            'getForYouMenu.500'                      :  810,
            'getForYouFeaturedMenu.503'              :  811,
            'getForYouFeaturedMenu.500'              :  811,
            'getRecordings.503'                      : [815, 'ERR_9'],
            'getScheduledRecordingsWithEntities.503' : [816, 'ERR_10'],
            'getScheduledRecordingsWithEntities.500' : [816, 'ERR_10'],
            'recentRecordings.503'                   :  817,
            'recentRecordings.500'                   :  817,
            'getRecorderSummary.503'                 :  818,
            'getDeletedRecordings.503'               : [819, 'ERR_4'],
            'getPurchases.503'                       : [825, 'ERR_8']

        },
        'transact' : {
            'DEFAULT' :  900,
            '500.102' : [903, 'ERR_20'],
            '500.103' : [904, 'ERR_21'],
            '500.104' : [905, 'ERR_22'],
            '500.105' : [906, 'ERR_23'],
            '500.106' : [907, 'ERR_24'],
        }
    });

    // https://www.stg.xfinity.com/stream/demo/errors/index.html
    ErrorOverlay.errorTVAPP = Object.freeze({
    //   code              title                                                                                  message
        'ERR_1'  : [ "Well that didn’t go as planned..."                                        , "Please try again in a few minutes. Haven’t added any yet? Start by selecting “Add to Favorites” on the Info screen of any show, movie or team." ],
        'ERR_2'  : [ "Sorry, we're having some trouble"                                         , "Please check back later." ],
        'ERR_3'  : [ "Unable to Find Listings"                                                  , "There was a problem retrieving the TV Listings. Apologies for any inconvenience." ],
        'ERR_4'  : [ "Unable to Retrieve Deleted Recordings"                                    , "There was a problem retrieving your deleted recordings. Please try again. Apologies for any inconvenience." ],
        'ERR_5'  : [ "Unable to Update Parental Controls."                                      , "There was a problem updating your parental controls. Please try again." ],
        'ERR_6'  : [ "Unable to Retrieve Parental Controls."                                    , "There was a problem retrieving your parental controls. Please try again." ],
        'ERR_7'  : [ "Sorry, we're having some trouble"                                         , "Xfinity Stream is not available at the moment. Please try back soon." ],
        'ERR_8'  : [ "Unable to Retrieve Purchases"                                             , "There was a problem retrieving your purchases. Please try again. Apologies for any inconvenience." ],
        'ERR_9'  : [ "Unable to Retrieve Recordings"                                            , "There was a problem retrieving your recordings. Please try again. Apologies for any inconvenience." ],
        'ERR_10' : [ "Unable to Retrieve Scheduled Recordings"                                  , "There was a problem retrieving your scheduled recordings. Please try again. Apologies for any inconvenience." ],
        'ERR_11' : [ "Unable to Find Listings"                                                  , "There was a problem retrieving the TV Listings. Apologies for any inconvenience." ],
        'ERR_12' : [ "Your Search Was Interrupted"                                              , "A problem occurred during your search. Please try again. Apologies for any inconvenience." ],
        'ERR_13' : [ "Something's not right here"                                               , "We are having trouble adding this device to your account. Please call 1-800-xfinity and mention error 11700." ],
        'ERR_14' : [ "Maximum Concurrent Streams Reached"                                       , "You've reached the maximum concurrent streaming limit. To stream on this device, you'll have to stop streaming on another." ],
        'ERR_15' : [ ""                                                                         , "NOW AIRING ON Watch Now Not the show you expected?" ],
        'ERR_16' : [ "Subscription to This Channel Required"                                    , "A subscription is required to watch this channel. Please call 1-800-xfinity to add it to your service." ],
        'ERR_17' : [ "Sorry, this game is currently blacked out due to league restrictions."    , "You may be able to find the game on another channel.Please check All Listings." ],
        'ERR_18' : [ "Heartbeat Error"                                                          , "Please try resuming your video to continue where you left off." ],
        'ERR_19' : [ "This Video Cannot Be Played"                                              , "We've run into a problem while streaming %%entity%%." ],

        'ERR_20' : [ "We need some help with your account"                                      , "To watch this program, please visit <i>xfin.tv/payment</i>" ],
        'ERR_21' : [ "You've purchased this already!"                                           , "" ], //TODO: we need to insert logic here? (purchase vs watch)
        'ERR_22' : [ "This isn't available right now"                                           , "Check back soon as programming is updated frequently." ],
        'ERR_23' : [ "Sorry, we're having some trouble"                                         , "Please try again." ],
        'ERR_24' : [ "Sorry, we're having some trouble"                                         , "This program is not available to watch at the moment. Please try again in a few minutes." ],
    });

    /* Get the TVAPP-##### string via 2 different combinations of parameters
     *    context, api, major
     *    context, major, minor
     * Note: Static function since Telemetry needs access
     * @param {ErrorOverlay.Context}  context -
     * @param {String}                prefix  - major number or if API then a description such as "searchByTerm"
     * @param {String}               [suffix] - minor number or if API then major number
     * @return {Number|Array}                 - the ErrorOverlay.errorMatrix entry
     */
    ErrorOverlay.getTVAPP = function( context, prefix, suffix )
    {
        var matrixKey, err;

        if( suffix === undefined )
            suffix = "0";

        if( prefix !== undefined )
            matrixKey = "" + prefix + "." + suffix;
        else
            console.log( "ErrorOverlay.getTVAPP - WARNING: invalid error key parameter combination.");

        //try to find a matching error key in the given context
        err = ErrorOverlay.errorMatrix[context][matrixKey];

        //next, try to find this error key in the COMMON context
        if( err === undefined )
            err = ErrorOverlay.errorMatrix[ErrorOverlay.Context.COMMON][matrixKey];

        //finally, default to the default error for this context
        if( err === undefined )
            err = ErrorOverlay.errorMatrix[context]['DEFAULT'];


        //LOG something that we did not find.
        return err;
    };

    ErrorOverlay.prototype.dismiss = function()
    {
        _x2.popOverlay( this );

        if( this._onDismiss )
            this._onDismiss()
    };

    ErrorOverlay.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._buttons, true );
        this._speakTarget.setText( this._title.getText() + ". " + this._desc.getText() + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press OK to select. Press back to dismiss. " + this._messageStr.getText() + ". ", focus._speakRole, this, false );
    };

    /**
     * Initialize the error overlay
     * @param {*}              params
     * @param {ApiError|*}     [params.error]   - ApiError object
     * @param {string}         [params.reason]  - text description of error (if error obj not provided)
     * @param {*}              [params.context] - error context determines message (member of ErrorOverlay.Context)
     * @param {function}       [params.onRetry] - callback when user selects "Try Again"
     * @param {Showing|Entity} [params.entity]  - entity in play when error occurred
     * @param {boolean}        [params.noRetry] - present 'Close' button instead of 'Try Again' //TODO: this should derived from whether we have a onRetry parameter, but it will end up changing many buttons across the UI. Needs a deeper look.
     * @return {ErrorOverlay}
     */
    ErrorOverlay.prototype.init = function( params )
    {
        Overlay.prototype.init. call( this, params );
        this._className  = "ErrorOverlay";

        var onReady, buttons, messageKey, textKey, api, major, minor, entry, message = "", tvapp;

        var error   = params.error;
        var reason  = params.reason;
        var context = params.context ? params.context : ErrorOverlay.Context.COMMON;
        var onRetry = params.onRetry;

        var self         = this;
        var titleStyle   = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(45), whiteSpace:"nowrap", textAlign:"center" } );
        var descStyle    = new Style( { font:"light" , color:"#9b9b9b", fontSize:_x2.scaleValInt(27), whiteSpace:"nowrap", textAlign:"center" } );
        var buttonStyle  = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000"  } );
        var messageStyle = new Style( { font:"medium", color:"#8c8c8c", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );

        var w      = this._bg.getW();
        var title  = "Sorry, something went wrong";
        var desc   = "Please try again";
        var entity = "";

        console.log( "Error in " + context, error ); //Trace error in splunk log, if there is something went wrong

        if( params.entity && params.entity.getTitle )
            entity = params.entity.getTitle();

        var close = function()
        {
            _x2.popOverlay( self );
        };

        var retry = function()
        {
            _x2.popOverlay( self );

            if( onRetry )
                onRetry();
        };

        if( error )
        {
            if( error instanceof ApiError )
            {
                api   = error.getApi();
                major = error.getMajor();
                minor = error.getMinor();

                if( minor !== undefined )
                    tvapp = ErrorOverlay.getTVAPP( context, major, minor );
                else if( api !== undefined )
                    tvapp = ErrorOverlay.getTVAPP( context, api, major );
                else
                    tvapp = ErrorOverlay.getTVAPP( context, major );

                if( Array.isArray(tvapp) )
                {
                    messageKey  = tvapp[0];
                    textKey     = tvapp[1];
                }
                else
                    messageKey = tvapp;

                message = "(Error TVAPP-00" + messageKey + ")";

                if( textKey !== undefined )
                    entry = ErrorOverlay.errorTVAPP[ textKey ]; // Do we have a more detailed error title/description available?

                if( entry )
                {
                    if( entry[0] )
                        title = entry[0];
                    if( entry[1] )
                        desc  = entry[1].replace( "%%entity%%", entity );
                }
            }
            else
            {
                message = "(Internal Error)";

                if( reason === undefined && params.error && params.error.stack )
                    reason = params.error.stack;

                if( _x2._splunk && reason )
                    _x2._splunk.sendGeneralError( reason, "TVAPP-00100" );
            }
        }
        else if( reason )
            message = reason;

        this._numReady = 0;

        buttons = [];

        if( params.noRetry === true )
            buttons.push( new StringWidget().init( { text:"Close", style:buttonStyle, onEnter:close } ) );
        else
            buttons.push( new StringWidget().init( { text:"Try Again", style:buttonStyle, onEnter:retry } ) );

        onReady = function() { self._numReady++; self.layoutErrorOverlay() };

        this._title = new StringWidget().init( { text:title, style:titleStyle } );
        this._title.setW( w );
        this._title.addReadyCallback( onReady );
        this._bg.addWidget( this._title );

        this._desc = new TextBlockWidget().init( { text:desc, style:descStyle, w:w/2 } );
        this._bg.addWidget( this._desc, w/4 );

        this._buttons = new HorizPillButtonsWidget().init( { entries:buttons, spacing:_x2.scaleValInt(41) } );
        this._buttons.addReadyCallback( onReady );
        this._bg.addWidget( this._buttons );

        this._messageStr = new StringWidget().init( { text:message, style:messageStyle } );
        this._messageStr.addReadyCallback( onReady );
        this._bg.addWidget( this._messageStr );

        this._bg.setA(0);

        return this;
    };

    ErrorOverlay.prototype.layoutErrorOverlay = function()
    {
        if( this._numReady === 3 )
        {
            var y0     = 0;
            var y1     = y0 + this._title.getH();
            var y2     = y1 + ((this._desc === undefined) ? 0 : this._desc.getH());
            var offset = (this._bg.getH() - y2 - _x2.scaleValInt( 24 ) - this._buttons.getH()) / 2;

            this._title.setY ( y0 + offset );

            if( this._desc !== undefined )
                this._desc.setY( y1 + offset );

            this._buttons.setY( y2 + offset + _x2.scaleValInt( 24 ) );
            this._buttons.setX( ( this._bg.getW() - this._buttons.getW()) / 2 );

            this._messageStr.setX( this._bg.getW() - this._messageStr.getW() - _x2.scaleValInt(34) );
            this._messageStr.setY( this._bg.getH() - this._messageStr.getH() - _x2.scaleValInt(34) );

            this._bg.setA(1);
        }
    };

    return ErrorOverlay;

})();
