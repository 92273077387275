// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.ThePlatformApi = (function ()
{
    function ThePlatformApi( vodEndpoint )
    {
        this._vodEndpoint = vodEndpoint;
        this._xtvApi      = new XtvApi( _x2._config._xtvApiRoot, Config.AUTH_OAUTH );
    }

    ThePlatformApi.prototype.getVodFolder = function()
    {
        var url = this._vodEndpoint;

        var remap = function (object)
        {
            return {
                name          : object.title,
                description   : object.description,
                partOfSeason  : { seasonNumber: object['fourk$seasonnumber'] },
                episodeNumber : object['fourk$episodeid']
            };
        };

        var resolver = function( resolve, reject )
        {
            var success = function (data)
            {
                var vodEntities = [];
                var vodData = JSON.parse(data);

                if( vodData && vodData.entries )
                {
                    for( var i=0; i<vodData.entries.length; i++ )
                        vodEntities.push( new Entity( remap( vodData.entries[i] ) ) );
                }

                resolve(vodEntities);
            };

            var error = function (error)
            {
                reject(error);
            };

            _x2._network.ajax( { type: "GET", url: url } ).then(success).catch(error);
        };

        return new Promise(resolver);
    };

    ThePlatformApi.prototype.getParentalControls = function(cancelOnDemand)
    {
        return this._xtvApi.getParentalControls( cancelOnDemand );
    };

    ThePlatformApi.prototype.getResumePoints = function()
    {
        return {};
    };

    ThePlatformApi.prototype.getSettings = function()
    {
        return {};
    };

    return ThePlatformApi;

})();
