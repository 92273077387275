// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.NetworkRowWidget = ( function()
{
    "use strict";

    NetworkRowWidget.prototype = new CollectionRowWidget();

    function NetworkRowWidget(){}

    NetworkRowWidget.prototype.init = function( params )
    {
        params.type = NetworkCellWidget;
        params.y    = _x2.scaleVal( 150 ) - _x2.scaleVal( 14 );
        params.h    = _x2.scaleValInt( 389 );

        CollectionRowWidget.prototype.init.call( this, params );
        this._className  = "NetworkRowWidget";

        return this;
    };

    NetworkRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 539 );
    };

    return NetworkRowWidget;

})();
