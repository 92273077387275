// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.KeyboardWidget = ( function()
{
    "use strict";

    KeyboardWidget.prototype = new Widget();

    function KeyboardWidget(){}

    KeyboardWidget.prototype.createSpeechStr = function()
    {
        var str  = this._widgets[this._index].getText && this._widgets[this._index].getText();
        var hint = this._speachLabels[this._pageNum][this._index];

        return hint ? hint : str;
    };

    KeyboardWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        this._widgets[this._index].gotFocus();
        this._hi.animate( { a:1, duration:300 } );
        _x2._hi.fadeOut();
        this._hi.setSpeechParams( this.createSpeechStr() + ". Press arrow keys to review characters. Press OK to select. ", undefined, this, newScreen );

        return this;
    };

    /**
     * Initializer
     * @memberof KeyboardWidget
     * @param   {Object}        params
     * @param   {Function}     [params.onEnter] - Callback when Enter is pressed
     * @param   {Function}     [params.onReady] - Callback when have loaded
     * @returns {KeyboardWidget}
     */
    KeyboardWidget.prototype.init = function( params )
    {
        var charsStyle, i, j, rect, onMouseDown, self = this;
        var onLoad = function() { self._numLoaded++; self.layoutKeyboardWidget() };

        Widget.prototype.init.call( this );
        this._className = "KeyboardWidget";

        if( params.onEnter )
            this._onEnter = params.onEnter;

        if( params.onReady )
             this.addReadyCallback( params.onReady );

        this._selectable = true;
        this._numLoaded  = 0;
        this._index      = 0;
        this._perRow     = 10;
        this._stepW      = _x2.scaleValInt(90);
        this._stepH      = _x2.scaleValInt(84);
        this._gap        = _x2.scaleValInt( 3);
        this._pageNum    = 0;
        this._pages      =
        [
            [ "A", "B", "C", "D", "E", "F", "G", "H", "I", "del", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "tr", "S", "T", "U", "V", "W", "X", "Y", "Z", "&12", "sp"  ],
            [ "0", "1", "2", "3", "4", "5", "6", "7", "8", "del", "9", "$", "_", ".", "'", ":", ";", "?", "!", "tr", "#", "@", "%", "-", "" , "" , "" , "" , "ABC", "sp"  ]
        ];

        this._numRows      = parseInt( (this._pages[0].length + this._perRow - 1) / this._perRow );
        this._widgets      = [];
        this._speachLabels = [[],[]];

        charsStyle  = new Style( { font:"regular" , color:"#e8e8e8", colorHi:"#000000", fontSize:_x2.scaleValInt(32), textAlign:"center" } );
        onMouseDown = function( index ) { return function() { self.onMouseDown( index ); } };

        for( i = 0; i < this._perRow * this._numRows; i++ )
        {
            rect = new RectWidget().init( { w:this._stepW-this._gap, h:this._stepH-this._gap, color:"#191919" } );
            this.addWidget( rect, this._stepW * (i % this._perRow), this._stepH * parseInt(i / this._perRow) );
            rect.setMouseDownListener( onMouseDown( i ) );
        }

        this._hi = new RectWidget().init( { w:this._stepW - this._gap, h:this._stepH - this._gap, color:"#e8e8e8" } );
        this._hi.setA( 0 );
        this.addWidget( this._hi );

        this._clear  = new DualImageWidget().init( { url:_x2._config._imageRoot + "clearIcon.png" , urlHi:_x2._config._imageRoot + "clearIconHi.png"  } );
        this._delete = new DualImageWidget().init( { url:_x2._config._imageRoot + "deleteIcon.png", urlHi:_x2._config._imageRoot + "deleteIconHi.png" } );
        this._space  = new DualImageWidget().init( { url:_x2._config._imageRoot + "spaceIcon.png" , urlHi:_x2._config._imageRoot + "spaceIconHi.png"  } );
        this._clear.addReadyCallback ( onLoad );
        this._delete.addReadyCallback( onLoad );
        this._space.addReadyCallback ( onLoad );

        for( i = 0; i < this._pages[0].length; i++ )
        {
            switch( this._pages[0][i] )
            {
                case "del":
                    this._widgets[i] = this._delete;
                    this._speachLabels[0][i] = this._speachLabels[1][i] = "delete";
                    break;

                case "sp":
                    this._widgets[i] = this._space;
                    this._speachLabels[0][i] = this._speachLabels[1][i] = "space";
                    break;

                case "tr":
                    this._widgets[i] = this._clear;
                    this._speachLabels[0][i] = this._speachLabels[1][i] = "clear";
                    break;

                default:
                    this._widgets[i] = new StringWidget().init( { text:this._pages[0][i], style:charsStyle, focusTime:250 } );
                    this._widgets[i].setW( this._stepW - this._gap );
                    break;
            }

            this.addWidget( this._widgets[i], this._stepW * (i % this._perRow), this._stepH * parseInt(i / this._perRow) );
        }

        var index;
        var toMap = [ "A" , "&12"                         , "ABC"             , "_"         , "."     , "\'"        , ":"    , ";"        , "?"            , "!"               , "-"      ];
        var strs  = [ "Ay", "numbers and symbols keyboard", "letters keyboard", "underscore", "period", "apostrophe", "colon", "semicolon", "question mark", "exclamation mark", "hyphen" ];

        if( _x2._config._host.getPlatformString() === "lg" )
            strs[0] = "Capital A";

        for( i = 0; i < this._pages.length; i++ )
        {
            for( j = 0; j < toMap.length; j++ )
            {
                index = this._pages[i].indexOf( toMap[j] );

                if( index >= 0 )
                    this._speachLabels[i][index] = strs[j];
            }
        }

        this._widgets[0].addReadyCallback( onLoad );

        this.setW( this._stepW * this._perRow  - this._gap );
        this.setH( this._stepH * this._numRows - this._gap );

        return this;
    };

    KeyboardWidget.prototype.layoutKeyboardWidget = function()
    {
        if( this._numLoaded === 4 )
        {
            for( var i = 0; i < this._widgets.length; i++ )
            {
                this._widgets[i].setY( this._widgets[i].getY() + (this._stepH - this._widgets[i].getH() - this._gap) / 2 );

                if( this._widgets[i] instanceof DualImageWidget )
                    this._widgets[i].setX( this._widgets[i].getX() + (this._stepW - this._widgets[i].getW() - this._gap) / 2 );
            }

            this.callbackSignalReady();
        }
    };

    KeyboardWidget.prototype.lostFocus = function()
    {
        this._widgets[this._index].lostFocus();
        this._hi.animate( { a:0, duration:300 } );
    };

    KeyboardWidget.prototype.onMouseDown = function( index )
    {
        var i, page, str = this._pages[this._pageNum][index];

        switch( str )
        {
            case "&12":
            case "ABC":
                this._pageNum = (this._pageNum + 1) % this._pages.length;
                page          = this._pages[this._pageNum];

                for( i = 0; i < this._widgets.length; i++ )
                    if( this._widgets[i].setText )
                        this._widgets[i].setText( page[i] );
                break;

            default:
                if( this._onEnter )
                    this._onEnter( str );
        }
    };

    KeyboardWidget.prototype.processEvent = function( val, type )
    {
        var retval = false, col, row, index;

        switch( val )
        {
            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED )
                {
                    row = parseInt( this._index / this._perRow );

                    if( row < this._numRows - 1 )
                    {
                        this._widgets[this._index].lostFocus();
                        this._index += this._perRow;

                        index = this._index;
                        col   = index % this._perRow;

                        while( this._pages[this._pageNum][index] === "" && (col < this._perRow - 1) )
                            col = ++index % this._perRow;

                        this._index = index;

                        this._widgets[this._index].gotFocus();
                        this._hi.setSpeechParams( this.createSpeechStr(), undefined, this, false );
                        this._hi.animate( { x:col * this._stepW, y:(row+1) * this._stepH, duration:300 } );
                        retval = true;
                    }
                }
                break;

            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    this._hi.setSpeechParams( this.createSpeechStr(), undefined, this, false );
                    this.onMouseDown( this._index );
                }
                retval = true;
                break;

            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                {
                    col = this._index % this._perRow;

                    if( col > 0 )
                    {
                        index = this._index - 1;

                        while( this._pages[this._pageNum][index] === "" && col > 0 )
                            col = --index % this._perRow;

                        this._widgets[this._index].lostFocus();
                        col         = index % this._perRow;
                        this._index = index;
                        this._widgets[this._index].gotFocus();
                        this._hi.setSpeechParams( this.createSpeechStr(), undefined, this, false );
                        this._hi.animate( { x:(col) * this._stepW, duration:300 } );
                        retval = true;
                    }
                }
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                {
                    col = this._index % this._perRow;

                    if( col < this._perRow - 1 )
                    {
                        index = this._index + 1;

                        while( this._pages[this._pageNum][index] === "" && (col < this._perRow - 1) )
                            col = ++index % this._perRow;

                        this._widgets[this._index].lostFocus();
                        col         = index % this._perRow;
                        this._index = index;
                        this._widgets[this._index].gotFocus();
                        this._hi.setSpeechParams( this.createSpeechStr(), undefined, this, false );
                        this._hi.animate( { x:(col) * this._stepW, duration:300 } );
                        retval = true;
                    }
                }
                break;

            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                {
                    row = parseInt( this._index / this._perRow );

                    if( row > 0 )
                    {
                        this._widgets[this._index].lostFocus();
                        this._index -= this._perRow;
                        this._widgets[this._index].gotFocus();
                        this._hi.setSpeechParams( this.createSpeechStr(), undefined, this, false );
                        this._hi.animate( { y:(row-1) * this._stepH, duration:300 } );
                        retval = true;
                    }
                }
                break;
        }

        if( retval === false )
            retval = Widget.prototype.processEvent.call( this, val, type );

        return retval;
    };

    return KeyboardWidget;

})();
