// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

"use strict";

/**
 * @class
 *
 * Conceptually related to {@link Channel}, but with distinct properties. Maps to XTV "ContentProvider".
 */
window.ContentProvider = (function()
{
    ContentProvider.DEFAULT_LOGO_W = 90;
    ContentProvider.DEFAULT_LOGO_H = 90;

    ContentProvider.prototype = new DataObject();

    function ContentProvider(){}

    /**
     * Initialize this object with data
     * @memberOf ContentProvider
     * @param {Object} data - XTV derived JSON object
     * @return {ContentProvider}
     */
    ContentProvider.prototype.init = function( data )
    {
        DataObject.prototype.init.call( this, data );
        return this;
    };

    /**
     * Get the ID of this provider
     * @memberOf ContentProvider
     * @return {String}
     */
    ContentProvider.prototype.getCompanyId = function()
    {
        return this.get("companyId");
    };

    /**
     * Get the URL of the image representing this provider
     * @memberOf ContentProvider
     * @param {Number} width  - Image width
     * @param {Number} height - Image height
     * @return {String}
     */
    ContentProvider.prototype.getLogoUrl = function( width, height )
    {
        var w = width  ? width  : ContentProvider.DEFAULT_LOGO_W;
        var h = height ? height : ContentProvider.DEFAULT_LOGO_H;
        return this.getLink("logo", { width:w, height:h } );
    };

    /**
     * Get the name of this provider. Example: "STARZ"
     * @memberOf ContentProvider
     * @return {String}
     */
    ContentProvider.prototype.getName = function()
    {
        return this.get("name");
    };

    return ContentProvider;
})();
