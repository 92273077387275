// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.DistractorOverlay = (function()
{
    "use strict";

    DistractorOverlay.prototype = new Overlay();

    function DistractorOverlay(){}

    DistractorOverlay.prototype.init = function()
    {
        Overlay.prototype.init.call( this, { hideExitStr:true } );
        this._className = "DistractorOverlay";

        var self = this;

        this._distractor = new DistractorWidget().init( {} );
        this._distractor.addReadyCallback( function()
        {
            self._distractor.centerOnWidget( self._bg );
            self._distractor.show();
        } );

        this._bg.addWidget( this._distractor );

        return this;
    };

    return DistractorOverlay;

})();
