// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.SearchScreen = ( function()
{
    "use strict";

    SearchScreen.prototype = new Screen();

    var LOCAL_STORAGE_KEY = "recentSearches";

    function SearchScreen(){}

    SearchScreen.prototype.getRecentSearches = function()
    {
        var retval = [], json = localStorage[LOCAL_STORAGE_KEY];

        if( json !== undefined )
        {
            var objs = JSON.parse(json);

            for( var i = 0; i < objs.length; i++ )
                retval.push( new SearchEntity().init( objs[i] ) );
        }

        return retval;
    };

    SearchScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._keyboard, true );
        this._speakTarget.setText( this.getBreadCrumb() + ". Search for TV Shows, Movies, Channels and more. " + focus._speakStr );
        this._speakTarget.setSpeechParams( undefined, focus._speakRole, this, false );
    };

    SearchScreen.prototype.init = function()
    {
        var self            = this, keyboardParams = {}, resultsParams = {}, recents;
        var onLoad          = function() { self.layout(); };
        var iconsTitleStyle = new Style( { font:"light" , color:"#a3a3a3", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", textAlign:"center" } );
        var listTitleStyle  = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap" } );
        var noRecentsStyle  = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap", textAlign:"center" } );

        Screen.prototype.init.call( this );
        this._className     = "SearchScreen";
        this._telemetryName = "Search";
        this._numReady      = 0;
        this.setBreadCrumbs( undefined, "Search" );

        keyboardParams.w       = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        keyboardParams.onReady = onLoad;
        keyboardParams.onEnter = function( char ) { self._text.onChar( char ); };

        this._container = new Widget().init();
        this._container.setA( 0 );

        this._scrollPane = new ScrollWidget().init( { widget: this._container, w: _x2._config._screenW, h:_x2._config._screenH  } );
        this.addWidget( this._scrollPane );

        this._keyboard = new KeyboardWidget().init( keyboardParams );
        this._container.addWidget( this._keyboard, Style._safeLeft );

        this._text = new TextEntryWidget().init( { w:300, instStr:"Search for TV Shows, Movies, Channels and more.", onChange:function( text ) { self.onTextChanged( text ); } } );
        this._text.setW( this._keyboard.getW() );
        this._text.addReadyCallback( onLoad );
        this._container.addWidget( this._text, Style._safeLeft );

        this._iconsTitle = new StringWidget().init( { text:"Enter one of the following...", style:iconsTitleStyle } );
        this._container.addWidget( this._iconsTitle );

        this._icons = new ImageWidget().init( { url:_x2._config._imageRoot + "searchIcons.png", onLoad:onLoad } );
        this._container.addWidget( this._icons );

        this._listTitle = new StringWidget().init( { text:"Recent Searches", style:listTitleStyle } );
        this._listTitle.setA( 0 );
        this._container.addWidget( this._listTitle, Style._safeLeft );

        this._noRecents = new StringWidget().init( { text:"No recent searches history.", style:noRecentsStyle } );
        this._noRecents.setW( _x2._config._screenW );
        this._container.addWidget( this._noRecents );

        resultsParams.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        resultsParams.h        = _x2.scaleValInt(510);
        resultsParams.maxIndex = 1;
        resultsParams.axis     = ListWidget.Axis.X;
        resultsParams.type     = GalleryCellWidget;
        resultsParams.obj      = this;
        resultsParams.touchH   = new GalleryCellWidget().getTouchDim();
        resultsParams.touchW   = Math.floor( Style._safeLeft * 2 / 3 );

        this._resultsList = new ListWidget().init( resultsParams );
        this._resultsList.setA( 0 );
        this._container.addWidget( this._resultsList, Style._safeLeft );

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( onLoad );
        this._resultsList.addWidget( this._distractor );

        recents = this.getRecentSearches();

        if( recents.length > 0 )
        {
            this._widgetShowing = true;
            this._resultsList.setData( recents );
            this._resultsList.animate( { a:1, duration:500 } );
            this._listTitle.animate( { a:1, duration:500 } );
            this._noRecents.animate( { a:0, duration:500 } );
        }

        window.ll( "tagScreen", "Search" );
        _x2._telemetry.sendScreenViewed( this._telemetryName );

        _x2._telemetry.watchPathPush( "Search" ); // Default for Channel collection

        return this;
    };

    SearchScreen.prototype.layout = function()
    {
        var self = this, onMouseWheel;

        if( ++this._numReady === 4 )
        {
            var keyboardR = this._keyboard.getX() + this._keyboard.getW();

            this._scrollPane.setY( this.getHeaderBottom() );
            this._scrollPane.setH( _x2._config._screenH - this.getHeaderBottom() );
            this._text.setY( _x2.scaleValInt( 42 ) ); // XC-13188, space between Breadcrumb line separator
            this._keyboard.setY( this._text.getY() + this._text.getH() + _x2.scaleValInt( 30 ) );
            this._iconsTitle.setW( _x2._config._screenW - Style._safeRight - keyboardR );
            this._iconsTitle.setX( keyboardR );
            this._iconsTitle.setY( _x2.scaleValInt( 42 ) );
            this._icons.setX( keyboardR + _x2.scaleValInt( 52 ) );
            this._icons.setY( this._iconsTitle.getY() + this._iconsTitle.getH() + _x2.scaleValInt( 33 ) );
            this._listTitle.setY( this._keyboard.getY() + this._keyboard.getH() + _x2.scaleValInt( 41 ) );
            this._noRecents.setY( this._keyboard.getY() + this._keyboard.getH() + _x2.scaleValInt( 235 ) );
            this._resultsList.setY( this._listTitle.getY() + this._listTitle.getH() + _x2.scaleValInt( 8 ) );
            this._yOffset = -this._keyboard.getY() - this._keyboard.getH();

            this._distractor.centerOnWidget( this._resultsList );

            onMouseWheel = function( evt )
            {
                if( evt.deltaY > 0 )
                    self.shiftUp();
                else if( evt.deltaY < 0 )
                    self.shiftDown();
            };

            this.setMouseWheelListener( onMouseWheel );
            this._container.animate( { a:1, duration:500 } );
        }
    };

    SearchScreen.prototype.onSelected = function( entity )
    {
        var obj     = [];
        var recents = this.getRecentSearches();
        var id      = entity.getEntityId();
        var pos     = this._resultsList ? this._resultsList.getCurrentAndMaxIndex() : { current: "NA" };

        for( var i = 0; i < recents.length; i++ )
            if( id !== recents[i].getEntityId() )
                obj.push( recents[i]._data );

        var metrics =
        {
            "Position"     : pos.current,
            "Results"      : "Yes",
            "Search Term"  : this._text.getText(),
            "Selected ID"  : id,
            "Selected Name": entity.getTitle(),
            "Type"         : entity._localyticsSearchType ? entity._localyticsSearchType : "UNDEFINED"
        };
        window.ll( "tagEvent", "Search", metrics ); // Localytics: Search.2

        obj.unshift( entity._data );
        localStorage.setItem( LOCAL_STORAGE_KEY, JSON.stringify(obj) );
    };

    SearchScreen.prototype.onTextChanged = function( str )
    {
        var self = this, onSuccess, onError, recents, metrics;

//        console.log( "Search String ==> " + str + " <" );

        onSuccess = function( result )
        {
            if( str === self._text.getText() )
            {
                self._distractor.hide();

//                 console.log( "========== " + result.length + " results for: " + str + " ==========" );
//
//                 for( var i = 0; i < result.length; i++ )
//                 {
//                     console.log( "=> " + result[i].getTitle() + " -> " + result[i].getSubtitle() );
//                     console.log( result[i] );
//                 }

                self._listTitle.setText( "Showing " + result.length + " results for \"" + str + "\"" );
                self._resultsList.setData( result );

                if( self._readSpeechHint !== true )
                {
                    self._readSpeechHint = true;
                    self._listTitle.setSpeechParams( "Use down arrow to navigate to the results below the keyboard. " , undefined, self, false );
                }
                else
                    self._listTitle.setSpeechParams( undefined, undefined, self, false );

                if( result.length < 1 )
                {
                    metrics =
                    {
                        "Position"     : "",
                        "Results"      : "No",
                        "Search Term"  : str,
                        "Selected ID"  : "",
                        "Selected Name": "",
                        "Type"         : "UNDEFINED"
                    };
                    window.ll( "tagEvent", "Search", metrics ); // Localytics: Search.1
                }
            }
            else
                console.log( "IGNORED SEARCH RETURN FOR " + str );
        };

        onError = function( error )
        {
            console.log( error.toString() );
            self._distractor.hide();
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.BROWSE_SEARCH } ) );
        };

        if( str.length > 0 )
        {
            this._resultsList.setData();

            if( this._widgetShowing !== true )
            {
                this._widgetShowing = true;
                this._resultsList.animate( { a:1, duration:500 } );
                this._listTitle.animate( { a:1, duration:500 } );
                this._noRecents.animate( { a:0, duration:500 } );
            }

            this._listTitle.setText( "Searching ..." );
            this._distractor.show();
            _x2._data.search( str, 100 ).then( onSuccess ).catch( onError );
        }
        else
        {
            recents = this.getRecentSearches();

            if( recents.length === 0 )
            {
                this._widgetShowing = false;
                this._resultsList.animate( { a:0, duration:500 } );
                this._listTitle.animate( { a:0, duration:500 } );
                this._noRecents.animate( { a:1, duration:500 } );
            }
            else
            {
                this._widgetShowing = true;
                this._resultsList.setData( recents );
                this._listTitle.setText( "Recent Searches" );
                this._resultsList.animate( { a:1, duration:500 } );
                this._listTitle.animate( { a:1, duration:500 } );
                this._noRecents.animate( { a:0, duration:500 } );
            }
        }
    };

    SearchScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED )
                    this.shiftUp();
                break;

            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                    this.shiftDown();
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    SearchScreen.prototype.shiftDown = function()
    {
        if( this._container.isAnimating( Widget.Axis.Y ) === false && this._resultsList.containsWidget( _x2._focus ) === true )
        {
            this._scrollPane.setScroll( { y:0, duration:300 } );
            _x2.requestFocus( this._keyboard );
        }
    };

    SearchScreen.prototype.shiftUp = function()
    {
        var onEnd, focus, self = this;

        if( this._resultsList.getMaxIndex() > 0 && this._container.isAnimating( Widget.Axis.Y ) === false && this._widgetShowing === true && this._resultsList.containsWidget( _x2._focus ) === false )
        {
            onEnd = function()
            {
                if( _x2._focus === self._keyboard )
                {
                    focus = _x2.requestFocus( self._resultsList, true );
                    self._listTitle.setSpeechParams( focus._speakStr + "Press left or right to review items. Press OK to select.", focus._speakRole, this, false );
                }
            };

            this._scrollPane.setScroll( { y:this._yOffset, duration:300, onEnd:onEnd } );
        }
    };

    return SearchScreen;

})();
