// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

window.MetricsSocket = ( function()
{
    "use strict";

    /**
     * @param {Object} [token                      ] - XSCT token
     * @param {String} [token.tokenSummary.deviceId] -
     * @param {String} [token.serviceAccessToken   ] -
     */
    function MetricsSocket( token )
    {
        this._deviceId = token && token.tokenSummary.deviceId;
        this._protocol = token && token.serviceAccessToken;
        this._queue    = [];
    }

    /**
     * Used for Request Id
     * @returns {number}
     */
    MetricsSocket.prototype.getRandomInt = function()
    {
        return Math.round( Math.random() * 0xffffffff );
    };

    /**
     * @param {Object} obj
     */
    MetricsSocket.prototype.send = function( obj )
    {
        var self = this, data, url;

        var onClose = function(evt)
        {
            console.error( "Metrics Socket: Closed", evt );

            self._socket = undefined;
        };

        var onError = function( evt )
        {
            console.error( "Socket error - " ,  evt );

            self._socket = undefined;
        };

        var onMessage = function( evt )
        {
            console.warn( "Metrics Socket: Unexpected mesage received: " , evt );
        };

        var onOpen = function()
        {
            console.log( "Metrics Socket: Open" );

            self._socket.send( data );
            obj.requestId = undefined;

            while( self._queue.length > 0 )
                self._socket.send( self._queue.shift() );
        };

        if( obj )
        {
            if( obj.requestId === undefined )
                obj.requestId = "requestId" + this.getRandomInt();

            if( obj.ts === undefined )
                obj.ts = Date.now(); // timestamp

            data = JSON.stringify( obj );

            if( !this._socket )
            {
//              url  = "wss://echo.websocket.org";                           // debug
                url  = "wss://metricscollector.lp.xcal.tv:18082";            // prod
//              url  = "wss://metricscollector-ci.lp.xcal.tv:18082";         // dev
                url += "/metrics?client=xstream&deviceId=" + this._deviceId; // common to all
                try {
                    this._socket            = new WebSocket( url, this._protocol );  // dev
                    console.log(this._socket);
                    this._socket.binaryType = "blob";
                    this._socket.onclose    = onClose;
                    this._socket.onerror    = onError;
                    this._socket.onmessage  = onMessage;
                    this._socket.onopen     = onOpen;
                    this._queue.push( data );
                } catch(e) {
                    console.log(e);
                }
            }
            else if( this._socket.readyState === WebSocket.OPEN )
            {
                this._socket.send( data );
                obj.requestId = undefined;
            }
            else if( this._socket.readyState === WebSocket.CONNECTING )
            {
                console.log( "Metrics Socket: STILL CONNECTING" );

                this._queue.push( data );
            }
            else if( this._socket.readyState === WebSocket.CLOSING )
            {
                console.error( "Metrics Socket: Closing!" );
            }
            else
            {
                console.error( "Metrics Socket: In a weird state" );
                console.log( this._socket );
            }
        }
    };

    return MetricsSocket;

})();
