// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.FindChannelOverlay = ( function()
{
    "use strict";

    FindChannelOverlay.prototype = new Overlay();

    function FindChannelOverlay(){}

    FindChannelOverlay.prototype.executeSearch = function( str )
    {
        var results  = [], chNum, callsign, company, voiceHint, i;
        var channels = this._cc.getChannels();

        for( i = 0; i < channels.length; i++ )
        {
            chNum     = channels[i].getNumber().toString();
            callsign  = channels[i].getCallSign();
            company   = channels[i].getCompany();
            voiceHint = channels[i].getVoiceHint();

            if( company )
                company = company.toUpperCase();

            if( voiceHint )
                voiceHint = voiceHint.toUpperCase();

            if( (chNum && chNum.startsWith( str ) === true) || (callsign && callsign.startsWith( str ) === true) || (company && company.startsWith( str ) === true) || (voiceHint && voiceHint.startsWith( str ) === true) )
                results.push( channels[i] );
        }

// console.log( "=====================================================================" );
//
// for( i = 0; i < results.length; i++ )
//     console.log( results[i].getNumber() + " " + results[i].getCallSign() + " " + results[i].getCompany() );
//
// console.log( "---------------------------------------------------------------------" );
// console.log( "Total: " + results.length );

        this._numResults = results.length;
        this._list.setData( results );

        if( this._numResults > 0 )
        {
            this._sep.setA( 1 );
            this._icon.setA( 0 );
            this._noResultsStr.setA( 0 );
        }
        else
        {
            this._sep.setA( 0 );

            if( str.length === 0 )
            {
                this._icon.setA( 1 );
                this._noResultsStr.setA( 0 );
            }
            else
            {
                window.ll( "tagEvent", "Live TV Find Channel Results", { title:this._searchStr } ); // Localytics 2.3.8
                _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localtyics: Live TV: Find Channel no results: " + this._searchStr ) : Config.NOP();

                this._icon.setA( 0 );
                this._noResultsStr.setA( 1 );
            }
        }
    };

    FindChannelOverlay.prototype.init = function( params )
    {
        var self           = this, x, y, h, date, onChannelsLoaded, onChannelsFailed;
        var keyboardParams = {}, listParams = {}, layout;
        var onLoad         = function() { self._numReady++; layout(); };
        var hPad           = _x2.scaleValInt(78);
        var vPad           = _x2.scaleValInt(80);

        Overlay.prototype.init.call( this );
        this._className  = "FindChannelOverlay";
        this._numReady   = 0;

        layout = function()
        {
            if( self._numReady === 3 )
            {
                self._keyboard.setY( self._text.getY() + self._text.getH() + _x2.scaleValInt( 30 ) );

                self._icon.setX( self._list.getX() + (self._list.getW() - self._icon.getW()) / 2 );
                self._icon.setY( (self._bg.getH() - self._icon.getH()) / 2 );

                self._noResultsStr.setX( self._list.getX() + (self._list.getW() - self._noResultsStr.getW()) / 2 );
                self._noResultsStr.setY( (self._bg.getH() - self._noResultsStr.getH()) / 2 );

                if( _x2._focus === self )
                    self.speak( _x2.requestFocus( self._keyboard, true ) );

                 // get the list of channels

                date = new Date();
                date.setMilliseconds( 0 );
                date.setSeconds( 0 );
                date.setMinutes( (date.getMinutes() < 30) ? 0 : 30 );

                _x2._channelCollection.fetch().then( onChannelsLoaded, onChannelsFailed );
            }
        };

        onChannelsLoaded = function()
        {
            var chanColl = _x2._channelCollection;

            self._dataReady = true;
            self._cc        = chanColl;

            if( self._searchStr )
                self.executeSearch( self._searchStr );
        };

        onChannelsFailed = function( error )
        {
            var onDismiss = function()
            {
                _x2.popOverlay( self );
            };

            var onRetry = function()
            {
                var retry = function()
                {
                    _x2._channelCollection.fetch().then( onChannelsLoaded, onChannelsFailed );
                };

                _x2.popOverlay( self._errorOverlay, retry );
            };

            self._errorOverlay = _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.GUIDE, onDismiss:onDismiss, onRetry:onRetry } ) );
        };

        this._icon  = new ImageWidget().init( { url:_x2._config._imageRoot + "ChannelFinder.png", onLoad:onLoad } );
        this._bg.addWidget( this._icon );

        var noResultStyle = new Style( { font:"regular" , color:"#a3a3a3", fontSize:_x2.scaleValInt(32) } );

        this._noResultsStr = new StringWidget().init( { text:"Sorry, there's no results", style:noResultStyle } );
        this._noResultsStr.setA( 0 );
        this._bg.addWidget( this._noResultsStr );

        keyboardParams.onReady = onLoad;
        keyboardParams.onEnter = function( char ) { self._text.onChar( char ); };

        this._keyboard = new KeyboardWidget().init( keyboardParams );
        this._bg.addWidget( this._keyboard, hPad );

        this._text = new TextEntryWidget().init( { w:300, instStr:"Search for Networks and Channels", onChange:function( text ) { self.onTextChanged( text ); } } );
        this._text.setW( this._keyboard.getW() );
        this._text.addReadyCallback( onLoad );
        this._bg.addWidget( this._text, hPad, vPad );

        x = this._keyboard.getX() + this._keyboard.getW() + _x2.scaleValInt( 60 );
        y = this._text.getY() + ListRowWidget.DIVIDER_H;
        h = new FindChannelRowWidget().getHeightUnfocused();
        h = Math.floor((this._bg.getH() - y) / h) * h;

        listParams.w        = this._bg.getW() - x - hPad;
        listParams.h        = h;
        listParams.maxIndex = 1;
        listParams.type     = FindChannelRowWidget;
        listParams.obj      = params.screen;

        this._list = new ListWidget().init( listParams );
        this._bg.addWidget( this._list, x, y );

        this._sep = new RectWidget().init( { w:listParams.w, h:ListRowWidget.DIVIDER_H, color:"#303030" } );
        this._sep.setA( 0 );
        this._bg.addWidget( this._sep, x, y - ListRowWidget.DIVIDER_H );

        this._timestampPageView = new Date(); // Localytics: 2.3.8

        return this;
    };

    FindChannelOverlay.prototype.onTextChanged = function( str )
    {
        console.log( ">" + str + "<" );

        this._searchStr = str;

        // The list initially defaults to empty
        // If user clears the search string empty the list
        if( str.length < 1 )
        {
            this._numResults = 0;
            this._list.setData( [] );
            this._sep.setA( 0 );
            this._icon.setA( 1 );
            this._noResultsStr.setA( 0 );
        }
        else if( this._dataReady === true )
            this.executeSearch( str );
    };

    FindChannelOverlay.prototype.processEvent = function( val, type )
    {
        var retval = false;

        switch( val )
        {
            case Host.KEY_LAST:
                // NOTE: retval is NOT set as we must var _x2.popOverlay() process
                this._durationPageView = new Date() - this._timestampPageView;
                window.ll( "tagEvent", "Back Button Find Channel Overlay", { "Duration": this._durationPageView } ); // Localytics 2.3.8
                _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Live TV: Back Button: Find Channel Overlay, duration: " + this._durationPageView ) : Config.NOP();
                break;

            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                {
                    this.speak( _x2.requestFocus( this._keyboard, false ) );
                    retval = true;
                }
                break;

            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._numResults )
                    {
                        this.speak( _x2.requestFocus( this._list, false ) );
                        retval = true;
                    }
                }
                break;
        }

        return retval;
    };

    FindChannelOverlay.prototype.speak = function( focus )
    {
        if( focus === this._keyboard )
        {
            this._speakTarget.setText( "Find Channels. " + this._text.getInstText() + ". " + focus._speakStr + ". " );
            this._speakTarget.setSpeechParams( "Go right for search results. Press back to dismiss.", focus._speakRole, this, false );
        }
    };

    return FindChannelOverlay;

})();
