// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.ScrollTestScreen = ( function()
{
    "use strict";

    ScrollTestScreen.prototype = new Screen();

    function ScrollTestScreen(){}

    ScrollTestScreen.prototype.init = function()
    {
        this._className = "ScrollTestScreen";

        Screen.prototype.init.call( this );

        var w = _x2._config._screenW;
        var h = _x2._config._screenH;

        this._bg = new RectWidget().init( { w:w, h:h, color:"#000000" } );
        this.addWidget( this._bg );

        var x1 = 100, y1 = 100;
        var w1 = w - 2*(Style._safeLeft + Style._safeRight ) - x1;
        var h1 = h - 2*(Style._safeTop  + Style._safeBottom) - y1;

        var x2 = Style._safeLeft, y2 = -y1 + 2*Style._safeLeft;
        var w2 = w1;
        var h2 = h1;

        var x3 = 2*Style._safeLeft, y3 = 4*Style._safeTop;
        var w3 = w2*0.75;
        var h3 = h2*0.75;

        this._contain = new Widget().init();

        this._rect1   = new RectWidget().init( { w:w1  , h:h1  , color:"#400000", borderThickness:2, borderColor:"#FF8080" } );
        this._rect2   = new RectWidget().init( { w:w2  , h:h2  , color:"#004000", borderThickness:2, borderColor:"#80FF80" } );
        this._rect3   = new RectWidget().init( { w:w3-4, h:h3-4, color:"#000040", borderThickness:2, borderColor:"#0000FF" } );
        this._rect1.setA( 0.5 );
        this._rect2.setA( 0.5 );
        this._rect3.setA( 0.5 );

        var path1 = { url: _x2._config._imageRoot    + "welcomeBg.jpg"    };
        //  path2 = { url: _x2._config._imageRootMso + "splashScreen.jpg" };
        this._image1 = new ImageWidget().init( path1 );
        this._contain.addWidget( this._image1 );

        this.         addWidget( this._rect2, x1+x2-2, y1+y2-2 );
        this.         addWidget( this._rect1, x1   -2, y1   -2 );

        // width, height, containerWidget, safeLeft, safeRight, safeTop, safeBottom, callback, onProgress
        this._scroll3 = new ScrollWidget().init( {w:w3, h:h3, widget:this._contain } );
        this._scroll2 = new ScrollWidget().init( {w:w2, h:h1, widget:this._scroll3 } );
        this._scroll1 = new ScrollWidget().init( {w:w1, h:h1, widget:this._scroll2 } );

        this._scroll3.addWidget( this._rect3,       0,       0 );

        this.addWidget( this._scroll1, x1, y1 );
        this._contain.setW( w2 );
        this._contain.setH( w3 );
        this._scroll3.setX( x3 );
        this._scroll3.setY( y3 );
        this._scroll2.setX( x2 );
        this._scroll2.setY( y2 );

        // Labels
        var fontSize = 16;

        var headStyle = new Style( { font:"light", color:"#007FFF", fontSize:fontSize, whiteSpace:"nowrap", overflow:"hidden" } );
        var bodyStyle = new Style( { font:"light", color:"#ffffff", fontSize:fontSize, whiteSpace:"nowrap", overflow:"hidden" } );
        var descStyle = new Style( { font:"light", color:"#FFFF00", fontSize:fontSize, whiteSpace:"nowrap", overflow:"hidden" } );

        this._string1 = new StringWidget().init( { style:headStyle } );
        this._string2 = new StringWidget().init( { style:headStyle } );
        this._string3 = new StringWidget().init( { style:headStyle } );
        this._string0 = new StringWidget().init( { style:bodyStyle } );

        this._desc1   = new StringWidget().init( { style:bodyStyle, text:"3 nested scroll panes" } );
        this._desc2   = new StringWidget().init( { style:bodyStyle, text:"Move Scroll Location:" } );
        this._desc3   = new StringWidget().init( { style:bodyStyle, text:"Move Scroll Contents:" } );

        this._keys2   = new StringWidget().init( { style:descStyle, text:"Up, Down, Left, Right" } );
        this._keys3   = new StringWidget().init( { style:descStyle, text:"1, 2, 3, 4"            } );

        this.addWidget( this._scroll1, x1, y1 );
        this.addWidget( this._string1, Style._safeLeft, Style._safeTop            );
        this.addWidget( this._string2, Style._safeLeft, Style._safeTop + fontSize );
        this.addWidget( this._string3, w*0.35         , Style._safeTop            );
        this.addWidget( this._string0, w*0.35         , Style._safeTop + fontSize );

        this.addWidget( this._desc1  , w*0.60         , Style._safeTop + fontSize*0 );
        this.addWidget( this._desc2  , w*0.60         , Style._safeTop + fontSize*1 );
        this.addWidget( this._desc3  , w*0.60         , Style._safeTop + fontSize*2 );

        this.addWidget( this._keys2  , w*0.75         , Style._safeTop + fontSize*1 );
        this.addWidget( this._keys3  , w*0.75         , Style._safeTop + fontSize*2 );

        this._x       = 0;
        this._y       = 0;

        this._offsetX = 0;
        this._offsetY = 0;

        this._selectable = true;

        return this;
    };

    ScrollTestScreen.prototype.layout = function()
    {
        var w = this._contain.getW();
        var h = this._contain.getH();

        this._string1.setText( "Scroll 1  @ " + Math.floor( this._scroll1.getX() ) + "," + Math.floor( this._scroll1.getY() ) );
        this._string2.setText( "Scroll 2  @ " + Math.floor( this._scroll2.getX() ) + "," + Math.floor( this._scroll2.getY() ) );
        this._string3.setText( "Scroll 3  @ " + Math.floor( this._scroll3.getX() ) + "," + Math.floor( this._scroll3.getY() ) );
        this._string0.setText( "Container @ " + Math.floor( this._contain.getX() ) + "," + Math.floor( this._contain.getY() ) + "  WxH: " + w + "x" + h );

        var x  = this._desc1.getX();
        var w1 = this._desc1.getW();
        var w2 = this._desc2.getW();
        var w3 = this._desc3.getW();

        w = w1;
        if( w2 > w ) w = w2;
        if( w3 > w ) w = w3;

        x += Style._pad + w;

        this._keys2.setX( x );
        this._keys3.setX( x );
    };

    ScrollTestScreen.prototype.processEvent = function( val, type )
    {
        var self   = this;
        var retval = true, w, h;

        var onEnd = function()
        {
            self.layout();
        };

        if( type === Host.KEY_PRESSED )
            switch( val )
            {
                // Toggle Alpha of nested scroll widget
                // WebGL verify stencil is being turned on/off for appropiate widgets
                case Host.KEY_ENTER:
                    var a = this._scroll2.getA();
                    this._scroll2.setA( 1 - a );
                    break;

                // scroll contents
                case Host.KEY_1:
                case Host.KEY_9:
                    w = this._contain.getW();
                    this._offsetX -= 25;
                    if( this._offsetX < -w )
                        this._offsetX = -w;

                    this.layout();
                    this._scroll3.setScroll( { x: this._offsetX, duration: 200, onEnd: onEnd } );
                    break;

                case Host.KEY_2:
                case Host.KEY_PAGE_DN:
                case Host.KEY_CH_DOWN:
                    h = this._contain.getH();
                    this._offsetY += 25;
                    if( this._offsetY > h )
                        this._offsetY = h;

                    this.layout();
                    this._scroll3.setScroll( { y: this._offsetY, duration: 200, onEnd: onEnd } );

                    break;

                case Host.KEY_3:
                case Host.KEY_PAGE_UP:
                case Host.KEY_CH_UP:
                    h = this._contain.getH();
                    this._offsetY -= 25;
                    if( this._offsetY < -h )
                        this._offsetY = -h;

                    this.layout();
                    this._scroll3.setScroll( { y: this._offsetY, duration: 200, onEnd: onEnd } );
                    break;

                case Host.KEY_4:
                case Host.KEY_0:
                    w = this._contain.getW();
                    this._offsetX += 25;
                    if( this._offsetX > w )
                        this._offsetX = w;

                    this.layout();
                    this._scroll3.setScroll( { x: this._offsetX, duration: 200, onEnd: onEnd } );
                    break;

                // move vertical scroll 2 left/right
                case Host.KEY_LEFT:
                    w = this._contain.getW() * 0.5;
                    this._x -= 75;
                    if( this._x < -w )
                        this._x = -w;

                    this.layout();
                    this._scroll2.setScroll( { x:this._x, duration: 200, onEnd: onEnd } );
                    break;

                case Host.KEY_RIGHT:
                    w = this._contain.getW() * 0.5;
                    this._x += 75;
                    if( this._x > w )
                        this._x = w;

                    this.layout();
                    this._scroll2.setScroll( { x: this._x, duration: 200, onEnd: onEnd } );
                    break;

                // move horizontal scroll 3 up/down
                case Host.KEY_UP:
                    h = this._contain.getH();
                    this._y -= 25;
                    if( this._y < -h )
                        this._y = -h;

                    this.layout();
                    this._scroll2.setScroll( { y: this._y, duration: 200, onEnd: onEnd } );
                    break;

                case Host.KEY_DOWN:
                    h = this._contain.getH();
                    this._y += 25;
                    if( this._y > h )
                        this._y = h;

                    this.layout();
                    this._scroll2.setScroll( { y: this._y, duration: 200, onEnd: onEnd } );
                    break;

                default:
                    retval = Screen.prototype.processEvent.call( this, val, type );
            }

        return retval;
    };

    ScrollTestScreen.prototype.resourcesLoaded = function()
    {
        this.layout();
    };

    return ScrollTestScreen;

})();
