// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PinSetOverlay = ( function()
{
    "use strict";

    PinSetOverlay.prototype = new Overlay();

    function PinSetOverlay(){}

    PinSetOverlay.prototype.gotFocus = function()
    {
        this._str.setSpeechParams( "Press back to dismiss.", undefined, this, false );
    };

    PinSetOverlay.prototype.init = function( params )
    {
        var self     = this, onLoad;
        var strStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap" } );

        Overlay.prototype.init.call( this, params );
        this._className  = "PinSetOverlay";
        this._numLoaded  = 0;

        onLoad = function() { self._numLoaded++; self.layoutPinSetOverlay(); };

        this._str = new StringWidget().init( { text:params.text, style:strStyle } );
        this._str.addReadyCallback( onLoad );
        this._bg.addWidget( this._str );

        this._icon = new ImageWidget().init( { url: _x2._config._imageRoot + "circleCheck.png", onLoad:onLoad } );
        this._bg.addWidget( this._icon );

        return this;
    };

    PinSetOverlay.prototype.layoutPinSetOverlay = function()
    {
        var spacing = _x2.scaleValInt( 32 );
        var pad     = _x2.scaleValInt( 80 );

        if( this._numLoaded === 2 )
        {
            this.setBgSize( 2 * pad + this._icon.getW() + spacing + this._str.getW(), 2 * pad + this._icon.getH() );

            this._icon.setX( pad );
            this._icon.setY( (this._bg.getH() - this._icon.getH()) / 2 );
            this._str.setX( this._icon.getX() + this._icon.getW() + spacing );
            this._str.setY( (this._bg.getH() - this._str.getH()) / 2 );
        }
    };

    return PinSetOverlay;

})();
