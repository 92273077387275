// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.Telemetry = ( function()
{
    "use strict";

    Telemetry.VideoType = Object.freeze
    ({
        CBS       :"CBS",
        DISNEY    :"Disney",
        ESPN      :"ESPN",
        HULU      :"Hulu",
        NBC       :"nbc",
        PURCHASE  :"PURCHASE",
        RECORDING :"RECORDING",
        T6_LINEAR :"T6 LINEAR",
        T6_VOD    :"T6 VOD",
        TVE_LINEAR:"TVE LINEAR",
        TVE_VOD   :"TVE VOD",
        NONE      :"Non Player", // ACTIVE_SESSION
        NA        :"NA"          // ERRORS_METRIC
    });

    /**
     * @param {String} inHome = token.tokenSummary.inHomeStatus
     */
    function Telemetry( inHome )
    {
        var self = this;

        _x2._config.debugAddClass( Config.CLASS_TELEMETRY, 0 );

        var metricsTimer = function()
        {
            if( self._metricsLastScreen )
            {
//              self._metricsLastScreen.ts = Date.now();
                self.send( self._metricsLastScreen );
            }
        };

        this._inHome = inHome;

        this.setVideoType();
        this.watchPathReset();

        _x2.addChangeCallback( metricsTimer );
    }

    Telemetry.prototype.errorPlayerVersion = function( functionName )
    {
        console.error( functionName + " player version not specified" );
    };

    Telemetry.prototype.errorVideoType = function( functionName )
    {
        console.error( functionName + " player video type not specified" );
    };

    Telemetry.prototype.getWatchButtonFullPath = function()
    {
        var retval = this._watchButtonSource;

        if( this._watchButtonPath && this._watchButtonPath.length )
        {
            retval = this._watchButtonPath.join( ": " );

            if( this._watchButtonSource )
                retval += ": " + this._watchButtonSource;
        }

        return retval;
    };

    /**
     * @see sendWatchButtonClicked(), setWatchButtonSource()
     */
    Telemetry.prototype.getWatchButtonSource = function()
    {
        return this._watchButtonSource;
    };

    Telemetry.prototype.send = function( obj )
    {
        if( obj )
        {
            if( obj.lType === undefined )
                obj.lType = "SMART";

            // Every obj should have smartEvent.parameters set
            if( obj.smartEvent.subSourceType === undefined )
                obj.smartEvent.subSourceType =
                [
                    "subSourceType",
                    "smart-tv"
                ];

            _x2._metricsSocket.send( obj );
        }
    };

    /**
     * @param {Widget}  widget
     * NOTE: setVideoType() must be called prior
     */
    Telemetry.prototype.sendActiveSession = function( widget )
    {
        var name = widget._telemetryName;

        if( name )
        {
            this._metricsLastScreen =
            {
                mType     : "xstream.ACTIVE_SESSION",
                smartEvent:
                {
                    parameters:
                    [
                        this._inHome,        // Key: inHomeStatus: "in-home" "out-of-home"
                        this._videoType,     // Key: playerVideoType: See Telemetry.VideoType
                        AboutOverlay.version // Key: appVersion: x.y.z.w
                    ]
                },
                ts: Date.now()
            };

            _x2._config.log( Config.CLASS_TELEMETRY, 9 ) ? console.log( this._metricsLastScreen ) : Config.NOP();
        }
        else
        {
            var whitelist = [ "Screen" ], warn = widget._className.indexOf( "TestScreen" ) < 0, i = 0;

            for( ; i < whitelist.length; i++ )
                if( warn && widget && widget._className && (widget._className === whitelist[ i ]))
                    warn = false;
            if( warn )
                console.warn( "Missing telemetry name for " + (widget && widget._className) );
        }
        _x2.localyticsSetAppVersionAndDeviceModel();
    };

    /**
     * @param {String             } category        -- AUTHENTICATION, VIDEO, XTVAPI
     * @param {String             } errorCode
     * @param {Boolean            } isUserFacing
     * @param {Telemetry.VideoType} playerVideoType -- RECORDING, TVE LINEAR, etc.
     * NOTE: If no video is playing `NA`
     */
     Telemetry.prototype.sendErrorsMetric = function( category, errorCode, isUserFacing, playerVideoType )
     {
         if( playerVideoType === undefined )
            return;

         if( playerVideoType === Telemetry.VideoType.NONE )
             playerVideoType = Telemetry.VideoType.NA;

         var obj =
         {
             mType     : "xstream.ERRORS_METRIC",
             smartEvent:
             {
                 parameters:
                 [
                     category,
                     errorCode,
                     isUserFacing,
                     playerVideoType
                 ]
             }
         };

         this.send( obj )
     };

    /*
     * @param {Number} seconds -- how long provisioning took
     * @param {String} type    -- "Silent" or "New Login"
     */
    Telemetry.prototype.sendProvisioning = function( seconds, type )
    {
        if( seconds === undefined )
            return;

        if( type === undefined )
            return;

        var obj =
        {
            mType     : "xstream.PROVISIONING",
            smartEvent:
            {
                parameters:
                [
                    seconds, // Key: seconds
                    type     // key: provisionType
                ]
            }
        };

        _x2._config.log( Config.CLASS_TELEMETRY, 8 ) ? console.log( "Telemetry PROVISIONING: %o", obj ) : Config.NOP();
        this.send( obj );
    };

    /**
     * @param {Number }  milliseconds -- Time taken to load
     * @param {String }  source       -- "Live TV: All Channels", etc.
     * @param {Boolean} [cached]
     */
    Telemetry.prototype.sendScreenLoadTime = function( milliseconds, source, cached )
    {
        if( milliseconds === undefined )
            return;

        if( source === undefined )
            return;

        if( cached === undefined )
            cached = false;

        var obj =
        {
            mType     : "xstream.SCREEN_LOAD_TIME",
            smartEvent:
            {
                parameters:
                [
                    milliseconds, // Key: Milliseconds
                    source,       // key: Screen
                    cached        // key: Cached
                ]
            }
        };

        _x2._config.log( Config.CLASS_TELEMETRY, 8 ) ? console.log( "Telemetry SCREEN_LOAD_TIME: %o", obj ) : Config.NOP();

        this.send( obj );
    };

    /*
     * @param {String} source -- "Live TV: All Channels", etc.
     */
    Telemetry.prototype.sendScreenViewed = function( source )
    {
        if( source === undefined )
            return;

        var obj =
        {
            mType     : "xstream.SCREEN_VIEWED",
            smartEvent:
            {
                parameters:
                [
                    source // key: Screen Name
                ]
            }
        };

        _x2._config.log( Config.CLASS_TELEMETRY, 8 ) ? console.log( "Telemetry SCREEN_VIEWED: %o", obj ) : Config.NOP();

        this.send( obj );
    };

    /*
     * @param {Telemetry.VideoType} playerVideoType
     */
    Telemetry.prototype.sendVideoAttempt = function( playerVideoType, playerVersion )
    {
        if( playerVideoType === undefined )
        {
            console.error( "sendVideoAttempt() player video type was not specified" );
            return;
        }

        if( playerVersion === undefined )
        {
            console.error( "sendVideoAttempt() player version unknown" );
            return;
        }

        var obj =
        {
            mType    : "xstream.VIDEO_ATTEMPT",
            smartEvent:
            {
                parameters:
                [
                    playerVideoType, // Key: playerVideoType
                    playerVersion    // Key: playerVersion
//                  streamProvider   // Key: streamProvider
                ]
            }
        };

        _x2._config.log( Config.CLASS_TELEMETRY, 6 ) ? console.log( "Telemetry VIDEO_ATTEMPT: %o", obj ) : Config.NOP();
        this.send( obj );
    };

    /**
     * @param {Boolean            } success         -- false = DENOMINATOR, true = NUMERATOR
     * @param {Telemetry.VideoType} playerVideoType -- RECORDING, TVE LINEAR, T6 LINEAR, T6 VOD, etc.
     * NOTE: You MUST send the DENOMINATOR first, before sending a NUMERATOR in order to keep the fraction values <= 1
     */
    Telemetry.prototype.sendVideoAttemptSuccess = function( success, playerVideoType )
    {
        if( playerVideoType === undefined )
            return this.errorVideoType( "sendVideoAttemptSuccess()" );

        var obj =
        {
            mType     : "xstream.VIDEO_ATTEMPT_SUCCESS_PERCENT", // dev: VASP_COAM_TEST
            smartEvent:
            {
                parameters: []
            },
            ts: Date.now()
        };

        obj.smartEvent.parameters[1] = "retired"; // isMidstream is deprecated
        obj.smartEvent.parameters[2] = playerVideoType;
        obj.smartEvent.parameters[3] = false;     // linearFreewheelsAds
        obj.smartEvent.parameters[4] = "NONE";    // adTypeServer is Android only

        if( success )
        {
            obj.requestId                = this._lastRequestId;
            obj.smartEvent.parameters[0] = "NUMERATOR";
            obj.ts                       = this._lastVaspTimestamp;
        }
        else
        {
            obj.requestId                = this._lastRequestId = "requestId" + _x2._metricsSocket.getRandomInt();
            obj.smartEvent.parameters[0] = "DENOMINATOR";
            this._lastVaspTimestamp      = obj.ts; // NOTE: Denominator is always sent first.  We cache and re-use the same timestamp so that the Numerator ends up in correct bucket
        }

        this.send( obj );
    };

    /*
     * @param {Telemetry.VideoType} playerVideoType -- RECORDING, TVE LINEAR, T6 LINEAR, T6 VOD, etc.
     * @param {Boolean            } isRetry
     * @param {Boolean            } isMidstream
     */
    Telemetry.prototype.sendVideoBufferComplete = function( playerVideoType, isRetry, isMidstream, isUserControlled )
    {
        if( playerVideoType === undefined )
            return this.errorVideoType( "sendVideoBufferComplete()" );

        var obj =
        {
            mType     : "xstream.VIDEO_BUFFER_COMPLETE",
            smartEvent:
            {
                parameters:
                [
                    playerVideoType,
                    isRetry,
                    isMidstream,
                    isUserControlled
                ]
            }
        };

        this.send( obj );
    };

    /*
     * @param {Telemetry.VideoType} playerVideoType -- RECORDING, TVE LINEAR, T6 LINEAR, T6 VOD, etc.
     * @param {Boolean            } isRetry
     * @param {Boolean            } isMidstream
     */
    Telemetry.prototype.sendVideoBufferStart = function( playerVideoType, isRetry, isMidstream, isUserControlled )
    {
        if( playerVideoType === undefined )
            return this.errorVideoType( "sendVideoBufferStart()" );

        var obj =
        {
            mType     : "xstream.VIDEO_BUFFER_START",
            smartEvent:
            {
                parameters:
                [
                    playerVideoType,
                    isRetry,
                    isMidstream,
                    isUserControlled
                ]
            }
        };

        this.send( obj );
    };

    /*
     * @param {Telemetry.VideoType} playerVideoType -- RECORDING, TVE LINEAR, T6 LINEAR, T6 VOD, etc.
     */
    Telemetry.prototype.sendVideoEnd = function( playerVideoType, playerVersion )
    {
        if( playerVideoType === undefined )
            return this.errorVideoType( "sendVideoEnd()" );

        if( playerVersion === undefined )
            return this.errorPlayerVersion( "sendVideoEnd()" );

        var obj =
        {
            mType     : "xstream.VIDEO_END",
            smartEvent:
            {
                parameters:
                [
                    playerVideoType, // Key: playerVideoType
                    playerVersion    // Key: playerVersion
//                  streamProvider   // Key: streamProvider
                ]
            }
        };

        this.send( obj );
    };

    /**
     * @param {String} errorCodeMajor   -- i.e. "10.1" or "NA"
     * @param {String} errorCodeMinor   -- i.e. "412"
     * @param {String} errorDescription --
     * @param {Telemetry.VideoType} playerVideoType  -- RECORDING, TVE LINEAR, T6 LINEAR, T6 VOD, etc.
     * @param {Boolean} isUserFacing
     */
    Telemetry.prototype.sendVideoError = function( errorCodeMajor, errorCodeMinor, errorDescription, playerVideoType, isUserFacing )
    {
        if( playerVideoType === undefined )
            return this.errorVideoType( "sendVideoError()" );

        var obj  =
        {
            mType     : "xstream.VIDEO_ERROR",
            smartEvent:
            {
                parameters:
                [
                    errorCodeMajor,
                    errorCodeMinor,
                    errorDescription,
                    playerVideoType,
                    isUserFacing
                ]
            }
        };

        this.send( obj );
    };

    /*
     * @param {Telemetry.VideoType} playerVideoType  -- RECORDING, TVE LINEAR, T6 LINEAR, T6 VOD, etc.
     * @param {Boolean            } isRetry
     * @param {Boolean            } isMidstream
     * @param {Boolean            } isUserControlled
     * @param {Boolean            } toBeginning
     */
    Telemetry.prototype.sendVideoJumpback = function( playerVideoType, isRetry, isMidstream, isUserControlled, toBeginning )
    {
        if( playerVideoType === undefined )
            return this.errorVideoType( "sendVideoJumpback()" );

        var obj  =
        {
            mType     : "xstream.VIDEO_JUMPBACK",
            smartEvent:
            {
                parameters:
                [
                    playerVideoType,
                    isRetry,
                    isMidstream,
                    isUserControlled,
                    toBeginning
                ]
            }
        };

        _x2._config.log( Config.CLASS_TELEMETRY, 8 ) ? console.log( "Telemetry VIDEO_JUMPBACK: %o", obj ) : Config.NOP();
        this.send( obj );
    };

    /* Time from VIDEO_ATTEMPT to VIDEO_START
     * @param {Number             } milliseconds
     * @param {Telemetry.VideoType} playerVideoType -- RECORDING, TVE LINEAR, T6 LINEAR, T6 VOD, etc.
     * @param {String             } playerVersion   -- see ViperPlayerPlatform getVersion()
     */
    Telemetry.prototype.sendVideoLatency = function( milliseconds, playerVideoType, playerVersion )
    {
        if( milliseconds === undefined )
            return console.error( "sendVideoLatency() milliseconds not specified" );

        if( playerVideoType === undefined )
            return this.errorVideoType( "sendVideoLatency()" );

        if( playerVersion === undefined )
            return this.errorPlayerVersion( "sendVideoLatency()" );

        var obj  =
        {
            mType     : "xstream.VIDEO_LATENCY",
            smartEvent:
            {
                parameters:
                [
                    milliseconds,
                    playerVideoType,
                    playerVersion
                ]
            }
        };

        _x2._config.log( Config.CLASS_TELEMETRY, 8 ) ? console.log( "Telemetry VIDEO_LATENCY: %o", obj ) : Config.NOP();
        this.send( obj );
    };

    /*
     * @param {Telemetry.VideoType} playerVideoType  -- RECORDING, TVE LINEAR, T6 LINEAR, T6 VOD, etc.
     * @param {Number             } manifestDuration -- The player duration in seconds
     * @param {Number             } metaDataDuration -- The recording duration in seconds
     */
    Telemetry.prototype.sendVideoPlaybackAvailableBucket = function( playerVideoType, manifestDuration, metaDataDuration, recordingManagerId )
    {
        if( playerVideoType === undefined )
            return this.errorVideoType( "sendVideoPlaybackAvailableBucket()" );

        if( metaDataDuration === undefined )
        {
            console.error( "sendVideoPlaybackAvailableBucket() recording duration not specified" );
            return;
        }

        if( metaDataDuration && manifestDuration )
        {
            if( manifestDuration > metaDataDuration )
                manifestDuration = metaDataDuration;

            var percent = 100 * manifestDuration / metaDataDuration;
            if( percent > 100 )
            {
console.error( "Warning: VPAB > 100%. Capping" );
                percent = 100;
            }
            else if( percent < 0 )
            {
console.error( "Warning: VPAB < 0%. Capping" );
                percent = 0;
            }

            var obj =
            {
                mType     : "xstream.VIDEO_PLAYBACK_AVAILABLE_BUCKET",
                smartEvent:
                {
                    parameters:
                    [
                        percent,
                        playerVideoType,
                        recordingManagerId
                    ]
                }
            };

            _x2._config.log( Config.CLASS_TELEMETRY, 8 ) ? console.log( "Telemetry VIDEO_PLAYBACK_AVAILABLE_BUCKET: %o", obj ) : Config.NOP();
            this.send( obj );
        }
    };

    /*
     * Sends the manifest and metadata durations
     * @param {Boolean            } isSuccess -- true = manifest & meta-data duration is sent, false = only meta-data duration is sent
     * @param {Number             } manifestDuration -- The player duration in seconds
     * @param {Number             } metaDataDuration -- The recording duration in seconds
     * @param {Telemetry.VideoType} playerVideoType  -- RECORDING, TVE LINEAR, T6 LINEAR, T6 VOD, etc.
     * @param {}
     */
    Telemetry.prototype.sendVideoPlaybackAvailablePercent = function( isSuccess, manifestDuration, metaDataDuration, playerVideoType, recordingManagerId )
    {
        if( playerVideoType === undefined )
            return this.errorVideoType( "sendVideoPlaybackAvailablePercent()" );

        if( manifestDuration === undefined )
        {
            console.error( "sendVideoPlaybackAvailablePercent() manifest duration not specified" );
            return;
        }

        if( recordingManagerId === undefined )
        {
            console.error( "sendVideoPlaybackAvailablePercent() recording manager id not specified" );
            return;
        }

        if( (manifestDuration !== undefined) && (metaDataDuration !== undefined) )
        {
            if( manifestDuration > metaDataDuration ) // AAEWT-826
                manifestDuration = metaDataDuration;  // Numerator
        }

        if( manifestDuration < 0 )
            manifestDuration = 0;

        if( metaDataDuration < 1 )
            metaDataDuration = 1; // Don't use zero to prevent div-by-zero

        var num = // NUMERATOR: manifest (player) duration in seconds
        {
            mType     : "xstream.VIDEO_PLAYBACK_AVAILABLE_PERCENT",
            smartEvent:
            {
                eventCount: manifestDuration,
                parameters:
                [
                    "NUMERATOR",       // Key:
                    playerVideoType,   // Key: playerVideoType
                    recordingManagerId // Key: recordingManagerId
                ]
            }
        };

        var den = // DENOMINATOR: metadata (recording) duration in seconds
        {
            mType     : "xstream.VIDEO_PLAYBACK_AVAILABLE_PERCENT",
            smartEvent:
            {
                eventCount: metaDataDuration,
                parameters:
                [
                    "DENOMINATOR",     // Key:
                    playerVideoType,   // Key: playerVideoType
                    recordingManagerId // Key: recordingManagerId
                ]
            }
        };

        _x2._config.log( Config.CLASS_TELEMETRY, 8 ) ? console.log( "Telemetry VIDEO_PLAYBACK_AVAILABLE_PERCENT numer: %o", num ) : Config.NOP();
        _x2._config.log( Config.CLASS_TELEMETRY, 8 ) ? console.log( "Telemetry VIDEO_PLAYBACK_AVAILABLE_PERCENT denom: %o", den ) : Config.NOP();

        // XC-18068
        var id = "requestId" + _x2._metricsSocket.getRandomInt();
        num.requestId = id;
        den.requestId = id;

        var ts = Date.now();
        num.ts = ts;
        den.ts = ts;

        if( isSuccess )
            this.send( num );

        this.send( den );
    };

    /** Sent once per streaming session.
     * @param {Telemetry.VideoType} playerVideoType -- RECORDING, TVE LINEAR, T6 LINEAR, T6 VOD, etc.
     * @param {String             } playerVersion   -- see ViperPlayerPlatform getVersion()
     * @param {Boolean            } isRetry
     */
    Telemetry.prototype.sendVideoStart = function( playerVideoType, playerVersion, isRetry )
    {
        if( playerVideoType === undefined )
            return this.errorVideoType( "sendVideoStart()" );

        if( playerVersion === undefined )
            return this.errorPlayerVersion( "sendVideoStart()" );

        if( isRetry === undefined )
            isRetry = false;

        var obj =
        {
            mType     : "xstream.VIDEO_START",
            smartEvent:
            {
                parameters:
                [
                    playerVideoType, // Key: playerVideoType
                    playerVersion,   // Key: playerVersion
                    "NA",            // Key: streamProvider
                    isRetry
                ]
            }
        };

        this.send( obj );
    };

    /** ACTIVE_SESSION requires the video type.
     *  When a user enters/exit video playback we need to update the current type.
     * @param {Telemetry.VideoType} [videoType] -- Valid to leave undefined
     */
    Telemetry.prototype.setVideoType = function( videoType )
    {
        this._videoType = videoType ? videoType : Telemetry.VideoType.NONE;
    };

    /**
     * @param {String             } source          -- "Browse Free", etc, see setWatchButton()
     * @param {Telemetry.VideoType} playerVideoType -- RECORDING, TVE LINEAR, etc.
     * @param {String             } playerVersion   -- see VideoStream ViperPlayerPlatform getVersion()
     * NOTE: Use setWatchButton() / getWatchButton() to save/get the source prior to VideoPlayer
     */
    Telemetry.prototype.sendWatchButtonClicked = function( source, playerVideoType, playerVersion )
    {
        if( source === undefined )
            return console.error( "sendWatchButtonClicked() invalid source" );

        if( playerVideoType === undefined )
            return this.errorVideoType( "sendWatchButtonClicked()" );

        if( playerVersion === undefined )
            return this.errorPlayerVersion( "sendWatchButtonClicked()" );

        var obj =
        {
            mType     : "xstream.WATCH_BUTTON_CLICKED",
            smartEvent:
            {
                parameters:
                [
                    source,
                    playerVideoType,
                    playerVersion
                ]
            }
        };

        if( source )
        {
            _x2._config.log( Config.CLASS_TELEMETRY, 8 ) ? console.log( "Telemetry WATCH_BUTTON_CLICKED: %o", obj ) : Config.NOP();
            this.send( obj );
        }
    };

    /* Save the category of the entity so video video player can send it when the entity video is played
     * @param {String} source -- Browse Free, Browse Kids, etc.
     * See: getWatchButtonSource(), sendWatchButtonClicked()
     */
    Telemetry.prototype.setWatchButton = function( source )
    {
        this._watchButtonSource = source;
    };

    Telemetry.prototype.watchPathDepth = function()
    {
        return this._watchButtonPath.length;
    };

    Telemetry.prototype.watchPathReset = function()
    {
        this._watchButtonPath = [];
    };

    Telemetry.prototype.watchPathPush = function( path )
    {
        if( path === undefined )
            console.error( "Invalid watch path" );
        else
            this._watchButtonPath.push( path );
    };

    Telemetry.prototype.watchPathPop = function()
    {
        this._watchButtonPath.pop();
    };

    /**
     * @param {Showing} - showing. One of LinearShowing, VodShowing, etc.
     */
    Telemetry.prototype.watchSourceClassify = function( showing )
    {
        var self          = this;
        var entity        = showing.getEntity();
        var promise;

        var onHaveProgram = function()
        {
            entity = showing.getEntity();
            onHaveEntity( entity );
        };

        var onFailProgram = function()
        {
            console.error( "Failed to get Entity: " + (self._showing.getTitle ? self._showing.getTitle() : "Unknown!") );
        };

        var onHaveEntity  = function( entity )
        {
            var type = entity.getType(), programType;

            switch( type )
            {
                case "Movie":
                case "s:Movie":
                    self.setWatchButton( "Entity Movie Info" );
                    break;
                case "Episode":
                case "s:TvSeries":
                case "SeriesMaster":
                    self.setWatchButton( "Entity Episodes" );
                    break;
                case "s:CreativeWork":
                    // intentional fall into default
                default: // "Other", "MusicVideo", "Sports"
                    programType = entity.getProgramType && entity.getProgramType(); // VOD: getType() == "Program" but getProgramType() -> "Movie"
                    if( programType && programType === "Movie"  )
                        self.setWatchButton( "Entity Movie Info" );
                    else
                        self.setWatchButton( "Entity Info" );
                    break;
            }
        };

        this._showing = showing;
        if( entity )
            onHaveEntity( entity );
        else
        {
            self.setWatchButton( "Entity Info" );

            if( showing instanceof LinearShowing )
                promise = showing.getDetail( false );
            else
                promise = showing.fetchProgram();

            promise.then( onHaveProgram ).catch( onFailProgram );
        }
    };

    return Telemetry;

})();
