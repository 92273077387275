// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * RecordingGroup aggregates a set of related recordings and associates them with a name.
 * A RecordingGroup is contained by a RecordingCollection, which in addition to RecordingGroups,
 * aggregates other structures including EntityRecordings (series recordings), Programs (series)
 * and SportsTeams, which have a 1:* relationship with the Recordings contained within.
 *
 */

window.RecordingGroup = (function()
{
    "use strict";

    var RecordingGroup = function(){};

    /**
     * Initialize this object
     * @param {Object} data - XTV derived JSON object
     * @return {RecordingGroup}
     */
    RecordingGroup.prototype.init = function( data )
    {
        this._data = data || {};

        return this;
    };

    /**
     * Add a recording to this group
     * @memberOf RecordingGroup
     * @param {Recording} recording
     */
    RecordingGroup.prototype.addRecording = function( recording )
    {
        if( !this._recordings )
            this._recordings = [];

        this._recordings.push( recording );
    };

    RecordingGroup.prototype.getLastRecordingDate = function()
    {
        var retval;

        if( this._data.lastRecorded )
            retval = new Date(this._data.lastRecorded);

        return retval;
    };

    /**
     * Get the name of this group
     * @memberOf RecordingGroup
     * @return {String}
     */
    RecordingGroup.prototype.getName = function()
    {
        return this._data.name;
    };

    /**
     * Get all recordings in this group
     * @memberOf RecordingGroup
     * @return {Array} {@link Recording} Array
     */
    RecordingGroup.prototype.getRecordings = function()
    {
        return this._recordings;
    };

    /**
     * Get the number of recordings in this group
     * @return {Number}
     */
    RecordingGroup.prototype.getRecordingsCount = function()
    {
        if( this._recordings )
            return this._recordings.length;
        else
            return this._data.numRecordings;
    };

    /**
     * Get the details of the recording in this group at given index
     * @memberOf RecordingGroup
     * @param {Number} index
     * @return {Promise}
     */
    RecordingGroup.prototype.getRecording = function( index )
    {
        var promise, self = this;

        if( ! this._data._recordings )
            this._data._recordings = [];

        if( this._data._recordings[index] )
            promise = Promise.resolve( this._data._recordings[index] );
        else
        {
            var resolver = function( resolve, reject )
            {
                var success = function( response )
                {
                    var recording = new Recording().init( JSON.parse( response.data ) );
                    self._data._recordings[index] = recording;
                    resolve( recording );
                };

                var error = function( error )
                {
                    reject( error );
                };

                var recordings = self._data._links.recordings;
                var url = recordings.length ? recordings[index].href : recordings.href ;

                url = XtvApi.trimPath( url );
                url = url.replace( /\#/g, '' );

                _x2._network.ajax({type: "GET", url: _x2._data._host + url, headers: [_x2._data._authHeader], accepts:Network.Accepts.xtv}).then(success).catch(error);
            };

            promise = new Promise( resolver );
        }

        return promise;
    };

    /**
     * Get a list of all IDs for recordings in this group
     * @memberOf RecordingGroup
     * @return {Array} {String} Array
     */
    RecordingGroup.prototype.getRecordingIds = function()
    {
        var recordings, tokens, i;

        if( !this._recordingIds )
        {
            this._recordingIds = [];

            if( this._data._links && this._data._links.recordings )
            {
                recordings = this._data._links.recordings;

                if( recordings instanceof Array )
                {
                    for( i = 0; i < recordings.length; i++ )
                    {
                        tokens = recordings[i].href.split( '/' );  //TODO: a cleaner source of IDs?

                        if( tokens && tokens.length > 2 )
                            this._recordingIds.push( tokens[tokens.length - 2] )
                    }
                }
                else
                {
                    tokens = recordings.href.split( '/' );  //TODO: a cleaner source of IDs?

                    if( tokens && tokens.length > 2 )
                        this._recordingIds.push( tokens[tokens.length - 2] )
                }
            }
        }

        return this._recordingIds;
    };

    /**
     * Get the program associated with this recording.
     * @return {Promise.<Program>}
     */
    RecordingGroup.prototype.getProgram = function()
    {
        var promise, self = this;

        if( this._program )
            promise = Promise.resolve( this._program );
        else if( this._data._embedded && this._data._embedded.entity )
        {
            this._program = new Entity().init( this._data._embedded.entity );
            promise = Promise.resolve( this._program)
        }
        else
        {
            var resolver = function( resolve, reject )
            {
                var success = function( response )
                {
                    self._program = new Entity().init( JSON.parse( response.data ) );
                    resolve( self._program );
                };

                var error = function( error )
                {
                    reject( error );
                };

                var url, links = self._data._links;
                if( links )
                {
                    url = self._data._links.entity;
                    if( url )
                    {
                        if( url.href )
                        {
                            url = XtvApi.trimPath( url.href );
                            url = url.replace( /\#/g, '' );
                            _x2._network.ajax( { type: "GET", url: _x2._data._host + url, headers: [_x2._data._authHeader], accepts: Network.Accepts.xtv } ).then( success ).catch( error );
                        }
                    }
                }
            };

            promise = new Promise( resolver );
        }

        return promise;
    };

    RecordingGroup.prototype.removeRecording = function( recording )
    {
        var i, id = recording.getId();

        for( i = 0; i < this._recordings.length; i++ )
        {
            if( this._recordings[i].getId() === id )
            {
                this._recordings.splice(i,1);
                break;
            }
        }
    };

    RecordingGroup.prototype.getType = function()
    {
        return this._data.groupType;
    };

    return RecordingGroup;
})();
