// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * PurchaseCollection is the top-level object which contains PurchaseGroup, Series and Purchase objects.
 * Maps to XTVAPI s:Enumeration/Purchases
 */
window.PurchaseCollection = (function()
{
    "use strict";

    function PurchaseCollection(){}

    PurchaseCollection.prototype.init = function( data )
    {
        this._data = data;

        return this;
    };

    PurchaseCollection.prototype.getMovies = function()
    {
        var i, movies = [], groups = this.getPurchaseGroups();

        for( i=0; i<groups.length; i++ )
        {
            if( groups[i].getEntityType() === XtvApi.EntityType.MOVIE )
                movies.push( groups[i] );
        }

        return new Collection().init( movies, "Purchased Movies" )
    };

    PurchaseCollection.prototype.getTvSeries = function()
    {
        var i, series = [], groups = this.getPurchaseGroups();

        for( i=0; i<groups.length; i++ )
        {
            if( groups[i].getEntityType() === XtvApi.EntityType.EPISODE )
                series.push( groups[i] );
        }

        return new Collection().init( series, "Purchased TV Shows" )
    };

    PurchaseCollection.prototype.getPurchaseGroups = function( sort )
    {
        if( ! this._groups )
        {
            this._groups = [];

            var i, j, k, s, ids, group, purchase, program, link, tokens, sid, sortedGroups;
            var purchasesAry = [];
            var seriesMap     = {};
            var purchases     = this._data._embedded.purchases;
            var groups        = this._data._embedded.purchaseGroups;
            var series        = this._data._embedded.series;

            //create a hashmap of series
            for( i=0; i<series.length; i++ )
            {
                s = new Entity().init( series[i] );
                sid = s.getEntityId();
                seriesMap[sid] = s;
            }

            //create the purchase (ProgramEntity) object, assoc w/series and add to collection
            for( i = 0; i < purchases.length; i++ )
            {
                purchase = new Purchase().init( purchases[i] );
                program  = purchase.getEntity();

                if( program )
                {
                    if( program.getType() === XtvApi.EntityType.EPISODE )
                    {
                        link = program.getTvSeriesLink();
                        if( link )
                            tokens = link.split('/');

                        if( tokens && tokens.length )
                            sid = tokens[tokens.length-2];

                        if( sid && seriesMap[sid] )
                            purchase.setSeries( seriesMap[sid] );
                    }
                }

                purchasesAry.push( purchase );
            }

            //add purchases to the correct group
            for( i = 0; i < groups.length; i++ )
            {
                group = new PurchaseGroup().init( groups[i] );
                ids   = group.getPurchaseIds();

                if( ids.length )
                {
                    for( j = 0; j < ids.length; j++ )
                    {
                        for( k = 0; k < purchasesAry.length; k++ )
                        {
                            if( ids[j] === purchasesAry[k].getId() )
                            {
                                purchase = purchasesAry.splice( k, 1 )[0];
                                purchase.setGroup( group );
                                group.addPurchase( purchase );
                                break;
                            }
                        }
                    }
                }

                if( group.getPurchaseCount() > 0 )
                    this._groups.push( group );
            }
        }

        if( sort )
        {
            var sortMap, sortGroups = this._groups;

            if( sort === 'recent' )
            {
                sortMap = sortGroups.map( function( group, i )
                {
                    return ( { index:i, value:group.getPurchasedOnDate().getTime() } );
                });

                sortMap.sort( function( a, b )
                {
                    return a.value > b.value ? -1 : 1;
                });
            }
            else if( sort === 'a-z' )
            {
                sortMap = sortGroups.map( function( group, i )
                {
                    return ( { index:i, value:group.getTitle().toLowerCase() } );
                });

                sortMap.sort( function( a, b )
                {
                    return a.value > b.value ?  1 : -1;
                });
            }

            sortedGroups = sortMap.map( function( element )
            {
                return sortGroups[element.index]
            });
        }

        return sortedGroups ? sortedGroups : this._groups;
    };

    PurchaseCollection.prototype.getTitle = function()
    {
        return this._data.name;
    };

    return PurchaseCollection;
})();
