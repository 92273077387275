// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.Config = (function()
{
    "use strict";

    var defaultConfigServer = 'https://config.xpp.tv.xfinity.com/';

    Config.ANI_TIME_HORIZ_MENU  = 300;

    Config.AUTH_CIMA            = 0;
    Config.AUTH_CISCO_SAML      = 1;
    Config.AUTH_NONE            = 2;
    Config.AUTH_OAUTH           = 3;
    Config.AUTH_XTV             = 4;

    Config.CLASS_CONFIG         = 0;
    Config.CLASS_GRID           = 1;
    Config.CLASS_LOCALYTICS     = 2;
    Config.CLASS_NETWORK        = 3;
    Config.CLASS_OAUTH          = 4;
    Config.CLASS_SPLUNK         = 5;
    Config.CLASS_TELEMETRY      = 6;
    Config.CLASS_VIDEO_SCREEN   = 7;
    Config.CLASS_VIPER          = 8;
    Config.CLASS_WEBGL          = 9;
    Config.CLASS_X2             = 10;
    Config.CLASS_XTV_AUTH       = 11;

    Config.MODE_CLOUD           = 0;
    Config.MODE_RUI             = 1;
    Config.MODE_TEST            = 2;
    Config.MODE_UHD             = 3;

    Config.NOP                  = function(){};

    Config.RENDER_DOM           = 0;
    Config.RENDER_WEBGL         = 1;

    Config.RES_480              = 0;
    Config.RES_540              = 1;
    Config.RES_720              = 2;
    Config.RES_1080             = 3;

    Config.default    = {
                            accessibility    : [ "captions", "captionOptions", "videoDesc" ],
                            authEndpoint     : "https://clientservices.rialto.ccp.xcal.tv/clientservices/cima",
//                            authType       : Config.AUTH_OAUTH,
                            authType         : Config.AUTH_XTV,
//                            authType       : Config.AUTH_NONE,
                            guideMenu        : [ "all", "favs", "kids", "sports", "movies" ],
                            imageRootMso     : "resources/images/comcast/",
                            initialScreen    : "mainMenu",
                            localyticsKey    : environment.ENV === 'prod' ? 'ea1410fa675877c8e810f1d-98573a50-0a5d-11e7-24d6-002a25e53804' : '06de8712cae5f02bb138080-6cc7ff96-0a5d-11e7-eaa4-004ca8463ac9',
                            parentalControls : [ "enable", "modify", "safeBrowse", "movieRatings", "tvRatings", "reset" ],
                            sapLanguages     : [ "en", "es", "french", "cafrench", "german", "italian", "portuguese" ],
                            mainMenu         : [ "guide", "saved", "onDemand", "search", "settings" ],
                            manage           : [ "deviceName", "deviceDownloads", "deviceStreams" ],
                            purchasePin      : [ "enable", "modify" ],
                            savedMenu        : [ "forYou", "recordings", "scheduled", "favorites", "purchases" ],
//                            settings       : [ "account", "preferences", "device", "language", "parental", "accessibility", "help", "notices", "about" ],
//                            settings       : [ "language", "parental", "accessibility", "manage", "help", "terms", "about", "signOut" ],
                            settings       : [ "format", "language", "parental", "accessibility", "help", "terms", "privacy", "about", "signOut" ],
                            parent         : undefined
                        };

    Config.ruiCox     = {
                            imageRootMso   : "resources/images/cox/"
                        };

    Config.rui        = {
                            authEndpoint   : "https://clientservices.rialto.ccp.xcal.tv/clientservices/cima",
                            authType       : Config.AUTH_CIMA,
                            initialScreen  : "mainMenu",
                            mainMenu       : [ "saved", "guide", "settings", "search" ],
                            parent         : Config.default
                        };

    Config.ruiCox     = {
                            authEndpoint   : "https://lb01-cox-prod-int.cisco.com/mobileactivationservice/accounts/users/login/saml",
                            authType       : Config.AUTH_CISCO_SAML,
                            imageRootMso   : "resources/images/cox/",
                            parent         : Config.rui,
                            usernameSuffix : "@cox.net"
                        };

    Config.ruiCoxDev  = {
                            authEndpoint   : "https://x1-cox-int.cisco.com/mobileactivationservice/accounts/users/login/saml",
                            parent         : Config.ruiCox,
                            usernameSuffix : "@dev.cox.net"
                        };

    Config.test       = {
                            authType       : Config.AUTH_NONE,
                            initialScreen  : "test",
                            parent         : Config.default

                        };

    Config.uhd        = {
                            authEndpoint   : "https://clientservices.rialto.ccp.xcal.tv/clientservices/cima",
//                            authType       : Config.AUTH_CIMA,
//                            authType       : Config.AUTH_XTV,
                            authType       : Config.AUTH_OAUTH,
                            initialScreen  : "mainMenu",
                            mainMenu       : [ "onDemand", "settings" ],
                            parent         : Config.default
                        };
    //To check where app is running in simulator instead of smart tv.
    Config.isTizenSimulator = false;
    Config.isWebOsSimulator = false;

    /**
     * @constructor
     * Use _x2._config._screenW and _x2._config._screenH to get the screen dimensions
     */
    function Config( debugEnabled )
    {
        // default config values

        this.setSize( "720" );

        this._enabled = debugEnabled;
        this._classes = [];

        // language files

        this._english = "resources/languages/English.json";
        this._spanish = "resources/languages/Spanish.json";

        // metadata sources

        this._thePlatformRoot = "http://feed.theplatform.com/f/tyO8VC/Samsung?form=json";
//        this._xtvApiRoot      = "https://xtvapi-staging.cloudtv.comcast.net/";
        this._xtvApiRoot      = "https://xtvapi.cloudtv.comcast.net/";
        this._xtvAuthRoot     = "https://xtvapi.cloudtv.comcast.net/";

        this.debugAddClass( Config.CLASS_CONFIG, 0 );
    }

    Config.prototype.debugAddClass = function( classVal, level )
    {
        this._classes[classVal] = level;
    };

    Config.prototype.debugEnable = function( val )
    {
        this._enabled = val;
    };

    /**
     * @memberof Config
     */
    Config.prototype.getValue = function( primaryConfig, fieldName )
    {
        var config = primaryConfig;
        var retval = primaryConfig[fieldName];

        while( retval === undefined && config["parent"]  )
        {
            config = config["parent"];
            retval = config[fieldName];
        }

        return retval;
    };

    /**
     * @memberof Config
     */
    Config.prototype.load = function( onLoaded )
    {
        var self         = this;
        var configServer = this._configServer ? this._configServer : defaultConfigServer;

        function onConfig( response )
        {
            var data = JSON.parse( response.data );

            console.log( data );

            /**
             * @property {object}  data
             * @property {string} [data.authHost]
             * @property {string} [data.licenseServer]
             * @property {string} [data.provHost]
             * @property {string} [data.xtvApi]
             * @property {string} [data.testVideo]
             */

            if( data.authHost )
                self._authHost = data.authHost;

            if( data.licenseServer )
                self._licenseServer = data.licenseServer;

            if( data.provHost )
                self._provHost = data.provHost;

            console.log( self._authHost, self._provHost, self._licenseServer );

            if( data.xtvApi )
            {
                self._xtvApiRoot  = data.xtvApi;
                self._xtvAuthRoot = data.xtvApi;
            }

            if( data.testVideo )
                self._testVideo = data.testVideo;

            onLoaded();
        }

        function onError( error )
        {
             console.error( "ERROR -> Unable to load config from server [" + error.message + "]. Using defaults.");
             onLoaded();
        }

        function onMapping( response )
        {
            if( response )
            {
                var data   = JSON.parse( response.data );
                var mac    = self._mac;
                var record = data[mac];

                if( record === undefined )
                    record = data[_x2._config._host.getPlatformString()];
                if(_x2._config._loadConfigId) {
                    record = data[_x2._config._loadConfigId];
                }
                if( record )
                {
                    console.log( "Found a device mapping -> " + record );

                    if( record.substr( 0, 4 ) === "http" )
                        _x2._network.get( record ).then( onConfig ).catch( onError );
                    else
                        _x2._network.get( configServer + record ).then( onConfig ).catch( onError );
                }
                else
                {
                    console.log( "No mapping found" );
                    onNoDeviceConfig.call(this);
                }
            }
            else
                onNoDeviceConfig.call(this);
        }

        function onNoDeviceConfig()
        {
          var configServer = this._configServer ? this._configServer : defaultConfigServer,
              type = _x2._config._host.getType(),
              configUrl;

          switch(type) {
            case Host.Type.ANDROID:
              onError(new Error('No remote config for ANDROID host type'));
              return;
            case Host.Type.LG:
              configUrl = configServer + 'jenkins/prod-lg.json';
              break;
            case Host.Type.TIZEN:
              configUrl = configServer + 'jenkins/prod-samsung.json';
              break;
            case Host.Type.PC:
              onError(new Error('No remote config for PC host type'));
              return;
          }
          _x2._network.get(configUrl).then( onConfig ).catch( onError );
        }
        var hostType = _x2._config._host.getType();
        if( (hostType === Host.Type.PC || hostType == Host.Type.ANDROID) && !_x2._config._loadConfigId ) {
            onError(new Error('No remote config for PC host type'));
        } else {
            _x2._network.get( configServer + "mapping.json", 5000 ).then( onMapping ).catch( onNoDeviceConfig.bind(this) );
        }//if it is PC or android, then we avoid delay of around 5 seconds on waiting for mapping.json
    };

    Config.prototype.log = function( classVal, level )
    {
        var retval = false;

        if( this._enabled === true )
        {
            if( classVal === undefined )
            {
                console.error( "ERROR -> Using a debug class that was not defined in Config (Config.CLASS_???)" );
                console.trace();
            }
            else
            {
                if( this._classes[classVal] === undefined )
                {
                    console.error( "ERROR -> debugAddClass() was not set for debug class id = " + classVal );
                    console.trace();
                }
                else if( this._classes[classVal] >= level )
                    retval = true;
            }
        }

        return retval; //( this._enabled === true && this._classes[classVal] && this._classes[classVal] >= level ) ? true : false;
    };

    /**
     * @memberof Config
     */
    Config.prototype.setAppMode = function( mode, partner )
    {
        this._mode    = mode;
        this._partner = partner ? partner : "Comcast";

        this._localyticsKey = this.getValue(Config.default, 'localyticsKey');
        switch( mode )
        {
            case Config.MODE_CLOUD:
                this._accessibilityMenu    = this.getValue( Config.default, "accessibility"    );
                this._authEndpoint         = this.getValue( Config.default, "authEndpoint"     );
                this._authHost             = "https://xacs.ccp.xcal.tv";
                this._authMode             = Config.default.authType;
                this._guideMenu            = this.getValue( Config.default, "guideMenu"        );
                this._imageRootMso         = this.getValue( Config.default, "imageRootMso"     ) + this._screenH + "/";
                this._initialScreen        = this.getValue( Config.default, "initialScreen"    );
                this._licenseServer        = "https://mds.ccp.xcal.tv";
                this._manageDevicesMenu    = this.getValue( Config.default, "manage"           );
                this._parentalControlsMenu = this.getValue( Config.default, "parentalControls" );
                this._provHost             = "https://xacsa.ccp.xcal.tv";
                this._purchasePinMenu      = this.getValue( Config.default, "purchasePin"      );
                this._sapLanguages         = this.getValue( Config.default, "sapLanguages"     );
                this._mainMenu             = this.getValue( Config.default, "mainMenu"         );
                this._savedMenu            = this.getValue( Config.default, "savedMenu"        );
                this._settingsMenu         = this.getValue( Config.default, "settings"         );
                this._xcalEndPoints        = {
                    authenticationActivationUrl: 'https://xacs-activation-prod.cs.xcal.tv',
                    authenticationRenewalUrl: 'https://xacs-renewal-prod.cs.xcal.tv',
                    deviceProvisionUrl: 'https://xacsa.ccp.xcal.tv',
                    licenseAcquisitionUrl: 'https://mds.ccp.xcal.tv'
                };

                switch( partner )
                {
                    case "Cox":
                        this._imageRootMso = this.getValue( Config.ruiCox, "imageRootMso" ) + this._screenH + "/";
                        break;

                    case "Rogers":
                        this._imageRootMso = "resources/images/rogers/" + this._screenH + "/";
                        break;

                    case "Shaw":
                        this._imageRootMso = "resources/images/shaw/" + this._screenH + "/";
                        break;

                    default:
                        console.error( "Unknown partner = " + partner );
                        break;
                }
                break;

            case Config.MODE_RUI:
                switch( partner )
                {
                    case "cox":
                        this._authEndpoint   = this.getValue( Config.ruiCox, "authEndpoint"   );
                        this._authMode       = this.getValue( Config.ruiCox, "authType"       );
                        this._imageRootMso   = this.getValue( Config.ruiCox, "imageRootMso"   ) + this._screenH + "/";
                        this._initialScreen  = this.getValue( Config.ruiCox, "initialScreen"  );
                        this._usernameSuffix = this.getValue( Config.ruiCox, "usernameSuffix" );
                        break;

                    case "cox-dev":
                        this._authEndpoint   = this.getValue( Config.ruiCoxDev, "authEndpoint"   );
                        this._authMode       = this.getValue( Config.ruiCoxDev, "authType"       );
                        this._imageRootMso   = this.getValue( Config.ruiCoxDev, "imageRootMso"   ) + this._screenH + "/";
                        this._initialScreen  = this.getValue( Config.ruiCoxDev, "initialScreen"  );
                        this._usernameSuffix = this.getValue( Config.ruiCoxDev, "usernameSuffix" );
                        break;

                    default:
                        this._authEndpoint   = this.getValue( Config.rui, "authEndpoint"   );
                        this._authMode       = this.getValue( Config.rui, "authType"       );
                        this._imageRootMso   = this.getValue( Config.rui, "imageRootMso"   ) + this._screenH + "/";
                        this._initialScreen  = this.getValue( Config.uhd, "initialScreen"  );
                        this._usernameSuffix = this.getValue( Config.rui, "usernameSuffix" );
                        break;
                }
                break;

            case Config.MODE_TEST:
//                this._authEndpoint  = this.getValue( Config.test, "authEndpoint"  );
                this._authMode      = this.getValue( Config.test, "authType"      );
                this._imageRootMso  = this.getValue( Config.test, "imageRootMso"  ) + this._screenH + "/";
                this._initialScreen = this.getValue( Config.test, "initialScreen" );
                break;

            case Config.MODE_UHD:
                this._authEndpoint  = this.getValue( Config.uhd, "authEndpoint"  );
                this._authMode      = this.getValue( Config.uhd, "authType"      );
                this._imageRootMso  = this.getValue( Config.uhd, "imageRootMso"  ) + this._screenH + "/";
                this._initialScreen = this.getValue( Config.uhd, "initialScreen" );
                this._mainMenu      = this.getValue( Config.uhd, "mainMenu"      );
                break;

            default:
                console.error( "ERROR -> setting app mode to unrecognized value = " + mode );
                break;
        }
    };

    Config.prototype.setSize = function( size )
    {
        switch( size )
        {
            case "480":
            case "sd":
                this._screenW       = 640;
                this._screenH       = 480;
                this._layout        = Config.RES_480;
                this._imageRoot     = "resources/images/480/";
                this._imageRootTest = "resources/images/test/480/";
                break;

            case "540":
            case "lhd":
                this._screenW       = 960;
                this._screenH       = 540;
                this._layout        = Config.RES_540;
                this._imageRoot     = "resources/images/540/";
                this._imageRootTest = "resources/images/test/480/";
                break;

            case "720":
            case "hd":
                this._screenW       = 1280;
                this._screenH       =  720;
                this._layout        = Config.RES_720;
                this._imageRoot     = "resources/images/720/";
//                this._imageRootMso  = this.getValue( Config.default, "imageRootMso"  ) + "720/";
                this._imageRootTest = "resources/images/test/480/";
                break;

            case "1080":
            case "hhd":
                this._screenW       = 1920;
                this._screenH       = 1080;
                this._layout        = Config.RES_1080;
                this._imageRoot     = "resources/images/1080/";
//                this._imageRootMso  = this.getValue( Config.default, "imageRootMso"  ) + "1080/";
                this._imageRootTest = "resources/images/test/480/";
                break;

            default:
                console.log( "ERROR -> unrecognized size = " + size );
                break;
        }

        if( this._mode !== undefined )
            this.setAppMode( this._mode, this._partner );
    };

    return Config;

})();
