// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Base class for all types which represent XTVAPI data.
 */
window.DataObject = (function()
{
    "use strict";

    function DataObject(){}

    DataObject.prototype.init = function( data )
    {
        this._data = data;
    };

    DataObject.prototype.addPath = function( keys, object )
    {
        var i, path, key;

        if( this._data )
        {
            path = this._data;

            for( i=0; i<keys.length; i++ )
            {
                key = keys[i];

                if( i === keys.length - 1 )
                    path[key] = object;
                else
                {
                    if( ! path[key] )
                        path[key] = {};
                    path = path[key];
                }
            }
        }
    };

    DataObject.prototype.get = function( key )
    {
        if( this._data )
            return this._data[key];
    };

    DataObject.prototype.getDate = function( key )
    {
        var data = this.get(key);
        var value = !isNaN(data) ? parseInt(data) : data; //handle both "1559050403302" and 1559050403302

        if( value )
            return new Date(value);
    };

    DataObject.prototype.getMeta = function()
    {
        return this._meta || {};
    };

    DataObject.prototype.getPath = function( keys, object )
    {
        var i, value, root = object ? object : this._data;

        if( root )
        {
            value = root;
            for( i=0; i<keys.length; i++ )
            {
                value = value[ keys[i] ];
                if( value === undefined )
                    break;
            }
        }

        return value;
    };

    DataObject.prototype.getLink = function( key, params )
    {
        var link;

        if( this._data && this._data._links )
        {
            if( this._data._links[key] )
            {
                link = this._data._links[key].href;

                if( params )
                    link = XtvApi.replaceUrlParams( link, params );
            }
        }

        return link;
    };

    DataObject.prototype.getLinkObject = function( key )
    {
        if( this._data && this._data._links )
            if( this._data._links[key] )
                return new Link().init( this._data._links[key] );
    };

    DataObject.prototype.getEmbedded = function( key )
    {
        if( this._data && this._data._embedded )
            return this._data._embedded[key];
    };

    DataObject.prototype.getForm = function( key )
    {
        var form;

        if( this._data && this._data._forms )
        {
            if( this._data._forms[key] )
                form = new Form().init(this._data._forms[key], key);
        }

        return form;
    };

    DataObject.prototype.getBoolean = function( key )
    {
        return !!this.get( key );
    };

    DataObject.prototype.getInteger = function( key )
    {
        var value = -1;

        if( this._data )
        {
            value = this._data[key];

            if( value && !isNaN( value ) )
                value = parseInt( value );
        }

        return value;
    };

    DataObject.prototype.getByPriority = function( a, b, c )
    {
        var value = this._data[a];

        if( value === undefined && b )
            value = this._data[b];

        if( value === undefined && c )
            value = this._data[c];

        return value;
    };

    DataObject.prototype.getData = function()
    {
        return this._data;
    };

    DataObject.prototype.removePath = function( keys )
    {
        var i, key, path;

        if( this._data )
        {
            path = this._data;
            for( i=0; i<keys.length; i++ )
            {
                key = keys[i];
                if( path[key] === undefined )
                    break;
                else
                {
                    if( i === keys.length - 1 )
                        path[key] = undefined;
                    else
                        path = path[key];
                }
            }
        }
    };

    DataObject.prototype.setMeta = function( meta )
    {
        this._meta = meta;
    };

    return DataObject;
})();
