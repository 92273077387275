// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PurchaseOfferRowWidget = (function()
{
    "use strict";

    PurchaseOfferRowWidget.prototype = new ListRowWidget();

    function PurchaseOfferRowWidget(){}

    PurchaseOfferRowWidget.prototype.init = function( data )
    {
        ListRowWidget.prototype.init.call( this, data );
        this._className = "PurchaseOfferRowWidget";

        this._entity         = data.obj.entity;
        this._optionsOverlay = data.obj.overlay;
        this._onBuy          = data.obj.onBuy;
        this._metricsBuyOptionClicked =
        {
            "Program Type": "",
            "Option"      : "",
            "Modal Type"  : "Transactional",
            "Tab Clicked" : "No" // Web has tabbed UI for HD and SD; we have a unified single list
        };

        var titleStyle    = new Style( { color:"#e8e8e8", font:"light", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#2b9cd8" } );
        var subTitleStyle = new Style( { color:"#bababa", font:"light", fontSize:_x2.scaleValInt(20), whiteSpace:"nowrap" } );

        this._vertOffsets = [_x2.scaleValInt(18), _x2.scaleValInt(35), _x2.scaleValInt(60)];

        this._title    = new StringWidget().init({ style:titleStyle, text:"" });
        this._subTitle = new StringWidget().init({ style:subTitleStyle, text:"" });

        this.addWidget( this._title   , 0, 0 );
        this.addWidget( this._subTitle, 0, 0 );

        return this;
    };

    PurchaseOfferRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 110 );
    };

    PurchaseOfferRowWidget.prototype.gotFocus = function( lostFocus, newScreen )
    {
        ListRowWidget.prototype.gotFocus.call( this, lostFocus );

        var entity    = this._entity,
            offer     = this._offer,
            infoStr   = "",
            speechStr = "",
            rating    = offer.getRating(),
            year      = entity.getYear(),
            isHd      = offer.isHD(),
            isSap     = offer.isSap(),
            isCc      = offer.isClosedCaptioned(),
            duration  = offer.getDuration(),
            subTitle  = this._subTitle.getText(),
            isSeason  = offer.isSeasonBundle(),
            type      = entity.getType(),
            metrics   = offer.getLocalyticsCommonTransactional( type );

        if( subTitle )
            speechStr += subTitle + ". ";

        this._metricsBuyOptionClicked["Program Type"] = metrics.programType;
        this._metricsBuyOptionClicked.Option          = metrics.option;

        if( type === XtvApi.EntityType.EPISODE )
        {
            var season  = entity.getSeasonNumber(),
                episode = entity.getEpisodeNumber(),
                title   = entity.getTitle();

            if( isSeason === true )
            {
                infoStr   += "Season " + entity.getSeasonNumber() + " (" + offer.getNumberOfFeatures() + " Episodes) ";
                speechStr += "Season " + entity.getSeasonNumber() + ". " + offer.getNumberOfFeatures() + " Episodes. ";
            }
            else
            {
                if( season !== undefined && episode !== undefined )
                {
                    infoStr   += "S" + season + " Ep" + episode;
                    speechStr += "season " + season + ". episode " + episode + ". ";
                }

                if( title )
                {
                    if( infoStr.length > 0 )
                        infoStr += " - ";

                    infoStr   += title + ", ";
                    speechStr += " " + title + ". ";
                }
            }
        }
        else
        {
            if( year !== undefined )
            {
                infoStr   += "(" + year + ") ";
                speechStr += year + ". ";
            }
        }

        if( duration > 0 )
        {
            infoStr   += duration + "min ";
            speechStr += duration + " minutes. ";
        }

        if( rating )
        {
            infoStr   += Strings.Glyphs[rating] + " ";
            speechStr += "Rating " + rating + ". ";
        }
        if( isHd )
        {
            infoStr   += Strings.Glyphs.HD + " ";
            speechStr += "HD available . ";
        }
        if( isSap )
        {
            infoStr   += Strings.Glyphs.SAP + " ";
            speechStr += "Secondary audio available. ";
        }
        if( isCc )
        {
            infoStr   += Strings.Glyphs.CC + " ";
            speechStr += "Closed captioning available. ";
        }

        this._optionsOverlay.setInfo( infoStr );

        this._title.gotFocus();
        this._title.setSpeechParams( speechStr, undefined, this, newScreen );
        return this;
    };

    PurchaseOfferRowWidget.prototype.localyticsSend = function()
    {
//console.log( "LOCALYTICS: Buy Option Clicked: %o", this._metricsBuyOptionClicked );
        window.ll( "tagEvent", "Buy Option Clicked", this._metricsBuyOptionClicked );
    };

    PurchaseOfferRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._title.lostFocus()
    };

    PurchaseOfferRowWidget.prototype.processEvent = function(val, type)
    {
        var retval;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    var params = {
                        offer          : this._offer,
                        entity         : this._entity,
                        optionsOverlay : this._optionsOverlay,
                        onBuy          : this._onBuy
                    };

                    this.localyticsSend();

                    _x2.pushOverlay( new PurchaseOverlay().init( params ) );
                }
                break;

            case Host.KEY_LAST:
                if( type === Host.KEY_PRESSED )
                {
                    this._metricsBuyOptionClicked.Option = "[Unfinished]";
                    this.localyticsSend();
                    retval = ListRowWidget.prototype.processEvent.call( this, val, type );
                }
                break;

            default:
                retval = ListRowWidget.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    PurchaseOfferRowWidget.prototype.setData = function( offer )
    {
        if( offer !== undefined )
        {
            this._offer = offer;

            var season   = offer.isSeasonBundle() ? " Season " : " ";
            var titleStr = offer.getPurchaseType() + season + "$" + offer.getPrice() + " " + offer.getVideoQuality();

            this._title.setText(titleStr);
            this._subTitle.setText();

            var window = offer.getRentalWindowInHours();
            if( window > 0 )
            {
                this._subTitle.setText( "<i>" + window + " Hour Rental</i>");

                this._title.setY( this._vertOffsets[0] );
                this._subTitle.setY( this._vertOffsets[2] )
            }
            else
                this._title.setY( this._vertOffsets[1] );

            this.setA(1);
        }
        else
            this.setA(0);

    };

    return PurchaseOfferRowWidget;

})();
