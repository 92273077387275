// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.MultiStateButtonWidget = ( function()
{
    "use strict";

    MultiStateButtonWidget.prototype = new Widget();

    function MultiStateButtonWidget(){}

    MultiStateButtonWidget.prototype.getState = function()
    {
        return Math.floor( this._index / 2 );
    };

    MultiStateButtonWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        Widget.prototype.gotFocus.call( this );

        if( this._index % 2 === 0 )
            this._images[++this._index].animate( { duration:250, alpha:1 } );
        this._label.animate( { duration:250, alpha:1 } );

        if( this._label.getText() )
            this._label.setSpeechParams( (this._index < this._images.length / 2)? "Unselected. " : "Selected. ", "button", this, newScreen );
    };

    MultiStateButtonWidget.prototype.inFocusState = function()
    {
        return this._index % 2 === 1;
    };

    /**
     * Initializer
     * @memberof MultiStateButtonWidget
     * @param   {Object}    params
     * @param   {Array}     params.images   - array of images, non-highlighted and highlighted images for each state
     * @param   {Number}    params.initial  - initial state of button
     * @param   {String}   [params.label]   - string to display below the button
     * @param   {Function}  params.onEnter  - Callback when button state is changed
     * @returns {MultiStateButtonWidget}
     */
    MultiStateButtonWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this );
        this._className  = "MultiStateButtonWidget";
        this._selectable = true;

        var self  = this, numLoaded = 0, w = _x2._config._screenW / 4;
        var style = new Style( { font:"medium", color:"#e8e8e8", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", textAlign:"center" } );

        var imagesLoaded = function()
        {
            if( ++numLoaded === self._images.length )
            {
                self.setW( self._images[0].getW() );
                self.setH( self._images[0].getH() );
                self._label.setX( (self._images[0].getW() - w) / 2 );
                self._label.setY( self._images[0].getY() + self._images[0].getH() + _x2.scaleValInt( 10 ) );
                self.callbackSignalReady();
            }
        };

        this._index  = params.initial * 2;
        this._images = [];

        for( var i = 0; i < params.images.length; i++ )
        {
            this._images[i] = new ImageWidget().init( { url:params.images[i], onLoad:imagesLoaded } );
            this._images[i].setA( 0 );
            this.addWidget( this._images[i] );
        }

        this._images[this._index].setA( 1 );

        this._label = new StringWidget().init( { text:params.label, style:style } );
        this._label.setA( 0 );
        this._label.setW( w );
        this.addWidget( this._label );

        if( params.onEnter )
        {
            this._onEnter = params.onEnter;
            this.setMouseDownListener( params.onEnter );
        }

        return this;
    };

    MultiStateButtonWidget.prototype.lostFocus = function()
    {
        Widget.prototype.lostFocus.call( this );

        this._images[this._index--].animate( { duration:250, alpha:0 } );
        this._label.animate( { duration:250, alpha:0 } );
    };

    MultiStateButtonWidget.prototype.processEvent = function( val, type )
    {
        var retval, self = this, currentIndex = this._index;

        var onEnd = function()
        {
            self._images[currentIndex - 1].setA( 0 ) ;
            self._images[currentIndex].setA( 0 ) ;
            if( self._index > 0 ) {
                self._images[self._index - 1].setA( 1 ) ;
            }
        };

        if( val === Host.KEY_ENTER && type === Host.KEY_PRESSED )
        {
            if( this._index % 2 === 0 )  // must be in a focus state to be toggling
                this._index++;

            this._index = (this._index + 2) % this._images.length;

            if( this._index < currentIndex )
            {
                this._images[this._index - 1].setA( 1 );
                this._images[this._index].setA( 1 );
                this._images[currentIndex - 1].setA( 0 );
                this._images[currentIndex].animate( { duration:250, a:0 } );
            }
            else
                this._images[this._index].animate( { duration:250, a:1, onEnd:onEnd } );

            if( this._onEnter )
                this._onEnter( Math.floor( this._index / 2 ) );

            if( this._label.getText() )
                this._label.setSpeechParams( (this._index < this._images.length / 2)? "Unselected. " : "Selected. ", "button", this, false );

            retval = true;
        }
        else
            retval = Widget.prototype.processEvent.call( this, val, type );

        return retval;
    };

    MultiStateButtonWidget.prototype.setLabel = function( str )
    {
        this._label.setText( str );
    };

    MultiStateButtonWidget.prototype.setState = function( index )
    {
        this._index  = index * 2;

        for( var i = 0; i < this._images.length; i++ )
            this._images[i].setA( 0 );

        this._images[this._index].setA( 1 );
    };

    return MultiStateButtonWidget;

})();
