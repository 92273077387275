// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.Tizen = (function()
{
    "use strict";

    Tizen.prototype = new Host();

    function Tizen()
    {
//         console.log( "SCREEN HEIGHT = " + screen.availHeight );
//         console.log( screen );
//         console.log( "window.innerWidth = " + window.innerWidth );
//         console.log( "window.innerHeight = " + window.innerHeight );

        _x2._useListitem = true;

        if( window.innerHeight === 1080 )
            _x2._config.setSize( "1080" );
        else if( window.innerHeight === 720 )
            _x2._config.setSize( "720" );
    }

//     Tizen.prototype.checkMinVersion = function()
//     {
//     };
//
//     Tizen.prototype.checkNetworkConnection = function( interval )
//     {
//         console.log( "WARNING -> Network check not implemented." );
//     };

    Tizen.prototype.exitApp = function( viaExit )
    {
        tizen.application.getCurrentApplication().exit();

//         if( viaExit === true )
//             tizen.application.getCurrentApplication().exit();
//         else
//             tizen.application.getCurrentApplication().hide();
    };

    Tizen.prototype.getDrmType = function()
    {
        return "com.widevine.alpha";
    };

    Tizen.prototype.getCurrentDateTime = function(convertToTimeStamp) 
    {
        convertToTimeStamp = convertToTimeStamp || false;
        if( _x2._config.useJSTime === true ) {
            return Host.prototype.getCurrentDateTime( convertToTimeStamp );
        } else {
            var tzDateTime = tizen.time.getCurrentDateTime();
            var jsDateTime = new Date( tzDateTime.getFullYear(), tzDateTime.getMonth(), 
                                       tzDateTime.getDate(), tzDateTime.getHours(), 
                                       tzDateTime.getMinutes(), tzDateTime.getSeconds() );
            return (convertToTimeStamp == true )? jsDateTime.getTime() : jsDateTime;
        }
    };

//     Tizen.prototype.getBorderImageType = function()
//     {
//         return Tizen.BORDER_IMAGE;
//     };
//
//     Tizen.prototype.getDate = function()
//     {
//         return (new Date());
//     };
//
//     Tizen.prototype.getImageSoftA = function()
//     {
//         return "softKeys/SoftKey_A.png";
//     };
//
//     Tizen.prototype.getImageSoftB = function()
//     {
//         return "softKeys/SoftKey_B.png";
//     };
//
//     Tizen.prototype.getImageSoftC = function()
//     {
//         return "softKeys/SoftKey_C.png";
//     };
//
//     Tizen.prototype.getImageSoftD = function()
//     {
//         return "softKeys/SoftKey_D.png";
//     };

//     Tizen.prototype.getType = function()
//     {
//         return this._type;
//     };

    Tizen.prototype.getPlatformString = function()
    {
       return "tizen";
    };

    Tizen.prototype.getSecClientDeviceType = function()
    {
       return "Samsung2017";
    };

    Tizen.prototype.getVideoPlayerClass = function( ext )
    {
        var retval;

        switch( ext )
        {
           case "mpd":
//                retval = TizenPlayer;
                break;

            default:
                console.log( "ERROR -> no player type for ext = " + ext );
                break;
        }

        return retval;
    };

    Tizen.prototype.init = function( callback )
    {
        this._type = Host.Type.TIZEN;
        var self = this;

        document.body.style.backgroundColor = "#000000";

        var onDeepLink = function( atLaunch )
        {
            console.log( "========== ON DEEP LINK ==========", JSON.stringify( atLaunch ) );

            var appControlData, actionData, actionId;
            
            var tokenAuth = Host.getLocalStorage( "XtvAuthToken" );
            var tokenXsct = Host.getLocalStorage( "XsctToken"    );
            var currentApplication = null;
            try {
                currentApplication = tizen.application.getCurrentApplication();
            } catch ( error ) {
                console.log("No active app in tizen simulator", error );
                if( atLaunch === true || !tokenAuth || !tokenXsct )
                    _x2._tempDefaultScreen = actionId;
                else {
                    //Check authenticate, before the transition screen
                    self.authenticateBeforeTransitionScreen( _x2._config._initialScreen );
                }
            }// if there is no current application, then simulator throws an error and on catch we need to transit the screen
            if ( currentApplication ) {
                console.log("Active app is ", currentApplication.appInfo && currentApplication.appInfo.id );   
                var requestedAppControl = currentApplication.getRequestedAppControl();
                var metrics;
                if( requestedAppControl )
                {
                    appControlData = requestedAppControl.appControl.data;

                    console.log( appControlData );

                    for( var i = 0; i < appControlData.length; i++ )
                    {
                        if( appControlData[i].key == "PAYLOAD" )
                        {
                            actionData = JSON.parse( appControlData[i].value[0] ).values;

                            console.log( actionData );

                            actionId = JSON.parse( actionData ).actionId;

                            if( atLaunch === true || !tokenAuth || !tokenXsct )
                                _x2._tempDefaultScreen = actionId;
                            else
                                switch( actionId )
                                {
                                    case "browse":
                                        _x2.setScreen( new VodRootScreen().init() );
                                        break;

                                    case "live":
                                        _x2.setScreen( new GuideScreen().init() );
                                        break;

                                    case "saved":
                                        _x2.setScreen( new SavedScreen().init() );
                                        break;

                                    case "search":
                                        _x2.setScreen( new SearchScreen().init() );
                                        break;

                                    case "settings":
                                        setTimeout( function() { _x2.setScreen( new SettingsScreen().init() ) }, 100 );
                                        break;

                                    default:
                                        self.authenticateBeforeTransitionScreen( actionId );//Check authenticate, before the transition screen
                                        break;
                                };
                            break;
                        }
                    }
                }
                else
                    console.error( "Could not get app control." );   
            }
        };

        window.addEventListener( "appcontrol", onDeepLink );
        onDeepLink( true );

        tizen.tvinputdevice.registerKey( "0"                  );
        tizen.tvinputdevice.registerKey( "1"                  );
        tizen.tvinputdevice.registerKey( "2"                  );
        tizen.tvinputdevice.registerKey( "3"                  );
        tizen.tvinputdevice.registerKey( "4"                  );
        tizen.tvinputdevice.registerKey( "5"                  );
        tizen.tvinputdevice.registerKey( "6"                  );
        tizen.tvinputdevice.registerKey( "7"                  );
        tizen.tvinputdevice.registerKey( "8"                  );
        tizen.tvinputdevice.registerKey( "9"                  );
        tizen.tvinputdevice.registerKey( "ChannelUp"          );
        tizen.tvinputdevice.registerKey( "ChannelDown"        );
        tizen.tvinputdevice.registerKey( "ColorF0Red"         );
        tizen.tvinputdevice.registerKey( "ColorF1Green"       );
        tizen.tvinputdevice.registerKey( "ColorF2Yellow"      );
        tizen.tvinputdevice.registerKey( "ColorF3Blue"        );
        tizen.tvinputdevice.registerKey( "Exit"               );
        tizen.tvinputdevice.registerKey( "Info"               );
        tizen.tvinputdevice.registerKey( "MediaFastForward"   );
        tizen.tvinputdevice.registerKey( "MediaPause"         );
        tizen.tvinputdevice.registerKey( "MediaPlay"          );
        tizen.tvinputdevice.registerKey( "MediaPlayPause"     );
        tizen.tvinputdevice.registerKey( "MediaRecord"        );
        tizen.tvinputdevice.registerKey( "MediaRewind"        );
        tizen.tvinputdevice.registerKey( "MediaStop"          );
        tizen.tvinputdevice.registerKey( "MediaTrackNext"     );
        tizen.tvinputdevice.registerKey( "MediaTrackPrevious" );
        tizen.tvinputdevice.registerKey( "Minus"              );

        var loadFunction = function()
        {
//            console.log( "WEBAPIS LOADED" );

            var mac = webapis.network.getMac();

//            console.log( "MAC ADDRESS = " + mac );

            mac = (mac)? mac.replace( /:/g, "" ) : '';

//            console.log( "MAC ADDRESS = " + mac );

            _x2._config._mac             = mac;
            _x2._config._host._make      = "Samsung";
            _x2._config._host._model     = webapis.productinfo.getModelCode();
            _x2._config._host._product   = webapis.productinfo.getRealModel();
            _x2._config._host._osName    = "Tizen";
            _x2._config._host._osVersion = webapis.productinfo.getFirmware();

            try
            {
                var connectionType = webapis.network.getActiveConnectionType();
                var type;

                switch( connectionType )
                {
                    case webapis.network.NetworkActiveConnectionType.CELLULAR:
                        type = Host.NetworkType.UNKNOWN;
                        break;

                    case webapis.network.NetworkActiveConnectionType.DISCONNECTED:
                        type = Host.NetworkType.NONE;
                        break;

                    case webapis.network.NetworkActiveConnectionType.ETHERNET:
                        type = Host.NetworkType.WIRED;
                        break;

                    case webapis.network.NetworkActiveConnectionType.WIFI:
                        type = Host.NetworkType.WIFI;

                        try
                        {
                            _x2._config._host.setSsid( webapis.network.getWiFiSsid() );
                        }
                        catch( e )
                        {
                            console.error( e );
                        }
                        break;

                    default:
                        type = Host.NetworkType.UNKNOWN;
                        break;
                }

                _x2._config._host.setNetworkType( type );
            }
            catch( e )
            {
                console.error( e );
            }

            console.log( "FIRMWARE = " + _x2._config._host._osVersion );
            console.log( "NETWORK  = " + _x2._config._host.getNetworkType() + ", " + _x2._config._host.getSsid() );
            console.log( "MODEL    = " + _x2._config._host._model );
            console.log( "PRODUCT  = " + _x2._config._host._product );

            var modelYear = _x2._config._host._model.substring( 0, 2 );

            if( modelYear === "17" )
                _x2._modSpeech = true;

            callback();
        };
        if( window.webapis && _x2._config.isTizenSimulator === true ) {
            loadFunction(); //in tizen simulator, webapis will be available in window variable, so no need to fetch webapis.js
        } else {
            _x2.getScript( "$WEBAPIS/webapis/webapis.js", function(){ loadFunction(); }, function() {
                if( window.webapis ) {
                    loadFunction();
                }
            } );//through smarthub workflow, webapis will be available in window variable
        }
    };

    Tizen.prototype.mapKey = function( event, which )
    {
        var val = event.which;

        switch( val )
        {
            case 13:
                val = Host.KEY_ENTER;
                break;

            case 19:
                val = Host.KEY_PAUSE;
                break;

            case 37:
                val = Host.KEY_LEFT;
                break;

            case 38:
                val = Host.KEY_UP;
                break;

            case 39:
                val = Host.KEY_RIGHT;
                break;

            case 40:
                val = Host.KEY_DOWN;
                break;

            case 48:
                val = Host.KEY_0;
                break;

            case 49:
                val = Host.KEY_1;
                break;

            case 50:
                val = Host.KEY_2;
                break;

            case 51:
                val = Host.KEY_3;
                break;

            case 52:
                val = Host.KEY_4;
                break;

            case 53:
                val = Host.KEY_5;
                break;

            case 54:
                val = Host.KEY_6;
                break;

            case 55:
                val = Host.KEY_7;
                break;

            case 56:
                val = Host.KEY_8;
                break;

            case 57:
                val = Host.KEY_9;
                break;

            case 189:
                val = Host.KEY_HUD;
                break;

            case 403:
                val = Host.KEY_SOFT_A;
                break;

            case 404:
                val = Host.KEY_SOFT_B;
                break;

            case 405:
                val = Host.KEY_SOFT_C;
                break;

            case 406:
                val = Host.KEY_SOFT_D;
                break;

            case 412:
                val = Host.KEY_RW;
                break;

            case 413:
                val = Host.KEY_STOP;
                break;

            case 415:
                val = Host.KEY_PLAY;
                break;

            case 416:
                val = Host.KEY_RECORD;
                break;

            case 417:
                val = Host.KEY_FF;
                break;

            case 427:
                val = Host.KEY_CH_UP;
                break;

            case 428:
                val = Host.KEY_CH_DOWN;
                break;

            case 457:
                val = Host.KEY_INFO;
                break;

            case 10009:
                val = Host.KEY_LAST;
                break;

            case 10182:
                val = Host.KEY_QUIT;
                break;

            case 10252:  // play/pause
                val = Host.KEY_PLAY_PAUSE;
                break;

//             case 10232: // prev track
//                 val = ;
//                 break;

//             case 10233: // next track
//                 val = ;
//                 break;
        }

        return Host.prototype.mapKey.call( this, event, val );
    };

    Tizen.prototype.setScreenSaverActive = function( active )
    {
        if( active === true )
        {
            console.log( "##### ENABLE SCREEN SAVER #########################################" );
            webapis.appcommon.setScreenSaver( webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_ON );
        }
        else if( active === false )
        {
            console.log( "##### DISABLE SCREEN SAVER #########################################" );
            webapis.appcommon.setScreenSaver( webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_OFF );
        }
        else
            console.log( "ERROR -> invalid parameter for setScreenSaverActive = " + active );
    };

    Tizen.prototype.authenticateBeforeTransitionScreen = function( entityId ) {
        entityId = entityId || null;
        if( !_x2._data ) { //wait for few seconds and then transit to screen
            console.log("No data found from api, wait for few seconds");
            window.setTimeout( function() {
                this.transitToScreen( entityId );
            }.bind(this), 3000 );
        } else {
            this.transitToScreen( entityId );
        }
    }

    Tizen.prototype.transitToScreen = function( entityId ) {
        console.log("Transition screen has initiated with entity id - ", entityId, " on ", _x2.getTopScreen()._className );
        if( _x2.getTopScreen() instanceof WelcomeScreen || 
            ( entityId == 'mainMenu' && ( _x2.getTopScreen() instanceof LoadingScreen ) )) {
            return false;
        } else if( entityId == 'mainMenu' ) {
            _x2.setScreen( new MainMenuScreen().init() );
        } else if( entityId ) {
            console.log("Smarthub preview workflow started with entity id", entityId );
            _x2.setScreen( new TransitionScreen().init( {entityId: entityId }));
        }
    }

    return Tizen;

})();
