// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.RatingsScreen = ( function()
{
    "use strict";

    RatingsScreen.prototype = new Screen();

    RatingsScreen.Type = Object.freeze
    ({
        MOVIE : 0,
        TV    : 1
    });

    function RatingsScreen(){}

    RatingsScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list );
        this._speakTarget.setText( this.getBreadCrumbsTermStr() + ". " + focus._speakStr + "." );
        this._speakTarget.setSpeechParams( "Press up or down to review options. Press OK to select.", focus._speakRole, this, false );
    };

    /**
     * Initializer
     * @memberof RatingsScreen
     * @param   {Object}             params
     * @param   {RatingsScreen.Type} params.type - type of screen to create
     * @returns {RatingsScreen}
     */

    RatingsScreen.prototype.init = function( params )
    {
        var listParams = {}, className, breadCrumb, rows, rowType;

        if( params.type === RatingsScreen.Type.MOVIE )
        {
            className           = "MovieRatingsScreen";
            this._telemetryName = "Movie Ratings";
            breadCrumb          = "Movie Ratings";
            rowType             = RatingsRowWidget.Type.MOVIE;
            rows                =
            [
                { title:"Adult", type:RatingsRowWidget.Type.MOVIE, rating:ParentalControls.MpaaRatings.ADULT },
                { title:"NC-17", type:RatingsRowWidget.Type.MOVIE, rating:ParentalControls.MpaaRatings.NC17  },
                { title:"R"    , type:RatingsRowWidget.Type.MOVIE, rating:ParentalControls.MpaaRatings.R     },
                { title:"PG-13", type:RatingsRowWidget.Type.MOVIE, rating:ParentalControls.MpaaRatings.PG13  },
                { title:"PG"   , type:RatingsRowWidget.Type.MOVIE, rating:ParentalControls.MpaaRatings.PG    },
                { title:"G"    , type:RatingsRowWidget.Type.MOVIE, rating:ParentalControls.MpaaRatings.G     }
            ];
        }
        else if( params.type === RatingsScreen.Type.TV )
        {
            className  = "TvRatingsScreen";
            breadCrumb = "TV Ratings";
            rowType    = RatingsRowWidget.Type.TV;
            rows       =
            [
                { title:"TV-MA", type:RatingsRowWidget.Type.TV, rating:ParentalControls.TvRatings.TVMA },
                { title:"TV-14", type:RatingsRowWidget.Type.TV, rating:ParentalControls.TvRatings.TV14 },
                { title:"TV-PG", type:RatingsRowWidget.Type.TV, rating:ParentalControls.TvRatings.TVPG },
                { title:"TV-G" , type:RatingsRowWidget.Type.TV, rating:ParentalControls.TvRatings.TVG  },
                { title:"TV-Y7", type:RatingsRowWidget.Type.TV, rating:ParentalControls.TvRatings.TVY7 },
                { title:"TV-Y" , type:RatingsRowWidget.Type.TV, rating:ParentalControls.TvRatings.TVY  }
            ];
        }

        Screen.prototype.init.call( this );
        this._className = className;
        this.setBreadCrumbs( "Settings / Parental Controls", breadCrumb );

        listParams.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 1;
        listParams.rows     = rows;
        listParams.type     = RatingsRowWidget;
        listParams.obj      = new RatingsController( rowType );

        this._list = new ListWidget().init( listParams );
        this.addWidget( this._list, Style._safeLeft );

        return this;
    };

    RatingsScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._list.setY( this.getHeaderBottom() );
        this._list.setH( _x2._config._screenH - this._list.getY() );
    };

    return RatingsScreen;

})();
