// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.EntityScreen = (function()
{
    "use strict";

    EntityScreen.prototype = new Screen();

    function EntityScreen(){}

    /**
     * @param {Object} params
     * @param {BrowseEntity, SearchEntity, Recording, Entity, Purchase} params.entity
     * @param {BrowseEntity} [params.molt]
     */
    EntityScreen.prototype.init = function( params )
    {
        Screen.prototype.init.call( this );
        this._className     = "EntityScreen";
        this._telemetryName = "Entity Info";

        var self   = this;
        var entity = this._entity = params.entity;
        this._molt = params.molt;

        var y = _x2.scaleValInt( 42 ); // XC-13188 // NOTE: Can't use 'y = {Screen.}getHeaderBottom()' due to _time.getH() returning undefined -> NaN

        var yOffsets = [ y, _x2.scaleValInt( y+285 ), _x2.scaleValInt( y+449 ), _x2.scaleValInt( y+461 )]; // [3] = 560 was used for???
        var xOffsets = [_x2.scaleValInt( 355 )];
        var maxW     = _x2._config._screenW - Style._safeLeft - Style._safeRight;

        var scrollW = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        var scrollH = _x2._config._screenH;

        var onRefresh = function( updatedEntity )
        {
            if( updatedEntity )
                self._entityDetail.setData( updatedEntity );

            if( entity._changeCallback )
                entity._changeCallback();
        };

        this._yOffsets      = yOffsets;
        this._entityTitle   = (entity.getSeriesTitle && entity.getSeriesTitle()) ? entity.getSeriesTitle() : entity.getTitle();

        this._entityDetail  = new EntityInfoWidget().init();
        this._moltWidget    = new GalleryRowWidget().init( { w: maxW, sep: false } );
        this._scrollWidget  = new Widget().init();
        this._actionButtons = new EntityActionButtonsWidget().init( undefined, scrollW - xOffsets[0] );
        this._moltRectSep   = new RectWidget().init( { w:_x2._config._screenW - Style._safeLeft - Style._safeRight, h:ListRowWidget.DIVIDER_H, color:"#303030" } );

        this._actionButtons.setRefreshListener( onRefresh );

        this._scrollWidget.addWidget( this._entityDetail ,           0,0 );
        this._scrollWidget.addWidget( this._actionButtons, xOffsets[0], yOffsets[1] );
        this._scrollWidget.addWidget( this._moltRectSep  ,           0, yOffsets[2] );
        this._scrollWidget.addWidget( this._moltWidget   ,           0, yOffsets[3] );

        this._scrollPane = new ScrollWidget().init( { widget: this._scrollWidget, w: scrollW, h: scrollH } );
        this.addWidget( this._scrollPane, Style._safeLeft, yOffsets[0] );

        this._entityDetail.setW( maxW );
        this._entityDetail.setH( _x2._config._screenH - Style._safeBottom - yOffsets[0] );

        this._distractor = new DistractorWidget().init( {} );
        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget( self ); } );
        this.addWidget( this._distractor );

        this.setBreadCrumbs( undefined, this._entityTitle );

        window.ll( "tagScreen", "Entity Info" ); // TODO: Which one? Docs say "Entity Info" but XTV has "Entity " + entity name
        _x2._telemetry.sendScreenViewed( this._telemetryName );

        this.addReadyCallback( function(){ self.layout(); } );

        return this;
    };

    EntityScreen.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();

        if( this._popScreen === true )
            _x2.popScreen( self );
        else if( this._actionButtonsReady )
            this.speak( _x2.requestFocus( this._actionButtons ) );
        else
            this.speak();
    };

    EntityScreen.prototype.layout = function()
    {
        var self = this;
        var dy   = this.getHeaderBottom();
        var i    = 0, n = this._yOffsets.length;

        for( ; i < n; i++ )
            this._yOffsets[i] += dy;

        this._scrollPane   .setY( this._yOffsets[0] );
        this._actionButtons.setY( this._yOffsets[1] );
        this._moltRectSep  .setY( this._yOffsets[2] );
        this._moltWidget   .setY( this._yOffsets[3] );

        // HACK: put in a delay before setting the data to fix a problem with LG2017 speech engine

        setTimeout( function() { self.setData(); }, 200 );
    };

    EntityScreen.prototype.processEvent = function( val, type )
    {
        var retval, self = this;

        switch( val )
        {
            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._moltWidget.containsWidget( _x2._focus )  )
                    {
                        this._scrollPane.setScroll( { y: 0, duration: 300 } );
                        _x2.requestFocus( this._actionButtons );
                    }
                }
                break;

            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED )
                    if( this._molt && this._molt.length && this._moltWidget.containsWidget( _x2._focus ) === false )
                        this._scrollPane.setScroll( { y:-this._yOffsets[3] - _x2.scaleValInt( 50 ), duration: 300, onEnd: function(){ _x2.requestFocus( self._moltWidget ) } } ); // HACK: +50 because CollectionRowWidget top offset
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    EntityScreen.prototype.setData = function()
    {
        var self   = this;
        var entity = this._entity;

        /**
         * Push an error overaly on failure.
         * @param error
         */
        var fail = function( error )
        {
            self._popScreen = true;
            self._distractor.hide();

            if( self._alerted !== true ) // don't push multiple overlays if there is more than one error.
            {
                self._alerted = true;
                _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.ENTITY } ) );
            }
        };

        /**
         * Callback when entity options loaded
         */
        var onEntityOptionsLoaded = function()
        {
            self._distractor.hide();
            self._entityDetail.setData( self._entity ); //call setData on EntityInfoWidget again to fill in add'l (i.e. recording) data
            self._actionButtons.setData( { entity:self._entity, infoButton:self._infoButton } );
            self._actionButtonsReady = true;

            if( _x2._focus === self )
                self.speak( _x2.requestFocus( self._actionButtons ) );
        };

        /**
         * Set the breadcrumb string by inspecting the entity (i.e. set series/episode if available)
         * @param {Entity} entity
         */
        var setBreadcrumbString = function( entity )
        {
            var breadcrumbString;

            if( self._entityTitle === undefined ) //no title was set on init, default to entity title
                self._entityTitle = breadcrumbString = entity.getTitle();

            if( entity.getType() === XtvApi.EntityType.EPISODE ) //display season and episode in breadcrumbs
            {
                self._entityTitle = breadcrumbString = entity.getTitle();

                var series = entity.getSeries();
                if( series)
                {
                    var seriesTitle   = series.getTitle();
                    var seasonNumber  = entity.getSeasonNumber();
                    var episodeNumber = entity.getEpisodeNumber();

                    if( seriesTitle )
                        breadcrumbString = seriesTitle;

                    if( seasonNumber && episodeNumber )
                    {
                        if( seriesTitle )
                            breadcrumbString += " / ";

                        breadcrumbString += "Season " + seasonNumber + " / Episode " + episodeNumber;
                    }
                }
            }

            if( breadcrumbString !== undefined )
                self.setBreadCrumbs( undefined, breadcrumbString );
        };

        /**
         * Callback when entity loaded
         */
        var onEntityLoaded = function()
        {
            setBreadcrumbString( self._entity );

            if( self._entity.getType() === XtvApi.EntityType.SPORTS )
            {
                //For sporting events show home/away team in place of MoLT.
                var teams = [];
                var home  = self._entity.getHomeTeam();
                var away  = self._entity.getAwayTeam();

                if( home )
                    teams.push(home);

                if( away )
                    teams.push(away);

                if( teams.length )
                {
                    var collection = new Collection().init( teams, "Teams", XtvApi.EntityRenderStyle.POSTER );
                    self._molt = teams;
                    self._moltWidget.setData( collection );
                }
            }

            self._distractor.show();
            self._entityDetail.setData( self._entity );

            var p = [];
            p.push( self._entity.fetchEntityOptions() );

            if( self._entity.getType() === XtvApi.EntityType.SERIES )
                p.push( self._entity.fetchEntityRecording() );

            Promise.all( p ).then( onEntityOptionsLoaded ).catch( fail )
        };

        /**
         * Callback when More Like This data loaded
         * @param molt
         */
        var onMoltLoaded = function( molt )
        {
            if( molt && molt.length )
            {
                var collection = new Collection().init( molt, "People Are Watching", XtvApi.EntityRenderStyle.POSTER );
                self._molt = molt;
                self._moltWidget.setData( collection );
            }
        };

        var onLinearShowingDetail = function( showingDetail )
        {
            self._entity = showingDetail;
            self._entityDetail.setData( showingDetail );
            self._actionButtons.setData( { entity: showingDetail, infoButton:true } );
            self._actionButtonsReady = true;
            self._distractor.hide();

            if( _x2._focus === self )
                self.speak( _x2.requestFocus( self._actionButtons ) );
        };

        if( entity instanceof ThumbnailEntity ) //BROWSE, SEARCH
        {
            this._distractor.show();

            var getMolt     = true;
            var id          = entity.getEntityId();
            var programType = entity.getProgramType();

            if( programType === 'MusicVideo' || programType === 'SportingEvent' )
                getMolt = false;
            else if( programType === 'Episode' )
            {
                id = entity.getSeriesId();
                getMolt = false;
            }

            var p = [];
            p.push( _x2.refreshResumePoints() );
            p.push( _x2._data.getProgram( id ).then( function( data ) { self._entity = data } ) );
            Promise.all(p).then( onEntityLoaded ).catch( fail );

            if( getMolt )
                _x2._data.getMoreLikeThis( id ).then( onMoltLoaded );
        }
        else if( entity instanceof Entity ) //SAVED > PURCHASES >> (SERIES/MOVIE/MORE) INFO
        {
            this._distractor.show();
            _x2._data.getMoreLikeThis( entity.getEntityId() ).then( onMoltLoaded );

            onEntityLoaded();
        }
        else if( entity instanceof Showing )
        {
            if( entity.getEntity() )
                setBreadcrumbString( entity.getEntity() );

            if( entity instanceof Recording ) //SAVED > RECORDINGS, SCHEDULED
            {
                var setRecording = function()
                {
                    self._entityDetail.setData( entity );
                    self._actionButtons.setData( { entity:entity, infoButton:true } );
                    self._actionButtonsReady = true;

                    if( _x2._focus === self )
                        self.speak( _x2.requestFocus( self._actionButtons ) );
                };

                if( ! entity.getProgram() ) //NOTE: some recordings do not have embedded encodesCreativeWork (i.e. news programs)
                    entity.fetchProgram().then( setRecording ).catch( fail );
                else
                    setRecording()
            }
            else if( entity instanceof LinearShowing ) //LIVE TV > KIDS, SPORTS, MOVIES
            {
                this._infoButton = true;
                this._distractor.show();
                entity.getDetail().then( onLinearShowingDetail );
            }
            else if( entity instanceof VodShowing )
            {
                this._entityDetail.setData( entity );
                this._actionButtons.setData( { entity: entity, infoButton:true } );
                self._actionButtonsReady = true;

                if( _x2._focus === this )
                    this.speak( _x2.requestFocus( this._actionButtons ) );
            }
        }
        else
            console.log( "EntityScreen.setData -> Unexpected type");

        if( this._molt )
            onMoltLoaded( this._molt );
    };

    EntityScreen.prototype.speak = function( focus )
    {
        if( focus )
        {
            this._speakTarget.setText( this._entityTitle + ". " + focus._speakStr + ". " );
            this._speakTarget.setSpeechParams( this._entityDetail.getSpeechStr() + "Press right or left to review program options. Press OK to select. ", focus._speakRole, this, false );
        }
        else
        {
            this._speakTarget.setText( "Loading, please wait. " );
            this._speakTarget.setSpeechParams( undefined, undefined, this, false );
        }
    };

    return EntityScreen;

})();
