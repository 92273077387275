// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ProgramPromoRowWidget = ( function()
{
    "use strict";

    ProgramPromoRowWidget.prototype = new CollectionRowWidget();

    function ProgramPromoRowWidget(){}

    ProgramPromoRowWidget.prototype.init = function( params )
    {
        params.type = ProgramPromoCellWidget;
        params.y    = _x2.scaleVal( 150 ) - _x2.scaleVal( 13 );
        params.h    = _x2.scaleValInt( 450 );

        CollectionRowWidget.prototype.init.call( this, params );
        this._className  = "ProgramPromoRowWidget";

        return this;
    };

    ProgramPromoRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 560 );
    };

    return ProgramPromoRowWidget;

})();
