// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.StringTestScreen = ( function()
{
    "use strict";

    StringTestScreen.prototype = new Screen();

    function StringTestScreen(){}

    StringTestScreen.prototype.init = function()
    {
        this._className = "StringTestScreen";

        Screen.prototype.init.call( this );

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        var style = new Style( { color:"#e5e5e5", font:"light", fontSize:36, whiteSpace:"nowrap" } );

        this._hello = new StringWidget().init( { text:"Hello", style:style } );
        this.addWidget( this._hello, Style._safeLeft, Style._safeTop );

        this._helloStatic = new StringWidget().init( { text:"Hello Static", style:style } );
        this.addWidget( this._helloStatic, Style._safeLeft, Style._safeTop );

        this._helloDynamic = new StringWidget().init( { text:"Hello Dynamic", style:style } );
        this.addWidget( this._helloDynamic, Style._safeLeft, Style._safeTop );

        return this;
    };

    StringTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    StringTestScreen.prototype.resourcesLoad = function()
    {
        Screen.prototype.resourcesLoad.call( this );

        var self = this;

        this._helloStatic.setVal( Widget.X, Style._safeLeft + this._hello.getVal( Widget.W ) + 30 );
        this._helloStatic.setVal( Widget.FONT_COLOR, "#2ea0dd" );
        this._helloStatic.setVal( Widget.FONT_SIZE, 48 );

        var onEnd = function()
                    {
                        if( self._helloDynamic.getVal( Widget.Y ) === 500 )
                            self._helloDynamic.animate( { duration:1000, y:100, color:"#363636", fontSize:36, onEnd:onEnd, onEndAxis:Widget.Y } );

                        if( self._helloDynamic.getVal( Widget.Y ) === 100 )
                            self._helloDynamic.animate( { duration:1000, y:500, color:"#2ea0dd", fontSize:24, onEnd:onEnd, onEndAxis:Widget.Y } );
                    };

        this._helloDynamic.setVal( Widget.X, this._helloStatic.getVal( Widget.X ) + this._helloStatic.getVal( Widget.W ) + 30 );
        this._helloDynamic.animate( { duration:1000, y:500, color:"#2ea0dd", fontSize:24, onEnd:onEnd, onEndAxis:Widget.Y } );
    };

    return StringTestScreen;

})();
