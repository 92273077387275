// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.ListAnimTestScreen = ( function()
{
    "use strict";

    ListAnimTestScreen.prototype = new Screen();

    function ListAnimTestScreen(){}

    ListAnimTestScreen.prototype.init = function()
    {
        var listParams = {};

        this._className = "ListAnimTestScreen";

        Screen.prototype.init.call( this );

        this.setW( _x2._config._screenW );

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        listParams.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 1;
        listParams.type     = RecordingRowWidget;
        this._list          = new ListWidget().init( listParams );
        this.addWidget( this._list, Style._safeLeft, Style._safeTop );

        this._data       = false;
        this._selectable = true;

        return this;
    };

    ListAnimTestScreen.prototype.gotFocus = function()
    {
        this.loadData();
        _x2.requestFocus( this._list );
    };

    ListAnimTestScreen.prototype.loadData = function()
    {
        if( !this._groups )
        {
            this._data = true;

            var r1 = new Recording().init( { name: 'Foo_1', derivedTitle: 'Foo' } );
            var r2 = new Recording().init( { name: 'Bar_2', derivedTitle: 'Bar' } );
            var r3 = new Recording().init( { name: 'Qux_3', derivedTitle: 'Qux' } );

            var g1 = new RecordingGroup().init( { name: 'Foo', numRecordings: 1 } );
            var g2 = new RecordingGroup().init( { name: 'Bar', numRecordings: 2 } );
            var g3 = new RecordingGroup().init( { name: 'Qux', numRecordings: 3 } );

            g1.addRecording( r1 );
            g2.addRecording( r2 );
            g3.addRecording( r3 );
            this._groups = [ g1, g2, g3 ];

            this._list.setData( this._groups );
        }
    };

    ListAnimTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        if( type === Host.KEY_PRESSED )
            switch( val )
            {
                default:
                    retval = Screen.prototype.processEvent.call( this, val, type );
                    break;
            }

        return retval;
    };

    return ListAnimTestScreen;

})();

