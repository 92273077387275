// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PurchaseConfirmOverlay = (function()
{
    "use strict";

    PurchaseConfirmOverlay.prototype = new Overlay();

    function PurchaseConfirmOverlay(){}

    PurchaseConfirmOverlay.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._buttons );
        var str   = this._titleString.getText() + ". ";

        if( this._subTitleString )
            str += this._subTitleString.getText() + ". ";

        this._speakTarget.setText( str + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press left or right to review options. Press OK to select. Press back to dismiss.", focus._speakRole, this, false );
    };

    PurchaseConfirmOverlay.prototype.init = function( params )
    {
        Overlay.prototype.init.call( this );
        this._className = "PurchaseConfirmOverlay";

        var entity  = params.entity;
        var offer   = params.offer;
        var onWatch = params.onWatch;
        this._onClose = params.onClose;

        var self = this;

        var titleStyle    = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap", textAlign:"center" } );
        var subTitleStyle = new Style( { font:"light" , color:"#a3a3a3", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", textAlign:"center" } );
        var buttonStyle   = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#000000"  } );

        var title = "Enjoy",
            subTitle,
            entityTitle,
            buttons = [];

        if( offer.getOfferType() === "SeasonBundle" )
        {
            entityTitle = offer.getTitle();

            if( entityTitle.indexOf("Buy ") === 0 )
                entityTitle = entityTitle.replace( "Buy ", "");
        }
        else
        {
            var series = entity.getSeries();

            if( series && series.getTitle() )
                entityTitle = series.getTitle() + " - " + entity.getTitle();
            else
                entityTitle = entity.getTitle();

            buttons.push( new StringWidget().init( { text:"Watch Now", style:buttonStyle, onEnter:onWatch } ) );
        }

        buttons.push( new StringWidget().init( { text:"Dismiss"  , style:buttonStyle, onEnter:self._onClose } ) );

        if( offer.getPurchaseType() === "Buy" )
            subTitle = entityTitle + " has been purchased for $" + offer.getPrice() + ". Access anytime from Saved > Purchases.";
        else
            subTitle = entityTitle + " has been rented for $" + offer.getPrice() + ". You have " + offer.getRentalWindowInHours() + " hours to watch. Access your rental from Saved > For You.";

        var w = this._bg.getW() - Style._safeLeft - Style._safeRight;
        var h = _x2.scaleValInt( 80 );

        this._titleString    = new StringWidget().init({text:title, style:titleStyle});
        this._subTitleString = new TextBlockWidget().init( { w:w, h:h, text:subTitle, style:subTitleStyle, ellipsis:true } );
        this._buttons        = new HorizPillButtonsWidget().init( { entries:buttons, spacing:_x2.scaleValInt(41) } );

        this._bg.addWidget(this._titleString   , 0, Style._safeTop + _x2.scaleValInt(163) );
        this._bg.addWidget(this._subTitleString, 0, Style._safeTop + _x2.scaleValInt(245) );
        this._bg.addWidget(this._buttons       , 0, Style._safeTop + _x2.scaleValInt(420) );

        this._buttons.addReadyCallback(function(){self.layout()});

        return this;
    };

    PurchaseConfirmOverlay.prototype.layout = function()
    {
        var center = this._bg.getW() / 2;

        this._buttons.setX( center - this._buttons.getW() / 2 );
        this._titleString.setX( center - this._titleString.getW() / 2 );
        this._subTitleString.setX( center - this._subTitleString.getW() / 2 );
    };

    PurchaseConfirmOverlay.prototype.processEvent = function( val, type )
    {
        //intercept KEY_LAST and fire onClose. NOTE: onClose will pop this overlay, so don't allow Overlay to handle it.
        if( type === Host.KEY_PRESSED && val === Host.KEY_LAST )
        {
            if( this._onClose )
                this._onClose();
        }
        else
            Overlay.prototype.processEvent.call( this, val, type );
    };

    return PurchaseConfirmOverlay;

})();
