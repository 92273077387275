// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.TextBlockTestScreen = ( function()
{
    "use strict";

    TextBlockTestScreen.prototype = new Screen();

    function TextBlockTestScreen(){}

    TextBlockTestScreen.prototype.init = function()
    {
        var x, y, cx, dy;
        var width, height;
        var headStyle, bodyStyle;
        var fontSize;

        this._className = "TextBlockTestScreen";

        Screen.prototype.init.call( this );

        fontSize = 36;
        this._size = fontSize;

        cx = _x2._config._screenW*0.5;
        dy = fontSize + 2;

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        this._text1 = "Eddie Murphy plays a womanizer who gets his comeuppance from a colleague (Robin Givens). Angela: Halle Berry. Gerard: David Alan Grier. Tyler: Martin Lawrence. Strange: Grace Jones. Lady Eloise: Eartha Kitt. Nelson: Geoffrey Holder. Bony T: Chris Rock. Directed by Reginald Hudlin.";
        this._text2 = "Mitzi Gaynor and Rossano Brazzi team in this colorful adaptation of the Rodgers and Hammerstein classic. Billis: Ray Walston. Lt. Cable: John Kerr. Bloody Mary: Juanita Hall. Liat: France Nuyen. The songs include ''Bali Ha'i,'' ''Some Enchanted Evening,'' ''A Wonderful Guy,'' ''There Is Nothing like a Dame,'' ''Bloody Mary,'' ''I'm Gonna Wash That Man Right Outta My Hair,'' ''Carefully Taught,'' ''A Cockeyed Optimist,'' ''Younger than Springtime,'' ''Happy Talk'' and ''This Nearly Was Mine.'' Joshua Logan directed.";

        headStyle = new Style( { color:"#2ea0dd", font:"light", fontSize:fontSize-6, whiteSpace:"nowrap" } );
        bodyStyle = new Style( { color:"#e5e5e5", font:"light", fontSize:fontSize-6                      } );

        width  = Math.min( cx - Style._safeLeft - Style._safeRight, 500 );
        height = 150;
        this._h = height;

        this._colorFAIL = "#FF0000";
        this._colorPASS = "#00FF00";
        this._colorSIZE = "#0000FF";

        this._rectF1 = new RectWidget().init( { w:width, h:height, color:"#000000", borderThickness:1, borderColor:this._colorFAIL } );
        this._rectF3 = new RectWidget().init( { w:width, h:height, color:"#000000", borderThickness:1, borderColor:this._colorFAIL } );
        this._rectP1 = new RectWidget().init( { w:width, h:height, color:"#000000", borderThickness:1, borderColor:this._colorPASS } );
        this._rectP3 = new RectWidget().init( { w:width, h:height, color:"#000000", borderThickness:1, borderColor:this._colorPASS } );

        this._rectS2 = new RectWidget().init( { w:width, h:height, color:"#000000", borderThickness:1, borderColor:this._colorSIZE } );
        this._rectS4 = new RectWidget().init( { w:width, h:height, color:"#000000", borderThickness:1, borderColor:this._colorSIZE } );

        this._rectF1.setA( 0 );
        this._rectF3.setA( 0 );
        this._rectP1.setA( 0 );
        this._rectP3.setA( 0 );

        this._title1 = new StringWidget().init( { text: "Fix W, Fix H"                      , style: headStyle } );
        this._title2 = new StringWidget().init( { text: "Fix W, Var H  Kyes: 1,2"           , style: headStyle } );
        this._title3 = new StringWidget().init( { text: "Var W, Fix H  Keys: 3-6"           , style: headStyle } );
        this._title4 = new StringWidget().init( { text: "Var W, Var H  Keys: 7-0,arrows,-,+", style: headStyle } );

        this._block1 = new TextBlockWidget().init( { text: this._text1, style:bodyStyle , w:width, h:height    } );
        this._block2 = new TextBlockWidget().init( { text: this._text1, style:bodyStyle , w:width, h:undefined } );
        this._block3 = new TextBlockWidget().init( { text: this._text2, style:bodyStyle , w:width, h:height    } );
        this._block4 = new TextBlockWidget().init( { text: this._text2, style:bodyStyle , w:width, h:undefined } );

        x = Style._safeLeft;
        y = Style._safeTop;

        this.addWidget( this._title1, x, y ); y += dy;
        this.addWidget( this._rectF1, x, y );
        this.addWidget( this._rectP1, x, y );
        this.addWidget( this._block1, x, y ); y += dy + height;

        this.addWidget( this._title2, x, y ); y += dy;
        this.addWidget( this._rectS2, x, y );
        this.addWidget( this._block2, x, y );

        x = cx + Style._safeLeft;
        y = Style._safeTop;
        this.addWidget( this._title3, x, y ); y += dy;
        this.addWidget( this._rectF3, x, y );
        this.addWidget( this._rectP3, x, y );
        this.addWidget( this._block3, x, y ); y += dy + height;

        this.addWidget( this._title4, x, y ); y += dy;
        this.addWidget( this._rectS4, x, y );
        this.addWidget( this._block4, x, y );

        this._selectable = true;

        return this;
    };

    TextBlockTestScreen.prototype.gotFocus = function()
    {
        _x2.requestFocus( this._block4 );
    };

    TextBlockTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;
        var w,h;

        if( type === Host.KEY_PRESSED )
            switch( val )
            {
                case Host.KEY_EQUAL:
                    this._block3.pageDown();
                    break;

                case Host.KEY_MINUS:
                    this._block3.pageUp();
                    break;

                case Host.KEY_1:
                    w = this._block2.getW();
                    w -= this._size;
                    if( w < 0 )
                        w = 0;
                    this._rectP3.setH( h ) // TODO: intentional h === undefined ???
console.log( "W2: " + w );

                    this._block2.setW( w );
                    this._rectS2.setW( w );

                    h = this._block2.getH();
                    this._rectS2.setH( h );

                    break;

                case Host.KEY_2:
                    w = this._block2.getW( Widget.W );
                    w += this._size;
                    if( w > _x2._config._screenW )
                        w = _x2._config._screenW;
console.log( "W2: " + w );

                    this._block2.setW( w );
                    this._rectS2.setW( w );

                    h = this._block2.getH();
                    this._rectS2.setH( h );

                    break;

// Text Block 3

                case Host.KEY_3:
                    h = this._block3.getH();
                    h -= this._size;
                    h = Math.floor( h );
                    if( h < 0 )
                        h = 0;
console.log( "H3: " + h );
                    this._block3.setH( h );
                    this._rectP3.setH( h );
                    break;

                case Host.KEY_4:
                    h = this._block3.getH();
                    h += this._size;
                    h = Math.floor( h );
                    if( h > _x2._config._screenH )
                        h = _x2._config._screenH;
console.log( "H3: " + h );
                    this._block3.setH( h );
                    this._rectP3.setH( h );
                    break;

                case Host.KEY_5:
                    this._block3.pageDown();
                    break;

                case Host.KEY_6:
                    this._block3.pageUp();
                    break;

// Text Block 4

                case Host.KEY_7:
                    h = this._block4.getH();
                    h -= this._size;
                    h = Math.floor( h );
                    if( h < 0 )
                        h = 0;
console.log( "H4: " + h );

                    this._block4.setH( h );
                    this._rectS4.setH( h );
                    break;

                case Host.KEY_8:
                    h = this._block4.getH();
                    h += this._size;
                    h = Math.floor( h );
                    if( h > _x2._config._screenH )
                        h = _x2._config._screenH;
console.log( "H4: " + h );

                    this._block4.setH( h );
                    this._rectS4.setH( h );
                    break;

                case Host.KEY_LEFT:
                case Host.KEY_9:
                    w = this._block4.getW();
                    w -= this._size;
                    w = Math.floor( w );
                    if( w < 0 )
                        w = 0;
console.log( "W4: " + w );

                    this._block4.setW( w );
                    this._rectS4.setW( w );

                    break;

                case Host.KEY_RIGHT:
                case Host.KEY_0:
                    w = this._block4.getW();
                    w += this._size;
                    w = Math.floor( w );
                    if( w > _x2._config._screenW )
                        w = _x2._config._screenW;
console.log( "W4: " + w );

                    this._block4.setW( w );
                    this._rectS4.setW( w );

                    break;

                case Host.KEY_PAGE_DN:
                case Host.KEY_CH_DOWN:
                    this._block4.pageDown();
                    break;

                case Host.KEY_PAGE_UP:
                case Host.KEY_CH_UP:
                    this._block4.pageUp();
                    break;

                case Host.KEY_UP:
                    this._block4.lineUp();
                    break;

                case Host.KEY_DOWN:
                    this._block4.lineDown();
                    break;

                default:
                    retval = Screen.prototype.processEvent.call( this, val, type );
                    break;

// TODO: Need to set focus on one of the text widgets
            }

        return retval;
    };

    TextBlockTestScreen.prototype.resourcesLoaded = function()
    {
        var a1, a3;
        var h = [];

        Widget.prototype.resourcesLoaded.call( this );

        h[1] = this._block1.getH();
        h[3] = this._block3.getH();

        a1 = (h[1] === this._h) ? 1 : 0;
        a3 = (h[3] === this._h) ? 1 : 0;

        this._rectF1.setA( 1 - a1 );
        this._rectP1.setA(     a1 );

        this._rectF3.setA( 1 - a3 );
        this._rectP3.setA(     a3 );

        h[2] = this._block2.getH();
        h[4] = this._block4.getH();

        this._rectS2.setH( h[2] );
        this._rectS4.setH( h[4] );

    };

    return TextBlockTestScreen;

})();

