// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ProvisioningScreen = ( function()
{
    "use strict";

    ProvisioningScreen.prototype = new Screen();

    function ProvisioningScreen(){}

    ProvisioningScreen.prototype.gotFocus = function()
    {
        if( this._startOver === true )
        {
            this._startOver = false;
            _x2.authorize();
        }

        this._text.setSpeechParams( "Loading, please wait. ", undefined, this, false );
    };

    ProvisioningScreen.prototype.init = function( onAuth )
    {
        var self   = this;
        var style  = new Style( { font:"light" , color:"#a3a3a3", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", textAlign:"center" } );
        var onLoad = function() { self._numLoaded++; self.layout(); };

        Screen.prototype.init.call( this, { noHeader:true, noSep:true } );
        this._className     = "ProvisioningScreen";
        this._onAuth        = onAuth;
        this._numLoaded     = 0;

        this._brand = new ImageWidget().init( { url:_x2._config._imageRootMso + "msoLogo.png", onLoad:onLoad } );
        this._brand.setA( 0 );
        this.addWidget( this._brand );

        this._text = new StringWidget().init( { text:"Now connecting to your entertainment experience", style:style } );
        this._text.setW( _x2._config._screenW );
        this._text.addReadyCallback( onLoad );
        this.addWidget( this._text );

        this._distractor = new DistractorWidget().init( { hideBg:true } );
        this._distractor.addReadyCallback( onLoad );
        this.addWidget(this._distractor);

        _x2._config.log( Config.CLASS_LOCALYTICS, 1 ) ? console.log( "Localytics: Provisioning screen" ) : Config.NOP();
        window.ll( "tagScreen", "Provisioning" ); // Localytics

        _x2._metricsStartAuthentication = Date.now(); // telemetry.sendProvisioning()

        return this;
    };

    ProvisioningScreen.prototype.layout = function()
    {
        if( this._numLoaded === 3 )
        {
            var onSession, onSessionError, self = this;

            this._brand.setX( (_x2._config._screenW - this._brand.getW()) / 2 );
            this._brand.setY( (_x2._config._screenH - this._brand.getH()) / 2 );
            this._brand.animate( { a:1, duration:500 } );

            this._text.setY( this._brand.getY() + this._brand.getH() + _x2.scaleValInt(14) );
            this._text.animate( { a:1, duration:500 } );

            this._distractor.setX( (_x2._config._screenW - this._distractor.getW()) / 2 );
            this._distractor.setY( this._text.getY() + this._text.getH() + _x2.scaleValInt(20) );
            this._distractor.show();

            // determine the platform and execute the correct provisioning flow

            onSession = function( data )
            {
                console.log( data );

                _x2._config._host.setLocalStorage( "XsctToken", data );
                _x2._data.setAuthHeader( Config.AUTH_XTV );

                //setup cache busting if the user logged out in the last 15 minutes.
                var logoutTimestamp = Host.getLocalStorage( "logoutTimestamp" );
                if( logoutTimestamp )
                {
                    if( parseInt(logoutTimestamp) > Date.now() - 900000 )
                        _x2._network.resetGlobalCache();
                }

                _x2._showTerms = true;
                _x2._metricsFirstAuthentication = true; // Localytics: XC-15494 XC-15534
                _x2._metricsTimerAuthentication = ((Date.now() - _x2._metricsStartAuthentication) / 1000) | 0; // telemetry.sendProvisioning()
                _x2.localyticsSetAppVersionAndDeviceModel();
                window.ll( "tagEvent", "Provision Completed", { "Succeeded": "Yes", "Duration": _x2._metricsTimerAuthentication } ); // seconds
                self._onAuth();
            };

            onSessionError = function( error )
            {
                var popScreen = function()
                {
                    _x2.popScreen( self );
                };
                _x2.localyticsSetAppVersionAndDeviceModel();
                window.ll( "tagEvent", "Provision Completed", { "Succeeded": "No", "Duration": "" } );

                _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.COMMON, onDismiss:popScreen, onRetry:popScreen } ) );

                console.error( "ERROR -> unable to create a DRM session" );
                console.error( error );
                _x2._splunk.sendProvisionError(error.statusCode, error);
                if( error )
                    console.error( error.toString() );

                
                _x2._config._host.removeAllTokenLocalStorage( );
                _x2._config._host.removeLocalStorage( "XtvAuthToken" );
                _x2._config._host.removeLocalStorage( "features"     );
                _x2._data = undefined;
                self._startOver = true;
                _x2._metricsStartAuthentication = Date.now();
            };

            _x2.provision().then( onSession, onSessionError );
        }
    };

    return ProvisioningScreen;

})();
