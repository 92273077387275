// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.RectAnimTestScreen = ( function()
{
    "use strict";

    RectAnimTestScreen.prototype = new Screen();

    function RectAnimTestScreen(){}

    RectAnimTestScreen.prototype.init = function()
    {
        var self = this;
        var i, colorStr;
        var paramW, paramH;
        var top, bot, left, right;
        var narrow, short;
        var border = 3;

        this._className = "RectAnimTestScreen";
        this._border    = border;

        Screen.prototype.init.call( this );

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        this._aniRect = new RectWidget().init( { w:_x2._config._screenW / 4 - 2*border, h:_x2._config._screenH / 4 - 2*border, color:"#222222", borderColor:"#963", borderThickness:border } );
        this.addWidget( this._aniRect, Style._safeLeft + _x2._config._screenW / 4 + 30, Style._safeTop );

        // Boundary Borders for animation
        paramW = { h:1, w:_x2._config._screenW, color:"#4444FF" };
        paramH = { w:1, h:_x2._config._screenH, color:"#44FF44" };

        this._grid = [];
        this._grid[0] = new RectWidget().init( paramW ); // top
        this._grid[1] = new RectWidget().init( paramW ); // bot
        this._grid[2] = new RectWidget().init( paramH ); // left
        this._grid[3] = new RectWidget().init( paramH ); // right

        top   = Style._safeTop;
        bot   = top  + (_x2._config._screenH / 2) - 2*this._border;
        left  = (Style._safeLeft + _x2._config._screenW / 4 + 30);
        right = left + (_x2._config._screenW / 2) - 2*this._border;

        this.addWidget( this._grid[0],         0, top - 1 );
        this.addWidget( this._grid[1],         0, bot + 0 );
        this.addWidget( this._grid[2], left  - 1,       0 );
        this.addWidget( this._grid[3], right + 0,       0 );

        narrow = _x2._config._screenW / 4 - 2*this._border;
        short  = _x2._config._screenH / 4 - 2*this._border;

        this._grid[4] = new RectWidget().init( { h:1, w:_x2._config._screenW, color:"#111144" } ); // center x
        this._grid[5] = new RectWidget().init( { w:1, h:_x2._config._screenH, color:"#114411" } ); // center y

        this.addWidget( this._grid[4],                         0, top + short + 2*border );
        this.addWidget( this._grid[5], left + narrow +  2*border,                      0 );

        // Axis Toggle
        var style = new Style( { color:"#e5e5e5", font:"light", fontSize:14, whiteSpace:"nowrap", textAlign:"center" } );
        var labels = [ 'A', 'X', 'Y', 'W', 'H' ];
        var str, key, box;
        var y = _x2._config._screenH - Style._safeBottom - 16;

        this._labels = [];
        for( i = 0; i < 10; ++i )
        {
            colorStr = (i < 5)
                ? "#880000" // off
                : "#00FF00";// on
            this._labels[ i ] = new RectWidget().init( { w:15, h:15, color: colorStr } );
            this._labels[ i ].setA( 0 );

            var x = Style._safeLeft + ((i % 5) * 16);

            this.addWidget( this._labels[ i ], x, y );

            if( i % 2 === 0 )
            {
                var j = (i/2)|0;
                x = Style._safeLeft + (j * 16);

                str = new StringWidget().init( { text:labels[j], style:style } );
                str.setW( 16 );
                this.addWidget( str, x, y - 16 );

                key = new StringWidget().init( { text:'' + (j+1), style:style } );
                key.setW( 16 );
                this.addWidget( key, x, y + 16 + 2 );

                box = new RectWidget().init( { w: 13, h: 13, borderColor: "#cccccc", borderThickness:1 } );
                this.addWidget( box, x, y + 16 + 3 );
            }
        }

        this._animAllOn = function()
        {
            self._animA = 1;
            self._animX = 1;
            self._animY = 1;
            self._animW = 1;
            self._animH = 1;
            self.layout();
        };

        this._animAllOn();

        return this;
    };

    RectAnimTestScreen.prototype.layout = function()
    {
        var state =
        [
            this._animA | 0,
            this._animX | 0,
            this._animY | 0,
            this._animW | 0,
            this._animH | 0
        ];

        var i, a;
        for( i = 0; i < state.length; ++i )
        {
            a = state[ i ];
            this._labels[ i   ].setA( 1-a ); // state=0 -> alpha=0
            this._labels[ i+5 ].setA(   a ); // state=1 -> alpha=1
        }
    };

    RectAnimTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        var top    = Style._safeTop;
        var bot    = top + (_x2._config._screenH / 2) - 2*this._border;
        var left   = Style._safeLeft + _x2._config._screenW / 4 + 30;
        var right  = left + (_x2._config._screenW / 2) - 2*this._border;

        var narrow = _x2._config._screenW / 4 - 2*this._border;
        var wide   = 2 * narrow;
        var short  = _x2._config._screenH / 4 - 2*this._border;
        var tall   = 2 * short;

        var alpha, i;
        var self   = this;

        var onEndX = function()
        {
            if( self._animX )
                if( self._aniRect.getVal( Widget.X ) === right )
                    self._aniRect.animate( aniLeft );
                else if( self._aniRect.getVal( Widget.X ) === left )
                    self._aniRect.animate( aniRight );
        };

        var onEndY = function()
        {
            if( self._animY )
                if( self._aniRect.getVal( Widget.Y ) === bot )
                    self._aniRect.animate( aniUp );
                else if( self._aniRect.getVal( Widget.Y ) === top )
                    self._aniRect.animate( aniDown );
        };

        var onEndW = function()
        {
            if( self._animW )
                if( self._aniRect.getVal( Widget.W ) === (wide - 2*self._border) )
                    self._aniRect.animate( aniNarrow );
                else //if( self._aniRect.getVal( Widget.W ) === narrow )
                    self._aniRect.animate( aniWide );
        };

        var onEndH = function()
        {
            if( self._animH )
                if( self._aniRect.getVal( Widget.H ) === (tall -2*self._border) )
                    self._aniRect.animate( aniShort );
                else //if( self._aniRect.getVal( Widget.H ) === short )
                    self._aniRect.animate( aniTall );
        };

        var onEndA = function()
        {
            if( self._animA )
                if( self._aniRect.getVal( Widget.ALPHA ) === 1.0 )
                    self._aniRect.animate( aniFade );
                else if( self._aniRect.getVal( Widget.ALPHA ) === 0.3 )
                    self._aniRect.animate( aniOpaque );
        };

        var aniDown   = { duration:1000, y:bot    , onEnd:onEndY, onEndAxis:Widget.Y     };
        var aniUp     = { duration:1000, y:top    , onEnd:onEndY, onEndAxis:Widget.Y     };
        var aniRight  = { duration:4000, x:right  , onEnd:onEndX, onEndAxis:Widget.X     };
        var aniLeft   = { duration:4000, x:left   , onEnd:onEndX, onEndAxis:Widget.X     };
        var aniWide   = { duration:3000, w:wide   , onEnd:onEndW, onEndAxis:Widget.W     };
        var aniNarrow = { duration:3000, w:narrow , onEnd:onEndW, onEndAxis:Widget.W     };
        var aniTall   = { duration:1500, h:tall   , onEnd:onEndH, onEndAxis:Widget.H     };
        var aniShort  = { duration:1500, h:short  , onEnd:onEndH, onEndAxis:Widget.H     };
        var aniFade   = { duration:5000, alpha:0.3, onEnd:onEndA, onEndAxis:Widget.ALPHA };
        var aniOpaque = { duration:5000, alpha:1.0, onEnd:onEndA, onEndAxis:Widget.ALPHA };

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._animY ) this._aniRect.animate( aniDown  );
                    if( this._animX ) this._aniRect.animate( aniRight );
                    if( this._animW ) this._aniRect.animate( aniWide  );
                    if( this._animH ) this._aniRect.animate( aniTall  );
                    if( this._animA ) this._aniRect.animate( aniFade  );
                }
                break;

            case Host.KEY_0:
                if( type === Host.KEY_PRESSED )
                {
                    this._aniRect.setY( top     );
                    this._aniRect.setX( left    );
                    this._aniRect.setW( narrow  );
                    this._aniRect.setH( short   );
                    this._aniRect.setA( 1       );

                    this._animA = 0;
                    this._animX = 0;
                    this._animY = 0;
                    this._animW = 0;
                    this._animH = 0;
                    this.layout();

                    this._aniRect.stopAnimation( Widget.Y     );
                    this._aniRect.stopAnimation( Widget.X     );
                    this._aniRect.stopAnimation( Widget.W     );
                    this._aniRect.stopAnimation( Widget.H     );
                    this._aniRect.stopAnimation( Widget.ALPHA );
                }
                break;

            case Host.KEY_1:
                if( type === Host.KEY_PRESSED )
                {
                    this._animA = !this._animA;
                    if( this._animA ) this._aniRect.animate( aniFade  );
                    this.layout();
                }
                break;

            case Host.KEY_2:
                if( type === Host.KEY_PRESSED )
                {
                    this._animX = !this._animX;
                    if( this._animX ) this._aniRect.animate( aniRight );
                    this.layout();
                }
                break;

            case Host.KEY_3:
                if( type === Host.KEY_PRESSED )
                {
                    this._animY = !this._animY;
                    if( this._animY ) this._aniRect.animate( aniDown  );
                    this.layout();
                }
                break;

            case Host.KEY_4:
                if( type === Host.KEY_PRESSED )
                {
                    this._animW = !this._animW;
                    if( this._animW ) this._aniRect.animate( aniWide  );
                    this.layout();
                }
                break;

            case Host.KEY_5:
                if( type === Host.KEY_PRESSED )
                {
                    this._animH = !this._animH;
                    if( this._animH ) this._aniRect.animate( aniTall  );
                    this.layout();
                }
                break;

            case Host.KEY_6:
                if( type === Host.KEY_PRESSED )
                {
                    alpha = 1 - this._grid[0].getA();

                    for( i = 0; i < this._grid.length; i++ )
                        this._grid[ i ].setA( alpha );
                }
                break;

            case Host.KEY_8:
                if( type === Host.KEY_PRESSED )
                {
                    this._aniRect.setY( top     );
                    this._aniRect.setX( left    );
                    this._aniRect.setW( narrow  );
                    this._aniRect.setH( short   );
                    this._aniRect.setA( 1       );
                }
                break;

            case Host.KEY_9:
                if( type === Host.KEY_PRESSED )
                    this._animAllOn();
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    return RectAnimTestScreen;

})();
