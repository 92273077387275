// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ImageWidget = ( function()
{
    "use strict";

    ImageWidget.prototype             = new Widget();
    ImageWidget.prototype.constructor = ImageWidget;
    ImageWidget.SHADER                = Widget.SHADER_KVT;

    function ImageWidget(){}

    ImageWidget.prototype.getUrl = function()
    {
        return this._url;
    };

    /**
     * @memberof ImageWidget
     * @param {Object}   [params]
     * @param {String}   [params.name]     - Class name override
     * @param {String}   [params.url]      - image URL
     * @param {Number}   [params.xScale]   - x-axis scale factor
     * @param {Number}   [params.yScale]   - y-axis scale factor
     * @param {Number}   [params.w]        - width in pixels
     * @param {Number}   [params.h]        - height in pixels
     * @param {Function} [params.onChange] - Callback
     * @param {Function} [params.onError]  - Callback
     * @param {Function} [params.onFocus]  - Callback
     * @param {Function} [params.onLoad]   - Callback
     * @returns {ImageWidget}
     */
    ImageWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this );
        this._className = (params && params.name) ? params.name : "ImageWidget";

        if( params )
        {
            if( params.xScale !== undefined )
                this.setScaleX( params.xScale );

            if( params.yScale !== undefined )
                this.setScaleY( params.yScale );

            this.setW( params.w );
            this.setH( params.h );

            this._url     = params.url;
            this._onError = params.onError;

            if( params.onChange )
                this.addChangeCallback( params.onChange );

            if( params.onLoad )
                this.addReadyCallback( params.onLoad );

            if( params.onFocus )
                this.setFocusListeners( params.onFocus );

            if( _x2._config._render === Config.RENDER_WEBGL )
            {
                this._paramW = params.w;
                this._paramH = params.h;
            }
        }

        return this;
    };

    ImageWidget.prototype.layoutVertices = function()
    {
        var iw   = this._image.width;
        var ih   = this._image.height;
        var w    = this._paramW || iw;
        var h    = this._paramH || ih;
        var hasW = this._paramW !== undefined;
        var hasH = this._paramH !== undefined;

        // mimic DOM behavior such that if only one axis is specified, proportions are constrained

        if( hasW && !hasH )
            h = h * w/iw;
        else if( !hasW && hasH )
            w = w * h/ih;

        // need to make sure values are rounded to float 32
        // prevent fractional dimensions from false positive of this._dataPosition[2] !== getW()
        w |= 0;
        h |= 0;

        if( this._dataPosition )
        {
            this._dataPosition[2] = this._dataPosition[6] = w;
            this._dataPosition[5] = this._dataPosition[7] = h;
        }

        this._curVal[ Widget.W ] = w;
        this._curVal[ Widget.H ] = h;
    };

    ImageWidget.prototype.render = function( alphaParent )
    {
        var gl = Widget.gl, shader = Widget.glShaderThis, w, h, wChanged, hChanged;

        if( this._texture )
        {
            w        = this.getW();
            h        = this.getH();
            wChanged = this._dataPosition[2] != w;
            hChanged = this._dataPosition[5] != h;

            if( wChanged )
                this._paramW = w;

            if( hChanged )
                this._paramH = h;

            if( wChanged || hChanged )
                this.layoutVertices();

            gl.uniform4f( shader.uvColor, alphaParent, alphaParent, alphaParent, alphaParent );

            gl.activeTexture( gl.TEXTURE0 );
            gl.bindTexture( gl.TEXTURE_2D, this._texture );
            gl.uniform1i( shader.utDiffuse1, 0 );

            gl.bindBuffer( gl.ARRAY_BUFFER, this._buffTexCoord );
            gl.vertexAttribPointer( shader.avTexCoord, 2, gl.FLOAT, false, this._dataTexCoord, 0 );
            gl.enableVertexAttribArray( shader.avTexCoord );

            gl.bindBuffer( gl.ARRAY_BUFFER, this._buffPosition );
            gl.bufferData( gl.ARRAY_BUFFER, this._dataPosition, gl.STATIC_DRAW );
            gl.vertexAttribPointer( shader.avPosition, 2, gl.FLOAT, false, this._dataPosition, 0 ); // index, size, type, bNormalized, stride, offset
            gl.enableVertexAttribArray( shader.avPosition );

            gl.drawArrays( gl.TRIANGLE_STRIP, 0, 4 );
        }
    };

    ImageWidget.prototype.resourcesLoad = function()
    {
        var self = this, img, onError, onLoad;

        onError = function()
        {
            self._failed = true;

            if( self._onError )
                self._onError();
        };

        onLoad = function()
        {
            if( _x2._config._render === Config.RENDER_WEBGL )
            {
                var gl = Widget.gl;

                self._dataPosition = new Float32Array( [ 0, 0, 0, 0, 0, 0, 0, 0 ] );
                self._dataTexCoord = new Float32Array( [ 0, 0, 1, 0, 0, 1, 1, 1 ] );

                self.setW( self._paramW );
                self.setH( self._paramH );

                self._texture = gl.createTexture();
                self._buffPosition = gl.createBuffer();
                self._buffTexCoord = gl.createBuffer();  // texture

                gl.bindBuffer( gl.ARRAY_BUFFER, self._buffPosition );
                gl.bufferData( gl.ARRAY_BUFFER, self._dataPosition, gl.STATIC_DRAW );

                gl.bindBuffer( gl.ARRAY_BUFFER, self._buffTexCoord );
                gl.bufferData( gl.ARRAY_BUFFER, self._dataTexCoord, gl.STATIC_DRAW );

                gl.bindTexture( gl.TEXTURE_2D, self._texture );
                gl.texImage2D( gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, self._image );

                // non-power-of-2 textures ARE supported IF no mipmaps and clamp to edge
                gl.texParameteri( gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR        );
                gl.texParameteri( gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR        );
                gl.texParameteri( gl.TEXTURE_2D, gl.TEXTURE_WRAP_S    , gl.CLAMP_TO_EDGE );
                gl.texParameteri( gl.TEXTURE_2D, gl.TEXTURE_WRAP_T    , gl.CLAMP_TO_EDGE );
                gl.bindTexture( gl.TEXTURE_2D, null );
            }

            self.callbackSignalReady();
            self.callbackSignalChange();
        };

        img         = document.createElement( 'img' );
        img.crossOrigin = "";
        img.onload  = onLoad;
        img.onerror = onError;

        if( _x2._config._render === Config.RENDER_DOM )
            this._div = img;
        else if( _x2._config._render === Config.RENDER_WEBGL )
            this._image = img;

        if( this._url )
            img.src = this._url;

        Widget.prototype.resourcesLoad.call( this );
    };

    ImageWidget.prototype.setH = function( val )
    {
        Widget.prototype.setH.call( this, val );

        if( _x2._config._render === Config.RENDER_WEBGL )
        {
            this._paramH = val;

            if( this._image && this._image.complete )
                this.layoutVertices();
        }
    };

    ImageWidget.prototype.setOnError = function( funct )
    {
        this._onError = funct;
    }

    /**
     * Set the URL of the image to display
     * @memberof ImageWidget
     * @param {String} [url] - image URL, if not defined image will be cleared
     */
    ImageWidget.prototype.setUrl = function( url )
    {
        var img;

        this._url = url;

        if( _x2._config._render === Config.RENDER_DOM )
            img = this._div;
        else if( _x2._config._render === Config.RENDER_WEBGL )
            img = this._image;

        if( img )
        {
            if( img.src === url )
            {
                if( ! this._failed )
                {
                    this.callbackSignalReady();
                    this.callbackSignalChange();
                }
            }
            else
            {
                this._failed = false;

                img.src = url ? url : "";
            }
        }
    };

    ImageWidget.prototype.setW = function( val )
    {
        Widget.prototype.setW.call( this, val );

        if( _x2._config._render === Config.RENDER_WEBGL )
        {
            this._paramW = val;

            if( this._image && this._image.complete )
                this.layoutVertices();
        }
    };
    /**
     * This will replace the height and weight for an image url with regexp
     */
    ImageWidget.prototype.setUrlWithDimension = function( w, h ) {
        var url = this.getUrl();
        if( url && w && h ) {
            var newUrl = url.replace(/width=\d{1,3}&height=\d{1,3}/g,'width=' + w +'&height=' + h);
            if( newUrl ) {
                this.setUrl( newUrl );
            }
        }
    }

    return ImageWidget;

})();
