// Copyright (C) 2018 Comcast Corporation, All Rights Reserved
/*
    This class wraps/normalizes errors from API calls. There are two error response signatures
    possible for errors received in the AJAX response.

    This is also used to wrap errors thrown internally (params.local), for example when we receive a
    valid http response (200) with invalid data.

    example xtvapi.cloudtv.comcast.net response:
    {
        "reason": "Bad CloudDvr Token",
        "statusCode": 403,
        "statusSubCode": "403-103"
    }
*/

/**
 * @class
 */
window.ApiError = ( function()
{
    "use strict";

    function ApiError(){}

    /**
     * Initialize the object with either and http object or error/description
     * @param {Object} params
     * @param {XMLHttpRequest} [params.http]  - error coming from http request (XMLHttpRequest) call
     * @param {Object}         [params.flash] - provisioning/drm/media error returned from a 3rd party library (security client or player platform)
     * @param {string}         [params.local] - string describing error triggered by the app.
     * @return {ApiError}
     */
    ApiError.prototype.init = function( params )
    {
        var response, responseObj;

        if( params.http )
        {
            response = params.http.response;
            if( response )
            {
                try //try to parse out properties from known JSON responses
                {
                    responseObj = JSON.parse( response );

                    if( responseObj.statusCode ) //xtvapi
                    {
                        this._major       = responseObj.statusCode;
                        this._description = responseObj.reason;

                        if( responseObj.statusSubCode )
                            this._minor = responseObj.statusSubCode.split('-')[1];
                    }
                    else if( responseObj.error ) //login.comcast.net
                    {
                        this._major       = params.http.status;
                        this._minor       = responseObj.error;
                        this._description = responseObj.description;
                    }
                }
                catch(e)
                {
                    console.log( "WARNING -- Unexpected error payload:" + response );
                }
            }

            //default to http status if the error metadata was not found.
            if( this._major === undefined )
                this._major = params.http.status;

            if( params.api )
                this._api = params.api;

            this._httpStatus   = params.http.status;
            this._httpResponse = params.http.response;
        }
        else if( params.flash )
        {
            var error = params.flash;

            if( error.major )
            {
                if( error.major.indexOf && error.major.indexOf('.') !== -1 )
                {
                    var tokens = error.major.split('.');
                    this._major = tokens[0];
                    this._minor = tokens[1];
                }
                else
                {
                    this._major = error.major;
                    this._minor = error.minor;
                }
            }

            this._description = error.description;
        }
        else
            this._description = params.local;

        return this;
    };

    ApiError.prototype.getMajor = function()
    {
        var retval = (this._major === null) ? undefined : this._major;

        if( isNaN(retval) === false )
            retval = retval.toString();

        return retval;
    };

    ApiError.prototype.getMinor = function()
    {
        var retval = (this._minor === null) ? undefined : this._minor;

        if( isNaN(retval) === false )
            retval = retval.toString();

        return retval;
    };

    ApiError.prototype.getHttpResponse = function()
    {
        return this._httpResponse;
    };

    ApiError.prototype.getHttpStatus = function()
    {
        return this._httpStatus;
    };

    ApiError.prototype.getDescription = function()
    {
        return this._description;
    };

    ApiError.prototype.toString = function()
    {
        return "ERROR (ApiError) >> api:" + this._api + " major:" + this._major + " minor:" + this._minor + " description:" + this._description;
    };

    ApiError.prototype.setApi = function( api )
    {
        this._api = api;
    };

    ApiError.prototype.getApi = function()
    {
        return this._api;
    };

    return ApiError;
})();
