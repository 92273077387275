// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.VodLeafScreen = (function()
{
    "use strict";

    VodLeafScreen.prototype = new Screen();

    function VodLeafScreen(){}

    VodLeafScreen.prototype.gotFocus = function()
    {
        if( this._ready === true )
            this.speak( _x2.requestFocus( this._grid ) );
    };

    /**
     * @param {Object}           params
     * @param {BrowseCollection} params.folder - data folder to display
     * @param {String}           params.path   - root path to this screen
     */

    VodLeafScreen.prototype.init = function( params )
    {
        var self = this;

        Screen.prototype.init.call( this );
        this._className     = "VodLeafScreen";
        this._telemetryName = "Browse";

        this._grid = new VodGridWidget().init( params.folder );
        this.addWidget( this._grid, Style._safeLeft );

        this.setBreadCrumbs( params.path, params.folder.getTitle() );
        this.addReadyCallback( function(){ self.layout(); } );

        this.setHeaderFilters( _x2._settings.getValue( Settings.Key.FILTERS ) );

        return this;
    };

    VodLeafScreen.prototype.layout = function()
    {
        this._grid.setY( this.getHeaderBottom() + _x2.scaleValInt( 42 ) );

        this._ready = true;

        if( _x2._focus === this )
            this.speak( _x2.requestFocus( this._grid ) );
    };

    VodLeafScreen.prototype.speak = function( focus )
    {
        if( focus )
        {
            this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr + ". " );
            this._speakTarget.setSpeechParams( "Press up, down, left or right to review programs. Press OK to select. ", focus._speakRole, this, false );
        }
    };

    VodLeafScreen.prototype.refreshCheck = function()
    {
        if( this._grid && this._grid.refresh )
            this._grid.refresh();
    };

    return VodLeafScreen;

})();
