// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.EpisodesScreen = (function()
{
    "use strict";

    EpisodesScreen.prototype = new Screen();

    function EpisodesScreen(){}

    /**
     * memberof EpisodesScreen
     * @param {Object} params
     * @param {Entity|RecordingGroup|PurchaseGroup} params.entity - entity to display
     * @returns {EpisodesScreen}
     */
    EpisodesScreen.prototype.init = function( params )
    {
        var xDims,
            yDims,
            seasonParams = {},
            episodeParams = {},
            onPosterError,
            onSeasonExpanded,
            episodeStepListener,
            setEpisodeListData,
            expandedSeason,
            page,
            perPage = EntityOptions.programsPerPage,
            self = this;

        Screen.prototype.init.call( this );
        this._className     = "EpisodesScreen";
        this._telemetryName = "Entity Episodes";

        //setup dims
        xDims = [Style._safeLeft, _x2.scaleValInt( 303 ), _x2.scaleValInt( 52 ), Style._safeRight];
        yDims = [ _x2.scaleValInt( 150 ), _x2.scaleValInt( 404 )]; // First Y is absolute position; NOTE: Can't use 'y = {Screen.}getHeaderBottom() - 2 + 42;' due to _time.getH() returning undefined -> NaN

        onPosterError = function()
        {
            var fallback = self._entity.getFallbackImageLink( 303, 404 );

            if( !fallback || ( self._poster.getUrl() === fallback ) ) //hide if no fallback or fallback == current
                self._poster.setA( 0 );
            else
                self._poster.setUrl( fallback );
        };

        //add poster
        this._poster = new ImageWidget().init( { w: xDims[1], h: yDims[1], onError:onPosterError } );
        this.addWidget( this._poster, Style._safeLeft, yDims[0] );
        this._poster.setA(0);

        setEpisodeListData = function()
        {
            var episodes = expandedSeason.getEpisodes( page - 1 );

            self._distractor.hide();

            if( page > 1 )
                self._episodesList.appendData( episodes );
            else
                self._episodesList.setData( episodes );

            self._episodesList.setBlockingOnData( false );
        };

        episodeStepListener = function( val )
        {
            if( Math.floor( Math.round( val ) ) > ( page * perPage ) - self._episodesListRowCount )
            {
                if( expandedSeason.getEpisodeCount() > ( page * perPage ) )
                {
                    page++;
                    self._distractor.show();
                    self._episodesList.setBlockingOnData( true );
                    expandedSeason.fetchEpisodes( page, perPage ).then( setEpisodeListData );
                }
            }
        };

        onSeasonExpanded = function( rowObject )
        {
            //if the list row data contains a Season object this is dynamic & paged data. ignore or other cases.
            if( rowObject.season )
            {
                page           = 1;
                expandedSeason = rowObject.season;

                //if the season has data then set it, otherwise fetch it first
                if( expandedSeason.getEpisodes().length > 0 )
                    setEpisodeListData();
                else
                {
                    self._distractor.show();
                    self._episodesList.setData([]);
                    expandedSeason.fetchEpisodes( page, perPage ).then( setEpisodeListData );
                }
            }
        };

        //setup episode list
        episodeParams.w            = _x2._config._screenW - xDims.reduce( function( a, b ){return a + b} );
        episodeParams.h            = _x2._config._screenH - yDims[0] - Style._safeBottom;
        episodeParams.maxIndex     = 1;
        episodeParams.type         = EpisodeRowWidget;
        episodeParams.stepListener = episodeStepListener;
        this._episodesList         = new ListWidget().init( episodeParams );
        this._episodesListRowCount = this._episodesList.getVisibleRowCount();

        //setup season list
        seasonParams.w        = episodeParams.w;
        seasonParams.h        = episodeParams.h;
        seasonParams.maxIndex = 0;
        seasonParams.type     = ExpandingListRowWidget;
        seasonParams.obj      = { list:this._episodesList, baseWidget:this, onExpand:onSeasonExpanded };
        this._seasonList      = new ListWidget().init( seasonParams );

        this.addWidget( this._seasonList, xDims[0] + xDims[1] + xDims[2], yDims[0] );
        this._seasonList.addWidget( this._episodesList );

        this._topSep = new RectWidget().init( { w:episodeParams.w, h:ListRowWidget.DIVIDER_H, color:"#303030" } );
        this.addWidget( this._topSep, this._seasonList.getX() );

        this._distractor = new DistractorWidget().init( {} );
        this._distractor.addReadyCallback( function()
        {
            self._distractor.centerOnWidget( self );
        });
        this.addWidget( this._distractor );

        window.ll( "tagScreen", "Entity Episodes" );
        _x2._telemetry.sendScreenViewed( this._telemetryName );

        setTimeout( function() { self.loadData( params.entity ) }, 1 );

        if( params.entity && params.entity instanceof RecordingGroup )
        {
            var setPoster = function( program )
            {
                self._poster.setUrl( program.getImageLink( 303, 404 ) );
                self._poster.setA(1);
            };

            params.entity.getProgram().then( setPoster )
        }

        this.addReadyCallback( function(){ self.layout(); } );

        return this;
    };

    EpisodesScreen.prototype.layout = function()
    {
        var y = this.getHeaderBottom() + _x2.scaleValInt( 42 );

        this._poster.setY( y );
        this._seasonList.setY( y );
        this._topSep.setY( y - ListRowWidget.DIVIDER_H );
        this._ready = true;

        if( _x2._focus === this && this._seasonList.getMaxIndex() > 0 )
            this.speak( _x2.requestFocus( this._seasonList, true ) );
    };

    EpisodesScreen.prototype.gotFocus = function()
    {
        if( this._jumpToIndex !== undefined )
        {
            if( this._jumpToIndex !== -1 )
            {
                this._episodesList.setJumpToIndex( this._jumpToIndex );
                this.speak( _x2.requestFocus( this._episodesList, true ) );
            }
            else
            {
                this._seasonList._expanded = false; //TODO: _expanded should probably be set to false in ListWidget.setData()
                this.speak( _x2.requestFocus( this._seasonList, true ) );
            }
        }
    };

    EpisodesScreen.prototype.loadData = function( data )
    {
        if( data )
            this._entity = data;

        if( this._distractor )
            this._distractor.hide();

        var i, seasonNumber, episodes, seasons, count, self = this;
        var title = this._entity.getTitle ? this._entity.getTitle() : this._entity.getName();

        if( this._entity instanceof Entity )
        {
            episodes = this._entity.getEntityOptions().getEpisodes();

            if( episodes.length === 0 )
                seasons = this._entity.getEntityOptions().getSeasons();
        }
        else if( this._entity instanceof RecordingGroup )
            episodes = this._entity.getRecordings();
        else if( this._entity instanceof PurchaseGroup )
            episodes = this._entity.getPurchases();
        else
            episodes = this._entity.getEpisodes();

        this.setBreadCrumbs( title );

        if( this._entity.getImageLink )
        {
            this._poster.setUrl( this._entity.getImageLink( 303, 404 ) );
            this._poster.setA(1);
        }

        //set the season/episode data
        this._seasons = [];

        if( seasons && seasons.length > 0 )
        {
            for( i=0; i<seasons.length; i++ )
            {
                seasonNumber = seasons[i].getNumber();

                count = seasons[i].getEpisodeCount();
                title = ( seasonNumber ? "Season " + seasonNumber: "Other Episodes" ) + " (" + count + ")";
                this._seasons.push( { season:seasons[i], number:seasonNumber, title:title, entityCount:count } );
            }
        }
        else
        {
            for( i=0; i<episodes.length; i++ )
            {
                var entity  = episodes[i];
                var episode = episodes[i];

                if( episode instanceof Showing )
                {
                    if( episode.getEntity() )
                        entity = episode.getEntity();
                }

                seasonNumber = entity.getSeasonNumber() ? parseInt(entity.getSeasonNumber()) : 0;

                title = seasonNumber ? "Season " + seasonNumber: "Other Episodes";

                if(!this._seasons[seasonNumber])
                    this._seasons[seasonNumber] = { number:seasonNumber, title:title, entries:[] };

                if( ! episode._deleteListener ) //don't set the listener twice.
                    episode._deleteListener = function(episode){ self.removeRecording( episode ); return false };

                this._seasons[seasonNumber].entries.push(episodes[i]);
            }

            //clean up and sort the season array
            for( i=0; i<this._seasons.length; i++ )
            {
                if( this._seasons[i] && this._seasons[i].entries.length > 0 )
                {
                    this._seasons[i].title += " (" + this._seasons[i].entries.length + ")";
                    this._seasons[i].entries.sort( function( a, b ){ return ( b.getEpisodeSortNumber() - a.getEpisodeSortNumber()) });
                }
                else
                {
                    this._seasons.splice(i,1);
                    i--;
                }
            }
        }

        if( this._seasons.length > 1 )
            this._seasons.sort( function( a, b ){ return ( b.number - a.number ) } );

        this._seasonList.setData( this._seasons );

        if( _x2._focus === this && this._ready )
            this.speak( _x2.requestFocus( this._seasonList, true ) );
    };

    EpisodesScreen.prototype.removeRecording = function( episode )
    {
        var i, j, spliced, doBreak, id = episode.getId();

        for( i = 0; i < this._seasons.length; i++ )
        {
            var episodes = this._seasons[i].entries;

            for( j = 0; j < episodes.length; j++ )
            {
                if( id === episodes[j].getId() )
                {
                    this._entity.removeRecording( episode );

                    episodes.splice( j, 1 );

                    if( episodes.length === 0 )
                    {
                        this._seasons.splice( i, 1 );
                        spliced = true;
                    }

                    if( this._seasons.length > 0 )
                    {
                        //hijack focus so we get the gotFocus call (not the now-deleted row)
                        this._lastFocus = this;
                        if( this._seasons.length > 1 || spliced )
                        {
                            //hide the episode list if there is more than one season, or we just deleted the selected season
                            this._jumpToIndex = -1;
                            this._episodesList.setA(0);
                        }
                        else
                        {
                            this._jumpToIndex = ( j > 0 ? j-1 : 0 );
                            this._episodesList.setData( episodes );
                        }

                        this.loadData();
                    }
                    else
                        _x2.popScreen( this );

                    doBreak = true;
                    break;
                }
            }
            if( doBreak )
                break;
        }
    };

    EpisodesScreen.prototype.speak = function( focus )
    {
        this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press up or down to review seasons. Press OK to reviews episodes in a season. ", focus._speakRole, this, false );
    };

    return EpisodesScreen;

})();
