// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.LastWatched = (function()
{
    "use strict";

    function LastWatched(){}

    var LOCAL_STORAGE_KEY = "lastWatched";

    function createEntity( obj, type )
    {
        var retval;

        if( type === "Channel" )
            retval = new Channel().init( obj );
        else if( type === "Entity" )
            retval = new Entity().init( obj );
        else if( type === "Recording" )
            retval = new Recording().init( obj );
        else if( type === "VodShowing" )
            retval = new VodShowing().init( obj );
        else
            console.error( "Need to support data type = " + type );

        return retval;
    }

    LastWatched.prototype.getEntries = function()
    {
        var self = this;

        var foo = function( resolve )
        {
            var setEntries = function()
            {
                var struct = self.read();
                var array  = struct.entries;
                var retval = [];

                for( var i = 0; i < array.length; i++ )
                {
                    var foo = createEntity( array[i].data, array[i].dataType );

                    if( foo )
                    {
                        var rentalTime = foo.get("rentalDateTime");
                        if( rentalTime )
                        {
                            var expires = new Date( rentalTime ).getTime() + ( foo.getInteger("rentalPeriod") * 60000 );
                            if( Date.now() < expires )
                                retval.push( { entity:foo, data:array[i] } );
                        }
                        else
                            retval.push( { entity:foo, data:array[i] } );
                    }
                }

                for( i = retval.length - 1; i >= 0; i-- )
                {
                    if( retval[i].entity instanceof Channel )
                    {
                        retval[i].entity = _x2._channelCollection.getChannel( retval[i].entity.getSelfLink() );

                        if( retval[i].entity === undefined )
                        {
                            console.error( "NO MATCH: DELETE ENTRY" );
                            retval.splice( i, 1 );
                        }
                    }
                }

                resolve( retval );
            };

            _x2._channelCollection && _x2._channelCollection.fetch().then( setEntries );
        };

        return new Promise( foo );
    };

    LastWatched.prototype.read = function()
    {
        var retval = { version:1, entries:[] }, parsed;
        var json   = Host.getLocalStorage( LOCAL_STORAGE_KEY );

        if( json )
        {
            parsed = JSON.parse( json );

            var version = parsed.version;

            if( version === undefined )
            {
                console.error( "Unsupported version of last watched = " + version + ". Resetting." );
                this.write( retval );
            }
            else
                retval = parsed;
        }
        else
            this.write( retval );

        return retval;
    };

    LastWatched.prototype.write = function( obj )
    {
        _x2._config._host.setLocalStorage( LOCAL_STORAGE_KEY, JSON.stringify( obj ) );
    };

    return LastWatched;

})();
