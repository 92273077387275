// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.Settings = (function()
{
    "use strict";

    Settings.Key = Object.freeze
    ({
        CC_ENABLED     : "ccEnabled",
        CC_STYLING     : "ccStyling",
        DV_ENABLED     : "dvEnabled",
        FILTERS        : "filters",
        FORMAT         : "format",
        LAST_CHAN      : "lastChannel",
        PURCHASES      : "purchases",
        SAP_ENABLED    : "sapEnabled",
        SAP_LANG       : "sapLang",
        TERMS          : "terms"
    });

    Settings.StyleDefaults = Object.freeze
    ({
        fontStyle             : "default",
        penSize               : "standard",
        textBackgroundColor   : "black",
        textBackgroundOpacity : "solid",
        textEdgeColor         : "white",
        textEdgeStyle         : "none",
        textForegroundColor   : "white",
        textForegroundOpacity : "solid"
    });

    Settings.Default = Object.freeze
    ({
        ccEnabled     : false,
        ccStyling     : Settings.StyleDefaults,
        dvEnabled     : false,
        filters       : { hd:false, cc:false, sap:false, dvs:false },
        format        : "hd",
        sapEnabled    : false,
        sapLang       : "en",
        terms         : 0
    });

    Settings.TERMS_VERSION = 2;

    var LOCAL_STORAGE_KEY = "settings";

    function Settings()
    {
        var json = localStorage[LOCAL_STORAGE_KEY], key;

        if( json )
        {
            this._data = JSON.parse( json );

            if( typeof this._data[Settings.Key.LAST_CHAN] !== "object" )  // changed from string to object
                this._data[Settings.Key.LAST_CHAN] = undefined;

            for( key in Settings.Key )
            {
                key = Settings.Key[key];

                if( this._data[key] === undefined )
                {
                    console.warn( "Missing settings for: " + key );
                    this._data[key] = Settings.Default[key];
                    this.write();
                }
            }
        }
        else
        {
            this._data = {};
            this.setDefaults();
            this.write();
        }
    }

    Settings.prototype.getValue = function( key )
    {
        return this._data[key];
    };

    Settings.prototype.setDefaults = function()
    {
        for( var key in Settings.Key )
        {
            key = Settings.Key[key];
            this._data[key] = Settings.Default[key];
        }
    };

    Settings.prototype.setValue = function( key, value )
    {
        if( key !== undefined )
        {
            this._data[key] = value;
            this.write();
        }
        else
            console.error( "Trying to set a setting using an undefined key." );
    };

    Settings.prototype.write = function()
    {
        localStorage.setItem( LOCAL_STORAGE_KEY, JSON.stringify(this._data) );
    };

    return Settings;

})();
