// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.AniTestScreen = ( function()
{
    "use strict";

    AniTestScreen.prototype = new Screen();

    function AniTestScreen(){}

    AniTestScreen.prototype.init = function()
    {
        this._className = "AniTestScreen";

        Screen.prototype.init.call( this );

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        this._numEasing = Object.keys( Widget.Easing ).length;

        this._rects = new Array( this._numEasing );
        this._texts = new Array( this._numEasing );
        this._endx  = _x2._config._screenW/2;
        var p = 2; // padding
        var s = ((_x2._config._screenH - Style._safeTop - Style._safeBottom - p*this._numEasing) / this._numEasing)|0;
        var w = s; // 15
        var h = s; // 15

        var types =
        [
             Widget.Easing.QUAD_IN
            ,Widget.Easing.CUBIC_IN
            ,Widget.Easing.QUART_IN
            ,Widget.Easing.QUINT_IN
            ,Widget.Easing.SEXTIC_IN
            ,Widget.Easing.SEPTIC_IN
            ,Widget.Easing.OCTIC_IN

            ,Widget.Easing.BACK_IN
            ,Widget.Easing.BOUNCE_IN
            ,Widget.Easing.CIRC_IN
            ,Widget.Easing.ELASTIC_IN
            ,Widget.Easing.EXPO_IN
            ,Widget.Easing.SINE_IN

            ,Widget.Easing.LINEAR

            ,Widget.Easing.QUAD_IN_OUT
            ,Widget.Easing.CUBIC_IN_OUT
            ,Widget.Easing.QUART_IN_OUT
            ,Widget.Easing.QUINT_IN_OUT
            ,Widget.Easing.SEPTIC_IN_OUT
            ,Widget.Easing.SEXTIC_IN_OUT
            ,Widget.Easing.OCTIC_IN_OUT

            ,Widget.Easing.BACK_IN_OUT
            ,Widget.Easing.BOUNCE_IN_OUT
            ,Widget.Easing.CIRC_IN_OUT
            ,Widget.Easing.ELASTIC_IN_OUT
            ,Widget.Easing.EXPO_IN_OUT
            ,Widget.Easing.SINE_IN_OUT

            ,Widget.Easing.QUAD_OUT
            ,Widget.Easing.CUBIC_OUT
            ,Widget.Easing.QUART_OUT
            ,Widget.Easing.QUINT_OUT
            ,Widget.Easing.SEPTIC_OUT
            ,Widget.Easing.SEXTIC_OUT
            ,Widget.Easing.OCTIC_OUT

            ,Widget.Easing.BACK_OUT
            ,Widget.Easing.BOUNCE_OUT
            ,Widget.Easing.CIRC_OUT
            ,Widget.Easing.ELASTIC_OUT
            ,Widget.Easing.EXPO_OUT
            ,Widget.Easing.SINE_OUT
        ];
        this._types = types;

        var names =
        [
             "In Quadratic        "
            ,"In Cubic            "
            ,"In Quartic          "
            ,"In Quintic          "
            ,"In Sextic           "
            ,"In Septic           "
            ,"In Octic            "

            ,"In Back             "
            ,"In Bounce           "
            ,"In Circle           "
            ,"In Elastic          "
            ,"In Exponential 2    "
            ,"In Sine             "

            ,"Linear"

            ,"In/Out Cubic        "
            ,"In/Out Quadratic    "
            ,"In/Out Quartic      "
            ,"In/Out Quintic      "
            ,"In/Out Sextic       "
            ,"In/Out Septic       "
            ,"In/Out Octic        "

            ,"In/Out Back         "
            ,"In/Out Bounce       "
            ,"In/Out Circle       "
            ,"In/Out Elastic      "
            ,"In/Out Exponential 2"
            ,"In/Out Sine         "

            ,"Out Cubic           "
            ,"Out Quadratic       "
            ,"Out Quartic         "
            ,"Out Quintic         "
            ,"Out Sextic          "
            ,"Out Septic          "
            ,"Out Octic           "

            ,"Out Back            "
            ,"Out Bounce          "
            ,"Out Circle          "
            ,"Out Elastic         "
            ,"Out Exponential 2   "
            ,"Out Sine            "
        ];

        var colors = [ "#F00", "#FF0", "#0F0", "#0FF", "#00F", "#F0F", "#777", "#FFF" ];
        for( var i = 0; i < this._rects.length; i++ )
        {
            var color;

            if( types[i] === Widget.Easing.QUAD_IN     ) color = colors[ 0 ];
            if( types[i] === Widget.Easing.QUAD_IN_OUT ) color = colors[ 2 ];
            if( types[i] === Widget.Easing.QUAD_OUT    ) color = colors[ 4 ];

            if( types[i] === Widget.Easing.BACK_IN     ) color = colors[ 1 ];
            if( types[i] === Widget.Easing.BACK_IN_OUT ) color = colors[ 3 ];
            if( types[i] === Widget.Easing.BACK_OUT    ) color = colors[ 5 ];

            if( types[i] === Widget.Easing.LINEAR      ) color = colors[ 7 ];

            var style = new Style( { color:color, font:"medium", fontSize:h, whiteSpace:"nowrap" } );

            this._rects[i] = new RectWidget().init( { w:w, h:h, color:color } );
            this._texts[i] = new StringWidget().init( { text: names[i], style: style } );
            var y = Style._safeTop + i * (w + p); // 15 -> 20;

            this.addWidget( this._rects[i], Style._safeLeft, y );
            this.addWidget( this._texts[i], this._endx + 2*w, y );
        }

        var lineStyle = { w:1, h: _x2._config._screenH, color:"#404040" };
        this._left    = new RectWidget().init( lineStyle );
        this._right   = new RectWidget().init( lineStyle );
        this._mid     = new RectWidget().init( lineStyle );
        this.addWidget( this._left , Style._safeLeft - 1, 0 );
        this.addWidget( this._right, this._endx      + w, 0 );
        this.addWidget( this._mid  ,(Style._safeLeft+this._endx+w)/2,0);

        return this;
    };

    AniTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true, i;

        switch( val )
        {
            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED )
                    for( i = 0; i < this._numEasing; i++ )
                        this._rects[i].animate( {  duration:2000, x:this._endx, easing:this._types[i] } );
                break;

            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                    for( i = 0; i < this._numEasing; i++ )
                        this._rects[i].animate( {  duration:2000, x:Style._safeLeft, easing:this._types[i] } );
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    return AniTestScreen;

})();
