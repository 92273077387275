// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.GridListingsWidget = (function()
{
    "use strict";

    GridListingsWidget.prototype = new Widget();

    function GridListingsWidget(){}

    GridListingsWidget.prototype.filtersChanged = function()
    {
        this._grid.filtersChanged();
    };

    GridListingsWidget.prototype.getLocalyticsTabName = function()
    {
        return "Live TV: All Channels";
    };

    GridListingsWidget.prototype.gotFocus = function()
    {
        _x2.requestFocus( this._grid );
    };

    /**
     * Initializer
     * @memberof GridListingsWidget
     * @returns {GridListingsWidget}
     */
    GridListingsWidget.prototype.init = function()
    {
        Widget.prototype.init.call( this );
        this._className  = "GridListingsWidget";
        this._selectable = true;

        this.setW( _x2._config._screenW );

        this._grid = new GridWidget().init( { w:_x2._config._screenW - Style._safeLeft, h:_x2._config._screenH, hasInfo:true } );
        this.addWidget( this._grid, Style._safeLeft );

        return this;
    };

    GridListingsWidget.prototype.loadData = function()
    {
        this._grid.refresh( screen );
    };

    GridListingsWidget.prototype.refresh = function( screen )
    {
        this._grid.refresh( screen );
    };

    GridListingsWidget.prototype.setJumpToChannel = function( channel )
    {
        this._grid.setJumpToChannel( channel );
    };

    // Common code:
    //    GridListingsWidget.prototype.startViewTimestamp
    //    GuideViewFilterWidget.prototype.startViewTimestamp
    GridListingsWidget.prototype.startViewTimestamp = function()
    {
        this._timestampPageView = new Date();
    };

    GridListingsWidget.prototype.stopViewTimestamp = function()
    {
        // Back Button while in gallery view, and view duration
        var whichTab = this.getLocalyticsTabName();
        this._durationPageView = new Date() - this._timestampPageView;
        window.ll( "tagEvent", "Back Button " + whichTab, { "Duration": this._durationPageView } );
        _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Live TV: Back Button: " +  whichTab + ", duration: " + this._durationPageView ) : Config.NOP();
    };

    return GridListingsWidget;

})();
