// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ChannelCollectionScreen = (function()
{
    "use strict";

    ChannelCollectionScreen.prototype = new Screen();

    var infoStr = "Press up or down to review programs on other channels. Press OK to watch.";

    function ChannelCollectionScreen(){}

    ChannelCollectionScreen.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut(0);

        if( this._ready )
        {
            var focus = _x2.requestFocus( this._list, true );

            this._speakTarget.setText(  this.getBreadCrumb() + "." + focus._speakStr + "." );
            this._speakTarget.setSpeechParams( infoStr, focus._speakRole, this, false );
        }
    };

    /**
     * @memberOf ChannelCollectionScreen
     * @param {Organization} org
     * @return {ChannelCollectionScreen}
     */
    ChannelCollectionScreen.prototype.init = function( org )
    {
        Screen.prototype.init.call( this );
        this._className     = "ChannelCollectionScreen";
        this._telemetryName = "Channel Collection";

        var self       = this, sep, layout, onDataReady, numEntries;
        var listParams = {};
        var listW      = _x2._config._screenW - Style._safeRight - Style._safeLeft;
        var listH      = _x2._config._screenH - Style._safeTop - Style._safeBottom;
        var style      = new Style( { color:"#a3a3a3", font:"medium" , fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", textAlign:"right" } );

        listParams.w        = listW;
        listParams.h        = listH;
        listParams.maxIndex = 1;
        listParams.type     = ChannelRowWidget;

        layout = function()
        {
            var y = self.getHeaderBottom() + _x2.scaleValInt( 42 );
            numEntries.setY( y );
            y += numEntries.getH() + _x2.scaleValInt( 30 );
            sep.setY( y );
            y += sep.getH();
            self._list.setY( y );
            self._list.setH( _x2._config._screenH - y );
        };

        onDataReady = function()
        {
            var channels = self._organization.getChannels();

            if( self._distractor )
                self._distractor.hide();

            numEntries.setText( channels.length + " Results" );

            self._list.setData( channels );
            self._ready = true;

            if( _x2._focus === self )
            {
                var focus = _x2.requestFocus( self._list );
                self._speakTarget.setText(  self.getBreadCrumb() + "." + focus._speakStr + "." );
                self._speakTarget.setSpeechParams( infoStr, focus._speakRole, self, false );
            }
        };

        numEntries = new StringWidget().init({ text:"Results", style:style });
        numEntries.setW( _x2._config._screenW / 4 );
        this.addWidget( numEntries, 3 * _x2._config._screenW / 4 - Style._safeRight );

        sep = new RectWidget().init( { w:listW, h:ListRowWidget.DIVIDER_H, color:"#262626" } );
        this.addWidget( sep, Style._safeLeft );

        this._list  = new ListWidget().init( listParams );
        this.addWidget( this._list, Style._safeLeft, 0);

        //load the org data
        this._organization = org;
        org.loadData().then( onDataReady );

        this.setBreadCrumbs( "Search", org.getTitle() + " Channels" );
        this.addReadyCallback( layout );

        return this;
    };

    return ChannelCollectionScreen;

})();
