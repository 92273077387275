// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * This class functions as a container for generic collections of entities. Whereas a
 * BrowseCollection wraps a corresponding XTVAPI data object returned, this class wraps
 * a simple array of entities
 *
 */
window.Collection = (function()
{
    "use strict";

    Collection.prototype = new DataObject();

    function Collection(){}

    /**
     * Initialize the object
     * @memberOf Collection
     * @param {Object[]}                 entities           - The collection of entities
     * @param {String}                   title              - The title of this collection i.e. "Recently Watched"
     * @param {XtvApi.EntityRenderStyle} [childRenderStyle] - set the render style of this collection
     * @return {Collection}
     */
    Collection.prototype.init = function( entities, title, childRenderStyle )
    {
        this._entities         = entities;
        this._title            = title;
        this._childRenderStyle = childRenderStyle;

        return this;
    };

    /**
     * Get the entity render style. Default to poster for mixed collection
     * @return {XtvApi.EntityRenderStyle|String}
     */
    Collection.prototype.getChildRenderStyle = function()
    {
        return this._childRenderStyle || XtvApi.EntityRenderStyle.POSTER;
    };

    /**
     * Get entities (align method with BrowseCollection)
     * @return {Object[]}
     */
    Collection.prototype.getEmbeddedBrowseItems = function()
    {
        return this.getEntities();
    };

    /**
     * Get the entities collection
     * @param {Number} [max] - Maximum number of entities to return
     * @return {Object[]}
     */
    Collection.prototype.getEntities = function( max )
    {
        var entities;

        if( this._entities.length > 12 )
        {
            if( ( this._entities[0] instanceof Collection ) === false )
            {
                var all = new Collection().init( this._entities, this._title );
                all.setMeta({ all:true });
                this._entities.unshift( all );
            }

            max++ //adjust for 'all' at [0] and return one more.
        }

        if( max )
            entities = this._entities.slice(0,max);
        else
            entities = this._entities;

        return entities;
    };

    /**
     * Get the number of entities in the collection
     * @return {Number}
     */
    Collection.prototype.getTotalEntityCount = function()
    {
        var count = this._entities.length;

        if( this._entities.length > 12 )
        {
            if( this._entities[0].getMeta().all )
                count--;
        }

        return count;
    };

    /**
     * Get the title of the collection
     * @return {String}
     */
    Collection.prototype.getTitle = function()
    {
        return this._title;
    };

    return Collection;
})();
