// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

"use strict";

window.ImageTestScreen = ( function()
{
    ImageTestScreen.prototype = new Screen();

    function ImageTestScreen(){}

    ImageTestScreen.prototype.init = function()
    {
        Screen.prototype.init.call( this, { noHeader:true } );
        this._className = "ImageTestScreen";

        var self  = this, staticOnLoad;
        var image = _x2._config._imageRootMso + "msoLogo.png";
        var stepX = (_x2._config._screenW - Style._safeLeft - Style._safeRight) / 5;
        var stepY = _x2._config._screenW / 10;
        var x0    = Style._safeLeft;
        var x1    = x0 + stepX;
        var x2    = x1 + stepX;
        var x3    = x2 + stepX;
        var x4    = x3 + stepX;
        var y0    = Style._safeTop;
        var y1    = y0 + stepY;
        var y2    = y1 + stepY;
        var y3    = y2 + stepY;
        var y4    = y3 + stepY;

        staticOnLoad = function()
        {
            var w  = self._imageW = self._naturalIm.getW();
            var h  = self._imageH = self._naturalIm.getH();

            self._h0.setX( x2 );
            self._h0.setY( y2 + h/2 );
            self._h1.setX( x2 );
            self._h1.setY( y2 + h/4 );
            self._h2.setX( x2 );
            self._h2.setY( y2 + h/4*3 );
            self._h3.setX( x2 );
            self._h3.setY( y2 - h/2 );
            self._h4.setX( x2 );
            self._h4.setY( y2 + h/2*3 );

            self._v0.setX( x2 + w/2 );
            self._v0.setY( y2 );
            self._v1.setX( x2 + w/4 );
            self._v1.setY( y2 );
            self._v2.setX( x2 + w/4*3 );
            self._v2.setY( y2 );
            self._v3.setX( x2 - w/2 );
            self._v3.setY( y2 );
            self._v4.setX( x2 + w/2*3 );
            self._v4.setY( y2 );
        };

        this._width0  = _x2.scaleVal( 150 );
        this._width1  = _x2.scaleVal( 400 );
        this._height0 = _x2.scaleVal(  75 );
        this._height1 = _x2.scaleVal( 150 );

        this._naturalIm = new ImageWidget().init( { url:image, name:"Natural", onLoad:staticOnLoad } );
        this._widthIm0  = new ImageWidget().init( { url:image, name:"Width" ,                  w:this._width0 } );
        this._widthIm1  = new ImageWidget().init( { url:image, name:"Width" ,                  w:this._width1 } );
        this._heightIm0 = new ImageWidget().init( { url:image, name:"Height", h:this._height0                 } );
        this._heightIm1 = new ImageWidget().init( { url:image, name:"Height", h:this._height1                 } );
        this._bothIm0   = new ImageWidget().init( { url:image, name:"Both0" , h:this._height0, w:this._width0 } );
        this._bothIm1   = new ImageWidget().init( { url:image, name:"Both1" , h:this._height1, w:this._width1 } );

        this._noScaleIm = new ImageWidget().init( { url:image, name:"No Scale" } );
        this._wScaleIm1 = new ImageWidget().init( { url:image, name:"wScale", xScale:0.5 } );
        this._hScaleIm1 = new ImageWidget().init( { url:image, name:"hScale", yScale:0.5 } );
        this._wScaleIm2 = new ImageWidget().init( { url:image, name:"wScale", xScale:2.0 } );
        this._hScaleIm2 = new ImageWidget().init( { url:image, name:"hScale", yScale:2.0 } );

        this._hLine0    = new RectWidget().init( { w:_x2._config._screenW, h:2, color: "#333333" } );
        this._hLine1    = new RectWidget().init( { w:_x2._config._screenW, h:2, color: "#333333" } );
        this._vLine0    = new RectWidget().init( { w:2, h:_x2._config._screenH, color: "#333333" } );
        this._vLine1    = new RectWidget().init( { w:2, h:_x2._config._screenH, color: "#333333" } );

        this._h0        = new RectWidget().init( { w:_x2._config._screenW, h:2, color: "#333333" } );
        this._h1        = new RectWidget().init( { w:_x2._config._screenW, h:2, color: "#333333" } );
        this._h2        = new RectWidget().init( { w:_x2._config._screenW, h:2, color: "#333333" } );
        this._h3        = new RectWidget().init( { w:_x2._config._screenW, h:2, color: "#666666" } );
        this._h4        = new RectWidget().init( { w:_x2._config._screenW, h:2, color: "#666666" } );

        this._v0        = new RectWidget().init( { h:_x2._config._screenH, w:2, color: "#333333" } );
        this._v1        = new RectWidget().init( { h:_x2._config._screenH, w:2, color: "#333333" } );
        this._v2        = new RectWidget().init( { h:_x2._config._screenH, w:2, color: "#333333" } );
        this._v3        = new RectWidget().init( { h:_x2._config._screenH, w:2, color: "#666666" } );
        this._v4        = new RectWidget().init( { h:_x2._config._screenH, w:2, color: "#666666" } );

        this._naturalIm.setA( 0.3  );
        this._bothIm1.setA  ( 0.15 );

        this.addWidget( this._hLine0, 0, y0 + this._height0 );
        this.addWidget( this._hLine1, 0, y0 + this._height1 );
        this.addWidget( this._vLine0, x0 + this._width0, 0 );
        this.addWidget( this._vLine1, x0 + this._width1, 0 );

        this.addWidget( this._h0 );
        this.addWidget( this._h1 );
        this.addWidget( this._h2 );
        this.addWidget( this._h3 );
        this.addWidget( this._h4 );

        this.addWidget( this._v0 );
        this.addWidget( this._v1 );
        this.addWidget( this._v2 );
        this.addWidget( this._v3 );
        this.addWidget( this._v4 );

        this.addWidget( this._naturalIm, x0, y0 );
        this.addWidget( this._widthIm0 , x0, y1 );
        this.addWidget( this._widthIm1 , x0, y2 );
        this.addWidget( this._heightIm0, x2, y0 );
        this.addWidget( this._heightIm1, x3, y0 );
        this.addWidget( this._bothIm0  , x0, y0 );
        this.addWidget( this._bothIm1  , x0, y0 );

        this.addWidget( this._noScaleIm, x2, y2 );
        this.addWidget( this._wScaleIm1, x2, y3 );
        this.addWidget( this._hScaleIm1, x3, y2 );
        this.addWidget( this._wScaleIm2, x2, y4 );
        this.addWidget( this._hScaleIm2, x4, y2 );

        return this;
    };

    ImageTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_SOFT_A:
                if( type === Host.KEY_PRESSED )
                {
                    this._widthIm0.setW ( undefined );
                    this._widthIm1.setW ( undefined );
                    this._heightIm0.setH( undefined );
                    this._heightIm1.setH( undefined );
                    this._bothIm0.setW  ( this._imageW );
                    this._bothIm0.setH  ( this._imageH );
                    this._bothIm1.setW  ( this._imageW );
                    this._bothIm1.setH  ( this._imageH );


                    this._wScaleIm1.setScaleX( 1 );
                    this._hScaleIm1.setScaleY( 1 );
                    this._wScaleIm2.setScaleX( 1 );
                    this._hScaleIm2.setScaleY( 1 );
                }
                break;

            case Host.KEY_SOFT_B:
                if( type === Host.KEY_PRESSED )
                {
                    this._widthIm0.setW ( this._width0  );
                    this._widthIm1.setW ( this._width1  );
                    this._heightIm0.setH( this._height0 );
                    this._heightIm1.setH( this._height1 );
                    this._bothIm0.setW  ( this._width0  );
                    this._bothIm0.setH  ( this._height0 );
                    this._bothIm1.setW  ( this._width1  );
                    this._bothIm1.setH  ( this._height1 );

                    this._wScaleIm1.setScaleX( 0.5 );
                    this._hScaleIm1.setScaleY( 0.5 );
                    this._wScaleIm2.setScaleX( 2.0 );
                    this._hScaleIm2.setScaleY( 2.0 );
                }
                break;

            case Host.KEY_SOFT_C:
                if( type === Host.KEY_PRESSED )
                {
                    this._widthIm0.animate ( { duration:2000, w:this._imageW } );
                    this._widthIm1.animate ( { duration:2000, w:this._imageW } );
                    this._heightIm0.animate( { duration:2000, h:this._imageH } );
                    this._heightIm1.animate( { duration:2000, h:this._imageH } );
                    this._bothIm0.animate  ( { duration:2000, h:this._imageH, w:this._imageW } );
                    this._bothIm1.animate  ( { duration:2000, h:this._imageH, w:this._imageW } );

                    this._wScaleIm1.animate( { duration:2000, xScale:1, yScale:1 } );
                    this._hScaleIm1.animate( { duration:2000, xScale:1, yScale:1 } );
                    this._wScaleIm2.animate( { duration:2000, xScale:1, yScale:1 } );
                    this._hScaleIm2.animate( { duration:2000, xScale:1, yScale:1 } );
                }
                break;

            case Host.KEY_SOFT_D:
                if( type === Host.KEY_PRESSED )
                {
                    this._widthIm0.animate ( { duration:2000, w:this._width0 } );
                    this._widthIm1.animate ( { duration:2000, w:this._width1  } );
                    this._heightIm0.animate( { duration:2000, h:this._height0 } );
                    this._heightIm1.animate( { duration:2000, h:this._height1 } );
                    this._bothIm0.animate  ( { duration:2000, h:this._height0, w:this._width0 } );
                    this._bothIm1.animate  ( { duration:2000, h:this._height1, w:this._width1 } );

                    this._wScaleIm1.animate( { duration:2000, xScale:0.5 } );
                    this._hScaleIm1.animate( { duration:2000, yScale:0.5 } );
                    this._wScaleIm2.animate( { duration:2000, xScale:2.0 } );
                    this._hScaleIm2.animate( { duration:2000, yScale:2.0 } );
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    return ImageTestScreen;

})();
