// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ThumbnailEntity = (function()
{
    "use strict";

    function ThumbnailEntity(){}

    ThumbnailEntity.prototype = new DataObject();

    ThumbnailEntity.prototype.init = function( data )
    {
        DataObject.prototype.init.call( this, data );
        return this;
    };

    return ThumbnailEntity;
})();
