// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ResetParentalOverlay = ( function()
{
    "use strict";

    ResetParentalOverlay.prototype = new Overlay();

    function ResetParentalOverlay(){}

    ResetParentalOverlay.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._buttons );
        this._speakTarget.setText( this._title.getText() + ". " + this._desc.getText() + focus._speakStr + "." );
        this._speakTarget.setSpeechParams( "Press right or left to review options. Press OK to select.", focus._speakRole, this, false );
    };

    /**
     * Initializer
     * @memberof ResetParentalOverlay
     * @param   {Object}    params
     * @param   {Function} [params.onEnter] - Function called when reset is selected
     * @returns {ResetParentalOverlay}
     */

    ResetParentalOverlay.prototype.init = function( params )
    {
        Overlay.prototype.init. call( this );
        this._className  = "ResetParentalOverlay";

        var self        = this, onReady, reset, cancel;
        var titleStyle  = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(45), whiteSpace:"nowrap", textAlign:"center" } );
        var descStyle   = new Style( { font:"light" , color:"#9b9b9b", fontSize:_x2.scaleValInt(27), whiteSpace:"nowrap", textAlign:"center" } );
        var buttonStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000"  } );
        var w           = this._bg.getW();
        var title       = "Reset Parental Controls";
        var desc        = "Resetting parental control will remove all restrictions currently set on this device.";

        this._numReady = 0;

        onReady = function() { self._numReady++; self.layoutResetParentalOverlay() };
        reset   = new StringWidget().init( { text:"Reset" , style:buttonStyle, onEnter:params.onEnter   } );
        cancel  = new StringWidget().init( { text:"Cancel", style:buttonStyle, onEnter:function() { _x2.popOverlay(); } } );

        this._title = new StringWidget().init( { text:title, style:titleStyle } );
        this._title.setW( w );
        this._title.addReadyCallback( onReady );
        this._bg.addWidget( this._title );

        this._desc = new TextBlockWidget().init( { text:desc, style:descStyle, w:w/2 } );
        this._bg.addWidget( this._desc, w/4 );

        this._buttons = new HorizPillButtonsWidget().init( { entries:[reset,cancel], spacing:_x2.scaleValInt(41) } );
        this._buttons.addReadyCallback( onReady );
        this._bg.addWidget( this._buttons );

        return this;
    };

    ResetParentalOverlay.prototype.layoutResetParentalOverlay = function()
    {
        if( this._numReady === 2 )
        {
            var y0     = 0;
            var y1     = y0 + this._title.getH();
            var y2     = y1 + ((this._desc === undefined) ? 0 : this._desc.getH());
            var offset = (this._bg.getH() - y2 - _x2.scaleValInt( 24 ) - this._buttons.getH()) / 2;

            this._title.setY ( y0 + offset );

            if( this._desc !== undefined )
                this._desc.setY( y1 + offset );

            this._buttons.setY( y2 + offset + _x2.scaleValInt( 24 ) );
            this._buttons.setX( (this._bg.getW() - this._buttons.getW()) / 2 );
        }
    };

    return ResetParentalOverlay;

})();
