// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ListRowWidget = ( function()
{
    "use strict";

    ListRowWidget.prototype = new Widget();

    ListRowWidget.DIVIDER_H = 2;

    function ListRowWidget(){}

    /** Abstract Base Class - get the unfocused height of the row
     * @memberof ListRowWidget
     */
    ListRowWidget.prototype.getHeightUnfocused = function()
    {
        console.log( "ERROR -> a derived class of a ListRowWidget must override getHeightUnfocused()" );
    };

    ListRowWidget.prototype.gotFocus = function()
    {
        Widget.prototype.gotFocus.call( this );
        this.setHighlight();
    };

    /** Get the separator height
     * @memberof ListRowWidget
     * @returns {number}
     */
    ListRowWidget.prototype.getSepH = function()
    {
        return this._sep ? this._sep.getVal( Widget.H ) : 0;
    };

    /**
     * Initializer
     * Creates a vertical, selectable list
     * Note: Divider is at the bottom only.
     * @memberof ListRowWidget
     * @param {Object}  params            - Parameters to define how the string should be displayed
     * @param {boolean} [params.sep=true] - show a separator line
     * @param {boolean} [params.chevron]  - show a right (more indicator) chevron
     * @param {Number}  params.w          - Width in pixels
     * @returns {ListRowWidget}
     */
    ListRowWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this );
        this._className  = "ListRowWidget";
        this._selectable = true;

        if( params.chevron === true )
        {
            var chevStyle = new Style( { color:"#8b8b8b", font:"regular", fontSize:_x2.scaleValInt(36) } );
            var chevX     = params.w - _x2.scaleValInt(20);
            var chevY     = this.getHeightUnfocused() / 2 -  _x2.scaleValInt(26);
            this.addWidget( new StringWidget().init( { style: chevStyle, text:Strings.Glyphs.CHEV_RIGHT } ), chevX, chevY );
        }

        this._sep = new RectWidget().init( { w:params.w, h:ListRowWidget.DIVIDER_H, color:"#262626" } );
        this.addWidget( this._sep, 0, this.getHeightUnfocused() - this._sep.getVal( Widget.H ) );

        if( params.sep === false )  // _sep is needed for TTS, but should not always be visible
            this._sep.setA( 0 );

        this.setVal( Widget.W, params.w                  );
        this.setVal( Widget.H, this.getHeightUnfocused() );

        return this;
    };

    /**
     * Is the list to which this row belongs currently blocked during an animation.
     * @memberof ListRowWidget
     * @returns {Boolean}
     */
    ListRowWidget.prototype.isListBlocking = function()
    {
        var retval = false, widget = this;

        while( widget )
        {
            var parent = widget._parent;

            if( parent instanceof ListWidget )
            {
                if( parent._blocked === true )
                    retval = true;
                break;
            }
            else
                widget = parent;
        }

        return retval;
    };

    /**
     * Set the row to be highlighted
     * @memberof ListRowWidget
     * @param {Number} [time] - TODO:
     * @param {Number} [h   ] - default to Unfocused Height - Divider Height
     */
    ListRowWidget.prototype.setHighlight = function( time, h )
    {
        var obj = this.getGlobalPos();

        obj.w        = this.getVal( Widget.W );
        obj.h        = (h === undefined) ? this.getHeightUnfocused() - ListRowWidget.DIVIDER_H : h;
        obj.inner    = false;
        obj.duration = (time === undefined) ? ListWidget.SCROLL_TIME_STEP : time;

        _x2._hi.setPosition( obj );
    };

    return ListRowWidget;

})();
