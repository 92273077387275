// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 * @see StringWidget
 */
window.TextBlockWidget = ( function()
{
    "use strict";

    TextBlockWidget.prototype = new Widget();

    function TextBlockWidget(){}

    /**
     * Initializer
     * @memberof TextBlockWidget
     * @param {Object}   params           - Paramaters to define how the string should be displayed
     * @param {String}  [params.name]     - Class name
     * @param {Boolean} [params.ellipsis] - If last line should have ellipsis ("...") displayed if text overflows.
     * @param {String}  [params.text]     - Text to display
     * @param {Style}    params.style     - Alignment, Color, Font Type, Font Size, Whitespace
     * @param {Number}  [params.w]        - Width in pixels
     * @param {Number}  [params.h]        - Height in pixels
     * @returns {TextBlockWidget}
     */
    TextBlockWidget.prototype.init = function( params )
    {
        var file = [ "", "", "thumb_6_6_7_7.png", "thumb_13_13_14_14.png" ][_x2._config._layout]; // TODO: This should be in Style.ThumbFileName
        var w    = params.w;
        var h    = params.h;

        if( this._className === undefined )
            this._className = (params && params.name) ? params.name : "TextBlockWidget";

        Widget.prototype.init.call( this );
        Widget.prototype.setW.call( this, w );
        Widget.prototype.setH.call( this, h );

        this._text          = params.text;
        this._offsetY       = 0; // default to top
        this._style         = params.style; // WebGL needs style for line breaks
        this._hasEllipsis   = params.ellipsis;
        this._ellipsisLines = undefined;

        this._container = new Widget         ().init();
        this._scroll    = new ScrollWidget   ().init( { widget:this._container, w:w, h:h     } );
        this._string    = new StringWidget   ().init( { text:params.text, style:params.style } ); // Note: Sets mandatory overflow:"hidden"
        this._thumb     = new NineSliceWidget().init( { url:_x2._config._imageRoot + file    } );
        this._thumb.setA( 0 );

        this._container.setW( w );
        this._container.setH( h );
        this._container.addWidget( this._string );

        // Disable wrapping so we can query a single line for leading metric
        this._string.setWhiteSpace( "nowrap" );
        this.addWidget( this._scroll );
        this.addWidget( this._thumb, w, 0 );

        return this;
    };

    TextBlockWidget.prototype.getLeadingGL = function()
    {
        var font      = Widget.glFonts[ this._string.getVal( Widget.FONT ) ];
        var size      = this._string._curVal[ Widget.FONT_SIZE ];
        this._leading = font.getLineHeight( size );
    };

    TextBlockWidget.prototype.getText = function()
    {
        return this._text;
    };

    /** Called when text, width, or height has changed */
    TextBlockWidget.prototype.layout = function()
    {
        var g, h, w;
        var ellipsisH, text, end, prev;
        var src, dst;

        if( this._text === undefined )
            return;

        this._string.setWhiteSpace( "normal" );

        if( this._hasEllipsis )
        {
            ellipsisH = this._ellipsisLines * this._leading;
            this._string.setText( this._text ); // force h to be re-calculated
        }

        g = this._scroll.getH();
        h = this._string.getH(); // full height that may extend past scroll
        w = this.getW(); // right edge of wrap width

        if( _x2._config._render === Config.RENDER_DOM )
        {
            this._string.setW( w );

            if( this._hasEllipsis && (h >= ellipsisH) )
            {
                end = 0;

                // grow string to just too long
                do
                {
                    prev = text;
                    end  = this._text.indexOf( " ", ++end );
                    text = this._text.substring( 0, end ) + "&hellip;";
                    if( end < 0 )
                        break;
                    this._string.setText( text );
                    h = this._string.getH();
                } while( h <= ellipsisH );

                if( end < 0 )
                {
                    this._string.setText( this._text );
                    h = this._string.getH();
                    if( h <= ellipsisH )
                        prev = this._text; // Edge case: this._text fits
                }

                this._string.setText( prev );
            }
        }
        else if( _x2._config._render === Config.RENDER_WEBGL )
        {
            /*
                If we have HTML text the container has been split into string ...

                Before:
                    ScrollWidget    this._scroll
                    Widget              _children[0] = this._container
                    StringWidget            _children[0] = this._string
                    Widget                      _container
                    Widget                         _children[0]
                    Widget                         _children[1]

                After:
                    ScrollWidget    this._scroll
                    Widget              _children[0] = this._container
                    StringWidget            _children[0]
                    StringWidget            _children[1]
                    StringWidget            _children[2]
                    :
                    StringWidget            _children[n]

                ... but we still may need to wrap each individual string.
            */
            var prevString    = this._string;
            var prevContainer = this._string._container;

            this._container.removeAllWidgets();

            if( prevString._tags && (prevString._tags.length > 1) )
            {
                var iLine  = 0;
                var nLine  = prevContainer._children.length;
                var y0     = 0;
                var y1     = 0;
                var eLine  = this._ellipsisLines;
                var ooLead = 1 / this._leading;

                for( ; iLine < nLine; iLine++ )
                {
                    src    = prevContainer._children[ iLine ];
                    y1     = this.wrapGL( prevString, src._text, y0, eLine );
                    dst    = this._container._children[ iLine ];
                    eLine -= Math.ceil((y1 - y0) * ooLead);
                    y0     = y1;
                    dst.setVal( Widget.FONT_COLOR, src._curVal[ Widget.FONT_COLOR ] );
                }

                // Note: We reset the ellipsisH so that the line with the ellipsis is visible
                // TODO: Is _leading twice as big?
                ellipsisH = h = y0;
            }
            else
                h = this.wrapGL( this._string, this._text, 0, this._ellipsisLines );

            this._container.resourcesLoad();
        }

        if( this._hasEllipsis )
        {
            g = 0;
            h = ellipsisH;
        }

        // Initial height may have been not specified and thus the scroll height == 0
        if( !g )
        {
            // Inline setH() as we can't call it for ref-low since it calls layout()
            Widget.prototype.setH.call( this, h );
            this._container .setH( h );
            this._scroll    .setH( h );

            g = h; // thumb bar height is 100% and thus not visible
        }

        // Update thumb bar height so it is proportional to visible scroll height / string height
        this._thumbS = Math.max( this._thumbH, g * (g / h) ) | 0;

        // We can't use: this._thumb.setH( this._thumbS );
        // As the thumb bar needs to be inside the total height
        this._thumb.setPosition( { w:0, h:this._thumbS, inner:true } );

        // If entire string is visible, hide thumb slider
        if( !this._hasEllipsis )
            this._thumb.setA( this._offsetY >= ((this._string.getH() - g) | 0) ? 0 : 1 );

        // Keep scrollbar adjacent to textblock
        this._thumb.setX( w+1 );
    };

    /** Scroll the text down by a line
     *  @memberof TextBlockWidget
     */
    TextBlockWidget.prototype.lineDown = function()
    {
        this.setScrollDelta( this._leading );
    };

    /** Scroll the text up by a line
     *  @memberof TextBlockWidget
     */
    TextBlockWidget.prototype.lineUp = function()
    {
        this.setScrollDelta( -this._leading );
    };

    /** Scroll the text down by a page (minus 1 line to keep continuity)
     * @memberof TextBlockWidget
     */
    TextBlockWidget.prototype.pageDown = function()
    {
        this.setScrollDelta( this._scroll.getH() - this._leading );
    };

    /** Scroll the text up by a page (minus 1 line to keep continuity)
     *  @memberof TextBlockWidget
     */
    TextBlockWidget.prototype.pageUp = function()
    {
        this.setScrollDelta( this._leading - this._scroll.getH()  );
    };

    TextBlockWidget.prototype.resourcesLoaded = function()
    {
        Widget.prototype.resourcesLoaded.call( this );

        // guard against being called multiple times
        if( !this._leading )
        {
            this._leading = this._string.getH();

            if( _x2._config._render === Config.RENDER_WEBGL )
                if( !this._leading )
                    this.getLeadingGL();

            if( this._hasEllipsis && this._leading )
                this._ellipsisLines = (this.getH() / this._leading) | 0;
        }

        this._thumbH  = this._thumb.getH();

        this.layout();
        this.callbackSignalReady();
    };

    /**
     * Called by lineDown, lineUp, pageDown, or pageUp
     * @memberof TextBlockWidget
     * @param {Number} dy
     */
    TextBlockWidget.prototype.setScrollDelta = function( dy )
    {
        var s = this._scroll.getH(), t = this._string.getH(), h = t - s;

        this._offsetY += dy;

        if( this._offsetY < 0 )
            this._offsetY = 0;

        if( this._offsetY > h )
            this._offsetY = h;

        // TODO: Config.ANI_TIME_SCROLL ? Based off of Config.ANI_TIME_HORIZ_MENU
        // HACK: Hard-coded scroll animation time
        this._scroll.setScroll( { y: -this._offsetY, duration: 250 } );

        this._thumb.animate( { y: (this._offsetY / h) * (s - this._thumbS), duration: 250 } );
    };

    /** Update text
     * @memberof TextBlockWidget
     * @param {String} str - New text
     */
    TextBlockWidget.prototype.setText = function( str )
    {
        this._text = str ? str : "";

        this._string.setText( str );

        // It is valid for ctor() to have no text
        // The string height is set in layout() but needs an updated leading
        // If we are called multiple times only set the first time since the text style can't change
        if( !this._leading )
        {
            this._string.setWhiteSpace( "nowrap" );
            this._leading = this._string.getH();

            if( _x2._config._render === Config.RENDER_WEBGL )
                if( !this._leading )
                    this.getLeadingGL();

            if( this._hasEllipsis && this._leading )
                this._ellipsisLines = (this.getH() / this._leading) | 0;
        }

        this.layout();
    };

    /** Resize text block
     * @memberof TextBlockWidget
     * @param {Number} val - Width in pixels
     */
    TextBlockWidget.prototype.setW = function( val )
    {
        Widget.prototype.setW.call( this, val );

        this._container.setW( val );
        this._scroll   .setW( val );

        this.layout();
    };

    /** Resize text block
     * @memberof TextBlockWidget
     * @param {Number} val - Height in pixels
     */
    TextBlockWidget.prototype.setH = function( val )
    {
        Widget.prototype.setH.call( this, val );

        this._container.setH( val );
        this._scroll   .setH( val );

        if( this._hasEllipsis && this._leading )
            this._ellipsisLines = (this.getH() / this._leading) | 0;

        if( _x2._config._render === Config.RENDER_WEBGL )
        {
            this.getLeadingGL();

            if( this._hasEllipsis && this._leading )
                this._ellipsisLines = (this.getH() / Math.ceil(this._leading)) | 0;
        }

        this.layout();
    };

    /** Word wrap and if neccessary truncate a long text string block with ellipsis
     *  Will add a new StringWidget to this._container
     *  NOTE: Will update the string height
     *  NOTE: Uses _ellipsisLines to determine which line the ellipsis should be placed on
     * @param {StringWidget} string
     * @param {String}       text
     * @param {String}       y -- initial y offset for all strings that get added
     * @param {Number}       ellipsisLine -- which line to place ellipsis on
     */
    TextBlockWidget.prototype.wrapGL = function( string, text, y, ellipsisLine )
    {
        var w = this.getW(); // right edge of wrap width

        var end;
        var begOfLine = 0;
        var endOfLine = 0;
        var lenOfLine = text.length;

        var params    =
        {
            text    : text,
            offset  : 0,
            length  : lenOfLine,
            fontSize: this._string._curVal[ Widget.FONT_SIZE ]
        };
        var font      = Widget.glFonts[ this._string.getVal( Widget.FONT ) ];
        var width     = font.getLineWidth( params ).width;
        var maxWidth  = this._hasEllipsis ? w : 0; // ellipsis max width
        var strings   = [];
        var idxWrap;
        var idxSoftWrap;
        var idxHardWrap;
        var LF = String.fromCharCode( 10 );

        var x = 0;
        var i, strWidget, align = this._style._object.textAlign;

        while( true )
        {
            // Hard  Soft  Action
            // -1    -1    done line wrapping, need to check from wrap to end-of-string
            // -1     ?    idxWrap = soft position
            //  ?    -1    idxWrap = hard position
            //  ?     ?    idxWrap = min( soft, hard )
            idxHardWrap = text.indexOf( LF , endOfLine );
            idxSoftWrap = text.indexOf( " ", endOfLine );

            if( idxHardWrap < 0 )
            {
                if( idxSoftWrap < 0 )
                    break;
                else
                    idxWrap = idxSoftWrap;
            }
            else if( idxSoftWrap < 0 )
                idxWrap = idxHardWrap;
            else
                idxWrap = (idxHardWrap < idxSoftWrap) ? idxHardWrap : idxSoftWrap;

            params.offset = begOfLine;
            params.length = idxWrap - begOfLine + 1;
            width         = font.getLineWidth( params ).width; // NOTE: params.text set above

            if( (idxHardWrap != -1) && (idxHardWrap < idxSoftWrap) )
            {
                endOfLine = idxHardWrap; // break at the hard wrap
                width     = w;           // intentional fall into soft wrap
            }

            if( (width >= w) && (begOfLine != endOfLine) )
            {
                strings.push( text.substring( begOfLine, endOfLine ) );
                begOfLine = endOfLine;
            }

            endOfLine = idxWrap + 1;
        }

        // Handle edge cases:
        // 1) Never had a hard wrap (LF) or soft wrap (SPACE)
        // 2) End of String from last WRAP
        if( begOfLine < (lenOfLine - 1) )
        {
            params.offset = begOfLine;
            params.length = lenOfLine - begOfLine;
            width         = font.getLineWidth( params ).width;

            if( width >= w ) // right edge of wrap width
            {
                strings.push( text.substring( begOfLine, endOfLine ) );
                strings.push( text.substring( endOfLine, lenOfLine ) );
            }
            else
                strings.push( text.substring( begOfLine, lenOfLine ) );
        }

        for( i = 0; i < strings.length; i++ )
        {
            text = strings[i];

            if( this._hasEllipsis && (i === (ellipsisLine-1)) ) // on last visible line?
            {
                if( strings.length !== ellipsisLine )
                {
                    params.offset = 0;
                    end           = text.length;

                    // Edge case 1: entire string fits? Nothing to do, handled above
                    // Edge case 2: "bar_..."     fits?
                    // Edge case 3: "foo_bar..."  fits?
                    // Repeat until we can fit most of the line
                    do
                    {
                        end           = strings[i].lastIndexOf( " ", end-1 );
                        params.text   = strings[i].substring( 0, end ) + "...";
                        params.length = params.text.length;
                        text          = params.text;
                        width         = font.getLineWidth( params ).width;
                    } while( width > maxWidth );
                }
            } // "..."

            strWidget = new StringWidget().init( { text:text, style:this._style } );

            x = 0;
            if( align === "right" || align === "center" )
            {
                params.text   = strings[i];
                params.offset = 0;
                params.length = params.text.length;
                x = w - font.getLineWidth( params ).width;
            }

            if( align === "center" )
                x *= 0.5;

            this._container.addWidget( strWidget, x, y );
            y += this._leading;

            if( this._hasEllipsis && end ) // don't show extra lines
                break;
        } // for

        this._string.setH( y ); // need full height for scrolling

        return y;
    };

    return TextBlockWidget;

})();

