// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.TransitionScreen = ( function()
{
    "use strict";

    TransitionScreen.prototype = new Screen();

    function TransitionScreen(){}

    TransitionScreen.prototype.gotFocus = function()
    {
        if( this._error === true )
            _x2.setDefaultScreen();

        this._speakTarget.setText( "Loading, please stand by. " );
        this._speakTarget.setSpeechParams( undefined, undefined, this, false );
    };

    /**
     * @param {Object}   params
     * @param {String}  [params.entityId] - entityID to use for transition to entity screen
     * @param {Boolean} [params.signOut ] - execute the sign out process
     */
    TransitionScreen.prototype.init = function( params )
    {
        var self = this, onEntity, onError, onCollection;

        Screen.prototype.init.call( this, { noHeader:true, noSep:true } );
        this._className     = "TransitionScreen";
        this._telemetryName = "Transition";
        this._blockKeys     = true;

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget( self ); self._distractor.show(); } );
        this.addWidget( this._distractor );

        var entity, molt, p=[];
        console.log("Is params has entityId", params.entityId , JSON.stringify( params ) );
        if( params.entityId )
        {
            onError = function( error )
            {
                self._error = true;
                self._distractor.hide();
                console.error( error.toString() );
                _x2.pushOverlay( new ErrorOverlay().init( { error:error } ) );
            };

            if( params.entityId.indexOf("https://") !== -1 )
            {
                onCollection = function( folder )
                {
                    var metrics =
                            {
                                entityId   : params.entityId,
                                entityTitle: folder.getTitle(),
                                entityType : "Collection"
                            };

                    window.ll( 'tagEvent', "Hub Preview", metrics ); // Localytics 1.3.6

                    folder.setMeta({deepLink:true});
                    _x2.setScreen( new VodBranchScreen().init( folder ) );
                };

                var tokens  = params.entityId.split('/');
                var alias   = tokens[tokens.length-1];
                var filters = _x2.getBrowseFilterString();
                _x2._data.getNodeByAlias( alias, filters ).then( onCollection ).catch( onError );
            }
            else
            {
                onEntity = function()
                {
                    // Movie or TV Show
                    var type        = entity.getType        && entity.getType();
                    var programType = entity.getProgramType && entity.getProgramType();
                    var entityType  = undefined;

                    switch( type )
                    {
                        case "Movie":
                        case "s:Movie":
                            entityType = "Movie";
                            break;

                        case "Episode":
                        case "s:TvSeries":
                        case "SeriesMaster":
                            entityType = "TV Show";
                            break;

                        default:
                            if( programType === "Movie" )
                                entityType = "Movie"
                            else
                                entityType = "TV Show";
                            break;
                    }

                    var metrics =
                            {
                                entityId   : params.entityId,
                                entityTitle: entity.getTitle(),
                                entityType : entityType
                            };

                    window.ll( 'tagEvent', "Hub Preview", metrics ); // Localytics 1.3.6

                    console.log( "Hub Preview Type: " + entityType );

                    entity.fetchEntityOptions().then(function() {
                        _x2.setScreen( new EntityScreen().init( { entity:entity, molt:molt } ) );
                    })

                };
                if( _x2._data ) {
                    p.push( _x2._data.getProgram( params.entityId ).then( function( data ) { entity = data } ) );
                    // p.push( _x2._data.getProgramEntity( params.entityId ).then( function( data ) { entity = data } ) ); //TODO: we need to get entity options
                    p.push( _x2._data.getMoreLikeThis ( params.entityId ).then( function( data ) { molt   = data } ) );
                    Promise.all( p ).then( onEntity ).catch( onError );
                } else {
                    console.log("Data was not ready for smart hub preview" );
                }
                
            }
        }
        else if( params.signOut )
        {
            _x2._authService.logout();
            _x2._data = undefined;
            setTimeout( function() { _x2.authorize(); }, 1000 );
        }

        return this;
    };

    TransitionScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        if( this._blockKeys !== true )
            retval = Screen.prototype.processEvent.call( this, val, type );

        return retval;
    };

    return TransitionScreen;

})();
