// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.InHomeFailOverlay = ( function()
{
    "use strict";

    InHomeFailOverlay.prototype = new Overlay();

    function InHomeFailOverlay(){}

    InHomeFailOverlay.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._buttons );
        this._speakTarget.setText( this._title.getText() + ". " + this._desc.getText().replace( /xfinity/gi, "x finity" ) + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( undefined, focus._speakRole, this, false );
    };

    /**
     * Initializer
     * @memberof InHomeFailOverlay
     * @returns {InHomeFailOverlay}
     */
    InHomeFailOverlay.prototype.init = function()
    {
        Overlay.prototype.init. call( this );
        this._className  = "InHomeFailOverlay";

        var self        = this, onReady, exit, desc;
        var titleStyle  = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(45), whiteSpace:"nowrap", textAlign:"center" } );
        var descStyle   = new Style( { font:"light" , color:"#9b9b9b", fontSize:_x2.scaleValInt(27), whiteSpace:"nowrap", textAlign:"center" } );
        var buttonStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000"  } );
        var w           = this._bg.getW();
        var title       = "Welcome!";

        if( _x2._config._isSmbUser === true )
            desc = "This device can only access XFINITY Stream on your Comcast Business WiFi. Use the app on your smartphone, tablet or laptop to watch on the go, anytime you like";
        else
            desc = "This device can only access Xfinity Stream on your in-home Xfinity network. Use the app on your smartphone, tablet, or laptop to watch on the go, any time you like.";

        this._numReady = 0;

        onReady = function() { self._numReady++; self.layoutInHomeFailOverlay() };
        exit   = new StringWidget().init( { text:"Exit App", style:buttonStyle, onEnter:function() { _x2._authService.logout(); _x2._config._host.exitApp( true ); } } );

        this._title = new StringWidget().init( { text:title, style:titleStyle } );
        this._title.setW( w );
        this._title.addReadyCallback( onReady );
        this._bg.addWidget( this._title );

        this._desc = new TextBlockWidget().init( { text:desc, style:descStyle, w:w*3/4 } );
        this._bg.addWidget( this._desc, w/8 );

        this._buttons = new HorizPillButtonsWidget().init( { entries:[exit], spacing:_x2.scaleValInt(41) } );
        this._buttons.addReadyCallback( onReady );
        this._bg.addWidget( this._buttons );

        _x2.localyticsLogout( true );

        return this;
    };

    InHomeFailOverlay.prototype.layoutInHomeFailOverlay = function()
    {
        if( this._numReady === 2 )
        {
            var titleH  = this._title.getH();
            var descH   = this._desc.getH();
            var buttonH = this._buttons.getH();
            var offset  = ( this._bg.getH() - titleH - descH - buttonH - 7 * Style._pad ) / 2;

            this._title.setY ( offset );
            this._desc.setY( this._title.getY() + titleH + 3 * Style._pad );
            this._buttons.setY( this._desc.getY() + descH + 4 * Style._pad );
            this._buttons.setX( (this._bg.getW() - this._buttons.getW()) / 2 );
        }
    };

    InHomeFailOverlay.prototype.processEvent = function( val, type )
    {
        if( val === Host.KEY_LAST  ) { //If user press enter or back button
           _x2.setScreen( new TransitionScreen().init( { signOut:true } ) );
        } else if( val === Host.KEY_ENTER ) {
            _x2._authService.logout(); 
            _x2._config._host.exitApp( true );
        }
        return true;
    };

    return InHomeFailOverlay;

})();
