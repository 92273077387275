// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ForYouWidget = (function()
{
    "use strict";

    ForYouWidget.prototype = new Widget();

    function ForYouWidget(){}

    ForYouWidget.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list, true );

        if( focus )
            this._title.setSpeechParams( focus._speakStr, focus._speakRole, this, false );
        else
            this._title.setSpeechParams( undefined, undefined, this, false );
    };

    /**
     * Initializer
     * @memberof ForYouWidget
     * @param   {Object} params
     * @returns {ForYouWidget}
     */
    ForYouWidget.prototype.init = function( params )
    {
        var self = this;

        Widget.prototype.init.call( this, params );
        this._className  = "ForYouWidget";
        this._selectable = true;

        this.setW( _x2._config._screenW );

        this._distractor = new DistractorWidget().init();

        var setDistractor = function()
        {
            setTimeout( function()
            {
                self._distractor.centerOnWidget( self );
                self._distractor.show();
            },1);
        };

        this._distractor.addReadyCallback( setDistractor );
        this.addWidget( this._distractor );

        this._title = new StringWidget().init( { text:params.path, style:new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(32) } ) } );
        this._title.setA( 0 );
        this.addWidget( this._title );

        return this;
    };

    ForYouWidget.prototype.loadData = function( hasFocus )
    {
        var fail, self = this, rows = [], types = [], errorCallback, readyCallback, featuredCallback, menuCallback, recentCallback, purchaseCallback;

        errorCallback = function( error )
        {
            var onRetry = function()
            {
                self._distractor.show();
                setTimeout( function (){ self.loadData( hasFocus ) }, 3000 );
            };

            var e = error instanceof ApiError ? error : new ApiError( { local:"Unknown Error" } );
            _x2.pushOverlay( new ErrorOverlay().init({ error:e, context:ErrorOverlay.Context.SAVED, onRetry:onRetry } ) );
        };

        readyCallback = function()
        {
            self._distractor.hide();

            var millisecondsLoadDuration = new Date() - self._timestampPageLoad;
            var whichTab = "For You";

            _x2._config.log( Config.CLASS_TELEMETRY, 2 ) ? console.info( "Watch source: " + _x2._telemetry._watchButtonSource ) : Config.NOP();
            _x2._config.log( Config.CLASS_TELEMETRY, 2 ) ? console.warn( "Telemetry: " + whichTab + ", Load Time: " + millisecondsLoadDuration + " (ms)" ) : Config.NOP();
            _x2._telemetry.sendScreenLoadTime( millisecondsLoadDuration, whichTab );

            for( var i=0; i<rows.length; i++ )
            {
                if( ! rows[i] || rows[i].getEntities().length === 0 )
                {
                    rows.splice(i, 1);
                    types.splice(i, 1);
                    i--;
                }
            }

            if( rows.length )
            {
                var listParams   = {};
                listParams.w     = _x2._config._screenW;
                listParams.h     = _x2._config._screenH;
                listParams.rows  = rows;
                listParams.types = types;
                listParams.indexListener = function( index ){ self._listIndex = index };

                self._list = new StaticListWidget().init( listParams );
                self.addWidget( self._list, 0 );
                self._list.patchWidget();

                if( _x2._focus === self || hasFocus === true )
                {
                    if( self._listIndex > 0 )
                        self._list.setJumpToIndex( self._listIndex );

                    if( self._horizListIndex )
                    {
                        var listObj = self._list.getSelectedWidget();
                        if( listObj && listObj.setListPosition )
                            listObj.setListPosition( self._horizListIndex );
                    }

                    _x2.requestFocus( self._list );
                }
            }
            else
                errorCallback( self._error );
        };

        menuCallback = function( data )
        {
            var i, type, row;

            for( i=0; i<data.length; i++ )
            {
                row = data[i];

                switch( row.getChildRenderStyle() )
                {
                    case XtvApi.EntityRenderStyle.POSTER:
                        type = GalleryRowWidget;
                        break;

                    case XtvApi.EntityRenderStyle.PROMO:
                        type = PromoRowWidget;
                        break;

                    case XtvApi.EntityRenderStyle.NETWORK:
                        type = NetworkRowWidget;
                        break;

                    case XtvApi.EntityRenderStyle.SMALL_PROMO:
                        type = SmallPromoRowWidget;
                        break;

                    case XtvApi.EntityRenderStyle["4X3_PROGRAM_LINEAR"]:
                        type = LinearPromoRowWidget;
                        break;
                    default:
                        type = undefined;
                }

                if( type )
                {
                    rows [i+4] = row;
                    types[i+4] = type;
                }
                else
                    console.log( "WARNING -> Excluding unknown type: " + type )
            }
        };

        featuredCallback = function( data )
        {
            rows[0] = data;
            types[0] = SmallPromoRowWidget;
        };

        recentCallback = function( data, index )
        {
            rows[index] = data;
            types[index] = GalleryRowWidget;
        };

        purchaseCallback = function( data )
        {
            rows[3]  = data;
            types[3] = GalleryRowWidget;
        };

        fail = function( error )
        {
            self._error = error;
            console.log( error )
        };

        if( ! this._list )
        {
            var p = [];
            this._timestampPageLoad = new Date();
            _x2._config.log( Config.CLASS_TELEMETRY, 2 ) ? console.warn( "Telemetry: starting load time: For You" ) : Config.NOP();

            this._distractor.show();
            _x2._data.cancelAllPendingRequests();
            p.push( _x2.refreshResumePoints( true ) );
            p.push( _x2._data.getForYouMenu( true ).then( menuCallback ).catch( fail ) );
            p.push( _x2._data.getForYouFeaturedMenu( true ).then( featuredCallback ).catch( fail ) ) ;
            p.push( _x2._data.getRecentlyWatched( null, true ).then( function(data){ recentCallback(data,2) } ).catch( fail ) );
            p.push( _x2._data.getForYouPurchases( true ).then( function(data){ purchaseCallback(data) } ).catch( fail ) );

            if( _x2._config._isCdvr === true )
                p.push( _x2._data.getRecentRecordings().then( function(data){ recentCallback(data,1) } ).catch( fail ) );

            //Since there is a 'catch' on all above promises a failure on any/all will still result in us calling the 'then' case.
            //errorCallback will only be called in the case of error triggered in the readyCallback.
            Promise.all( p ).then( readyCallback ).catch( errorCallback );
        }
    };

    ForYouWidget.prototype.refresh = function( screen )
    {
        var hasFocus = false;

        if( this._list )
        {
            hasFocus = ( this._list.containsWidget( _x2._focus ) === true );
            if( hasFocus )
                _x2._hi.fadeOut(0);

            if( this._list.containsWidget( screen._lastFocus ) === true )
                screen._lastFocus = this;

            var listObj = this._list.getSelectedWidget();
            if( listObj )
                this._horizListIndex = listObj.getListPosition ? listObj.getListPosition() : 0;

            this.removeWidget( this._list );
            this._list = undefined;
        }

        this.loadData( hasFocus );
    };

    return ForYouWidget;

})();
