// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.GuideViewEntity = (function()
{
    "use strict";

    function GuideViewEntity( title, entries, emptyString )
    {
        this._title       = title;
        this._entries     = entries;
        this._emptyString = emptyString;
    }

    GuideViewEntity.prototype.getEmptyString = function()
    {
        return this._emptyString;
    };

    GuideViewEntity.prototype.getEntities = function()
    {
        return this._entries;
    };

    GuideViewEntity.prototype.getTitle = function()
    {
        return this._title;
    };

    return GuideViewEntity;

})();
