// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.FontGlyphTestScreen = ( function()
{
    "use strict";

    FontGlyphTestScreen.prototype = new Screen();

    function FontGlyphTestScreen(){}

    FontGlyphTestScreen.prototype.init = function()
    {
        var aColors  = [ "#F00", "#FF0"  , "#0F0"   , "#0FF" , "#00F" ];
        var aWeights = [ "bold", "medium", "regular", "light", "thin" ];
        var aStrings = [];
        var iWeight;
        var nWeight  = aWeights.length;
        var fontSize = 225; // _x2._config._screenW / nWeight;
        var x = 0;
        var y = Style._safeTop;
        var h = 40;

        this._className = "FontGlyphTestScreen";
        Screen.prototype.init.call( this );

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        for( iWeight = 0; iWeight < nWeight; iWeight++ )
        {
            var color  = aColors[iWeight];
            var weight = aWeights[ iWeight ]; 
            var style  = new Style( { font:weight, color:color, fontSize:_x2.scaleValInt( fontSize ) } ); // , whiteSpace:"nowrap", textAlign :"left" } );
            aStrings.push( new StringWidget().init( { text: " ", style: style } ) );

            var rect = new RectWidget().init( {w:fontSize, h:fontSize, color:"#000", borderThickness:1, borderColor:color } );
            this.addWidget( rect, x, y - 1 );
            rect.setA( 0.5 );

            this.addWidget( aStrings[ iWeight ], x + 1, y );

            var title = new Style( { font:weight, color:"#FFFFFF", fontSize:_x2.scaleValInt( h ) } );
            var label = new StringWidget().init( { text:weight, style:title } );
            this.addWidget( label, x, y + fontSize + 2 );

            x += fontSize + 2;
        }

        this._char       = 0x21;
        this._glyph      = new StringWidget().init( { text:this._char, style:title } ); // intentional title style
        this._aColors    = aColors;
        this._aStrings   = aStrings;
        this._selectable = true;

        this.addWidget( this._glyph, _x2._config._screenW*0.5, y + fontSize + 2*h );

        this.delta( 0 );

        return this;
    };

    FontGlyphTestScreen.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();
    };

    FontGlyphTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        if( type === Host.KEY_PRESSED )
            switch( val )
            {
                case Host.KEY_LEFT:
                    this.delta( -1 );
                    break;

                case Host.KEY_RIGHT:
                    this.delta( +1 );
                    break;

                case Host.KEY_UP:
                    this.delta( -32 );
                    break;

                case Host.KEY_DOWN:
                    this.delta( +32 );
                    break;

                case Host.KEY_PAGE_DN:
                case Host.KEY_CH_DOWN:
                    this._char = 0xFF;
                    this.delta( 0 );
                    break;

                case Host.KEY_PAGE_UP:
                case Host.KEY_CH_UP:
                    this._char = 0x21;
                    this.delta( 0 );
                    break;

                default:
                    retval = Screen.prototype.processEvent.call( this, val, type );
                    break;
            }

        return retval;
    };

    FontGlyphTestScreen.prototype.delta = function( delta )
    {
        this._char += delta;

        if( this._char <  32 )
            this._char =  32;
        if( this._char > 0xFF)
            this._char = 0xFF;

        this._text = String.fromCharCode( this._char );
        this._glyph.setText( this._char ); 

        for( var i = 0; i < this._aStrings.length; i++ )
            this._aStrings[i].setText( this._text );
    };

    return FontGlyphTestScreen;

})();


