// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.GalleryCellWidget = ( function()
{
    "use strict";

    GalleryCellWidget.prototype = new CollectionCellWidget();

    function GalleryCellWidget(){}

    /*
     * @params {Object} params TODO:
     */
    GalleryCellWidget.prototype.init = function( params )
    {
        var self = this;
        var titleStyle          = new Style( { color:"#e8e8e8", font:"medium" , fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap" } );
        var subTitleStyle       = new Style( { color:"#a3a3a3", font:"regular", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap" } );
        var posterTitleStyle    = new Style( { color:"#e8e8e8", font:"medium" , fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", textAlign:"center" } );
        var posterSubTitleStyle = new Style( { color:"#a3a3a3", font:"regular", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", textAlign:"center" } );

        if( params.style === XtvApi.EntityRenderStyle["16X9_PROGRAM"] )
        {
            this._srcImageW = _x2.scaleValInt( 480 );
            this._srcImageH = _x2.scaleValInt( 270 );

            this._imageW = params.bgW = this._srcImageW * 0.85;
            this._imageH = params.bgH = this._srcImageH * 0.85;
        }
        else
        {
            this._srcImageW = _x2.scaleValInt( 270 );
            this._srcImageH = _x2.scaleValInt( 360 );

            if( params.collectionType === 'grid' )
            {
                this._imageW = params.bgW = _x2.scaleValInt( 324 );
                this._imageH = params.bgH = _x2.scaleValInt( 432 );
            }
            else
            {
                this._imageW = params.bgW = this._srcImageW;
                this._imageH = params.bgH = this._srcImageH;
            }
        }

        this._imageX = params.bgX     = _x2.scaleValInt( 18 );
        this._scale  = params.bgScale = (this._imageW + 2 * this._imageX) / this._imageW;
        this._imageY = params.bgY     = (this._scale - 1) * this._imageH / 2;

        this._focusW = this._scale * this._imageW;
        this._focusH = this._scale * this._imageH;

        params.background = true;
        params.bgTextSize = _x2.scaleValInt(28);

        CollectionCellWidget.prototype.init.call( this, params );
        this._className  = "GalleryCellWidget";

        var onImageChanged = function()
        {
            self._image.setA(1);
            self.hideBackground();
        };

        var onImageError = function()
        {
            if( self._data )
            {
                var fallback = self._data.getFallbackImageLink( self._imageW, self._imageH );

                if( self._image.getUrl() === fallback )
                    self._image.setA( 0 ); //fallback already tried. hide image.
                else
                    self._image.setUrl( fallback );
            }

            // AAECH-59
            //    Where   Screen        self =
            // 1. Search  SearchScreen  SearchEntity.
            // 2. LiveTV  GuideScreen   LinearShowing.getFallbackImageLink() -> getProgram().getFallbackImageLink()
            // self._image.setUrl( self._image.getUrl() + "&default=force" );
        };

        this._image = new ImageWidget().init( { w:this._imageW, h:this._imageH, onError:onImageError, onChange:onImageChanged } );
        this.addWidget( this._image, this._imageX, this._imageY );

        this._title = new StringWidget().init( { style:titleStyle } );
        this._title.setA( 0 );

        this._subTitle = new StringWidget().init( { style:subTitleStyle } );
        this._subTitle.setA( 0 );

        this._posterText = new StringWidget().init( { style:posterTitleStyle } );
        this._posterText.setW( this._image.getW() );

        this._posterSubText = new StringWidget().init( { style:posterSubTitleStyle } );
        this._posterSubText.setW( this._image.getW() );

        this._tomatoWidget = new TomatoWidget().init( { noTitle:true, style:subTitleStyle } );
        this._tomatoWidget.setA( 0 );

        this.addWidget( this._title        , 0                  , this._image.getH() + 2 * this._imageY + _x2.scaleValInt( 20 ) );
        this.addWidget( this._subTitle     , 0                  , this._image.getH() + 2 * this._imageY + _x2.scaleValInt( 60 ) );
        this.addWidget( this._tomatoWidget , 0                  , this._image.getH() + 2 * this._imageY + _x2.scaleValInt( 60 ) );
        this.addWidget( this._posterText   , this._imageX       , this._image.getH() * 2 / 3 );
        this.addWidget( this._posterSubText, this._imageX       , this._image.getH() * 2 / 3 + _x2.scaleValInt(38) );

        this._speakTarget = new StringWidget().init( { style:titleStyle } );
        this._speakTarget.setA( 0 );
        this.addWidget( this._speakTarget );

        return this;
    };

    GalleryCellWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 270 ) + _x2.scaleValInt( 18 );
    };

    GalleryCellWidget.prototype.getTouchDim = function()
    {
        return _x2.scaleValInt( 360 ) * (_x2.scaleValInt( 270 ) + 2 * _x2.scaleValInt( 18 )) / _x2.scaleValInt( 270 );
    };

    GalleryCellWidget.prototype.getIndex = function()
    {
        return this._index;
    };

    GalleryCellWidget.prototype.getPosAndDimUnfocused = function()
    {
        return { pos:this._imageY, dim:this._imageH };
    };

    GalleryCellWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        if( this._data )
        {
            this._speakTarget.setSpeechParams( undefined, undefined, this, newScreen );

            CollectionCellWidget.prototype.gotFocus.call( this );

            var poster = ( this._image._failed ? this._defaultImage : this._image );
            if( poster )
                poster.animate( { xScale:this._scale, yScale:this._scale, duration:ListWidget.SCROLL_TIME_STEP } );

            if( this._posterText.getText() )
            {
                this._posterText.stopAnimation( Widget.X_SCALE );
                this._posterText.stopAnimation( Widget.Y_SCALE );
                this._posterText.animate( { xScale:this._scale, yScale:this._scale, duration:ListWidget.SCROLL_TIME_STEP } );

                this._posterSubText.stopAnimation( Widget.X_SCALE );
                this._posterSubText.stopAnimation( Widget.Y_SCALE );
                this._posterSubText.animate( { xScale:this._scale, yScale:this._scale, duration:ListWidget.SCROLL_TIME_STEP } );
            }

            this._title.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );
            this._subTitle.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );

            if( this._showTomatos )
                this._tomatoWidget.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );
        }

        return this;
    };

    GalleryCellWidget.prototype.lostFocus = function()
    {
        CollectionCellWidget.prototype.lostFocus.call( this );

        this._title.stopAnimation( Widget.ALPHA );
        this._subTitle.stopAnimation( Widget.ALPHA );
        this._tomatoWidget.stopAnimation( Widget.ALPHA );

        var poster = ( this._image._failed ? this._defaultImage : this._image );
        if( poster )
        {
            poster.stopAnimation( Widget.X_SCALE );
            poster.stopAnimation( Widget.Y_SCALE );
            poster.animate( { xScale:1, yScale:1, duration:ListWidget.SCROLL_TIME_STEP } );
        }

        if( this._posterText.getText() )
        {
            this._posterText.stopAnimation( Widget.X_SCALE );
            this._posterText.stopAnimation( Widget.Y_SCALE );
            this._posterText.animate( { xScale:1, yScale:1, duration:ListWidget.SCROLL_TIME_STEP } );

            this._posterSubText.stopAnimation( Widget.X_SCALE );
            this._posterSubText.stopAnimation( Widget.Y_SCALE );
            this._posterSubText.animate( { xScale:1, yScale:1, duration:ListWidget.SCROLL_TIME_STEP } );
        }

        this._title.animate( { alpha:0, duration:ListWidget.SCROLL_TIME_STEP } );
        this._subTitle.animate( { alpha:0, duration:ListWidget.SCROLL_TIME_STEP } );
        this._tomatoWidget.animate( { alpha:0, duration:ListWidget.SCROLL_TIME_STEP } );
    };

    //TODO (Entity Refactor) Document. What types can we pass here?
    GalleryCellWidget.prototype.setData = function( data, index )
    {
        this._showTomatos = false;
        this._background.setA(1);
        this._image.setA(0);

        var start, end, channel, separator = ", ", speechTitle, speechSubTitle = "", speechStr, timeStr, review, self = this, count, title;

        this._data = data;

        if( data !== undefined )
        {
            this._index = index;

            if( data.getMeta && data.getMeta().all === true )
            {
                this._image.setUrl( _x2._config._imageRoot + "view_all_tile.png" );

                this._title.setText("");
                this._subTitle.setText("");
                this._backgroundText.setText("");
                this._tomatoWidget.setA( 0 );

                this._posterText.setText( "View All" );
                this._posterSubText.setText( data.getTotalEntityCount() + " available" );

                speechTitle    = this._posterText.getText();
                speechSubTitle = data.getTotalEntityCount() + " available";
            }
            else
            {
                var remaining, subTitleElements = [];

                var setRemaining = function( comma )
                {
                    if( remaining )
                    {
                        //clear "Watched" when adding remaining (entity can be watched AND have min remaining)
                        subTitleElements = [];
                        speechSubTitle = "";

                        subTitleElements.push( remaining + " min left" + (comma ? ", " : "") );
                        speechSubTitle += remaining + " minutes left, ";
                    }
                };

                //try to get a Entity from the showing, but default back to data if not available
                var entity = ( data instanceof Showing ? data.getEntity() : data );
                if( ! entity )
                    entity = data;

                if( data.getMinsRemaining )
                    remaining = data.getMinsRemaining() > 0 ? data.getMinsRemaining() : undefined;

                if( data.isWatched && data.isWatched() )
                {
                    subTitleElements.push( "Watched, " );
                    speechSubTitle += subTitleElements[subTitleElements.length-1];
                }

                title = data.getTitle();

                if( entity.getType && entity.getType() === XtvApi.EntityType.EPISODE )
                {
                    var series = ( entity.getSeries ? entity.getSeries() : undefined );
                    if( series )
                    {
                        var ep = entity.getEpisodeNumber();
                        var se = entity.getSeasonNumber();

                        if( ep !== undefined && se !== undefined )
                            title = "S" + se + " Ep" + ep + " - " + entity.getTitle();
                    }
                }

                if( data instanceof Rental )
                {
                    separator = " ";
                    setRemaining( true );

                    subTitleElements.push( _x2.createExpiresString( data.getExpires() ) );
                    speechSubTitle += _x2.createExpiresSpeechString( data.getExpires() );
                }
                else if( data instanceof PurchaseGroup )
                {
                    if( data.getEpisodeCount() > 1 )
                    {
                        count = data.getEpisodeCount();

                        subTitleElements.push( count + ( count === 1 ? " Episode" : " Episodes" ) );
                        speechSubTitle += subTitleElements[subTitleElements.length-1];
                    }
                    else
                    {
                        separator = " ";
                        var purchase      = data.getPurchases()[0];
                        var purchasedDate = _x2.createDateStr( data.getPurchasedOnDate() );
                        var purchasedOn   = "Purchased " + ( purchase.isHD() ? " HD " : " SD " );

                        if( purchase.getMinsRemaining )
                            remaining = purchase.getMinsRemaining() > 0 ? purchase.getMinsRemaining() : undefined;

                        setRemaining( true );

                        if( purchasedDate.indexOf('/') > 0 )
                            purchasedOn += " on ";

                        purchasedOn += purchasedDate;

                        subTitleElements.push( purchasedOn );
                        speechSubTitle += subTitleElements[subTitleElements.length-1];

                        if( purchase.getYear() && purchase.getType() === "Movie" )
                            subTitleElements.push("(" + purchase.getYear() + ")");

                        review = data.getRottenTomatoReview();
                        if( review )
                        {
                            if( review.isValid() )
                            {
                                if( review.getFanSummaryScore() > 0 || review.getCriticSummaryScore() > 0 )
                                    subTitleElements.push("|");

                                this._tomatoWidget.setData( review );
                                this._tomatoWidget.setX( _x2.scaleValInt(278) );
                                this._showTomatos = true;

                                speechSubTitle += ", " + this._tomatoWidget.getSpeechStr();
                            }
                        }
                    }
                }
                else if( data instanceof Recording )
                {
                    setRemaining();

                    switch (data.getRecordingStatus())
                    {
                        case "COMPLETE":
                            start   = data.getRecordedOnDate();
                            end     = new Date( start.getTime() + data.getDurationMs() );
                            timeStr = _x2.createTimeRange( start, end );

                            subTitleElements.push( "Recorded " + _x2.createDateStr(start) + " " + timeStr );
                            speechSubTitle += "Recorded " + _x2.createDateStr(start) + ", " + _x2.createTimeSpeechStr( timeStr );
                            break;

                        case "STARTED":
                            channel = data.getChannel();

                            if( channel )
                            {
                                subTitleElements.push( "Recording Now on " + channel.getNumber() + " " + channel.getCallSign() );
                                speechSubTitle += "Recording Now on channel " + channel.getNumber() + ", " + channel.getCallSign();
                            }
                            else
                            {
                                subTitleElements.push( "Recording Now" );
                                speechSubTitle += subTitleElements[subTitleElements.length-1];
                            }

                            break;
                    }
                }
                else if( data.getStartTime && data.getStartTime() ) //else if( data instanceof LinearShowing ) //NOTE: in the case of a deep linked folder a browse entity can also represent a linear showing.
                {
                    var now, when, rating;

                    separator = " ";

                    now   = new Date();
                    start = new Date( parseInt( data.getStartTime() ));
                    end   = new Date( parseInt( data.getEndTime() ));

                    channel = data.getChannel ? data.getChannel() : undefined;
                    rating  = data.getRating  ? data.getRating()  : undefined;

                    if( start.getTime() < now.getTime() && end.getTime() > now.getTime() )
                    {
                        when = "On now";
                        speechSubTitle += when + " ";
                    }
                    else if( now.getDate() === start.getDate() )
                    {
                        when = "Airs today";
                        speechSubTitle += when + " ";
                    }
                    else
                    {
                        when = _x2.createDateStr(start);
                        speechSubTitle += when + " ";
                    }

                    subTitleElements.push( when );
                    subTitleElements.push( _x2.createTimeRange( start, end ) );
                    speechSubTitle += _x2.createTimeSpeechStr( subTitleElements[subTitleElements.length-1] );

                    if( channel )
                    {
                        subTitleElements.push( "on");
                        subTitleElements.push( channel.getNumber() );
                        subTitleElements.push( channel.getCallSign() );
                        speechSubTitle += " on channel " + channel.getNumber() + ", " + channel.getCallSign();
                    }
                    else if( data.getCallSign && data.getCallSign() )
                    {
                        subTitleElements.push( "on");
                        subTitleElements.push( data.getCallSign() );
                        speechSubTitle += " on " + data.getCallSign();
                    }

                    speechSubTitle += ". ";

                    if( rating )
                    {
                        subTitleElements.push( Strings.Glyphs[rating] );
                        speechSubTitle += "Rating " + rating + ". ";
                    }

                    if( channel && channel.isHD() )
                    {
                        subTitleElements.push( Strings.Glyphs.HD );
                        speechSubTitle += "HD available . ";
                    }

                    if( data.isSap && data.isSap() )
                    {
                        subTitleElements.push( Strings.Glyphs.SAP );
                        speechSubTitle += "Secondary audio available. ";
                    }

                    if( data.isClosedCaptioned && data.isClosedCaptioned() )
                    {
                        subTitleElements.push( Strings.Glyphs.CC );
                        speechSubTitle += "Closed captioning available. ";
                    }

                    if( data.isDvs && data.isDvs() )
                    {
                        subTitleElements.push( Strings.Glyphs.DV );
                        speechSubTitle += "Video description available. ";
                    }
                }
                else if( data instanceof SearchEntity )
                {
                    subTitleElements.push( data.getSubtitle() );
                    speechSubTitle += subTitleElements[subTitleElements.length-1];
                }
                else
                {
                    if( data.getEpisodeCount && data.getEpisodeCount() )
                    {
                        count = data.getEpisodeCount();

                        subTitleElements.push( count + ( count === 1 ? " Episode" : " Episodes" ) );
                        speechSubTitle += subTitleElements[subTitleElements.length-1];
                    }
                    else
                    {
                        if( entity.getType() !== XtvApi.EntityType.EPISODE )
                        {
                            if( data.getSubtitle && data.getSubtitle())
                            {
                                subTitleElements.push(data.getSubtitle());
                                speechSubTitle += subTitleElements[subTitleElements.length-1] + " ";
                            }
                            else if( data.getYear && data.getYear())
                            {
                                subTitleElements.push(data.getYear());
                                speechSubTitle += subTitleElements[subTitleElements.length-1];
                            }
                        }

                        setRemaining();

                        if( entity.getRottenTomatoReview )
                        {
                            review = entity.getRottenTomatoReview();
                            if( review )
                            {
                                if( review.isValid() )
                                {
                                    separator = " ";

                                    if( review.getFanSummaryScore() > 0 || review.getCriticSummaryScore() > 0 )
                                        subTitleElements.push("|");

                                    this._tomatoWidget.setData( review );
                                    this._showTomatos = true;

                                    speechSubTitle += ", " + this._tomatoWidget.getSpeechStr();
                                }
                            }
                        }
                    }
                }

                this._posterText.setText("");
                this._posterSubText.setText("");
                this._tomatoWidget.setA(0);

                this._title.setText( title );
                this._subTitle.setText( subTitleElements.join(separator) );

                if( this._showTomatos )
                    this._subTitle.addReadyCallback( function(){ self._tomatoWidget.setX( self._subTitle.getW() + Style._pad ) } );

                this.setBackgroundText( data.getTitle() );

                if( entity )
                    this._image.setUrl( entity.getImageLink( this._srcImageW, this._srcImageH ) );

                speechTitle = title;
            }

            speechStr = speechTitle + ". ";

            if( speechSubTitle )
                speechStr += speechSubTitle + ". ";

            this._speakTarget.setText( speechStr );

            this.setA( 1 );
        }
        else
        {
            this.setA( 0 );
            this._image.setUrl();
        }
    };

    return GalleryCellWidget;

})();
