// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.Android = (function()
{
    "use strict";

    Android.prototype = new Host();

    function Android(){}

    Android.prototype.exitApp = function()
    {
        if( android )
            android.onExit();
    };

    Android.prototype.getCryptoType = function()
    {
        return "webcrypto";
    };

    Android.prototype.getPlatformString = function()
    {
       return "android";
    };

    Android.prototype.getSecClientDeviceType = function()
    {
       return "SONY2018";
    };

    Android.prototype.init = function( callback )
    {
        this._type = Host.Type.ANDROID;

        document.body.style.backgroundColor = "#000000";

        var mac = android.getDeviceId();

        _x2._config._mac = mac;

        console.log( "MAC ADDRESS = " + mac );

        if( _x2._config._host !== undefined )
        {
            _x2._config._host._make  = undefined;
            _x2._config._host._model = "Android";
            _x2._config._host._product = "Android";
        }

        setTimeout( callback(), 1 );
    };

    return Android;

})();

