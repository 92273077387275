// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.VodBrowseWidget = (function()
{
    "use strict";

    VodBrowseWidget.prototype = new Widget();

    function VodBrowseWidget(){}

    VodBrowseWidget.prototype.activate = function()
    {
        var self = this, delayPatch, success, fail;

        success = function( data )
        {
            var imageY, menuY, listY = 0;
            var millisecondsLoadDuration = new Date() - self._timestampPageLoad;
            var whichTab = self._folder ? "Browse: " + self._folder.getTitle() : undefined;

            if( self._active === true )
            {
                if( whichTab )
                {
                    _x2._config.log( Config.CLASS_TELEMETRY, 2 ) ? console.warn( "Telemetry: " + whichTab + ", Load Time: " + millisecondsLoadDuration + " (ms)" ) : Config.NOP();
                    _x2._config.log( Config.CLASS_TELEMETRY, 2 ) ? console.log( "Previous Watch source: " + _x2._telemetry._watchButtonSource ) : Config.NOP();

                    _x2._telemetry.setWatchButton( whichTab );
                    if( _x2._telemetry.watchPathDepth() <= 1 )
                    {
                        _x2._telemetry.watchPathReset();
                        _x2._telemetry.watchPathPush( whichTab ); // First time enter Browse
                    }

                    _x2._telemetry.sendScreenLoadTime( millisecondsLoadDuration, whichTab );
                }

                var listData = data.getEmbeddedBrowseItems(), entries = [], types = [], type;

                for( var i = 0; i < listData.length; i++ )
                {
                    if( listData[i].getEmbeddedBrowseItems )
                    {
                        if( listData[i].getEmbeddedBrowseItems().length )
                        {
                            switch( listData[i].getChildRenderStyle() )
                            {
                                case XtvApi.EntityRenderStyle.POSTER:
                                case XtvApi.EntityRenderStyle["3X4_PROGRAM_LINEAR"]:
                                    type = GalleryRowWidget;
                                    break;

                                case XtvApi.EntityRenderStyle.PROMO:
                                    type = PromoRowWidget;
                                    break;

                                case XtvApi.EntityRenderStyle.NETWORK:
                                    type = NetworkRowWidget;
                                    break;

                                case XtvApi.EntityRenderStyle.SMALL_PROMO:
                                    type = SmallPromoRowWidget;
                                    break;

                                case XtvApi.EntityRenderStyle["16X9_PROGRAM"]:
                                    type = ProgramPromoRowWidget;
                                    break;

                                case XtvApi.EntityRenderStyle["4X3_PROGRAM_LINEAR"]:
                                    type = LinearPromoRowWidget;
                                    break;

                                default:
                                    type = undefined;
                                    console.error( "ERROR -> unsupported render style = " + listData[i].getChildRenderStyle() );
                                    break;
                            }

                            if( type )
                            {
                                entries.push( listData[i] );
                                types.push( type );
                            }
                        }
                    }
                }

                if( data.getCollectionRenderStyle() === XtvApi.CollectionRenderStyle.NETWORK_ENTITY )
                {
                    var onIconLoaded = function()
                    {
                        self._networkIcon.setH( _x2.scaleValInt( 200 ) );
                        self._networkIcon.setX( self.getW() / 2 - self._networkIcon.getW() / 2 );
                        self._networkIcon.setA( 1 );
                    };

                    imageY = _x2.scaleValInt(77);
                    listY  = _x2.scaleValInt(280);

                    self._networkIcon = new ImageWidget().init( { url:data.getImageLink(304,228), onLoad:onIconLoaded } );
                    self.addWidget( self._networkIcon, 0, imageY );
                    self._networkIcon.patchWidget();
                    self._networkIcon.setA(0);

                    if( _x2._pc.isEnabled() )
                    {
                        menuY = _x2.scaleValInt(327);
                        listY = _x2.scaleValInt(400);

                        var toggleLock = function( lock )
                        {
                            var overlay, lastFocus, params = {};

                            var validatePin = function( validatePinStr )
                            {
                                var activate, deactivate;

                                if( _x2._pc.checkPin( validatePinStr ) === true )
                                {

                                    _x2._pc.setNetworkLocks(data.getId(), lock);
                                    _x2._data.setParentalControlsSettings(_x2._pc);

                                    if( lock )
                                    {
                                        activate = self._unlockButton;
                                        deactivate = self._lockButton;
                                    }
                                    else
                                    {
                                        activate = self._lockButton;
                                        deactivate = self._unlockButton;
                                    }

                                    activate.setA(1);
                                    deactivate.setA(0);
                                    self._menu = activate;

                                    lastFocus = _x2.getOverlayRootLastFocus();

                                    if( lastFocus === self._lockButton )
                                        _x2.setOverlayRootLastFocus( self._unlockButton );
                                    else if( lastFocus === self._unlockButton )
                                        _x2.setOverlayRootLastFocus( self._lockButton );

                                    _x2.popOverlay();
                                }
                                else
                                {
                                    params.type = PinOverlay.Type.PC_VALIDATE_FAIL;
                                    _x2.pushOverlay( new PinOverlay().init( params ) );
                                    _x2.popOverlay( overlay );
                                }
                            };

                            params.type      = PinOverlay.Type.PC_VALIDATE;
                            params.onCapture = validatePin;

                            _x2.pushOverlay( overlay = new PinOverlay().init( params ) );
                        };

                        var buttonStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000"  } );
                        self._lockButton   = new HorizPillButtonsWidget().init( { entries:[new StringWidget().init( { text:"Lock"  , style:buttonStyle, onEnter:function(){ toggleLock(true ) } } )], spacing:0 } );
                        self._unlockButton = new HorizPillButtonsWidget().init( { entries:[new StringWidget().init( { text:"Unlock", style:buttonStyle, onEnter:function(){ toggleLock(false) } } )], spacing:0 } );

                        self.addWidget(self._lockButton, 0, menuY);
                        self._lockButton.patchWidget();
                        self._lockButton.addReadyCallback( function() { self._lockButton.setX( self.getW() / 2 - self._lockButton.getW() / 2 ) });
                        self._lockButton.setA(0);

                        self.addWidget(self._unlockButton, 0, menuY);
                        self._unlockButton.patchWidget();
                        self._unlockButton.addReadyCallback( function() { self._unlockButton.setX( self.getW() / 2 - self._unlockButton.getW() / 2 ) });
                        self._unlockButton.setA(0);

                        if( _x2._pc.isNetworkLocked(data.getId()) )
                            self._menu = self._unlockButton;
                        else
                            self._menu = self._lockButton;

                        self._menu.setA(1);
                    }
                }

                self._offsetY = listY;

                delayPatch = function()
                {
                    if( self._active === true )
                    {
                        var listParams = {}, focus;

                        self._distractor.hide();

                        if( entries === undefined || entries.length === 0 )
                        {
                            var alertTitle = 'Oops, something went wrong. Please try again later.', 
                            alertMessage = 'This folder is empty.';
                            if( _x2.getBrowseFilterString() ) {
                                alertTitle = 'Adjust filters to see more titles.';
                                alertMessage = '';
                            } 
                            _x2.pushOverlay(new AlertOverlay().init( { title: alertTitle, message: alertMessage} ) );
                            _x2._hi.fadeOut();
                        }
                        else
                        {
                            listParams.w     = _x2._config._screenW;
                            listParams.h     = _x2._config._screenH;
                            listParams.rows  = entries;
                            listParams.types = types;

                            //if this widget already contains a list, remove it now so that we don't end up with two.
                            if( self._list )
                                self.removeWidget( self._list );

                            self._list = new StaticListWidget().init( listParams );
                            self.addWidget( self._list, 0, listY );
                            self._list.patchWidget();

                            if( _x2._focus === self )
                            {
                                if( self._menu )
                                    focus = _x2.requestFocus( self._menu, false );
                                else if( self._list )
                                    focus = _x2.requestFocus( self._list, false );
                            }
                        }
                    }
                };

                setTimeout( delayPatch, 1000 );
            }
        };

        fail = function( error )
        {
            self._distractor.hide();
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.BROWSE_SEARCH } ) );
            console.log( error.toString() )
        };

        this._timestampPageLoad = new Date();
        this._active = true;

        //fetch this on every activate and allow http caching to decide when it's stale **EXCEPT** in the case of a deep link - transition screen handles query
        if( this._folder.getMetaValue('deepLink') === true )
            success( this._folder );
        else
        {
            _x2._config.log( Config.CLASS_TELEMETRY, 2 ) ? console.warn( "Telemetry: starting load time for: " + this._folder.getTitle() ) : Config.NOP();
            this._distractor.show();

            _x2._data.cancelAllPendingRequests();
            var filters = _x2.getBrowseFilterString();
            _x2._data.getVodFolder( this._folder, 2, 12, filters, null, true ).then( success ).catch( fail );
        }
    };

    VodBrowseWidget.prototype.deactivate = function()
    {
        this._active = false;
        this._distractor.hide();

        if( this._list )
        {
            this.removeWidget( this._list );
            this._list = undefined;
        }
    };

    VodBrowseWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        var focus;

        _x2._hi.fadeOut(0);

        if( this._menu )
            focus = _x2.requestFocus( this._menu, newScreen );
        else if( this._list )
            focus = _x2.requestFocus( this._list, newScreen );

        return focus;
    };

    VodBrowseWidget.prototype.init = function( folder )
    {
        Widget.prototype.init.call( this );
        this._className  = "VodBrowseWidget";
        this._selectable = true;
        this._folder     = folder;

        this.setW( _x2._config._screenW );
        this.setH( _x2._config._screenH - _x2.scaleValInt(150) );

        var self = this;
        this._distractor = new DistractorWidget().init();
        this._distractor.hide();
        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget(self); } );
        this.addWidget( this._distractor );

        return this;
    };

    VodBrowseWidget.prototype.processEvent = function( val, type )
    {
        var retval = false;
        var self = this;

        if( type === Host.KEY_PRESSED )
        {
            if( val === Host.KEY_DOWN )
            {
                if( this._menu && _x2._focus === this._menu )
                {
                    _x2._hi.fadeOut(0);

                    this._menu.setA(0);
                    this._networkIcon.setA(0);
                    this._list.animate({y:0, duration:300, onEnd:function(){ _x2.requestFocus(self._list) }});

                    retval = true;
                }
            }
            else if( val === Host.KEY_UP )
            {
                if( this._menu && _x2._focus !== this._menu )
                {
                    _x2._hi.fadeOut(0);
                    _x2.requestFocus(this._menu);

                    this._menu.setA(1);
                    this._networkIcon.setA(1);
                    this._list.animate({y:this._offsetY, duration:300});

                    retval = true;
                }
            }
        }
        return retval;
    };

    return VodBrowseWidget;

})();
