// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Represents an entity retrieved from a browse query with the basic/limited data required to
 * display the entity in some list or grid view.
 *
 * A BrowseEntity is different from a ProgramEntity (in addition to source) by virtue of the detail available. A
 * BrowseEntity has minimal information (as used by a browse screen) whereas a ProgramEntity has full entity detail.
 *
 * Maps to a XTV "BrowseEntity" (_type = "BrowseEntity").
 */

window.BrowseEntity = (function()
{
    "use strict";

    function BrowseEntity(){}

    BrowseEntity.prototype = new ThumbnailEntity();

    /**
     * Initialize this object
     * @memberOf BrowseEntity
     * @param {Object}            data       - XTV derived JSON object
     * @param {BrowseCollection}  [collection] - collection which contains this entity (parent)
     * @return {BrowseEntity}
     */
    BrowseEntity.prototype.init = function( data, collection )
    {
        ThumbnailEntity.prototype.init.call( this, data );
        this._collection = collection;
        return this;
    };

    /**
     * Returns channel call sign end time if this is a linear entity
     * @return {string}
     */
    BrowseEntity.prototype.getCallSign = function()
    {
        var callSign, linearInfo = this.getEmbedded('linearInfo');

        if( linearInfo )
            callSign = linearInfo["callsign"];

        return callSign;
    };

    /**
     * Return network logo URL
     * @return {string}
     */
    BrowseEntity.prototype.getNetworkImageLink = function()
    {
        var linearInfo, links, stationImage, imageUrl;

        linearInfo = this.getEmbedded("linearInfo");

        if( linearInfo )
        {
            links = linearInfo._links;
            if( links )
            {
                stationImage = links["stationImage"];
                if( stationImage )
                    imageUrl = stationImage.href;
            }
        }

        if( imageUrl )
            imageUrl = XtvApi.replaceUrlParams( imageUrl, { width:50, height:35, extent:"true", gravity:"SouthWest" } );

        return imageUrl;
    };

    /**
     * Return the collection this entity belongs to
     * @returns {BrowseCollection|*}
     */
    BrowseEntity.prototype.getCollection = function()
    {
        return this._collection;
    };

    /**
     * Returns program end time if this is a linear entity
     * @return {number}
     */
    BrowseEntity.prototype.getEndTime = function()
    {
        var time, linearInfo = this.getEmbedded("linearInfo");

        if( linearInfo )
            time = linearInfo['endTime'] && parseInt( linearInfo['endTime'] );

        return time;
    };

    /**
     * Get this entity's ID. ID can be used for get<Type>Entity XTV queries.
     * @memberOf BrowseEntity
     * @return {String}
     */
    BrowseEntity.prototype.getEntityId = function()
    {
        return this.get("entityId");
    };

    /**
     * Returns episode number if this is a episode
     * @return {number}
     */
    BrowseEntity.prototype.getEpisodeNumber = function()
    {
        return this.get("episodeNumber");
    };

    /**
     * Get the fallback URL of the poster image (if main fails)
     * @param width
     * @param height
     * @return {String|undefined}
     */
    BrowseEntity.prototype.getFallbackImageLink = function( width, height )
    {
        if( this._collection )
        {
            var link = this._collection.getProgramFallbackImageLink();
            return XtvApi.replaceUrlParams( link, { entityId: this.getEntityId(), width: width, height: height } );
        }

        return undefined;
    };

    /**
     * Get the URL of the poster image
     * @param width
     * @param height
     * @return {string}
     */
    BrowseEntity.prototype.getImageLink = function( width, height )
    {
        // https://etwiki.sys.comcast.net/pages/viewpage.action?spaceKey=AAE&title=XTVAPI+Browse+Interface+Client+Usage+Guide#XTVAPIBrowseInterfaceClientUsageGuide-BrowseCollectionImages
        var link = this.getLink("programImageLink") || this.getLink("programFallbackImageLink");
        if( ! link )
        {
            if( this._collection && this._collection.getProgramImageLink() )
                link = this._collection.getProgramImageLink();
        }

        if( link )
            link = XtvApi.replaceUrlParams( link, { entityId: this.getEntityId(), width: width, height: height } );

        return link;
    };

    /**
     * Returns the program type
     * @returns {String}
     */
    BrowseEntity.prototype.getProgramType = function()
    {
        return this.get("programType");
    };

    /**
     * Get the URL for a promo
     * @param width
     * @param height
     * @return {*}
     */
    BrowseEntity.prototype.getPromoImageLink = function( width, height )
    {
        var id   = this.getByPriority("seriesProgramId", "entityId"); //try for the seriesProgramId first

        var link = this.getLink("image");
        if( ! link )
            link = this.getLink("programFallbackImageLink");
        if( ! link )
            link = this._collection.getProgramImageLink();

        if( link )
            return XtvApi.replaceUrlParams( link, { entityId: id, width: width, height: height } );
    };

    /**
     * Returns program start time if this is a linear entity
     * @return {number}
     */
    BrowseEntity.prototype.getStartTime = function()
    {
        var time, linearInfo = this.getEmbedded("linearInfo");

        if( linearInfo )
            time = linearInfo['startTime'] && parseInt( linearInfo['startTime'] );

        return time;
    };

    /**
     * Get the entities tile render style. See (@link XtvApi.EntityRenderStyle)
     * @return {String}
     */
    BrowseEntity.prototype.getTileRenderStyle = function()
    {
        var renderStyle = this.get("tileRenderStyle");

        if( renderStyle === undefined && this._collection )
            renderStyle = this._collection.getChildRenderStyle();

        return renderStyle;
    };

    BrowseEntity.prototype.getRottenTomatoReview = function()
    {
        var review, data, criticScore, fanScore, criticRotten, fanRotten;

        data = this.get("ordering");
        if( data )
        {
             criticScore  = Math.floor(data["criticScore"]);
             fanScore     = Math.floor(data["fanScore"]);
             fanRotten    = fanScore    < 60;
             criticRotten = criticScore < 60;
             review = new Review().init( { attributes: { criticSummaryRotten:criticRotten, criticSummaryScore:criticScore, fanSummaryRotten:fanRotten, fanSummaryScore:fanScore } } )
        }

        return review;
    };

    /**
     * Returns season number if this is a episode
     * @return {number}
     */
    BrowseEntity.prototype.getSeasonNumber = function()
    {
        return this.get("seasonNumber");
    };

    /**
     * Returns the series program id
     * @returns {String}
     */
    BrowseEntity.prototype.getSeriesId = function()
    {
        return this.get("seriesProgramId");
    };

    /**
     * Get the title of this entity
     * @memberOf BrowseEntity
     * @returns {String}
     */
    BrowseEntity.prototype.getTitle = function()
    {
        return this.get("title");
    };

    /**
     * Get the year of this entity
     * @memberOf BrowseEntity
     * @returns {String}
     */
    BrowseEntity.prototype.getYear = function()
    {
        return this.get("releaseYear");
    };

    /**
     * Get the rating of this entity
     * @memberOf BrowseEntity
     * @returns {String}
     */
    BrowseEntity.prototype.getRating = function()
    {
        return this.get("contentRating");
    };

    /**
     * Get the number of episodes for this series
     * @memberOf BrowseEntity
     * @returns {Number}
     */
    BrowseEntity.prototype.getEpisodeCount = function()
    {
        return this.getInteger("numberOfEpisodes");
    };

    /**
     * Get the series title. NOTE: Relates to promo entities, not to series/episode entities
     * @returns {*}
     */
    BrowseEntity.prototype.getSeriesTitle = function()
    {
        return this.get("seriesTitle");
    };

    /**
     * Returns true if this entity is marked as adult content
     * @memberOf BrowseEntity
     * @return {boolean}
     */
    BrowseEntity.prototype.isAdult = function()
    {
        return this.getBoolean("isAdult");
    };

    /**
     * Fetches the TV Listing object if this entity is a linear showing (has embedded.linearInfo)
     * @memberOf BrowseEntity
     * @return {Promise} - Returns LinearShowing.
     */
    BrowseEntity.prototype.getLinearTvListing = function()
    {
        var url, listing;

        var linearInfo = this.getEmbedded("linearInfo");


        if( linearInfo )
        {
            listing = linearInfo._links["tvListing"];
            if( listing )
            {
                url = listing.href;

                var resolver = function( resolve, reject )
                {
                    var success = function( response )
                    {
                        var listing = new LinearShowing().init( JSON.parse( response.data ) );
                        resolve( listing );
                    };

                    var error = function( error )
                    {
                        reject( error );
                    };

                    url = _x2._data._host + XtvApi.trimPath( url );
                    _x2._network.ajax( { url: url,  headers: [_x2._data._authHeader], accepts:Network.Accepts.xtv } ).then( success ).catch( error );
                };

                return new Promise( resolver )
            }
        }

        // if( !url )
        return Promise.reject( new ApiError().init( { local:"BrowseEntity.getLinearTvListing: Linear data not available"} ) );
    };

    BrowseEntity.prototype.getSubtitle = function()
    {
        return this.get("subtitle");
    };

    BrowseEntity.prototype.getType = function()
    {
        return this.get("entityType");
    };

    return BrowseEntity;

})();

