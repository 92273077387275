// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.StringAlignTestScreen = ( function()
{
    "use strict";

    StringAlignTestScreen.prototype = new Screen();

    function StringAlignTestScreen(){}

    StringAlignTestScreen.prototype.init = function()
    {
        var self = this;
        this._className = "StringAlignTestScreen";

        Screen.prototype.init.call( this );

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        var w         = _x2._config._screenW*0.5;
        var fontSize  = 36;
        var makeStyle = function( align, color )
        {
            if( !color )
                color = "#e5e5e5";
            return new Style( { color:color, font:"light", fontSize:fontSize, whiteSpace:"nowrap", textAlign: align } );
        };

        this._divider = new RectWidget().init( { color:"#777777", w: 1, h:_x2._config._screenH } );
        this._header1 = new StringWidget().init( { text: "Width = 1/2 Screen", style:makeStyle( "left", "#0AF" ) } );
        this._header2 = new StringWidget().init( { text: "Width = undefined ", style:makeStyle( "left", "#0AF" ) } );
        this._left    = new StringWidget().init( { text:"Left"               , style:makeStyle( "left"         ) } );
        this._center  = new StringWidget().init( { text:"Center"             , style:makeStyle( "center"       ) } );
        this._right   = new StringWidget().init( { text:"Right"              , style:makeStyle( "right"        ) } );
        this._center2 = new StringWidget().init( { text:"Center"             , style:makeStyle( "center"       ) } );
        this._center3 = new StringWidget().init( { text:"Center layout:width", style:makeStyle( "center"       ) } );

        this._left  .setW( w );
        this._center.setW( w );
        this._right .setW( w );
        // center2 does NOT have a width specified
        this._center3.addReadyCallback( function() { self.layout(); } );

        var h = _x2._config._screenW * 0.5;
        var x = 0; // Style._safeLeft;
        var y = Style._safeTop;

        this.addWidget( this._header1, x, y ); y += 1.5*fontSize;
        this.addWidget( this._left   , x, y ); y += 1.5*fontSize;
        this.addWidget( this._center , x, y ); y += 1.5*fontSize;
        this.addWidget( this._right  , x, y ); y += 2  *fontSize;

        this.addWidget( this._header2, x, y ); y += 1.5*fontSize;
        this.addWidget( this._center2, h, y ); y += 1.5*fontSize;
        this.addWidget( this._center3, h, y );

        this.addWidget( this._divider, h, 0 );

// Add Bounding Box Rects ?

        return this;
    };

    StringAlignTestScreen.prototype.layout = function()
    {
        this._center3.setW( _x2._config._screenW * 0.5 );
    };

    return StringAlignTestScreen;

})();
