// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 */
window.ChannelCollection = (function()
{
    "use strict";

    function ChannelCollection(){}

    ChannelCollection.blockHours = 4;

    /**
     * Initialize
     * @memberOf ChannelCollection
     * @param data
     * @return {ChannelCollection}
     */
    ChannelCollection.prototype.init = function( data )
    {
        var channels     = data[0];
        var schedulesObj = data[1];

        var schedules = schedulesObj.data;
        var blockId   = schedulesObj.blockId;

        this._loadedBlocks = {};
        this._channelMap   = [];
        this._channels     = channels;

        //create a hash map of channels
        for( var i=0; i<this._channels.length; i++ )
            this._channelMap[this._channels[i].getSelfLink()] = this._channels[i];

        this.assignSchedules( schedules );
        this.validate( [blockId] );

        this._loadedBlocks[blockId] = true;

        return this;
    };

    /**
     * Assign schedules to channels
     * @private
     * @param schedules
     * @param blockId
     */
    ChannelCollection.prototype.assignSchedules = function( schedules, blockId )
    {
        var i, j;

        for( i=0; i<schedules.length; i++ )
        {
            for( j=0; j<this._channels.length; j++ )
            {
                if( schedules[i].getSelfLink() === this._channels[j].getSelfLink() )
                {
                    this._channels[j].setSchedules( schedules[i], blockId ); //track which block the schedule belongs to, for the purpose of purging.
                    break;
                }
            }
        }
    };

    /**
     * Fetch any missing schedule data. Called before accessing schedule data. If start/end are
     * omitted, only validate that we have schedule data for the current time (on now).
     *
     * TODO: validate that start and end resolve to contiguous blocks.
     *
     * @param {Number} (start) - start time for which coverage is needed
     * @param {Number} (end)   - end time for which coverage is needed
     * @return {Promise<void>}
     */
    ChannelCollection.prototype.fetch = function( start, end )
    {
        var i, self = this;
        var requestBlocks = [];
        var fetchBlocks   = [];
        var uniformStart, uniformEnd;

        if( start === undefined && end === undefined )
            requestBlocks.push( ChannelCollection.getUniformBlockTime( _x2._config._host.getCurrentDateTime(true) ) );
        else
        {
            if( start !== undefined )
                uniformStart = ChannelCollection.getUniformBlockTime( start );
            if( end !== undefined )
                uniformEnd = ChannelCollection.getUniformBlockTime( end );

            if( uniformStart )
                requestBlocks.push( uniformStart );
            if( uniformEnd && uniformEnd !== uniformStart )
                requestBlocks.push( uniformEnd );
        }

        for( i=0; i<requestBlocks.length; i++ )
        {
            if( self._loadedBlocks[requestBlocks[i]] !== true && self._loadedBlocks[requestBlocks[i]] !== 'Loading' ) {
                self._loadedBlocks[requestBlocks[i]] = 'Loading';
                fetchBlocks.push( requestBlocks[i] );
            }
        }
        if( fetchBlocks.length > 2 ) { //always there will be one or two fetchBlocks, but at peak hour, there are more than 2 requests sent.
            console.log("Warning --- it fetchs more than two requests", fetchBlocks);
        }
        if( fetchBlocks.length > 0 )
        {
            var resolver = function( resolve )
            {
                var p = [];

                var assignSchedules = function( schedulesObj )
                {
                    self.assignSchedules( schedulesObj.data, schedulesObj.blockId );
                    self._loadedBlocks[schedulesObj.blockId] = true;
                };

                var ready = function()
                {
                    self.validate( fetchBlocks );
                    resolve();
                };

                for( i=0; i<fetchBlocks.length; i++ )
                    p.push( _x2._data.getScheduleBlock( fetchBlocks[i], ChannelCollection.blockHours ).then( assignSchedules ) );

                Promise.all( p ).then( ready );
            };

            return new Promise( resolver );
        }
        else
            return Promise.resolve();
    };

    ChannelCollection.prototype.getChannel = function( link )
    {
        return this._channelMap[link];
    };

    ChannelCollection.prototype.getChannels = function()
    {
        return this._channels;
    };

    /**
     * Fill any schedule gaps with 'TBD' blocks and report gaps to console.log
     * @memberOf ChannelCollection
     * @param blocks - array of block start times
     */
    ChannelCollection.prototype.validate = function( blocks )
    {
        var i, j, channel, schedules, lSchedule, rSchedule, lEnd, rStart, gap, startCheck, elapsed;

        var blockStart = blocks[0];
        var blockEnd   = blocks[blocks.length-1] + ChannelCollection.blockHours * 3600000;

        startCheck = Date.now();
        console.log("######### Checking schedules... #########");

        var getTbd = function( start, end )
        {
            console.log( "----- schedule gap detected -----");
            console.log( "Channel   : " + channel.getNumber()               );
            console.log( "Gap start : " + ( new Date(start)).toTimeString() );
            console.log( "Gap end   : " + ( new Date(end)).toTimeString()   );

            return new LinearShowing().init( { startTime:start, endTime:end, title:"TBD...", isTbd:true } )
        };

        for( i=0; i<this._channels.length; i++ )
        {
            channel   = this._channels[i];
            schedules = channel.getSchedules();
            lSchedule = schedules[0];

            //if there are no schedules for this channel insert a TBD to fill the entire schedule block
            if( schedules.length === 0 )
                schedules.push( getTbd( blockStart, blockEnd ) );
            else
            {
                //if the first schedule begins after block start add a TBD to fill the gap
                if( schedules[0].getStartTime() > blockStart )
                    schedules.unshift( getTbd( blockStart, schedules[0].getStartTime() ) );

                //if the last schedule ends before the end of the block add a TBD to fill the gap
                if( schedules[schedules.length-1].getEndTime() < blockEnd )
                    schedules.push( getTbd( schedules[schedules.length-1].getEndTime(), blockEnd ) );

                //fill any gaps between schedules
                for( j=1; j<schedules.length; j++ )
                {
                    rSchedule = schedules[j];
                    lEnd      = lSchedule.getEndTime();
                    rStart    = rSchedule.getStartTime();
                    gap       = ( rStart - lEnd ) / (60 * 1000);

                    if( gap > 0 )
                        schedules.splice( j, 0, getTbd( lEnd, rStart ) );

                    lSchedule = rSchedule;
                }
            }
        }
        elapsed = Date.now() - startCheck;
        console.log("######### Check complete. (" + elapsed + "ms) #########");
    };

    /**
     * Return the uniform block start time, considering that we'll only pull blocks for 0:00,4:00,... (GMT),
     * according to ChannelCollection.blockHours. We're adjusting to GMT so that all clients pull the same
     * blocks
     * @param time
     * @return {number}
     */
    ChannelCollection.getUniformBlockTime = function( time )
    {
        if( _x2._config.skipBlockTimeCheck == true ) {
            return time;
        }
        var blockStart = new Date(time);
        blockStart.setMinutes(0);
        blockStart.setSeconds(0);
        blockStart.setMilliseconds(0);

        //Adjust the timezone offset to whole hours and compensate by adjusting the time

        var zoneOffset = blockStart.getTimezoneOffset();
        var offset = zoneOffset % 60;

        if( offset !== 0 )
        {
            zoneOffset = zoneOffset - offset;
            blockStart = new Date( blockStart.getTime() + ( offset * 60 * 1000 ) );//offset will be in minutes
        }

        while( ( blockStart.getHours() + ( zoneOffset / 60 ) ) % ChannelCollection.blockHours !== 0 )
            blockStart.setTime( blockStart.getTime() - ( 60 * 60 * 1000 ) );

        return blockStart.getTime();
    };

    return ChannelCollection;
})();
