// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.FavoritesWidget = (function()
{
    "use strict";

    FavoritesWidget.prototype = new Widget();

    function FavoritesWidget(){}

    FavoritesWidget.prototype.gotFocus = function()
    {
        if( this._list && this._list.getMaxIndex() > 0 )
            this.speak( _x2.requestFocus( this._list, true ) );
        else
            this.speak( this );
    };

    FavoritesWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this, params );
        this._className  = "FavoritesWidget";
        this._path       = params.path;
        this._selectable = true;

        this.setW( _x2._config._screenW );

        this._distractor = new DistractorWidget().init();

        var self = this;

        this._emptyMessage = new EmptyMessageWidget().init( EmptyMessageWidget.Key.FAVORITES );
        this.addWidget( this._emptyMessage, Style._safeLeft );

        var setDistractor = function()
        {
            setTimeout( function()
            {
                self._distractor.centerOnWidget( self );
                self._distractor.show();
            },1);
        };

        this._distractor.addReadyCallback( setDistractor );
        this.addWidget( this._distractor );

        return this;
    };

    FavoritesWidget.prototype.loadData = function( listIndex )
    {
        var fail, self = this, rows = [], types = [], success, hasFocus;

        if( this._list )
        {
            this._list.setA( 0 );
            this._list._selectable = false;
        }

        this._distractor.show();

        success = function( response )
        {
            self._distractor.hide();

            var i, type, row, data = response.getEmbeddedBrowseItems();

            for( i=0; i<data.length; i++ )
            {
                row = data[i];

                switch( row.getChildRenderStyle() )
                {
                    case XtvApi.EntityRenderStyle.POSTER:
                        type = GalleryRowWidget;
                        break;

                    case XtvApi.EntityRenderStyle.PROMO:
                        type = PromoRowWidget;
                        break;

                    case XtvApi.EntityRenderStyle.NETWORK:
                        type = NetworkRowWidget;
                        break;

                    case XtvApi.EntityRenderStyle.SMALL_PROMO:
                        type = SmallPromoRowWidget;
                        break;

                    case XtvApi.EntityRenderStyle["4X3_PROGRAM_LINEAR"]:
                        type = LinearPromoRowWidget;
                        break;

                    default:
                        type = undefined;
                        console.log( "WARNING -> Excluding unknown render style: " + row.getChildRenderStyle() )
                }

                if( type )
                {
                    rows [i] = row;
                    types[i] = type;
                }
            }

            if( rows.length > 0 )
            {
                for( i=0; i<rows.length; i++ )
                {
                    if( ! rows[i] || rows[i].getEntities().length === 0 )
                    {
                        rows.splice(i, 1);
                        types.splice(i, 1);
                        i--;
                    }
                }

                var listParams   = {};
                listParams.w     = _x2._config._screenW;
                listParams.h     = _x2._config._screenH;
                listParams.rows  = rows;
                listParams.types = types;

                if( self._list )
                {
                    hasFocus = self._list.containsWidget( _x2._focus );
                    self.removeWidget( self._list );
                }

                self._list = new StaticListWidget().init( listParams );
                self.addWidget( self._list, 0 );
                self._list.patchWidget();

                if( listIndex >= rows.length - 1 )
                    listIndex = rows.length - 1;

                if( listIndex > 0 )
                    self._list.setJumpToIndex( listIndex );

                if( _x2._focus === self || hasFocus )
                    _x2.requestFocus( self._list, true );
            }

            //recheck rows.length (rows may have been pruned)
            if( rows.length > 0 )
            {
                self._emptyMessage.hide();

                if( _x2._focus === self )
                    self.speak( _x2.requestFocus( self._list, true ) );
            }
            else
            {
                self._emptyMessage.show();

                if( _x2._focus === self )
                    self.speak( self );
            }
        };

        fail = function( error )
        {
            console.log( error )
        };
        
        _x2._data.cancelAllPendingRequests();
        _x2._data.getFavoritesBrowse( true ).then( success ).catch( fail );
    };

    FavoritesWidget.prototype.refresh = function( screen )
    {
        _x2._hi.fadeOut();

        if( this._list.containsWidget( screen._lastFocus ) === true )
            screen._lastFocus = this;

        this.loadData( this._list.getSelectedIndex() );
    };

    FavoritesWidget.prototype.speak = function( focus )
    {
        var str;

        if( focus === this || focus === undefined )
        {
            if( this._emptyMessage.isShowing() === true )
                str = this._emptyMessage.getSpeechText();
            else
                str = "Loading, please wait. ";

            this._distractor.setSpeechParams( str, undefined, this, false );
        }
        else
        {
            str = this._path + ". " +
                  focus._speakStr +
                  "Press up or down to review items. Press OK for options. ";

            this._distractor.setSpeechParams( str, focus._speakRole, this, false );
        }
    };

    return FavoritesWidget;

})();
