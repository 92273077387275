// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.ListTestScreen = ( function()
{
    "use strict";

    ListTestScreen.prototype = new Screen();

    function ListTestScreen(){}

    ListTestScreen.prototype.gotFocus = function( lostFocus )
    {
        _x2.requestFocus( this._list );
    };

    ListTestScreen.prototype.init = function()
    {
        this._className = "ListTestScreen";

        Screen.prototype.init.call( this );

        this._bg   = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this._list = new ListWidget().init( { w:_x2._config._screenW/2                                        } );

        this.addWidget( this._bg                           );
        this.addWidget( this._list, _x2._config._screenW/4 );

        return this;
    };

    return ListTestScreen;

})();
