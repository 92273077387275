// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.TestScreen = ( function()
{
    "use strict";

    TestScreen.prototype = new Screen();

    function TestScreen(){}

    TestScreen.prototype.gotFocus = function()
    {
        this.setHighlight();
    };

    TestScreen.prototype.init = function()
    {
        var self       = this, i;
        var style      = new Style( { color:"#e5e5e5", font:"light", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap" } );
        var renderType = "Tests - ";

        if( _x2._config._render === Config.RENDER_DOM   ) renderType += "DOM";
        if( _x2._config._render === Config.RENDER_WEBGL ) renderType += "WebGL";

        Screen.prototype.init.call( this, { baseStr:renderType } );
        this._className = "TestScreen";

        var onMainMenu = function()
        {
            self._lastCol = 0;
            self._col     = self._row + 1;
            self._row     = 0;

            self._activeMenu = self._menus[ self._col ];
            self.selectMenu();
        };

        var makeOnEnter = function( screen )
        {
            return function() { _x2._hi.fadeOut(); _x2.pushScreen( new screen().init() ); };
        };

        var onGradMenu = function()
        {
            self._lastCol    = self._col;
            self._activeMenu = self._grads[0];

            self._col  =  0;
            self._row  =  0;
            self._sub  =  1;
            self.selectMenu();
        };

        var onStringsMenu = function()
        {
            self._lastCol    = self._col;
            self._activeMenu = self._strings[0];

            self._col  =  0;
            self._row  =  0;
            self._sub  =  1;
            self.selectMenu();
        };

        this._tests =
        [
            new StringWidget().init( { text:"Core"             , style:style, onEnter:onMainMenu } ),
            new StringWidget().init( { text:"Component"        , style:style, onEnter:onMainMenu } ),
            new StringWidget().init( { text:"Application"      , style:style, onEnter:onMainMenu } )
        ];

        this._menuCore =
        [
            new StringWidget().init( { text:"Animation"        , style:style, onEnter:makeOnEnter( AniTestScreen              ) } ),
            new StringWidget().init( { text:"Rectangle Color"  , style:style, onEnter:makeOnEnter( RectColorTestScreen        ) } ),
            new StringWidget().init( { text:"Rect Anim Widget" , style:style, onEnter:makeOnEnter( RectAnimTestScreen         ) } ),
            new StringWidget().init( { text:"Font Glyph"       , style:style, onEnter:makeOnEnter( FontGlyphTestScreen        ) } ),
            new StringWidget().init( { text:"Font Scale"       , style:style, onEnter:makeOnEnter( FontScaleTestScreen        ) } ),
            new StringWidget().init( { text:"Gradients ..."    , style:style, onEnter:onGradMenu                                } ),
            new StringWidget().init( { text:"Image Widget"     , style:style, onEnter:makeOnEnter( ImageTestScreen            ) } ),
            new StringWidget().init( { text:"Nine Slice Widget", style:style, onEnter:makeOnEnter( NineSliceTestScreen        ) } ),
            new StringWidget().init( { text:"Nine Slice Shadow", style:style, onEnter:makeOnEnter( NineSliceShadowTestScreen  ) } ),
            new StringWidget().init( { text:"Scroll Pane"      , style:style, onEnter:makeOnEnter( ScrollTestScreen           ) } ),
            new StringWidget().init( { text:"Strings ..."      , style:style, onEnter:onStringsMenu                             } )
        ];

        this._menuCoreStrings =
        [
            new StringWidget().init( { text:"String Widget"    , style:style, onEnter:makeOnEnter( StringTestScreen           ) } ),
            new StringWidget().init( { text:"String Alignment" , style:style, onEnter:makeOnEnter( StringAlignTestScreen      ) } ),
            new StringWidget().init( { text:"String Ellipsis"  , style:style, onEnter:makeOnEnter( StringEllipsisTestScreen   ) } )
        ];

        this._menuCoreGrad =
        [
            new StringWidget().init( { text:"Gradient 2 Widget", style:style, onEnter:makeOnEnter( Gradient2TestScreen        ) } ),
            new StringWidget().init( { text:"Gradient 3 Widget", style:style, onEnter:makeOnEnter( Gradient3TestScreen        ) } ),
            new StringWidget().init( { text:"Gradient 4 Widget", style:style, onEnter:makeOnEnter( Gradient4TestScreen        ) } ),
            new StringWidget().init( { text:"Gradient AA AR GH", style:style, onEnter:makeOnEnter( GradientAAARGHTestScreen   ) } )
        ];

        this._menuComp =
        [
            new StringWidget().init( { text:"MultiStateButton" , style:style, onEnter:makeOnEnter( MultiStateButtonTestScreen ) } ),
            new StringWidget().init( { text:"Ring Distractor"  , style:style, onEnter:makeOnEnter( RingDistractorTestScreen   ) } ),
            new StringWidget().init( { text:"Horz Pill"        , style:style, onEnter:makeOnEnter( HorizPillButtonTestScreen  ) } ),
            new StringWidget().init( { text:"Horz Selection"   , style:style, onEnter:makeOnEnter( HorizSelectionTestScreen   ) } ),
            new StringWidget().init( { text:"Tabbed Pill"      , style:style, onEnter:makeOnEnter( TabbedPillButtonTestScreen ) } ),
            new StringWidget().init( { text:"Text Block Widget", style:style, onEnter:makeOnEnter( TextBlockTestScreen        ) } ),
            new StringWidget().init( { text:"Text Block 2"     , style:style, onEnter:makeOnEnter( TextBlockTestScreen2       ) } ),
            new StringWidget().init( { text:"Text Block Ellip" , style:style, onEnter:makeOnEnter( TextBlockEllipsisTestScreen) } ),
            new StringWidget().init( { text:"Text Block Trunc" , style:style, onEnter:makeOnEnter( TextBlockTruncatedTestScreen)} ),
            new StringWidget().init( { text:"Text HTML"        , style:style, onEnter:makeOnEnter( HTMLStringTestScreen       ) } ),
            new StringWidget().init( { text:"Text Leading"     , style:style, onEnter:makeOnEnter( TextLeadingTestScreen      ) } )
        ];

        this._menuApp  =
        [
            new StringWidget().init( { text:"List Widget"      , style:style, onEnter:makeOnEnter( ListTestScreen             ) } ),
            new StringWidget().init( { text:"List Animation"   , style:style, onEnter:makeOnEnter( ListAnimTestScreen         ) } ),
            new StringWidget().init( { text:"LRU Cache"        , style:style, onEnter:makeOnEnter( LruCacheTestScreen         ) } ),
            new StringWidget().init( { text:"Metrics"          , style:style, onEnter:makeOnEnter( MetricsTestScreen          ) } )
        ];

        this._menus =
        [
            this._tests   ,
            this._menuCore,
            this._menuComp,
            this._menuApp
        ];

        this._row        = 0;
        this._col        = 0;

        this._lastRow    = [ 0, 0, 0 ];
        this._lastCol    = 0;

        this._activeMenu =  this._menus[ this._col ];

        this._sub        = 0;
        this._grads      =
        [
            this._menuCoreGrad
        ];
        this._menuCore[4]._subMenu = this._menuCoreGrad; // Gradients ...

        this._strings =
        [
            this._menuCoreStrings
        ];
        this._menuCore[9]._subMenu = this._menuCoreStrings; // Strings ...

        this._entries = this._tests.concat( this._menuCore ).concat( this._menuComp ).concat( this._menuApp ).concat( this._menuCoreGrad ).concat( this._menuCoreStrings );

        for( i = 0; i < this._entries.length; i++ )
            this.addWidget( this._entries[i], Style._safeLeft );

        return this;
    };

    TestScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        var i, j, obj;
        var y = this.getHeaderBottom(), y2;

        var subX = 0;
        var subY = [];

        var pad  = 3 * Style._pad;
        var cols = this._menus.length, rows;

        var w    = 0;
        var h    = this._menus[0][0].getH() + pad;

        for( i = 0; i < cols; i++ )
        {
            y2   = y + ((i > 0) ? h*(i-1) : 0);
            rows = this._menus[ i ].length;

            for( j = 0; j < rows; j++ )
            {
                obj = this._menus[i][j];
                obj.setY( y2 );

                if( obj._subMenu ) // i == 1 && j == 4 // Gradients...
                {
                    subY.push( y2 );
                    subX = w;
                }

                if( i > 0 )
                {
                    obj.setX( Style._safeLeft + w + pad );
                    if( subX < (w + obj.getW() + pad) )
                        subX = (w + obj.getW() + pad);
                }
                else
                    if( w < obj.getW() )
                        w = obj.getW();

                y2 += obj.getH() + pad;
            }
        }

        y2 = subY[ 0 ];
        for( i = 0; i < this._grads[0].length; i++ )
        {
            obj = this._grads[0][ i ];
            obj.setX( Style._safeLeft + subX +  pad );
            obj.setY( y2 );

            y2 += obj.getH() + pad;
        }

        y2 = subY[ 1 ];
        for( i = 0; i < this._strings[0].length; i++ )
        {
            obj = this._strings[0][ i ];
            obj.setX( Style._safeLeft + subX +  pad );
            obj.setY( y2 );

            y2 += obj.getH() + pad;
        }

        this.selectMenu();
    };

    TestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED && (this._row < (this._activeMenu.length - 1)) )
                {
                    this._row++;
                    this.setHighlight();
                }
                break;

            case Host.KEY_ENTER:
            case Host.KEY_RIGHT:
                if( type === Host.KEY_PRESSED )
                {
                    this._lastRow[ this._col ] = this._row;
                    this._activeMenu[this._row].onEnter();
                }
                break;

            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED && (this._row > 0 ) )
                {
                    this._row--;
                    this.setHighlight();
                }
                break;

            case Host.KEY_LAST:
            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED && ((this._col > 0 ) || this._sub) )
                {
                    if( this._sub )
                        this._col = this._lastCol;
                    else
                        if( this._col > 0 )
                            this._col = 0;

                    this._sub        = 0;
                    this._activeMenu = this._menus  [ this._col ];
                    this._row        = this._lastRow[ this._col ];
                    this.selectMenu();
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    TestScreen.prototype.selectMenu = function()
    {
        var col  = this._sub ? this._lastCol : this._col, alpha;
        var i, n = this._entries.length, rows = this._menus[col].length, main = this._menus[0].length;

        for( i = 0; i < n; i++ )
            this._entries[i].setA( 0 );

        // Dim 1st col
        if( this._col > 0 || this._sub )
            for( i = 0; i < main; i++ )
                this._menus[0][i].setA( 1/3 );

        // Dim 2nd col
        for( i = 0; i < rows; i++ )
            this._menus[col][i].setA( this._sub ? 1/2 : 1 );

        // Dim 3rd col
        alpha = this._activeMenu === this._grads[0] ? 1 : 0;
        for( i = 0; i < this._grads[0].length; i++ )
            this._grads[0][i].setA( alpha );

        alpha = this._activeMenu === this._strings[0] ? 1 : 0;
        for( i = 0; i < this._strings[0].length; i++ )
            this._strings[0][i].setA( alpha );

        this.setHighlight();
    };

    TestScreen.prototype.setHighlight = function()
    {
        var obj  = this.getGlobalPos();
        var menu = this._activeMenu[ this._row ];

        obj.x       += menu.getX();
        obj.y       += menu.getY();
        obj.w        = menu.getW();
        obj.h        = menu.getH();
        obj.inner    = false;
        obj.duration = 300;

        _x2._hi.setPosition( obj );
    };

    return TestScreen;

})();
