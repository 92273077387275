// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.TimeCellWidget = ( function()
{
    "use strict";

    TimeCellWidget.prototype = new ListRowWidget();

    function TimeCellWidget(){}

    TimeCellWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 291 );
    };

    TimeCellWidget.prototype.gotFocus = function()
    {
        return this;
    };

    TimeCellWidget.prototype.init = function( params )
    {
        var self = this;

        params.sep = false;

        ListRowWidget.prototype.init.call( this, params );
        this._className  = "TimeCellWidget";
        this._selectable = false;

        var style = new Style( { color:"#8c8c8c", font:"medium", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", colorHi:"#e8e8e8" } );

        this._str = new StringWidget().init( { style:style } );
        this._str.addReadyCallback( function() { self.layout() } );
        this.addWidget( this._str, Style._pad );

        this.setA( 0 );
        this.setW( this.getHeightUnfocused() );
        this.setH( params.h );

        return this;
    };

    TimeCellWidget.prototype.layout = function()
    {
        this._str.setY( (this.getH() - this._str.getH()) / 2 );
    };

    TimeCellWidget.prototype.setData = function( data )
    {
        if( data !== undefined )
        {
            this._data = data;
            this._str.setText( data );
            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

    return TimeCellWidget;

})();
