/**
 * @class
 */
window.EmptyMessageWidget = (function()
{
    "use strict";

    EmptyMessageWidget.prototype = new Widget();

    function EmptyMessageWidget(){}

    EmptyMessageWidget.Key = Object.freeze
    ({
          PURCHASES_ON   : 0,
          PURCHASES_OFF  : 1,
          RECORDINGS     : 2,
          UPCOMING       : 3,
          FAVORITES      : 4,
          GRID_FILTER    : 5,
          GRID_EMPTY     : 6,
          GRID_ERROR     : 7,
    });

    EmptyMessageWidget.prototype.getSpeechText = function()
    {
        var retval = this._line1.getText() + " " + this._line2.getText();

        return retval.replace( /xfinity/gi, "x finity" );
    };

    EmptyMessageWidget.prototype.hide = function()
    {
        this._line1.setA(0);
        this._line2.setA(0);
        if( this._widget )
            this._widget.setA( 0 );
        this._isShowing = false;
    };

    /**
     * @memberOf EmptyMessageWidget
     * @param  {Number} key
     * @param  {Widget} widget
     * @return {EmptyMessageWidget}
     */
    EmptyMessageWidget.prototype.init = function( key, widget )
    {
        Widget.prototype.init.call( this );
        this._className = "EmptyMessageWidget";

        var self   = this;
        var style1 = new Style( { font:"light"  , color:"#e8e8e8", fontSize:_x2.scaleValInt(60), textAlign:"center", whiteSpace:"nowrap" } );
        var style2 = new Style( { font:"regular", color:"#a3a3a3", fontSize:_x2.scaleValInt(32), textAlign:"center" } );

        var onReady = function()
        {
            self._readyCount ++;
            self._ready = ( self._readyCount === (widget ? 3 : 2) );

            if( self._ready )
                self.layout();
        };

        this.setW( _x2._config._screenW - Style._safeLeft - Style._safeRight );
        this.setH( _x2._config._screenH );

        var blockW = this.getW() * 2/3;
        var blockH = this.getH() * 1/3;

        this._line1 = new StringWidget().init( { style:style1 } );
        this._line2 = new TextBlockWidget().init( { style:style2, w:blockW, h:blockH } );

        this.setMessage( key );

        this.addWidget( this._line1 );
        this.addWidget( this._line2 );

        if( widget )
        {
            this._widget = widget;
            widget.setA( 0 );
            widget.addReadyCallback( onReady );
            this.addWidget( widget );
        }

        this._line1.setA(0);
        this._line2.setA(0);

        this._readyCount = 0;
        this._line1.addReadyCallback( onReady );
        this._line2.addReadyCallback( onReady );

        return this;
    };

    EmptyMessageWidget.prototype.isShowing = function()
    {
        return this._isShowing;
    };

    EmptyMessageWidget.prototype.layout = function()
    {
        this.setH( _x2._config._screenH - this.getGlobalPos().y );

        var pad = Style._pad * 2;
        var w   = this.getW();
        var h   = this.getH();

        var w1 = this._line1.getW();
        var w2 = this._line2.getW();

        var h1 = this._line1.getH();
        var h2 = this._line2.getH();

        var totalH = h1 + h2 + pad + ( this._widget? this._widget.getH() + 2 * pad : 0 );

        this._line1.setX( w/2 - w1/2 );
        this._line2.setX( w/2 - w2/2 );

        this._line1.setY( h/2 - totalH/2 );
        this._line2.setY( h/2 - totalH/2 + h1 + pad );

        if( this._widget )
        {
            this._widget.setX( (this.getW() - this._widget.getW()) / 2 );
            this._widget.setY( this._line2.getY() + this._line2.getH() + 2 * pad );
        }

        this.callbackSignalReady();
    };

    EmptyMessageWidget.prototype.setMessage = function( messageKey )
    {
        var text1, text2;

        switch( messageKey )
        {
            case EmptyMessageWidget.Key.PURCHASES_ON:
                text1 = "No purchases found.";
                text2 = "When you purchase or rent something, it will be available to watch here.";
                break;
            case EmptyMessageWidget.Key.PURCHASES_OFF:
                text1 = "You haven't purchased anything yet.";
                text2 = "Unfortunately, you can’t buy shows or movies using this app. But, if you rent or buy through the XFINITY Stream website or your TV, you can watch here.";
                break;
            case EmptyMessageWidget.Key.RECORDINGS:
                text1 = "You have no completed recordings yet.";
                text2 = "Tip: Visit Saved > Scheduled to see your recording schedule.";
                break;
            case EmptyMessageWidget.Key.UPCOMING:
                text1 = "Your schedule is empty.";
                text2 = "Nothing is scheduled for the next 14 days.";
                break;
            case EmptyMessageWidget.Key.FAVORITES:
                text1 = "No Favorites...yet.";
                text2 = "Getting started is easy. Just select \"Favorite\" on the Info screen for any TV show, movie, or team.";
                break;
            case EmptyMessageWidget.Key.GRID_FILTER:
                text1 = "We didn’t find a match.";
                text2 = "Expand your search by adjusting or removing your filters.";
                break;
            case EmptyMessageWidget.Key.GRID_EMPTY:
                text1 = "Get more of what you’re looking for.";
                text2 = "It looks like your plan doesn’t include any channels. Luckily, there’s an easy fix. Xfinity Stream delivers hundreds of channels that you can watch just about anywhere. Learn more at the Xfinity website.";
                break;
            case EmptyMessageWidget.Key.GRID_ERROR:
                text1 = "Sorry, we’re having some trouble.";
                text2 = "We can’t load your Guide right now. Please give us a few minutes and try again.";
                break;
        }

        this._line1.setText( text1 );
        this._line2.setText( text2 );

        if( this._ready )
            this.layout();
    };

    EmptyMessageWidget.prototype.show = function()
    {
        this._line1.setA(1);
        this._line2.setA(1);
        if( this._widget )
            this._widget.setA( 1 );
        this._isShowing = true;
    };

    return EmptyMessageWidget;

})();
