// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.CheckBoxRowWidget = ( function()
{
    "use strict";

    CheckBoxRowWidget.prototype = new ListRowWidget();

    function CheckBoxRowWidget(){}

    /**
     * Initializer
     * @memberof CheckBoxRowWidget
     * @param   {Object}   params
     * @param   {String}   params.title    - String to be displayed in the entry
     * @param   {Number}   params.w        - Display width of the row
     * @returns {CheckBoxRowWidget}
     * @see ListRowWidget
     */
    CheckBoxRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );
        this._className  = "CheckBoxRowWidget";
        this._selectable = true;

        var self       = this;
        var titleStyle = new Style( { color:"#b8b8b8", colorHi:"#2ea0dd", font:"light" , fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap" } );
        var glyphStyle = new Style( { color:"#b8b8b8", colorHi:"#2ea0dd", font:"medium", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap" } );

        this._numElements = 0;

        this._title = new StringWidget().init( { text:params.title, style:titleStyle } );
        this._title.addReadyCallback( function() { self._numElements++; self.layout(); } );
        this.addWidget( this._title );

        this._glyph = new StringWidget().init( { style:glyphStyle } );
        this.addWidget( this._glyph );

        this._check = new DualImageWidget().init( { url:_x2._config._imageRoot + "check_white.png", urlHi:_x2._config._imageRoot + "check.png" } );
        this._check.setA( 0 );
        this._check.addReadyCallback( function() { self._numElements++; self.layout(); } );
        this.addWidget( this._check );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        return this;
    };

    CheckBoxRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 114 * _x2._config._text );
    };

    CheckBoxRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        ListRowWidget.prototype.gotFocus.call( this, losingFocus );
        this._glyph.gotFocus();
        this._title.gotFocus();
        this._check.gotFocus();
        this._title.setSpeechParams( undefined, "_radio", this, newScreen, this._data.selected );

        return this;
    };

    CheckBoxRowWidget.prototype.layout = function()
    {
        if( this._numElements === 2 )
        {
            this._title.setX( 2 * this._check.getW() );
            this._title.setY( (this.getHeightUnfocused() - this._title.getH()) / 2 );
            this._glyph.setX( this.getW() - this._glyph.getW() );
            this._glyph.setY( (this.getHeightUnfocused() - this._glyph.getH()) / 2 );
            this._check.setY( (this.getHeightUnfocused() - this.getSepH() - this._check.getH()) / 2 );
        }
    };

    CheckBoxRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._glyph.lostFocus();
        this._title.lostFocus();
        this._check.lostFocus();
    };

    CheckBoxRowWidget.prototype.processEvent = function( val, type )
    {
        var retval;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    retval = true;

                    this.setSelected( !this._data.selected, true );
                    this._title.setSpeechParams( undefined, "_radio", this, false, this._data.selected );
                }
                break;
        }

        if( retval === undefined )
            retval = ListRowWidget.prototype.processEvent.call( this, val, type );

        return retval;
    };

    CheckBoxRowWidget.prototype.setData = function( data )
    {
        var self = this;
        this._data = data;

        if( data !== undefined )
        {
            this._title.setText( data.title );
            this._glyph.setText( data.glyph );
            this.setSelected( data.selected );
            this.setA( 1 );
            this.setMouseDownListener( function( event ) { event.stopPropagation(); self.processEvent( Host.KEY_ENTER, Host.KEY_PRESSED ); } );
        }
        else
        {
            this.setA( 0 );
            this.enableMouseDownListener( false );
        }
    };

    CheckBoxRowWidget.prototype.setSelected = function( val, animate )
    {
        this._data.selected = val;

        var alpha = val ? 1 : 0 ;

        if( animate )
            this._check.animate( { a:alpha, duration:300 } );
        else
            this._check.setA( alpha );
    };

    return CheckBoxRowWidget;

})();
