// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * Highlight over which widget currently has focus
 * @class
 */
window.SelectionWidget = ( function()
{
    "use strict";

    SelectionWidget.prototype = new NineSliceWidget();

    SelectionWidget.FADE_IN_TIME  = 400;
    SelectionWidget.FADE_OUT_TIME = 100;

    function SelectionWidget(){}

    /**
     * Initializer
     * @param   {Object} param
     * @returns {SelectionWidget}
     * @see NineSliceWidget.init()
     */
    SelectionWidget.prototype.init = function( params )
    {
        if( this._className === undefined )
            this._className = (params && params.name) ? params.name : "SelectionWidget";

        NineSliceWidget.prototype.init.call( this, params );

        this._fadingIn = false;

        return this;
    };

    /**
     * @memberof SelectionWidget
     */
    SelectionWidget.prototype.fadeIn = function()
    {
        this._fadingIn = true;
        this.animate( { alpha:1.0, duration:SelectionWidget.FADE_IN_TIME } );
    };

    /**
     * @memberof SelectionWidget
     */
    SelectionWidget.prototype.fadeOut = function( time )
    {
        this._fadingIn = false;

        if( this.getVal( Widget.ALPHA ) === 1.0 )
            this.setVal( Widget.ALPHA, 0.99 );

        this.animate( { alpha:0.0, duration:(time === undefined) ? SelectionWidget.FADE_OUT_TIME : time } );
    };

    /**
     * @memberof SelectionWidget
     * @param {Object} params
     * @see NineSliceWidget
     */
    SelectionWidget.prototype.setPosition = function( params )
    {
        var self = this;

        if( this.getA() < 1.0 )
        {
            if( this._fadingIn === false )
            {
                params.onEnd = function() { self.fadeIn(); };
                params.duration = 0;
            }
        }

        NineSliceWidget.prototype.setPosition.call( this, params );
        _x2._shadow.setPosition( { w:params.w, h:params.h } );
    };

    return SelectionWidget;

})();
