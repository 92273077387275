// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * This widget encapsulates the Watch, Resume, Record, Episodes, Recording Options, Upcoming, Movie Info, Series Info buttons
 * @class
 */
window.EntityActionButtonsWidget = (function()
{
    "use strict";

    function EntityActionButtonsWidget(){}

    EntityActionButtonsWidget.prototype = new Widget();

    EntityActionButtonsWidget.prototype.gotFocus = function()
    {
        return _x2.requestFocus( this._menuWidget );
    };

    EntityActionButtonsWidget.prototype.getRecordingRefreshParams = function( form )
    {
        var params = {};
        params.hideDistractor = true;

        if( [ this._setSerRecForm, this._modSerRecForm, this._cancelSerRecForm ].indexOf( form ) !== -1 )
        {
            if( this._entity instanceof LinearShowing )
                params.linearEntityRecording = { force:true };
            else
                params.entityRecording = { force:true };
        }
        else
        {
            if( this._entity instanceof Recording )
                params.entity = true;
            else
                params.linearRecording = { force:true };
        }

        return params;
    };

    EntityActionButtonsWidget.prototype.init = function( entity, w )
    {
        Widget.prototype.init.call( this );

        var self     = this;
        var numReady = 0;

        var onGotFocus = function()
        {
            var refreshOptions = self._pendingRefreshParams;

            if( self._refreshResumePointsOnFocus === true )
            {
                // if there is a pending refresh, piggy back on it
                if( refreshOptions !== undefined )
                {
                    refreshOptions.resumePoints = true;
                    refreshOptions.distractor = true;
                }
                else
                {
                    refreshOptions = {
                        distractor : true,
                        resumePoints : true,
                        showDistractor : true
                    }
                }

                self._refreshResumePointsOnFocus = false;
            }

            if( refreshOptions )
            {
                if( refreshOptions.delay === true )
                    refreshOptions.delay = false; //wait for the next refresh call
                else
                {
                    refreshOptions.waitOnFocus = false;
                    self.refresh( refreshOptions );
                    self._pendingRefreshParams = undefined;
                }
            }
        };

        var placeDistractor = function()
        {
            if( ++numReady === 2 )
            {
                self._distractor.centerOnScreen();
                self.setH( self._menuWidget.getH() );
            }
        };

        this._className  = "EntityActionButtonsWidget";
        this._selectable = true;
        this._menuWidget = new HorizPillButtonsWidget().init( { spacing: _x2.scaleValInt( 45 - Style._pad ), bypassDown: true, scrollW:w } );
        this._menuWidget.addReadyCallback( placeDistractor );
        this._menuWidget.setFocusListeners( onGotFocus );
        this.setW( w );

        this.addWidget( this._menuWidget );

        if( entity )
            setTimeout( function() { self.setData({entity:entity}) }, 1 );

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( placeDistractor );
        this.addWidget(this._distractor);

        return this;
    };

    EntityActionButtonsWidget.prototype.refresh = function( params )
    {
        var self = this;

        var exec = function()
        {
            var onComplete = function()
            {
                if( self._refreshListener )
                    self._refreshListener( self._entity );

                self.setData();
                self._menuWidget.setDisabled(false);

                if( params.hideDistractor || params.showDistractor )
                    self._distractor.hide();
            };

            var p = [];

            if( params.entity )
                p.push( self._entity.reload() );
            if( params.entityOptions )
                p.push( self._entity.fetchEntityOptions( params.entityOptions.force ) );
            if( params.entityRecording )
                p.push( self._entity.fetchEntityRecording( params.entityRecording.force ) );
            if( params.linearRecording )
                p.push( self._entity.fetchEpisodeRecording( params.linearRecording.force ) );
            if( params.linearEntityRecording )
                p.push( self._entity.fetchSeriesRecording( params.linearEntityRecording.force ) );
            if( params.resumePoints )
                p.push( _x2.refreshResumePoints() );

            if( params.showDistractor )
                self._distractor.show();

            self._menuWidget.setA(0);
            self._menuWidget.setDisabled(true);

            Promise.all( p ).then( onComplete );
        };

        if( params.waitOnFocus )
            this._pendingRefreshParams = params;
        else
            exec( params )
    };

    EntityActionButtonsWidget.prototype.selectBuyRent = function()
    {
        var self = this;

        var onBuyCompleted = function( watched )
        {
            var params = {};
            params.waitOnFocus    = true;
            params.showDistractor = true;
            params.entityOptions  = { force: true };

            if( watched )
            {
                params.resumePoints = true;
                params.delay        = true; //this is a hack to delay the refresh until the second gotFocus call to work around the way focus transitions when playing purchased content.
            }

            self.refresh( params );
        };

        _x2.pushOverlay( new PurchaseOptionsOverlay().init( this._entity, onBuyCompleted ) );
    };

    EntityActionButtonsWidget.prototype.selectWatch = function( resumePoint )
    {
        //TODO: 'entity' is confusing here. 'entity' should the Entity instance and 'showing' should be the VOD/Linear/Recording showing. We should also mod VideoScreen to accept entity and showing.
        var program, url, mode, position, mediaId, programId, paid, hasSap, hasDvs, streamId, assetId, providerId, mediaGuid, recordingId, options, vodShowings, linearShowings, recordings;
        var self   = this;
        var entity = this._entity;

        this._refreshResumePointsOnFocus = true;

        var playVideo = function()
        {
            var play = function()
            {
                var obj =
                {
                    url:url,
                    mediaId:mediaId,
                    programId:programId,
                    paid:paid,
                    mode:mode,
                    entity:entity,
                    resume:position,
                    hasSap:hasSap,
                    hasDvs:hasDvs,
                    streamId:streamId,
                    assetId:assetId,
                    providerId:providerId,
                    mediaGuid:mediaGuid,
                    recordingId:recordingId
                };

                self._refreshResumePointsOnFocus = true;
                _x2.pushScreen( new VideoScreen().init( obj ) );
            };

            if( _x2._pc.isEnabled() === true )
                _x2.checkParentalControls( entity, program, self._channel ).then( play );
            else
                play();
        };

        //use the resume point's showing if we have one.
        if( resumePoint )
        {
            entity   = resumePoint.getShowing();
            position = resumePoint.getPosition();
        }

        //extract a showing from Entity or push options screen if > 1 showing.
        if( entity instanceof Entity )
        {
            program        = entity;
            programId      = entity.getEntityId();
            options        = entity.getEntityOptions();
            vodShowings    = options.getVodShowings();
            linearShowings = options.getOnNowListings();
            recordings     = options.getRecordings();

            if( ( vodShowings.length + linearShowings.length + recordings.length ) > 1 )
                _x2.pushScreen( new ShowingsScreen().init( { entity:entity } ) );
            else
            {
                if( vodShowings.length > 0 )
                    entity = vodShowings[0];
                else if( linearShowings.length > 0 )
                    entity = linearShowings[0];
                else if( recordings.length > 0 )
                    entity = recordings[0];

                //add entity reference to showing if not already set so that VideoScreen will have access to Entity data.
                if( entity.getEntity() === undefined )
                    entity.setEntity( program );
            }
        }

        //get common properties from showing
        if( entity instanceof Showing )
        {
            if( ! program )
                program = entity.getEntity();

            url       = entity.getStreamUrl();
            mediaId   = entity.getMediaId();
            programId = entity.getProgramId();
            paid      = entity.getPaid();
            hasSap    = entity.isSap();
        }

        //set mode for each type and get type-specific properties
        if( entity instanceof Recording )
        {
            mode        = VideoScreen.Mode.DVR;
            recordingId = entity.getId();
            hasDvs      = entity.isDvs();
        }
        else if( entity instanceof VodShowing )
        {
            mode       = VideoScreen.Mode.VOD;
            assetId    = entity.getAssetId();
            providerId = entity.getProviderId();
            mediaGuid  = entity.getMediaGuid();
            hasDvs     = false;
        }
        else if( entity instanceof LinearShowing )
        {
            mode     = VideoScreen.Mode.LIVE;
            url      = this._channel.getStreamUrl();
            streamId = this._channel.getStreamId();
            hasDvs   = entity.isDvs();
        }

        if( url )
            playVideo();
    };

    EntityActionButtonsWidget.prototype.selectRecord = function( form )
    {
        var entity,
            setRecording,
            pushSeriesOptions,
            optCallback,
            optParams,
            optOverlay,
            self = this;

        if( this._entity instanceof Showing )
            entity = this._entity.getEntity();
        else if( this._entity instanceof Entity )
            entity = this._entity;
        else
            console.log( "ERROR: Unexpected type passed to EntityActionButtonsWidget.record");

        setRecording = function( form, overlay )
        {
            var success,
                error;

            success = function( response )
            {
                _x2._hi.fadeOut(0);

                self._distractor.hide();

                var responseObj = JSON.parse( response.data );

                if( responseObj['conflictingRecordingIds'] )
                    _x2.pushOverlay( new AlertOverlay().init( { title:"There's a conflict", message:"There are too many recordings scheduled. Please cancel a few of your recordings and try again." } ) );
                else
                {
                    self.refresh( self.getRecordingRefreshParams( form ) );
                    _x2.pushOverlay( new MessageOverlay().init( { message:"Your Recording is Set" } ) );
                }
            };

            error = function( error )
            {
                _x2._hi.fadeOut(0);

                self._distractor.hide();

                _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.DVR } ) );
            };

            if( overlay )
                _x2.popOverlay( overlay );

            self._distractor.show();
            form.submit().then( success ).catch( error );
        };

        pushSeriesOptions = function( form, overlay )
        {
            var formOverlay,
                formParams;

            formParams = {
                entity   : entity,
                form     : form,
                callback : function( form ){ setRecording( form, formOverlay ) }
            };

            formOverlay = new RecordingOptionsOverlay().init( formParams );

            _x2.pushOverlay( formOverlay );

            if( overlay )
                _x2.popOverlay( overlay );
        };

        if( form !== undefined )
        {
            if( form === this._setEpRecForm )
                setRecording( this._setEpRecForm );
            else
                pushSeriesOptions( this._setSerRecForm );
        }
        else
        {
            if( this._setSerRecForm && this._setEpRecForm )
            {
                optCallback = function( option, overlay )
                {
                    if( option === 'series' )
                        pushSeriesOptions( self._setSerRecForm, overlay );
                    else
                        setRecording( self._setEpRecForm , overlay );
                };

                optParams = {
                    title    : "Recording Options",
                    button1  : { text:"Just This Episode", key:'episode' },
                    button2  : { text:"All Episodes"     , key:'series'  },
                    callback : function(option){ optCallback(option, optOverlay) }
                };

                optOverlay = new TwoOptionsOverlay().init( optParams );
                _x2.pushOverlay( optOverlay );
            }
            else if( this._setEpRecForm )
                setRecording( this._setEpRecForm );
            else if( this._setSerRecForm )
                pushSeriesOptions( this._setSerRecForm );
            else
                console.log( "ERROR - there are no recording options. Recording button should not appear.")
        }
    };

    EntityActionButtonsWidget.prototype.selectRecordingOptions = function()
    {
        var self   = this,
            entity = ( this._entity instanceof Showing ? this._entity.getEntity() : this._entity ),

            canModEp  = ( undefined !== ( this._modEpRecForm  || this._delEpRecForm     ) ),
            canModSer = ( undefined !== ( this._modSerRecForm || this._cancelSerRecForm ) ),
            canSetEp  = ( undefined !== this._setEpRecForm && canModEp === false ),
            canSetSer = ( undefined !== this._setSerRecForm ),

            hasEpisodeOption = ( canSetEp  || canModEp  ),
            hasSeriesOption  = ( canSetSer || canModSer ),

            setEpisodeButton    = this._setEpRecForm     ? { text:this._setEpRecForm.getTitle()    , key:{ type:'episode' , op:'set'    , form:this._setEpRecForm     } } : undefined,
            modEpisodeButton    = this._modEpRecForm     ? { text:this._modEpRecForm.getTitle()    , key:{ type:'episode' , op:'mod'    , form:this._modEpRecForm     } } : undefined,
            deleteEpisodeButton = this._delEpRecForm     ? { text:this._delEpRecForm.getTitle()    , key:{ type:'episode' , op:'delete' , form:this._delEpRecForm     } } : undefined,
            cancelEpisodeButton = this._cancelEpRecForm  ? { text:this._cancelEpRecForm.getTitle() , key:{ type:'episode' , op:'cancel' , form:this._cancelEpRecForm  } } : undefined,
            setSeriesButton     = this._setSerRecForm    ? { text:this._setSerRecForm.getTitle()   , key:{ type:'series'  , op:'set'    , form:this._setSerRecForm    } } : undefined,
            modSeriesButton     = this._modSerRecForm    ? { text:this._modSerRecForm.getTitle()   , key:{ type:'series'  , op:'mod'    , form:this._modSerRecForm    } } : undefined,
            cancelSeriesButton  = this._cancelSerRecForm ? { text:this._cancelSerRecForm.getTitle(), key:{ type:'series'  , op:'cancel' , form:this._cancelSerRecForm } } : undefined;

        //show a recording options overlay and submit form with choices.
        var presentOptionsForm = function( form )
        {
            var submitOptionsForm = function()
            {
                var success = function()
                {
                    _x2._hi.fadeOut(0);
                    self.refresh( self.getRecordingRefreshParams( form ) );
                    _x2.pushOverlay( new MessageOverlay().init( { message:"Recording Updated" } ) );

                    if( formOverlay )
                        _x2.popOverlay( formOverlay );
                };

                var error = function( error )
                {
                    _x2._hi.fadeOut(0);
                    self._distractor.hide();
                    _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.DVR } ) );

                    if( formOverlay )
                        _x2.popOverlay( formOverlay );
                };

                self._distractor.show();
                form.submit().then(success).catch(error);
            };

            var formParams = {
                entity   : entity,
                form     : form,
                callback : submitOptionsForm
            };

            var formOverlay = new RecordingOptionsOverlay().init( formParams );
            _x2.pushOverlay( formOverlay );
        };

        //delete or cancel a recording
        var removeRecording = function( option )
        {
            var form = option.form,
                successMessage = ( option.op === 'delete' ? "Recording Deleted" : "Recording Cancelled" );

            var onSuccess = function()
            {
                self.refresh( self.getRecordingRefreshParams( form ) );
                _x2.pushOverlay( new MessageOverlay().init( { message:successMessage } ) );
            };

            var onError = function( error )
            {
                self._distractor.hide();
                _x2.pushOverlay( new ErrorOverlay().init( { context:ErrorOverlay.Context.DVR, error:error } ) );
            };

            self._distractor.show();
            form.submit().then( onSuccess ).catch( onError );
        };

        //handle presenting one of the 6 forms
        var onOptionSelected = function( option, popOverlay )
        {
            switch(  option.op )
            {
                case 'set':
                    self.selectRecord( option.form );
                    break;
                case 'mod':
                    presentOptionsForm( option.form );
                    break;
                case 'cancel':
                case 'delete':
                    removeRecording( option );
            }

            if( popOverlay )
                _x2.popOverlay( popOverlay );
        };

        //handle choosing between mod and delete
        var onTypeSelected = function( type, popOverlay )
        {
            var optionParams,
                optionOverlay,
                entityRemoveButton = ( self._cancelEpRecForm ? cancelEpisodeButton : deleteEpisodeButton ),
                option1 = ( type === 'series') ? cancelSeriesButton : entityRemoveButton,
                option2 = ( type === 'series') ? modSeriesButton    : modEpisodeButton,
                optionsTitle;

            if( type === 'series' )
                optionsTitle = "Modify Series Recording";
            else
                optionsTitle = "Modify Recording";

            optionParams = {
                title    : optionsTitle,
                button1  : option1,
                button2  : option2,
                callback : function( option ){ onOptionSelected( option, optionOverlay ) }
            };
            optionOverlay = new TwoOptionsOverlay().init( optionParams );

            _x2.pushOverlay( optionOverlay );

            if( popOverlay )
                _x2.popOverlay( popOverlay )
        };

        //begin option (set/mod/delete) and type (episode/series) decision
        if( hasEpisodeOption && hasSeriesOption )
        {
            var typeSelector,
                typeOverlay,
                typeParams,
                type1 = ( canSetEp  ? setEpisodeButton : modEpisodeButton ),
                type2 = ( canSetSer ? setSeriesButton  : modSeriesButton  );

            typeSelector = function( option )
            {
                if( option.op === 'set' )
                    onOptionSelected( option, typeOverlay );
                else
                    onTypeSelected( option.type, typeOverlay );
            };

            typeParams = {
                title    : "Record Options",
                button1  : type1,
                button2  : type2,
                callback : typeSelector
            };
            typeOverlay = new TwoOptionsOverlay().init( typeParams );

            _x2.pushOverlay( typeOverlay );
        }
        else if( canModEp )
            onTypeSelected( 'episode' );
        else if( canModSer )
            onTypeSelected( 'series' );
    };

    EntityActionButtonsWidget.prototype.selectEpisodes = function()
    {
        _x2.pushScreen( new EpisodesScreen().init( { entity: this._entity } ) );
    };

    EntityActionButtonsWidget.prototype.selectInfo = function()
    {
        var entity = this._entity instanceof Showing ? this._entity.getEntity() : this._entity;
        _x2.pushScreen( new EntityScreen().init( { entity:entity } ) );
    };

    EntityActionButtonsWidget.prototype.selectResume = function()
    {
        var rp;

        if( this._entity instanceof Entity )
            rp = this._entity.getEntityOptions().getResumePoint();
        else
            rp = this._entity.getResumePoint();

        this.selectWatch( rp );
    };

    EntityActionButtonsWidget.prototype.selectSeriesInfo = function( seriesId )
    {
        this._refreshResumePointsOnFocus = true;
        var browseEntity = new BrowseEntity().init( { entityId:seriesId } );
        _x2.pushScreen( new EntityScreen().init( { entity:browseEntity } ) );
    };

    EntityActionButtonsWidget.prototype.selectStartOver = function()
    {
        //NOTE: we want to play the showing associated with the resume point, so pass that resume point (with embedded showing) after setting position to 0
        var resumePoint;

        if( this._entity instanceof Entity )
            resumePoint = this._entity.getEntityOptions().getResumePoint();
        else
            resumePoint = this._entity.getResumePoint();

        if( resumePoint )
            resumePoint.setPosition(0);

        this.selectWatch( resumePoint );
    };

    EntityActionButtonsWidget.prototype.selectUpcoming = function()
    {
        _x2.pushScreen( new UpcomingScreen().init( { entity:this._entity } ) );
    };

    EntityActionButtonsWidget.prototype.selectErase = function()
    {
        var self = this;
        var success = function()
        {
            self._distractor.hide();
            _x2.pushOverlay( new MessageOverlay().init( { message:"Recording was erased", onDismiss:function(){ _x2.popScreen() } } ) );
        };

        var error = function( error )
        {
            self._distractor.hide();
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.DVR } ) );
        };

        this._distractor.show();
        this._menuWidget.setDisabled( true );
        this._entity.erase().then( success ).catch( error )
    };

    EntityActionButtonsWidget.prototype.selectRecover = function()
    {
        var self = this;
        var success = function()
        {
            self._distractor.hide();
            _x2.pushOverlay( new MessageOverlay().init( { message:"Recording was recovered", onDismiss:function(){ _x2.popScreen() } } ) );
        };

        var error = function(error)
        {
            self._distractor.hide();
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.DVR } ) );
        };

        this._distractor.show();
        this._menuWidget.setDisabled( true );
        this._entity.undelete().then( success ).catch( error );
    };

    EntityActionButtonsWidget.prototype.selectSubscribe = function()
    {
        var offer = this._entity.getEntityOptions().getSubscribeOffer();
        _x2.pushOverlay( new SubscribeOverlay().init( offer ) )
    };

    EntityActionButtonsWidget.prototype.onFavoriteChanged = function(changedStatus)
    {
        var self = this;

        var onRefresh = function()
        {
            self._distractor.hide();
            if( self._isChannel ) {
                self._entity._channel.isFavorite = changedStatus;
                self.setData( { entity: self._entity, isChannel: true });
            } else {
                self.setData();
            }
            
            if( self._refreshListener ) {
                self._refreshListener( self._entity );
            }
        };

        _x2.refreshFavorites().then( onRefresh );
    };

    EntityActionButtonsWidget.prototype.setFavorite = function()
    {
        var self   = this;
        var object = this._isChannel ? this._channel : this._entity;

        var fail = function( error )
        {
            self._distractor.hide();
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.COMMON } ) );
        };

        this._distractor.show();

        _x2._favorites.addFavorite( object ).then( function () { _x2.localyticsSendFavoriteEntityOrViewed( self._entity, "Add" ); self.onFavoriteChanged(1) } ).catch( fail );
    };

    EntityActionButtonsWidget.prototype.removeFavorite = function()
    {
        var self = this;
        var object = this._isChannel ? this._channel : this._entity;

        var fail = function( error )
        {
            self._distractor.hide();
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.COMMON } ) );
        };

        var success = function()
        {
            self.onFavoriteChanged(0);

            _x2.localyticsSendFavoriteEntityOrViewed( self._entity, "Remove" );
        };

        this._distractor.show();

        _x2._favorites.removeFavorite( object ).then( success ).catch( fail );
    };

    /**
     * @param [params]
     * @param [params.entity]     {Entity | Showing} - Pass either a Entity or a Showing type [LinearShowing, VodShowing, Recording]
     * @param [params.channel]    {Channel}
     * @param [params.infoButton] {Boolean} - Include "Movie Info" or "Series Info" button.
     * @param [params.isChannel]  {Boolean} - Are the buttons associated with a channel
     */
    EntityActionButtonsWidget.prototype.setData = function( params )
    {
        var self        =  this,
            buttonStyle = new Style( { font:"light", color:"#e8e8e8", fontSize:_x2.scaleValInt( 30 ), whiteSpace:"nowrap", colorHi:"#000000" } ),
            buttons     = [],
            entity,
            options,
            showing,
            type,
            isWatchable,
            isCheckedOut,
            hasResumePoint,
            hasMultipleShowings,
            hasUpcomingListings,
            hasBuyOption,
            hasRentOption,
            hasSubscribeOption,
            isFavorite,
            isFavoriteable,
            seriesId,
            episodeRecording,
            seriesRecording;

        this._setEpRecForm     = undefined;
        this._modEpRecForm     = undefined;
        this._delEpRecForm     = undefined;
        this._cancelEpRecForm  = undefined;
        this._eraseEpRecForm   = undefined;
        this._undelEpRecForm   = undefined;
        this._setSerRecForm    = undefined;
        this._modSerRecForm    = undefined;
        this._cancelSerRecForm = undefined;

        if( params )
        {
            this._entity     = params.entity;
            this._channel    = params.channel || params.entity._channel || ( params.entity && params.entity.getChannel && params.entity.getChannel() );
            this._infoButton = params.infoButton;
            this._isChannel  = params.isChannel;
        }
        if( this._entity instanceof Recording )
        {
            isCheckedOut  = this._entity.isCheckedOut();
            this._eraseEpRecForm = this._entity.getEraseForm();
            this._undelEpRecForm = this._entity.getUndeleteForm();
        }

        if( this._entity instanceof Showing )
        {
            showing = this._entity;
            entity  = this._entity.getEntity();

            hasResumePoint = showing.getMinsRemaining() > 0;
            isWatchable    = showing.isWatchable();

            if( showing instanceof LinearShowing )
            {
                seriesRecording = showing.getSeriesRecording();

                if( showing instanceof Recording )
                    episodeRecording = showing;
                else
                    episodeRecording = showing.getRecording();

                if( episodeRecording )
                {
                    this._modEpRecForm = episodeRecording.getModifyForm();
                    this._delEpRecForm = episodeRecording.getDeleteForm();
                    this._cancelEpRecForm = episodeRecording.getCancelForm();
                }
                else
                    this._setEpRecForm = showing.getEpisodeRecordingForm();

                if( seriesRecording )
                {
                    this._setSerRecForm    = seriesRecording.getScheduleForm();
                    this._modSerRecForm    = seriesRecording.getModifyForm();
                    this._cancelSerRecForm = seriesRecording.getCancelForm();
                }
            }
        }
        else if( this._entity instanceof Entity )
        {
            entity              = this._entity;
            options             = entity.getEntityOptions();
            seriesRecording     = entity.getEntityRecording();
            hasResumePoint      = options.getMinsRemaining() > 0;
            hasMultipleShowings = options.getVodShowings().length > 1;
            hasUpcomingListings = options.getUpcomingListings().length > 0;
            hasBuyOption        = options.hasBuyOption();
            hasRentOption       = options.hasRentOption();
            hasSubscribeOption  = options.hasSubscribeOption();
            isWatchable         = options.isWatchable();

            if( seriesRecording )
            {
                this._modSerRecForm    = seriesRecording.getModifyForm();
                this._cancelSerRecForm = seriesRecording.getCancelForm();
            }

            this._setSerRecForm = options.getSeriesRecordingForm();
        }

        var hasRecordingOptions      = ( undefined !== ( this._setEpRecForm || this._setSerRecForm ) );
        var hasModRecordingOptions   = ( undefined !== ( this._modEpRecForm || this._modSerRecForm || this._delEpRecForm || this._cancelEpRecForm || this._cancelSerRecForm ) );
        var hasEraseRecordingOptions = ( undefined !== this._eraseEpRecForm );

        type     = entity && entity.getType();
        seriesId = entity && entity.getSeriesId();

        if( type === XtvApi.EntityType.SERIES && options )
        {
            if( options.getEpisodeCount() > 0 || options.getSeasonCount() > 0 )
                buttons.push( new StringWidget().init( { text: "Episodes", style: buttonStyle, onEnter: function() { self.selectEpisodes() } } ) );
        }

        if( _x2._config._isChurned === true )
        {
            isFavorite     = false;
            isFavoriteable = false;

        }
        else if( this._isChannel )
        {
            //Update favorite from local cache, since user might open channel info immediately, after change the favorite status
            isFavorite = this._channel.isFavorite > -1 ? !!this._channel.isFavorite : _x2._favorites.isFavorite( this._channel );
            isFavoriteable = true;
        }
        else
        {
            if( this._entity instanceof LinearShowing === false &&
                this._entity instanceof Purchase      === false &&
                this._entity instanceof Rental        === false   )
            {
                isFavorite = _x2._favorites.isFavorite( entity );
                isFavoriteable = ( type === XtvApi.EntityType.SERIES || type === XtvApi.EntityType.MOVIE ); // || type === XtvApi.EntityType.OTHER );
                // NOTE: not sure what 'OTHER' is supposed to cover here but it's causing sporting events to incorrectly be favorite-able.
                // Considering that favorites are segmented into TV Shows, Movies and Teams, we should be covered. (SportsTeamScreen does not use this)
            }
        }

        if( ! isCheckedOut )
        {
            if( isWatchable )
            {
                if( hasResumePoint )
                {
                    buttons.push( new StringWidget().init( { text: "Resume", style: buttonStyle, onEnter: function(){ self.selectResume() } } ) );
                    buttons.push( new StringWidget().init( { text: "Start Over", style: buttonStyle, onEnter: function(){ self.selectStartOver() } } ) );

                    if( hasMultipleShowings )
                        buttons.push( new StringWidget().init( { text: "Watch Options", style: buttonStyle, onEnter: function() { self.selectWatch(); } } ) );
                }
                else
                    buttons.push( new StringWidget().init( { text: "Watch", style: buttonStyle, onEnter: function() { self.selectWatch(); } } ) );
            }
        }

        if( hasBuyOption || hasRentOption )
        {
            var hasBuyAndRent = hasBuyOption && hasRentOption;
            var label = hasBuyAndRent ? "Rent/Buy" : ( hasBuyOption ? "Buy" : "Rent" );
            var metricsTransactionalButtonClicked =
            {
                "Initial Button Clicked": hasBuyAndRent ? "rent/buy" : (hasBuyOption ? "buy" : "rent"),
                "Initial Source"        : "Entity Button",
                "Program Type"          : (type === XtvApi.EntityType.EPISODE || type === XtvApi.EntityType.SERIES)
                                            ? "Series"
                                            : (type === XtvApi.EntityType.MOVIE)
                                                ? "Movie"
                                                : (type === XtvApi.EntityType.SPORTS || type === XtvApi.EntityType.TEAM)
                                                    ? "Sporting Event"
                                                    : "Other"
            };

            buttons.push( new StringWidget().init( { text: label, style: buttonStyle, onEnter: function() { window.ll( "tagEvent", "Transactional Button Clicked", metricsTransactionalButtonClicked ); self.selectBuyRent(); } } ) ); }

        if( hasSubscribeOption )
            buttons.push( new StringWidget().init( { text: "Subscribe", style: buttonStyle, onEnter: function() { self.selectSubscribe() } } ) );

        if( this._eraseEpRecForm !== undefined )
            buttons.push( new StringWidget().init( { text: "Erase", style: buttonStyle, onEnter: function() { self.selectErase() } } ) );

        if( this._undelEpRecForm !== undefined )
            buttons.push( new StringWidget().init( { text: "Recover", style: buttonStyle, onEnter: function() { self.selectRecover() } } ) );

        if( ! hasEraseRecordingOptions )
        {
            if( hasModRecordingOptions )
                buttons.push( new StringWidget().init( { text: "Recording Options", style: buttonStyle, onEnter: function(){ self.selectRecordingOptions() } } ) );
            else if( hasRecordingOptions )
                buttons.push( new StringWidget().init( { text: "Record", style: buttonStyle, onEnter: function(){ self.selectRecord() } } ) );

            if( hasUpcomingListings )
                buttons.push( new StringWidget().init( { text: "Upcoming", style: buttonStyle, onEnter: function(){ self.selectUpcoming() } } ) );

            if( this._infoButton === true && this._isChannel !== true && _x2._config._isChurned !== true )  // TODO: blocking channel info, need to add support for it
            {
                if( type === XtvApi.EntityType.EPISODE )
                {
                    if( seriesId )
                        buttons.push( new StringWidget().init( { text: "Series Info", style: buttonStyle, onEnter: function(){ self.selectSeriesInfo(seriesId) } } ) );
                }
                else if( type === XtvApi.EntityType.MOVIE )
                    buttons.push( new StringWidget().init( { text: "Movie Info", style: buttonStyle, onEnter: function(){ self.selectInfo() } } ) );
                else
                    buttons.push( new StringWidget().init( { text: "More Info", style: buttonStyle, onEnter: function(){ self.selectInfo() } } ) );
            }
        }
        if( isFavoriteable )
        {
            if( isFavorite )
            {
                if( !_x2._telemetry._watchButtonPath.length && !this._sentViewed ) // Any location aside from favorites will have an first entry "Browse: TV", "Search", etc.
                {
                    _x2.localyticsSendFavoriteEntityOrViewed( this._entity, "Viewed" );
                    this._sentViewed = true;
                }

                buttons.push( new StringWidget().init( { text: "Remove Favorite", style: buttonStyle, onEnter: function(){ self.removeFavorite() } } ) );
            }
            else
                buttons.push( new StringWidget().init( { text: "Favorite", style: buttonStyle, onEnter: function(){ self.setFavorite() } } ) );
        }

        if( _x2._pc.isEnabled() )
        {
            var isLocked, channelId, entityId, title, toggleLock;

            toggleLock = function()
            {
                var params = {}, onValidatePin, overlay, toPop, onSet, onError;

                onValidatePin = function( validatePinStr )
                {
                    if( _x2._pc.checkPin( validatePinStr ) === true )
                    {
                        onSet = function()
                        {
                            self._lockButton.setText( isLocked ? "Unlock" : "Lock" );
                            self._menuWidget.layout();

                            if( self._refreshListener )
                                self._refreshListener( self._entity );
                        };

                        onError = function(error)
                        {
                            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.COMMON } ) );
                        };

                        isLocked = !isLocked;

                        _x2.popOverlay( overlay );

                        if( self._isChannel === true )
                             _x2._pc.setChannelLocks( channelId, isLocked );
                        else
                            _x2._pc.setTitleLock( entityId, title, isLocked );

                        _x2._data.setParentalControlsSettings( _x2._pc ).then( onSet ).catch( onError );
                    }
                    else
                    {
                        toPop       = overlay;
                        params.type = PinOverlay.Type.PC_VALIDATE_FAIL;
                        _x2.pushOverlay( overlay = new PinOverlay().init( params ) );
                        _x2.popOverlay( toPop );
                    }
                };

                params.type      = PinOverlay.Type.PC_VALIDATE;
                params.onCapture = onValidatePin;
                _x2.pushOverlay( overlay = new PinOverlay().init( params ) );
            };

            if( this._isChannel === true )
            {
                channelId = this._channel.getId();
                isLocked  = _x2._pc.isChannelLocked( channelId );
            }
            else
            {
                entityId = entity.getEntityId();
                title    = entity.getTitle();
                isLocked = _x2._pc.isTitleLocked( entityId );
            }

            this._lockButton = new StringWidget().init({ text:isLocked ? "Unlock" : "Lock", style:buttonStyle, onEnter:toggleLock } );
            buttons.push( this._lockButton );
        }

        if( buttons.length )
        {
            this._menuWidget.setButtons( buttons );
            this._menuWidget.setA(1);
//            this._menuReady = true;
        }
    };

    /**
     * Register a callback to receive an updated entity when some action (setting/deleting a recording)
     * triggers a change to the entity state.
     * @param callback - passes the updated entity
     */
    EntityActionButtonsWidget.prototype.setRefreshListener = function( callback )
    {
        this._refreshListener = callback;
    };

    return EntityActionButtonsWidget;

})();
