// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.PurchaseOverlay = (function()
{
    "use strict";

    PurchaseOverlay.prototype = new Overlay();

    function PurchaseOverlay(){}

    PurchaseOverlay.prototype.init = function( params )
    {
        Overlay.prototype.init.call( this );
        this._className  = "PurchaseOverlay";

        var offer             = params.offer;
        var entity            = params.entity;
        var optionsOverlay    = params.optionsOverlay;
        var onBuy             = params.onBuy;
        var confirmOverlay    = undefined;
        var metrics           = offer.getLocalyticsCommonTransactional( entity.getType() );
        var distractorOverlay = new DistractorOverlay().init();

        var metricsTransactionalConfirmClicked =
        {
            "Option"      : metrics.option,
            "Final Action": metrics.finalAction
        };

        var self = this;

        var titleStyle    = new Style( { font:"light"  , color:"#e8e8e8", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
        var subTitleStyle = new Style( { font:"light"  , color:"#a3a3a3", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
        var footerStyle   = new Style( { font:"light"  , color:"#bababa", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
        var infoStyle     = new Style( { font: "medium", color:"#a3a3a3", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );

        var onBuyPress = function()
        {
            var onTransactionComplete = function( purchasedResponse )
            {
                self._distractor.hide();

                var onWatch = function()
                {
                    var onResolveShowing = function( response )
                    {
                        self._distractor.hide();

                        var showing = new VodShowing().init( JSON.parse( response.data ) );

                        var playVideo = function()
                        {
                            var obj =
                                    {
                                        mode       :VideoScreen.Mode.VOD,
                                        entity     :showing,
                                        url        :showing.getStreamUrl(),
                                        mediaId    :showing.getMediaId(),
                                        programId  :showing.getProgramId(),
                                        paid       :showing.getPaid(),
                                        hasSap     :showing.isSap(),
                                        assetId    :showing.getAssetId(),
                                        providerId :showing.getProviderId(),
                                        mediaGuid  :showing.getMediaGuid()
                                    };

                            //triggers refresh of watch options in EntityActionButtonsWidget on EntityScreen.
                            if( onBuy )
                                onBuy( true );

                            _x2.popOverlay( distractorOverlay );
                            _x2.popOverlay( confirmOverlay, function() { _x2.pushScreen( new VideoScreen().init( obj ) ) } );
                        };

                        if( _x2._pc.isEnabled() === true )
                            _x2.checkParentalControls( showing, entity ).then( playVideo );
                        else
                            playVideo();
                    };

                    var metricsTransactionalWatchNowClicked =
                    {
                        "Option": metrics.option
                    };

                    window.ll( "tagEvent", "Transactional Watch Now Clicked", metricsTransactionalWatchNowClicked );

                    self._distractor.show();
                    offer.getLinkObject("featureAsset").resolve().then( onResolveShowing );
                };

                var onClose = function()
                {
                    if( onBuy )
                        onBuy( false );

                    _x2.popOverlay( distractorOverlay );
                    _x2.popOverlay( confirmOverlay );
                };

                var params = {};
                params.entity  = entity;
                params.offer   = offer;
                params.onWatch = onWatch;
                params.onClose = onClose;

                var metricsTransactionalSuccess =
                {
                    "Option"  : metrics.option,
                    "PIN Used": self._pinRequired ? "Yes" : "No"
                };

                window.ll( "tagEvent", "Transactional Success", metricsTransactionalSuccess );

                if( _x2._splunk )
                    _x2._splunk.sendTransact( true, entity, offer, purchasedResponse );

                confirmOverlay = new PurchaseConfirmOverlay().init( params );

                _x2.popOverlay( self );
                _x2.pushOverlay( confirmOverlay );
            };

            var onTransactionError = function( error )
            {
                self._distractor.hide();

                var major = error.getMajor();
                var minor = error.getMinor();

                if( major === "500" && minor === "100" )  // 500.100 signals pin required; 500.101 signals a failed pin
                {
                    self._transactionInProgress = false;
                    buyNow( true );
                }
                else
                {
                    if( _x2._splunk )
                        _x2._splunk.sendTransact( false, entity, offer, error );

                    _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.TRANSACT, noRetry:true } ) );
                    _x2.popOverlay( distractorOverlay );
                    _x2.popOverlay( self );
                }
            };

            var doTransaction = function( pinObj, pin )
            {
                if( self._transactionInProgress !== true )
                {
                    self._distractor.show();
                    self._menu.setDisabled();
                    self._transactionInProgress = true;
                    offer.executeTransaction( pin ).then( onTransactionComplete ).catch( onTransactionError );
                }
            };

            var buyNow = function( pinChallenge )
            {
                self._pinRequired = pinChallenge;

                window.ll( "tagEvent", "Transactional Confirm Clicked", metricsTransactionalConfirmClicked );

                if( pinChallenge === true )
                {
                    window.ll( "tagEvent", "Transactional PIN", {} );
                    PinOverlay.setPinFlow( PinOverlay.FlowType.PURCHASE, doTransaction, undefined, undefined, function(){ _x2.popOverlay( distractorOverlay ) } );
                }
                else
                    doTransaction();
            };

            _x2.pushOverlay( distractorOverlay );
            _x2.popOverlay( self );
            _x2.popOverlay( optionsOverlay );  // cannot back out to options once buy is pressed, so pop it

            buyNow();  // try to execute without a pin. challenge will come from the API response.
        };

        var onCancel = function()
        {
            metricsTransactionalConfirmClicked[ "Final Action" ] = "[Unfinished]";
//console.log( "LOCALYTICS: Transactional Confirm Clicked: %o", metricsTransactionalConfirmClicked );
            window.ll( "tagEvent", "Transactional Confirm Clicked", metricsTransactionalConfirmClicked );

            _x2.popOverlay( self );
        };

        var onGotPinSettings = function( pinObj )
        {
            self._pinMsg.setA( pinObj.isEnabled() ? 1 : 0);
            self._ready = true;

            if( _x2._focus === self )
                self.speak( _x2.requestFocus( self._menu ) );
        };

        var onGotPinSettingsError = function( e )
        {
            console.error( e );
            self._ready = true;

            if( _x2._focus === self )
                self.speak( _x2.requestFocus( self._menu ) );
        };

        var url    = entity.getImageLink( 303, 404 );
        var title  = entity.getTitle();
        var season = offer.isSeasonBundle() ? " Season " : " ";
        var meta   = offer.getPurchaseType() + season + "$" + offer.getPrice() + " " + offer.getVideoQuality();
        var label  = offer.getPurchaseType() + " Now";
        var footer = "Access anytime in the Purchases menu on your favorite devices. View terms at xfinity.com/BuyTerms. Buy to agree.";

        var buttonStyle  = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000"  } );
        var buy    = new StringWidget().init( { text:label   , style:buttonStyle, onEnter:onBuyPress } );
        var cancel = new StringWidget().init( { text:"Cancel", style:buttonStyle, onEnter:onCancel } );
        var image  = new ImageWidget().init( { url:url, w:_x2.scaleValInt(303), h:_x2.scaleValInt(404) } );

        if( entity && entity.getSeries && offer.isSeasonBundle() === true )
        {
            var series = entity.getSeries();

            if( series )
                title = series.getTitle();
        }

        this._menu   = new HorizPillButtonsWidget().init( { entries:[buy,cancel], spacing:_x2.scaleValInt(41) } );
        this._title  = new StringWidget().init( { style:titleStyle   , text:title  } );
        this._meta   = new StringWidget().init( { style:subTitleStyle, text:meta   } );
        this._footer = new StringWidget().init( { style:footerStyle  , text:footer } );
        this._pinMsg = new StringWidget().init({ style:infoStyle, text: Strings.Glyphs.LOCKED + " Purchase PIN is required" } );
        this._pinMsg.setA(0);

        var xCoords = [ _x2.scaleValInt(80), _x2.scaleValInt(433) ];
        var yCoords = [ _x2.scaleValInt(80), _x2.scaleValInt(142), _x2.scaleValInt(396), _x2.scaleValInt(586), _x2.scaleValInt(630) ];

        this._bg.addWidget( image        , xCoords[0], yCoords[0] );
        this._bg.addWidget( this._title  , xCoords[1], yCoords[0] );
        this._bg.addWidget( this._meta   , xCoords[1], yCoords[1] );
        this._bg.addWidget( this._menu   , xCoords[1], yCoords[2] );
        this._bg.addWidget( this._pinMsg , xCoords[0], yCoords[3] );
        this._bg.addWidget( this._footer , xCoords[0], yCoords[4] );

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget( self ) } );
        this.addWidget(this._distractor);

        _x2._data.getPurchasePin().then( onGotPinSettings, onGotPinSettingsError );

        return this;
    };

    PurchaseOverlay.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();
        this.speak( this._ready ? _x2.requestFocus( this._menu ) : undefined );
    };

    PurchaseOverlay.prototype.speak = function( focus )
    {
        if( focus )
        {
            var str = this._pinMsg.getText().replace( /xfinity/gi, "x finity" );

            this._speakTarget.setText( "Confirm purchase. " + this._title.getText() + ". " + this._meta.getText() + ". " + focus._speakStr + ". " );
            this._speakTarget.setSpeechParams( (this._pinMsg.getA() === 1 ? str + ". " : "") + this._footer.getText().replace( /xfinity/gi, "x finity" ) + ". Press left or right to review options. Press OK to select. Press back to exit. ", focus._speakRole, this, false );
        }
        else
        {
            this._speakTarget.setText( "Loading, please wait. " );
            this._speakTarget.setSpeechParams( undefined, undefined, this, false );
        }
    };

    return PurchaseOverlay;

})();
