//Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * XtvApi is an API abstraction layer for the XTV API backend. This class exposes a subset of XTV APIs and
 * creates a clear interaction model for utilizing the backend services by:<br>
 *  ~ Exposing and documenting only the APIs used by coamX2<br>
 *  ~ Wrapping XTV API-returned data in a set of types/classes that expose their capabilities<br>
 *  ~ Manipulating XTV API data to make consumption easier<br>
 *  ~ Abstract away HAL (Hypermedia Application Language) semantics.<br>
 *  ~ Breaking the HTTP cache when required.<br>
 */

window.XtvApi = (function ()
{
    "use strict";

    /**
     * @enum {String} EntityType
     * @memberOf XtvApi
     */
    XtvApi.EntityType =
    {
        MOVIE   : "Movie",
        PROGRAM : "Program",
        SERIES  : "SeriesMaster",
        EPISODE : "Episode",
        SPORTS  : "Sports",
        TEAM    : "Team",
        OTHER   : "Other"
    };

    /**
     * Defines the screen layout
     * @enum {String} CollectionRenderStyle
     * @memberOf XtvApi
     */
    XtvApi.CollectionRenderStyle =
    {
        GALLERY_ROWS   : "GALLERY_ROWS",
        NETWORK_ENTITY : "NETWORK_ENTITY", //NOTE: not sure we will have a specific network view
        BROWSE         : "BROWSE"
    };

    /**
     * Defines the entity style/layout
     * @enum {String} EntityRenderStyle
     * @memberOf XtvApi
     */
    XtvApi.EntityRenderStyle =
    {
        "POSTER"             : "POSTER",
        "PROMO"              : "PROMO",
        "NETWORK"            : "NETWORK",
        "SMALL_PROMO"        : "SMALL_PROMO",
        "4X3_PROGRAM_LINEAR" : "4X3_PROGRAM_LINEAR",
        "3X4_PROGRAM_LINEAR" : "3X4_PROGRAM_LINEAR",
        "16X9_PROGRAM"       : "16X9_PROGRAM"
    };

    /**
     * This class is intended to be instantiated once, and accessed via a globally accessible variable
     * @param {String} host - The URL of the XTV API HAL
     * @param {Number} authType - From Config.AUTH
     * @constructor
     */
    function XtvApi( host, authType )
    {
        var onSuccess, onError, self = this;

        this._host = host;

        this.setAuthHeader( authType );

        onSuccess = function( response )
        {
            self._root = JSON.parse( response.data );
        };

        onError = function( error )
        {
            console.log( "ERROR -> Unable to load XTV API root [" + error + "]");
        };

        //keep a reference to the promise so we can use it to defer API calls before this (root) query resolves
        this._rootPromise = _x2._network.get( host ).then( onSuccess ).catch( onError );
    }

    /**
     * Add (non-channel) items to favorites.
     * @memberOf XtvApi
     * @param {String} selfLink - Object's _links.self.href
     * @return {Promise}
     */
    XtvApi.prototype.addFavoriteItem = function( selfLink )
    {
        return this.sendForm( "addFavoriteItems", { itemSelfLinks: '["' + selfLink + '"]' }, true );
    };

    /**
     * Add favorite channel
     * @memberOf XtvApi
     * @param {String} channelLink - Channel's _links.self.href
     * @return {Promise}
     */
    XtvApi.prototype.addFavoriteChannel = function( channelLink )
    {
        return this.sendForm( "addFavoriteChannels", { channelSelfLinks: '["' + channelLink + '"]' }, true );
    };

    /**
     * Setup the value of the "Authorization" header according to authType
     * @memberOf XtvApi
     * @param {Number} authType - Config.AUTH
     */
    XtvApi.prototype.setAuthHeader = function( authType )
    {
        var token;

        if( authType )
            this._authType = authType;

        if( this._authType === Config.AUTH_OAUTH )
        {
            token = Host.getLocalStorage( "OauthToken" );
            token = token && JSON.parse( token ).access_token;

            this._deviceHeader = { name:"X-FINITY-DEVICE-ID", value:"x1.cvp2" };
            this._authHeader   = { name:"Authorization", value:"Bearer " + token };
        }
        else
        {
            token = Host.getLocalStorage( "XsctToken" );

            if( token )
            {
                token = JSON.parse( token );

                if( token.tokenSummary.partnerId !== "comcast" )
                    _x2._config._xocPartner = token.tokenSummary.partnerId;

                token = token.xsct;
            }

            this._authHeader = { name:"Authorization", value:"CCP " + token };
        }
    };

    /**
     * Check in/return downloads for the given device
     * @memberOf XtvApi
     * @param {String} deviceId
     * @return {Promise}
     */
    XtvApi.prototype.checkInDownloadsForDevice = function( deviceId )
    {
        return _x2._network.ajax( { type: "POST", url: this._host + 'devices/' + deviceId + '/checkInAll/', headers:[this._authHeader] } );
    };

    /**
     * Translates a XACT token to DRM data including XSCT token
     * @memberOf XtvApi
     * @param   {String}  xact - XACT token string
     * @param   {String}  partnerId - Partner id
     * @return  {Promise} Promise resolves with a JSON structure containing DRM information including XSCT token.
     */
    XtvApi.prototype.createDrmSession = function( xact, partnerId )
    {
        var self = this;

        //NOTE: this extra promise layer is only needed because the callers of this function are expecting response.data instead of response. If we fix the callers we can eliminate it.
        var resolver = function( resolve, reject )
        {
            var success = function( response )
            {
                //follow the features link if available
                var drmSession = JSON.parse( response.data );
                if(drmSession.tokenSummary) {
                    _x2._splunk.sendProvisionSuccess({
                        accountId: drmSession.tokenSummary.xboAccountId,
                        deviceId: drmSession.tokenSummary.deviceId,
                        inHomeStatus: drmSession.tokenSummary.inHomeStatus,
                        isVideoEntitled: drmSession.tokenSummary.isLinearEntitled && drmSession.tokenSummary.isVODEntitled 
                    });
                }
                if( drmSession._links && drmSession._links.features )
                {
                    var onFeatures = function( featuresResponse )
                    {
                        //update the features object and resolve with response from the createDrmSession call
                        _x2._features.setData( JSON.parse( featuresResponse.data ) );
                        _x2._features.saveToLocalStorage();
                        window.sessionCreatedAt = new Date();
                        console.log("DRM session was created at ", new Date());
                        if( window.sessionStartCreatingAt ) {
                            console.log("Time taken to create DRM session & features is ", ( window.sessionCreatedAt - window.sessionStartCreatingAt) / 1000);
                        }
                        resolve( response.data );
                    };

                    var onFeaturesError = function( error )
                    {
                        console.log( error ); //TODO: what do we do about this?
                        resolve( response.data );
                    };

                    var link = drmSession._links.features.href;
                    var url  = self._host + XtvApi.trimPath( link );

                    var authHeader = { name:"Authorization", value:"CCP " + drmSession.xsct };
                    _x2._network.ajax( { url:url, type:"GET", headers:[authHeader], accepts:Network.Accepts.xtv } ).then(onFeatures).catch(onFeaturesError);
                }
                else {
                    window.sessionCreatedAt = new Date();
                    console.log("DRM session was created at ", new Date());
                    if( window.sessionStartCreatingAt ) {
                        console.log("Time taken to create DRM session is ", ( window.sessionCreatedAt - window.sessionStartCreatingAt) / 1000);
                    }
                    resolve( response.data );
                }
                    
            };
            var error   = function( error )
            {
                console.log("DRM session got failed at ", new Date() );
                reject( error );
            };

            self.sendForm( "createDrmSession", { xact:encodeURIComponent( xact ), partnerId:partnerId, hardAcquisition:"true" }, false ).then( success ).catch( error );
        };

        return new Promise( resolver );
    };

    /**
     * Delete all devices associated with the drmId in the XACT, or sets device to PendingDelete with XSCT
     * @memberOf XtvApi
     * @returns {Promise}
     */
    XtvApi.prototype.deleteDevice = function()
    {
        var tact = Host.getLocalStorage( "XactToken" );
        var tsct = Host.getLocalStorage( "XsctToken" );
        var xact = tact ? encodeURIComponent( JSON.parse( tact ).deviceToken ) : undefined;
        var xsct = tsct ? encodeURIComponent( JSON.parse( tsct ).xsct        ) : undefined;

        return this.sendForm( "deleteDevice", { xact:xact, xsct:xsct }, false );
    };

    /**
     * Call any arbitrary XTV API.
     * @memberOf XtvApi
     * @param   {String}    api           - The name of the API. Example: "getResumePoints"
     * @param   {Object}    params        - A JSON object containing parameters. Example: {maxDaysOld:30,someOtherParam:'abc'}
     * @param   {function}  [transform]   - A function to call to transform data before Promise resolves.
     * @param   {Boolean}   [bustCache]   - Force the query to bypass the local cache
     * @returns {Promise <Object,*>}  Promise resolves with results of transform or raw JSON structure from XTV API
     */
    XtvApi.prototype.get = function( api, params, transform, bustCache )
    {
        var self = this;
        var exec = function( api, params, transform )
        {
            if( self._root )
            {
                var node = self._root._links[api];

                if( node )
                {
                    var url = self._host + ( node.templated ? XtvApi.replaceUrlParams( node.href, params ) : node.href );

                    var resolver = function( resolve, reject )
                    {
                        var success, error;

                        success = function (response)
                        {
                            //transform if we have a function then resolve.
                            response.responseTimeXtvApi = Date.now() - self._responseTimeStart;
                            var transformed = transform ? transform( response ) : response.data;

                            if( _x2._splunk && api )
                                _x2._splunk.onXtvApi( true, api, response, params, transformed );

                            resolve( transformed );
                        };

                        error = function (error)
                        {
                            if( error.setApi )
                                error.setApi( api ); //record the api that triggered the failure

                            error.responseTimeXtvApi = Date.now() - self._responseTimeStart;

                            if( _x2._splunk && api )
                                _x2._splunk.onXtvApi( false, api, error, params, undefined );

                            reject( error );
                        };

                        _x2._network.ajax({type: "GET", url: url, headers:self.getAuthHeader(), accepts:Network.Accepts.xtv, bustCache:bustCache, cancelOnDemand: params.cancelOnDemand || false }).then(success).catch(error);
                    };

                    return new Promise( resolver );
                }
                else
                {
                    return Promise.reject( new ApiError().init( { local:"XtApi.get: There is no API matching '" + api + "'"} ) );
                }
            }
            else
            {
                return Promise.reject( new ApiError().init( { local:"XtvApi.get: XTV API was not initialized. Clear browser cache and retry"} ) );
            }
        };

        this._responseTimeStart = Date.now();

        if( this._root )
            return exec( api, params, transform );
        else // defer this call until root is loaded
        {
            return this._rootPromise.then( function()
            {
                return exec( api, params, transform );
            } );
        }
    };

    /**
     * Get the Authorization headers
     * @memberOf XtvApi
     * @return {Object[]}
     */
    XtvApi.prototype.getAuthHeader = function()
    {
        return ( this._authType === Config.AUTH_OAUTH ) ? [this._authHeader, this._deviceHeader] : [this._authHeader];
    };

    /**
     * Get a list of all deleted recordings
     * @memberOf XtvApi
     * @return {Promise <Recording[]>}
     */
    XtvApi.prototype.getDeletedRecordings = function()
    {
        var transform = function( response )
        {
            var i, recordings = [], data = JSON.parse( response.data );

            if( data._embedded && data._embedded.recordings )
            {
                for( i=0; i<data._embedded.recordings.length; i++ )
                    recordings.push( new Recording().init(data._embedded.recordings[i]));
            }

            return recordings;
        };

        return this.get("getDeletedRecordings", {}, transform )
    };

    /**
     * Get the TokenSummary object from XTVAPI
     * @memberOf XtvApi
     * @return {Promise <Object>}
     */
    XtvApi.prototype.getTokenSummary = function()
    {
        return this.get( "getTokenSummary", {}, function( response ) { return JSON.parse( response.data ); } );
    };

    /**
     * Get a ChannelCollection object initialized with all channels + a schedules block for the current time.
     * @memberOf XtvApi
     * @return {Promise <ChannelCollection>}
     */
    XtvApi.prototype.getChannelCollection = function(cancelOnDemand)
    {
        var p = [], self = this;
        cancelOnDemand  = cancelOnDemand || false;
        var resolver = function( resolve, reject )
        {
            var blockTime = ChannelCollection.getUniformBlockTime( _x2._config._host.getCurrentDateTime(true) );

            var success = function( response )
            {
                var cc = new ChannelCollection().init( response );
                resolve( cc );
                self._getCcPromise = undefined;
            };

            var error = function( error )
            {
                reject( error );
            };

            p.push(self.getChannels(cancelOnDemand));
            p.push(self.getScheduleBlock( blockTime, ChannelCollection.blockHours, cancelOnDemand ));
            Promise.all(p).then( success ).catch( error );
        };

        //handle simultaneous calls with one network call.
        if( this._getCcPromise === undefined )
            this._getCcPromise = new Promise( resolver );

        return this._getCcPromise;
    };

    /**
     * Get an array of all channels for current user
     * @memberOf XtvApi
     * @return {Promise <Channel[]>}
     */
    XtvApi.prototype.getChannels = function( cancelOnDemand )
    {
        function transform( response )
        {
            var i, objects, channel, hdChannelNumber, hdMap = [], sdChannels = [], channels = [];

            objects = JSON.parse( response.data )._embedded.channels;

            for( i = 0; i < objects.length; i++ )
            {
                channel = new Channel().init( objects[i] );

                if( channel.isHD() )
                    hdMap[channel.getNumber()] = channel;
                else
                    sdChannels.push( channel );

                channels.push( channel );
            }

            // try to find an HD complement for all SD channels
            for( i = 0; i < sdChannels.length; i++ )
            {
                hdChannelNumber = sdChannels[i].getAssocChannelNumber();

                if( hdChannelNumber !== undefined && hdMap[hdChannelNumber] )
                    sdChannels[i].setHdComplement( hdMap[hdChannelNumber] )
            }

            return channels;
        }

        return this.get( "getChannelMap", {cancelOnDemand: cancelOnDemand}, transform );
    };

    /**
     * Get the list of all devices associated with this account
     * @memberOf XtvApi
     * @return {Promise <Object[]>}
     */
    XtvApi.prototype.getAllDeviceList = function(cancelOnDemand)
    {
        var transform = function( response )
        {
            var list = [], obj = JSON.parse( response.data );

            if( obj && obj._embedded )
                list = obj._embedded.devices;

            return list;
        };

        return this.get( "getAllDevicesList", { cancelOnDemand: cancelOnDemand }, transform, true );
    };

    /**
     * Get a collection of favorite IDs (self links) by type
     * @memberOf XtvApi
     * @return {Promise <Favorites>}
     */
    XtvApi.prototype.getFavorites = function( cancelOnDemand )
    {
        var transform = function( response )
        {
            return new Favorites().init( JSON.parse( response.data ) );
        };

        return this.get( "getAllFavorites", { cancelOnDemand: cancelOnDemand || false}, transform, true );
    };

    /**
     * Get a collection of favorite entities TODO: why are we cache busting here?
     * @memberOf XtvApi
     * @return {Promise}
     */
    XtvApi.prototype.getFavoritesBrowse = function( cancelOnDemand )
    {
        cancelOnDemand = cancelOnDemand || false;
        var transform = function( response )
        {
            return new BrowseCollection().init( JSON.parse( response.data ) );
        };

        return this.get("browseFavorites", { depth:2, maxPrograms:12, cancelOnDemand: cancelOnDemand }, transform,true);
    };

    /**
     * Get the "featured" collection for "for you"
     * @memberOf XtvApi
     * @return {Promise} Resolves to BrowseCollection
     */
    XtvApi.prototype.getForYouFeaturedMenu = function( cancelOnDemand )
    {
        cancelOnDemand = cancelOnDemand || false;
        var transform = function( response )
        {
            var embedded, collection, data = JSON.parse( response.data );

            embedded = data._embedded.browseItems;

            if( embedded && embedded.length )
                collection = new BrowseCollection().init( embedded[0] );

            return collection;
        };

        return this.get("getForYouFeaturedMenu", { depth:2, maxPrograms:12, cancelOnDemand: cancelOnDemand }, transform );
    };

    /**
     * Get the "For You" root (Collection of Collections)
     * @memberOf XtvApi
     * @return {Promise} Resolves to BrowseCollection[]
     */
    XtvApi.prototype.getForYouMenu = function( cancelOnDemand )
    {
        cancelOnDemand = cancelOnDemand || false;
        var transform = function( response )
        {
            var i, embedded, items, data, root, collection = [];

            data = JSON.parse( response.data );
            root = new BrowseCollection().init( data );

            embedded = data._embedded;
            if( embedded )
            {
                items = embedded.browseItems;
                if( items )
                {
                    for( i=0; i<items.length; i++ )
                        collection.push( new BrowseCollection().init( items[i], root ) );
                }
            }
            return collection;
        };

        return this.get("getForYouMenu", { depth:2, maxPrograms:12, cancelOnDemand: cancelOnDemand }, transform );
    };

    /**
     * Get a collection by the node alias (deep link)
     * @memberOf XtvApi
     * @see https://etwiki.sys.comcast.net/display/AAE/Xfinity+Stream+App+Deeplinks+for+Collections+and+VOD+Playback
     * @param {String} alias
     * @param {String} [filters] - Comma separated list of filters. Full set from XTVAPI docs: [closedcaption,hd,downloadable,tve,sap]
     * @returns {Promise}
     */
    XtvApi.prototype.getNodeByAlias = function( alias, filters )
    {
        var params = {
            aliasName   : alias,
            depth       : 2,
            maxPrograms : 12,
            filters     : filters };

        var transform = function( response )
        {
            return new BrowseCollection().init( JSON.parse( response.data ) );
        };

        return this.get( "browseNodeByAlias", params, transform );
    };

    /**
     * Get the entity detail data for a program
     * @memberOf XtvApi
     * @param {String}   id         - Program ID
     * @param {Boolean} [tve]       - Include TVE showings in result
     * @param {Boolean} [tveLinear] - Include TVE linear showings in result
     * @returns {Promise}
     */
    XtvApi.prototype.getProgram = function( id, tve, tveLinear )
    {
        var params = {
            programId        : id,
            includeTve       : tve||false,
            includeTveLinear : tveLinear||false };

        var transform = function( response )
        {
            return new Entity().init( JSON.parse( response.data ) );
        };

        return this.get( "getProgramEntity", params, transform );
    };

    /**
     * Get the detail for a grid listing
     * @memberOf XtvApi
     * @param {LinearShowing} listing - Listing object to pull deatil for
     * @returns {Promise} Promise resolves with the same {@link LinearShowing} object pass as param, with detail added.
     *
     * TODO: shouldn't this be in LinearShowing?
     */
    XtvApi.prototype.getListingDetail = function ( listing )
    {
        var self = this;

        //Take a listing object, extract and call the embedded query, return promise and callback with detail
        var resolver = function (resolve, reject)
        {
            var success = function (response)
            {
                listing.init(JSON.parse(response.data));
                resolve( listing );
            };
            var error = function (error)
            {
                reject( error );
            };

            _x2._network.ajax({type: "GET", url: self._host + listing.getSelfLink(), api:"getTvListingDetail", headers: [self._authHeader], accepts:Network.Accepts.xtv}).then(success).catch(error);
        };

        return new Promise(resolver);
    };

    /**
     * Find recommendations by entity
     * @memberOf XtvApi
     * @param {String} entityId - The ID of the entity to find related content for.
     * @returns {Promise <SearchEntity[]>}
     */
    XtvApi.prototype.getMoreLikeThis = function( entityId )
    {
        return this.get( "recommendMoreLikeThis", { entityId: entityId },
            function( response )
            {
                var i, entities = [], data = JSON.parse( response.data )._embedded.results;

                for( i = 0; i < data.length; i++ )
                    entities.push( new SearchEntity().init(data[i]) );

                return entities;
            }
        );
    };

    /**
     * Get parental controls settings
     * @memberOf XtvApi
     * @returns {Promise <ParentalControls>}
     */
    XtvApi.prototype.getParentalControls = function(cancelOnDemand)
    {
        var transform = function( response )
        {
            return new ParentalControls( JSON.parse(response.data) );
        };

        return this.get( "getParentalControls2Settings", { cancelOnDemand: cancelOnDemand, cache: false }, transform );
    };

    /**
     * Get a list of purchases and rentals for For You.
     * @memberOf XtvApi
     * @returns {Promise <PurchaseCollection>}
     */
    XtvApi.prototype.getForYouPurchases = function( cancelOnDemand )
    {
        cancelOnDemand = cancelOnDemand || false;
        var transform = function( response )
        {
            var data = JSON.parse( response.data );
            var entities = [];

            if( data && data._embedded )
            {
                var programs = data._embedded.programs;
                if( programs && programs.length )
                {
                    for( var i=0; i<programs.length; i++ )
                        entities.push( new Entity().init( programs[i] ) )
                }
            }

            return new Collection().init( entities, "Recent Purchases" );
        };

        return this.get( "getForYouPurchases", { cancelOnDemand: cancelOnDemand }, transform );
    };


    /**
     * Get a list of purchases for this account
     * @memberOf XtvApi
     * @returns {Promise <PurchaseCollection>}
     */
    XtvApi.prototype.getPurchases = function( cancelOnDemand )
    {
        cancelOnDemand = cancelOnDemand || false;
        var transform = function( response )
        {
            return new PurchaseCollection().init( JSON.parse( response.data ), "Purchases" );
        };

        return this.get( "getPurchases", { cancelOnDemand: cancelOnDemand }, transform );
    };

    /**
     * Get a PurchasePin object
     * @memberOf XtvApi
     * @returns {Promise <PurchasePin>}
     */
    XtvApi.prototype.getPurchasePin = function()
    {
        var transform = function( response )
        {
            return new PurchasePin().init( JSON.parse( response.data ) );
        };

        return this.get( "getPurchasePinSettings", {}, transform );
    };

    /**
     * Get the result of the first 302 redirect associated with an xtvapi CDVR DASH url.
     * @memberOf XtvApi
     * @returns {Promise <Object>} Promise resolve with the url of the first redirect.
     */
    XtvApi.prototype.getRecordingRedirect = function( recordingId )
    {
        return this.get( "getStreamPlaybackUrl", { recordingId:recordingId }, function( response ) { return response.data; } );
    };

    /**
     * Get a list of rented VOD items.
     * @memberOf XtvApi
     * @return {Promise<Collection>}
     */
    XtvApi.prototype.getRentals = function( force , cancelOnDemand )
    {
        cancelOnDemand = cancelOnDemand || false;
        var transform = function( response )
        {
            var data = JSON.parse(response.data);
            var vodItems = ( data && data._embedded ) ? data._embedded.watchedVodList : undefined;
            var list = [];

            if( vodItems )
            {
                for( var i=0; i<vodItems.length; i++ )
                    list.push( new Rental().init( vodItems[i] ) )
            }

            return new Collection().init(list,"Rentals", XtvApi.EntityRenderStyle.POSTER);
        };

        return this.get( "getVodWatched", { cancelOnDemand: cancelOnDemand }, transform, force );
    };

    /**
     * Get a list of resume points for this account
     * @memberOf XtvApi
     * @param {Number} [maxDaysOld] - Max number of days old. Defaults to 60.
     * @returns {Promise <ResumePoint[]>}
     */
    XtvApi.prototype.getResumePoints = function( maxDaysOld , cancelOnDemand)
    {
        var transform = function( response )
        {
            var resumePoints = [];
            var obj = JSON.parse( response.data );

            if( obj && obj._embedded )
            {
                var groups = obj._embedded.resumePointGroups;
                if( groups )
                {
                    for( var i = 0; i < groups.length; i++ )
                    {
                        var rp = groups[i]._embedded.lastUpdatedResumePoint;
                        if( rp )
                            resumePoints.push(new ResumePoint().init( rp ));
                    }
                }
            }

            return resumePoints;
        };

        return this.get( "getResumePoints", { maxDaysOld: maxDaysOld || 60, cancelOnDemand: cancelOnDemand || false }, transform );
    };

    /**
     * Get a list of recordings for this account
     * @memberOf XtvApi
     * @returns {Promise <RecordingCollection>}
     *
     * TODO: only bust cache after setting a recording?
     */
    XtvApi.prototype.getRecordings = function( cancelOnDemand )
    {
        cancelOnDemand = cancelOnDemand || false;
        var transform = function( response )
        {
            return new RecordingCollection().init( JSON.parse( response.data ), "All Recordings" ); //TODO: not good for i18n - pass in the title? Or just put a setTitle on RecordingCollection
        };

        return this.get( "getRecordings", { cancelOnDemand : cancelOnDemand }, transform,true );
    };

    /**
     * Get a list of recent recordings. Max days is not configurable.
     * @memberOf XtvApi
     * @returns {Promise <RecordingCollection>}
     */
    XtvApi.prototype.getRecentRecordings = function()
    {
        var transform = function( response )
        {
            return new RecordingCollection().init( JSON.parse( response.data ), "Just Recorded", XtvApi.EntityRenderStyle.POSTER );
        };

        return this.get( "recentRecordings", {}, transform );
    };

    /**
     * Get a list of recently watched programs
     * @memberOf XtvApi
     * @returns {Promise <Collection>} Collection contains Recordings and/or VodShowings
     */
    XtvApi.prototype.getRecentlyWatched = function(limit, cancelOnDemand )
    {
        cancelOnDemand = cancelOnDemand || false;
        var transform = function( response )
        {
            var result   = JSON.parse( response.data );
            var entities = [];

            if( result && result._embedded )
            {
                var list = result._embedded.recentlyWatchedAssets;

                for( var i = 0; i < list.length; i++ )
                {
                    if( list[i]._type === "s:VideoObject/Recording" )
                        entities.push( new Recording().init( list[i] ) );
                    else
                    {
                        var type = ( ( list[i]._embedded && list[i]._embedded.transactionDetails ) ? list[i]._embedded.transactionDetails.type : "" );
                        switch( type )
                        {
                            case( TransactionDetails.Type.RENTAL ) :
                                entities.push( new Rental().init( list[i] ) );
                                break;
                            case( TransactionDetails.Type.PURCHASE ) :
                                entities.push( new Purchase().init( list[i] ) );
                                break;
                            default:
                                entities.push( new VodShowing().init( list[i] ) );
                                break;
                        }
                    }
                }
            }

            return new Collection().init(entities,"Recently Watched", XtvApi.EntityRenderStyle.POSTER);
        };

        return this.get( "getRecentlyWatched", {limit:limit || 12, cancelOnDemand: cancelOnDemand }, transform );
    };

    /**
     * Get Recorder summary data for this account (% free, etc)
     * @memberOf XtvApi
     * @return {Promise <RecorderSummary>}
     */
    XtvApi.prototype.getRecorderSummary = function( force )
    {
        var transform = function( response )
        {
            return new RecorderSummary().init( JSON.parse( response.data ) );
        };

        return this.get( "getRecorderSummary", {}, transform, !!force );
    };

    /**
     * Get a block of linear schedules.
     * @memberOf XtvApi
     * @param {Number} startTime - Block start time as epoch datetime stamp.
     * @param hours
     * @returns {Promise <Object>} Returns channel array + block id
     */
    XtvApi.prototype.getScheduleBlock = function( startTime, hours , cancelOnDemand)
    {
        var transform = function( response )
        {
            var channels = [];
            var data     = JSON.parse( response.data );
            if( data && data._embedded )
            {
                var channelData = data._embedded.channels;
                if( channelData && channelData.length )
                {
                    for( var i = 0; i < channelData.length; i++ )
                        channels.push( new Channel().init( channelData[i], response.path ) )
                }
            }
            return { data:channels, blockId:startTime };
        };

        return this.get( "getTvGridChunk", { startTime: startTime, hours: hours , cancelOnDemand: cancelOnDemand}, transform );
    };

    /**
     * Get the linear showing for the given channel and time
     * @memberOf XtvApi
     * @param {string} channelId
     * @param {number} time
     * @returns {Promise <LinearShowing>}
     *
     * TODO: why not use the transform pattern here?
     */
    XtvApi.prototype.getLinearScheduleByTime = function( channelId, time )
    {
        var self = this;

        var resolver = function( resolve, reject )
        {
            var success = function( response )
            {
                var data = JSON.parse( response );
                if( data && data._embedded )
                {
                    var channelData = data._embedded.channels[0];
                    if( channelData )
                    {
                        var listings = channelData._embedded.listings;
                        if( listings && listings.length )
                        {
                            for( var i=0; i<listings.length; i++ )
                            {
                                if( listings[i].startTime <= time && listings[i].endTime > time )
                                {
                                    new LinearShowing().init( listings[i] ).getDetail().then( resolve ).catch( reject );
                                    break;
                                }
                            }
                        }
                    }
                }
            };

            var error = function( error )
            {
                reject( error );
            };

            self.get( "getTvGridChunk", { startTime: ChannelCollection.getUniformBlockTime(time), hours: 4, channelIds:channelId } ).then( success ).catch( error );
        };

        return new Promise( resolver );
    };

    /**
     * Get a household's scheduled recordings
     * @memberOf XtvApi
     * @returns {Promise <Recording[]>}
     */
    XtvApi.prototype.getScheduledRecordings = function( cancelOnDemand )
    {
        cancelOnDemand = cancelOnDemand || false;
        var transform = function( response )
        {
            var i, s, sid, entity, recording;

            var data       = JSON.parse( response.data );
            var recordings = data._embedded.recordings;
            var series     = data._embedded.entities;
            var recArray   = [];
            var seriesMap  = {};

            if( series.length )
            {
                for( i=0; i<series.length; i++ )
                {
                    s = new Entity().init(series[i]);
                    sid = s.getEntityId();
                    seriesMap[sid] = s;
                }
            }

            if( recordings.length )
            {
                for( i = 0; i < recordings.length; i++ )
                {
                    recording = new Recording().init( recordings[i] );
                    entity    = recording.getEntity();
                    sid       = recording.getSeriesId();

                    if( entity && sid && seriesMap[sid] )
                        entity.setSeries( seriesMap[sid] );

                    recArray.push( recording );
                }
            }

            return recArray;
        };

        return this.get( "getScheduledRecordingsWithEntities", { cancelOnDemand: cancelOnDemand }, transform );
    };

    /**
     * Get the global scheduled recordings data. NOTE: only available after asyc call to fetchRecordingMetadata completes.
     * @memberOf XtvApi
     * @return {ScheduledRecordings}
     */
    XtvApi.prototype.getScheduledRecordingMetadata = function()
    {
        return this._scheduledRecordings;
    };

    /**
     * Get the contents of a VOD folder with variable depth and number of programs.
     * @memberOf XtvApi
     * @param {BrowseCollection} folder       - The root folder for this query
     * @param {Number}          [depth]       - Number of child folder levels to retrieve. Defaults to 2.
     * @param {Number}          [maxPrograms] - Number of entities to retrieve for each folder. Defaults to 10.
     * @param {String}          [filters]     - Comma separated list of filters. Full set from XTVAPI docs: [closedcaption,hd,downloadable,tve,sap]
     * @param {Boolean}         [bustCache]   - Force new (non-cached) query
     * @returns {Promise} Promise resolves with the same {@BrowseCollection} with folder data populated.
     */
    XtvApi.prototype.getVodFolder = function( folder, depth, maxPrograms, filters, bustCache , cancelOnDemand)
    {
        cancelOnDemand = cancelOnDemand || false;

        var self = this;

        var resolver = function( resolve, reject )
        {
            var params = {};

            var success = function( response )
            {
                resolve( folder.init( JSON.parse( response.data ) ) );
            };

            var error = function( error )
            {
                reject( error );
            };

            params.depth       = depth || 2;
            params.maxPrograms = maxPrograms || 10;
            params.perPage     = maxPrograms || 50;
            params.filters     = filters;
            params.freetome    = ( _x2._config._purchases && _x2._features.hasEntitlement( Features.Entitlement.EST ) ) ? "off" : "on";

            var url = XtvApi.replaceUrlParams( folder._data._links.browse.href, params );
            url = self._host + XtvApi.trimPath( url );

            if( bustCache )
                url += "&time=" + Date.now();

            _x2._network.ajax( { type: "GET", url: url, api:"browseNode", headers: [self._authHeader], accepts:Network.Accepts.xtv, cancelOnDemand: cancelOnDemand } ).then( success ).catch( error );
        };

        return new Promise( resolver );
    };

    /**
     * Get a SportsTeam entity
     * @memberOf XtvApi
     * @param teamId
     * @return {Promise}
     */
    XtvApi.prototype.getSportsTeamEntity = function( teamId )
    {
        return this.get( "getSportsTeamEntity", { teamId:teamId } )
    };

    /**
     * Get the root VOD folder
     * @memberOf XtvApi
     * @param {Number} [depth]       - Number of child folder levels to retrieve. Defaults to 2.
     * @param {Number} [maxPrograms] - Number of entities to retrieve for each folder. Defaults to 10.
     * @param {String} [filters]     - Comma separated list of filters. Full set from XTVAPI docs: [closedcaption,hd,downloadable,tve,sap]
     * @returns {Promise} Promise resolves with a {@link BrowseCollection} object
     */
    XtvApi.prototype.getVodRoot = function( depth, maxPrograms, filters , cancelOnDemand)
    {
        var params         = {};
        params.depth       = depth || 2;
        params.maxPrograms = ( isNaN( maxPrograms ) ? 7 : maxPrograms );
        params.filters     = filters;
        params.cancelOnDemand = cancelOnDemand || false;
        if( _x2._config._previewRoot === true )
            params.aliasName = "xtv-preview-root";

        var transform = function( response )
        {
            return new BrowseCollection().init( JSON.parse( response.data ) );
        };

        return this.get( "getBrowseRoot", params, transform );
    };

    /**
     * Remove a channel from favorites.
     * @memberOf XtvApi
     * @param selfLink
     * @return {Promise}
     */
    XtvApi.prototype.removeFavoriteChannel = function( selfLink )
    {
        return this.sendForm( "deleteFavoriteItems", { itemSelfLinks: '["' + selfLink + '"]' }, true );
    };

    /**
     * Remove any a program or sports team from favorites.
     * @memberOf XtvApi
     * @param selfLink
     * @return {Promise}
     */
    XtvApi.prototype.removeFavoriteItem = function( selfLink )
    {
        return this.sendForm( "deleteFavoriteItems", { itemSelfLinks: '["' + selfLink + '"]' }, true );
    };

    /**
     * Load recording data
     * @memberOf XtvApi
     * @return {Promise}
     */
    XtvApi.prototype.fetchScheduledRecordingMetadata = function( force )
    {
        var self = this;

        var resolver = function( resolve, reject )
        {
            var success = function( result )
            {
                self._scheduledRecordings = new ScheduledRecordings().init( JSON.parse( result ) );
                resolve();
            };

            self.get( "getScheduledRecordingCandidates", {}, undefined, force ).then( success ).catch( reject );
        };

        return new Promise( resolver );
    };

    /**
     * Search by term
     * @memberOf XtvApi
     * @param {String} term - The character or string to search for
     * @param {Number} limit - The number of programs to return
     * @returns {Promise <SearchEntity[]>}
     */
    XtvApi.prototype.search = function( term, limit )
    {
        var transform = function( response )
        {
            var searchEntities = [];
            var resultObjects  = JSON.parse( response.data )._embedded.results;

            for( var i = 0; i < resultObjects.length; i++ )
                searchEntities.push( new SearchEntity().init( resultObjects[i] ) );

            return searchEntities;
        };

        var params = {};
        params.query    = encodeURIComponent( term );
        params.limit    = limit;
        params.freetome = ( _x2._config._purchases && _x2._features.hasEntitlement( Features.Entitlement.EST ) ) ? "off" : "on";

        return this.get( "searchByTerm", params, transform );
    };

    /**
     * Set the name of the device;
     * @memberOf XtvApi
     * @param {String} name
     */
    XtvApi.prototype.setDeviceName = function( name )
    {
        return this.sendForm( "setDeviceName", { deviceName:name }, true );
    };

    /**
     * Set Parental Controls data
     * Use setParentalControlsSettings() or setParentalControlsRatings()
     * @memberOf XtvApi
     * @private
     * @param {ParentalControls} pcObject - Object wrapping PCs.
     * @param {String}           action   - The HAL link name
     * @returns {Promise}
     */
    XtvApi.prototype.setParentalControls = function( pcObject, action )
    {
        var node   = this._root._forms[action];
        var url    = this._host + node.action;
        var method = node.method;
        var data   = JSON.stringify(pcObject._data);
        var cType  = { name:'Content-Type', value:'application/json' };

        //remove the 'links' object from the settings before posting it back.
        var settings = JSON.parse(data);
        delete settings._links;
        data = JSON.stringify(settings);

        return _x2._network.ajax( { type:method, url:url, api:action, data:data, headers: [this._authHeader, cType] } );
    };

    /**
     * Set the parental controls ratings
     * @memberOf XtvApi
     * @param {ParentalControls} pcObject - Object wrapping PCs.
     * @returns {Promise} Success or fail callback only; no data.
     */
    XtvApi.prototype.setParentalControlsRatings = function( pcObject )
    {
        return this.setParentalControls( pcObject, "updateParentalControls2Ratings" );
    };

    /**
     * Set the parental controls settings (everything other than ratings)
     * @memberOf XtvApi
     * @param {ParentalControls} pcObject - Object wrapping PCs.
     * @returns {Promise} Success or fail callback only; no data.
     */
    XtvApi.prototype.setParentalControlsSettings = function( pcObject )
    {
        return this.setParentalControls( pcObject, "updateParentalControls2Settings" );
    };

    /**
     * Set a resume point
     * @memberOf XtvApi
     * @param {string} mediaId   - example: "mediaId:comcast:dvr:schedule:V4121208831986220730L200118670205123018"
     * @param {number} programId - example: 5696787302847616112
     * @param {number} progress  - elapsed time in milliseconds
     * @return {Promise}
     */
    XtvApi.prototype.setResumePoint = function ( mediaId, programId, progress )
    {
        return this.sendForm( "updateResumePoint", { mediaId:mediaId, programId:programId, progress:parseInt(progress) }, true );
    };

    /**
     * Submit a XTV API form, sending params as data key-value pairs.
     * @memberOf XtvApi
     * @param  {string}  api    - the name of the form (matches _root._forms[api])
     * @param  {Object}  params - A JSON object containing parameters. Example: {maxDaysOld:30,someOtherParam:'abc'}
     * @param  {boolean} auth   - Send the auth header
     * @return {Promise}
     */
    XtvApi.prototype.sendForm = function(api, params, auth)
    {
        var exec, self = this;

        exec = function( api, params )
        {
            var node, obj = {};

            if( self._root )
            {
                node = self._root._forms[api];

                if( node )
                {
                    var url, method, param, data = "";

                    url    = self._host + node.action;
                    method = node.method;

                    if( params )
                    {
                        for( param in params )
                        {
                            if( params.hasOwnProperty( param ) )
                            {
                                if( data.length > 0 )
                                    data += '&';

                                data += param + "=" + params[param];
                            }
                        }
                    }

                    obj.type = method;
                    obj.url  = url;
                    obj.data = data;
                    obj.contentType = 'application/x-www-form-urlencoded';

                    if( auth )
                        obj.headers = self.getAuthHeader();

                    //intercept the error so that on failure we can set the triggering API call.
                    var resolver = function( resolve, reject )
                    {
                        var error = function( error )
                        {
                            error.setApi( api );
                            reject( error );
                        };

                        _x2._network.ajax( obj ).then( resolve ).catch( error );
                    };

                    return new Promise( resolver );
                }
                else
                    return Promise.reject( new ApiError().init( { local:"XtvApi.sendForm: There is no API matching '" + api + "'"} ) );
            }
        };

        if( this._root )
            return exec( api, params );
        else // defer this call until root is loaded
            return this._rootPromise.then( function() { return exec( api, params ); } );
    };

    /**
     * Send recording playback heartbeat message
     * @memberOf XtvApi
     * @param recordingId
     */
    XtvApi.prototype.playbackRecordingHeartbeat = function( recordingId )
    {
        this.sendForm( "recordingHeartbeat", { recordingId: recordingId }, true ).catch( function( error ){ console.log( error ) } );
    };

    /**
     * Send recording playback stop message
     * @memberOf XtvApi
     * @param recordingId
     */
    XtvApi.prototype.playbackRecordingStopWatching = function( recordingId )
    {
        this.sendForm( "stopWatchingRecording", { recordingId: recordingId }, true ).catch( function( error ){ console.log( error ) } );
    };

    /**
     * Send recording playback finish messsage
     * @memberOf XtvApi
     * @param recordingId
     */
    XtvApi.prototype.playbackRecordingFinishWatching = function( recordingId )
    {
        this.sendForm( "finishWatchingRecording", { recordingId: recordingId }, true ).catch( function( error ){ console.log( error ) } );
    };

    /**
     * Send VOD playback heartbeat message
     * @memberOf XtvApi
     * @param paid
     */
    XtvApi.prototype.playbackVodHeartbeat = function( paid )
    {
        this.sendForm( "vodStreamHeartbeat", { paid: paid }, true ).catch( function( error ){ console.log( error ) } );
    };

    /**
     * Send VOD playback stop message
     * @memberOf XtvApi
     * @param paid
     */
    XtvApi.prototype.playbackVodStopWatching = function( paid )
    {
        this.sendForm( "stopWatchingVod", { paid: paid }, true ).catch( function( error ){ console.log( error ) } );
    };

    /**
     * Send VOD playback finish message
     * @memberOf XtvApi
     * @param paid
     */
    XtvApi.prototype.playbackVodFinishWatching = function( paid )
    {
        this.sendForm( "finishWatchingVod", { paid: paid }, true ).catch( function( error ){ console.log( error ) } );
    };

    /**
     * Send Linear playback heartbeat message
     * @memberOf XtvApi
     * @param streamId
     */
    XtvApi.prototype.playbackLinearHeartbeat = function( streamId )
    {
        this.sendForm( "linearStreamHeartbeat", { streamId: streamId }, true ).catch( function( error ){ console.log( error ) } );
    };

    /**
     * Send linear playback stop message
     * @memberOf XtvApi
     * @param streamId
     */
    XtvApi.prototype.playbackLinearStopWatching = function( streamId )
    {
        this.sendForm( "stopWatchingLinearStream", { streamId: streamId }, true ).catch( function( error ){ console.log( error ) } );
    };

    /**
     * Provision Partner Device
     * @memberOf XtvApi
     * @param xact
     * @param saml
     * @param partnerId
     * @return {Promise<any>}
     */
    XtvApi.prototype.provisionPartnerDevice = function( xact, saml, partnerId )
    {
        return this.sendForm( "provisionPartnerDevice", { xact:encodeURIComponent( xact ), saml:saml, partnerId:partnerId }, false );
    };

    /**
     * Provision partner device and create session
     * @param xact
     * @param saml
     * @param partnerId
     * @param hardAcquisition
     * @return {Promise}
     */
    XtvApi.prototype.provisionPartnerDeviceAndCreateSession = function( xact, saml, partnerId, hardAcquisition )
    {
        return this.sendForm( "provisionAndCreateSession", { xact:encodeURIComponent( xact ), saml:saml, partnerId:partnerId, hardAcquisition:hardAcquisition }, false );
    };
    
    /**
     * Cancel all pending requests, which are block to load the current tab
     * 
     */

    XtvApi.prototype.cancelAllPendingRequests = function() 
    {
        _x2._network._pendingRequests.forEach( function(pendingRequest) {
            if( pendingRequest.status != 200 ) {
                pendingRequest.abort();
            }
        });
        _x2._network._pendingRequests = [];
    }

    return XtvApi;

})();

/**
 * Replaces values in url with corresponding property values from params. For example,
 * given url:
 * ```
 * "browse/node/{nodeId}/{menuId}/{?page,perPage,sort,filters,embedPlayNowDetail}"
 * ```
 * and params:
 * ```
 * { nodeId:123456, menuId:7891011, page:1, perPage:10, sort:name }
 * ```
 * this function will return:
 * ```
 * "browse/node/123456/7891011/?page=1&perPage=10&sort=name"
 * ```
 * Note that any url parameters without a corresponding param values will be dropped from
 * the url.
 * @global
 * @param url
 * @param params
 * @returns {string}
 */
XtvApi.replaceUrlParams = function( url, params )
{
    var tokens, start, props, prop, retval = '';

    if( typeof url === 'string' || url instanceof String )
    {
        tokens = url.split( /[\{\}]+/ ); //split the string on '{' and '}'

        if( tokens && tokens.length )
        {
            retval += tokens[0];

            for( var i = 1; i < tokens.length; i++ )
            {
                if( tokens[i].charAt( 0 ) === '?' || tokens[i].charAt( 0 ) === '&' ) // begins the query portion of url
                {
                    start = tokens[i].charAt( 0 ) === '?'; // take note if this is the beginning of the query
                    props = tokens[i].replace( /[\?\&]/, '' ).split( ',' );

                    if( props && props.length )
                    {
                        for( var j = 0; j < props.length; j++ )
                        {
                            prop = undefined;

                            if( params[props[j]] !== undefined ) //we were passed a value for this property
                                prop = props[j] + '=' + params[props[j]];
                            else if( props[j].indexOf('=') > 0 ) //this is a self-contained (key-value pair) url parameter
                                prop = props[j];

                            if( prop )
                            {
                                retval += ( start ? '?' : '&' ) + prop;
                                start = false;
                            }
                        }
                    }
                }
                else if( tokens[i].charAt( 0 ) === '/' )
                    retval += tokens[i];
                else if( params[tokens[i]] !== undefined )
                    retval += params[tokens[i]];
            }
        }
    }
    else
        console.log( "ERROR -> invalid parameter (url) passed to XtvApi.replaceUrlParams. Expecting String" );

    return retval;
}

/**
 * Trim all '../' paths from url.
 * @param {string} url
 * @return {string}
 */
XtvApi.trimPath = function( url )
{
    if( url )
        return url.replace( /(\.\.\/)/g, '' );
}


