// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.XtvAuth = (function()
{
    "use strict";

    function XtvAuth( onHasSession, onNeedsCredentials )
    {
        _x2._config.debugAddClass( Config.CLASS_XTV_AUTH, 1 );

        var tokenAuth = Host.getLocalStorage( "XtvAuthToken" );
        var tokenXsct = Host.getLocalStorage( "XsctToken"    );

        _x2._config.log( Config.CLASS_XTV_AUTH, 9 ) ?  console.log( tokenAuth ) : Config.NOP();

        if( !tokenAuth || !tokenXsct )
            this.getAuthCode( onNeedsCredentials );
        else
            onHasSession();
    }

    XtvAuth.prototype.getAuthCode = function( onNeedsCredentials )
    {
        var params  = {}, success, error, self = this;

        params.type = "GET";
if( _x2._config._partner === "Rogers" )
    params.url  = _x2._config._xtvAuthRoot + "activationCode/?partner=Comcast";
else
        params.url  = _x2._config._xtvAuthRoot + "activationCode/?partner=" + _x2._config._partner;

        error = function( e )
        {
            console.log( e.toString() );
        };

        success = function( response )
        {
            console.log( ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SUCCESS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<" );
            console.log( response.data );

            var parsed = JSON.parse( response.data );
            var obj    = {};

            _x2._config.log( Config.CLASS_XTV_AUTH, 5 ) ?  console.log( parsed ) : Config.NOP();

            self._interval = parsed.pollInSeconds;
            self._pollUrl  = _x2._config._xtvAuthRoot + parsed._links.poll.href.slice(3);

            _x2._config.log( Config.CLASS_XTV_AUTH, 1 ) ?  console.log( "USER URL  = " + parsed.activationUrl  ) : Config.NOP();
            _x2._config.log( Config.CLASS_XTV_AUTH, 1 ) ?  console.log( "USER CODE = " + parsed.activationCode ) : Config.NOP();

            obj.url            = parsed.activationUrl;
            obj.code           = parsed.activationCode;
            obj.pollFunc       = function( onSuccess, onError ) { self.pollForToken( onSuccess, onError ); };
            obj.getNewCodeFunc = function( func ) { self.getNewCode( func ); };

            setTimeout( function() { onNeedsCredentials( obj ); }, 1 );
        };

        _x2._network.ajax( params ).then( success ).catch( error );
    };

    XtvAuth.prototype.logout = function()
    {
        _x2._data.deleteDevice();
        _x2._config._host.resetLocalStorage();
        _x2._config._host.setLocalStorage   ( "logoutTimestamp", Date.now() );
        _x2._config._token = undefined;
    };

    XtvAuth.prototype.pollForToken = function( onSuccess, onError )
    {
        var params = {}, error, success, self = this, status, response;

        params.type = "GET";
        params.url  = this._pollUrl;

        error = function( e )
        {
            status = e.getHttpStatus();

            switch( status )
            {
                case 404:
                    response = JSON.parse( e.getHttpResponse() );
                    self._interval = response.pollInSeconds;
                    self._delay = setTimeout( function() { _x2._network.ajax( params ).then( success ).catch( error ); }, self._interval * 1000 );
                    break;

                case 500:
                    if( onError )
                        onError();
                    break;

                default:
                    console.log( "ERROR -> unknown response to XtvAuth poll = " + status );
                    console.log( e );
                    break;
            }
        };

        success = function( response )
        {
            _x2._config.log( Config.CLASS_XTV_AUTH, 9 ) ?  console.log( response      ) : Config.NOP();
            _x2._config.log( Config.CLASS_XTV_AUTH, 9 ) ?  console.log( response.data ) : Config.NOP();

            var parsed = JSON.parse( response.data );

            _x2._config.log( Config.CLASS_XTV_AUTH, 9 ) ?  console.log( parsed ) : Config.NOP();

            self._delay = undefined;
            _x2._config._host.setLocalStorage( "XtvAuthToken", response.data );

            if( onSuccess )
                onSuccess();
        };

        self._delay = setTimeout( function() { _x2._network.ajax( params ).then( success ).catch( error ); }, self._interval * 1000 );
    };

    XtvAuth.prototype.getNewCode = function( onNeedsCredentials )
    {
        if( this._delay )
        {
            clearTimeout( this._delay );
            this._delay = undefined;
        }

        this.getAuthCode( onNeedsCredentials );
    };

    return XtvAuth;

})();
