// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.SubscribeOverlay = (function()
{
    "use strict";

    SubscribeOverlay.prototype = new Overlay();

    function SubscribeOverlay(){}

    /**
     * Init overlay
     * @param {TransactionOffer} offer
     * @return {SubscribeOverlay}
     */
    SubscribeOverlay.prototype.init = function( offer )
    {
        Overlay.prototype.init.call( this );
        this._className  = "SubscribeOverlay";

        var self = this;

        var close = function()
        {
            _x2.popOverlay( self );
        };

        var xCoords = [ _x2.scaleValInt(80), _x2.scaleValInt(433) ];
        var yCoords = [ _x2.scaleValInt(80), _x2.scaleValInt(126), _x2.scaleValInt(196), _x2.scaleValInt(400), _x2.scaleValInt(630) ];

        var infoStyle    = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
        var titleStyle   = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
        var messageStyle = new Style( { font:"light" , color:"#a3a3a3", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
        var footerStyle  = new Style( { font:"medium", color:"#bababa", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis" } );
        var buttonStyle  = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000"  } );

        var imgW = _x2.scaleValInt(303);
        var imgH = _x2.scaleValInt(404);

        var textBlockW = this._bg.getW() - _x2.scaleValInt(513);
        var textBlockH = yCoords[3] - yCoords[2];

        var alphas   = [ { opacity:100    , location:0 }, { opacity:100    , location:100 } ];
        var colors   = [ { color:"#1C262F", location:0 }, { color:"#130917", location:100 } ];
        var imageBg  = new GradientWidget().init( { alphas:alphas, angle:-135, colors:colors, w:imgW, h:imgH, type:GradientWidget.Type.Linear } );
        var imageUrl = offer.getContentProvider().getLogoUrl( 303, 404 );
        var subUrl   = ( _x2._features.getUserType() === "stream" ? "xfin.tv/add" : "xfin.tv/subscribe" );

        var imageLoaded = function()
        {
            var maxW   = Math.round(imgH * 0.5);
            var maxH   = Math.round(imgW * 0.5);
            var aspect = maxW / maxH;

            if( aspect > self._image.getW() / self._image.getH() )
                self._image.setH( maxH );
            else
                self._image.setW( maxW );

            self._image.setX( xCoords[0] + (imgW - self._image.getW()) / 2 );
            self._image.setY( yCoords[0] + (imgH - self._image.getH()) / 2 );
            self._image.setA( 1 );
        };

        this._info    = new StringWidget().init( { style:infoStyle   , text:"This Requires an Upgrade" } );
        this._title   = new StringWidget().init( { style:titleStyle  , text:offer.getTitle() } );
        this._footer  = new StringWidget().init( { style:footerStyle , text: Strings.Glyphs.LOCKED + " Purchase PIN can be added in Settings" } );
        this._message = new TextBlockWidget().init( { style:messageStyle, w:textBlockW, h:textBlockH, text:"To watch this channel you'll need to upgrade your channel package. Go to <font color='#f3f3f3'><b>" + subUrl + "</b></font> to select what channels you'd like to add to your lineup." } );
        this._image   = new ImageWidget().init( { url:imageUrl, onLoad:imageLoaded } );

        this._image.setA(0);

        var button  = new StringWidget().init( { text:"Back", style:buttonStyle, onEnter:close } );
        this._menu  = new HorizPillButtonsWidget().init( { entries:[button], spacing:_x2.scaleValInt(41) } );

        this._bg.addWidget( imageBg       , xCoords[0], yCoords[0] );
        this._bg.addWidget( this._image   , xCoords[0], yCoords[0] );
        this._bg.addWidget( this._info    , xCoords[1], yCoords[0] );
        this._bg.addWidget( this._title   , xCoords[1], yCoords[1] );
        this._bg.addWidget( this._message , xCoords[1], yCoords[2] );
        this._bg.addWidget( this._menu    , xCoords[1], yCoords[3] );
        this._bg.addWidget( this._footer  , xCoords[0], yCoords[4] );

        return this;
    };

    SubscribeOverlay.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();
        this.speak( _x2.requestFocus( this._menu ) );
    };

    SubscribeOverlay.prototype.speak = function( focus )
    {
        this._speakTarget.setText( this._info.getText() + ". " + this._title.getText() + ". " + this._message.getText().replace( /xfinity/gi, "x finity" ) + this._footer.getText().replace( Strings.Glyphs.LOCKED, "" ) + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press OK to dismiss. ", focus._speakRole, this, false );
    };

    return SubscribeOverlay;

})();
