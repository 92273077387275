// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * A EntityRecording is synonymous with a "Series Recording". It represents a recurring recording.
 * This can be a series recording, or a recording associated with some other entity such as sports
 * team or personality.
 *
 * Maps to XTV type: s:VideoObject/Recording
 *
 * This XTV data structure is embedded within XTV type "TVListing" (LinearShowing) or "CreativeWorkEntity" (ProgramEntity)
 */
window.EntityRecording = (function()
{
    "use strict";

    EntityRecording.prototype = new DataObject();

    function EntityRecording(){}

    /**
     * Initialize this object with data
     * @memberOf EntityRecording
     * @param {Object} data - XTV derived JSON object
     */
    EntityRecording.prototype.init = function( data )
    {
        DataObject.prototype.init.call( this, data );
        return this;
    };

    EntityRecording.prototype.getDurationMs = function()
    {
        var ms = 0, duration = this.getInteger("duration");

        if( duration )
            ms = parseInt( duration );

        return ms;
    };

    EntityRecording.prototype.getModifyForm = function()
    {
        if( ! this._modifyForm )
            this._modifyForm = this.getForm("modify");

        return this._modifyForm;
    };

    EntityRecording.prototype.getRecordedOnDate = function()
    {
        return this.getDate("dateRecorded");
    };

    EntityRecording.prototype.getRecordingStatus = function()
    {
        return this.getByPriority("status", "recordingStatus");
    };

    // EntityRecording.prototype.getRecordingType = function()
    // {
    //     return this._data.recordingType;
    // };

    EntityRecording.prototype.getScheduleForm = function()
    {
        if( ! this._scheduleForm )
            this._scheduleForm = this.getForm("schedule");

        return this._scheduleForm;
    };

    EntityRecording.prototype.getCancelForm = function()
    {
        //look first for a 'cancel' form then 'delete' form.
        if( ! this._cancelForm )
            this._cancelForm = this.getForm("cancel");

        if( ! this._cancelForm )
            this._cancelForm = this.getForm("delete");

        return this._cancelForm;
    };

    EntityRecording.prototype.isScheduled = function()
    {
        var scheduled = false, status = this.get("status") || this.get("recordingStatus");

        if( status === 'scheduled' || status === 'STARTED' || status === 'NOTSTARTED' )
            scheduled = true;

        return scheduled;
    };

    return EntityRecording

})();
