// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.StackScreen = ( function()
{
    "use strict";

    StackScreen.prototype = new Screen();
    StackScreen.ANI_TIME  = 500;

    var keyEater;

    function StackScreen(){}

    StackScreen.prototype.clear = function()
    {
        var start = 0;

        if( this._bg )
        {
            this._bg.stopAnimation( Widget.ALPHA, false );
            this._bg.animate( { a:0, duration:StackScreen.ANI_TIME } );
            start = 1;
        }

        for( var i = start; i < this._children.length; i++ )
        {
            this._div.removeChild( this._children[i]._div );
            this._children[i].destroy();
        }

        this._children.splice( start );
    };

    StackScreen.prototype.getTop = function()
    {
        return this._children[this._children.length - 1];
    };

    /**
     * @param {Object}   params
     * @param {Boolean} [params.hasBg] - has background rectangle
     * @param {String}  [params.name ] - name to appear in DOM
     */
    StackScreen.prototype.init = function( params )
    {
        Screen.prototype.init.call( this, { noBg:true, noHeader:true, noSep:true } );
        this._className = (params && params.name) ? params.name : "StackScreen";

        if( params.hasBg === true )
        {
            this._bg = new RectWidget().init( { name: "Dimming", w:_x2._config._screenW, h:_x2._config._screenH, color:"#303030" } );
            this._bg.setVal( Widget.ALPHA, 0 );
            this.addWidget( this._bg );
        }

        this.enableMouseDownListener( false );

        if( keyEater === undefined )
            keyEater = new KeyEater().init();

        return this;
    };

    StackScreen.prototype.pop = function( screen, onPopped )
    {
        var retval = false, currScreen, nextScreen, screenCount, self = this, lastFocus;
        var onEnd  = function()
        {
            lastFocus = nextScreen._lastFocus;

            if( lastFocus )
            {
                if( lastFocus instanceof KeyEater )
                {
                    var focus = lastFocus.getFocus();

                    if( focus )
                        lastFocus = focus;
                }

                _x2.requestFocus( lastFocus );
            }
            else
                _x2.requestFocus( nextScreen );
        };

        if( screen === undefined )
            screen = this._children[this._children.length - 1];

        screen._hasBeenPopped = true;
        screenCount = this._children.length;

        for( var i = 0; i < screenCount; i++ )
        {
            if( screen === this._children[i] )
            {
                currScreen = this._children[i];

                if( i < screenCount - 1 )  // not on top
                {
                    currScreen.forceUpdate( true );
                    currScreen.animate( { a:0, duration:StackScreen.ANI_TIME, onEnd:function() { self.removeWidget( currScreen ); if( onPopped ) onPopped(); } } );
                }
                else // on top
                {
                    if( screenCount > 1 )
                    {
                        var duration = currScreen instanceof VideoScreen ? 10 : StackScreen.ANI_TIME;

                        retval     = true;
                        currScreen = this._children[screenCount - 1];
                        nextScreen = this._children[screenCount - 2];

                        // in a push/pop scenario the nextScreen may be fading out, but not yet removed from _children. in that case try to go back one screen.
                        if( nextScreen._hasBeenPopped === true )
                        {
                            if( screenCount > 2 )
                            {
                                nextScreen = this._children[screenCount - 3];
                                screenCount--; //decrement the screen count to ensure the _bg will fade out and onEnd will fire (below) when screenCount==2.
                            }
                        }

                        currScreen.animate( { a:0, duration:duration, onEnd:function() { self.removeWidget( currScreen ); if( onPopped ) onPopped(); } } );

                        _x2.requestFocus( keyEater );

                        if( nextScreen !== this._bg )
                        {
                            if( nextScreen.refreshCheck )
                                nextScreen.refreshCheck();

                            nextScreen.animate( { a:1, duration:duration, onEnd:onEnd } );

                            if( _x2._telemetry && nextScreen instanceof Screen )
                                _x2._telemetry.sendActiveSession( nextScreen );
                        }
                    }
                }

                if( this._bg && screenCount === 2 )
                    this._bg.animate( { a:0, duration:StackScreen.ANI_TIME, easing:Widget.Easing.QUAD_IN, onEnd:onEnd } );

                break;
            }
        }

        return retval;
    };

    StackScreen.prototype.push = function( screen, duration )
    {
        var currentScreen = this._children[this._children.length - 1];

        duration = ( duration === undefined ) ? StackScreen.ANI_TIME : duration;

        if( this._bg )// && this._children.length === 1 )
        {
            this._bg.stopAnimation( Widget.ALPHA, false );
            this._bg.animate( { a:0.8, duration:StackScreen.ANI_TIME } );
        }

        currentScreen._lastFocus = _x2._focus;

        screen.setA( 0 );
        this.addWidget( screen );
        screen.resourcesLoad();
        screen.getVal( Widget.W );  // trigger reflow in DOM
        screen.resourcesLoaded();
        screen.animate( { a:1, duration:duration, onEnd:function() { if( screen.pushComplete ) screen.pushComplete() } } );

        if( currentScreen !== this._bg )
        {
            currentScreen.stopAnimation( Widget.ALPHA, true );
            currentScreen.animate( { a:0, duration:duration } );
        }

        _x2.requestFocus( screen );

        if( screen instanceof Screen )
        {
            if( _x2._telemetry )
                _x2._telemetry.sendActiveSession( screen );

            if( window.ll && screen._telemetryName )
                window.ll( "tagEvent", "Screen Viewed", { "Screen": screen._telemetryName } ); // Localytics
        }
    };

    StackScreen.prototype.set = function( screen )
    {
        var remove, toRemove, self = this;

        remove = function()
        {
            if( _x2._config._render === Config.RENDER_DOM )
            {
                if( self._div )
                    for( var i = 0; i < toRemove.length; i++ )
                    {
                        self._div.removeChild( toRemove[i]._div );
                        toRemove[i].destroy();
                    }
            }
        };

        screen.setA( 0 );
        this.addWidget( screen );
        screen.resourcesLoad();
        screen.getVal( Widget.W );  // trigger reflow in DOM
        screen.resourcesLoaded();

        toRemove = this._children.splice( 0, this._children.length - 1 );
        screen.animate( { a:1, duration:StackScreen.ANI_TIME, onEnd:remove } );

        _x2.requestFocus( screen );

        if( screen instanceof Screen )
        {
            if( _x2._telemetry )
                _x2._telemetry.sendActiveSession( screen );

            if( window.ll && screen._telemetryName )
                window.ll( "tagEvent", "Screen Viewed", { "Screen": screen._telemetryName } ); // Localytics
        }
    };
    /**
     * Clear splash screen, when we play video screen
     */
    StackScreen.prototype.clearSplashScreen = function() 
    {
        for( var i = 0; i < this._div.children.length; i++ )
        {
            if( this._div.children[i].id == 'LoadingScreen' ) {
                this._div.removeChild( this._div.children[i] );
                break;
            }
        }
    }//sometimes, it remove from children properties, but it still there in the div html children

    return StackScreen;

})();

window.KeyEater = ( function()
{
    "use strict";

    KeyEater.prototype = new Widget();

    function KeyEater(){}

    KeyEater.prototype.getFocus = function()
    {
        return this._inFocus;
    };

    KeyEater.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();
        _x2._hi.setA( 0 );//fadeout still showing highlight while moving between screen
    };

    KeyEater.prototype.init = function( widgetInFocus )
    {
        Widget.prototype.init.call( this );
        this._className  = "KeyEater";
        this._selectable = true;
        this._inFocus    = widgetInFocus;

        return this;
    };

    KeyEater.prototype.processEvent = function()
    {
        return true;
    };

    return KeyEater;

})();
