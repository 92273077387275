// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.LinearPromoRowWidget = ( function()
{
    "use strict";

    LinearPromoRowWidget.prototype = new CollectionRowWidget();

    function LinearPromoRowWidget(){}

    LinearPromoRowWidget.prototype.tick = function()
    {
        var i, entities, hasOutOfWindowEntities, now = Date.now(), self = this;

        var reload = function()
        {
            var i, entities, filtered=[], now = Date.now(), collection = self._data;

            self._list.setData( filtered );

            //The refresh query appears to return out-of-window entities for a few minutes after the entity is no longer airing. Filter them out.
            entities = collection.getEmbeddedBrowseItems();
            for( i=0; i<entities.length; i++ )
            {
                if( entities[i].getStartTime() <= now && entities[i].getEndTime() > now )
                    filtered.push( entities[i] );
            }

            self._filteredList = filtered;
            self._list.setData( filtered );
            if( self._list.containsWidget( _x2._focus ) )
                _x2.requestFocus( self._list );
        };

        //Refresh the data ONLY if there are any out-of-window entities.
        entities = ( this._filteredList ? this._filteredList : this._data.getEmbeddedBrowseItems() );
        for( i=0; i<entities.length; i++ )
        {
            if( ! ( entities[i].getStartTime() <= now && entities[i].getEndTime() > now ) )
            {
                hasOutOfWindowEntities = true;
                break;
            }
        }

        if( hasOutOfWindowEntities )
            this._data.refresh().then( reload , function(error) {
                if( error.getMajor() == 404 ) {
                    if( this._filteredList ) {
                        this._filteredList = [];
                    }
                    this._data._embedded = [];
                }// if the browsenode is not found, then we need to empty the embedded list, so that it will not retry the request
            }.bind(this));
    };

    LinearPromoRowWidget.prototype.init = function( params )
    {
        var self = this;

        params.type = LinearPromoCellWidget;
        params.y    = _x2.scaleVal( 152 ) - _x2.scaleVal( 13 );
        params.h    = _x2.scaleValInt( 560 );

        CollectionRowWidget.prototype.init.call( this, params );
        this._className  = "LinearPromoRowWidget";

        _x2.addChangeCallback( function() { self.tick() } );

        return this;
    };

    LinearPromoRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 660 );
    };

    return LinearPromoRowWidget;

})();
