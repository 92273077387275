// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.WelcomeScreen = ( function()
{
    "use strict";

    WelcomeScreen.prototype = new Screen();

    function WelcomeScreen(){}

    WelcomeScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._button, true );

        this._speakTarget.setText( this._a11yText + ' ' + focus._speakStr );
        this._speakTarget.setSpeechParams( "Press OK to select.", focus._speakRole, this, false );
    };

    WelcomeScreen.prototype.init = function( onAuth, params )
    {
        var self = this;

        Screen.prototype.init.call( this, { noHeader:true, noSep:true, image:_x2._config._imageRoot + "welcomeBg.jpg" } );
        this._className     = "WelcomeScreen";
        this._numLoaded     = 0;

        var layout      = function() { self.layout(); };
        var rectW       = _x2.scaleValInt(74);
        var titleStyle  = new Style( { font:"thin" , color:"#e8e8e8", fontSize:_x2.scaleValInt(100), textAlign :"center" } );
        var subStyle    = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt( 42), textAlign :"center" } );
        var buttonStyle = new Style( { font:"regular", color:"#e8e8e8", fontSize:_x2.scaleValInt( 32), whiteSpace:"nowrap", colorHi:"#000000" } );
        var titleStr    = _x2._st.welcomeTitle;
        var sub1Str     = _x2._st.welcomeBody1;
        var sub2Str     = [
          _x2._st.welcomeBody2,
          _x2._config._host.getType() === Host.Type.LG && _x2._st.welcomeBodyLG
        ].filter(Boolean).join(' ');
        var button      = new StringWidget().init( { text:_x2._st.welcomeButton, style:buttonStyle, onEnter:function() { _x2.pushScreen( new AuthCodeScreen().init( onAuth, params ) ); } } );

        this._a11yText = [
          titleStr.replace(/xfinity/gi, 'x finity'),
          sub1Str,
          sub2Str
        ].join(' ');

        this._container = new Widget().init();
        this._titleStr  = new StringWidget().init( { text:titleStr, style:titleStyle } );
        this._rect      = new RectWidget().init( { w:rectW, h:2, color:"#e8e8e8" } );
        this._sub1Str   = new StringWidget().init( { text:sub1Str, style:subStyle } );  // TODO text block
        this._sub2Str   = new StringWidget().init( { text:sub2Str, style:subStyle } );  // TODO text block
        this._button    = new HorizPillButtonsWidget().init( { entries:[button], spacing:_x2.scaleValInt(60) } );

        this._container.setA( 0 );
        this._titleStr.setW( _x2._config._screenW );
        this._sub1Str.setW ( _x2._config._screenW );
        this._sub2Str.setW ( _x2._config._screenW );
        this._sub2Str.addReadyCallback( layout );

        this.addWidget( this._container );
        this._container.addWidget( this._titleStr );
        this._container.addWidget( this._rect, (_x2._config._screenW - rectW) / 2, Style._safeTop );
        this._container.addWidget( this._sub1Str );
        this._container.addWidget( this._sub2Str );
        this._container.addWidget( this._button  );
        this._button.addReadyCallback( layout );

        _x2._config.log( Config.CLASS_LOCALYTICS, 1 ) ? console.log( "Localytics: Non-authenticated device: Welcome screen" ) : Config.NOP();
        window.ll( "tagScreen", "Welcome Screen" ); // Localytics 2.1.1

        // NOTE: Can't send Telemetry SCREEN_VIEWED -- don't have token to open WebSocket

        return this;
    };

    WelcomeScreen.prototype.layout = function()
    {
        if( ++this._numLoaded === 2 )
        {
            var y = _x2.scaleValInt(507);
            this._titleStr.setY( y );

            y += this._titleStr.getH() + _x2.scaleValInt(21);
            this._rect.setY( y );

            y += this._rect.getH() + _x2.scaleValInt(30);
            this._sub1Str.setY( y );

            y += this._sub1Str.getH() + _x2.scaleValInt(15);
            this._sub2Str.setY( y );

            y+= this._sub2Str.getH() + _x2.scaleValInt(51);
            this._button.setY( y );

            this._button.setX( (_x2._config._screenW - this._button.getW()) / 2 );

            this._container.animate( { a:1, duration:500 } );
        }
    };

    return WelcomeScreen;

})();
