// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.GradientWidget = ( function()
{
    "use strict";

    GradientWidget.prototype             = new Widget();
    GradientWidget.prototype.constructor = GradientWidget;
    GradientWidget.SHADER                = Widget.SHADER_GRADIENT_LINEAR_2AA; // place-holder value; proper shader will be selected at render() time

    GradientWidget.Type = Object.freeze
    ({
        Linear: 0,
        Radial: 1
    });

    function GradientWidget(){}

    /**
     * @memberof GradientWidget
     * @param {Object}               params
     * @param {Object[]}            [params.alphas]      - array of 2+ { opacity: percentage, location: percentage }
     * @param {Number}              [params.angle=0]     - Angle in degrees for Linear style
     * @param {Object[]}            [params.colors]      - array of 2+ { color: HTML hex color string, location: percentage }
     * @param {String}              [params.name]        - Class name override
     * @param {Number}              [params.w]           - width in pixels
     * @param {Number}              [params.h]           - height in pixels
     * @param {GradientWidget.Type} [params.type=Linear] - Linear or Radial
     * @returns {GradientWidget}
     */
    GradientWidget.prototype.init = function( params )
    {
        Widget.prototype.init.call( this );
        this._className = (params && params.name) ? params.name : "GradientWidget";

        if( params )
        {
            this.setW( params.w );
            this.setH( params.h );

            this._angle = params.angle || 0;
            this._type  = params.type || GradientWidget.Type.Linear;
            this.getGradients( params.alphas, params.colors );

            if( _x2._config._render === Config.RENDER_WEBGL )
            {
                this._paramW = params.w;
                this._paramH = params.h;
            }
        }

        return this;
    };

    GradientWidget.prototype.render = function( alphaParent )
    {
        var gl = Widget.gl, shader = Widget.glShaders[ this._shader ], a = this._curVal[Widget.ALPHA] * alphaParent;
        var rgba, n = this._gradients.length, aColors = this._aColors, w, h, xScale, yScale;

        // Over-ride place-holder GradientWidget.SHADER
        Widget.glShaderThis = shader;
        Widget.glShaderNext = this._shader;
        Widget.gl.useProgram( Widget.glShaderThis.program );

        Widget.gl.uniformMatrix4fv( Widget.glShaderThis.umProj, false, Widget.glProjMatrix[ Widget.glProjDepth ] );
        Widget.gl.uniformMatrix4fv( Widget.glShaderThis.umView, false, Widget.glViewMatrix[ Widget.glViewDepth ] );

        rgba = aColors[0]; gl.uniform4f( shader.uvGrad0, rgba[0], rgba[1], rgba[2], rgba[3] );
        rgba = aColors[1]; gl.uniform4f( shader.uvGrad1, rgba[0], rgba[1], rgba[2], rgba[3] );
        rgba = aColors[2]; gl.uniform4f( shader.uvGrad2, rgba[0], rgba[1], rgba[2], rgba[3] );
        rgba = aColors[3]; gl.uniform4f( shader.uvGrad3, rgba[0], rgba[1], rgba[2], rgba[3] );

        gl.uniform4f( shader.uvColor, 1, 1, 1, a );

        w      = this._curVal[ Widget.W ] || 1; // prevent divide-by-zero
        h      = this._curVal[ Widget.H ] || 0;
        xScale = this._curVal[Widget.X_SCALE];
        yScale = this._curVal[Widget.Y_SCALE];

        if( xScale !== 1 )
        {
            w *= xScale;
        }

        if( yScale !== 1 )
        {
            h *= yScale;
        }

        gl.uniform2f( shader.uvDimension, w, h );

        // Widget.SHADER_GRADIENT_RADIAL_2   // nothing extra to do
        // Widget.SHADER_GRADIENT_LINEAR_2AA // nothing extra to do for "Fast" Path - 2 colors axis aligned
        if( this._shader === Widget.SHADER_GRADIENT_LINEAR_4ROT ) // "Medium" Path - upto 4 colors any angle
        {
            if( n > 1 )
                gl.uniform4f( shader.uvStops, 0.0, this._gradients[1].x, this._gradients[n-2].x, 1.0 );

            gl.uniform1f( shader.unAngle, this._angleRad );
            gl.uniform1f( shader.unooAspectRatio, h / w );
        }
        else if( this._shader === Widget.SHADER_GRADIENT_RADIAL_4 ) // Radial
        {
            if( n > 1 )
                gl.uniform4f( shader.uvStops, 0.0, this._gradients[1].x, this._gradients[n-2].x, 1.0 );
        }

        gl.bindBuffer( gl.ARRAY_BUFFER, this._buffPosition );
        gl.vertexAttribPointer( shader.avPosition, 2, gl.FLOAT, false, this._dataPosition, 0 ); // index, size, type, bNormalized, stride, offset
        gl.enableVertexAttribArray( shader.avPosition );
        gl.drawArrays( gl.TRIANGLE_STRIP, 0, 4 );
    };

    GradientWidget.prototype.resourcesLoad = function()
    {
        var gl, w, h, verts;

        Widget.prototype.resourcesLoad.call( this );

        if(  _x2._config._render === Config.RENDER_WEBGL )
        {
            gl = Widget.gl;
            w  = this._curVal[Widget.W];
            h  = this._curVal[Widget.H];

            verts =
            [
                0, 0,
                w, 0,
                0, h,
                w, h
            ];

            this._dataPosition = new Float32Array( verts );
            this._buffPosition = gl.createBuffer();

            gl.bindBuffer( gl.ARRAY_BUFFER, this._buffPosition );
            gl.bufferData( gl.ARRAY_BUFFER, this._dataPosition, gl.DYNAMIC_DRAW ); // Dynamic
        }

        this.setAngle( this._angle );
    }

    GradientWidget.prototype.layout = function()
    {
        var i, n = this._gradients.length, grad = this._gradients;

        var obj, loc;
        var color;
        var r,g,b,a;

        if( _x2._config._render === Config.RENDER_DOM )
        {
            var bg = "";

            switch( this._type )
            {
                case GradientWidget.Type.Linear:
                    bg = "linear-gradient(" + this._angle + "deg";
                    break;

                case GradientWidget.Type.Radial:
                    bg = "radial-gradient(ellipse at center";
                    break;

                default:
                    console.error( "ERROR -> Invalid gradient fill style" );
                    n = 0;
                    break;
            }

            for( i = 0; i < n; i++ )
            {
                obj   = this._gradients[i];
                color = obj.color;
                loc   = obj.x;

                r = parseInt( color.substring( 1,3 ), 16 );
                g = parseInt( color.substring( 3,5 ), 16 );
                b = parseInt( color.substring( 5,7 ), 16 );
                a = obj.alpha;
                bg += ", rgba(" + r + "," + g + "," + b + "," + a + ") " + loc + "%";
            }
            bg += ")"; // NOTE: Do NOT include semi-colon after 'gradient()' !

//console.info( bg );

            this._div.style.background = bg;
        }
        else if(  _x2._config._render === Config.RENDER_WEBGL )
        {
            if( this._type === GradientWidget.Type.Linear )
            {
                if( (n === 2) && ((this._angle % 90) === 0) )
                {
                    this._shader = Widget.SHADER_GRADIENT_LINEAR_2AA;
                    switch( this._angle ) // "Fast" Path - only 2 colors and axis aligned -- 0, 90, 180, 270 -> setup corner colors
                    {
                        case 0:
                            this._aColors[0] = grad[  0].rgba; // Bottom Left
                            this._aColors[1] = grad[n-1].rgba; // Bottom Right
                            this._aColors[2] = grad[  0].rgba; // Top Left
                            this._aColors[3] = grad[n-1].rgba; // Top Right
                            break;

                        case 90:
                            this._aColors[0] = grad[n-1].rgba; // Bottom Left
                            this._aColors[1] = grad[n-1].rgba; // Bottom Right
                            this._aColors[2] = grad[  0].rgba; // Top Left
                            this._aColors[3] = grad[  0].rgba; // Top Right
                            break;

                        case 180:
                            this._aColors[0] = grad[n-1].rgba; // Bottom Left
                            this._aColors[1] = grad[  0].rgba; // Bottom Right
                            this._aColors[2] = grad[n-1].rgba; // Top Left
                            this._aColors[3] = grad[  0].rgba; // Top Right
                            break;

                        case 270:
                            this._aColors[0] = grad[  0].rgba; // Bottom Left
                            this._aColors[1] = grad[  0].rgba; // Bottom Right
                            this._aColors[2] = grad[n-1].rgba; // Top Left
                            this._aColors[3] = grad[n-1].rgba; // Top Right
                            break;
                    }
                }
                else if( n < 5 )
                {
                    this._shader = Widget.SHADER_GRADIENT_LINEAR_4ROT;

                    this._aColors[0] = grad[  0].rgba; // color at  0 %
                    this._aColors[1] = grad[  1].rgba; // color at x1 %
                    this._aColors[2] = grad[n-2].rgba; // color at x2 %
                    this._aColors[3] = grad[n-1].rgba; // color at 100%
                }
                else
                    console.error( "WebGL ERROR: Implement Linear Gradient > 4 stops!" );

            }
            else
            {
                // n < 2 already verifed in getGradients()
                if( n === 2 )
                    this._shader = Widget.SHADER_GRADIENT_RADIAL_2;
                else if( n < 5 )
                    this._shader = Widget.SHADER_GRADIENT_RADIAL_4;
                else
                    console.error( "WebGL ERROR: Implement Radial Gradient > 4 stops!" );
                /*
                    n   stop0    stop1    stop2    stop3   n-2  n-1
                    2   grad[0]  grad[1]  n/a      n/a       0    1
                    3   grad[0]  grad[1]  grad[2]  n/a       1    2
                    4   grad[0]  grad[1]  grad[2]  grad[3]   2    3

                    NOTE:
                        grad[  0] =   0%
                        grad[n-1] = 100%
                */

                this._aColors[0] = grad[  0].rgba; // 1st stop @ 0%
                this._aColors[1] = grad[  1].rgba; // 2nd stop
                this._aColors[2] = grad[n-2].rgba; // 3rd stop
                this._aColors[3] = grad[n-1].rgba; // 4th stop @ 100%
            }
        }
    };

    // Internal Use Only
    // @param {Object[]} alphas
    // @param {Object[]} colors
    // @param {Boolean}  normalizeLocation - true if locations are 0% ... 100%, false if locations already normalized
    // this._gradients[] // DOM & WebGL
    GradientWidget.prototype.getGradients = function( alphas, colors )
    {
        var locNormalizeInverse = Widget.gl
            ? 1.0 / 100.0
            : 1.0
            ;

        // Linearly Interpolate, aka 'mix' or 'lerp'
        // NOTE: Non-standard argument order to be consistent with Widget.interpolateColor()
        // @param {Number} t - normalized percentage to mix between 'a' and 'b', 0.0 = a, 1.0 = b
        // @param {Number} a - min value
        // @param {Number} b - max value
        var lerp = function( t, a, b ) // NOTE: TODO: Should be in Math Utility or Widget.Easing
        {
            return a + (b-a)*t;
        };

        // @param {Number} alpha    - normalized percentage
        // @param {Number} location - percentage
        var makeAlphaLocationObject = function( alpha, location )
        {
            return {
                alpha: alpha,
                x    : location
            };
        };

        // @param {String} color    - 7 character HTML hex color string in format "#RRGGBB"
        // @param {Number} location - percentage
        var makeColorLocationObject = function( color, location )
        {
            return {
                color: color,
                x    : location
            };
        };

        var alphaIdx = 0, alphaLen = alphas.length;
        var colorIdx = 0, colorLen = colors.length;

        var alphaObj = alphas[0];
        var colorObj = colors[0];

        var alphaLoc = alphaObj.location * locNormalizeInverse;
        var colorLoc = colorObj.location * locNormalizeInverse;

        var alphaVal = alphaObj.opacity / 100;
        var colorVal = colorObj.color;

        var alphaPrevIdx = alphaIdx;
        var colorPrevIdx = colorIdx;

        var iStop, nStop;
        var obj, prev;

        var aAlpha = [];
        var aColor = [];
        var grad   = [];

        var x0, w1, w2, t;
        var a, c;


        if( !Array.isArray( alphas ) || alphaLen < 2 )
            return console.error( "ERROR -> Gradient alphas[] must be 2+ elements" );

        if( !Array.isArray( colors ) || colorLen < 2 )
            return console.error( "ERROR -> Gradient colors[] must be 2+ elements" );

        if( _x2._config._render === Config.RENDER_WEBGL )
        {
            this._aColors = [];
        }

        // Deep Copy: Add Alpha Locations: Start @ 0% and End @ 100% if missing
        if( alphaLoc !== 0 )
            aAlpha.push( makeAlphaLocationObject( alphaVal, 0 ) );

        for( ; alphaIdx < alphaLen; alphaIdx++ )
            aAlpha.push( makeAlphaLocationObject( alphas[ alphaIdx ].opacity / 100, alphas[ alphaIdx ].location * locNormalizeInverse) );

        if( alphas[ alphaLen - 1 ].location !== 100 )
            aAlpha.push( makeAlphaLocationObject( alphas[ alphaLen - 1 ].opacity / 100, 100 * locNormalizeInverse ) );

        // Deep Copy: Add Color Locations: Start @ 0% and End @ 100% if missing
        if( colorLoc !== 0 )
            aColor.push( makeColorLocationObject( colorVal, 0 ) );

        for( ; colorIdx < colorLen; colorIdx++ )
            aColor.push( makeColorLocationObject( colors[ colorIdx ].color, colors[ colorIdx ].location * locNormalizeInverse ) );

        if( colors[ colorLen - 1 ].location !== 100 )
            aColor.push( makeColorLocationObject( colors[ colorLen - 1 ].color, 100 * locNormalizeInverse ) );


        // Duplicate last alpha/color to keep "next index" in bounds for interpolation
        alphaIdx = 0; alphaLoc = 0; alphaLen = aAlpha.length;
        colorIdx = 0; colorLoc = 0; colorLen = aColor.length;

        aAlpha.push( aAlpha[ alphaLen - 1 ] );
        aColor.push( aColor[ colorLen - 1 ] );

        nStop = alphaLen + colorLen; // maximum number of stops -- some of these may get collapsed if colorLoc and AlphaLoc are at same location
        prev =
        {
            alpha: alphaVal,
            color: colorVal,
            x    : 0
        };

        for( iStop = 0; iStop < nStop; )
        {
            if( (alphaIdx < alphaLen) && (alphaLoc < colorLoc) ) // interpolate color
            {
                /*        x0         x1        x2
                   0.0 --------------|------------- 1.0 Alpha Location
                   0.0 ---|--------------------|--- 1.0 Color Location
                          ^          ^         ^
                          colorLeft  alphaLoc  colorLoc
                          <----w1---->
                          <----w2-------------->
                */
                x0   = aColor[ colorPrevIdx ].x;
                w1   = alphaLoc - x0;
                w2   = colorLoc - x0;
                t    = w1 / w2;
                c    = this.interpolateColor( t, prev.color, colorVal ); // aColor[ colorPrevIdx ].color

                obj =
                {
                    alpha: alphaVal,
                    color: c,
                    x    : alphaLoc
                };
                alphaPrevIdx = alphaIdx++;
                iStop++;
            }
            else if( (colorIdx < colorLen) && (alphaLoc > colorLoc) ) // interpolate alpha
            {
                /*        x0         x1        x2
                   0.0 ---|--------------------|--- 1.0 Alpha Location
                   0.0 --------------|------------- 1.0 Color Location
                          ^          ^         ^
                          alphaLeft  colorLoc  alphaLoc
                          <----w1---->
                          <----w2-------------->
                */
                x0  = aAlpha[ alphaPrevIdx ].x;
                w1  = colorLoc - x0;
                w2  = alphaLoc - x0;
                t   = w1 / w2;
                a   = lerp( t, prev.alpha, alphaVal ); // aAlpha[ alphaPrevIdx ].opacity/100

                obj =
                {
                    alpha: a,
                    color: colorVal,
                    x    : colorLoc
                };
                colorPrevIdx = colorIdx++;
                iStop++;
            }
            else // collapse down into 1 entry, no interpolation needed
            {
                obj =
                {
                    alpha: alphaVal,
                    color: colorVal,
                    x    : colorLoc
                };

                alphaPrevIdx = alphaIdx++;
                colorPrevIdx = colorIdx++;
                iStop += 2;
            }

            if( alphaIdx < alphaLen ) alphaObj = aAlpha[ alphaIdx ];
            if( colorIdx < colorLen ) colorObj = aColor[ colorIdx ];

            alphaVal = alphaObj.alpha; alphaLoc = alphaObj.x;
            colorVal = colorObj.color; colorLoc = colorObj.x;


            if( _x2._config._render === Config.RENDER_WEBGL )
            {
                var rgba = Widget.glColorHexToFloat( obj.color );
                rgba[3]  = obj.alpha;

                // https://www.w3.org/TR/css3-images/#color-stop-syntax
                // Between two color-stops, the line's color is linearly interpolated
                // between the colors of the two color-stops,
                // with the interpolation taking place in premultiplied RGBA space.
                rgba[0] *= obj.alpha;
                rgba[1] *= obj.alpha;
                rgba[2] *= obj.alpha;

                obj.rgba = rgba;
            }

//console.info( obj ); // DEBUG only

            grad.push( obj );
            prev = obj;
        }

        this._gradients = grad;
    };

    GradientWidget.prototype.setAngle = function( angle )
    {
        if( _x2._config._render === Config.RENDER_DOM )
        {
            /*
                Who designed this "standard" ???

                    1) Ignore _standard_ trigonometric angle conventions (0 degrees = left-to-right)
                    2) Uses positive clock-wise angles !?

                DOM          <- Trigonometric angle

                315   0   45    135   90   45
                   <  ^  >         <  ^  >
                    \ | /           \ | /
                     \|/             \|/
                270<--+--> 90   180<--+-->  0
                     /|\             /|\
                    / | \           / | \
                   <  v  >         <  v  >
                225  180  135   225  270  315

                Reference:

                    https://www.w3.org/TR/css3-images/#linear-gradients
                    For the purpose of this argument, ‘0deg’ points upward, and positive angles represent clockwise rotation, so ‘90deg’ point toward the right.
            */
            this._angle = (360 - ((angle || 0) - 90)); // *sigh*
//console.info( "Gradient Angle: " + params.angle + " --DOM-> " + this._angle );
        }
        else if( _x2._config._render === Config.RENDER_WEBGL )
        {
            this._angle  = (angle || 0);
        }

        this._angle %= 360;
        if( this._angle < 0 ) // mod doesn't convert negative to positive
            this._angle += 360;

        this._angleRad = this._angle * Math.PI / 180.0; // DEG2RAD()

        this.layout();

    }

    return GradientWidget;

})();
