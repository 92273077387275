// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.TextLeadingTestScreen = ( function()
{
    "use strict";

    TextLeadingTestScreen.prototype = new Screen();

    function TextLeadingTestScreen(){}

    TextLeadingTestScreen.prototype.init = function()
    {
        var x, y, cx, dy;
        var headStyle, bodyStyle, infoStyle, descStyle;
        var fontSize;

        this._className = "TextLeadingTestScreen";
        Screen.prototype.init.call( this );

        fontSize = 27;
        this._size = fontSize;

        cx = _x2._config._screenW*0.5;
        dy = fontSize + 2;

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        headStyle = new Style( { font: "light", color: "#22AAFF", fontSize: _x2.scaleValInt( fontSize), whiteSpace:"nowrap" } );
        bodyStyle = new Style( { font: "light", color: "#e8e8e8", fontSize: _x2.scaleValInt( fontSize), whiteSpace:"nowrap" } );
        infoStyle = new Style( { font: "light", color: "#FFFF00", fontSize: _x2.scaleValInt( fontSize)                      } );

        this._text1 = new StringWidget().init( { text: "Abc...xyz", style: bodyStyle } ); // Requires whiteSpace: "nowrap" to measure
        this._text2 = new StringWidget().init( { text: "Abc...xyz", style: bodyStyle } );
        this._text3 = new StringWidget().init( { text: "Abc...xyz", style: bodyStyle } );

        this._head1 = new StringWidget().init( { text: "Font Size: ", style: headStyle } );
        this._head2 = new StringWidget().init( { text: "Leading..: ", style: headStyle } );
        this._head3 = new StringWidget().init( { text: "Width....: ", style: headStyle } );

        this._info1 = new StringWidget().init( { text: "?", style: infoStyle } );
        this._info2 = new StringWidget().init( { text: "?", style: infoStyle } );
        this._info3 = new StringWidget().init( { text: "?", style: infoStyle } );

        this._bbox1 = new RectWidget().init( { w:200, h:fontSize, color:"#222", borderThickness:1, borderColor: "#00FF00" } );



        x = Style._safeLeft;
        y = Style._safeTop;

        this.addWidget( this._bbox1, x, y );
        this.addWidget( this._text1, x, y ); y += dy;
        this.addWidget( this._text2, x, y ); y += dy;
        this.addWidget( this._text3, x, y );

        x = cx;
        y = Style._safeTop;
        this.addWidget( this._head1, x, y ); this.addWidget( this._info1, x, y ); y += dy;
        this.addWidget( this._head2, x, y ); this.addWidget( this._info2, x, y ); y += dy;
        this.addWidget( this._head3, x, y ); this.addWidget( this._info3, x, y );

        this._selectable = true;

        return this;
    };

    TextLeadingTestScreen.prototype.gotFocus = function()
    {
    };

    TextLeadingTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        if( type === Host.KEY_PRESSED )
            switch( val )
            {
                default:
                    retval = Screen.prototype.processEvent.call( this, val, type );
                    break;
            }

        return retval;
    };

    TextLeadingTestScreen.prototype.resourcesLoaded = function()
    {
        Widget.prototype.resourcesLoaded.call( this );

        var w, h;
        w = this._text1.getW(); this._bbox1.setW( w );
        h = this._text1.getH(); this._bbox1.setH( h );

        this._info1.setText( this._size );
        this._info2.setText( h );
        this._info3.setText( w );

        w = this._head1.getW(); this._head1.setX( this._head1.getX() - w - 2 );
        w = this._head2.getW(); this._head2.setX( this._head2.getX() - w - 2 );
        w = this._head3.getW(); this._head3.setX( this._head3.getX() - w - 2 );
    };

    return TextLeadingTestScreen;

})();

