// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.LastWatchedRowWidget = ( function()
{
    "use strict";

    LastWatchedRowWidget.prototype = new CollectionRowWidget();

    function LastWatchedRowWidget(){}

    /**
     * @memberof LastWatchedRowWidget
     * @param params
     * @returns {LastWatchedRowWidget}
     * @see CollectionRowWidget
     */
    LastWatchedRowWidget.prototype.init = function( params )
    {
        params.type = LastWatchedCellWidget;
        params.y    = _x2.scaleVal( 160 ) - _x2.scaleVal( 12 );
        params.h    = _x2.scaleValInt( 376 );

        CollectionRowWidget.prototype.init.call( this, params );
        this._className  = "LastWatchedRowWidget";

        return this;
    };

    LastWatchedRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 540 );
    };

    return LastWatchedRowWidget;

})();
