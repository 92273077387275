// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 *
 * @class
 */
window.Season = (function()
{
    "use strict";

    Season.prototype = new DataObject();

    function Season(){}

    Season.prototype.init = function( data )
    {
        DataObject.prototype.init.call( this, data );
        return this;
    };

    Season.prototype.addEpisode = function( episode )
    {
        if( ! this._episodes )
            this._episodes = [];

        this._episodes.push( episode );
    };

    Season.prototype.getNumber = function()
    {
        return this.get("seasonNumber");
    };

    Season.prototype.getEpisodeCount = function()
    {
        var count = this.getInteger("totalPrograms");

        if( ! count && this._episodes )
            count = this._episodes.length;

        return count;
    };

    Season.prototype.getEpisodes = function( page )
    {
        var embedded, episodes = [];

        if( ! this._episodes )
        {
            this._episodes = [];
            embedded = this.getPath(["_embedded","watchOptions","_embedded","programs"] );
            if( embedded )
            {
                for( var i=0; i<embedded.length; i++ )
                    this._episodes.push( new Entity().init(embedded[i]));
            }
        }

        if( page !== undefined )
        {
            if( this._episodes.length > 0 )
            {
                var start = page  * EntityOptions.programsPerPage;
                var end   = start + EntityOptions.programsPerPage;
                episodes = this._episodes.slice( start, end )
            }
        }
        else
            episodes = this._episodes;

        return episodes;
    };

    Season.prototype.fetchEpisodes = function( page, perPage )
    {
        var setEpisodes, params, link, i, promise;
        var self         = this;
        var episodeCount = this._episodes ? this._episodes.length : 0;

        setEpisodes = function( response )
        {
            var data     = JSON.parse(response.data);
            var episodes = self.getPath(["_embedded", "programs"], data );

            if( self._episodes === undefined )
                self._episodes = [];

            for( i=0; i<episodes.length; i++ )
                self._episodes.push( new Entity().init(episodes[i]));
        };

        link = this.getLinkObject("watchOptions");

        if( link )
        {
            params = {};
            params.programPage     = page;
            params.programsPerPage = perPage;
            params.programsSortDir = 'desc';
            params.freetome        = ( _x2._config._purchases && _x2._features.hasEntitlement( Features.Entitlement.EST ) ) ? "off" : "on";

            if( episodeCount < ( page * perPage ) )
                promise = link.resolve( params ).then( setEpisodes );
            else
                promise = Promise.resolve();
        }
        else //if there is no watchOptions link, there are no additional episodes to fetch
            promise = Promise.resolve();

        return promise;
    };

    return Season;
})();
