// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.NetworkCellWidget = ( function()
{
    "use strict";

    NetworkCellWidget.prototype = new CollectionCellWidget();

    function NetworkCellWidget(){}

    NetworkCellWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        CollectionCellWidget.prototype.gotFocus.call( this );
        this._bg.animate( { xScale:this._scale, yScale:this._scale, duration:ListWidget.SCROLL_TIME_STEP } );
        this._title.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );
        this._title.setSpeechParams( undefined, undefined, this, newScreen );

        return this;
    };

    /**
     * @memberof NetworkCellWidget
     * @param {Object} params - @see CollectionCellWidget
     * @returns {NetworkCellWidget}
     * @see CollectionCellWidget
     */
    NetworkCellWidget.prototype.init = function( params )
    {
        var self      = this;
        var spacing   = _x2.scaleValInt( 19 );
        var textSize  = _x2.scaleValInt( 48 );
        var titleSize = _x2.scaleValInt( 32 );
        var textStyle = new Style( { color:"#e8e8e8", font:"light", fontSize:textSize, textAlign :"center" } );
        var titleStyle= new Style( { color:"#e8e8e8", font:"light", fontSize:titleSize, whiteSpace:"nowrap" } );
        var alphas    = [ { opacity:100    , location:0 }, { opacity:100    ,location: 100 } ];
        var colors    = [ { color:"#1C262F", location:0 }, { color:"#130917", location:100 } ];

        CollectionCellWidget.prototype.init.call( this, params );
        this._className  = "NetworkCellWidget";

        this._bgW    = _x2.scaleValInt( 412 );
        this._bgH    = _x2.scaleValInt( 309 );
        this._scale  = (this._bgW + 2 * spacing) / this._bgW;
        this._focusW = this._scale * this._bgW;
        this._focusH = this._scale * this._bgH;

        this._bg = new GradientWidget().init( { alphas:alphas, angle:-135, colors:colors, w:this._bgW, h:this._bgH, type:GradientWidget.Type.Linear } );
        this.addWidget( this._bg, spacing, (this._scale - 1) * this._bgH / 2 );

        this._defaultText = new TextBlockWidget().init( { style:textStyle, w:this._bgW, h:this._bgH } );
        this.addWidget( this._defaultText, spacing, ( this._bgH / 2 - textSize / 2 ) );

        this._title = new StringWidget().init( { style:titleStyle } );
        this._bg.addWidget( this._title, 0, this._bgH + Style._pad );
        this._title.setA(0);

        this._image = new ImageWidget().init( { onError:function(){ self.imageError()}, onChange:function(){ self.imageLoaded() } } );
        this._bg.addWidget( this._image );
        this._image.setA(0);

        return this;
    };

    NetworkCellWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 412 ) + _x2.scaleValInt( 19 );
    };

    NetworkCellWidget.prototype.getTouchDim = function()
    {
        return _x2.scaleValInt( 309 ) * (_x2.scaleValInt( 412 ) + 2 * _x2.scaleValInt( 19 )) / _x2.scaleValInt( 412 );
    };

    NetworkCellWidget.prototype.imageError = function()
    {
        this._image.setA(0);
    };

    NetworkCellWidget.prototype.imageLoaded = function()
    {
        this._image.setW( undefined );
        this._image.setH( undefined );

        var maxW   = _x2.scaleValInt( 156 );
        var maxH   = _x2.scaleValInt( 117 );
        var aspect = maxW / maxH;

        if( aspect > this._image.getW() / this._image.getH() )
            this._image.setH( maxH );
        else
            this._image.setW( maxW );

        this._image.setX( (this._bgW - this._image.getW()) / 2 );
        this._image.setY( (this._bgH - this._image.getH()) / 2 );

        this._image.setA( 1 );
        this._defaultText.setA( 0 );
    };

    NetworkCellWidget.prototype.lostFocus = function()
    {
        CollectionCellWidget.prototype.lostFocus.call( this );
        this._bg.stopAnimation( Widget.X_SCALE );
        this._bg.stopAnimation( Widget.Y_SCALE );
        this._bg.animate( { xScale:1, yScale:1, duration:ListWidget.SCROLL_TIME_STEP } );
        this._title.animate( { alpha:0, duration:ListWidget.SCROLL_TIME_STEP } );

    };

    NetworkCellWidget.prototype.setData = function( data )
    {
        if( data !== undefined )
        {
            this._data = data;

            this._image.setA( 0 );
            this._defaultText.setA( 1 );

            this._image.setUrl( data.getNetworkImageLink( 206, 92 ) );  //??? where did these numbers come from
            this._defaultText.setText( data.getTitle() );
            this._title.setText( data.getTitle() );

            this.setA( 1 );
        }
        else
            this.setA( 0 );
    };

   return NetworkCellWidget;

})();
