// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Maps to XTV "SearchResult"
 */
window.SearchEntity = (function()
{
    "use strict";
    function SearchEntity(){}

    SearchEntity.prototype = new ThumbnailEntity();

    /**
     * Initialize this object
     * @param {Object} data - XTV derived JSON object
     * @return {SearchEntity}
     */
    SearchEntity.prototype.init = function( data )
    {
        ThumbnailEntity.prototype.init.call( this, data );

        return this;
    };

    /**
     * Get the linear asset's end time
     * @return {Date}
     */
    SearchEntity.prototype.getEndTime = function()
    {
        var time, endTime = this.getInteger("endTime");

        if( endTime )
            time = new Date( endTime );

        return time;
    };

    /**
     * Get the embedded entity
     * @return {String}
     */
    SearchEntity.prototype.getEntity = function()
    {
        return this.getEmbedded("entity");
    };

    /**
     *
     * @return {*|String}
     */
    SearchEntity.prototype.getEntityId = function()
    {
        var id, entity = this.getEntity();

        if( entity )
        {
            id = entity['programId'];

            if( ! id )
                id = entity['merlinId'];
        }

       return id;
    };

    /**
     *
     * @param width
     * @param height
     * @returns {string}
     */
    SearchEntity.prototype.getFallbackImageLink = function( width, height )
    {
        var link = this.getPath(["_embedded","entity","_links","fallbackImage"]);

        if( link )
            link = XtvApi.replaceUrlParams( link.href, { width: width, height: height } );
        else
        {
            link = this.getImageLink( width, height );
            if( link )
                link = link + "&default=force";
        }

        return link;
    };

    /**
     *
     * @param width
     * @param height
     * @return {string}
     */
    SearchEntity.prototype.getImageLink = function( width, height )
    {
        var link;
        var w    = width  || 180;
        var h    = height || 240;
        var type = this.getPath(["_embedded","entity","_type"]);

        if( type === "s:Organization" )
            link = _x2._config._imageRoot + "channel_collection_bg.png";
        else
        {
            link = this.getPath(["_embedded","entity","_embedded","channel","_links","logo"]);

            if( ! link )
                link = this.getPath(["_embedded","entity","_links","image"]);

            if( link )
                link = XtvApi.replaceUrlParams( link.href, { width: w, height: h } );
        }

        return link;
    };

    SearchEntity.prototype.getChannel = function()
    {
        if( ! this._channel )
        {
            var channel = this.getPath(["_embedded","entity","_embedded","channel"]);

            if( channel )
                this._channel = new Channel().init(channel);
        }

        return this._channel;
    };


    SearchEntity.prototype.getProgramType = function()
    {
        return this.getPath(["_embedded","entity","_type"]);
    };

    /**
     * Get the entity subtitle (description)
     * @memberOf SearchEntity
     * @return {String}
     */
    SearchEntity.prototype.getSubtitle = function()
    {
        return this.get("subtitle");
    };

    /**
     * Get the HLS stream for this entity
     * @return {String} url
     */
    SearchEntity.prototype.getStreamUrl = function()
    {
        var i, url, stream, streams = this.getPath(["_embedded","entity","_embedded","channel","_embedded","stream"]);

        if( streams )
        {
            for( i=0; i<streams.length; i++ )
            {
                stream = streams[i];
                if( stream["encodingFormat"] === 'HLS' )
                    url = this.getPath(["_links","contentUrl","href"], stream );
            }
        }

        return url;
    };

    /**
     * Get the linear asset's start time
     * @return {Date}
     */
    SearchEntity.prototype.getStartTime = function()
    {
        var time, startTime = this.getInteger("startTime");

        if( startTime )
            time = new Date( startTime );

        return time;
    };

    /**
     * Get the entity title
     * @memberOf SearchEntity
     * @return {String}
     */
    SearchEntity.prototype.getTitle = function()
    {
        return this.get("name");
    };

    SearchEntity.prototype.getType = function()
    {
        return this.getPath(["_embedded","entity","_type"]);
    };

    return SearchEntity;

})();
