// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.DeleteDownloadsOverlay = ( function()
{
    "use strict";

    DeleteDownloadsOverlay.prototype = new Overlay();

    function DeleteDownloadsOverlay(){}

    DeleteDownloadsOverlay.prototype.gotFocus = function()
    {
        _x2.requestFocus( this._buttons );
    };

    /**
     * Initializer
     * @memberof DeleteDownloadsOverlay
     * @param   {Object}   params
     * @param   {Function} params.onEnter - Function called when remove is selected
     * @returns {DeleteDownloadsOverlay}
     */
    DeleteDownloadsOverlay.prototype.init = function( params )
    {
        Overlay.prototype.init.call( this );
        this._className = "DeleteDownloadsOverlay";
        this._numReady  = 0;

        var self        = this, onReady, reset, cancel;
        var titleStyle  = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(45), whiteSpace:"nowrap", textAlign:"center" } );
        var descStyle   = new Style( { font:"light" , color:"#9b9b9b", fontSize:_x2.scaleValInt(27), whiteSpace:"nowrap", textAlign:"center" } );
        var buttonStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap", colorHi:"#000000"  } );
        var w           = this._bg.getW();
        var title       = "Are you sure?";
        var desc        = "All downloaded content will be removed from this device.";

        onReady = function() { self._numReady++; self.layoutDeleteDownloadsOverlay() };
        reset   = new StringWidget().init( { text:"Yes, remove", style:buttonStyle, onEnter:params.onEnter   } );
        cancel  = new StringWidget().init( { text:"Cancel" , style:buttonStyle, onEnter:function() { _x2.popOverlay(); } } );

        this._title = new StringWidget().init( { text:title, style:titleStyle } );
        this._title.setW( w );
        this._title.addReadyCallback( onReady );
        this._bg.addWidget( this._title );

        this._desc = new TextBlockWidget().init( { text:desc, style:descStyle, w:w/2 } );
        this._bg.addWidget( this._desc, w/4 );

        this._buttons = new HorizPillButtonsWidget().init( { entries:[cancel,reset], spacing:_x2.scaleValInt(41) } );
        this._buttons.addReadyCallback( onReady );
        this._bg.addWidget( this._buttons );

        return this;
    };

    DeleteDownloadsOverlay.prototype.layoutDeleteDownloadsOverlay = function()
    {
        if( this._numReady === 2 )
        {
            var titleH   = this._title.getH();
            var descH    = this._desc.getH();
            var buttonsH = this._buttons.getH();
            var offset   = (this._bg.getH() - titleH - descH - buttonsH - 7 * Style._pad) / 2;

            this._title.setY ( offset );
            this._desc.setY( this._title.getY() + titleH + 3 * Style._pad );
            this._buttons.setY( this._desc.getY() + descH + 4 * Style._pad );
            this._buttons.setX( (this._bg.getW() - this._buttons.getW()) / 2 );
        }
    };

    return DeleteDownloadsOverlay;

})();
