// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.StreamingDevicesScreen = ( function()
{
    "use strict";

    StreamingDevicesScreen.prototype = new Screen();

    function StreamingDevicesScreen(){}

    StreamingDevicesScreen.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();
//       _x2.requestFocus( this._list );
    };

    /**
     * Initializer
     * @memberof StreamingDevicesScreen
     * @param   {Object} params
     * @param   {String} params.path - breadcrumb path
     * @returns {StreamingDevicesScreen}
     */
    StreamingDevicesScreen.prototype.init = function( params )
    {
        var onSuccess;
        var self       = this, listParams = {};
        var w          = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        var titleStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap" } );
        var bodyStyle  = new Style( { font:"light" , color:"#a3a3a3", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap" } );
        var noStyle    = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(54), whiteSpace:"nowrap", textAlign:"center" } );
        var descStr    = "If you've reached your streaming limit, you'll need to stop playback on one device before you can start streaming on another.";
        var onLoad     = function() { self._numLoaded++; self.layout(); };

        Screen.prototype.init.call( this );
        this._className     = "StreamingDevicesScreen";
        this._telemetryName = "Streaming Devices";
        this.setBreadCrumbs( params.path, "Devices Currently Streaming" );

        this._numLoaded = 0;

        this._title = new StringWidget().init( { text:"Devices Currently Streaming", style:titleStyle } );
        this.addWidget( this._title, Style._safeLeft );

        this._desc = new TextBlockWidget().init( { text:descStr , style:bodyStyle, w:w } );
        this.addWidget( this._desc, Style._safeLeft );

        this._lowerSep = new RectWidget().init( { w:w, h:ListRowWidget.DIVIDER_H, color:"#303030" } );
        this.addWidget( this._lowerSep, Style._safeLeft );

        this._noStream = new StringWidget().init( { text:"No devices currently streaming.", style:noStyle } );
        this._noStream.setW( _x2._config._screenW );
        this._noStream.setA( 0 );
        this._noStream.addReadyCallback( onLoad );
        this.addWidget( this._noStream );

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( onLoad );
        this.addWidget( this._distractor );

        listParams.w        = w;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 1;
        listParams.type     = DevicesRowWidget;

        this._list = new ListWidget().init( listParams );
        this._list.setA( 0 );
        this.addWidget( this._list, Style._safeLeft );

            /**
             * @param {Object[]} data
             * @param {Boolean}  data[].isStreaming - indicates that the specified device is currently streaming
             * @param {String}   data[].streamType  - indicates the type of the stream
             */
            onSuccess = function( data )
            {
                var results = [], subTitle;

                console.log( "Devices Currently Streaming" );
                console.log( data );

                self._distractor.hide();
                self._loaded = true;

                if( data && data.length > 0 )
                {
                    for( var i = 0; i < data.length; i++ )
                        if( data[i].isStreaming === true )
                        {
                            switch( data[i].streamType )
                            {
                                case "DVR":
                                    subTitle = "Recording";
                                    break;

                                case "LINEAR":
                                case "TVE_LINEAR":
                                    subTitle = "Live TV";
                                    break;

                                case "PURCHASED_VOD":
                                    subTitle = "Purchase";
                                    break;

                                case "TVE_VOD":
                                    subTitle = "TV GO";
                                    break;

                                case "VOD":
                                    subTitle = "On Demand";
                                    break;
                            }

                            console.log( data[i] );
                            results.push( { title:data[i].deviceName, subTitle:subTitle, showIcon:false } );
                        }

                    console.log( results );

                    if( results.length > 0 )
                    {
                        self._list.setData( results );
                        self._list.animate( { a:1, duration:500 } );

                        if( _x2._focus === self )
                            _x2.requestFocus( self._list );
                    }
                    else
                        self._noStream.animate( { a:1, duration:500 } );
                }
            };

            _x2._data.getAllDeviceList().then( onSuccess );

        return this;
    };

    StreamingDevicesScreen.prototype.layout = function()
    {
        if( this._numLoaded === 2 )
        {
            Screen.prototype.resourcesLoaded.call( this );

            this._title.setY( this.getHeaderBottom() + _x2.scaleValInt(50) );
            this._desc.setY( this._title.getY() + this._title.getH() );
            this._lowerSep.setY( this._desc.getY() + this._desc.getH() + _x2.scaleValInt(50) );

            var top = this._lowerSep.getY() + this._lowerSep.getH();
            var h   = _x2._config._screenH - Style._safeBottom - top;

            this._noStream.setY( top + (h - this._noStream.getH()) / 2 );

            this._distractor.setX( (_x2._config._screenW - this._distractor.getW() ) / 2 );
            this._distractor.setY(  top + (h - this._distractor.getH()) / 2 );

            if( this._loaded !== true )
                this._distractor.show();

            this._list.setH( h + Style._safeBottom );
            this._list.setY( top );
        }
    };

    return StreamingDevicesScreen;

})();
