// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.FormatScreen = ( function()
{
    "use strict";

    FormatScreen.prototype = new Screen();

    function FormatScreen(){}

    FormatScreen.prototype.entrySelected = function( key )
    {
        _x2._settings.setValue( Settings.Key.FORMAT, key );
    };

    FormatScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._list );

        this._speakTarget.setText( this.getBreadCrumb() + ". " + focus._speakStr + "." );
        this._speakTarget.setSpeechParams( "Press up or down to review options. Press OK to select.", focus._speakRole, this, false );
    };

    FormatScreen.prototype.init = function()
    {
        var params = {}, self = this;

        Screen.prototype.init.call( this );
        this._className     = "FormatScreen";
        this._telemetryName = "Format";
        this.setBreadCrumbs( "Settings", "Format" );

        var rows =
        [
            { title:"HD Preferred"         , key:"hd"  },
            { title:"All Available Formats", key:"all" }
        ];

        params.w        = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        params.h        = _x2._config._screenH;
        params.maxIndex = 1;
        params.rows     = rows;
        params.type     = RadioButtonRowWidget;
        params.obj      = new RadioButtonController( _x2._settings.getValue( Settings.Key.FORMAT ), function( key ) { self.entrySelected( key ) } );

        this._list = new ListWidget().init( params );
        this.addWidget( this._list, Style._safeLeft );

        window.ll( "tagScreen", "Format" );
        _x2._telemetry.sendScreenViewed( this._telemetryName );

        return this;
    };

    FormatScreen.prototype.resourcesLoaded = function()
    {
        Screen.prototype.resourcesLoaded.call( this );

        this._list.setY( this.getHeaderBottom() );
        this._list.setH( _x2._config._screenH - this._list.getY() );
    };

    return FormatScreen;

})();
