// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.CollectionCellWidget = ( function()
{
    "use strict";

    CollectionCellWidget.prototype = new ListRowWidget();

    function CollectionCellWidget(){}

    CollectionCellWidget.prototype.gotFocus = function()
    {
        var obj;

        ListRowWidget.prototype.gotFocus.call( this );

        obj          = this.getGlobalPos();
        obj.w        = this._focusW;
        obj.h        = this._focusH;
        obj.duration = 0;

        _x2._shadow.animate( { alpha:1, duration:ListWidget.SCROLL_TIME_STEP } );

        if( this._background && this._background.getA() )
            this._background.animate( { xScale:this._backgroundScale, yScale:this._backgroundScale, duration:ListWidget.SCROLL_TIME_STEP } );

        return this;
    };

    /**
     * @memberof CollectionCellWidget
     * @param {Object}  params
     * @param {Boolean} params.background - whether to render a background
     * @param {Number}  params.bgW        - Width in pixels
     * @param {Number}  params.bgH        - Height in pixels
     * @param {Number}  params.bgScale    - Scale factor
     * @param {Number}  params.bgX        - left offset in pixels
     * @param {Number}  params.bgY        - top offset in pixels
     * @param {Number}  params.h          - height
     * @param {Number}  params.obj        - Widget
     * @param {Number}  params.bgTextSize - Font size of background text
     * @returns {CollectionCellWidget}
     */
    CollectionCellWidget.prototype.init = function( params )
    {
        var self = this;

        ListRowWidget.prototype.init.call( this, params );
        this._className  = "CollectionCellWidget";
        this._selectable = true;
        this._listObj    = params.obj;

        if( params.background === true )
        {
            var textStyle = new Style( { color:"#e8e8e8", font:"light", fontSize:params.bgTextSize, textAlign :"center" } );

            var gradientParams    = {};
            gradientParams.w      = params.bgW;
            gradientParams.h      = params.bgH;
            gradientParams.type   = GradientWidget.Type.Linear;
            gradientParams.angle  = -224;
            gradientParams.alphas = [ { opacity:100    , location:0 }, { opacity:100    ,location: 100 } ];
            gradientParams.colors = [ { color:"#1C262F", location:0 }, { color:"#130917", location:100 } ];

            this._backgroundScale = params.bgScale;

            this._background = new GradientWidget().init( gradientParams );
            this.addWidget( this._background, params.bgX, params.bgY );

            this._defaultImage = new ImageWidget().init({w:params.bgW, h:params.bgH});
            this._defaultImage.setA(0);
            this.addWidget( this._defaultImage, params.bgX, params.bgY );

            this._backgroundText = new TextBlockWidget().init({style:textStyle, w:params.bgW, h:params.bgH});
            this.addWidget( this._backgroundText, params.bgX, params.bgY + params.bgH - ( params.bgTextSize * 4 ) );
        }

        this.setA( 0 );
        this.setW( this.getHeightUnfocused() );
        this.setH( params.h );

        this.setMouseDownListener( function() { self.processEvent( Host.KEY_ENTER, Host.KEY_PRESSED ); self.processEvent( Host.KEY_ENTER, Host.KEY_RELEASED ); } );

        return this;
    };

    CollectionCellWidget.prototype.hideBackground = function()
    {
        if( this._background )
        {
            this._background.setA(0);
            this._backgroundText.setA(0);
        }
    };

    CollectionCellWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        _x2._shadow.stopAnimation( Widget.ALPHA );
        _x2._shadow.setA( 0 );

        if( this._background && this._background.getA() )
        {
            this._background.stopAnimation( Widget.X_SCALE );
            this._background.stopAnimation( Widget.Y_SCALE );
            this._background.animate( { xScale:1, yScale:1, duration:ListWidget.SCROLL_TIME_STEP } );
        }
    };

    CollectionCellWidget.prototype.processEvent = function( val, type )
    {
        var retval, next, url, streamId, screen, self = this, localytics;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    if( this.isListBlocking() === false && this._data )
                    {
                        if( this._data instanceof Collection )
                        {
                            _x2._telemetry.watchPathPush( this._data.getTitle() );

                            next = new VodLeafScreen().init( { folder:this._data, path:_x2.getTopScreen().getBreadCrumb() } );
                        }
                        else if( this._data instanceof BrowseCollection )
                        {
                            _x2._telemetry.watchPathPush( this._data.getTitle() );

                            if( this._data.getCollectionRenderStyle() === "BROWSE" )
                                next = new VodLeafScreen().init( { folder:this._data, path:_x2.getTopScreen().getBreadCrumb() } );
                            else
                            {
                                screen = _x2.getTopScreen();
                                next   = new VodBranchScreen().init( this._data, screen ? screen.getBreadCrumb() : undefined );
                            }
                        }
                        else if( this._data instanceof ThumbnailEntity || this._data instanceof Entity )
                        {
                            switch( this._data.getType() )
                            {
                                case "Network":
                                    console.error( "If you find a way to hit this path, let Tony know how." );
                                    url = this._data.getStreamUrl();
                                    next = new VideoScreen().init( { url:url, mode:VideoScreen.Mode.LIVE, entity:this._data } );
                                    break;
                                case "s:Organization":
                                    localytics = "Channel Collection";
                                    _x2._telemetry.setWatchButton( "Search" ); // Search > Channel Collection
                                    var org = new Organization().init( { searchEntity:this._data } );
                                    next = new ChannelCollectionScreen().init( org );
                                    break;
                                case "SportsTeam":
                                case "s:SportsTeam":
                                    localytics = "Sports Team";
                                    next = new SportsTeamScreen().init( this._data );
                                    break;
                                case "s:Movie":
                                    localytics = "Movie";
                                    _x2._telemetry.setWatchButton( "Entity Movie Info" ); // Search > Entity Page > Movie Info
                                    next = new EntityScreen().init( { entity: this._data } );
                                    break;
                                case "s:TvSeries":
                                    localytics = "TV Show";
                                    _x2._telemetry.setWatchButton( "Entity Episodes" ); // Search > Entity Page > Episodes Tab
                                    next = new EntityScreen().init( { entity: this._data } );
                                    break;
                                case "s:CreativeWork": // Search > HBO > HBO Films
                                    // intentional fall into default
                                default: // "Program"
                                    var pt = this._data.getProgramType && this._data.getProgramType(); // VOD: getType() == "Program" but getProgramType() -> "Movie"
                                    if( pt && pt === "Movie"  )
                                    {
                                        localytics = "Movie";
                                        _x2._telemetry.setWatchButton( "Entity Movie Info" );
                                    }
                                    else // "MusicVideo", "Other", "SeriesMaster"
                                    {
                                        localytics = "TV Program";
                                        _x2._telemetry.setWatchButton( "Entity Info" );
                                    }
                                    next = new EntityScreen().init( { entity: this._data } );
                                    break;
                            }

                            if( this._data instanceof SearchEntity && localytics )
                                this._data._localyticsSearchType = localytics; // Localytics: Search.2

                            if( this._listObj && this._listObj.onSelected )
                                this._listObj.onSelected( this._data );
                        }
                        else if( this._data instanceof PurchaseGroup )
                        {
                            if( this._data.getEpisodeCount() === 1 )
                                next = new EntityScreen().init( { entity: this._data.getPurchases()[0] } );
                            else
                                next = new EpisodesScreen().init( { entity: this._data } );
                        }
                        else if( this._data instanceof Recording )
                        {
                            next = new EntityScreen().init( { entity: this._data } );

                            if( this._listObj && this._listObj.onSelected )
                                this._listObj.onSelected( this._data );
                        }
                        else if( this._data instanceof LinearShowing )
                        {
                            console.log( this._data );

                            var tabName;
                            var parent   = this;
                            var showing  = this._data;
                            var title    = showing.getTitle();
                            var start    = showing.getStartTime();
                            var end      = showing.getEndTime();
                            var duration = end - start;
                            var metrics  =
                            {
                                "Channel"  : showing._channel.getNumber(), // TODO: Do we always have one?
                                "Title"    : title,
                                "Start"    : start,
                                "End"      : end,
                                "Duration" : duration,
                                "Scheduled": showing.isRecorded() ? 'yes' : 'no'
                            };

                            // In order to find which Live TV tab we are in (Kids, Sports, Movies)
                            // We need to traverse up the hierarchy until we find a GuideViewFilterWidget
                            // e.g.
                            //     this._parent._parent._parent._parent._parent._parent._parent._parent._filter
                            while( parent )
                            {
                                parent = parent._parent;
                                if( parent instanceof GuideViewFilterWidget )
                                {
                                    tabName = parent.getLocalyticsTabName();
                                    break;
                                }
                            }

                            if( tabName )
                            {
                                window.ll( "tagEvent", "Gallery View: " +  tabName, metrics ); // Localytics 2.3.5
                                _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Gallery View Info: " + tabName + ": %o", metrics ) : Config.NOP();
                            }
                            else
                                console.error( "Unknown Live TV tab" );

                            if( this._data.isWatchable() === true )
                            {
                                url      = this._data._channel.getStreamUrl();
                                streamId = this._data._channel.getStreamId();

                                console.log( "URL = " + url );

                                if( url )
                                {
                                    var playVideo = function()
                                    {
                                        _x2._telemetry.watchSourceClassify( self._data );

                                        _x2.pushScreen( new VideoScreen().init( { url:url, mode:VideoScreen.Mode.LIVE, entity:self._data, streamId:streamId } ) );
                                    };

                                    if( _x2._pc.isEnabled() === true )
                                        _x2.checkParentalControls( this._data, undefined, this._data._channel ).then( playVideo );
                                    else
                                        playVideo();
                                }
                            }
                            else
                            {
                                _x2._telemetry.watchSourceClassify( this._data );

                                _x2.pushScreen( new EntityScreen().init( { entity:this._data } ));
                            }
                        }
                        else if( this._data instanceof VodShowing )
                        {
                            next = new EntityScreen().init( { entity: this._data } );

                            if( this._listObj && this._listObj.onSelected )
                                this._listObj.onSelected( this._data );
                        }
                        else if( this._data instanceof SportsTeam )
                        {
                            next = new SportsTeamScreen().init( this._data );
                        }
                        else
                        {
                            console.log( "Error: unknown entity type in CollectionCellWidget.processEvent" );
                            console.log( this._data );
                        }

                        if( next )
                            _x2.pushScreen( next );
                    }

                    retval = true;
                }
                break;

            case Host.KEY_LAST:
                if( _x2._telemetry.watchPathDepth() >= 2 )
                    _x2._telemetry.watchPathPop();
                // Intentional fall into default

            default:
                retval = ListRowWidget.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    CollectionCellWidget.prototype.setBackgroundText = function( val )
    {
        this._backgroundText.setText( val );
    };

    CollectionCellWidget.prototype.setHighlight = function( time )
    {
        var obj;

        obj          = this.getGlobalPos();
        obj.w        = this._focusW;
        obj.h        = this._focusH;
        obj.inner    = false;
        obj.duration = (time === undefined) ? ListWidget.SCROLL_TIME_STEP : time;

        _x2._hi.setPosition( obj );
    };

    CollectionCellWidget.prototype.showBackground = function()
    {
        if( this._background )
        {
            this._background.setA(1);
            this._backgroundText.setA(1);
        }
    };

    CollectionCellWidget.prototype.showDefaultImage = function( url )
    {
        this._defaultImage.setUrl( url );
        this._background.setA(0);
        this._defaultImage.setA(1);
        this._backgroundText.setA(1);
    };

    return CollectionCellWidget;

})();
