// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.Lg = (function()
{
    "use strict";

    Lg.prototype = new Host();

    function Lg()
    {
        if( window.innerHeight === 1080 )
            _x2._config.setSize( "1080" );
        else if( window.innerHeight === 720 )
            _x2._config.setSize( "720" );
    }

//     Lg.prototype.checkMinVersion = function()
//     {
//     };
//
//     Lg.prototype.checkNetworkConnection = function( interval )
//     {
//         console.log( "WARNING -> Network check not implemented." );
//     };

    Lg.prototype.exitApp = function( viaLastKey )
    {
        webOS.platformBack();
        window.close();
    };

    Lg.prototype.getCryptoType = function()
    {
        var retval = ( _x2._config._loadWebcrypto ) ? "webcrypto": "ecdh";
        var agent  = navigator.userAgent;

        if( agent.indexOf( "Chrome/38.0.2125.122" ) !== -1 )
        {
            console.log( "LG 2017 TV detetected." );
            retval = "ecdh";
        }
        else if( agent.indexOf( "Chrome/53.0.2785.34" ) !== -1 )
        {
            console.log( "LG 2018 TV detetected." );
            retval = ( _x2._config._loadWebcrypto ) ? "webcrypto" : "ecdh";
        }
        else
            console.error( "Unable to detect the LG model year, dafaulting to WebCrypto." );

        return retval;
    };

    Lg.prototype.getDrmType = function()
    {
        return "com.widevine.alpha";
    };

//     Lg.prototype.getBorderImageType = function()
//     {
//         return Lg.BORDER_IMAGE;
//     };
//
//     Lg.prototype.getDate = function()
//     {
//         return (new Date());
//     };
//
//     Lg.prototype.getImageSoftA = function()
//     {
//         return "softKeys/SoftKey_A.png";
//     };
//
//     Lg.prototype.getImageSoftB = function()
//     {
//         return "softKeys/SoftKey_B.png";
//     };
//
//     Lg.prototype.getImageSoftC = function()
//     {
//         return "softKeys/SoftKey_C.png";
//     };
//
//     Lg.prototype.getImageSoftD = function()
//     {
//         return "softKeys/SoftKey_D.png";
//     };

//     Lg.prototype.getType = function()
//     {
//         return this._type;
//     };

    Lg.prototype.getPlatformString = function()
    {
       return "lg";
    };

    Lg.prototype.getSecClientDeviceType = function()
    {
       return "LG2017";
    };

    Lg.prototype.getVideoPlayerClass = function( ext )
    {
        var retval;

        switch( ext )
        {
           case "mpd":
//                retval = LgPlayer;
                break;

            default:
                console.log( "ERROR -> no player type for ext = " + ext );
                break;
        }

        return retval;
    };

    Lg.prototype.init = function( callback )
    {
        this._type = Host.Type.LG;

console.log( "window.screen.availWidth  = " + window.screen.availWidth );
console.log( "window.screen.availHeight = " + window.screen.availHeight );
console.log( "window.innerWidth = " + window.innerWidth );
console.log( "window.innerHeight = " + window.innerHeight );
console.log( "document.body.clientWidth = " + document.body.clientWidth );
console.log( "document.body.clientHeight = " + document.body.clientHeight );

        document.body.style.backgroundColor = "#000000";



//         Lg.tvinputdevice.registerKey( "0"                  );
//         Lg.tvinputdevice.registerKey( "1"                  );
//         Lg.tvinputdevice.registerKey( "2"                  );
//         Lg.tvinputdevice.registerKey( "3"                  );
//         Lg.tvinputdevice.registerKey( "4"                  );
//         Lg.tvinputdevice.registerKey( "5"                  );
//         Lg.tvinputdevice.registerKey( "6"                  );
//         Lg.tvinputdevice.registerKey( "7"                  );
//         Lg.tvinputdevice.registerKey( "8"                  );
//         Lg.tvinputdevice.registerKey( "9"                  );
//         Lg.tvinputdevice.registerKey( "ChannelUp"          );
//         Lg.tvinputdevice.registerKey( "ChannelDown"        );
//         Lg.tvinputdevice.registerKey( "ColorF0Red"         );
//         Lg.tvinputdevice.registerKey( "ColorF1Green"       );
//         Lg.tvinputdevice.registerKey( "ColorF2Yellow"      );
//         Lg.tvinputdevice.registerKey( "ColorF3Blue"        );
//         Lg.tvinputdevice.registerKey( "Exit"               );
//         Lg.tvinputdevice.registerKey( "Info"               );
//         Lg.tvinputdevice.registerKey( "MediaFastForward"   );
//         Lg.tvinputdevice.registerKey( "MediaPause"         );
//         Lg.tvinputdevice.registerKey( "MediaPlay"          );
//         Lg.tvinputdevice.registerKey( "MediaPlayPause"     );
//         Lg.tvinputdevice.registerKey( "MediaRecord"        );
//         Lg.tvinputdevice.registerKey( "MediaRewind"        );
//         Lg.tvinputdevice.registerKey( "MediaStop"          );
//         Lg.tvinputdevice.registerKey( "MediaTrackNext"     );
//         Lg.tvinputdevice.registerKey( "MediaTrackPrevious" );
//         Lg.tvinputdevice.registerKey( "Minus"              );
//
//         console.log( "################################################################################################" );
//
//
        var onScriptLoaded  = function()
        {
            var numReady    = 0;
            var onCallbacks = function()
            {
                if( ++numReady === 3 )
                {
                    console.log( "LGDUID   = " + _x2._config._mac );
                    console.log( "FIRMWARE = " + _x2._config._host._osVersion );
                    console.log( "NETWORK  = " + _x2._config._host.getNetworkType() + ", " + _x2._config._host.getSsid() );
                    console.log( "PRODUCT  = " + _x2._config._host._product );

                    callback();
                }
            };

            var onId  = function( inResponse )
            {
                var list = inResponse.idList;

                for( var i = 0; i < list.length; i++ )
                    if( list[i].idType === "LGUDID" )
                        {
                            _x2._config._mac = list[i].idValue;
                            break;
                        }

                onCallbacks();
            };

            var onIdError  = function( inError )
            {
                console.error( inError );
                onCallbacks();
            };

            var idObj =
            {
                method     : "deviceid/getIDs",
                parameters : { "idType": ["LGUDID"] },
                onSuccess  : onId,
                onFailure  : onIdError
            };

            webOS.service.request( "luna://com.webos.service.sm", idObj );

            var onDeviceInfo = function( info )
            {
                _x2._config._host._make      = "LG";
                _x2._config._host._product   = info.modelName;
                _x2._config._host._osName    = "webOS";
                _x2._config._host._osVersion = info.version;

                onCallbacks();
            };

            webOS.deviceInfo( onDeviceInfo );

            var onNetwork = function( data )
            {
                var type;

                if( data )
                {
                    if( data.wired.state === "connected" )
                        type = Host.NetworkType.WIRED;

                    if( data.wifi.state === "connected" )
                    {
                        type = Host.NetworkType.WIFI;
                        _x2._config._host.setSsid( data.wifi.ssid );
                    }
                }

                _x2._config._host.setNetworkType( type );

                onCallbacks();
            };

            var onNetworkError = function( e )
            {
                console.error( e );

                onCallbacks();
            };

            var networkObj =
            {
                method    : "getStatus",
                onSuccess : onNetwork,
                onFailure : onNetworkError
            };

            webOS.service.request( "luna://com.palm.connectionmanager", networkObj );
        };

        _x2.getScript( "lib/webOSTVjs-1.0.0/webOSTV.js", function(){ onScriptLoaded(); } );

// //             // see if this is the first run of the application or if it has been deleted
// //
// //             var beenRun = _host.getLocalStorage( FILE_HAS_RUN );
// //
// //             console.log( beenRun );
// //
// //             _beenRun = beenRun["beenRun"];
// //
// //             console.log( "=========>>>>>>>>>>>>>>>>> beenRun = " + _beenRun )
// //
// //             if( val === undefined )
// //             {
// //                 beenRun["beenRun"] = true;
// //                 _host.setLocalStorage( FILE_HAS_RUN, beenRun );
// //             }
//
    };

    Lg.prototype.mapKey = function( event, which )
    {
        var val = event.which;

        switch( val )
        {
            case 13:
                val = Host.KEY_ENTER;
                break;

            case 19:
                val = Host.KEY_PAUSE;
                break;

            case 33:
                val = Host.KEY_PAGE_UP;
                break;

            case 34:
                val = Host.KEY_PAGE_DN;
                break;

            case 37:
                val = Host.KEY_LEFT;
                break;

            case 38:
                val = Host.KEY_UP;
                break;

            case 39:
                val = Host.KEY_RIGHT;
                break;

            case 40:
                val = Host.KEY_DOWN;
                break;

            case 48:
                val = Host.KEY_0;
                break;

            case 49:
                val = Host.KEY_1;
                break;

            case 50:
                val = Host.KEY_2;
                break;

            case 51:
                val = Host.KEY_3;
                break;

            case 52:
                val = Host.KEY_4;
                break;

            case 53:
                val = Host.KEY_5;
                break;

            case 54:
                val = Host.KEY_6;
                break;

            case 55:
                val = Host.KEY_7;
                break;

            case 56:
                val = Host.KEY_8;
                break;

            case 57:
                val = Host.KEY_9;
                break;

            case 189:
                val = Host.KEY_HUD;
                break;

            case 403:
                val = Host.KEY_SOFT_A;
                break;

            case 404:
                val = Host.KEY_SOFT_B;
                break;

            case 405:
                val = Host.KEY_SOFT_C;
                break;

            case 406:
                val = Host.KEY_SOFT_D;
                break;

            case 412:
                val = Host.KEY_RW;
                break;

            case 413:
                val = Host.KEY_STOP;
                break;

            case 415:
                val = Host.KEY_PLAY;
                break;

//             case 416:
//                 val = Host.KEY_RECORD;
//                 break;

            case 417:
                val = Host.KEY_FF;
                break;

//             case 427:
//                 val = Host.KEY_CH_UP;
//                 break;
//
//             case 428:
//                 val = Host.KEY_CH_DOWN;
//                 break;

            case 457:
                val = Host.KEY_INFO;
                break;

            case 461:
                val = Host.KEY_LAST;
                break;

            case 1008:  // CC
                val = Host.KEY_CC;
                break;

            case 1028:  // SAP
                val = Host.KEY_SAP;
                break;

            case 2016:  // Ch Up
                val = Host.KEY_CH_UP;
                break;

            case 2022:  // Ch Down
                val = Host.KEY_CH_DOWN;
                break;

//             case 10009:
//                 val = Host.KEY_LAST;
//                 break;
//
//             case 10182:
//                 val = Host.KEY_QUIT;
//                 break;
//
//             case 10252:  // play/pause
//                 val = Host.KEY_PLAY_PAUSE;
//                 break;

//             case 10232: // prev track
//                 val = ;
//                 break;

//             case 10233: // next track
//                 val = ;
//                 break;
        }

        return Host.prototype.mapKey.call( this, event, val );
    };

//     Lg.prototype.setScreenSaverActive = function( active )
//     {
//         if( active === true )
//         {
//             console.log( "##### ENABLE SCREEN SAVER #########################################" );
//             webapis.appcommon.setScreenSaver( webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_ON );
//         }
//         else if( active === false )
//         {
//             console.log( "##### DISABLE SCREEN SAVER #########################################" );
//             webapis.appcommon.setScreenSaver( webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_OFF );
//         }
//         else
//             console.log( "ERROR -> invalid parameter for setScreenSaverActive = " + active );
//     };

    return Lg;

})();
