// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.SixteenNineGridRowWidget = (function()
{
    "use strict";

    SixteenNineGridRowWidget.prototype = new VodGridRowWidget();

    function SixteenNineGridRowWidget(){}

    SixteenNineGridRowWidget.prototype.init = function( params )
    {
        params.sep = false;
        ListRowWidget.prototype.init.call( this, params );
        this._className = "SixteenNineGridRowWidget";

        this.initCells( params, XtvApi.EntityRenderStyle["16X9_PROGRAM"] );
        this.setA( 0 );

        return this;
    };

    SixteenNineGridRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 380 );
    };

    return SixteenNineGridRowWidget;

})();
