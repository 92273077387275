// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ShowingRowWidget = (function()
{
    "use strict";

    ShowingRowWidget.prototype = new ListRowWidget();

    function ShowingRowWidget(){}

    ShowingRowWidget.prototype.gotFocus = function( lostFocus, newScreen )
    {
        var str;

        ListRowWidget.prototype.gotFocus.call( this, lostFocus );
        this._playIcon.setA(0);
        this._playIconFocus.setA(1);
        this._hasFocus = true;

        str = this._subTitle.getText();
        str = str ? str + ". " : "";
        str = this._speakGlyphStr ? str + this._speakGlyphStr : str;

        if( newScreen !== true )
            str += "Press OK to play. ";

        this._title.setSpeechParams( str, undefined, this, newScreen );

        return this;
    };

    ShowingRowWidget.prototype.init = function(params)
    {
        ListRowWidget.prototype.init.call( this, params );

        this._className = "ShowingRowWidget";

        var self = this;
        var titleStyle    = new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap" } );
        var subTitleStyle = new Style( { color:"#a3a3a3", font:"light", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );
        var infoStyle     = new Style( { color:"#a3a3a3", font: "medium" ,  fontSize: _x2.scaleValInt( 24 ), whiteSpace: "nowrap" } );

        var xCords = [ _x2.scaleValInt(128) ];
        var yCords = [ _x2.scaleValInt(14), _x2.scaleValInt(30), _x2.scaleValInt(54), _x2.scaleValInt(90) ];

        this._networkIcon   = new ImageWidget().init({ onError:function(){ self.imageError()}, onChange:function(){ self.imageLoaded() }});
        this._playIcon      = new ImageWidget().init({ url:_x2._config._imageRoot + "Play-icon-min.png", onChange:function(){ self.playIconLoaded() }});
        this._playIconFocus = new ImageWidget().init({ url:_x2._config._imageRoot + "Play-focus-state-icon-min.png" });
        this._title         = new StringWidget().init({ style:titleStyle, text:"" });
        this._subTitle      = new StringWidget().init({ style:subTitleStyle, text:"" });
        this._infoGlyphs    = new StringWidget().init( { style: infoStyle } );

        this.addWidget( this._networkIcon, 0, yCords[1] );
        this.addWidget( this._playIcon );
        this.addWidget( this._playIconFocus );
        this.addWidget( this._title     , xCords[0], yCords[0] );
        this.addWidget( this._subTitle  , xCords[0], yCords[2] );
        this.addWidget( this._infoGlyphs, xCords[0], yCords[3] );

        this._playIcon.setA(0);
        this._playIconFocus.setA(0);
        this._hasFocus = false;

        return this;
    };

    ShowingRowWidget.prototype.imageLoaded = function()
    {
        this._networkIcon.setW( undefined );
        this._networkIcon.setH( undefined );

        var maxW   = _x2.scaleValInt( 96 );
        var maxH   = _x2.scaleValInt( 72 );
        var aspect = maxW / maxH;

        if( aspect > this._networkIcon.getW() / this._networkIcon.getH() )
            this._networkIcon.setH( maxH );
        else
            this._networkIcon.setW( maxW );

        //center the image in the cell
        this._networkIcon.setY( this.getHeightUnfocused()/ 2 - this._networkIcon.getH() / 2 );
        this._networkIcon.setA( 1 );
    };

    ShowingRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 140 );
    };

    ShowingRowWidget.prototype.imageError = function()
    {
        this._networkIcon.setA(0);
    };

    ShowingRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._playIcon.setA(1);
        this._playIconFocus.setA(0);
        this._hasFocus = false;
    };

    ShowingRowWidget.prototype.playIconLoaded = function()
    {
        var y = this.getHeightUnfocused() / 2 - this._playIcon.getH() / 2;
        var x = this.getW() - this._playIcon.getW();

        this._playIcon.setY(y);
        this._playIcon.setX(x);
        this._playIconFocus.setY(y);
        this._playIconFocus.setX(x);

        if( ! this._hasFocus ) //NOTE: alpha already manipulated in gotFocus
            this._playIcon.setA(1);
    };

    ShowingRowWidget.prototype.processEvent = function(val, type)
    {
        var retval, url, mediaId, paid, programId, hasSap, hasDvs, streamId, assetId, providerId, mediaGuid, playVideo, mode, self = this;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED )
                {
                    var showing = this._data.showing;

                    if( showing instanceof Recording )
                    {
                        mode      = VideoScreen.Mode.DVR;
                        url       = this._data.showing.getStreamUrl();
                        mediaId   = this._data.showing.getMediaId();
                        programId = this._data.showing.getProgramId();
                        hasSap    = this._data.showing.isSap();
                        hasDvs    = this._data.showing.isDvs();
                    }
                    else if( showing instanceof LinearShowing )
                    {
                        mode     = VideoScreen.Mode.LIVE;
                        url      = this._data.showing.getChannel().getStreamUrl();
                        streamId = this._data.showing.getChannel().getStreamId();
                        hasSap   = this._data.showing.isSap();
                        hasDvs   = this._data.showing.isDvs();
                    }
                    else if( showing.getStreamUrl )
                    {
                        mode       = VideoScreen.Mode.VOD;
                        url        = this._data.showing.getStreamUrl();
                        assetId    = this._data.showing.getAssetId();
                        providerId = this._data.showing.getProviderId();
                        mediaGuid  = this._data.showing.getMediaGuid();
                        mediaId    = this._data.showing.getMediaId();
                        programId  = this._data.showing.getProgramId();
                        paid       = this._data.showing.getPaid();
                        hasSap     = this._data.showing.isSap();
                        hasDvs     = false;  // TODO: not supported, nedd API when it is
                    }

                    //add entity reference to showing if not already set so that VideoScreen will have access to Entity data.
                    if( showing.getEntity() === undefined )
                        showing.setEntity( this._data.entity );

                    if( url )
                    {
                        playVideo = function()
                        {
                            var obj =
                            {
                                url:url,
                                mediaId:mediaId,
                                programId:programId,
                                mode:mode,
                                entity:showing,
                                paid:paid,
                                hasSap:hasSap,
                                hasDvs:hasDvs,
                                streamId:streamId,
                                assetId:assetId,
                                providerId:providerId,
                                mediaGuid:mediaGuid
                            };

                            _x2.pushScreen( new VideoScreen().init( obj ) );
                        };

                        if( _x2._pc.isEnabled() === true )
                            _x2.checkParentalControls( this._data.showing, this._data.entity ).then( playVideo );
                        else
                            playVideo();
                    }
                    else
                    {
                        var error = new ApiError( { local:"No stream available" } );
                        _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.ENTITY } ) );
                        console.error( error.toString() );
                    }

                    retval = true;
                }
                break;

            default:
                retval = ListRowWidget.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    ShowingRowWidget.prototype.setData = function( data )
    {
        if( data !== undefined )
        {
            this._data          = data;
            this._speakGlyphStr = "";

            var rating, locked, info, glyphs = "";

            var showing  = data.showing;
            var len      = showing.getDuration();
            var ends     = showing.getVodWindowExpireTime ? showing.getVodWindowExpireTime() : 0;
            var provider = ( showing instanceof LinearShowing ) ? showing.getChannel() : showing.getContentProvider();

            if( showing instanceof Recording )
            {
                this._title.setText( "DVR" );

                info = "Recording added " + _x2.createDateTimeStr( showing.getRecordedOnDate() );
            }
            else if( showing instanceof LinearShowing )
            {
                this._title.setText( "On Now" );

                var channel = showing.getChannel();
                if( channel )
                    info = channel.getNumber() + " " + channel.getCallSign()
            }
            else if( showing instanceof Purchase )
            {
                var transaction = showing.getTransactionDetails();
                var purchasedOn = transaction.getPurchasedOnDate();

                this._title.setText( "Purchased" );

                if( len )
                    info = len + "min";

                if( purchasedOn )
                {
                    if( info.length )
                        info += ", ";
                    info += "Purchased " + _x2.createDateStr( purchasedOn );
                }
            }
            else if( showing instanceof Rental )
            {
                var expires = showing.getExpires();

                this._title.setText( "Rented" );

                if( len )
                    info = len + "min";

                if( expires )
                {
                    if( info.length )
                        info += ", ";
                    info += "Rental " + _x2.createExpiresString( expires );
                }
            }
            else
            {
                this._title.setText( "On Demand" );

                if( len )
                    info = len + "min";

                if( ends )
                {
                    if( info )
                        info += ", ";
                    info += "Ends " + ( ends.getMonth() + 1 ) + '/' + ends.getDate();
                }
            }

            if( info )
                this._subTitle.setText( info );

            if( provider )
                this._networkIcon.setUrl( provider.getLogoUrl() );

            if( showing.getRating() )
                rating = showing.getRating();

            locked = _x2._pc.isRatingLocked( rating );

            if( locked === false )
                locked = _x2._pc.isTitleLocked( showing.getEntityId() );

            if( locked )
            {
                glyphs += Strings.Glyphs.LOCKED + " ";
                this._speakGlyphStr += "Locked. ";
            }

            if( showing.isHD() )
            {
                glyphs += Strings.Glyphs.HD + " ";
                this._speakGlyphStr += "HD. ";
            }

            if( showing.isClosedCaptioned() )
            {
                glyphs += Strings.Glyphs.CC + " ";
                this._speakGlyphStr += "Closed captioning available. ";
            }

            if( showing.isSap() )
            {
                glyphs += Strings.Glyphs.SAP + " ";
                this._speakGlyphStr += "Secondary audio available. ";
            }

            if( rating )
            {
                glyphs += Strings.Glyphs[rating];
                this._speakGlyphStr += "Rating " + rating + ". ";
            }

            this._infoGlyphs.setText( glyphs );

            this.setA(1);
        }
        else
            this.setA(0);
    };

    return ShowingRowWidget;
})();
