// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * This class encapsulates data from XTVAPI getAllFavorites which is a list of object IDs the user has favorite-ed.
 * Not be be confused with a browse favorites collection which contains a list of favorite-ed objects.
 */
window.Favorites = ( function()
{
    "use strict";

    function Favorites(){}

    /**
     * Initializer
     * @memberof Favorites
     * @param   {Object}        data
     * @param   {Object}        data._links
     * @returns {Favorites}
     */
    Favorites.prototype.init = function( data )
    {
        var i, programs, channels, teams;

        this._programs = [];
        this._channels = [];
        this._teams    = [];

        programs = data._links['programs'];
        channels = data._links['channels'];
        teams    = data._links['sportsTeams'];

        //NOTE: data can contain an array OR an object in the case of a single entity. Convert to array so we'll catch it below.

        if( programs && programs.href )
            programs = [{href:programs.href}];

        if( channels && channels.href )
            channels = [{href:channels.href}];

        if( teams && teams.href )
            teams = [{href:teams.href}];

        if( programs && programs.length )
            for( i=0; i<programs.length; i++ )
                this._programs.push( XtvApi.trimPath( programs[i].href ) );

        if( channels && channels.length )
            for( i=0; i<channels.length; i++ )
                this._channels.push( channels[i].href );

        if( teams && teams.length )
            for( i=0; i<teams.length; i++ )
                this._teams.push( XtvApi.trimPath( teams[i].href ) );

        return this;
    };

    Favorites.prototype.addFavorite = function( object )
    {
        return this.setFavorite( object, true );
    };

    Favorites.prototype.removeFavorite = function( object )
    {
        return this.setFavorite( object, false );
    };

    Favorites.prototype.setFavorite = function( object, add )
    {
        var link, promise, program;

        if( object.getProgram )
        {
            program = object.getProgram();

            if( program )
                link = program.getSelfLink();
        }

        if( link === undefined )
            link = object.getSelfLink();

        if( link )
        {
            link = XtvApi.trimPath( link );
            link = XtvApi.replaceUrlParams(link, {});

            if( object instanceof Channel )
                promise = ( add ? _x2._data.addFavoriteChannel( link ) : _x2._data.removeFavoriteChannel( link ) );
            else
                promise = ( add ? _x2._data.addFavoriteItem( link ) : _x2._data.removeFavoriteItem( link ) ) ;
        }
        else
            promise = Promise.reject("Failed to set favorite (no self link)");

        return promise;
    };

    Favorites.prototype.hasChannelFavorites = function()
    {
        return this._channels && this._channels.length > 0;
    };

    Favorites.prototype.isFavorite = function( object )
    {
        var i, id, group=[];

        if( object instanceof Entity )
        {
            group = this._programs;
            id    = object.getSelfLink();
        }
        else if( object instanceof Channel )
        {
            group = this._channels;
            id    = object.getSelfLink();

        }
        else if( object instanceof SportsTeam )
        {
            group = this._teams;
            id    = object.getSelfLink();
        }

        if( id && group && group.length )
        {
            id = XtvApi.trimPath( id );

            for( i=0; i<group.length; i++ )
                if( id === group[i] )
                    return true;
        }

        return false;
    };

    return Favorites;

})();
