// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.Organization = (function()
{
    "use strict";

    function Organization(){}
    
    Organization.prototype.init = function( params )
    {
        this._name = params.name; 
        this._searchEntity = params.searchEntity;
        
        return this;
    };
    
    Organization.prototype.loadData = function()
    {
        var self = this;

        var resolver = function( resolve, reject )
        {
            var success = function( response )
            {
                self._data = JSON.parse(response.data);
                resolve( self );
            };

            var error = function( error )
            {
                reject( error );
            };

            var entity = self._searchEntity.getEntity();
            if( entity )
            {
                var url = entity._links.search.href.replace( /(\.\.\/)/g, '' );
                _x2._network.ajax({type: "GET", url: _x2._data._host + url, headers: [_x2._data._authHeader], accepts:Network.Accepts.xtv}).then(success).catch(error);
            }
            else
                return Promise.reject( new ApiError().init( { local:"Organization.loadData: Missing entity"} ) );
        };

        return new Promise( resolver );
    };
    
    Organization.prototype.getChannels = function()
    {
        if( ! this._objects )
        {
            var list = this._data._embedded.results;

            if( list && list.length )
            {
                this._objects = [];
                //use SearchEntity (that's what the data is) and think about how to make this cleaner later.
                for( var i=0; i<list.length; i++ )
                {
                    this._objects.push( new SearchEntity().init(list[i]) );
                }
            }
        }

        return this._objects;
    };
    
    Organization.prototype.getTitle = function()
    {
        if( this._searchEntity )
        {
            return this._searchEntity.getTitle();
        }
    };
    
    return Organization;
})();
