// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 * 
 * Maps to XTV "Review"
 */
window.Review = (function()
{
    "use strict";

    function Review(){}

    /**
     * Initialize this object
     * @memberOf Review
     * @param {Object} data - XTV derived JSON object
     * @return {Review}
     */
    Review.prototype.init = function( data )
    {
        this._data = data;
        return this;
    };

    /**
     * Return true if the critic summary is marked as certified
     * @memberOf Review
     * @return {Boolean}
     */
    Review.prototype.getCriticSummaryCertified = function()
    {
        return this._data.attributes.criticSummaryCertified;
    };

    /**
     * Get the number of critic reviews
     * @memberOf Review
     * @return {Number}
     */
    Review.prototype.getCriticSummaryCount = function()
    {
        return this._data.attributes.criticSummaryCount;
    };

    /**
     * Return true if the critic score is 'rotten'
     * @memberOf Review
     * @return {Boolean}
     */
    Review.prototype.getCriticSummaryRotten = function()
    {
        return this._data.attributes.criticSummaryRotten;
    };

    /**
     * Get the critic score
     * @memberOf Review
     * @return {Number}
     */
    Review.prototype.getCriticSummaryScore = function()
    {
        return this._data.attributes.criticSummaryScore;
    };

    /**
     * Get the Common Sense Media score
     */
    Review.prototype.getCommonSenseMediaScore = function()
    {
        return this._data.attributes.value;
    };

    /**
     * Return true if the fan summary is marked as certified
     * @memberOf Review
     * @return {Boolean}
     */
    Review.prototype.getFanSummaryCertified = function()
    {
        return this._data.attributes.fanSummaryCertified;
    };

    /**
     * Get the number of fan reviews
     * @memberOf Review
     * @return {Number}
     */
    Review.prototype.getFanSummaryCount = function()
    {
        return this._data.attributes.fanSummaryCount;
    };

    /**
     * Return true if the fan score is 'rotten'
     * @memberOf Review
     * @return {Boolean}
     */
    Review.prototype.getFanSummaryRotten = function()
    {
        return this._data.attributes.fanSummaryRotten;
    };

    /**
     * Get the fan summary score
     * @memberOf Review
     * @return {Number}
     */
    Review.prototype.getFanSummaryScore = function()
    {
        return this._data.attributes.fanSummaryScore;
    };

    /**
     * Get the review type
     * @memberOf Review
     * @returns {String}
     */
    Review.prototype.getProvider = function()
    {
        return this._data.provider;
    };

    /**
     * Return true if there are valid ratings in this review
     * @return {boolean}
     */
    Review.prototype.isValid = function()
    {
        var retval = true;
        
        if( isNaN( this.getCriticSummaryScore() ) )
            retval = false;
        else if( isNaN( this.getFanSummaryScore() ))
            retval = false;
        
        return retval;
    };
    
    return Review;
    
})();
