// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.StaticListWidget = ( function()
{
    "use strict";

    StaticListWidget.prototype = new Widget();

    StaticListWidget.SCROLL_RATE = 1500 / 1000;

    function StaticListWidget(){}

    StaticListWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        return _x2.requestFocus( this._widgets[this._index], newScreen );
    };

    /**
     * Initializer
     * @memberof StaticListWidget
     * @param   {Object}        params
     * @param   {Number}        params.h              - Height of the list
     * @param   {Array}         params.rows           - Array of data objects to display
     * @param   {ListRowWidget} params.types          - The specific type of ListRowWidget for each row
     * @param   {Number}        params.w              - Width of the list
     * @param   {Number}       [params.max]           - Max number of items to display in each row
     * @param   {function}     [params.indexListener] - callback function for selected index change
     * @returns {StaticListWidget}
     */
    StaticListWidget.prototype.init = function( params )
    {
        var i, widget, y = 0, self = this;

        Widget.prototype.init.call( this );
        this._className      = "StaticListWidget";
        this._selectable     = true;
        this._types          = params.types;
        this._indexListener  = params.indexListener;
        this._max            = params.max;
        this._widgets        = [];
        this._rows           = [];
        this._index          = 0;
        this._blocked        = false;
        this._queue          = [];
        this._container      = new Widget().init();
        this._lastIndexAdded = 0;
        this.setW( params.w );
        this.setH( params.h );

        for( i = 0; i < params.rows.length; i++ )
            if( params.rows[i].getEntities().length > 0 )
                this._rows.push( params.rows[i] );
            else
                console.warn( "EMPTY ROW" );

        for( i = 0; i < this._rows.length; i++ )
            if( this._rows[i].getEntities().length > 0 )
            {
                widget = new params.types[i]().init( { w:params.w - Style._safeLeft - Style._safeRight, data:this._rows[i], max:this._max } );
                this._widgets.push( widget );
                this._container.addWidget( widget, Style._safeLeft, y );
                y += widget.getHeightUnfocused();

                if( y > params.h )
                    break;

                this._lastIndexAdded++;
            }

        this._container.setH( y );

        this._scroll = new ScrollWidget().init( { w:params.w, h:params.h, widget:this._container } );
        this.addWidget( this._scroll );

        this.setMouseWheelListener( function( event ) { if( self._blocked === false ) self.processEvent( ( event.deltaY > 0 ) ? Host.KEY_DOWN : Host.KEY_UP, Host.KEY_PRESSED ); } );

        return this;
    };

    StaticListWidget.prototype.getMaxIndex = function()
    {
        return this._rows !== undefined ? this._rows.length : 0;
    };

    StaticListWidget.prototype.getSelectedIndex = function()
    {
        return this._index;
    };

    StaticListWidget.prototype.getSelectedWidget = function()
    {
        return this._widgets[this._index];
    };

    StaticListWidget.prototype.processEvent = function( val, type )
    {
        var retval = true, self = this, onEnd, currY, nextY, duration;

        onEnd = function()
        {
            _x2.requestFocus( self._widgets[self._index] );
            self.processQueue();
        };

        switch( val )
        {
            case Host.KEY_DOWN:
                if( this._blocked === false )
                {
                    if( this._index < this._rows.length - 1 )
                    {
                        if( type === Host.KEY_PRESSED )
                        {
                            if( this._lastIndexAdded < this._rows.length - 1 )
                            {
                                console.log( "Add a new row, last added = " + this._lastIndexAdded );

                                var widget = this._widgets[this._lastIndexAdded++];
                                var w      = this._scroll.getW() - Style._safeLeft - Style._safeRight;
                                var y      = widget.getY() + widget.getH();

                                widget = new this._types[this._lastIndexAdded]().init( { w:w, data:this._rows[this._lastIndexAdded], max:this._max } );
                                this._widgets.push( widget );
                                this._container.addWidget( widget, Style._safeLeft, y );
                                y += widget.getHeightUnfocused();

                                widget.patchWidget();

                                this._scroll.setH( y );
                            }

                            this._index++;
                            this._blocked = true;

                            if( this._indexListener )
                                this._indexListener( this._index );

                            currY    = this._container.getY();
                            nextY    = this._widgets[this._index].getY();
                            duration = (nextY + currY) / StaticListWidget.SCROLL_RATE;

                            this._scroll.setScroll( { y:-nextY, duration:duration, onEnd:onEnd } );
                            _x2._hi.fadeOut( 0 );
                        }
                        else
                            this.processQueue();
                    }
                    else if( this._queue.length > 0 )
                        this.processQueue();
                    else
                        retval = Screen.prototype.processEvent.call( this, val, type );
                }
                else
                    this._queue.push( [ val, type ] );
                break;

            case Host.KEY_UP:
                if( this._blocked === false )
                {
                    if( this._index > 0 )
                    {
                        if( type === Host.KEY_PRESSED )
                        {
                            this._index--;
                            this._blocked = true;

                            if( this._indexListener )
                                this._indexListener( this._index );

                            currY    = this._container.getY();
                            nextY    = this._widgets[this._index].getY();
                            duration = -(nextY + currY) / StaticListWidget.SCROLL_RATE;

                            this._scroll.setScroll( { y:-nextY, duration:duration, onEnd:onEnd } );
                            _x2._hi.fadeOut( 0 );
                        }
                        else
                            this.processQueue();
                    }
                    else if( this._queue.length > 0 )
                        this.processQueue();
                    else
                        retval = Screen.prototype.processEvent.call( this, val, type );
                }
                else
                    this._queue.push( [ val, type ] );
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    StaticListWidget.prototype.processQueue = function()
    {
        var key = this._queue.shift(), self = this;

        this._blocked = false;

        if( key !== undefined )
            setTimeout( function() { self.processEvent( key[0], key[1] ); }, 1 );
    };

    StaticListWidget.prototype.setJumpToIndex = function( jumpIdx )
    {
        var i, baseY, maxY, self = this;
        var width  = this.getW() - Style._safeLeft - Style._safeRight;
        var height = this.getH();

        var addWidget = function( idx )
        {
            var y = idx > 0 ? self._widgets[idx-1].getY() + self._widgets[idx-1].getHeightUnfocused() : 0;
            var widget = new self._types[idx]().init( { w: width, data: self._rows[idx] } );
            self._widgets.push( widget );
            self._container.addWidget( widget, Style._safeLeft, y );
            self._lastIndexAdded++;
            widget.patchWidget();

            return widget;
        };

        //add widgets from lastIndexAdded up to and including jumpIdx
        for( i = this._lastIndexAdded + 1; i < jumpIdx+1; i++ )
            addWidget(i);

        //get the Y position of the widget at jumpIdx and set the max Y of the frame
        baseY = this._widgets[jumpIdx].getY();
        maxY  = baseY + height;

        //add widgets starting from jumpIdx+1 as necessary until we fill the frame
        for( i = jumpIdx+1; i < this._rows.length; i++ )
        {
            baseY += ( this._widgets[i] ? this._widgets[i] : addWidget( i ) ).getHeightUnfocused();
            if( baseY >= maxY )
                break;
        }

        this._index = jumpIdx;
        this._container.setH( baseY );
        this._scroll.setScroll( { y:-this._widgets[this._index].getY(), duration:0 } );
    };

    return StaticListWidget;

})();
