// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 * Language Strings
 */
window.Strings = (function()
{
    "use strict";

    Strings.Language =
    {
        ENGLISH : 0,
        SPANISH : 1
    };

    Strings.Glyphs = Object.freeze
    ({
        "ALERT"         : "\uf035",
        "CC"            : "\uf028",
        "CHECK"         : "\uf031",
        "CHEV_LEFT"     : "\uf069",
        "CHEV_RIGHT"    : "\uf06a",
        "DOLBY"         : "\uf02c",
        "DOLBY51"       : "\uf02d",
        "DOLBYA"        : "\uf02e",
        "DOWNLOAD"      : "\uf03a",
        "DV"            : "\uf02b",
        "ESP"           : "\uf02a",
        "G"             : "\uf007",
        "HD"            : "\uf024",
        "HEART"         : "\uf033",
        "KEY"           : "\uf032",
        "LOCKED"        : "\uf030",
        "NC-17"         : "\uf00b",
        "NR"            : "\uf00c",
        "PG"            : "\uf008",
        "PG-13"         : "\uf009",
        "R"             : "\uf00a",
        "RECORD"        : "\uf03d",
        "RECORD_CANCEL" : "\uf03f",
        "SAP"           : "\uf029",
        "SD"            : "\uf023",
        "SERIES"        : "\uf03e",
        "SERIES_CANCEL" : "\uf040",
        "STAR"          : "\uf034",
        "TV-14"         : "\uf004",
        "TV-G"          : "\uf002",
        "TV-MA"         : "\uf005",
        "TV-NR"         : "\uf006",
        "TV-PG"         : "\uf003",
        "TV-Y"          : "\uf000",
        "TV-Y7"         : "\uf001",
        "UHD"           : "\uf025",
        "UNLOCKED"      : "\uf02f"
    });

    function Strings(){}

    /**
     * @memberof Strings
     * @param {String} language
     * @returns {Promise}
     */
    Strings.prototype.load = function( language )
    {
        var params = {}, resolver, loading = Strings.Language.ENGLISH, loaded, key;

        resolver = function( resolve, reject )
        {
            var success, error;

            success = function( response )
            {
                var data = JSON.parse( response.data ), keys, val, partner, i;

                if( language === loading )
                {
                    if( loaded )
                    {
                        keys = Object.keys(data);

                        for( i = 0; i < keys.length; i++ )
                        {
                            key = keys[i];
                            loaded[key] = data[key];
                        }

                        data = loaded;
                    }

                    // strings that are partner specific have an object of partner mappings instead of a string in the string table
                    // reduce to a string using the partner as a the key to the object

                    partner = _x2._config._partner;
                    keys    = Object.keys(data);

                    for( i = 0; i < keys.length; i++ )
                    {
                        val = data[keys[i]];

                        if( typeof( val ) === "object" )
                            data[keys[i]] = val[partner] ? val[partner] : val["Comcast"];
                    }

                    resolve( data );
                }
                else
                {
                    loaded     = data;
                    loading    = language;
                    params.url = mapUrl( language );

                    _x2._network.ajax( params ).then( success ).catch( error );
                }
            };

            error = function( error )
            {
                reject( { error:error, data:loaded } );
            };

            // always load english as a base

            params.type    = "GET";
            params.url     = _x2._config._english;
            params.accepts = Network.Accepts.json;

            _x2._network.ajax( params ).then( success ).catch( error );
        };

        return new Promise( resolver );
    };

    function mapUrl( language )
    {
        var retval;

        switch( language )
        {
            case Strings.Language.ENGLISH:
                retval = _x2._config._english;
                break;

            case Strings.Language.SPANISH:
                retval = _x2._config._spanish;
                break;

            default:
                console.log( "ERROR -> unable to map language to a data file, language = " + language );
                break;
        }

        return retval;
    }

    return Strings;

})();
