// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * A RecordingCollection aggregates RecordingGroups, EntityRecordings (series recordings),
 * Programs (series) and SportsTeams.
 *
 * Every Recording object should contain a reference to its RecordingCollection so that
 * any of the associated EntityRecordings, Programs and SportsTeams can be found.
 *
 * We maintain this reference instead of iterating all of the Recordings up front, and
 * instead rely on a lazy initialization pattern.
 *
 */

window.RecordingCollection = (function()
{
    "use strict";

    function RecordingCollection(){}

    /**
     * Get the entity render style - default to POSTER.
     * @return {XtvApi.EntityRenderStyle|String}
     */
    RecordingCollection.prototype.getChildRenderStyle = function()
    {
        return this._childRenderStyle || XtvApi.EntityRenderStyle.POSTER;
    };

    /**
     * Get all entities in this collection.
     * @memberOf RecordingCollection
     * @return {Recording[]}
     */
    RecordingCollection.prototype.getEntities = function()
    {
        return this.getRecordings();
    };

    /**
     * Initialize this object
     * @memberOf RecordingCollection
     * @param {Object} data - XTV derived JSON object
     * @param {String} title - Title of this collection. i.e. "Recent Recordings"
     * @param {XtvApi.EntityRenderStyle} [childRenderStyle] - set the render style of this collection
     * @return {RecordingCollection}
     */
    RecordingCollection.prototype.init = function( data, title, childRenderStyle )
    {
        this._data = data;
        this._title = title;
        this._childRenderStyle = childRenderStyle;

        return this;
    };

    /**
     * Get all recordings in the collection
     * @memberOf RecordingCollection
     * @return {Recording[]} Array
     */
    RecordingCollection.prototype.getRecordings = function()
    {
        if( !this._recordings )
        {
            var i, recordings, entity, recording, series, seriesId,  seriesObjs = {};

            series = this._data._embedded.series;

            for( i=0; i<series.length; i++ )
                seriesObjs[series[i].merlinId] = new Entity().init(series[i]);

            this._recordings = [];
            recordings = this._data._embedded.recordings;

            for( i = 0; i < recordings.length; i++ )
            {
                recording = new Recording().init( recordings[i] );
                entity    = recording.getEntity();

                if( entity )
                {
                    seriesId = entity.getSeriesId();
                    if( seriesObjs[seriesId] )
                        entity.setSeries( seriesObjs[seriesId] );
                }

                this._recordings.push( recording );
            }
        }

        return this._recordings;
    };

    /**
     * Get All recordings organized by group
     * @memberOf RecordingCollection
     * @return {Array} {@link RecordingGroup} Array
     */
    RecordingCollection.prototype.getRecordingsByGroup = function()
    {
        if( !this._groups )
        {
            this._groups = [];

            var i, j, k, s, ids, group, recording, sid, entity;
            var recordingsAry = [];
            var seriesMap     = {};
            var recordings    = this._data._embedded.recordings;
            var groups        = this._data._embedded.recordingGroups;
            var series        = this._data._embedded.series;

            for( i=0; i<series.length; i++ )
            {
                s = new Entity().init(series[i]);
                sid = s.getEntityId();
                seriesMap[sid] = s;
            }

            for( i = 0; i < recordings.length; i++ )
            {
                recording = new Recording().init( recordings[i] );
                entity    = recording.getEntity();
                sid       = recording.getSeriesId();

                if( entity && sid && seriesMap[sid] )
                    entity.setSeries( seriesMap[sid]);

                recordingsAry.push( recording );
            }

            for( i = 0; i < groups.length; i++ )
            {
                group = new RecordingGroup().init( groups[i] );
                ids   = group.getRecordingIds();

                if( ids.length )
                {
                    for( j = 0; j < ids.length; j++ )
                    {
                        for( k = 0; k < recordingsAry.length; k++ )
                        {
                            if( ids[j] === recordingsAry[k].getId() )
                            {
                                recording = recordingsAry.splice( k, 1 )[0];
                                // recording.setGroup(group); //group is never used
                                group.addRecording(recording);
                                break;
                            }
                        }
                    }
                }
                else
                {
                    if( ! group._data._embedded || ! group._data._embedded.entity )
                        group._empty = true;
                }

                if( !group._empty )
                    this._groups.push( group );
            }
        }

        return this._groups;
    };

    /**
     * Get the title of this collection
     * @memberOf RecordingCollection
     * @return {String}
     */
    RecordingCollection.prototype.getTitle = function()
    {
        return this._title;
    };

    /**
     * Get the number of entities in this collection
     * @memberOf RecordingCollection
     * @return {Number}
     */
    RecordingCollection.prototype.getTotalEntityCount = function()
    {
        var recordings = this.getRecordings();
        return recordings ? recordings.length : 0;
    };

    return RecordingCollection;

})();


