// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.NineSliceWidget = ( function()
{
    "use strict";

    NineSliceWidget.prototype             = new Widget();
    NineSliceWidget.prototype.constructor = NineSliceWidget;
    NineSliceWidget.SHADER                = Widget.SHADER_KVT;

    function NineSliceWidget(){}

    NineSliceWidget.prototype.getInsetX = function()
    {
        return this._left;
    };

    NineSliceWidget.prototype.getInsetY = function()
    {
        return this._top;
    };

    /**
     * @memberof NineSliceWidget
     * @param {Axis} axis
     * @returns {number}
     */
    NineSliceWidget.prototype.getVal = function( axis )
    {
        if( _x2._config._render === Config.RENDER_WEBGL )
        {
            if( axis === Widget.W )
                return (this._curVal[ Widget.W ] + this._left + this._right) | 0;
            if( axis === Widget.H )
                return (this._curVal[ Widget.H ] + this._top  + this._bottom) | 0;
        }

        return Widget.prototype.getVal.call( this, axis );
    };

    /**
     * Initializer
     * @memberof NineSliceWidget
     * @param {Object}  params
     * @param {String}  params.url   - URL or local filename
     * @param {Number} [params.w=0]  - Width in pixels
     * @param {Number} [params.h=0]  - Height in pixels
     * @param {String} [params.name] - Class name over-ride
     * @param {Boolean}[params.pma]  - Set this to false for special circumstances when pre-multiplied alpha is blending as white instead of transparency.
     * @returns {NineSliceWidget}
     */
    NineSliceWidget.prototype.init = function( params )
    {
        if( this._className === undefined )
            this._className = (params && params.name) ? params.name : "NineSliceWidget";

        Widget.prototype.init.call( this );

        var dims = (params.url.split( "." )[0]).split( "_");  // top right bottom left

        dims.splice( 0, 1 );

        this._left   = parseInt( dims[0] );
        this._right  = parseInt( dims[1] );
        this._top    = parseInt( dims[2] );
        this._bottom = parseInt( dims[3] );
        this._url    = params.url;
        this._pma    = !!params.pma;

        this.setW( params.w ? params.w : 0 );
        this.setH( params.h ? params.h : 0 );

        return this;
    };

    /**
     * WebGL only: draw image
     * <p>
     * NOTE: Vertex colors are hard-coded to RGBA = &lt;a,a,a,a&gt; where a is alphaParent
     * @memberof NineSliceWidget
     * @param {Number} alphaParent - parent's alpha * current alpha
     */
    NineSliceWidget.prototype.render = function( alphaParent )
    {
        var gl = Widget.gl, shader = Widget.glShaderThis;

        if( this._pending )
            return;

        var w = (this._curVal[ Widget.W ] + this._left + this._right ) | 0;
        var h = (this._curVal[ Widget.H ] + this._top  + this._bottom) | 0;

        var hasW = (w !== this._width );
        var hasH = (h !== this._height);

        if( hasW || hasH )
        {
            var i, verts = this._dataPosition;

            if( hasW )
            {
                var cw = this._curVal[ Widget.W ];
                var x2 = this._left + ((cw < 0) ? w : cw);
                var x3 = x2 + this._right;

                // [16, 20, 24, 28, 32, 36, 40] = x2
                for( i = 16; i < 44; i += 4 )
                    verts[ i ] = x2;

                // [30, 34, 38, 42] = x3
                for( i = 30; i < 44; i += 4 )
                    verts[ i ] = x3;

                this._width = w;
            }

            if( hasH )
            {
                var ch = this._curVal[ Widget.H ];
                var y2 = this._top + ((ch < 0) ? h : ch);
                var y3 = y2 + this._bottom;

                // [9, 11, 19, 21, 37, 39] = y2
                for( i = 9; i < 20; i += 10 )
                {
                    verts[ i   ] = y2;
                    verts[ i+2 ] = y2;
                }

                verts[37] = y2;
                verts[39] = y2;

                // [13, 15, 17, 41, 43] = y3
                for( i = 13; i < 44; i += 28 )
                {
                    verts[ i   ] = y3;
                    verts[ i+2 ] = y3;
                }

                verts[17] = y3;

                this._height = h;
            }

            gl.bindBuffer( gl.ARRAY_BUFFER, this._buffPosition );
            gl.bufferData( gl.ARRAY_BUFFER, this._dataPosition, gl.STATIC_DRAW );
        }

        gl.activeTexture( gl.TEXTURE0 );
        gl.bindTexture( gl.TEXTURE_2D, this._texture );
        gl.uniform1i( shader.utDiffuse1, 0 );

        gl.bindBuffer( gl.ARRAY_BUFFER, this._buffTexCoord );
        gl.vertexAttribPointer( shader.avTexCoord, 2, gl.FLOAT, false, this._dataTexCoord, 0 );
        gl.enableVertexAttribArray( shader.avTexCoord );

        gl.uniform4f( shader.uvColor, alphaParent, alphaParent, alphaParent, alphaParent );

        gl.bindBuffer( gl.ARRAY_BUFFER, this._buffPosition );
        gl.vertexAttribPointer( shader.avPosition, 2, gl.FLOAT, false, this._dataPosition, 0 ); // index, size, type, bNormalized, stride, offset
        gl.enableVertexAttribArray( shader.avPosition );

        if( this._pma === false )
            gl.blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA );

        gl.drawArrays( gl.TRIANGLE_STRIP, 0, 22 );

        if( this._pma === false )
            gl.blendFunc( gl.ONE, gl.ONE_MINUS_SRC_ALPHA ); // always leave premultiplied alpha on; see glInit()
    };

    NineSliceWidget.prototype.resourcesLoad = function()
    {
        var self, gl;

        Widget.prototype.resourcesLoad.call( this );

        if( _x2._config._render === Config.RENDER_DOM )
        {
            this._div.style.border      = "solid transparent";
            this._div.style.borderImage = "url(" + this._url + ") " + this._top + " " + this._right + " " + this._bottom + " " + this._left + " fill";
            this._div.style.borderWidth = this._top + "px " + this._right + "px " + this._bottom + "px " + this._left + "px";
        }
        else if( _x2._config._render === Config.RENDER_WEBGL )
        {
            self = this;
            gl   = Widget.gl;

            this._pending = true;

            var w = this._curVal[Widget.W] | 0;
            var h = this._curVal[Widget.H] | 0;

            var x1 =     this._left;
            var x2 = w + this._left;
            var x3 = w + this._left + this._right;

            var y1 =     this._top;
            var y2 = h + this._top;
            var y3 = h + this._top + this._bottom;

            this._dataPosition = new Float32Array([ // 44 vertices
                 0, 0,  x1, 0,   0,y1,  x1,y1,   0,y2,  x1,y2,   0,y3,  x1,y3,
                x2,y3,  x1,y2,  x2,y2,  x1,y1,  x2,y1,  x1, 0,  x2, 0,
                x3, 0,  x2,y1,  x3,y1,  x2,y2,  x3,y2,  x2,y3,  x3,y3
            ]);

            this._buffPosition = gl.createBuffer();
            this._buffTexCoord = gl.createBuffer();

            gl.bindBuffer( gl.ARRAY_BUFFER, this._buffPosition );
            gl.bufferData( gl.ARRAY_BUFFER, this._dataPosition, gl.STATIC_DRAW );

            this._image = new Image();

            this._image.onload = function()
            {
                var imgW = self._image.width;
                var imgH = self._image.height;

                var rW = 1 / imgW;
                var rH = 1 / imgH;

                var s1 = self._left           * rW;
                var s2 = (imgW - self._right) * rW;

                var t1 = self._top             * rH;
                var t2 = (imgH - self._bottom) * rH;

/*
              0, 0                    0, 1
             s0,t0   s1,t0   s2,t0   s3,t0

         s0,t0 A-------B-------L-------M s3,t0
               |...../.|.\.....|...../.|
               |.1../..|..\.13.|.15./..|
               |.../...|...\...|.../...|
               |../..2.|.12.\..|../.16.|
               |./.....|.....\.|./.....|
         s0,t1 C/______D______\K/______N s3,t1
               |...../.|.\.....|...../.|
               |.3../..|..\.11.|.17./..|
               |.../...|...\...|.../...|
               |../..4.|.10.\..|../.18.|
               |./.....|.....\.|./.....|
         s0,t2 E/______F______\J/______O s3,t2
               |...../.|.\.....|...../.|
               |.5../..|..\..9.|.19./..|
               |.../...|...\...|.../...|
               |../..6.|.8..\..|../.20.|
               |./.....|.....\.|./.....|
         s0,t3 G/______H______\I/______P s3,t3

             s0,t3   s1,t3   s2,t3   s3,t3
              0, 1                    1, 1
*/

                self._dataTexCoord = new Float32Array(
                [ // 44 = 22 vertices
                     0, 0,  s1, 0,   0,t1, // A B C
                    s1,t1,   0,t2,  s1,t2, // D E F
                    0 , 1,  s1, 1,  s2, 1, // G H I
                    s1,t2,  s2,t2,  s1,t1, // F J D
                    s2,t1,  s1, 0,  s2, 0, // K B L
                     1, 0,  s2,t1,   1,t1, // M K N
                    s2,t2,   1,t2,  s2, 1, // J O I
                     1, 1                  // P
                ]);

                gl.bindBuffer( gl.ARRAY_BUFFER, self._buffTexCoord );
                gl.bufferData( gl.ARRAY_BUFFER, self._dataTexCoord, gl.DYNAMIC_DRAW );

                self._texture = Widget.glMakeTexture( imgW, imgH,  self._image, gl.RGBA );

                self._pending = false;
            };

            this._image.src = this._url;
        }
    };

    /**
     * @memberof NineSliceWidget
     * @param {Object}             params
     * @param {Boolean|Undefined} [params.inner] - true if dimensions need to be inset for all four borders
     * @param {Number|Undefined}  [params.w]     - Only if inner
     * @param {Number|Undefined}  [params.h]     - Only if inner
     * @param {Number|Undefined}  [params.x]     - Only if not inner
     * @param {Number|Undefined}  [params.y]     - Only if not inner
     */
    NineSliceWidget.prototype.setPosition = function( params )
    {
        if( params.inner === true )
        {
            if( params.w !== undefined )
                params.w = params.w - this._left - this._right;

            if( params.h !== undefined )
                params.h = params.h - this._top  - this._bottom;
        }
        else
        {
            if( params.x !== undefined )
                params.x = params.x - this._left;

            if( params.y !== undefined )
                params.y = params.y - this._top;
        }

        this.animate( params );
    };

    return NineSliceWidget;

})();

