// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.ChoiceRowWidget = ( function()
{
    "use strict";

    ChoiceRowWidget.prototype = new ListRowWidget();

    function ChoiceRowWidget(){}

    ChoiceRowWidget.prototype.getHeightUnfocused = function()
    {
        return _x2.scaleValInt( 114 * _x2._config._text );
    };

    ChoiceRowWidget.prototype.gotFocus = function( losingFocus, newScreen )
    {
        ListRowWidget.prototype.gotFocus.call( this );
        this._title.gotFocus();
        this._icon.gotFocus();
        this._title.setSpeechParams( undefined, "_radio", this, newScreen, this._data.selected );
        this._parent._parent._parent._currentFocusIndex = this._currentRowIndex;
        return this;
    };

    /**
     * Initializer
     * @memberof ChoiceRowWidget
     * @param   {Object}    params
     * @param   {Number}   [params.focusTime=0] - TODO
     * @param   {Function} [params.onEnter]     - Function to execute on selection
     * @param   {String}    params.title        - TODO
     * @param   {Number}    params.w            - TODO
     * @returns {ChoiceRowWidget}
     * @see ListRowWidget
     */
    ChoiceRowWidget.prototype.init = function( params )
    {
        ListRowWidget.prototype.init.call( this, params );
        this._className  = "ChoiceRowWidget";
        this._selectable = true;

        var self       = this;
        var titleStyle = new Style( { color:"#b8b8b8", font:"light", fontSize:_x2.scaleValInt(42), whiteSpace:"nowrap", colorHi:"#2ea0dd" } );

        var onLoad = function()
        {
            self._numLoaded++;

            if( self._numLoaded === 2 )
            {
                var h = self.getHeightUnfocused();
                var iconY = (h - self._icon.getH()) / 2;

                self._icon.setY( iconY );

                if( self._title.getText() === undefined )  // make sure string widget has a valid height
                    self._title.setText( "????" );

                self._titleBaseY = (h - self._title.getH()) / 2;
                self._title.setY( self._titleBaseY );
                self._title.setX( self._icon.getW() + 3 * Style._pad );
            }
        };

        this._numLoaded = 0;
        this._onEnter   = params.onEnter;

        this._title = new StringWidget().init( { text:params.title, style:titleStyle } );
        this._title.addReadyCallback( onLoad );
        this.addWidget( this._title );

        this._icon = new DualImageWidget().init( { url:_x2._config._imageRoot + "check_white.png", urlHi:_x2._config._imageRoot + "check.png" } );
        this._icon.setA( 0 );
        this._icon.addReadyCallback( onLoad );
        this.addWidget( this._icon );

        this.setW( params.w );
        this.setH( this.getHeightUnfocused() );

        this._currentRowIndex = params.rowIndex;

        return this;
    };

    ChoiceRowWidget.prototype.lostFocus = function()
    {
        ListRowWidget.prototype.lostFocus.call( this );
        this._title.lostFocus();
        this._icon.lostFocus();
        this._parent._parent._parent._lastFocusIndex = this._currentRowIndex;
    };

    ChoiceRowWidget.prototype.processEvent = function( val, type )
    {
        var retval;

        switch( val )
        {
            case Host.KEY_ENTER:
                if( type === Host.KEY_PRESSED && this._onEnter )
                {
                    retval = true;
                    this._onEnter( this );
                }
                break;
        }

        if( retval === undefined )
            retval = ListRowWidget.prototype.processEvent.call( this, val, type );

        return retval;
    };

    /**
     * @memberof ChoiceRowWidget
     * @param {Object}   [data]
     * @param {Function}  data.onEnter  - callback
     * @param {Boolean}   data.selected - Should this row show as selected
     * @param {String}    data.title    - Title to display in the row
     * @param {Object}   [data.value]   - TODO
     */
    ChoiceRowWidget.prototype.setData = function( data )
    {
        var self = this;
        this._data = data;

        if( data !== undefined )
        {
            if( data.selected === true )
                this._icon.setA( 1 );

            this._title.setText( data.title );
            this._onEnter = data.onEnter;

            this.setA( 1 );
            this.setMouseDownListener( function( event ) { event.stopPropagation(); self.processEvent( Host.KEY_ENTER, Host.KEY_PRESSED ); } );
            this.setMouseEnterListener( function() { 
                this._parent._parent._parent.moveCursorToRow( this._currentRowIndex );
             } );
        }
        else
        {
            this.setA( 0 );
            this.enableMouseDownListener( false );
        }
    };

    return ChoiceRowWidget;

})();
