// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

window.HorizSelectionTestScreen = ( function()
{
    "use strict";

    HorizSelectionTestScreen.prototype = new Screen();

    function HorizSelectionTestScreen(){}

    HorizSelectionTestScreen.prototype.init = function()
    {
        this._className = "HorizSelectionTestScreen";

        Screen.prototype.init.call( this );

        this.addWidget( new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } ) );

        var onChange = function( val )
        {
            console.log( val + " selected");
        };
        
        var e1 = [
            { key:'a', label:"All Episodes"  },
            { key:'r', label:"Just Repeats"  },
            { key:'n', label:"Just New Episodes" }];

        var e2 = [
            { key:0, label:"Cats" },
            { key:1, label:"Dogs" },
            { key:2, label:"Fish" }];

        //NOTE: set an 'obj' when the callback function needs more information or context.
        var e3 = [
            { obj: {type:'sport', subType:'b'}, key:'b', label:"Baseball" },
            { obj: {type:'sport', subType:'f'}, key:'f', label:"Football" },
            { obj: {type:'sport', subType:'g'}, key:'g', label:"Golf", selected:true }];

        this._hsw1 = new HorizSelectionWidget().init( { width:300, entries:e1, defaultValue:'r', onChange:onChange } );
        this._hsw2 = new HorizSelectionWidget().init( { width:300, entries:e2, defaultIndex:1,   onChange:onChange } );
        this._hsw3 = new HorizSelectionWidget().init( { width:300, entries:e3,                   onChange:onChange } );

        this.addWidget( this._hsw1, Style._safeLeft, Style._safeTop );
        this.addWidget( this._hsw2, Style._safeLeft, Style._safeTop + 100 );
        this.addWidget( this._hsw3, Style._safeLeft, Style._safeTop + 200 );

        this._index = 0;
        
        return this;
    };

    HorizSelectionTestScreen.prototype.gotFocus = function()
    {
        _x2.requestFocus( this._hsw1 );
    };

    HorizSelectionTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                {
                    switch (this._index)
                    {
                        case 1:
                            _x2.requestFocus(this._hsw1);
                            this._index--;
                            break;
                        case 2:
                            _x2.requestFocus(this._hsw2);
                            this._index--;
                            break;
                        default:
                            retval = Screen.prototype.processEvent.call( this, val, type );
                            break;
                    }
                }
                break;

            case Host.KEY_DOWN:
                if( type === Host.KEY_PRESSED )
                {
                    switch (this._index)
                    {
                        case 0:
                            _x2.requestFocus(this._hsw2);
                            this._index++;
                            break;
                        case 1:
                            _x2.requestFocus(this._hsw3);
                            this._index++;
                            break;
                        default:
                            retval = Screen.prototype.processEvent.call( this, val, type );
                            break;
                    }
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    return HorizSelectionTestScreen;

})();

