// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.SavedScreen = ( function()
{
    "use strict";

    SavedScreen.prototype = new Screen();

    function SavedScreen(){}

    SavedScreen.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._menu, true );

        this._speakTarget.setText( "Saved. " + focus._speakStr );
        this._speakTarget.setSpeechParams( "Press left or right to change filters. Press up or down to review categories.", focus._speakRole, this, false );
    };

    SavedScreen.prototype.init = function()
    {
        Screen.prototype.init.call( this );
        this._className     = "SavedScreen";
        this._telemetryName = "Saved";

        var localytics, telemetry, str, i, obj, onEnter, onGotFocus;
        var self        = this;
        var menuItems   = _x2._config._savedMenu;
        var entries     = [];
        var buttonStyle = new Style( { font:"regular" , color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#000000" } );
        var index       = 0;

        this._widgets = [];
        this._labels  = [];

        onEnter    = function( idx, telemetry ) { return function() { _x2._telemetry.setWatchButton( telemetry ); _x2.requestFocus( self._widgets[idx] ); }; };
        onGotFocus = function( idx            ) { return function() { self.swapContent( idx ); }; };

        for( i = 0; i < menuItems.length; i++ )
        {
            str = undefined;

            switch( menuItems[i] )
            {
                case "forYou":
                    str        = "For You";
                    obj        = new ForYouWidget().init( { path: "Saved. " + str } );
                    localytics = "For You";
                    telemetry  = "For You"; // NOTE: Recently watched also will set "Player: Last Watched";
                    break;

                case "recordings":
                    if( _x2._config._isCdvr === true )
                    {
                        str        = "Recordings";
                        obj        = new RecordingsWidget().init( { path: "Saved. " + str }  );
                        localytics = "Recordings";
                        telemetry  = "Recordings";

                    }
                    break;
                case "scheduled":
                    if( _x2._config._isCdvr === true )
                    {
                        str        = "Scheduled";
                        obj        = new ScheduledWidget().init( { path: "Saved. " + str } );
                        localytics = "Scheduled";
                        telemetry  = "Scheduled";
                    }
                    break;

                case "favorites":
                    str        = "Favorites";
                    obj        = new FavoritesWidget().init( { path: "Saved. " + str } );
                    localytics = "Favorites";
                    telemetry  = "Favorites";
                    break;

                case "purchases":
                    if( _x2._config._isEst === true )
                    {
                        str        = "Purchases";
                        obj        = new PurchasesWidget().init( { path: "Saved. " + str } );
                        localytics = "Purchases";
                        telemetry  = "Purchases";
                    }
                    break;

                default:
                    console.error( "Error -> Unexpected menu item in SavedScreen: " + menuItems[i] );
            }

            if( str )
            {
                entries[index] = new StringWidget().init( { text:str, style:buttonStyle, focusTime:300 } );
                entries[index].onEnter = onEnter( index, telemetry );
                entries[index].setFocusListeners( onGotFocus( index ) );

                index++;

                this._widgets.push( obj );
                this._labels.push( str );
                this.addWidget( obj, _x2._config._screenW );
            }
        }

        this._menu = new TabbedPillButtonsWidget().init( { entries:entries } );
        this.addWidget( this._menu );

        this.setBreadCrumbs( undefined, "Saved" );
        this.addReadyCallback( function() { self.layout(); } );

        return this;
    };

    SavedScreen.prototype.layout = function()
    {
        var y = this.getHeaderBottom();
        var h = _x2._config._screenH - y;

        this._menu.setH( y );

        for( var i = 0; i < this._widgets.length; i++ )
        {
            this._widgets[i].setY( y );
            this._widgets[i].setH( h );

            if( i > 0 )
                this._widgets[i].setA( 0 );
            else
                this._widgets[i].setX( 0 );
        }
    };

    SavedScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_LAST:
            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED )
                {
                    if( this._menu.containsWidget( _x2._focus ) === false )
                        _x2.requestFocus( this._menu );
                    else
                        retval = Screen.prototype.processEvent.call( this, val, type );
                }
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    SavedScreen.prototype.refreshCheck = function()
    {
        if( this._activeWidget.refresh )
            this._activeWidget.refresh( this );
    };

    SavedScreen.prototype.swapContent = function( index )
    {
        var widget = this._activeWidget;

        if( this._activeWidget !== this._widgets[index] )
        {
            if( this._activeWidget )
                this._activeWidget.animate( { a:0, duration:500, onEnd:function() { widget.setX( _x2._config._screenW ); } } );

            var whichTab = this._labels[index] ;
            this._activeWidget = this._widgets[index];
            this.setBreadCrumbs( "Saved", whichTab );

            _x2._config.log( Config.CLASS_TELEMETRY, 2 ) ? console.warn( "Telemetry: Screen Viewed: " + whichTab ) : Config.NOP();
            _x2._telemetry.sendScreenViewed( whichTab );

            if( this._activeWidget.loadData )
                this._activeWidget.loadData();

            this._activeWidget.setX( 0 );
            this._activeWidget.animate( { a:1, duration:500 } );
        }
    };

    return SavedScreen;

})();
