// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.Gradient4TestScreen = ( function()
{
    "use strict";

    Gradient4TestScreen.prototype = new Screen();

    function Gradient4TestScreen(){}

    Gradient4TestScreen.prototype.init = function()
    {
        var angle, delta;
        var alphas, colors;
        var alpha2, color2;
        var linear, radial;
        var params, gradient;
        var MONOCHROME = 0;

        var i;
        var x, y;
        var col, row;
        var ar = 4/3; // AspectRatio = width/height
        var h = 200;
        var w = h*ar;

        Screen.prototype.init.call( this );
        this._className = "Gradient4TestScreen";

        //this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        //this.addWidget( this._bg );
        this.addWidget( new ImageWidget().init( { url: _x2._config._imageRoot + "welcomeBg.jpg" } ) );

        this._grads = [];

        if( MONOCHROME ) // DEBUG grayscale
        {
            alphas =
            [
                {
                    opacity : 100,
                    location:   0
                },
                {
                    opacity : 100,
                    location: 100
                }
            ];
            colors =
            [
                {
                    color   : "#000000",
                    location: 0
                },
                {
                    color   : "#555555",
                    location: 100*1/3
                },
                {
                    color   : "#AAAAAA",
                    location: 100*2/3
                },
                {
                    color   : "#FFFFFF",
                    location: 100
                }
            ];
        }
        else
        {
            alphas =
            [
                {
                    opacity : 100,
                    location:   0
                },
                {
                    opacity :  50,
                    location: 100
                }
            ];
            colors =
            [
                {
                    color   : "#FF0000",
                    location: 0
                },
                {
                    color   : "#00FF00",
                    location: 100*1/3
                },
                {
                    color   : "#0000FF",
                    location: 100*2/3
                },
                {
                    color   : "#FFFFFF",
                    location: 100
                }
            ];
        }

        linear =
        {
            w: w,
            h: h,
            type: GradientWidget.Type.Linear,
            angle: 0,
            alphas: alphas,
            colors: colors
        };

        radial =
        {
            w: w,
            h: h,
            type: GradientWidget.Type.Radial,
            alphas: alphas,
            colors: colors
        };

        x = Style._safeLeft;
        y = Style._safeTop;

        angle = 0;
        delta = 45;

        for( i = 0; i < 9; i++ )
        {
            col = i % 3;
            row =(i / 3) | 0;

            if( col === 0 )
            {
                switch( row )
                {
                    case 0: angle = 135; delta = -45; break;
                    case 1: angle = 180; delta =  90; break;
                    case 2: angle = 225; delta = +45; break;
                }
            }

            if( i !== 4 )
            {
                params = Object.assign( {}, linear );
                params.angle = angle;
            }
            else
            {
                params = Object.assign( {}, radial );
            }

            gradient = new GradientWidget().init( params );

            this._grads.push( gradient );
            this.addWidget( gradient, x, y );

//console.log( "(" + i + ") %o", params );

            x += w;
            angle += delta;

            if( col === 2)
            {
                x = Style._safeLeft;
                y += h;
            }
        }

        this._selectable = true;

        return this;
    };

    return Gradient4TestScreen;

})();
