// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.Joshalytics = ( function()
{
    "use strict";

    Joshalytics.Type = Object.freeze
    ({
        ANALYTICS : "ANAL",
        KEY_PRESS : "KEY",
        SCREEN    : "SCREEN"
    });

    var socket, onClose, onError, onMessage, onOpen;

    /**
     * Constructor
     * @memberof Joshalytics
     * @param   {Object}    params
     * @param   {String}    params.server     - target server
     * @param   {Function} [params.onClose  ] - function to be called if the socket closes
     * @param   {Function} [params.onError  ] - function to be called when an error occurs
     * @param   {Function} [params.onMessage] - function to be called when a message is received
     * @param   {Function} [params.onOpen   ] - function to be called when the socket is opened
     * @returns {Joshalytics}
     */
    function Joshalytics( params )
    {
        onClose   = params.onClose;
        onError   = params.onError;
        onMessage = params.onMessage;
        onOpen    = params.onOpen;

        socket = new WebSocket( params.server );

        socket.onclose = function( event )
        {
            if( onClose )
                onClose( event );
        };

        socket.onerror = function( event )
        {
            console.error( event );

            if( onError )
                onError( event );
        };

        socket.onmessage = function( event )
        {
            console.log( event );

            if( onMessage )
                onMessage( JSON.parse( event.data ) );
        };

        socket.onopen = function( event )
        {
            if( onOpen )
                onOpen( event );
        };
    }

    Joshalytics.prototype.close = function()
    {
        if( socket.readyState === WebSocket.OPEN )
            socket.close();
    };

    Joshalytics.prototype.send = function( type, data )
    {
        if( socket.readyState === WebSocket.OPEN )
            socket.send( JSON.stringify( { type:type, data:data } ) );
    };

    return Joshalytics;

})();
