// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.SignOutOverlay = ( function()
{
    "use strict";

    SignOutOverlay.prototype = new Overlay();

    function SignOutOverlay(){}

    SignOutOverlay.prototype.gotFocus = function()
    {
        var focus = _x2.requestFocus( this._buttons, true );
        var str   = this._title.getText().replace( /xfinity/gi, "x finity" );

        this._speakTarget.setText( str + ". " + focus._speakStr + ". " );
        this._speakTarget.setSpeechParams( "Press right or left to review options. Press OK to select.", focus._speakRole, this, false );
    };

    SignOutOverlay.prototype.init = function()
    {
        var onCancel, onSignOut, cancel, signOut;
        var self        = this;
        var titleStyle  = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap", textAlign:"center" } );
        var buttonStyle = new Style( { font:"regular" , color:"#e8e8e8", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap", colorHi:"#000000" } );

        Overlay.prototype.init.call( this );
        this._className  = "SignOutOverlay";

        this._title = new StringWidget().init( { text:"Are you sure that you want to Sign Out?", style:titleStyle } );
        this._title.setW( this._bg.getW() );
        this._bg.addWidget( this._title, 0, _x2.scaleVal( 243 ) );

        onCancel = function()
        {
            _x2.popOverlay();
        };

        onSignOut = function()
        {
            ll( "tagEvent", "Session Ended" ); // TODO: minutes?

            _x2.setScreen( new TransitionScreen().init( { signOut:true } ) );
        };

        cancel  = new StringWidget().init( { text:"No" , style:buttonStyle, onEnter:onCancel  } );
        signOut = new StringWidget().init( { text:"Yes", style:buttonStyle, onEnter:onSignOut } );

        this._buttons = new HorizPillButtonsWidget().init( { entries:[cancel, signOut], spacing:_x2.scaleValInt(40) } );
        this._buttons.addReadyCallback( function() { self.layoutSignOutOverlay(); } );
        this._bg.addWidget( this._buttons );

        return this;
    };

    SignOutOverlay.prototype.layoutSignOutOverlay = function()
    {
        var gap = _x2.scaleVal( 50 );
        var h   = this._title.getH() + gap + this._buttons.getH();
        var y   = (this._bg.getH() - h) / 2;

        this._title.setY( y );
        this._buttons.setY( y + this._title.getH() + gap );
        this._buttons.setX( (this._bg.getW() - this._buttons.getW()) / 2 );
    };

    return SignOutOverlay;

})();
