// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.DownloadDevicesScreen = ( function()
{
    "use strict";

    DownloadDevicesScreen.prototype = new Screen();

    function DownloadDevicesScreen(){}

    DownloadDevicesScreen.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();
//       _x2.requestFocus( this._list );
    };

    /**
     * Initializer
     * @memberof DownloadDevicesScreen
     * @param   {Object} params
     * @param   {String} params.path - breadcrumb path
     * @returns {DownloadDevicesScreen}
     */
    DownloadDevicesScreen.prototype.init = function( params )
    {
        var onSuccess;
        var self       = this, listParams = {};
        var w          = _x2._config._screenW - Style._safeLeft - Style._safeRight;
        var titleStyle = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap" } );
        var bodyStyle  = new Style( { font:"light" , color:"#a3a3a3", fontSize:_x2.scaleValInt(32), whiteSpace:"nowrap" } );
        var noStyle    = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(54), whiteSpace:"nowrap", textAlign:"center" } );
        var descStr    = "Remove watched content so it can be downloaded to another device.";
        var onLoad     = function() { self._numLoaded++; self.layout(); };

        var onEnter    = function( data )
        {
            return function()
            {
                var onDeleteSuccess = function()
                {
                    _x2._data.getAllDeviceList().then( onSuccess );
                };

                var onDeleteError = function( e )
                {
                    console.log( e );
                };

                var executeDelete = function()
                {
                    self._distractor.show();
                    self._list.setData();
                    _x2.popOverlay( confirmOverlay );
                    _x2._data.checkInDownloadsForDevice( data.deviceId ).then( onDeleteSuccess, onDeleteError );
                };

                var confirmOverlay = new DeleteDownloadsOverlay().init( { onEnter:executeDelete } );

                _x2.pushOverlay( confirmOverlay );
            };
        };

        Screen.prototype.init.call( this );
        this._className     = "DownloadDevicesScreen";
        this._telemetryName = "Download Devices";
        this.setBreadCrumbs( params.path, "Devices with Downloads" );

        this._numLoaded = 0;

        this._title = new StringWidget().init( { text:"Devices with Downloads", style:titleStyle } );
        this.addWidget( this._title, Style._safeLeft );

        this._desc = new TextBlockWidget().init( { text:descStr , style:bodyStyle, w:w } );
        this.addWidget( this._desc, Style._safeLeft );

        this._lowerSep = new RectWidget().init( { w:w, h:ListRowWidget.DIVIDER_H, color:"#303030" } );
        this.addWidget( this._lowerSep, Style._safeLeft );

        this._noStream = new StringWidget().init( { text:"There are no devices with downloads.", style:noStyle } );
        this._noStream.setW( _x2._config._screenW );
        this._noStream.setA( 0 );
        this._noStream.addReadyCallback( onLoad );
        this.addWidget( this._noStream );

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( onLoad );
        this.addWidget( this._distractor );

        listParams.w        = w;
        listParams.h        = _x2._config._screenH;
        listParams.maxIndex = 1;
        listParams.type     = DevicesRowWidget;
        listParams.obj      = this;

        this._list = new ListWidget().init( listParams );
        this._list.setA( 0 );
        this.addWidget( this._list, Style._safeLeft );

        /**
         * @param {Object[]} data
         * @param {Boolean}  data[].purchasedVodCheckoutCount -
         * @param {Boolean}  data[].recordingCheckoutCount    -
         * @param {Boolean}  data[].tveCheckoutCount          -
         */
        onSuccess = function( data )
        {
            var results = [], subTitle, purchases, recordings, tvGo;

            self._distractor.hide();
            self._loaded = true;

            if( data && data.length > 0 )
            {
                for( var i = 0; i < data.length; i++ )
                {
                    purchases  = data[i].purchasedVodCheckoutCount;
                    recordings = data[i].recordingCheckoutCount;
                    tvGo       = data[i].tveCheckoutCount;

                    if( purchases > 0 || recordings > 0 || tvGo > 0 )
                    {
//                        console.log( data[i].deviceName + ", " + purchases + " Purchases, " + purchases + " Recordings, " + tvGo + " TV Go" );

                        if( purchases === 1 )
                            subTitle = "1 Purchase";
                        else if( purchases > 1 )
                            subTitle = purchases + " Purchases";
                        else
                            subTitle = "";

                        if( recordings > 0 )
                        {
                            if( subTitle.length > 0 )
                                subTitle += " | ";

                            if( recordings === 1 )
                                subTitle += "1 Recording";
                            else if( recordings > 1 )
                                subTitle += recordings + " Recordings";
                        }

                        if( tvGo > 0 )
                        {
                            if( subTitle.length > 0 )
                                subTitle += " | ";

                            subTitle += tvGo + " TV Go";
                        }

                        results.push( { title:data[i].deviceName, subTitle:subTitle, showIcon:false, onEnter:onEnter( data[i] ) } );
                    }
                }

                if( results.length > 0 )
                {
                    self._list.setData( results );
                    self._list.animate( { a:1, duration:500 } );

                    if( _x2._focus === self )
                        _x2.requestFocus( self._list );
                }
                else
                    self._noStream.animate( { a:1, duration:500 } );
            }
        };

        _x2._data.getAllDeviceList().then( onSuccess );

        return this;
    };

    DownloadDevicesScreen.prototype.layout = function()
    {
        if( this._numLoaded === 2 )
        {
            Screen.prototype.resourcesLoaded.call( this );

            this._title.setY( this.getHeaderBottom() + _x2.scaleValInt(50) );
            this._desc.setY( this._title.getY() + this._title.getH() );
            this._lowerSep.setY( this._desc.getY() + this._desc.getH() + _x2.scaleValInt(50) );

            var top = this._lowerSep.getY() + this._lowerSep.getH();
            var h   = _x2._config._screenH - Style._safeBottom - top;

            this._noStream.setY( top + (h - this._noStream.getH()) / 2 );

            this._distractor.setX( (_x2._config._screenW - this._distractor.getW() ) / 2 );
            this._distractor.setY(  top + (h - this._distractor.getH()) / 2 );

            if( this._loaded !== true )
                this._distractor.show();

            this._list.setH( h + Style._safeBottom );
            this._list.setY( top );
        }
    };

    DownloadDevicesScreen.prototype.onRowLostFocus = function()
    {
        _x2.requestFocus( this._list );
    };

    return DownloadDevicesScreen;

})();
