// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.VideoScreen = ( function()
{
    "use strict";

    VideoScreen.prototype = new Screen();

    VideoScreen.Mode = Object.freeze
    ({
        DVR  : 0,
        LIVE : 1,
        VOD  : 2
    });

    VideoScreen.SpeedMode = Object.freeze
    ({
        DYNAMIC : 0,
        STATIC  : 1
    });

    VideoScreen.State = Object.freeze
    ({
        PAUSED : 0,
        PLAY   : 1
    });

    var videoPlayer;
    var rawImW  = 102;
    var rawImH  = 136;
    var typeStr = [ "DVR", "Live", "On Demand" ];

    function VideoScreen(){}

    function createClockTimeStr( msec )
    {
        var date  = new Date( msec );
        var hours = date.getHours() % 12;
        var mins  = date.getMinutes();

        return (hours === 0 ? 12 : hours) + ":" + (mins < 10 ? "0" + mins : mins);
    }

    VideoScreen.prototype.createLabelStr = function( which, val, doSet )
    {
        var retval;

        switch( which )
        {
            case "cc":
                retval = "Closed Captioning: <font color='#2B9CD8'>" + val + "</font>";
                if( doSet === true )
                    this._cc.setLabel( retval );
                break;

            case "dv":
                retval = "Video Description: <font color='#2B9CD8'>" + val + "</font>";
                if( doSet === true )
                    this._dv.setLabel( retval );
                break;

            case "sap":
                retval = "Secondary Audio: <font color='#2B9CD8'>" + val + "</font>";
                if( doSet === true )
                    this._sap.setLabel( retval );
                break;
        }

        return retval;
    };

    VideoScreen.prototype.createTimeStr = function( msec )
    {
        var time, secs, mins;

        time = Math.floor( msec / 1000 );
        secs = time % 60;
        time = Math.floor( time / 60 );
        mins = time % 60;
        time = Math.floor( time / 60 );

        return time + ":" + (mins < 10 ? "0" + mins : mins) + ":" + (secs < 10 ? "0" + secs : secs);
    };

    VideoScreen.prototype.destroy = function()
    {
        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 1 ) ? console.log( ">>>>>>>>>> DESTROY VIDEO SCREEN <<<<<<<<<<, current = " + this._current + ", attempts = " + this._attempts ) : Config.NOP();
        _x2.removeChangeCallback( this._tickWrapper );
        _x2._hi.setA(0);

        if( this._retryTimer )
        {
            clearTimeout( this._retryTimer );
            this._attempts = undefined;
        }

        if( videoPlayer )
            this.sendVideoPlayedLocalytics();

        if( ! this._mediaEnded )
        {
            switch( this._mode )
            {
                case VideoScreen.Mode.DVR:
                    _x2._data.playbackRecordingStopWatching( this._recordingId );
                    break;

                case VideoScreen.Mode.VOD:
                    _x2._data.playbackVodStopWatching( this._paid );
                    break;

                case VideoScreen.Mode.LIVE:
                    _x2._data.playbackLinearStopWatching( this._streamId );
                    break;
            }
        }

        if( videoPlayer )
        {
            _x2._telemetry.sendVideoEnd( this.getTelemetryVideoType(), videoPlayer.getVersion() );
            _x2._telemetry.setVideoType( Telemetry.VideoType.NONE ); // ACTIVE_SESSION sends this but if video is not playing we need to send "Non Player"

            videoPlayer.stop();
        }

        if( this._timeout )
            clearTimeout( this._timeout );

        Screen.prototype.destroy.call( this );
    };

    VideoScreen.prototype.fastForwardIncrease = function()
    {
        if( this._speedIndex < this._speeds.length - 1 && this._mode !== VideoScreen.Mode.LIVE )
        {
            this._speedIndex++;

            _x2._config.log( Config.CLASS_VIDEO_SCREEN, 5 ) ? console.log( "FAST FORWARD INCREASE -> " + this._speedIndex + ", " + this._speeds[this._speedIndex] + ", " + this._speedModes[this._speedIndex] ) : Config.NOP();

            if( this._speedModes[this._speedIndex] === VideoScreen.SpeedMode.STATIC )
            {
                if( this._scrubBase === undefined )
                    this._scrubBase = videoPlayer.getCurrentPosition();

                if( this._speedIndex === this._speeds.indexOf( 1 ) )
                {
                    videoPlayer.setSpeed( 1 );
                    videoPlayer.setPosition( this._scrubBase );
                    videoPlayer.play();
                    this._scrubBase = undefined;
                }
                else if( videoPlayer._playerPlatform.getPlayerStatus() === "playing" )
                    videoPlayer.pause();
            }
            else if( this._speedModes[this._speedIndex] === VideoScreen.SpeedMode.DYNAMIC )
                videoPlayer.setSpeed( this._speeds[this._speedIndex] );

            this.setIndicator();
            this.speakSpeed();
        }
    };

    VideoScreen.prototype.getCurrentEntity = function()
    {
        return this._entity;
    };

    VideoScreen.prototype.getTelemetryVideoType = function()
    {
        var retval;

        switch( this._mode )
        {
            case VideoScreen.Mode.DVR:
                retval = Telemetry.VideoType.RECORDING;
                break;

            case VideoScreen.Mode.LIVE:
                retval = Telemetry.VideoType.T6_LINEAR;
                break;

            case VideoScreen.Mode.VOD:
                retval = Telemetry.VideoType.T6_VOD;
                break;
        }

        return retval;
    };

    VideoScreen.prototype.gotFocus = function()
    {
        this.speak( _x2.requestFocus( this._cursor, true ) );
    };

    VideoScreen.prototype.hideLastWatched = function( params )
    {
        console.log( "HIDE LAST" );
        console.log( params );

        this._lastWatched.hide();

        if( this._showing === true )
        {
            this._touch = true;
            _x2.requestFocus( this._targets[this._index] );
        }
        else
            _x2.requestFocus( this );
    };

    /**
     * Initializer
     * @memberof VideoScreen
     * @param   {Object}    params
     * @param   {Object}    [params.entity   ] - Entity to be played.
     * @param   {Object}    [params.mediaId  ] - Media ID of asset.
     * @param   {number}    [params.mode     ] - VideoScreen.Mode of video associated with the URL.
     * @param   {Object}    [params.programId] - Program ID of asset.
     * @param   {String}    [params.url      ] - URL of video to play.
     * @returns {VideoScreen}
     */
    VideoScreen.prototype.init = function( params )
    {
        var self          = this, complement, channel, pref;
        var timeStyle     = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(24) } );
        var timeStyleR    = new Style( { font:"light" , color:"#e8e8e8", fontSize:_x2.scaleValInt(24), textAlign:"right" } );
        var onLoad        = function() { self._numLoaded++; self.layout(); };
        var onButtonFocus = function() { _x2._hi.fadeOut(); };

        Screen.prototype.init.call( this, { noBg:true, noHeader:true, noSep:true } );
        this._className     = "VideoScreen";
        this._telemetryName = "Player";
        this._numLoaded     = 0;
        this._progress      = undefined;
        this._mediaId       = params.mediaId;
        this._programId     = params.programId;
        this._hasDvs        = params.hasDvs;
        this._streamId      = params.streamId;
        this._assetId       = params.assetId;
        this._providerId    = params.providerId;
        this._mediaGuid     = params.mediaGuid;
        this._entity        = params.entity;  //TODO: we init with an entity, then call setData with a showing?
        this._paid          = params.paid;
        this._recordingId   = params.recordingId;
        this._url           = params.url; // Localytics Error.Playback
        this._initResume    = params.resume;
        this._errorContexts = {};
        this._onUrlError    = function( e )
        {
            console.log( e );
            console.log( "ATTEMPT = " + self._attempts );

            if( self._attempts !== undefined )
            {
                self._attempts++;

                if( self._attempts < 6 )
                    self._retryTimer = setTimeout( function() { self.patchUrl( params.url, params.mode ).then( function( url ) { self.onGotUrl( url, params.resume ); }, self._onUrlError ); }, 1500 );
                else
                    _x2.pushOverlay( new ErrorOverlay().init( { error:e, context:self._errorContexts[self._mode], entity:self._entity } ) );
            }
        };

        _x2._config.debugAddClass( Config.CLASS_VIDEO_SCREEN, 7 );

        this._errorContexts[VideoScreen.Mode.DVR ] = ErrorOverlay.Context.DVR;
        this._errorContexts[VideoScreen.Mode.LIVE] = ErrorOverlay.Context.LINEAR;
        this._errorContexts[VideoScreen.Mode.VOD ] = ErrorOverlay.Context.VOD;

        if( params && params.url )
        {
            channel = this._entity.getChannel && this._entity.getChannel();
            pref    = _x2._settings.getValue( Settings.Key.FORMAT );
            if( pref === "hd" && this._entity.isAutoTune === true && this._entity._sdChannel ) {
                channel = this._entity._sdChannel;
            }//If channel was autotune in previous watch, then it should stream url from hd complement
            if( channel ) {
                console.log("Playing channel number", channel.getNumber(), 
                "Is HD channel?", channel.isHD(),
                "As HD complement?", channel.getHdComplement() );
            }
            if( pref === "hd" && channel && channel.isHD() === false && (complement = channel.getHdComplement()) )
            {
                params.url     = complement.getStreamUrl();
                this._streamId = complement.getStreamId();
                console.log("Sd channel ", channel.getNumber() ," was auto tuned to HD channel ", complement.getNumber() );
                if( this._entity.setChannel ) {
                    this._entity.setChannel( complement ); //set the channel so that onPlay() will assign the correct 'LAST_CHAN' to settings.
                    this._entity.isAutoTune = true;
                    this._entity._sdChannel = channel;
                }
            }

            this._attempts = 1;
            this.patchUrl( params.url, params.mode ).then( function( url ) { self.onGotUrl( url, params.resume ); }, this._onUrlError );
        }

        // params will drive the screen UI with a showing

        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 1 ) ? console.log( "MODE = " + params.mode ) : Config.NOP();

        this._mode    = params.mode;
        this._showing = true;
        _x2._screenRoot.clearSplashScreen();
        
        this._container = new Widget().init();
        this._container.setW( _x2._config._screenW );
        this._container.setH( _x2._config._screenH );
        this._container.setA( 0 );
        this._container._deepHide = true;  // this is a hack to help PP platform on LG, they need every cycle of CPU
        this.addWidget( this._container );

        this._topBg  = new ImageWidget().init( { url: _x2._config._imageRoot + "videoTopFade.png"   , onLoad:onLoad } );
        this._botBg  = new ImageWidget().init( { url: _x2._config._imageRoot + "videoBottomFade.png", onLoad:onLoad } );

        this._container.addWidget( this._topBg  );
        this._container.addWidget( this._botBg  );

        this._rightRect = new RectWidget().init( { w:400, h:Style._pad, color:"#333333" } );
        this._leftRect  = new RectWidget().init( { w:400, h:Style._pad, color:"#2ea0dd" } );

        this._container.addWidget( this._rightRect );
        this._container.addWidget( this._leftRect  );

        this._currStr  = new StringWidget().init( { text:"0:00:00", style:timeStyleR } );
        this._stopStr  = new StringWidget().init( { text:"0:00:00", style:timeStyle  } );

        this._container.addWidget( this._currStr  );
        this._container.addWidget( this._stopStr  );

        this._cursor = new ImageWidget().init( { url: _x2._config._imageRoot + "videoCursor.png", onLoad:onLoad, onFocus:onButtonFocus } );
        this._cursor._selectable = true;
        this._container.addWidget( this._cursor );

        this._pause = new DualImageWidget().init( { url:_x2._config._imageRoot + "pause.png", urlHi:_x2._config._imageRoot + "pauseHi.png", onFocus:onButtonFocus, onEnter:function() { self.togglePlayPause(); }, speechName:"Pause" } );
        this._pause.setA( 0 );
        this._pause.addReadyCallback( onLoad );
        this._container.addWidget( this._pause );

        this._play = new DualImageWidget().init( { url:_x2._config._imageRoot + "play.png", urlHi:_x2._config._imageRoot + "playHi.png", onFocus:onButtonFocus, onEnter:function() { self.togglePlayPause(); }, speechName:"Play" } );
        this._play.setA( 0 );
        this._play.addReadyCallback( onLoad );
        this._container.addWidget( this._play );

        this._ff = new DualImageWidget().init( { url:_x2._config._imageRoot + "fastForward.png", urlHi:_x2._config._imageRoot + "fastForwardHi.png", onFocus:onButtonFocus, onEnter:function() { self.fastForwardIncrease(); }, speechName:"Fast Forward" } );
        this._ff.setA( 0 );
        this._ff.addReadyCallback( onLoad );
        this._container.addWidget( this._ff );

        this._rew = new DualImageWidget().init( { url:_x2._config._imageRoot + "rewind.png", urlHi:_x2._config._imageRoot + "rewindHi.png", onFocus:onButtonFocus, onEnter:function() { self.rewindIncrease(); }, speechName:"Rewind" } );
        this._rew.setA( 0 );
        this._rew.addReadyCallback( onLoad );
        this._container.addWidget( this._rew );

        this._last = new DualImageWidget().init( { url:_x2._config._imageRoot + "lastWatched.png", urlHi:_x2._config._imageRoot + "lastWatchedHi.png", onFocus:onButtonFocus, onEnter:function() { self.showLastWatched(); }, speechName:"Last Watched" } );
        this._last.addReadyCallback( onLoad );
        this._container.addWidget( this._last );

        var onMiniGuidePressed = function()
        {
            self._showing = false;
            self._container.stopAnimation( Widget.ALPHA );
            self._container.animate( { a:0, duration:1 } );
            _x2.pushScreen( new MiniGuideScreen().init( { videoScreen:self } ) );
        };

        if( _x2._config._isChurned !== true )
        {
            this._mini = new DualImageWidget().init( { url:_x2._config._imageRoot + "miniGuide.png", urlHi:_x2._config._imageRoot + "miniGuideHi.png", speechName:"MiniGuide", onEnter:onMiniGuidePressed } );
            this._mini.addReadyCallback( onLoad );
            this._container.addWidget( this._mini );
        }

        var ccState  = _x2._settings.getValue( Settings.Key.CC_ENABLED );
        var ccParams =
        {
            initial:ccState === true ? 1 : 0,
            onEnter:function() { self.toggleCaptions(); },
            label  :this.createLabelStr( "cc", (ccState === true ? "On" : "Off"), false ),
            images :[ _x2._config._imageRoot + "cc.png", _x2._config._imageRoot + "ccHi.png", _x2._config._imageRoot + "ccSelected.png", _x2._config._imageRoot + "ccSelectedHi.png" ]
        };

        var dvState  = _x2._settings.getValue( Settings.Key.DV_ENABLED );
        var dvParams =
        {
            initial:dvState === true ? 1 : 0,
            onEnter:function() { self.toggleDv(); },
            label  :this.createLabelStr( "dv", (dvState === true ? "On" : "Off"), false ),
            images :[ _x2._config._imageRoot + "dv.png", _x2._config._imageRoot + "dvHi.png", _x2._config._imageRoot + "dvSelected.png", _x2._config._imageRoot + "dvSelectedHi.png" ]
        };

        var sapState  = _x2._settings.getValue( Settings.Key.SAP_ENABLED );
        var sapParams =
        {
            initial:sapState === true ? 1 : 0,
            onEnter:function() { self.toggleSap(); },
            label  :this.createLabelStr( "sap", (sapState === true ? "On" : "Off"), false ),
            images :[ _x2._config._imageRoot + "sap.png", _x2._config._imageRoot + "sapHi.png", _x2._config._imageRoot + "sapSelected.png", _x2._config._imageRoot + "sapSelectedHi.png" ]
        };

        this._cc = new MultiStateButtonWidget().init( ccParams );
        this._cc.addReadyCallback( onLoad );
        this._container.addWidget( this._cc );

        this._dv = new MultiStateButtonWidget().init( dvParams );
        this._dv.addReadyCallback( onLoad );
        this._container.addWidget( this._dv );

        this._sap = new MultiStateButtonWidget().init( sapParams );
        this._sap.addReadyCallback( onLoad );
        this._container.addWidget( this._sap );

//        this._index   = this._pp = 2;
//        this._targets = [ this._last, this._rew, this._pause, this._ff, this._cc, this._sap, this._dv ];
//        this._index   = this._pp = 1;

        if( this._mode !== VideoScreen.Mode.LIVE )
        {
            this._targets = [ this._last, this._rew, this._pause, this._ff, this._dv, this._sap, this._cc ];
            this._index   = this._pp = 2;

            if( this._mini )
                this._targets.splice( 4, 0, this._mini )
        }
        else
        {
            this._targets = [ this._last, this._dv, this._sap, this._cc ];
            this._index   = this._pp = 1;

            if( this._mini )
                this._targets.splice( 1, 0, this._mini )
        }

        this._stopStr.addReadyCallback( onLoad );

this._bitRate = new StringWidget().init( { style:timeStyle, text:"Bit Rate = " } );
this._bitRate.setA( 0 );
this._container.addWidget( this._bitRate );

this._playRate = new StringWidget().init( { style:timeStyle, text:"Play Rate = 1" } );
this._playRate.setA( 0 );
this._container.addWidget( this._playRate );

        this._distractor = new DistractorWidget().init( { hideBg:true } );
        this._distractor.setA( 0 );
        this._distractor.addReadyCallback( onLoad );
        this.addWidget( this._distractor, _x2._config._screenW, _x2._config._screenH );

        var imW           = _x2.scaleValInt( rawImW );
        var imH           = _x2.scaleValInt( rawImH );
        var textGapX      = _x2.scaleValInt( 30 );
        var typeStyle     = new Style( { color:"#b8b8b8", font:"regular", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );
        var titleStyle    = new Style( { color:"#e8e8e8", font:"regular", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap" } );
        var subTitleStyle = new Style( { color:"#e8e8e8", font:"thin"   , fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap" } );
        var textX         = Style._safeLeft + imW + textGapX;
        var entity        = params.entity;

        this._image = new ImageWidget().init( { w:imW, h:imH, onLoad:function(){ self._image.setA( 1 ) } } );
        this._image.setA( 0 );
        this._container.addWidget( this._image, Style._safeLeft, Style._safeLeft );

        this._type = new StringWidget().init({ style:typeStyle, text:typeStr[params.mode] });
        this._container.addWidget( this._type, textX, Style._safeTop );

        this._title = new StringWidget().init({ style:titleStyle });
        this._container.addWidget( this._title, textX );

        this._subTitle = new StringWidget().init({ style:subTitleStyle });
        this._container.addWidget( this._subTitle, textX );

        this._lastWatched = new LastWatchedWidget().init( { params:params, videoScreen:this, onHide:function( params ) { self.hideLastWatched( params ); } } );
        this.addWidget( this._lastWatched );

        var onIndicatorsLoaded = function()
        {
            self._numIndicatorsLoaded++;

            if( self._numIndicatorsLoaded === 7 )
            {
                var x = (_x2._config._screenW - self._fwdIndicators[0].getW()) / 2;
                var y = (_x2._config._screenH - self._fwdIndicators[0].getH()) / 2;

                for( var i = 0; i < self._fwdIndicators.length; i++ )
                {
                    self._fwdIndicators[i].setX( x );
                    self._rewIndicators[i].setX( x );
                    self._fwdIndicators[i].setY( y );
                    self._rewIndicators[i].setY( y );
                }
            }
        };

        this._numIndicatorsLoaded = 0;
        this._lastIndicator = 0;
        this._indicators    = [];

        this._fwdIndicators =
        [
            new ImageWidget().init( { url:_x2._config._imageRoot + "1x-FF.png", onLoad:onIndicatorsLoaded } ),
            new ImageWidget().init( { url:_x2._config._imageRoot + "2x-FF.png", onLoad:onIndicatorsLoaded } ),
            new ImageWidget().init( { url:_x2._config._imageRoot + "3x-FF.png", onLoad:onIndicatorsLoaded } ),
            new ImageWidget().init( { url:_x2._config._imageRoot + "4x.png"   , onLoad:onIndicatorsLoaded } )
        ];

        this._rewIndicators =
        [
            new ImageWidget().init( { url:_x2._config._imageRoot + "1x-RWD.png", onLoad:onIndicatorsLoaded } ),
            new ImageWidget().init( { url:_x2._config._imageRoot + "2x-RWD.png", onLoad:onIndicatorsLoaded } ),
            new ImageWidget().init( { url:_x2._config._imageRoot + "3x-RWD.png", onLoad:onIndicatorsLoaded } ),
            this._fwdIndicators[3]
        ];

        for( var i = 0; i < this._fwdIndicators.length; i++ )
        {
            this._fwdIndicators[i].setA( 0 );
            this.addWidget( this._fwdIndicators[i] );
            this._rewIndicators[i].setA( 0 );
            this.addWidget( this._rewIndicators[i] );
        }

console.log( "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@" );
console.log( entity );

//        this._distractor.show();

        var onIconLoaded = function()
        {
            self._netIconImage.setY( -(self._netIconImage.getH() + 4 * Style._pad) );
            self._netIconImage.setX( (self._distractor.getW() - self._netIconImage.getW()) / 2 );
            self._netIconImage.setA( 1 );
        };

        this._netIconW     = _x2.scaleValInt( 248 );
        this._netIconH     = this._netIconW * 3 / 4;
        this._netIconImage = new ImageWidget().init( { onChange:onIconLoaded, onError:function(){ self._netIconImage.setA(0) } } );
        this._netIconImage.setA( 0 );
        this._distractor.addWidget( this._netIconImage );

        var outOfWindow = false;

        if( entity instanceof LinearShowing )
            if( entity.getStartTime() > Date.now() || entity.getEndTime() < Date.now() )
                outOfWindow = true;

        if( outOfWindow )
            this.tick();
        else
            this.setData( entity );

        this._telemetryRewindTimestamp = 0;
        this._tickWrapper = function() { self.tick(); };
        _x2.addChangeCallback( this._tickWrapper );

        return this;
    };

    VideoScreen.prototype.layout = function()
    {
        var progressY;
        var numNeeded = this._mini ? 14 : 13;

        if( this._numLoaded === numNeeded )
        {
            this._botBg.setY( _x2._config._screenH - this._botBg.getH() );

            progressY = _x2._config._screenH - _x2.scaleValInt(253);

            this._currStr.setW( undefined );
            this._currStr.setX( Style._safeLeft );
            this._currStr.setY( progressY + (this._leftRect.getH() - this._stopStr.getH()) / 2 );
            this._stopStr.setX( _x2._config._screenW - Style._safeRight - this._stopStr.getW() );
            this._stopStr.setY( this._currStr.getY() );
            this._startX    = this._currStr.getX() + this._currStr.getW() + 2 * Style._pad;
            this._stopX     = this._stopStr.getX() - 2 * Style._pad;
            this._progressW = this._stopX - this._startX;

            this._currStr.setW( this._startX - 2 * Style._pad );
            this._currStr.setX( 0 );

            this._leftRect.setX( this._startX );
            this._leftRect.setY( progressY );
            this._rightRect.setX( this._startX );
            this._rightRect.setY( progressY );

            this._cursorOffsetX = -Math.floor( this._cursor.getW() / 2 );

            var y = progressY + _x2.scaleValInt( 66 );

            this._pause.setX( (_x2._config._screenW - this._pause.getW()) / 2 );
            this._pause.setY( y );

            this._play.setX( this._pause.getX() );
            this._play.setY( y );

            this._ff.setX( this._pause.getX() + this._pause.getW() + 3 * Style._pad );
            this._ff.setY( y );

            this._rew.setX( this._pause.getX() - this._rew.getW() - 3 * Style._pad );
            this._rew.setY( y );

            this._last.setX( this._startX );
            this._last.setY( y );

            this._cc.setX( this._stopX - this._cc.getW() );
            this._cc.setY( y );

            this._sap.setX( this._cc.getX() - this._sap.getW() - 3 * Style._pad );
            this._sap.setY( y );

            this._dv.setX( this._sap.getX() - this._dv.getW() - 3 * Style._pad );
            this._dv.setY( y );

            if( this._mini )
            {
                this._mini.setX( this._dv.getX() - this._mini.getW() - 3 * Style._pad );
                this._mini.setY( y );
            }

            this._distractor.setX( (_x2._config._screenW - this._distractor.getW()                 ) / 2 );
            this._distractor.setY( (_x2._config._screenH - this._distractor.getH() + this._netIconH) / 2 );

            var iconW = this._netIconImage.getW();

            if( iconW )
                this._netIconImage.setX( (this._distractor.getW() - iconW) / 2 );

            this._cursor.setX( this._startX + this._cursorOffsetX );
            this._cursor.setY( this._leftRect.getY() + (this._leftRect.getH() - this._cursor.getH()) / 2 );

            switch( this._mode )
            {
                case VideoScreen.Mode.DVR:
                    this._leftRect.setW( this._progressW );
                    this._rightRect.setW( this._progressW );
                    break;

                case VideoScreen.Mode.LIVE:
                    this._leftRect.setW( 0 );
                    this._rightRect.setW( this._progressW );
                    break;

                case VideoScreen.Mode.VOD:
                    this._leftRect.setW( this._progressW );
                    this._rightRect.setW( this._progressW );
//                     this._cursor.setX( this._startX + this._cursorOffsetX );
//                     this._cursor.setY( this._leftRect.getY() + (this._leftRect.getH() - this._cursor.getH()) / 2 );
                    break;

                default:
                    console.error( "ERROR => unknown mode = " + this._mode );
                    break;
            }

this._playRate.setY( this._bitRate.getY() + this._bitRate.getH() );

            this._type.setY( (this._image.getH() - this._type.getH() - this._title.getH() - this._subTitle.getH()) / 2 + this._image.getY() );
            this._title.setY( this._type.getY() + this._type.getH() );
            this._subTitle.setY( this._title.getY() + this._title.getH() );
            this._currStr.setText();
            this._stopStr.setText();

            if( this._mode === VideoScreen.Mode.LIVE )
            {
                this.setTimes();
            }
            else
            {
                var duration;

                if( this._entity.getDurationMs )
                    duration = this._entity.getDurationMs();
                else if( this._entity.getDuration )
                    duration = this._entity.getDuration() * 60000;

                this.setTimes( this._initResume, 0, duration );
            }

            this._touch   = true;
            this._showing = true;
            this._container.stopAnimation( Widget.Axis.A );
            this._container.setA( 1 );
        }
    };

    VideoScreen.prototype.onBitRateChanged = function( bitRate )
    {
        var mbps = bitRate / (1024 * 1024);
        var str  = "Bit Rate = " + (( mbps > 1 ) ? mbps.toFixed(3) + " mpbs" : Math.floor(bitRate / 1024) + " kpbs");

        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 5 ) ? console.log( "BIT RATE CHANGED = " + str ) : Config.NOP();

        this._bitRate.setText( str );
    };

    VideoScreen.prototype.onBufferComplete = function()
    {
        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 7 ) ? console.log( ">>>>>>>>>> ON BUFFER COMPLETE <<<<<<<<<<" ) : Config.NOP();
        this._distractor.hide();

        _x2._telemetry.sendVideoBufferComplete( this.getTelemetryVideoType(), false, this._current > 0, false );
    };

    VideoScreen.prototype.onBufferStart = function()
    {
        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 7 ) ? console.log( ">>>>>>>>>> ON BUFFER START <<<<<<<<<<" ) : Config.NOP();
        this._distractor.show( true );

        _x2._telemetry.sendVideoBufferStart( this.getTelemetryVideoType(), false, this._current > 0, false );

        console.log( this._current + " of " + this._progress + ", total = " + this._stop );

        if( this._current !== undefined && this._stop !== undefined && (this._stop - this._current) < 8000 )  // HACK in case run out of fragments at the very end
            this.onMediaEnded();
    };


    VideoScreen.prototype.onEmergencyAlertComplete = function()
    {
        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 7 ) ? console.log( ">>>>>>>>>> ON EMERGENCY ALERT COMPLETE <<<<<<<<<<" ) : Config.NOP();
        this._easLocked = false;
    };

    VideoScreen.prototype.onEmergencyAlertStarted = function()
    {
        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 7 ) ? console.log( ">>>>>>>>>> ON EMERGENCY ALERT STARTED <<<<<<<<<<" ) : Config.NOP();

        var self  = this;
        this._easLocked = true;
        setTimeout( function() { self._easLocked = false; }, 180000 );
    };

    VideoScreen.prototype.onError = function( event )
    {
console.error( event );
//         var params = {};
//
//         params.message = "Please try again later.";
//         params.title   = "Video Playback Error";
//
//         if( this._errorShown !== true )
//         {
//             this._errorShown = true;
//             _x2.pushOverlay( new AlertOverlay().init( params ) );
//         }

//        _x2._telemetry.sendVideoError( event.error.major, event.error.minor, event.error.description, this.getTelemetryVideoType(), true );
    };

    VideoScreen.prototype.onGotUrl = function( url, resume )
    {
        if( this._attempts !== undefined )
        {
            if( (resume === undefined || resume === 0) && this._mode === VideoScreen.Mode.DVR )  // Hack: PP is not starting in-flight recordings from the beginning
                resume = 1;

            if (resume && resume > this._entity.getDurationMs() ) {
                resume = this._mode === VideoScreen.Mode.DVR ? 1 : 0;
            }// resume is more than movie duration, then we need to reset the resumepoint to 0

            _x2._config.log( Config.CLASS_VIDEO_SCREEN, 1 ) ? console.log( "#############################################################################################################################" ) : Config.NOP();
            _x2._config.log( Config.CLASS_VIDEO_SCREEN, 1 ) ? console.log( "PLAY: " + url + ", resume = " + resume )                                                                                         : Config.NOP();
            _x2._config.log( Config.CLASS_VIDEO_SCREEN, 1 ) ? console.log( "#############################################################################################################################" ) : Config.NOP();

            var lang, onVideoNotStarting, self = this;
    //         var newUrl = url.replace( /http:/g, "https:" );  // convert to https since using a secure production host
    //
    //         if( newUrl && _x2._config._host.getType() !== Host.Type.PC )  // the test content is http only, don't convert
    //             url = newUrl;

            var obj  =
            {
                recordingId: this._recordingId,
                streamId   : this._streamId,
                assetId    : this._assetId,
                providerId : this._providerId,
                mediaGuid  : this._mediaGuid
            };

            if( url )
            {
                var entity = this._entity, provider, networkLogoUrl, entityOptions;

                if( entity instanceof Entity )
                {
                    entityOptions = data.getEntityOptions();
                    provider = entityOptions && entityOptions.getContentProvider();
                }
                else
                {
                    if( entity instanceof Showing )
                        provider = entity.getContentProvider();

                    if( entity instanceof LinearShowing || entity instanceof Recording )
                        provider = entity.getChannel();
                }

                if( provider )
                {
                    networkLogoUrl = provider.getLogoUrl( this._netIconW, this._netIconH );

                    if( networkLogoUrl )
                    {
                        networkLogoUrl = networkLogoUrl.replace( /&extent=true/, "" );
                        networkLogoUrl = networkLogoUrl.replace( /&gravity/, "" );
                        this._netIconImage.setUrl( networkLogoUrl );
                    }
                }

                this._distractor.show( true );
            }

            if( _x2._settings.getValue( Settings.Key.DV_ENABLED ) === true && this._hasDvs === true )
                lang = "es";  // HACK since always coming through as Spanish at this point
            else if( _x2._settings.getValue( Settings.Key.SAP_ENABLED ) === true && this._hasDvs === false )
                lang = _x2._settings.getValue( Settings.Key.SAP_LANG );
            else
                lang = "en";

            if( videoPlayer === undefined )
            {
                var videoEas = true;

                if( _x2._config._host instanceof Tizen )  // HACK so broken Samsung TV can support EAS via text
                {
                    var textModels = [ "17_KANTS_FHD", "18_KANTS_FHD" ];

                    if( textModels.indexOf( _x2._config._host._model ) > -1 )
                        videoEas = false;
                }

                videoPlayer = (_x2._config._altVid === true) ? new CirrusVideo( url, this, resume, lang, obj, true ) : new ViperPlayerPlatform( url, this, resume, lang, obj, videoEas );
            }
            else if( url )
            {
                this._telemetryRewindTimestamp = 0;
                videoPlayer.stop();
                this._current = undefined;
                this._stop    = undefined;
                videoPlayer.setLanguage( lang );
                videoPlayer.play( url, this, resume, obj );
            }

            onVideoNotStarting = function()
            {
                var type = ErrorOverlay.Context.COMMON;

                switch( self._mode )
                {
                   case VideoScreen.Mode.LIVE:
                       type = ErrorOverlay.Context.LINEAR;
                       break;

                   case VideoScreen.Mode.DVR:
                       type = ErrorOverlay.Context.DVR;
                       break;

                   case VideoScreen.Mode.VOD:
                       type = ErrorOverlay.Context.VOD;
                       break;
                }
                var lastScreen = _x2._screenRoot.getTop();
                if( self._checkPlayStarted === true && self._checkInErrorState === false  && 
                    lastScreen instanceof VideoScreen )
                {
                    self._checkInErrorState = true;
                    _x2._splunk.sendVideoAttempt( self._entity, self._mode, self._url, videoPlayer.getVersion(), undefined, videoPlayer.getDuration() );
                    _x2._splunk.sendVideoError( self._entity, 'TVAPP-00148', 'Video playback has not started within 30 seconds.', undefined, undefined, self._mode, self._url, videoPlayer.getVersion(), videoPlayer.getDuration() );
                    _x2.pushOverlay( new ErrorOverlay().init( { reason:"(Error TVAPP-00148)" } ) ); //NOTE: TVAPP-00148 maps to playback start timeout. Set it directly as we're detecting it here (no mapping to external error).
                }
            };

            self._timeout = setTimeout( onVideoNotStarting, 35000 ); //onMediaFailed callback called at 32-33 seconds. Give that a chance to push a more specific message

            this._videoLatencyStart = Date.now(); // NOTE: We can't send the video attempt due the PlayerPlayer not having the player version available (yet)
            this._checkPlayStarted  = true;
            this._checkInErrorState = false;
            this._isMidStream       = resume > 0;
        }
    };

    VideoScreen.prototype.onInitialized = function( speeds )
    {
        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 3 ) ? console.log( speeds ) : Config.NOP();

        this._speeds     = [ -128, -16, -8, -4, 1, 4, 8, 16, 128 ];
        this._speedModes = [];
        this._speedIndex = this._speeds.indexOf( 1 );

        for( var i = 0; i < this._speeds.length; i++ )
        {
//             if( speeds.includes( this._speeds[i] ) )
//                 this._speedModes[i] = VideoScreen.SpeedMode.DYNAMIC;
//             else
                this._speedModes[i] = VideoScreen.SpeedMode.STATIC;

            var which = this._speedIndex - i;

            if( which < 0 )
                this._indicators[i] = this._fwdIndicators[-which - 1];
            else if( which > 0 )
                this._indicators[i] = this._rewIndicators[ which - 1];
        }

if( _x2._config._host.getType() === Host.Type.PC )
    videoPlayer.mute();

        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 3 ) ? console.log( this._speedModes ) : Config.NOP();
    };

    VideoScreen.prototype.onMediaOpened = function()
    {
        var videoType     = this.getTelemetryVideoType();
        var playerVersion = videoPlayer.getVersion();
        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 7 ) ? console.log( ">>>>>>>>>> ON MEDIA OPENED <<<<<<<<<<" ) : Config.NOP();

        _x2._telemetry.sendVideoAttempt( videoType, playerVersion ); // See note in onGotUrl
        _x2._splunk.sendVideoAttempt( this._entity, this._mode, this._url, playerVersion, videoPlayer.getDuration() );

        // set the sap button

        var langs = videoPlayer.getLanguages();

console.log( langs );
console.log( "--------> HAS DVS = " + this._hasDvs );

        if( _x2._settings.getValue( Settings.Key.SAP_ENABLED ) === true )
            this.createLabelStr( "sap", "On" , true );
        else
            this.createLabelStr( "sap", "Off", true );

        // set the dvs button

        var available = langs.length > 1 && this._hasDvs;

        if( _x2._settings.getValue( Settings.Key.DV_ENABLED ) === true )
            this.createLabelStr( "dv", available === true ? "On" : "Not Available", true );
        else
            this.createLabelStr( "dv", available === true ? "Off" : "Not Available", true );

        // turn on closed captions if enabled

        var captions = videoPlayer.getCaptions();

        if( captions.length > 0 )
        {
            if( _x2._settings.getValue( Settings.Key.CC_ENABLED ) === true )
            {
                _x2._config.log( Config.CLASS_VIDEO_SCREEN, 5 ) ? console.log( ">>>>>>>>>> CAPTIONS TURNED ON <<<<<<<<<<" ) : Config.NOP();
                _x2._config.log( Config.CLASS_VIDEO_SCREEN, 5 ) ? console.log( captions                                   ) : Config.NOP();

                videoPlayer.setCaption( captions[0] );
            }

            if( captions.length > 1 )
                console.warn( "more than one CC track available, do something with it" );

            videoPlayer._playerPlatform.setClosedCaptionsStyle( _x2._settings.getValue( Settings.Key.CC_STYLING ) );
        }

        // XC-18729: Video Playback - Playback is cut off when adjusting aspect ratio
        // guidance by LG in the #ext-tvpp-lg channel by dusik.oh @ Monday, December 9th, 6:52 PM
        // Issue 2) Part of screen is cropped when the resolution is set to 16:9 during the play of SD video.
        // -> LG provided Comcast with a solution that can be solved in the application. The issue is that both side are just cut off when switching to 16:9.
        // Please investigate our solution.
        // solution : document.getElementsByTagName ('video') [0] .style.objectFit = "fill" in the Video Tag.
        //
        // NOTES ON IMPLEMENTAION:
        // 1. We should not have to _explicitly_ set the *object-fit* CSS setting, since the initial value for the object-fit CSS is fill
        //    and since we do not set it, it is the default value:
        //    Please see official specification: https://www.w3.org/TR/css-images-3/#the-object-fit and https://drafts.csswg.org/css-cascade/#initial-values
        // 2. Make sure SD channels are available for testing.  Change the preferred format to *All Available Formats* from *HD Preferred*.
        // 3. Seems to only work for 16:9 aspect ratio display setting for SD channels.  *original* aspect ratio setting does not work for all LG TVs for SD channels.
        // 4. Even for HD channels, LG 2018 models appears to not behave correctly to explicit CSS object-fit: fill setting.
        //
        // Isolating this change to cirruscoam and to only LG hosts once the HTML5 video tag is created.
        if ( _x2._config._host.getType() === Host.Type.LG )
        {
            document.getElementsByTagName('video')[0].style.objectFit = "fill";
        }
    };

    VideoScreen.prototype.onMediaEnded = function()
    {
        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 3 ) ? console.log( ">>>>>>>>>> ON MEDIA ENDED <<<<<<<<<<" ) : Config.NOP();

        var self = this;
        this._mediaEnded = true;


        switch( this._mode )
        {
            case VideoScreen.Mode.DVR:
                this._resume = 0;
                this.storeResumePoint();

                _x2._data.playbackRecordingFinishWatching( this._recordingId );

                var optCallback = function( key, overlay )
                {
                    if( key === 1 )
                    {
                        var delCallback = function()
                        {
                            self._distractor.hide();
                            _x2.popScreen();
                        };

                        _x2.popOverlay( overlay );
                        self._distractor.show();
                        self._entity.delete().then( delCallback );
                    }
                    else
                    {
                        _x2.popOverlay( overlay, function() { _x2.popScreen() } );
                    }
                };

                var optParams = {
                    title    : "Delete Recording?",
                    subTitle : "You appear to have completed watching " + self._entity.getTitle() + "; would you like to delete it?",
                    button1  : { text:"Delete Now",   key:1 },
                    button2  : { text:"Don't Delete", key:0 },
                    callback : function( key ){ optCallback( key, optOverlay ) }
                };

                var optOverlay = new TwoOptionsOverlay().init( optParams );
                _x2.pushOverlay( optOverlay );

                break;

            case VideoScreen.Mode.LIVE:
                console.error( "Should never get a media ended event for live TV." );
                break;

            case VideoScreen.Mode.VOD:
                this._resume = 0;
                this.storeResumePoint();

                _x2._data.playbackVodFinishWatching( this._paid );

                _x2.popScreen();
                break;
        }
    };

    VideoScreen.prototype.onMediaFailed = function( event )
    {
        var context, mediaError;

        if( this._timeout )
            clearTimeout( this._timeout );

        switch( this._mode )
        {
            case VideoScreen.Mode.DVR:
                context = ErrorOverlay.Context.DVR;
                break;
            case VideoScreen.Mode.LIVE:
                context = ErrorOverlay.Context.LINEAR;
                break;
            case VideoScreen.Mode.VOD:
                context = ErrorOverlay.Context.VOD;
                break;
        }

        var playerVersion = videoPlayer.getVersion();
        var code  = ErrorOverlay.getTVAPP( context, event.error.major, event.error.minor );
        var err   = (Array.isArray(code) ? code[0] : code );
        var tvapp = "TVAPP-00" + err;
        _x2._telemetry.sendVideoError( err, "NA", event.error.description, this.getTelemetryVideoType(), true ); // always user facing

        if( !this._telemetrySentVASP )
        {
            this._telemetrySentVASP = true;
            _x2._telemetry.sendVideoAttemptSuccess( false, this.getTelemetryVideoType() ); // only denominator

            var duration = videoPlayer.getDuration();
            var metrics =
            {
                "Error Domain" : "Video Playback",
                "Error Code"   : event.error.major,
                "Error Subcode": event.error.minor,
                "Display Code" : tvapp,
                "URL"          : window.location.href,
                "Resource Info": this._entity.getTitle(),
                "Resource Link": this._url
            };
//console.log( metrics );
            window.ll( "tagEvent", "Error.Playback", metrics );

            _x2._splunk.sendVideoAttempt( this._entity, this._mode, this._url, playerVersion, duration );
            _x2._splunk.sendVideoError( this._entity, tvapp, event.error.description, event.error.major, event.error.minor, this._mode, this._url, playerVersion, duration );
        }

        _x2._telemetry.setVideoType( Telemetry.VideoType.NONE ); // ACTIVE_SESSION sends this but if video is not playing we need to send "Non Player"
        var lastScreen = _x2._screenRoot.getTop();
        if( this._checkInErrorState === false && lastScreen instanceof VideoScreen ) //don't push multiple errors (race with onVideoNotStarting callback)
        {
            this._checkInErrorState = true;
            mediaError = new ApiError().init( { flash:event.error } );
            _x2.pushOverlay( new ErrorOverlay().init( { context:context, error:mediaError, entity:this._entity } ) );
        }
    };

    VideoScreen.prototype.onMediaProgress = function( current, start, stop )
    {
        var now = Date.now();
        var nextHeartbeat = this._nextHeartbeat ? this._nextHeartbeat : now;

        if( nextHeartbeat <= now )
        {
            switch( this._mode )
            {
                case VideoScreen.Mode.LIVE:
                    _x2._data.playbackLinearHeartbeat( this._streamId );
                    break;

                case VideoScreen.Mode.DVR:
                    _x2._data.playbackRecordingHeartbeat( this._recordingId );
                    break;

                case VideoScreen.Mode.VOD:
                    _x2._data.playbackVodHeartbeat( this._paid );
                    break;
            }

            this._nextHeartbeat = now + 120000; //two minutes
        }

        if( current < this._current )
        {
            // HACK: Work-around player bug getting a current position before _current when no rewind has been pressed
            if( (Date.now() - this._telemetryRewindTimestamp) < 60000 )
                _x2._telemetry.sendVideoJumpback( this.getTelemetryVideoType(), false, current > start, true, (current <= start) );
        }

        this._current = current;
        this.setTimes( current, start, stop );
    };

    VideoScreen.prototype.onPaused = function()
    {
        if( _x2._focus === this._pause )
            _x2.requestFocus( this._play );

        if( this._mode !== VideoScreen.Mode.LIVE )
        {
            this._targets[this._pp] = this._play;
            this._play.setA( 1 );
            this._pause.setA( 0 );
        }
    };

    VideoScreen.prototype.onPlay = function()
    {
        var videoType, playerVersion, latency, watchSource;

        this._distractor.hide();

        if( this._checkPlayStarted === true )
        {
            this._checkPlayStarted = false;

            videoType     = this.getTelemetryVideoType();
            playerVersion = videoPlayer.getVersion();
            latency       = Date.now() - this._videoLatencyStart; // milliseconds
            watchSource   = _x2._telemetry.getWatchButtonFullPath();

            _x2._config.log( Config.CLASS_VIDEO_SCREEN, 7 ) ? console.log( ">>>>>>>>>> ON PLAY <<<<<<<<<<" ) : Config.NOP();
            _x2._config.log( Config.CLASS_TELEMETRY   , 3 ) ? console.warn( "videoType: "   + videoType    ) : Config.NOP();
            _x2._config.log( Config.CLASS_TELEMETRY   , 3 ) ? console.warn( "watchSource: " + watchSource  ) : Config.NOP();

            videoPlayer.setPcBlock( false );//if player was blocked previously and not unblock, we should unblock while playing again

            this._localyticsOnPlayStartTime = Date.now();

            console.log( "Watch Source: " + watchSource );
            if( this._entity.getChannel ) {
                console.log( "Watch source's channel no : ", + this._entity.getChannel().getNumber() )
            }
            _x2._telemetry.sendWatchButtonClicked( watchSource, videoType, playerVersion ); // NOTE: must have already called getTelemetryVideoType()
            _x2._telemetry.sendVideoLatency( latency, videoType, playerVersion );

            // Q. Do we need to gaurd against the edge case of getting an error and playing is re-tried?
            // A. Yes, since VASP is to be sent only once per streaming session
            if( !this._telemetrySentVASP  )
            {
                this._telemetrySentVASP = true;
                _x2._telemetry.sendVideoAttemptSuccess( false, videoType ); // both denominator
                _x2._telemetry.sendVideoAttemptSuccess( true , videoType ); // both numerator
            }

            _x2._telemetry.sendVideoStart( videoType, playerVersion );
            _x2._splunk.sendVideoStart( this._entity, this._mode, this._url, playerVersion, latency, videoPlayer.getDuration() );

            // XC-18060: Exception
            // If we are:
            //     * watching a currently airing program, and
            //     * recording it (i.e. it hasn't finished recording)
            // then DON'T send these metrics as they don't make sense. (The total time is not finalised.)
            var recordingDuration = this._entity.getDurationMs && this._entity.getDurationMs();
            if( recordingDuration )
            {
                recordingDuration /= 1000;
                var manifestDuration = Math.floor( videoPlayer._playerPlatform.getDuration() / 1000 );

                if( this._recordingManagerId && this._mode !== VideoScreen.Mode.LIVE && this._entity.getRecordingStatus() !== "STARTED" ) // VPAP recordings
                {
//                  console.log( "Duration: " + manifestDuration );
//                  console.log( this._entity._telemetryMetaDataDuration );

                    _x2._telemetry.sendVideoPlaybackAvailableBucket ( videoType, manifestDuration, recordingDuration, this._recordingManagerId );
                    _x2._telemetry.sendVideoPlaybackAvailablePercent( true     , manifestDuration, recordingDuration, videoType, this._recordingManagerId );
                }
                else if( this._mode === VideoScreen.Mode.VOD ) // VPAP VOD
                {
                    _x2._telemetry.sendVideoPlaybackAvailableBucket ( videoType, manifestDuration, recordingDuration, "NA" );
                    _x2._telemetry.sendVideoPlaybackAvailablePercent( true     , manifestDuration, recordingDuration, videoType, "NA" );
                }
            }

            if( this._mode === VideoScreen.Mode.LIVE )
            {
                if( this._entity.getChannel )
                {
                    var channel = this._entity.getChannel();

                    if( channel )
                        _x2._settings.setValue( Settings.Key.LAST_CHAN, { id:channel.getStationId(), num:channel.getNumber() } );
                    else
                        console.error( "No channel object found in the entity." );
                } else {
                    console.log("Last channel was not set:", this._entity.getTitle(), ' - ', this._entity._type, ' ', this._mode );
                }
            } else {
                console.log("Last entity's channel was not set:", this._entity.getTitle(), ' - ', this._entity._type, ' ', this._mode );
            }
        }

        if( _x2._focus === this._play )
            _x2.requestFocus( this._pause );

        if( this._mode !== VideoScreen.Mode.LIVE )
        {
            this._targets[this._pp] = this._pause;
            this._rew.setA( 1 );
            this._ff.setA( 1 );
            this._pause.setA( 1 );
            this._play.setA( 0 );
        }
        else
        {
            this._rew.setA( 0 );
            this._play.setA( 0 );
            this._pause.setA( 0 );
            this._ff.setA( 0 );
        }
    };

    VideoScreen.prototype.onPlaybackSpeedChanged = function( speed )
    {
        _x2._config.log( Config.CLASS_VIDEO_SCREEN, 7 ) ? console.log( "PLAY RATE = " + speed ) : Config.NOP();
        this._playRate.setText( "Play Rate = " + speed );
    };

    VideoScreen.prototype.patchUrl = function( url, mode )
    {
        var retval, parts;
        var newUrl   = url;
        var resolver = function( resolve )
        {
            resolve( newUrl );
        };

        console.log( "=================================================================================================" );
        console.log( "=================================================================================================" );
        console.log( url );

        if( url.indexOf( ".m3u8" ) !== -1 )
        {
            console.log( "Converting HLS to DASH, mode = " + mode );

            newUrl = url.replace( /.m3u8/g, ".mpd" );

            console.log( newUrl );

            newUrl = _x2._config._testVideo ? _x2._config._testVideo : newUrl;

            this._recordingManagerId = undefined;

            if( mode === VideoScreen.Mode.DVR )
            {
                console.log( "SWAPPING CDVR HOST" );

                parts             = newUrl.match( /.*\/recording\/(.*)\/.*/ );  // HACK: extract the recording ID
                this._recordingId = parts[1];

                var id = newUrl === undefined ? "NA" : newUrl.split("recorderManager=")[1];
                var n  = id.indexOf( '&' );
                if( n >= 0 )
                    id = id.substr( 0, n );

                this._recordingManagerId = id;

                if( _x2._config._host.getType() === Host.Type.PC || _x2._config._host.getType() === Host.Type.ANDROID )
                {
                    newUrl = _x2._config._testVideo ? _x2._config._testVideo : "http://assets.player.xcal.tv/super8sapcc/index.mpd";
                    retval = new Promise( resolver );
                }
                else
//                    retval = new Promise( resolver );
                    retval = _x2._data.getRecordingRedirect( parts[1] );
            }
            else if( mode === VideoScreen.Mode.LIVE )
            {
                console.log( "SWAPPING LINEAR HOST" );

                if( _x2._config._host.getType() === Host.Type.PC || _x2._config._host.getType() === Host.Type.ANDROID )
                    newUrl = _x2._config._testVideo ? _x2._config._testVideo : "http://assets.player.xcal.tv/super8sapcc/index.mpd";  // Ides of March - SAP CC
//                    newUrl = "http://rdmedia.bbc.co.uk/dash/ondemand/bbb/2/client_manifest-common_init.mpd";

//newUrl = "http://assets.player.xcal.tv/super8sapcc/index.mpd";  // Ides of March - SAP CC
//newUrl = "https://ccr.linear-cent-co-pil-red.xcr.comcast.net/NBCSH_HD_CENTdCO_15201_0_7619126265308348163.mpd?redirect=true";

                retval = new Promise( resolver );
            }
            else if( mode === VideoScreen.Mode.VOD )
            {
                console.log( "SWAPPING VOD HOST" );

//                newUrl = newUrl.replace( /http:/g, "https:" );

                if( _x2._config._host.getType() === Host.Type.PC || _x2._config._host.getType() === Host.Type.ANDROID )
                    newUrl = _x2._config._testVideo ? _x2._config._testVideo : "http://assets.player.xcal.tv/super8sapcc/index.mpd";  // Ides of March - SAP CC
//                    newUrl = "http://rdmedia.bbc.co.uk/dash/ondemand/bbb/2/client_manifest-common_init.mpd";

//newUrl = "http://69.252.103.216/cog_backup/html/dash/c2/1371826319012/1371826319012.mpd";
//newUrl = "http://69.252.103.207/CNN_HD.mpd";
//newUrl = "http://assets.player.xcal.tv/super8sapcc/index.mpd";  // Ides of March - SAP CC

                retval = new Promise( resolver );
            }
        }

        return retval;
    };

    VideoScreen.prototype.play = function( params )
    {
        console.log( params );

        if( this._lastWatched.containsWidget( _x2._focus ) === true )
        {
            this._lastWatched.hide();
            _x2.requestFocus( this );
        }

        this._lastWatched.addEntry( params );

        this._progress      = undefined;
        this._mediaId       = params.mediaId;
        this._programId     = params.programId;
        this._hasDvs        = params.hasDvs;
        this._streamId      = params.streamId;
        this._assetId       = params.assetId;
        this._providerId    = params.providerId;
        this._mediaGuid     = params.mediaGuid;
        this._entity        = params.entity;
        this._paid          = params.paid;
        this._recordingId   = params.recordingId;
        this._url           = params.url;
        this._mode          = params.mode;
        this._initResume    = params.resume;

        if( params && params.url )
        {
            var channel = this._entity.getChannel && this._entity.getChannel(), complement, self = this;
            var pref    = _x2._settings.getValue( Settings.Key.FORMAT );

            if( pref === "hd" && channel && channel.isHD() === false && (complement = channel.getHdComplement()) )
            {
                params.url     = complement.getStreamUrl();
                this._streamId = complement.getStreamId();

                if( this._entity.setChannel )
                    this._entity.setChannel( complement );
            }

            this._attempts = 1;
            this.patchUrl( params.url, params.mode ).then( function( url ) { self.onGotUrl( url, params.resume ); }, this._onUrlError );
        }

        this.setData( params.entity );

        if( this._mode !== VideoScreen.Mode.LIVE )
        {
            this._targets = [ this._last, this._rew, this._pause, this._ff, this._dv, this._sap, this._cc ];
            this._index   = this._pp = 2;

            if( this._mini )
                this._targets.splice( 4, 0, this._mini );
        }
        else
        {
            this._targets = [ this._last, this._dv, this._sap, this._cc ];
            this._index   = this._pp = 1;
            this._pause.setA( 0 );
            this._play.setA( 0 );
            this._ff.setA( 0 );
            this._rew.setA( 0 );

            if( this._mini )
                this._targets.splice( 1, 0, this._mini );
        }
    };

    VideoScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        if( this._easLocked !== true )
        {
            if( type === Host.KEY_PRESSED )
            {
                this._touch = true;

                if( this._showing === false && val !== Host.KEY_LAST && val !== Host.KEY_UP )
                {
                    this._showing = true;
                    this._container.stopAnimation( Widget.Axis.A );
                    this._container.animate( { a:1, duration:500 } );
                }
            }

            switch( val )
            {
                case Host.KEY_DOWN:
                    if( type === Host.KEY_PRESSED )
                    {
                        if( this._showing === false )
                        {
                            this._showing = true;
                            this._container.stopAnimation( Widget.Axis.A );
                            this._container.animate( { a:1, duration:500 } );

                            if( _x2._focus === this._cursor )
                                _x2.requestFocus( this._targets[this._index] );
                        }
                        else
                             _x2.requestFocus( this._targets[this._index] );
                    }
                    break;

                case Host.KEY_ENTER:
                    if( type === Host.KEY_PRESSED )
                        this.togglePlayPause();
                    break;

                case Host.KEY_FF:
                    if( type === Host.KEY_PRESSED )
                        this.fastForwardIncrease();
                    break;

                case Host.KEY_LAST:
                    if( type === Host.KEY_PRESSED )
                    {
                        if( this._showing === true )
                        {
                            this._showing = false;
    //                        this.stopAnimation( Widget.Axis.A );
                            this._container.stopAnimation( Widget.ALPHA );
                            this._container.animate( { a:0, duration:500 } );
                            this.storeResumePoint();
                        }
                        else
                        {
                            this.storeResumePoint();
                            retval = Screen.prototype.processEvent.call( this, val, type );
                        }
                    }
                    break;

                case Host.KEY_LEFT:
                    if( type === Host.KEY_PRESSED )
                    {
                        if( _x2._focus === this._cursor )
                            this.rewindIncrease();
                        else if( this._index > 0 )
                             _x2.requestFocus( this._targets[--this._index] );
                    }
                    break;

                case Host.KEY_MUTE:
                    if( type === Host.KEY_PRESSED )
                        videoPlayer.mute();
                    break;

                case Host.KEY_PAUSE:
                case Host.KEY_PLAY:
                    if( type === Host.KEY_PRESSED )
                        this.togglePlayPause();
                    break;

                case Host.KEY_RIGHT:
                    if( type === Host.KEY_PRESSED )
                    {
                        if( _x2._focus === this._cursor )
                            this.fastForwardIncrease();
                        else if( this._index < this._targets.length - 1 )
                            _x2.requestFocus( this._targets[++this._index] );
                    }
                    break;

                case Host.KEY_RW:
                    if( type === Host.KEY_PRESSED )
                    {
                        this._telemetryRewindTimestamp = Date.now(); // VIDEO_JUMPBACK
                        this.rewindIncrease();
                    }
                    break;

                case Host.KEY_SOFT_D:
                    if( type === Host.KEY_PRESSED )
                    {
                        if( this._bitRate.getA() === 0 )
                        {
                            this._bitRate.setA( 1 );
                            this._playRate.setA( 1 );
                        }
                        else
                        {
                            this._bitRate.setA( 0 );
                            this._playRate.setA( 0 );
                        }
                    }
                    break;

                case Host.KEY_UP:
                    if( type === Host.KEY_PRESSED )
                    {
                        if( this._showing === true )
                        {
                            if( _x2._focus === this._cursor )
                            {
                                this._showing = false;
                                this._touch   = false;
                                this._container.stopAnimation( Widget.Axis.A );
                                this._container.animate( { a:0, duration:500 } );
                            }
                            else
                            {
                                _x2.requestFocus( this._cursor );
                            }
                        }
                        else
                        {
                            this._showing = true;
                            this._container.stopAnimation( Widget.Axis.A );
                            this._container.animate( { a:1, duration:500 } );
                        }
                    }
                    break;

                case Host.KEY_VOL_DOWN:
                    if( type === Host.KEY_PRESSED )
                        videoPlayer.volumeDown();
                    break;

                case Host.KEY_VOL_UP:
                    if( type === Host.KEY_PRESSED )
                        videoPlayer.volumeUp();
                    break;

                default:
                    retval = Screen.prototype.processEvent.call( this, val, type );
                    break;
            }
        }

        return retval;
    };

    VideoScreen.prototype.rewindIncrease = function()
    {
        if( this._speedIndex > 0 && this._mode !== VideoScreen.Mode.LIVE )
        {
            this._speedIndex--;

            _x2._config.log( Config.CLASS_VIDEO_SCREEN, 5 ) ? console.log( "REWIND INCREASE -> " + this._speedIndex + ", " + this._speeds[this._speedIndex] + ", " + this._speedModes[this._speedIndex] ) : Config.NOP();

            if( this._speedModes[this._speedIndex] === VideoScreen.SpeedMode.STATIC )
            {
                if( this._scrubBase === undefined )
                    this._scrubBase = videoPlayer.getCurrentPosition();

                if( this._speedIndex === this._speeds.indexOf( 1 ) )
                {
                    videoPlayer.setSpeed( 1 );
                    videoPlayer.setPosition( this._scrubBase );
                    this._telemetryRewindTimestamp = 0;
                    videoPlayer.play();
                    this._scrubBase = undefined;
                }
                else
                    videoPlayer.pause();
            }
            else
                videoPlayer.setSpeed( this._speeds[this._speedIndex] );

            this.setIndicator();
            this.speakSpeed();
        }
    };

    VideoScreen.prototype.sendVideoPlayedLocalytics = function()
    {
        var type, programType  = "", program = this._entity.getProgram && this._entity.getProgram(), rating, csmr, seconds, minutes, percent;

        switch( this._mode )
        {
            case VideoScreen.Mode.DVR : type = "DVR"      ; break; // VOD can't be recorded, can only record T6 Linear
            case VideoScreen.Mode.LIVE: type = "Live"     ; break;
            case VideoScreen.Mode.VOD : type = "On-Demand"; break; // On-Demand, Purchase, Rental
        }

        if( this._entity instanceof Purchase )
            type = "Purchase";
        else if( this._entity instanceof Rental )
            type = "Rental";

        switch( this._entity.getType() )
        {
//          case "???"         : programType = "Sports Event"; break;
            case "TVListing"   : programType = "TV Episode"  ; break; // Browse > Sports > PGA Tour
            case "Episode"     : programType = "TV Episode"  ; break;
            case "Movie"       : programType = "Movie"       ; break;
            case "SeriesMaster": programType = "TV Series"   ; break;
            case "Other"       : programType = "unknown"     ; break; // Browse > Black Film & TV, HCBU Experience, Music Playlists
            default            : programType = "unknown"     ; break;
        }

        var reviews      = this._entity.getReviews && this._entity.getReviews();
        var now          = Date.now();
        var channel      = (this._entity.getChannel && this._entity.getChannel());
        var metrics      =
        {
            "Captions Utilized"             : _x2._settings.getValue( Settings.Key.CC_ENABLED ) ? "Yes" : "No",
            "Common Sense Media Rating"     : "NA",
            "Downloaded"                    : "No",
            "Entity"                        : this._entity.getEntityId(),
            "Is HD"                         : this._entity.isHD && this._entity.isHD() ? "Yes" : "No",
            "Name"                          : this._entity.getTitle(),
            "Picture in Picture"            : "No",
            "Program Type"                  : programType,
            "Rights Type"                   : "T6", // Alt.: this._entity.getChannel().isTve()
            "Rotten Tomatoes Critics Score" : "NA",
            "Rotten Tomatoes Audience Score": "NA",
            "SAP Audio Utilized"            : _x2._settings.getValue( Settings.Key.SAP_ENABLED ) ? "Yes" : "No",
            "Type"                          : type
        };
        var duration = this._entity.getDurationMs && this._entity.getDurationMs();

        if( duration )
        {
            duration /= 1000;
            seconds   = (duration % 60);
            minutes   = (duration / 60) | 0;

            metrics["Duration (secs)"] = ( seconds < 0 )? 0 : seconds;
            metrics["Duration (mins)"] = ( minutes < 0 )? 0 : minutes;

            if( this._mode !== VideoScreen.Mode.LIVE )
            {
                percent   = (100 * this._localyticsViewedPercent) | 0;
                duration *= this._localyticsViewedPercent;
                duration |= 0;
                seconds   = (duration % 60);
                minutes   = (duration / 60) | 0;

                metrics["Viewed %"     ] = ( percent < 0 )? 0 : percent;
                metrics["Viewed (secs)"] =  ( seconds < 0 )? 0 : seconds;
                metrics["Viewed (mins)"] = ( minutes < 0 )? 0 : minutes;
            }
        }

        if( program )
        {
            csmr   = program.getCommonSenseMediaReview && program.getCommonSenseMediaReview();
            rating = csmr && csmr.getCommonSenseMediaScore();
            if( rating  )
                metrics[ "Common Sense Media Rating" ] = rating;

            if( !reviews )
                reviews = program.getReviews && program.getReviews();

            var season  = program.getSeasonNumber  && program.getSeasonNumber();
            var episode = program.getEpisodeNumber && program.getEpisodeNumber();

            if( season )
                metrics["Season" ] = season;
            if( episode )
                metrics["Episode"] = episode;
        }

        if( reviews && Array.isArray(reviews) && (reviews.length > 0) )
        {
            rating = reviews[0].getCommonSenseMediaScore && reviews[0].getCommonSenseMediaScore(); if( rating ) metrics["Common Sense Media Rating"     ] = "" + rating;
            rating = reviews[0].getCriticSummaryRotten   && reviews[0].getCriticSummaryScore()   ; if( rating ) metrics["Rotten Tomatoes Critics Score" ] = "" + rating;
            rating = reviews[0].getFanSummaryRotten      && reviews[0].getFanSummaryScore()      ; if( rating ) metrics["Rotten Tomatoes Audience Score"] = "" + rating;
        }

        if( this._mode === VideoScreen.Mode.DVR || this._mode === VideoScreen.Mode.LIVE ) // Linear or Recordings (Always Linear)
        {
            if( channel )
            {
                metrics.Station           = channel.getCallSign();
                metrics["Linear Company"] = channel.getCompany() ? channel.getCompany() : ""; // Recording: UEFA Champions League Soccer - Juventus @ Red Devils
            }
        }
        else if( this._mode === VideoScreen.Mode.VOD ) // On-Demand, Purchase, Rental
        {
            if( this._entity.getContentProvider )
                metrics.Provider = this._entity.getContentProvider().getName();
        }

        if( this._localyticsVideoPlayedEntity !== this._entity )
        {
            this._localyticsVideoPlayedEntity = this._entity;
console.log( "Localytics: Video Played: ", metrics.Name );
            window.ll( "tagEvent", "Video Played", metrics ); // Localytics
        }

        this._localyticsOnPlayStartTime = now;
    };

    VideoScreen.prototype.setData = function( showing )
    {
        var titleString, episodeTitle, seriesTitle, series, subTitle = "";
        var isEpisode = showing.getType && showing.getType() === XtvApi.EntityType.EPISODE;
        var program   = showing.getProgram ? showing.getProgram() : showing; //TODO: not sure about this, I think this should be an entity always
        var metrics   =
        {
            "Entity"                 : this._entity.getEntityId(),
            "Episode"                : "NA",
            "Name"                   : this._entity.getTitle(),
            "Program Type"           : this._entity.getType(),
            "Season"                 : "NA",
            "To FF Restricted"       : this._mode === VideoScreen.Mode.LIVE ? "NA" : "No",
            "To HD"                  : showing && showing.isHD && showing.isHD() ? "Yes" : "No",
            "To Rights Type"         : "T6", // VOD can't be recorded, can only record T6 Linear
            "To Video Type"          : "NA",
            "Translated Resume Point": "NA"
        };

        this._subTitleSpeak = undefined;

        if( isEpisode === true )
        {
            metrics["Program Type"] = "Series"; // Re-map "Episode" -> "Series"

            if( showing.getSeries )
                series = showing.getSeries();

            if( series === undefined && ( program && program.getSeries ) )
                series = program.getSeries();

            if( series )
                seriesTitle = series.getTitle();

            episodeTitle = showing.getTitle();

            if( seriesTitle && episodeTitle )
            {
                var s  = program.getSeasonNumber();
                var ep = program.getEpisodeNumber();

                titleString = seriesTitle;

                if( s && ep )
                {
                    var epTitle = (seriesTitle !== episodeTitle) ? " - " + episodeTitle : "";
                    subTitle = "S" + s + " Ep" + ep + epTitle;
                    this._subTitleSpeak = "Season " + s + ". Episode " + ep + ". " + epTitle + ". ";

                    metrics["Season" ] = s;
                    metrics["Episode"] = ep;
                }
                else if( seriesTitle !== episodeTitle )
                {
                    subTitle = episodeTitle;
                    this._subTitleSpeak = episodeTitle;
                }
            }
            else
                titleString = showing.getTitle();
        }
        else
        {
            titleString = showing.getTitle();
            if( metrics["Program Type"] !== "Movie" )
                metrics["Program Type"] = "Other";
            else
            {
                // Movie: showing can be ProgramEntity (VOD) or LinearShowing (Linear)
                var i, showings = showing.getVodShowings && showing.getVodShowings();

                if( showings && !showing.isHD )
                {
                    for( i = 0; i < showings.length; i++ )
                    {
                        if( showings[ i ].getMediaId() === this._mediaId )
                        {
                            metrics["To HD"] = showings[ i ].isHD() ? "Yes" : "No";
                            break;
                        }
                    }
                }
            }
        }

        this._title.setText( titleString );
        this._subTitle.setText( subTitle );
        this._type.setText( typeStr[this._mode] );
        this._image.setUrl( showing.getImageLink( rawImW, rawImH ) );

        switch( this._mode )
        {
            case VideoScreen.Mode.DVR : metrics["To Video Type"] = "DVR"      ; break;
            case VideoScreen.Mode.LIVE: metrics["To Video Type"] = "Linear"   ; break;
            case VideoScreen.Mode.VOD : metrics["To Video Type"] = "VOD"      ; break;
        }

        if( showing instanceof Purchase )
            metrics["To Video Type"] = "Purchase";
        else if( showing instanceof Rental )
            metrics["To Video Type"] = "Rental";

//console.log( "Best Option Chosen: %o", metrics );
        window.ll( "tagEvent", "Best Option Chosen", metrics );
    };

    VideoScreen.prototype.setIndicator = function()
    {
        var which = this._indicators[this._lastIndicator];

        if( which )
            which.setA( 0 );

        this._lastIndicator = this._speedIndex;
        which               = this._indicators[this._speedIndex];

        if( which )
            which.setA( 1 );
    };

    VideoScreen.prototype.setTimes = function( current, start, stop )
    {
        switch( this._mode )
        {
            case VideoScreen.Mode.LIVE:
                var time = _x2._time;

                if( this._start === undefined || time > (this._start + 5400000) )
                {
                    this._start = Math.floor( time / 1800000 ) * 1800000;
                    this._currStr.setText( createClockTimeStr( this._start ) );

                    this._stop = this._start + 5400000;
                    this._stopStr.setText( createClockTimeStr( this._stop ) );
                }

                this._cursor.setX( this._startX + this._cursorOffsetX + (time - this._start) / (this._stop - this._start) * this._progressW );
                break;

            case VideoScreen.Mode.DVR:
            case VideoScreen.Mode.VOD:
                if( this._entity instanceof Recording && this._entity.getRecordingStatus() === "STARTED" )
                {
                    this._progress = stop;
                    stop           = this._entity.getDurationMs();
                    this._leftRect.setW( this._progress / stop * (this._stopX - this._startX) );
                }

                var fract  = current / (stop - start);
                var offset = fract * this._progressW;

                this._localyticsViewedPercent = (fract < 0) ? 0 : fract;
                this._currStr.setText( this.createTimeStr( current !== undefined ? current : 0 ) );

                if( this._start !== start )
                    this._start = start;

                if( this._stop !== stop )
                {
                    this._stop = stop;
                    this._stopStr.setText( this.createTimeStr( stop ) );
                }

                this._cursor.setX( this._startX + this._cursorOffsetX + offset );
                break;

            default:
                console.error( "ERROR => unknown mode = " + this._mode );
                break;
        }
    };

    VideoScreen.prototype.showDistractor = function( isStatic )
    {
        this._netIconImage.setA(0);
        this._distractor.show( isStatic );
    };

    VideoScreen.prototype.showLastWatched = function()
    {
        console.log( "LAST" );

        this._lastWatched.show();
        _x2.requestFocus( this._lastWatched );
    };

    VideoScreen.prototype.speak = function( silent )
    {
        var channel, str = "", title = this._title.getText();

        if( this._entity.getChannel )
        {
            channel = this._entity.getChannel();

            if( channel )
                str = "Channel " + channel.getNumber() + ". ";
            else
                console.error( "No channel object found in the entity." );
        }

        if( title )
            str += title + ". ";

        if( this._subTitleSpeak )
            str += this._subTitleSpeak;

        this._speakTarget.setText( "Now Playing. " + str + ". " );

        if( silent !== true )
            this._speakTarget.setSpeechParams( "Press down to review options. Loading, please wait. ", undefined, this, false );

        return this._speakTarget.getText();
    };

    VideoScreen.prototype.speakSpeed = function()
    {
        var str, val = this._speedIndex - this._speeds.indexOf( 1 );

        if( val > 0 )
            str = "Fast Forward " + val + ". ";
        else if( val < 0 )
            str = "Rewind " + (-1 * val) + ". ";
        else
            str = "Play. ";

        this._speakTarget.setText( str );
        this._speakTarget.setSpeechParams( undefined, undefined, this, false );
    };

    VideoScreen.prototype.storeResumePoint = function()
    {
        if( this._mode === VideoScreen.Mode.VOD || this._mode === VideoScreen.Mode.DVR )
        {
            var resume = (this._resume !== undefined) ? this._resume : this._current;
            if( resume !== undefined ) {
                _x2.setResumePointInCache( this._mediaId, resume );
                _x2._data.setResumePoint( this._mediaId, this._programId, resume );
            }
        }
    };

    VideoScreen.prototype.tick = function()
    {
        var self = this;

        console.log( "VIDEO SCREEN TICK, isLinear = " + (this._mode === VideoScreen.Mode.LIVE) + ', ticked at ', new Date() );
        var lastScreen = _x2._screenRoot.getTop();
        if( this._mode === VideoScreen.Mode.LIVE && lastScreen instanceof VideoScreen  )
        {
            var displayCurrentlyAiring = function( showing )
            {
                if( self._entity.getProgramId() !== showing.getProgramId() || 
                    self._entity.getStartTime() !== showing.getStartTime() )
                {
                    self.sendVideoPlayedLocalytics();
                    console.log("Last entity - ", self._entity.getTitle(), 
                                ", duration -", new Date( self._entity.getStartTime() ) ,' : ' , new Date( self._entity.getEndTime() ) );
                    self._entity = showing;
                    console.log( "NEW ENTITY NEW ENTITY NEW ENTITY" );
                    console.log("New entity - ", self._entity.getTitle(), 
                                ", duration -", new Date( self._entity.getStartTime() ) ,' : ' , new Date( self._entity.getEndTime() ) );
                    
                    self.setData( showing );
//                    self.layout();

                    var onLockCheck = function( isLocked )
                    {
                        console.log( "LOCKED = " + isLocked );

                        if( isLocked )
                        {
                            videoPlayer.setPcBlock( true );

                            var onUnlockChannel = function()
                            {
                                console.log( "START SHOWING VIDEO" );
                                videoPlayer.setPcBlock( false );
                            };

                            _x2.checkParentalControls( showing, showing.getProgram(), showing._channel, false, self.speak() ).then( onUnlockChannel, onLockCheckFailed );
                        }
                        else
                            self.speak();
                    };

                    var onLockCheckFailed = function( error )
                    {
                        console.error( error );
                    };

                    _x2.checkParentalControls( showing, showing.getProgram(), showing._channel, true ).then( onLockCheck, onLockCheckFailed );
                }
                else
                    console.log( "NO CHANGE NO CHANGE" );
            };

            var currentlyAiringLookupFailed = function( error )
            {
                console.error( error );
            };

            if( this._entity.getChannel )
            {
                var channel = this._entity.getChannel();

                if( channel ) {
                    var currentPlaying = channel._onNow;
                    var canFetchNextProgram = false;
                    if( !currentPlaying ) {
                        canFetchNextProgram =  Date.now() >= this._entity.getEndTime();
                        console.log("Currently playing entity - ", this._entity.getTitle(),
                        ", end at", new Date(this._entity.getEndTime()) );
                    } else {
                        canFetchNextProgram = (this._entity.getProgramId() !== currentPlaying.getProgramId() ||
                            this._entity.getStartTime() !== currentPlaying.getStartTime());
                        console.log("Currently playing entity - ", currentPlaying.getTitle(), 
                            ", end at", new Date(currentPlaying.getEndTime()) );
                    }
                    // Fetch get airing, if the current program get ends within time or program id got changed.
                    if (canFetchNextProgram) {
                        channel.getCurrentlyAiring().then( displayCurrentlyAiring ).catch( currentlyAiringLookupFailed );
                    } else {
                        console.log("No change in currently playing program");
                    }
                }
                else
                    currentlyAiringLookupFailed( "No channel object found in the entity." );
            }
        }
    };

    VideoScreen.prototype.toggleCaptions = function()
    {
        this._touch = true;

        if( _x2._settings.getValue( Settings.Key.CC_ENABLED ) === true )
        {
            this.createLabelStr( "cc", "Off", true );
            _x2._settings.setValue( Settings.Key.CC_ENABLED, false );
            videoPlayer.setCaptionsEnabled( false );
        }
        else
        {
            this.createLabelStr( "cc", "On", true );
            _x2._settings.setValue( Settings.Key.CC_ENABLED, true );
            videoPlayer.setCaptionsEnabled( true );
        }
    };

    VideoScreen.prototype.toggleDv = function()
    {
        this._touch = true;

        var langs     = videoPlayer.getLanguages();
        var available = langs.length > 1 && this._hasDvs;

console.log( langs );
console.log( "=> HAS DVS = " + this._hasDvs );

        if( _x2._settings.getValue( Settings.Key.DV_ENABLED ) === true )
        {
            this.createLabelStr( "dv", available === true ? "Off" : "Not Available", true );
            _x2._settings.setValue( Settings.Key.DV_ENABLED, false );

            if( available === true )
            {
                console.log( ">>>>>>>>>>>>>>>>>>>> SET LANGUAGE TO 'en'" );
                videoPlayer.setLanguage( "en" );
            }
        }
        else
        {
            this.createLabelStr( "dv", available === true ? "On" : "Not Available", true );
            _x2._settings.setValue( Settings.Key.DV_ENABLED, true );

            if( available === true )
            {
                console.log( ">>>>>>>>>>>>>>>>>>>> SET LANGUAGE TO 'es'" );
                videoPlayer.setLanguage( "es" );  // HACK since always coming through as Spanish at this point
            }
        }
    };

    VideoScreen.prototype.togglePlayPause = function()
    {
        if( this._mode !== VideoScreen.Mode.LIVE )
        {
            var state = videoPlayer.getState();

            if( state === VideoScreen.State.PAUSED )
            {
                if( this._scrubBase !== undefined )
                {
                    this._speedIndex = this._speeds.indexOf( 1 );
                    videoPlayer.setSpeed( 1 );
                    videoPlayer.setPosition( this._scrubBase );
                    this._scrubBase = undefined;
                }

                this._telemetryRewindTimestamp = 0;
                videoPlayer.play();
            }
            else if( state === VideoScreen.State.PLAY )
            {
                if( videoPlayer.getPlaySpeed() !== 1 )
                {
                    this._speedIndex = this._speeds.indexOf( 1 );
                    videoPlayer.setSpeed( 1 );
                }
                else
                    videoPlayer.pause();
            }

            this.setIndicator();
        }
    };

    VideoScreen.prototype.toggleSap = function()
    {
        this._touch = true;

        var langs = videoPlayer.getLanguages();

console.log( langs );
console.log( _x2._settings.getValue( Settings.Key.DV_ENABLED ) + "  " + _x2._settings.getValue( Settings.Key.SAP_ENABLED ) + " " + this._hasDvs );

        if( _x2._settings.getValue( Settings.Key.SAP_ENABLED ) === true )
        {
            this.createLabelStr( "sap", "Off", true );
            _x2._settings.setValue( Settings.Key.SAP_ENABLED, false );

            if( this._hasDvs === false )
            {
                console.log( ">>>>>>>>>>>>>>>>>>>> SET LANGUAGE TO en" );
                videoPlayer.setLanguage( "en" );
            }
        }
        else
        {
            this.createLabelStr( "sap", "On", true );
            _x2._settings.setValue( Settings.Key.SAP_ENABLED, true );

            if( this._hasDvs === false )
            {
                console.log( ">>>>>>>>>>>>>>>>>>>> SET LANGUAGE TO " + _x2._settings.getValue( Settings.Key.SAP_LANG ) );
                videoPlayer.setLanguage( _x2._settings.getValue( Settings.Key.SAP_LANG ) );
            }
        }
    };

    VideoScreen.prototype.update = function( step, alphaParent )
    {
        Screen.prototype.update.call( this, step, alphaParent );

        if( this._scrubBase !== undefined )
        {
            var stop = this._progress === undefined ? this._stop : this._progress;

            this._touch      = true;
            this._scrubBase += (step - this._lastStep) * this._speeds[this._speedIndex];

            if( this._scrubBase <= this._start )
            {
                this._scrubBase = this._start;
                this.onMediaProgress( this._scrubBase, this._start, stop );
                this.togglePlayPause();
            }
            else if( this._speeds[this._speedIndex] > 0 && this._scrubBase > (stop - 15000) )
            {
                this._scrubBase = stop - 15000;
                this.onMediaProgress( this._scrubBase, this._start, stop );
                this.togglePlayPause();
            }
            else
                this.onMediaProgress( this._scrubBase, this._start, stop );
        }

        this._lastStep = step;

        if( this._touch === true )
        {
            this._touch     = false;
            this._lastTouch = step;
        }

        if( this._showing === true && step > this._lastTouch + 5000 )
        {
            this._showing = false;
            this._container.stopAnimation( Widget.Axis.A );
            this._container.animate( { a:0, duration:500 } );

            if( this.containsWidget( _x2._focus ) === true && this._lastWatched.containsWidget( _x2._focus ) === false )
                _x2.requestFocus( this._cursor );
        }
    };

    return VideoScreen;

})();
