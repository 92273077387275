// Copyright (C) 2017 Comcast Corporation, All Rights Reserved

window.HTMLStringTestScreen = ( function()
{
    "use strict";

    HTMLStringTestScreen.prototype = new Screen();

    function HTMLStringTestScreen(){}

    HTMLStringTestScreen.prototype.init = function()
    {
        var fontSize   = 60; // 28;
        var fontWeight = "light"; // "bold", "medium", "light"

        this._className = "HTMLStringTestScreen";
        Screen.prototype.init.call( this );

        this._bg = new RectWidget().init( { w:_x2._config._screenW, h:_x2._config._screenH, color:"#000000" } );
        this.addWidget( this._bg );

        var style = new Style( { font:fontWeight, color:"#FFFF00", fontSize:fontSize, overflow:"hidden" } ); // yellow
        var text = "Test <font color='#80F'>Bar</font><br>"
                 + "<font color='#F00'>Hello World &lt; Normal</font><br>"
                 + "<font color='#0F0'><b>Hello World &lt; Bold</b></font><br>"
                 + "<font color='#00F'><i>Hello World &lt; Italic</i></font><br>"
                 + "<font color='#FFF'><b><i>Hello World &lt; Bold Italic</i></b></font><br>"
                 + "A <b>A</b> <i>A</i> <b><i>A</b></i>";
        this._htmlstring = new StringWidget().init( { text: text, style: style } );

        this.addWidget( this._htmlstring, Style._safeLeft, Style._safeTop );

        return this;
    };

    HTMLStringTestScreen.prototype.gotFocus = function()
    {
        _x2._hi.fadeOut();
    };

    HTMLStringTestScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        if( type === Host.KEY_PRESSED )
            switch( val )
            {
                default:
                    retval = Screen.prototype.processEvent.call( this, val, type );
                    break;
            }

        return retval;
    };

    return HTMLStringTestScreen;

})();

