// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.GridWidget = ( function()
{
    "use strict";

    GridWidget.prototype = new Widget();

    function GridWidget(){}

    GridWidget.prototype.destroy = function()
    {
        Widget.prototype.destroy.call( this );
        _x2.removeChangeCallback( this._tickWrapper );
    };

    GridWidget.prototype.filterChannels = function()
    {
        var channels, filters, i, j;

        if( _x2._channelCollection )
        {
            channels = _x2._channelCollection.getChannels();
            filters  = _x2._settings.getValue( Settings.Key.FILTERS );

            if( filters.hd || filters.cc || filters.sap || filters.dvs || this._view )
            {
                var result = [], schedules, first, last;
                var now    = new Date().getTime(), end = now + 5 * 30 * 60 * 1000;

                for( i = 0; i < channels.length; i++ )
                {
                    if( (filters.hd === false || channels[i].isHD() === true) && (this._view !== "favs" || _x2._favorites.isFavorite( channels[i] )) )
                    {
                        schedules = channels[i].getSchedules();
                        first     = -1;
                        last      = schedules.length - 1;

                        // find current schedule

                        for( j = 0; j < schedules.length; j++ )
                        {
                            if( now >= schedules[j].getStartTime() )
                                first = j;

                            if( first > -1 && end < schedules[j].getStartTime() )
                            {
                                last = j;
                                break;
                            }
                        }

                        for( j = first; j < last; j++ )
                        {
                            if( schedules[j] )
                            {
                                if( (filters.cc === false || schedules[j].isClosedCaptioned() === true) && (filters.sap === false || schedules[j].isSap() === true) && (filters.dvs === false || schedules[j].isDvs() === true) )
                                {
                                    result.push( channels[i] );
                                    break;
                                }
                            }
                        }
                    }
                }

                channels = result;
            }
        }

        return channels;
    };

    GridWidget.prototype.filtersChanged = function()
    {
        var currentId, currentCh;
        var obj      = this._list.getCurrentAndMaxIndex();
        var index    = obj.current;
        var channels = obj.rows;

        if( channels && channels.length > 0 )
        {
            currentId = channels[index].getStationId();
            currentCh = channels[index].getNumber();
        }
        else
        {
            var lastChannel = _x2._settings.getValue( Settings.Key.LAST_CHAN );

            if( lastChannel )
            {
                currentId = lastChannel.id;
                currentCh = lastChannel.num;
            }
        }

        channels = this.filterChannels();

        this._list.setData( channels );

        if( channels && channels.length > 0 )
        {
            this._emptyMessage.hide();

            for( var i = 0; i < channels.length; i++ )
                if( channels[i].getStationId() === currentId && channels[i].getNumber() === currentCh )
                {
                    this._list.setJumpToIndex( i );
                    break;
                }
        }
        else
            this.showEmptyMessage();
    };

    GridWidget.prototype.gotFocus = function()
    {
        if( this._dataReady === true )
            this.speak( _x2.requestFocus( this._list, true ) );

        this.positionInfoWidget();
    };

    GridWidget.prototype.positionInfoWidget = function () {
        if( this._positioned !== true )
        {
            var pos = this._info.getGlobalPos();
            this._info.setX( -pos.x );
            this._info.setY( -pos.y );
            this._positioned = true;
        }
    }

    /**
     * Initializer
     * @memberof GridWidget
     * @param   {Object}  params
     * @param   {Number}  params.h       - Height in pixels
     * @param   {Boolean} params.hasInfo - Instantiate and show the program info
     * @param   {String}  params.view    - Grid view to display
     * @param   {Number}  params.w       - Width in pixels
     * @returns {GridWidget}
     */
    GridWidget.prototype.init = function( params )
    {
        var self       = this, timeParams = {}, listParams = {}, date, numCells, strs, onChannelRowReady, onChannelsLoaded, onChannelsFailed;
        var sepParams  = { w:params.w, h:ListRowWidget.DIVIDER_H, color:"#303030" };
        var dateStyle  = new Style( { color:"#8c8c8c", font:"medium", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap", colorHi:"#e8e8e8" } );
        var y          = _x2.scaleValInt(66);

        Widget.prototype.init.call( this );
        this._className  = "GridWidget";
        this._selectable = true;
        this._info       = (params.hasInfo === true) ? new GridInfoWidget().init() : undefined;
        this._view       = params.view;

        this.setW( params.w );
        this.setH( params.h );

        // find the current grid start time and round down to the half hour boundry
        if( _x2._config._startTime ) // DEBUG: No CLASS_GRID ?
            date = new Date( _x2._config._startTime );
        else
            date = new Date();

        date.setMilliseconds( 0 );
        date.setSeconds( 0 );
        date.setMinutes( (date.getMinutes() < 30) ? 0 : 30 );

        this._datum         = date.getTime();
        this._gridTime      = this._datum;
        this._focusColTime  = this._datum;
        this._minTime       = this._datum;
        this._refreshAt     = this._minTime + 1800 * Math.random() * 1000
        this._stepTime      = 300;
        this._index         = 0;
        console.log('REFRESH AT:', new Date(this._refreshAt));

        // create the time strings

        numCells = 480;
        strs     = new Array( numCells );

        for( var i = 0; i < numCells; i++ )
        {
            strs[i] = _x2.createTimeStr( date );
            date.setTime( date.getTime() + 30 * 60 * 1000 );
        }
        // create time list along the top of the grid

        this._dateStr = new StringWidget().init( { style:dateStyle, text:"FPO" } );
        this._dateStr.setA( 0 );
        this.setDateString( new Date(), new Date());//Set today date as default, at intial loading
        this._dateStr.addReadyCallback( function() { 
            self._dateStr.setY( (y - self._dateStr.getH()) / 2 );
            self._dateStr.setA( 1 );
        } );
        this.addWidget( this._dateStr, 3 * Style._pad );

        var requestSchedules = function( val )
        {
            if( val === undefined )
                val = 0;

            var startTime  = self._gridTime + val * 30 * 60 * 1000;
            var stopTime   = startTime + 3 * 60 * 60 * 1000;
            var nowDate    = new Date();
            var startDate  = new Date( startTime );

            self.setDateString( nowDate, startDate );

            self.requestSchedules( startTime, stopTime, nowDate.getTime() );
        };

        // put lines above and below the time bar

        this._upperTimeSep = new RectWidget().init( sepParams );
        this._lowerTimeSep = new RectWidget().init( sepParams );
        this.addWidget( this._upperTimeSep );
        this.addWidget( this._lowerTimeSep, 0, y - ListRowWidget.DIVIDER_H );

        timeParams.w                 = _x2._config._screenW;
        timeParams.h                 = _x2.scaleValInt( 60 );
        timeParams.maxIndex          = 0;
        timeParams.axis              = ListWidget.Axis.X;
        timeParams.rows              = strs;
        timeParams.stepListener      = function( val ) { self.onStepListener( val ); };
        timeParams.stepStartListener = requestSchedules;
        timeParams.stepStopListener  = requestSchedules;
        timeParams.stepTime          = this._stepTime;
        timeParams.type              = TimeCellWidget;
        this._timeList               = new ListWidget().init( timeParams );
        this.addWidget( this._timeList, 0, ListRowWidget.DIVIDER_H );
        this._timeList.suppressSelection( true );
        this._timeList.setX( _x2.scaleValInt(210) );//Add space for date string to avoid overlapping
        
        // create the list for the channel rows

        listParams.w        = params.w;
        listParams.h        = params.h - timeParams.h;
        listParams.maxIndex = Math.floor( listParams.h / new GridRowWidget().getHeightUnfocused() / 2 ) - 1;
        listParams.obj      = this;
        listParams.type     = GridRowWidget;
        listParams.inner    = true;
        listParams.stepTime = 150;
        this._list          = new ListWidget().init( listParams );
        this.addWidget( this._list, 0, y );

        if( this._info )
            this._list.addWidget( this._info );

        // when the row lays out, shift the time bar past the channel column

        onChannelRowReady = function()
        {
            self._timeList.setX( self._list._cells[0]._chanColW );
            self._timeList.setW( _x2._config._screenW - self._timeList.getGlobalPos().x );
        };

        this._list._cells[0].addReadyCallback( onChannelRowReady );

        this._emptyMessage = new EmptyMessageWidget().init( EmptyMessageWidget.Key.GRID_FILTER );
        this.addWidget( this._emptyMessage );

        if( this._view === "favs" )
        {
            var noFavsStyle = new Style( { font:"light"  , color:"#e8e8e8", fontSize:_x2.scaleValInt(62), whiteSpace:"nowrap", textAlign:"center" } );
            var titleStyle  = new Style( { font:"medium" , color:"#d1d1d1", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap" } );
            var stepStyle   = new Style( { font:"bold"   , color:"#d1d1d1", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );
            var textStyle   = new Style( { font:"regular", color:"#8c8c8c", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap" } );
            var w           = params.w - Style._safeRight;

            var layoutNoFavs = function()
            {
                self._favsImage.setX( _x2.scaleValInt( 675 ) - self._favsImage.getW() );
                self._favsImage.setY( _x2.scaleValInt(  40 ) + self._noFavs.getH() );

                var x = self._favsImage.getX() + self._favsImage.getW() + _x2.scaleValInt( 180 );

                self._title.setX   ( x );
                self._step1Str.setX( x );
                self._text1Str.setX( x );
                self._step2Str.setX( x );
                self._text2Str.setX( x );

                self._title.setY   ( _x2.scaleValInt( 190 ) );
                self._step1Str.setY( self._title.getY   () + self._title.getH   () + _x2.scaleValInt( 34 ) );
                self._text1Str.setY( self._step1Str.getY() + self._step1Str.getH() + _x2.scaleValInt( 13 ) );
                self._step2Str.setY( self._text1Str.getY() + self._text1Str.getH() + _x2.scaleValInt( 34 ) );
                self._text2Str.setY( self._step2Str.getY() + self._step2Str.getH() + _x2.scaleValInt( 13 ) );

                self._container.setH( self._favsImage.getY() + self._favsImage.getH() );
            };

            this._container = new Widget().init();
            this._favsImage = new ImageWidget().init( { url:_x2._config._imageRoot + "favorite.jpg", onLoad:layoutNoFavs } );
            this._noFavs    = new StringWidget().init( { style:noFavsStyle, text:"You haven't favorited any channels ..." } );
            this._title     = new StringWidget().init( { text:"How to Add Favorite Channels", style:titleStyle } );
            this._step1Str  = new StringWidget().init( { text:"STEP 1", style:stepStyle } );
            this._text1Str  = new StringWidget().init( { text:"Press the left arrow to go to the channel column.", style:textStyle } );
            this._step2Str  = new StringWidget().init( { text:"STEP 2", style:stepStyle } );
            this._text2Str  = new StringWidget().init( { text:"Select a channel, then add it as a Favorite.", style:textStyle } );

            this._noFavs.setW( w );
            this._container.setW( w );
            this._container.setA( 0 );

            this._container.addWidget( this._favsImage );
            this._container.addWidget( this._noFavs );
            this._container.addWidget( this._title );
            this._container.addWidget( this._step1Str );
            this._container.addWidget( this._text1Str );
            this._container.addWidget( this._step2Str );
            this._container.addWidget( this._text2Str );
            this.addWidget( this._container, 0, _x2.scaleValInt( 90 ) );
        }

        // create the distractor used while data is loading

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( function() { self._distractor.centerOnScreen(self); } );
        this.addWidget( this._distractor );

        // get a global tick every minute to check when the grid needs to advance at the half hour

        this._tickWrapper = function() { self.tick(); };
        _x2.addChangeCallback( this._tickWrapper );

        // get the grid channel data

        onChannelsLoaded = function()
        {
            if( !_x2._channelCollection.getChannels().length )
            {
                console.error( "No channels loaded!?" );
                self.setElementVisibility();
            }

            var onScheduleBlock = function()
            {
                self.setChannels();
                self._dataReady = true;

                self.setDateString( new Date(), new Date( self._gridTime ) );// date should be set after element get visibility

                var whichTab = "Live TV: All Channels";
                var millisecondsLoadDuration = new Date() - self._timePageLoadStart; // Localytics
                window.ll( "tagEvent", "LiveTV Load Time", { "Duration": millisecondsLoadDuration } );
                _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "LiveTV All, Page Load duration: " + millisecondsLoadDuration + " (ms)" ) : Config.NOP();

                _x2._config.log( Config.CLASS_TELEMETRY, 2 ) ? console.info( "Telemetry: Load Time:" + whichTab + ", Load Time: " + millisecondsLoadDuration + " (ms)" ) : Config.NOP();
                _x2._telemetry.sendScreenLoadTime( millisecondsLoadDuration, whichTab );

                self._distractor.hide();

                if( _x2._focus === self )
                    self.speak( _x2.requestFocus( self._list, true ) );
            };

            _x2._channelCollection.fetch( self._gridTime, self._gridTime + 3 * 60 * 60 * 1000).then( onScheduleBlock ).catch( onChannelsFailed );
        };

        onChannelsFailed = function( error )
        {
            self._list.setA(0);
            self._distractor.hide();
            self._emptyMessage.setMessage( EmptyMessageWidget.Key.GRID_ERROR );
            self._emptyMessage.show();

            console.error( error.toString() );
        };

        
        self._distractor.show();
        if( self._gridTime > this._refreshAt ) {
            _x2.refreshFavorites(); //.then( onRefresh );
        }
        _x2._channelCollection && _x2._channelCollection.fetch( self._gridTime ).then( onChannelsLoaded, onChannelsFailed );
        this._timePageLoadStart = new Date(); // Localytics

        return this;
    };

    GridWidget.prototype.setH = function( val )
    {
        Widget.prototype.setH.call( this, val );

        if( this._list )
            this._list.setH( val );
    };

    GridWidget.prototype.onHideInfo = function( requestFocus )
    {
        var self = this;
        this._localyticsFirstTimeCellInfo = false;

        if( this._info )
        {
            this._infoShowing = false;
            this._list.suppressSelection( false );

            if( requestFocus === true )
            {
                this._info.animate( { a:0, duration:500, onEnd: function() { self._info.unsetEntity(requestFocus) } } );
                var lastChannel = _x2._settings.getValue( Settings.Key.LAST_CHAN );
                var gridHasLastChannel = false;
                for( var i = 0; i < this._list._cells.length; i++ ) {
                    if( this._list._cells[i]._channel && lastChannel &&
                        (this._list._cells[i]._channel.getNumber() == lastChannel.num ) ) {
                            gridHasLastChannel = true;
                        break;
                    }
                }
                if( !gridHasLastChannel ) {
                    this.setChannels();
                }
                _x2.requestFocus( this );
            }
            else
            {
                this._info.setA( 0 );
                this._info.unsetEntity( requestFocus );
            }
        }
    };

    GridWidget.prototype.setElementVisibility = function( channels )
    {
        if( channels === undefined || channels.length === 0 )
        {
            if( _x2._favorites.hasChannelFavorites() === false )
            {
                this._timeList.setA( 0 );
                this._upperTimeSep.setA( 0 );
                this._lowerTimeSep.setA( 0 );
                this._dateStr.setA( 0 );

                if( this._container )
                {
                    this._container.setA( 1 );
                    this._emptyMessage.hide();
                }
                else
                    this.showEmptyMessage();
            }
            else
            {
                this.showEmptyMessage();

                if( this._container )
                    this._container.setA( 0 );
            }
        }
        else
        {
            this._emptyMessage.hide();
            this._timeList.setA( 1 );
            this._upperTimeSep.setA( 1 );
            this._lowerTimeSep.setA( 1 );
            this._dateStr.setA( 1 );
            if( this._container )
                this._container.setA( 0 );
        }
    };

    GridWidget.prototype.setProgramInfo = function( showing, channel, isChannel )
    {
        var self = this, success, error;
        var onMetrics = function()
        {
            // Normally this would reside in `onShowInfo()` but since w don't have the recording information
            // we would need to use `showing.getDetail().then( onDetails )` -- however this introduces
            // an extra network trip.
            // In `setProgramInfo()` we have the data to tell if this program is/has a scheduled recording
            // but one minor caveat is that the user can press up/down in the program modal dialog
            // re-triggering sending the metrics data.
            if( !self._localyticsFirstTimeCellInfo )
            {
                var title    = showing.getTitle();
                var start    = showing.getStartTime();
                var end      = showing.getEndTime();
                var duration = end - start;
                var metrics  =
                {
                    "Channel"  : channel.getNumber(),
                    "Title"    : title,
                    "Start"    : start,
                    "End"      : end,
                    "Duration" : duration,
                    "Scheduled": showing.isRecorded() ? 'yes' : 'no'
                };

                self._localyticsFirstTimeCellInfo = true;
                window.ll( "tagEvent", "Cell Info LiveTV", metrics ); // Localytics 2.3.3
                _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Live TV: Cell Info: %o", metrics ) : Config.NOP();
            }
        };

        this._info.unsetEntity(false);

        success = function( data )
        {
            onMetrics();
            self._info.setEntity( data, channel, self._list.getCurrentAndMaxIndex(), isChannel );
        };

        error = function( error )
        {
            onMetrics();
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.GUIDE } ) );
        };

        if( showing.isTbd() )
            success( showing );
        else
            showing.getDetail().then( success ).catch( error );
    };

    // @param {LinearShowing} showing
    // @param {Channel} channel
    GridWidget.prototype.onShowInfo = function( showing, channel, isChannel )
    {
        this.positionInfoWidget();
        
        if( this._info && this._infoShowing !== true )
        {
            this._infoShowing = true;
            this._list.suppressSelection( true );
            this._info.animate( { a:1, duration:500 } );
            _x2.requestFocus( this._info );

            this.setProgramInfo( showing, channel, isChannel );
        }
    };

    GridWidget.prototype.onStepListener = function( val )
    {
        this._gridTime = this._datum + val * 30 * 60 * 1000;
        var nextStepDate = new Date(this._gridTime);
        // we should not update date for every step, it should be update near 23:58 to 00:03
        if( ( nextStepDate.getHours() == 0 && nextStepDate.getMinutes() < 3 ) || (nextStepDate.getHours() == 23 && nextStepDate.getMinutes() > 58 ) ) {
            if( nextStepDate.getHours() == 0 && nextStepDate.getMinutes() == 0 ) {
                this.setDateString( new Date(), nextStepDate );        
            }
        } else { //if it continue scrolling, then so we need to update after 200 ms to avoid date str get jump back and forth
            window.setTimeout( function() {
                var nextStepDate = new Date(this._gridTime);
                if( this._dateStr ) {
                    var displayDate = new Date(this._dateStr._timeStamp);
                    if( displayDate.getDate() != nextStepDate.getDate() ) {
                        this.setDateString(new Date(), nextStepDate);
                    }
                }
            }.bind(this), 500);
        }
    };

    GridWidget.prototype.onUpdateInfo = function( showing, channel, isChannel )
    {
        if( this._infoShowing )
            this.setProgramInfo( showing, channel, isChannel );

        return !this._infoShowing;
    };

    GridWidget.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_LAST:
            case Host.KEY_UP:
                if( type === Host.KEY_PRESSED && this._infoShowing === true )
                    this.onHideInfo( true );
                else
                    retval = Screen.prototype.processEvent.call( this, val, type );
                break;

            case Host.KEY_LEFT:
                if( this._gridTime > this._minTime )
                    this._timeList.processEvent( val, type );
                break;

            case Host.KEY_RIGHT:
                this._timeList.processEvent( val, type );
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval;
    };

    GridWidget.prototype.setChannels = function()
    {
        var lastChannel = _x2._settings.getValue( Settings.Key.LAST_CHAN );
        var channels = this.filterChannels();

        this._list.setData( channels );

        if( lastChannel && channels )
            for( var i = 0; i < channels.length; i++ )
                if( channels[i].getStationId() === lastChannel.id && channels[i].getNumber() === lastChannel.num )
                {
                    this._list.setJumpToIndex( i );
                    break;
                }

        this.setElementVisibility( channels );
    };

    GridWidget.prototype.refresh = function( screen )
    {
        var lastOverlayScreen = _x2._overlayRoot.getTop();
        var lastScreen = _x2._screenRoot.getTop();
        if( lastOverlayScreen instanceof PinOverlay || lastScreen instanceof VideoScreen ) {
            if( lastScreen instanceof VideoScreen ) {
                var lastChannel = _x2._settings.getValue( Settings.Key.LAST_CHAN );
                var entity = ( this._info._detail && this._info._detail._data );
                if( entity && (entity.getChannel().getNumber() !== lastChannel.num) ) {
                    this.onHideInfo( false );
                }
                this.setChannels();
            }
            return false;
        }//pin overlay screen is display, then we should not hide the grid info widget and set data for grid
        this.onHideInfo( false );
        this._list.setData();

        if( screen && this._info._actionButtons.containsWidget( screen._lastFocus ) )
            screen._lastFocus = this;

        var date = new Date();
        date.setMilliseconds( 0 );
        date.setSeconds( 0 );
        date.setMinutes( (date.getMinutes() < 30) ? 0 : 30 );

        if( date.getTime() > this._gridTime )
        {
            this._datum         = date.getTime();
            this._gridTime      = this._datum;
            this._focusColTime  = this._datum;
            this._minTime       = this._datum;

            this.setTimeList( date );
            this.setDateString( date, date );
            this.requestSchedules( this._gridTime, this._gridTime + 3 * 60 * 60 * 1000 );
        }

        this.setChannels();
    };

    GridWidget.prototype.requestSchedules = function( startTime, endTime )
    {
        var self = this;
        var resetChannels = ( _x2._channelCollection === undefined );

        if( this._distractorCount === undefined )
            this._distractorCount = 0;

        var onScheduleBlock = function()
        {
            self._distractorCount--;
            var loadedBlocked = _x2._channelCollection._loadedBlocks;
            if( self._distractorCount < 1 )
            {
                self._distractor.hide();
                self._dataReady = true;
                if( resetChannels )
                    self.setChannels();
            }
        };

        this._distractorCount++;

        if( this._distractorCount < 2 )
        {
            this._dataReady = false;
            this._distractor.show();
        }

        _x2.fetchChannelCollection().then( function() {
            _x2._channelCollection.fetch( startTime, endTime ).then( onScheduleBlock );
        } );
    };

    GridWidget.prototype.setDateString = function( nowDate, startDate )
    {
        var startMonth = startDate.getMonth() + 1;
        var startDay   = startDate.getDate();
        var str        = ( (startMonth === (nowDate.getMonth()+1) && startDay === nowDate.getDate()) ? "Today " : "" ) + startMonth + "/" + startDay;
        this._dateStr._timeStamp = startDate; 
        this._dateStr.setText( str );
    };

    GridWidget.prototype.showEmptyMessage = function()
    {
        var filters = _x2._settings.getValue( Settings.Key.FILTERS );

        if( filters.hd === true || filters.cc === true || filters.dvs === true || filters.sap === true )
            this._emptyMessage.setMessage( EmptyMessageWidget.Key.GRID_FILTER );
        else
            this._emptyMessage.setMessage( EmptyMessageWidget.Key.GRID_EMPTY );

        this._emptyMessage.show();
    };

    GridWidget.prototype.setJumpToChannel = function( channel )
    {
        var currentId = channel.getStationId();
        var currentCh = channel.getNumber();
        var channels  = this.filterChannels();
        var jumpSet   = false;

        if( channels && channels.length > 0 )
        {
            this._emptyMessage.hide();

            for( var i = 0; i < channels.length; i++ )
                if( channels[i].getStationId() === currentId && channels[i].getNumber() === currentCh )
                {
                    this._list.setJumpToIndex( i );
                    jumpSet = true;
                    _x2._telemetry.setWatchButton( "Live TV: All Channels" );
                    break;
                }

            if( jumpSet === false )
                this._list.setJumpToIndex( 0 );
        }
        else
            this.showEmptyMessage();
    };

    GridWidget.prototype.setTimeList = function( date )
    {
        var numCells = 480;
        var strs     = new Array( numCells );
        var millis   = date.getTime();

        for( var i = 0; i < numCells; i++ )
        {
            strs[i] = _x2.createTimeStr( date );
            date.setTime( millis + (i+1) * 30 * 60 * 1000 );
        }
        this._timeList.setData( strs );
    };

    GridWidget.prototype.speak = function( focus )
    {
        if( focus )
        {
            var hint = "Press arrows to review listings. Press OK for program details. Press back to return to Live TV menu.";
            this._distractor.setSpeechParams( focus._speakStr + hint, focus._speakRole, this, false );
        }
        else
            this._distractor.setSpeechParams( this._emptyMessage.getSpeechText(), undefined, this, false );
    };

    /**
     * @memberof GridWidget
     */
    GridWidget.prototype.tick = function()
    {
        var current, now, refreshTime;
        var date  = new Date();
        var time  = date.getTime();
        var index = this._list.getCurrentAndMaxIndex().current;

        if( time > this._refreshAt )
        {
            date.setMilliseconds( 0 );
            date.setSeconds( 0 );
            date.setHours(date.getMinutes() < 30 ? date.getHours() : date.getHours() + 1);
            date.setMinutes(date.getMinutes() < 30 ? 30 : 0 );

            refreshTime = date.getTime();
            this.requestSchedulesWithDelay(refreshTime);
            this._refreshAt = refreshTime + 1800 * Math.random() * 1000
            console.log('REFRESH AT:', new Date(this._refreshAt));
        }

        if( time > this._minTime + 1800000 )
        {
            date.setMilliseconds( 0 );
            date.setSeconds( 0 );
            date.setMinutes( (date.getMinutes() < 30) ? 0 : 30 );

            now = date.getTime();

            if( this._gridTime < now )
            {
                this._datum        = now;
                this._gridTime     = this._datum;
                this._focusColTime = this._datum;
                this._minTime      = this._datum;

                this.setDateString( date, date );
                this.setTimeList( date );
                this.requestSchedulesWithDelay(this._gridTime);

                var channels = this.filterChannels();

                this._list.setA( 1 );
                this._list.setData( channels );
                this._list.setJumpToIndex( index );

                if( channels && channels.length > 0 )
                    this._emptyMessage.hide();
                else
                    this.showEmptyMessage();
            }
        }

        if( this._infoShowing )
        {
            var showing   = this._info.getShowing();
            var channel   = this._info.getChannel();
            var isChannel = this._info.getIsChannel();

            if( channel ) //TODO: we're hitting this path where showing/channel are undefined. Guard for now and investigate.
            {
                var schedules = channel.getSchedules();

                date = new Date();
                date.setMilliseconds( 0 );
                date.setSeconds( 0 );

                now = date.getTime();

                if( showing.getStartTime() < now )
                {
                    for( var i = 0; i < schedules.length; i++ )
                        if( schedules[i].getEndTime() > now )
                        {
                            current = schedules[i];
                            break;
                        }

                    if( current && ( current.getEntityId() !== showing.getEntityId() ) )
                        this.setProgramInfo( current, channel, isChannel );
                }
                else if( showing.getStartTime() === now )
                    this.setProgramInfo( showing, channel, isChannel );
            }
        }
    };
    /* 
    * Before requesting the tvgrid/chunks api, check the current screen
    * if it is not all channels or favourite page, then request tvgrids with delay
    * if it is in all channels page, then request tvgrids for favourite page after 2.5 seconds
    * if it is favourite channels page, then request tvgrids for all channel page after 2.5 seconds
    */
    GridWidget.prototype.requestSchedulesWithDelay = function(startTime) {
        var guideScreen = _x2._screenRoot._children.filter( function(child){
            return (child instanceof GuideScreen);
        });
        var isAllChannelsGrid = (guideScreen[0] && guideScreen[0]._activeWidget instanceof GridListingsWidget);
        var isFavouriteChannelsGrid = (guideScreen[0] && guideScreen[0]._activeWidget instanceof GridListingsFavoritesWidget);
        if( (!isAllChannelsGrid && !isFavouriteChannelsGrid) || 
            (isAllChannelsGrid && this._parent instanceof GridListingsFavoritesWidget) || 
            (isFavouriteChannelsGrid && this._parent instanceof GridListingsWidget)) {
            window.setTimeout( function(){
                this.requestSchedules(startTime, startTime + 3 * 60 * 60 * 1000 );
            }.bind(this), 2500); 
        }  else {
            this.requestSchedules(startTime, startTime + 3 * 60 * 60 * 1000 );
        }
    }
    return GridWidget;

})();
