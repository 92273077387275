// Copyright (C) 2019 Comcast Corporation, All Rights Reserved

/**
 * @class
 *
 * Maps to a XTV "creativeWorkEntity" object
 * Represents a Movie, Series, Episode or other program.
 */
window.Entity = (function()
{
    "use strict";

    Entity.prototype = new DataObject();

    function Entity(){}

    /**
     * Initialize the object
     * @param {Object} data - XTV derived JSON object
     * @return {Entity}
     */
    Entity.prototype.init = function(data)
    {
        DataObject.prototype.init.call( this, data );

        return this;
    };

    /**
     * Get the 'away' SportsTeam for entity where _type === SportingEvent
     * @return {SportsTeam}
     */
    Entity.prototype.getAwayTeam = function()
    {
        if( ! this._awayTeam )
        {
            var team = this.getEmbedded("awayTeam");
            if( team )
                this._awayTeam = new SportsTeam().init(team);
        }

        return this._awayTeam;
    };

    /**
     * Get the 'home' SportsTeam for entity where _type === SportingEvent
     * @return {SportsTeam}
     */
    Entity.prototype.getHomeTeam = function()
    {
        if( ! this._homeTeam )
        {
            var team = this.getEmbedded("homeTeam");
            if( team )
                this._homeTeam = new SportsTeam().init(team);
        }

        return this._homeTeam;
    };

    /**
     * Get a list of credits
     * @memberOf Entity
     * @return {Array} List of names (String)
     */
    Entity.prototype.getCredits = function()
    {
        return this.get( "credits" )
    };

    /**
     * Get the program description
     * @memberOf Entity
     * @return {String}
     */
    Entity.prototype.getDescription = function()
    {
        return this.getByPriority( "description", "derivedDescription" );
    };

    /**
     * Get the program duration (NOTE: Showing may contain more accurate duration)
     * @memberOf Entity
     * @return {Number} Duration in minutes
     */
    Entity.prototype.getDuration = function()
    {
        return this.getInteger( "duration" ) / 60000;
    };

    /**
     * Get the entity id
     * @return {String} Entity's program id
     */
    Entity.prototype.getEntityId = function()
    {
        return this.get( "programId" );
    };

    /**
     * Get the program's episode number. Applies when this.getType() === XtvApi.EntityType.EPISODE
     * @memberOf Entity
     * @return {Number}
     */
    Entity.prototype.getEpisodeNumber = function()
    {
        return this.get( "episodeNumber" );
    };

    /**
     * Return the episode number or -1 if undefined
     * @memberOf Entity
     * @return {Number}
     */
    Entity.prototype.getEpisodeSortNumber = function()
    {
        return this.getEpisodeNumber() || -1;
    };

    /**
     * Get the secondary/fallback image URL for this program
     * @param width
     * @param height
     * @memberOf Entity
     * @return {String}
     */
    Entity.prototype.getFallbackImageLink = function(width, height)
    {
        var link = this.getLink( "fallbackImage" );

        if( link )
            link = XtvApi.replaceUrlParams( link, { width:width, height:height } );

        return link;
    };

    /**
     * Get the the primary image URL for this program
     * @memberOf Entity
     * @param {Number} width - Image width
     * @param {Number} height - Image height
     * @return {String}
     */
    Entity.prototype.getImageLink = function(width, height)
    {
        var link, image = this.getLink( "image" );

        //TODO: do we really need this + a getFallbackImageLink function?
        if( image === undefined )
            image = this.getLink( "fallbackImage" );

        if( image )
        {
            link = XtvApi.replaceUrlParams( image, { width:width, height:height } );
            link = link.replace( /&default=force/, "" ); // HACK: Removing the default=force is a temporary hack to prevent getting just the background image.
        }

        return link;
    };

    Entity.prototype.getRating = function()
    {
        return this.getPath( [ "contentRating/detailed", "name" ] );
    };

    /**
     * Get the year this program was release. Can contain range i.e. "2006-"
     * @memberOf Entity
     * @return {String}
     */
    Entity.prototype.getYear = function()
    {
        return this.get( "releaseYear" );
    };

    /**
     * Get a list of reviews (i.e. Rotten Tomatoes) for this program
     * @memberOf Entity
     * @return {Array} of {@link Review} objects
     */
    Entity.prototype.getReviews = function()
    {
        if( ! this._reviews )
        {
            var i, reviews = this.getEmbedded( "programReviews" );

            this._reviews = [];

            if( reviews && reviews.length )
            {
                for( i = 0; i < reviews.length; i++ )
                    this._reviews.push( new Review().init( reviews[i] ) );
            }
        }

        return this._reviews;
    };

    /**
     * Get the Rotten Tomato review for this program
     * @member Entity;
     * @return {Review}
     */
    Entity.prototype.getReview = function( type )
    {
        var i, review, reviews = this.getReviews();

        if( reviews && reviews.length )
        {
            for( i=0; i<reviews.length; i++ )
            {
                if( reviews[i].getProvider() === type )
                {
                    review = reviews[i];
                    break;
                }
            }
        }

        return review;
    };

    /**
     * Get the Rotten Tomato review for this program
     * @member Entity;
     * @return {Review}
     */
    Entity.prototype.getRottenTomatoReview = function()
    {
        return this.getReview( "RT" );
    };

    /**
     * Get the Common Sense Media review for this program
     * @member Entity;
     * @return {Review}
     */
    Entity.prototype.getCommonSenseMediaReview = function()
    {
        return this.getReview( "CSM" );
    };

    /**
     * Get the program's season number. Applies when this.getType() === XtvApi.EntityType.EPISODE.
     * @memberOf Entity
     * @returns {Number}
     */
    Entity.prototype.getSeasonNumber = function()
    {
        return this.getPath( [ "partOfSeason", "seasonNumber" ] );
    };

    /**
     * Get the objects self link
     * @return {String}
     */
    Entity.prototype.getSelfLink = function()
    {
        return this.getLink("self");
    };

    /**
     * Get the link/URL to query for the program's series. Applies when this.getType() === XtvApi.EntityType.EPISODE.
     * @memberOf Entity
     * @returns {String}
     */
    Entity.prototype.getTvSeriesLink = function()
    {
        return this.getLink( "partOfTvSeries" );
    };

    /**
     * Get the series associated with this episode.
     * @return {Entity|*}
     */
    Entity.prototype.getSeries = function()
    {
        if( ! this._series )
        {
            var series = this.getEmbedded("partOfTvSeries");

            if( series )
                this._series = new Entity().init( series )
        }
        return this._series;
    };

    /**
     * Return the series id extracted from _links
     */
    Entity.prototype.getSeriesId = function()
    {
        var id, series, link;

        series = this.getSeries();

        if( series )
            id = series.getEntityId();

        if( ! id )
        {
            link = this.getLink("partOfTvSeries");
            if( link )
            {
                var tokens = link.split('/');
                if( tokens && tokens.length )
                    id = tokens[tokens.length-2];
            }
        }

        return id;
    };

    /**
     * Get the title of this program
     * @memberOf Entity
     * @returns {string}
     */
    Entity.prototype.getTitle = function()
    {
        return this.get( "name" );
    };

    /**
     * Get this program's type
     * @memberOf Entity
     * @returns {XtvApi.EntityType}
     */
    Entity.prototype.getType = function()
    {
        var type, programType = this.getByPriority( "type", "_type" );

        switch( programType )
        {
            case "s:Movie":
                type = XtvApi.EntityType.MOVIE;
                break;
            case "s:TvSeries":
                type = XtvApi.EntityType.SERIES;
                break;
            case "s:TVEpisode":
                type = XtvApi.EntityType.EPISODE;
                break;
            case "s:SportsEvent":
                type = XtvApi.EntityType.SPORTS;
                break;
            default:
                type = XtvApi.EntityType.OTHER;
        }

        return type;
    };

    /**
     * Get the entity watch options. NOTE: ASYNC METHOD! Callers should make this call once and do all work when the promise resolves.
     * A new EntityOptions object will be returned on each call.
     */
    Entity.prototype.fetchEntityOptions = function( force )
    {
        var self = this;

        var resolver = function( resolve, reject )
        {
            var success = function() { resolve( self._options ) };

            self._options = new EntityOptions().init( self );
            self._options.fetch( force ).then( success ).catch( reject );
        };

        return new Promise(resolver);
    };

    /**
     * Follow entityRecording link.
     * @return {Promise}
     */
    Entity.prototype.fetchEntityRecording = function(force)
    {
        var self = this;

        var resolver = function( resolve, reject )
        {
            var success = function( result )
            {
                self._entityRecording = new EntityRecording().init( JSON.parse(result.data) );
                resolve();
            };

            var link = self.getLinkObject( "entityRecording" );

            if( link )
                link.resolve({},"getEntityRecording",force).then( success ).catch( reject );
            else
                resolve();
        };

         return new Promise( resolver );
    };

    /**
     * Get the watch options for this entity. These may have been created from a call to fetchEntityOptions
     * or they may be embedded in the entity data. If fetch was not called create the options from internal
     * data.
     */
    Entity.prototype.getEntityOptions = function()
    {
        if( ! this._options )
        {
            this._options = new EntityOptions().init( this );
            this._options.setWatchOptions( this.getPath(["_embedded", "watchOptions","_embedded"]) );
        }

        return this._options; //TODO: warn if options were not set, but always return the object
    };

    /**
     * Get the entity (series) recording. NOTE: only available after async fetchEntityRecording resolves
     * @return {Recording}
     */
    Entity.prototype.getEntityRecording = function()
    {
        return this._entityRecording;
    };

    /**
     * Return true if this program is marked as adult content
     * @memberOf Entity
     * @returns {Boolean}
     */
    Entity.prototype.isAdult = function()
    {
        return this.get( "isAdult" );
    };

    /**
     * Set the series for this entity (episode). Some XTVAPI result sets require us to manually merge data (i.e. Recording lists)
     * @param series
     */
    Entity.prototype.setSeries = function( series )
    {
        this._series = series;
    };

    return Entity;

})();
