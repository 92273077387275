// Copyright (C) 2018 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.MiniGuideScreen = ( function()
{
    "use strict";

    MiniGuideScreen.prototype = new Screen();

    function MiniGuideScreen(){}

    MiniGuideScreen.prototype.destroy = function()
    {
        _x2.removeChangeCallback( this._tickCallback );
        Screen.prototype.destroy.call( this );

        _x2._videoRoot.style.transform = "scale( 1, 1 )";
        _x2._videoRoot.style.left      = "0px";
        _x2._videoRoot.style.top       = "0px";
    };

    MiniGuideScreen.prototype.gotFocus = function()
    {
        if( this._dataReady === true )
            this.speak( _x2.requestFocus( this._list, true ) );
    };

    /**
     * @memberof MiniGuideScreen
     * @param   {Object}      params
     * @param   {Number}      params.selectedIndex - index if the currently active entry
     * @param   {VideoScreen} params.videoScreen - instance of VideoScreen that launched this screen
     * @returns {MiniGuideScreen}
     */
    MiniGuideScreen.prototype.init = function( params )
    {
        Screen.prototype.init.call( this, { noBg:true, noHeader:true, noSep:true } );
        this._className     = "MiniGuideScreen";
        this._telemetryName = "Mini Guide";
        this._videoScreen   = params.videoScreen;
        this._numLoaded     = 0;

        var self       = this, listParams = {}, topParams, rightParams, layout, onBottomFadeLoaded, date;
        var titleStyle = new Style( { font:"light"  , color:"#8c8c8c", fontSize:_x2.scaleValInt(48), whiteSpace:"nowrap" } );
        var backStyle  = new Style( { font:"medium" , color:"#8c8c8c", fontSize:_x2.scaleValInt(24), whiteSpace:"nowrap" } );
        var timeStyle  = new Style( { font:"light"  , color:"#8c8c8c", fontSize:_x2.scaleValInt(30), whiteSpace:"nowrap" } );
        var progStyle  = new Style( { font:"regular", color:"#8c8c8c", fontSize:_x2.scaleValInt(40), whiteSpace:"nowrap", overflow:"hidden" } );

        topParams =
        {
            w     : _x2._config._screenW,
            h     : _x2.scaleValInt( 141 ),
            color : "#000000"
        };

        rightParams  =
        {
            w     : _x2.scaleValInt( 640 ),
            h     : _x2._config._screenH,
            color : "#000000"
        };

        layout = function()
        {
            if( ++self._numLoaded === 2 )
            {
                self._backStr.setX( _x2._config._screenW - self._backStr.getW() - Style._safeRight );
                self._backStr.setY( Style._safeTop + (self._title.getH() - self._backStr.getH()) / 2 );
                self._progTitle.setY( _x2._config._screenH - Style._safeBottom - self._progTitle.getH() );
                self._fade.setX( self._progTitle.getX() + self._progTitle.getW() - self._fade.getW() );
                self._fade.setY( self._progTitle.getY() );
                self._fade.setW( self._fade.getW() );
                self._fade.setH( self._progTitle.getH() );
            }
        };

        onBottomFadeLoaded = function()
        {
            self._bottomFade.setX( self._right.getX() );
            self._bottomFade.setY( _x2._config._screenH - self._bottomFade.getH() );
            self._bottomFade.setH( self._bottomFade.getH() );
            self._bottomFade.setW( self._right.getW() );
        };

        this._top = new RectWidget().init( topParams );
        this.addWidget( this._top );

        this._bottom = new RectWidget().init( topParams );
        this._bottom.setH( _x2.scaleValInt( 220 ) );
        this.addWidget( this._bottom, 0, _x2._config._screenH - this._bottom.getH() );

        this._right = new RectWidget().init( rightParams );
        this.addWidget( this._right, _x2._config._screenW - rightParams.w );

        this._title = new StringWidget().init( { text:"Mini Guide", style:titleStyle } );
        this.addWidget( this._title, _x2._config._screenW - rightParams.w + 3 * Style._pad, Style._safeTop );

        this._backStr = new StringWidget().init( { text:"Press [Back] to exit", style:backStyle } );
        this._backStr.addReadyCallback( layout );
        this.addWidget( this._backStr, 0, Style._safeTop );

        this._time = new StringWidget().init( { text:_x2.createTimeStr(), style:timeStyle } );
        this.addWidget( this._time, Style._safeLeft, Style._safeTop );

        this._progTitle = new StringWidget().init( { text:"Now Playing: ", style:progStyle } );
        this._progTitle.setW( this._right.getX() - Style._safeLeft );
        this.addWidget( this._progTitle, Style._safeLeft );

        this._fade = new ImageWidget().init( { url:_x2._config._imageRoot + "textFade2.png", onLoad:layout } );
        this.addWidget( this._fade );

        listParams.w        = this._right.getW() - 3 * Style._pad;
        listParams.h        = _x2._config._screenH - this._top.getH();
        listParams.maxIndex = 3;
        listParams.type     = MiniGridRowWidget;
        listParams.obj      = this;
        listParams.stepTime = 150;

        this._list = new ListWidget().init( listParams );
        this.addWidget( this._list, _x2._config._screenW - this._right.getW() + 3 * Style._pad, this._top.getH() );

        this._bottomFade = new ImageWidget().init( { url:_x2._config._imageRoot + "bottomFade.png", onLoad:onBottomFadeLoaded } );
        this.addWidget( this._bottomFade );

        this._distractor = new DistractorWidget().init();
        this._distractor.addReadyCallback( function() { self._distractor.centerOnWidget( self._right ); self._distractor.show(); } );
        this._right.addWidget( this._distractor );

        this._tickCallback = function()
        {
            self._time.setText( _x2.createTimeStr() );

            // TODO update programs every minute

//             var index = this._list.getCurrentAndMaxIndex().current;
//
//             this._list.setJumpToIndex( index );
        };

        _x2.addChangeCallback( this._tickCallback );

        // get the list of channels

        date = new Date();
        date.setMilliseconds( 0 );
        date.setSeconds( 0 );
        date.setMinutes( (date.getMinutes() < 30) ? 0 : 30 );

        this.setNowPlaying( true );

        return this;
    };

    MiniGuideScreen.prototype.loadData = function()
    {
        var self = this;

        var onChannelsLoaded = function()
        {
            var chanColl = _x2._channelCollection;
            var channels = chanColl.getChannels();
            self._dataReady = true;
            self._cc        = chanColl;
            self._list.setData( channels );

            var lastChannel = _x2._settings.getValue( Settings.Key.LAST_CHAN );

            if( lastChannel )
                for( var i = 0; i < channels.length; i++ )
                    if( channels[i].getStationId() === lastChannel.id && channels[i].getNumber() === lastChannel.num )
                    {
                        self._list.setJumpToIndex( i );
                        break;
                    }

            self._distractor.hide();

            if( _x2._focus === self )
                self.speak( _x2.requestFocus( self._list, true ) );
        };

        var onChannelsFailed = function( error )
        {
            var onDismiss = function()
            {
                _x2.popScreen( self );
            };

            var onRetry = function()
            {
                var retry = function()
                {
                    _x2._channelCollection.fetch().then( onChannelsLoaded, onChannelsFailed );
                };

                _x2.popOverlay( self._errorOverlay, retry );
            };

            self._errorOverlay = _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.GUIDE, onDismiss:onDismiss, onRetry:onRetry } ) );
        };

        var date = new Date();
        date.setMilliseconds( 0 );
        date.setSeconds( 0 );
        date.setMinutes( (date.getMinutes() < 30) ? 0 : 30 );

        _x2._channelCollection.fetch().then( onChannelsLoaded, onChannelsFailed );

        this.setNowPlaying( false );

        return this;
    };

    MiniGuideScreen.prototype.processEvent = function( val, type )
    {
        var retval = true;

        switch( val )
        {
            case Host.KEY_LAST:
            case Host.KEY_LEFT:
                if( type === Host.KEY_PRESSED )
                {
                    this.sendMiniGuideLocalytics( "Dismiss" );
                    _x2.popScreen( this );
                }
                break;


            case Host.KEY_SOFT_D:
                if( type === Host.KEY_PRESSED )
                    this.setNowPlaying( false );
                break;

            default:
                retval = Screen.prototype.processEvent.call( this, val, type );
                break;
        }

        return retval
    };

    MiniGuideScreen.prototype.pushComplete = function()
    {
        console.log( "PUSH COMPLETE" );

        var w     = this._right.getW();
        var scale = (_x2._config._screenW - w) / _x2._config._screenW;

        _x2._videoRoot.style.transform = "scale( " + scale + ", " + scale +  " )";
        _x2._videoRoot.style.left      = "-" + w / 2 + "px";
        _x2._videoRoot.style.top       = "-" + (this._bottom.getH() - this._top.getH()) / 2 + "px";

        this.loadData();
    };

    /**
     * @param {String}                      action - "Dismiss", "Info", or "Watch"
     * @param {LinearShowing|ProgramEntity} showing
     */
    MiniGuideScreen.prototype.sendMiniGuideLocalytics = function( action, showing )
    {
        var entity  = showing ? showing : this._videoScreen._entity;
        var channel = entity && entity.getChannel ? entity.getChannel() : undefined;
        var metrics =
        {
            "Action" : action,
            "Name"   : entity  ? entity .getTitle()    : "NA",
            "Station": channel ? channel.getCallSign() : "NA"
        };

        window.ll( "tagEvent", "Mini-Guide", metrics );
        _x2._config.log( Config.CLASS_LOCALYTICS, 2 ) ? console.log( "Localytics: Mini-Guide: " + metrics ) : Config.NOP();
    };

    MiniGuideScreen.prototype.setNowPlaying = function( isSetSpeech )
    {
        var str = this._videoScreen._title.getText();
        var sub = this._videoScreen._subTitleSpeak || this._videoScreen._subTitle.getText();

        if( sub )
            str += " - " + sub;

        this._progTitle.setText( "Now Playing: " + str );

        // if( userPressedInfo )
        //    this.sendMiniGuideLocalytics( "Info" );
    };

    MiniGuideScreen.prototype.setTuneToChannel = function( channel, sched )
    {
        var self = this, onPopped;

        onPopped = function()
        {
            var onReady = function()
            {
                var url      = channel.getStreamUrl();
                var streamId = channel.getStreamId();

                // Handle switching from "Last Watched" back to Linear
                _x2._telemetry.watchPathReset();
                _x2._telemetry.setWatchButton( "Player: Mini-Guide" ); // Technically "Live TV: All Channels" but want to keep track of player source

                self.sendMiniGuideLocalytics( "Watch", sched );
                //Set last channel when tune channel in miniguide
                _x2._settings.setValue( Settings.Key.LAST_CHAN, { id: channel.getStationId(), num: channel.getNumber() } );
                self._videoScreen.play( { url:url, mode:VideoScreen.Mode.LIVE, entity:sched, streamId:streamId } );
            };

            var onError = function()
            {
                console.error( "Unable to get program detail." );
                self._videoScreen.hideDistractor();
            };

            sched.getDetail().then( onReady, onError );
        };

        this._videoScreen.showDistractor( true );
        _x2.popScreen( this, onPopped );
    };

    MiniGuideScreen.prototype.speak = function( focus )
    {
        var speechText = this._progTitle.getText() || "Mini Guide. " + focus._speakStr + ". ";
        this._speakTarget.setText( speechText );
        this._speakTarget.setSpeechParams( " Press up or down to review channels. Press OK to tune. Press back to dismiss. ", focus._speakRole, this, false );
    };

    return MiniGuideScreen;

})();
