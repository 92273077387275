// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.VodGridWidget = (function()
{
    "use strict";

    VodGridWidget.prototype = new Widget();

    function VodGridWidget(){}

    /**
     * @memberof VodGridWidget
     * @param {BrowseCollection} folder
     * @returns {VodGridWidget}
     */
    VodGridWidget.prototype.init = function( folder )
    {
        Widget.prototype.init.call( this );

        this._className       = "VodGridWidget";
        this._selectable      = true;

        if( folder instanceof Collection )
            this._rowsPerDataPage = Number.MAX_SAFE_INTEGER; //note: there is no paging on Collection types
        else
        {
            this._rowsPerDataPage = 10;
            this._isFavoriteGrid  = folder.isFavoriteCollection();
        }

        this._folder      = folder;
        this._loadedPages = [];
        this._rows        = [];
        this._columnCount = folder.getChildRenderStyle() === XtvApi.EntityRenderStyle["16X9_PROGRAM"] ? 4 : 5;

        var self  = this;
        var listH = _x2._config._screenH - 180;
        var listW = _x2._config._screenW - Style._safeLeft;
        var type  = folder.getChildRenderStyle() === XtvApi.EntityRenderStyle["16X9_PROGRAM"] ? SixteenNineGridRowWidget : VodGridRowWidget;

        var params          = {};
        params.w            = listW;
        params.h            = listH;
        params.hide         = true;
        params.maxIndex     = 0;
        params.type         = type;
        params.obj          = this;
        params.sep          = false;
        params.stepListener = function(val){ self.rowSelected(val) };
        params.renderStyle  = this._folder.getChildRenderStyle();

        this._distractor = new DistractorWidget().init();
        this._distractor.hide();
        this._distractor.addReadyCallback( function()
        {
            self._distractor.centerOnWidget( self._grid );
            if( !self._ready )
                self._distractor.show();
        } );
        this.addWidget( this._distractor );

        this._grid = new ListWidget().init( params );
        this.addWidget( this._grid, 0, 0 );
        this.loadData( this._isFavoriteGrid ); //always break caching when loading the favorites here. can be stale if returning here after setting favorite elsewhere.

        return this;
    };

    VodGridWidget.prototype.loadData = function( bustCache, index )
    {
        var success, fail, self = this;

        if( this._list )
        {
            this._list.setA( 0 );
            this._list._selectable = false;
        }

        success = function( data )
        {
            self._browseFolder = data;

            var embedded = data.getEmbeddedBrowseItems();

            if( embedded.length )
            {
                //trim the "all" folder
                if( embedded[0].getMeta().all === true )
                    embedded.splice( 0, 1 );

                //if this is a 'favorite' collection append a change callback to each entity to signal when we need to update the data based on change of favorite status
                if( self._isFavoriteGrid )
                    for( var n=0; n < embedded.length; n++ )
                        embedded[n]._changeCallback = function() { self._needsUpdate = true };

                //repack data into arrays of ROW_SIZE before calling set data.
                for( var i = 0; i < embedded.length; i += self._columnCount )
                {
                    var last = ( embedded.length > i + self._columnCount ) ? i + self._columnCount : embedded.length;
                    self._rows.push( embedded.slice( i, last ) );
                }

                //reset data
                self._grid.setData( self._rows );
                self._grid.setA(1);

                if( index )
                    self._grid.setJumpToIndex( index );

                self._ready = true;

                if( _x2._focus === self )
                    _x2.requestFocus( self._parent );

                self._loadedPages.push( 0 );
            }
            else
            {
                var alertTitle = 'Oops, something went wrong. Please try again later.', 
                alertMessage = 'This folder is empty.';
                if( _x2.getBrowseFilterString() ) {
                    alertTitle = 'Adjust filters to see more titles.';
                    alertMessage = '';
                }
                _x2.pushOverlay(new AlertOverlay().init( { title: alertTitle, message: alertMessage } ) );
                _x2._hi.fadeOut();
            }

            self._distractor.hide();
        };

        fail = function( error )
        {
            _x2.pushOverlay( new ErrorOverlay().init( { error:error, context:ErrorOverlay.Context.BROWSE_SEARCH } ) );
            console.log( error.toString() );
        };

        var filters = _x2.getBrowseFilterString();

        if( this._folder instanceof BrowseCollection )
            _x2._data.getVodFolder( this._folder, 1, this._columnCount * this._rowsPerDataPage, filters, bustCache ).then( success ).catch( fail );
        else
            success( this._folder );
    };

    VodGridWidget.prototype.gotFocus = function()
    {
        var focus;

        _x2._hi.fadeOut(0);

        if( this._ready )
            focus = _x2.requestFocus( this._grid );

        return focus;
    };

    VodGridWidget.prototype.getColumnCount = function()
    {
        return this._columnCount;
    };

    VodGridWidget.prototype.nextPageLoaded = function( data )
    {
        var rows = [];
        for( var i = 0; i < data.length; i += this._columnCount )
        {
            var last = ( data.length > i + this._columnCount ) ? i + this._columnCount : data.length;
            rows.push( data.slice( i, last ) );
        }

        this._grid.appendData( rows );
        this._grid.setBlockingOnData( false );
        this._distractor.hide();
    };

    VodGridWidget.prototype.rowSelected = function( val )
    {
        var self = this;
        var page = 0;
        var row  = Math.floor( Math.round( val ) ) + 4; //NOTE: look ahead by 4 rows to trigger the next data page.

        if( row % this._rowsPerDataPage === 0 )
        {
            page = row / this._rowsPerDataPage;

            if( this._browseFolder.getTotalEntityCount() > ( page * this._rowsPerDataPage * this._columnCount ) )
            {
                if( page && this._loadedPages.indexOf( page ) === -1 )
                {
                    this._distractor.show();
                    this._grid.setBlockingOnData( true );
                    this._loadedPages.push( page );
                    this._browseFolder.loadNextPage().then( function(data){ self.nextPageLoaded(data) } );
                }
            }
        }
    };

    VodGridWidget.prototype.refresh = function()
    {
        if( this._needsUpdate )
        {
            var index = this._grid ? this._grid.getCurrentAndMaxIndex().current : 0;

            this._ready = false;
            this._rows = [];
            this._loadedPages = [];

            _x2._hi.fadeOut(0);

            this._grid.setA(0);
            this._distractor.show();

            this.loadData( true, index );
        }
    };

    return VodGridWidget;

})();
