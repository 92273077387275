// Copyright (C) 2016 Comcast Corporation, All Rights Reserved

/**
 * @class
 */
window.Host = (function()
{
    "use strict";

    Host.NetworkType = Object.freeze
    ({
        NONE    : 0,
        UNKNOWN : 1,
        WIFI    : 2,
        WIRED   : 3
    });

    /**
     * @enum Type
     * @property {Number} PC Default
     * @property {Number} TIZEN Samsung Tizen Smart TV
     */
    Host.Type = Object.freeze
    ({
        ANDROID: 0,
        LG     : 1,
        PC     : 2,
        TIZEN  : 3
    });

    // Type
    Host.KEY_PRESSED        =    0;
    Host.KEY_RELEASED       =    1;
    Host.KEY_REPEAT         =    2;

    // Val
    Host.KEY_DELETE         =    8;
    Host.KEY_TAB            =    9;
    Host.KEY_ENTER          =   13;
    Host.KEY_ESC            =   27;
    Host.KEY_SPACE          =   32;
    Host.KEY_PAGE_UP        =   33;
    Host.KEY_PAGE_DN        =   34;
    Host.KEY_LEFT           =   37;
    Host.KEY_UP             =   38;
    Host.KEY_RIGHT          =   39;
    Host.KEY_DOWN           =   40;
    Host.KEY_DOT            =   46;
    Host.KEY_0              =   48;
    Host.KEY_1              =   49;
    Host.KEY_2              =   50;
    Host.KEY_3              =   51;
    Host.KEY_4              =   52;
    Host.KEY_5              =   53;
    Host.KEY_6              =   54;
    Host.KEY_7              =   55;
    Host.KEY_8              =   56;
    Host.KEY_9              =   57;
    Host.KEY_A              =   65;
    Host.KEY_B              =   66;
    Host.KEY_C              =   67;
    Host.KEY_D              =   68;
    Host.KEY_E              =   69;
    Host.KEY_F              =   70;
    Host.KEY_G              =   71;
    Host.KEY_H              =   72;
    Host.KEY_I              =   73;
    Host.KEY_J              =   74;
    Host.KEY_K              =   75;
    Host.KEY_L              =   76;
    Host.KEY_M              =   77;
    Host.KEY_N              =   78;
    Host.KEY_O              =   79;
    Host.KEY_P              =   80;
    Host.KEY_Q              =   81;
    Host.KEY_R              =   82;
    Host.KEY_S              =   83;
    Host.KEY_T              =   84;
    Host.KEY_U              =   85;
    Host.KEY_V              =   86;
    Host.KEY_W              =   87;
    Host.KEY_X              =   88;
    Host.KEY_Y              =   89;
    Host.KEY_Z              =   90;
    Host.KEY_F1             =  112;
    Host.KEY_F2             =  113;
    Host.KEY_F3             =  114;
    Host.KEY_F4             =  115;
    Host.KEY_F5             =  116;
    Host.KEY_F6             =  117;
    Host.KEY_F7             =  118;
    Host.KEY_F8             =  119;
    Host.KEY_F9             =  120;
    Host.KEY_F10            =  121;
    Host.KEY_F11            =  122;
    Host.KEY_F12            =  123;
    Host.KEY_F13            =  124;
    Host.KEY_F14            =  125;
    Host.KEY_F15            =  126;
    Host.KEY_F16            =  127;
    Host.KEY_F17            =  128;
    Host.KEY_F18            =  129;
    Host.KEY_F19            =  130;
    Host.KEY_EQUAL          =  187;
    Host.KEY_MINUS          =  189;
    Host.KEY_SLASH          =  191;
    Host.KEY_BRACKET_LEFT   =  219;
    Host.KEY_BACK_SLASH     =  220;
    Host.KEY_BRACKET_RIGHT  =  221;

    Host.KEY_BLUE           = 2000;
    Host.KEY_GREEN          = 2001;
    Host.KEY_RED            = 2002;
    Host.KEY_YELLOW         = 2003;
    Host.KEY_ON_DEMAND      = 2004;
    Host.KEY_INFO           = 2005;
    Host.KEY_SOFT_A         = 2006;
    Host.KEY_SOFT_B         = 2007;
    Host.KEY_SOFT_C         = 2008;
    Host.KEY_SOFT_D         = 2009;
    Host.KEY_MENU           = 2010;
    Host.KEY_EXIT           = 2011;
    Host.KEY_HELP           = 2012;
    Host.KEY_FAV            = 2013;
    Host.KEY_PLAY           = 2014;
    Host.KEY_PAUSE          = 2015;
    Host.KEY_FF             = 2016;
    Host.KEY_RW             = 2017;
    Host.KEY_STOP           = 2018;
    Host.KEY_RECORD         = 2019;
    Host.KEY_LAST           = 2020;
    Host.KEY_SEARCH         = 2021;
    Host.KEY_GUIDE          = 2022;
    Host.KEY_APP            = 2023;
    Host.KEY_CLEAR          = 2024;
    Host.KEY_POUND          = 2025;
    Host.KEY_STAR           = 2026;
    Host.KEY_BANG           = 2027;
    Host.KEY_AT             = 2028;
    Host.KEY_DOLR           = 2029;
    Host.KEY_PRCT           = 2030;
    Host.KEY_MUTE           = 2031;
    Host.KEY_VOL_DOWN       = 2032;
    Host.KEY_VOL_UP         = 2033;
    Host.KEY_CH_DOWN        = 2044;
    Host.KEY_CH_UP          = 2045;
    Host.KEY_CARET          = 2046;
    Host.KEY_AMP            = 2047;
    Host.KEY_LPAREN         = 2048;
    Host.KEY_RPAREN         = 2049;
    Host.KEY_USAGE          = 2050;
    Host.KEY_QUIT           = 2051;
    Host.KEY_QUIT_NOW       = 2052;
    Host.KEY_HUD            = 2053;
    Host.KEY_CC             = 2054;
    Host.KEY_SAP            = 2055;
    Host.KEY_PLAY_PAUSE     = 2056;


//     Host.BORDER_IMAGE        =  0;
//     Host.BORDER_IMAGE_WEBKIT =  1;

    function Host(){}

//     Host.prototype.checkMinVersion = function()
//     {
//     };
//
//     Host.prototype.checkNetworkConnection = function( interval )
//     {
//         console.log( "WARNING -> Network check not implemented." );
//     };

    Host.prototype.exitApp = function()
    {
        console.log( "########## EXIT APP NOT SUPPORTED ##########" );
    };

    Host.prototype.getCurrentDateTime = function( convertToTimeStamp ) 
    {
        convertToTimeStamp = convertToTimeStamp || false;
        return (convertToTimeStamp == true )? Date.now() : new Date();
    };

//     Host.prototype.getBorderImageType = function()
//     {
//         return Host.BORDER_IMAGE;
//     };
//
//     Host.prototype.getDate = function()
//     {
//         return (new Date());
//     };
//
//     Host.prototype.getImageSoftA = function()
//     {
//         return "softKeys/SoftKey_A.png";
//     };
//
//     Host.prototype.getImageSoftB = function()
//     {
//         return "softKeys/SoftKey_B.png";
//     };
//
//     Host.prototype.getImageSoftC = function()
//     {
//         return "softKeys/SoftKey_C.png";
//     };
//
//     Host.prototype.getImageSoftD = function()
//     {
//         return "softKeys/SoftKey_D.png";
//     };

    Host.prototype.getClientInfo = function()
    {
        return "app_name=\"Stream\";app_version=\"" + AboutOverlay.version +
               "\";os_name=\"" + _x2._config._host._osName +
               "\";os_version=\"" + _x2._config._host._osVersion +
               "\";device_model=\"" + _x2._config._host._product +
               "\"";
    };

    Host.prototype.getCryptoType = function()
    {
        return "dh";
    };

    Host.prototype.getDrmType = function()
    {
        return "com.widevine.alpha";
    };

    Host.getLocalStorage = function( key )
    {
        var retval;

        if( typeof(Storage) !== "undefined" && localStorage !== undefined )
            retval = localStorage.getItem( key );
        else
            console.error( "ERROR -> local storage is not supported" );

        return retval;
    };

    Host.prototype.getNetworkType = function()
    {
        var retval = "Unknown";

        switch( this._networkType )
        {
            case Host.NetworkType.NONE:
                retval = "None";
                break;

            case Host.NetworkType.WIFI:
                retval = "WiFi";
                break;

            case Host.NetworkType.WIRED:
                retval = "Wired";
                break;
        }

        return retval;
    };

    Host.prototype.getSsid = function()
    {
        return this._ssid;
    };
    Host.prototype.getPlatformInfo = function()
    {
        var modelInfo = ' ', productInfo = '';
        if( this._make ) {
            productInfo = this._make;
        } else {
            productInfo = this._product;
        }
        if (productInfo) {
            modelInfo += productInfo + '_';
        }
        if( this._model ) {
            modelInfo += this._model + ' ';
        }
        if( this._product ) {
            modelInfo += this._product + ' ';
        }
        return { "product" : productInfo, "model" : modelInfo.trim() };
    };

    Host.prototype.getPlatformString = function()
    {
        return "pc";
    };

    Host.prototype.getSecClientDeviceType = function()
    {
       return undefined;
    };

//     Host.prototype.getType = function()
//     {
//         return this._type;
//     };
//
//     Host.prototype.getVideoPlayerClass = function( ext )
//     {
//         var retval;
//
//         switch( ext )
//         {
//             case "dvr":
//             case "mp4":
//             case "ocap":
//                 retval = VideoTagPlayer;
//                 break;
//
//             case "f4m":
//             case "m3u8":
//                 retval = ViperFlashPlayer;
//                 break;
//
//             default:
//                 console.log( "ERROR -> no player type for ext = " + ext );
//                 break;
//         }
//
//         return retval;
//     };

    /**
     * Get the host type
     * @memberOf Host
     * @return {Host.Type}
     */
    Host.prototype.getType = function()
    {
        return this._type;
    };

    Host.prototype.init = function( callback )
    {
        this._type = Host.Type.PC;

        _x2._config._authHost = "https://xacs.ccp.xcal.tv";
        _x2._config._provHost = "https://xacsa.ccp.xcal.tv";  // TODO should be moved to xacs

        var device  = "Unknown", nameOffset, verOffset, ix;
        var browser = navigator.appName;
        var ua      = navigator.userAgent;
        var av      = navigator.appVersion;
        var version = parseFloat( av );

        if( av.indexOf( "Win" ) !== -1 )
            device="Windows";
        else if( av.indexOf( "Mac" ) !== -1 )
            device="Macintosh";
        else if( av.indexOf( "Linux" ) !== -1 )
            device="Linux";

        if( (verOffset=ua.indexOf( "OPR/" )) !== -1 )
        {
            browser = "Opera";
            version = ua.substring( verOffset + 4 );
        }
        else if( (verOffset=ua.indexOf( "MSIE" )) !== -1 )
        {
            browser = "Microsoft Internet Explorer";
            version = ua.substring( verOffset + 5 );
        }
        else if( (verOffset=ua.indexOf( "Chrome" )) !== -1 )
        {
            browser = "Chrome";
            version = ua.substring( verOffset + 7 );
        }
        else if( (verOffset=ua.indexOf( "Safari" )) !== -1 )
        {
            browser = "Safari";
            version = ua.substring( verOffset + 7 );

            if( (verOffset=ua.indexOf( "Version" )) !== -1 )
                version = ua.substring( verOffset + 8 );
        }
        else if( (verOffset=ua.indexOf( "Firefox" )) !== -1 )
        {
            browser = "Firefox";
            version = ua.substring( verOffset + 8 );
        }
        else if( (nameOffset=ua.lastIndexOf( " " ) + 1) < (verOffset=ua.lastIndexOf( "/" )) )
        {
            browser = ua.substring( nameOffset, verOffset );
            version = ua.substring( verOffset + 1 );

            if( browser.toLowerCase() === browser.toUpperCase() )
                browser = navigator.appName;
        }

        if( (ix=version.indexOf(";")) !== -1 )
           version = version.substring( 0, ix );
        if( (ix=version.indexOf(" ")) !== -1 )
           version = version.substring( 0, ix );

        _x2._config._host._make      = undefined;
        _x2._config._host._product   = device;
        _x2._config._host._osName    = browser;
        _x2._config._host._osVersion = version;

//         this._macAddress = _mac;
//
// //        this._videoTag = { _transMode: VideoPlayer.TRANS_SCRUB, _speeds:[-128,-64,-16,-4,-2,1,2,4,16,64,128] };
//         this._videoTag = { _transMode: VideoPlayer.TRANS_LIVE, _speeds:[-60,-30,-15,1,15,30,60] };

        setTimeout( function() { callback() }, 1 );
    };

    // The which field of the KeyboardEvent is read only. If the Host class is extended, the which field will contain
    // the remapped value. Otherwise, the which field can be excluded and it will be extracted from the event.

    Host.prototype.mapKey = function( event, which )
    {
        var val;

        if( which === undefined )
        {
            val = Host.mapKeyStr( event.key );

            if( val === undefined )
                val = event.which;
        }
        else
            val = which;

        val = Host.mapKeyVal( val );

        return val;
    };

    Host.mapKeyStr = function( str )
    {
        var retval;

        switch( str )
        {
            case "l":
                retval = Host.KEY_L;
                break;

            case "ArrowDown":
                retval = Host.KEY_DOWN;
                break;

            case "ArrowLeft":
                retval = Host.KEY_LEFT;
                break;

            case "ArrowRight":
                retval = Host.KEY_RIGHT;
                break;

            case "ArrowUp":
                retval = Host.KEY_UP;
                break;

            case "Backspace":
                retval = Host.KEY_LAST;
                break;

            case "ChannelDown":
                retval = Host.KEY_CH_DOWN;
                break;

            case "ChannelUp":
                retval = Host.KEY_CH_UP;
                break;

            case "ClosedCaptionToggle":
                retval = Host.KEY_CC;
                break;

            case "ColorF0Red":
                retval = Host.KEY_SOFT_A;
                break;

            case "ColorF1Green":
                retval = Host.KEY_SOFT_B;
                break;

            case "ColorF2Yellow":
                retval = Host.KEY_SOFT_C;
                break;

            case "ColorF3Blue":
                retval = Host.KEY_SOFT_D;
                break;

//                 case "ContextMenu":
//                     retval = ;
//                     break;

            case "Digit0":
            case "0":  // Samsung CVP-2 Bug
                retval = Host.KEY_0;
                break;

            case "Digit1":
            case "1":  // Samsung CVP-2 Bug
                retval = Host.KEY_1;
                break;

            case "Digit2":
            case "2":  // Samsung CVP-2 Bug
                retval =Host.KEY_2;
                break;

            case "Digit3":
            case "3":  // Samsung CVP-2 Bug
                retval = Host.KEY_3;
                break;

            case "Digit4":
            case "4":  // Samsung CVP-2 Bug
                retval = Host.KEY_4;
                break;

            case "Digit5":
            case "5":  // Samsung CVP-2 Bug
                retval = Host.KEY_5;
                break;

            case "Digit6":
            case "6":  // Samsung CVP-2 Bug
                retval = Host.KEY_6;
                break;

            case "Digit7":
            case "7":  // Samsung CVP-2 Bug
                retval = Host.KEY_7;
                break;

            case "Digit8":
            case "8":  // Samsung CVP-2 Bug
                retval = Host.KEY_8;
                break;

            case "Digit9":
            case "9":  // Samsung CVP-2 Bug
                retval = Host.KEY_9;
                break;

            case "Enter":
                retval = Host.KEY_ENTER;
                break;

            case "Exit":
                retval = Host.KEY_EXIT;
                break;

            case "Guide":
                retval = Host.KEY_GUIDE;
                break;

            case "Info":
                retval = Host.KEY_INFO;
                break;

            case "MediaFastForward":
                retval = Host.KEY_FF;
                break;

//                 case "MediaLast":
//                     retval = ;
//                     break;

            case "MediaPause":
                retval = Host.KEY_PAUSE;
                break;

            case "MediaPlay":
                retval = Host.KEY_PLAY;
                break;

            case "MediaPlayPause":
                retval = Host.KEY_PLAY_PAUSE;
                break;

            case "MediaRecord":
                retval = Host.KEY_RECORD;
                break;

            case "MediaRewind":
                retval = Host.KEY_RW;
                break;

//                 case "MediaSelect":
//                     retval = ;
//                     break;

            case "MediaStop":
                retval = Host.KEY_STOP;
                break;

//                 case "MediaTrackNext":
//                     retval = ;
//                     break;

//                 case "MediaTrackPrevious":
//                     retval = ;
//                     break;

            case "OnDemand":
                retval = Host.KEY_ON_DEMAND;
                break;

            case "PageDown":
                retval = Host.KEY_PAGE_DN;
                break;

            case "PageUp":
                retval = Host.KEY_PAGE_UP;
                break;

            case "U+005A":
                retval = Host.KEY_CH_DOWN;
                break;

            case "U+007B":  // Samsung HACK
                retval = Host.KEY_CH_UP;
                break;

            case "U+00BD":  // Samsung HACK
                retval = Host.KEY_HUD;
                break;
        }

        return retval;
    };

    Host.mapKeyVal = function( val )
    {
        var retval = val;

        switch( val )
        {
            case Host.KEY_A:
                retval = Host.KEY_SOFT_A;
                break;

            case Host.KEY_B:
                retval = Host.KEY_SOFT_B;
                break;

            case Host.KEY_BRACKET_LEFT:
                retval = Host.KEY_VOL_DOWN;
                break;

            case Host.KEY_BRACKET_RIGHT:
                retval = Host.KEY_VOL_UP;
                break;

            case Host.KEY_C:
                retval = Host.KEY_SOFT_C;
                break;

            case Host.KEY_D:
                retval = Host.KEY_SOFT_D;
                break;

            case Host.KEY_DELETE:
                retval = Host.KEY_LAST;
                break;

            case Host.KEY_E:
                retval = Host.KEY_EXIT;
                break;

            case Host.KEY_EQUAL:
                retval = Host.KEY_CH_UP;
                break;

            case Host.KEY_ESC:
                retval = Host.KEY_LAST;
                break;

            case Host.KEY_F:
                retval = Host.KEY_FF;
                break;

            case Host.KEY_F1:
                retval = Host.KEY_SOFT_A;
                break;

            case Host.KEY_F2:
                retval = Host.KEY_SOFT_B;
                break;

            case Host.KEY_F3:
                retval = Host.KEY_SOFT_C;
                break;

            case Host.KEY_F4:
                retval = Host.KEY_SOFT_D;
                break;

            case Host.KEY_G:
                retval = Host.KEY_GUIDE;
                break;

            case Host.KEY_H:
                retval = Host.KEY_HUD;
                break;

            case Host.KEY_I:
                retval = Host.KEY_INFO;
                break;

            case Host.KEY_J:
                break;

            case Host.KEY_K:
                retval = Host.KEY_CC;
                break;

            case Host.KEY_L:
                retval = Host.KEY_LAST;
                break;

            case Host.KEY_M:
                retval = Host.KEY_MENU;
                break;

            case Host.KEY_MINUS:
                retval = Host.KEY_CH_DOWN;
                break;

            case Host.KEY_N:
                break;

            case Host.KEY_O:
                retval = Host.KEY_ON_DEMAND;
                break;

            case Host.KEY_P:
                retval = Host.KEY_PLAY;
                break;

            case Host.KEY_Q:
                break;

            case Host.KEY_R:
                retval = Host.KEY_RW;
                break;

            case Host.KEY_S:
                retval = Host.KEY_STOP;
                break;

            case Host.KEY_SLASH:
                retval = Host.KEY_USAGE;
                break;

            case Host.KEY_T:
                retval = Host.KEY_MUTE;
                break;

            case Host.KEY_U:
                retval = Host.KEY_PAUSE;
                break;

            case Host.KEY_V:
                break;

            case Host.KEY_W:
                break;

            case Host.KEY_X:
                break;

            case Host.KEY_Y:
                break;

            case Host.KEY_Z:
                break;

            case Host.KEY_BACK_SLASH:
                retval = Host.KEY_MUTE;
                break;

            case 190:  // HACK: Sony CVP-2
                retval = Host.KEY_HUD;
                break;

            case 403:  // HACK: Sony CVP-2
                retval = Host.KEY_SOFT_C;
                break;

            case 404:  // HACK: Sony CVP-2
                retval = Host.KEY_SOFT_D;
                break;

            case 405:  // HACK: Sony CVP-2
                retval = Host.KEY_SOFT_A;
                break;

            case 406:  // HACK: Sony CVP-2
                retval = Host.KEY_SOFT_B;
                break;

            case 412:  // HACK: Sony CVP-2
                retval = Host.KEY_RW;
                break;

            case 413:  // HACK: Sony CVP-2
                retval = Host.KEY_STOP;
                break;

            case 415:  // HACK: Sony CVP-2
                retval = Host.KEY_PLAY;
                break;

            case 417:  // HACK: Sony CVP-2
                retval = Host.KEY_FF;
                break;

//             case 424:  // prev  // HACK: Sony CVP-2
//                 retval = Host.KEY_;
//                 break;

//             case 425:  // next  // HACK: Sony CVP-2
//                 retval = Host.KEY_;
//                 break;

            case 427:  // HACK: Sony CVP-2
                retval = Host.KEY_CH_UP;
                break;

            case 428:  // HACK: Sony CVP-2
                retval = Host.KEY_CH_DOWN;
                break;

            case 463:  // HACK: Sony CVP-2
                retval = Host.KEY_PAUSE;
                break;

            case 480:  // HACK: Sony CVP-2
                retval = Host.KEY_CC;
                break;
        }

        return retval;
    };

//     Host.prototype.onLoad = function()
//     {
//     };
    Host.prototype.removeAllTokenLocalStorage = function() {
        if( typeof(Storage) !== "undefined" && localStorage !== undefined ) {
            localStorage.removeItem( "OauthToken" );
            localStorage.removeItem( "XsctToken"  );   
            localStorage.removeItem( "XactToken"  );
        }
        else
            console.log( "ERROR -> local storage is not supported" );
    };
    Host.prototype.removeLocalStorage = function( key )
    {
        if( typeof(Storage) !== "undefined" && localStorage !== undefined )
            localStorage.removeItem( key );
        else
            console.log( "ERROR -> local storage is not supported" );
    };

    Host.prototype.resetLocalStorage = function()
    {
        if( typeof(Storage) !== "undefined" && localStorage !== undefined )
            localStorage.clear();
        else
            console.log( "ERROR -> local storage is not supported" );
    };

    Host.prototype.setLocalStorage = function( key, data )
    {
        if( typeof(Storage) !== "undefined" && localStorage !== undefined )
            localStorage.setItem( key, data );
        else
            console.log( "ERROR -> local storage is not supported" );
    };

    Host.prototype.setNetworkType = function( type )
    {
        this._networkType = type;
    };

    Host.prototype.setSsid = function( name )
    {
        this._ssid = name;
    };

//     Host.prototype.setScreenSaverActive = function( active )
//     {
//         if( active === true )
//         {
//             console.log( "##### ENABLE SCREEN SAVER #########################################" );
//         }
//         else if( active === false )
//         {
//             console.log( "##### DISABLE SCREEN SAVER #########################################" );
//         }
//         else
//             console.log( "ERROR -> invalid parameter for setScreenSaverActive = " + active );
//     };

    return Host;

})();
